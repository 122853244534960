/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import ReactDOM from 'react-dom';

import './Select.scss';

import {
  getDimensionsAndOffset,
  isEmpty,
  isEqual,
  isNotEmpty,
  itemIsArray,
  itemIsObject,
} from '../../../Utilities';
import Dropdown from '../../../Dropdown';
import InlineSVG from '../../../InlineSVG';
import EmptyState from '../../../EmptyState';
import TagItem from '../../../../components/RiskInsight/Tags/Item';

// eslint-disable-next-line camelcase
const defaultCredential = {
  'protocol': 'smb-wmi',
  'pam_type': 'none',
  'label': '',
  'eval_order': null,
};

const PortalSelectOptions = ( {
  showMultiselectOptions,
  wrapperRef,
  field,
  multiSelectValue,
  handleMultiSelect,
  setShowMultiSelectOptions,
} ) => {
  const portalRoot = document.getElementById( 'selectMultiItemsPortal' );

  const [ style, setStyle ] = React.useState( {} );

  // when it is time to show the dropdown, position it accordingly
  React.useEffect( () => {
    if ( isNotEmpty( wrapperRef ) && isNotEmpty( wrapperRef.current ) && showMultiselectOptions ) {
      const offset = getDimensionsAndOffset( wrapperRef.current );

      if ( isNotEmpty( offset ) ) {

        let top = offset.top + offset.height + 8;
        const height = 24 * 16;

        if ( field.optionsAbove ) {
          top = offset.top + offset.height - height - 8;
        }
        setStyle(
          {
            top,
            left: offset.left,
            width: offset.width,
            height,
          },
        );
      }
    } else {
      setStyle( {} );
    }
  }, [ wrapperRef, showMultiselectOptions, multiSelectValue ] );

  if ( isNotEmpty( portalRoot ) ) {
    return ReactDOM.createPortal(
      <div className="multiSelectOptionsWrapper" style={ style } >
        {
          ( isNotEmpty( field.options ) && showMultiselectOptions ) &&
          <div className={ `multiSelectOptions ${ Object.keys( field.options ).length > 20 ? 'twoColumn' : '' }` }>
            {
              Object.entries( field.options ).map( ( [ key, value ], index ) => {
                return <div className="option" key={index} onClick={ e => handleMultiSelect( e, key ) }>
                  {
                    multiSelectValue.includes( key )
                      ? <InlineSVG type="checkboxChecked" version="primary" elementClass="checked"/>
                      : <InlineSVG type="checkbox" elementClass="unchecked"/>
                  }
                  {
                    ( isNotEmpty( field ) && field.attribute === 'asset_tag_ids' )
                      ? <TagItem tag={value} minimalVersion />
                      : <span>{ value }</span>
                  }
                </div>;
              } )
            }
          </div>
        }
        {
          showMultiselectOptions &&
          <div className="multiselectShade" onClick={ () => setShowMultiSelectOptions( false ) } />
        }
      </div>,
      portalRoot,
    );
  }

};

const Select = ( {
  field,
  externalFormState,
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {
  // used as an intermediary to store selections
  const [ selections, setSelections ] = React.useState( [] );
  const [ menuItems, setMenuItems ] = React.useState( [] );
  const [ trigger, setTrigger ] = React.useState( null );
  const [ optionValuesMap, setOptionValuesMap ] = React.useState( {} );

  // needed for newer styled asMultiple variant
  const [ multiSelectValue, setMultiSelectValue ] = React.useState( [] );
  const [ showMultiselectOptions, setShowMultiSelectOptions ] = React.useState( false );

  const wrapperRef = React.useRef( null );

  const hasExistingRecord = () => {
    if ( isNotEmpty( existingRecord ) ) {
      if ( isEqual( existingRecord, defaultCredential ) ) {
        return false;
      }
      return true;
    }
    return false;
  };

  // only do this once, set the valuesMap for the display name of the dropdown type
  React.useEffect( ( ) => {
    const valuesMap = {};

    if ( isNotEmpty( field ) && field.asDropdown && isNotEmpty( field.options ) ) {
      // typical items, not grouped
      if ( itemIsObject( field.options ) ) {

        Object.entries( field.options ).map( ( [ value, content ] ) => {
          valuesMap[value] = content;
        } );
      // an array of option groups
      } else if ( itemIsArray( field.options ) ) {
        field.options.map( group => {
          // map over the options
          Object.entries( group.options ).map( ( [ value, content ] ) => {
            valuesMap[value] = content;
          } );
        } );
      }
      setOptionValuesMap( valuesMap );
    }
  }, [ field ] );

  // for drowdown selects, need to setup the items
  React.useEffect( ( ) => {
    const items = [];

    if ( isNotEmpty( field ) && field.asDropdown && isNotEmpty( field.options ) ) {
      // typical items, not grouped
      if ( itemIsObject( field.options ) ) {

        Object.entries( field.options ).map( ( [ value, content ], index ) => {
          const item = <span
            key={index}
            className="selectIemWrapper"
            onClick={ () => handleSelection( null, value, content ) }
          >
            { content }
          </span>;

          items.push( item );
        } );
      // an array of option groups
      } else if ( itemIsArray( field.options ) ) {
        field.options.map( group => {
          // push the label in as an unclickable divider
          if ( group.displayLabel !== false ) {
            items.push(
              <div className="selectIemWrapper asDivider" >
                <span className="dividerLabelWrapper">{ group.label }</span>
              </div>,
            );
          }

          // map over the options
          Object.entries( group.options ).map( ( [ value, content ], index ) => {
            const item = <span
              key={index}
              className="selectIemWrapper"
              onClick={ () => handleSelection( null, value, content ) }
            >
              { content }
            </span>;

            items.push( item );
          } );
        } );
      }
      setMenuItems( items );
    }
  }, [ field, optionValuesMap, formState ] );

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
    // since this is not actually a select, need to handle the value manually
    if ( field.asDropdown && isNotEmpty( optionValuesMap ) ) {
      const optionsKey = ( isEmpty( originalValue ) || originalValue === 'null' ) ? '' : originalValue;
      setTrigger( optionValuesMap[optionsKey] );
    }
    // since this replaces an html select field, need to handle differently
    if ( field.asMultiple ) {
      setMultiSelectValue( originalValue || [] );
    }
  }, [ originalValue, fieldRef, existingRecord, optionValuesMap ] );

  // this gets called if there is a conditional that needs to manually adjust this field's
  // value externally rare usage, only happens with nested conditional selects, not multiselect
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
      && isNotEmpty( formState )
      && isNotEmpty( formState.fieldStates )
      && isNotEmpty( formState.fieldStates[field.attribute] )
    ) {
      fieldRef.current.value = formState?.fieldStates[field.attribute].updatedValue || '';
    }
  }, [ formState ] );

  // this also gets called when the value is adjusted externally, unlike the above, this applies to the asMultiple
  // variant and is currently used when removing an applied filter in the tag editor v3 interface
  React.useEffect( () => {
    if (
      isNotEmpty( externalFormState )
      && isNotEmpty( externalFormState.fieldStates )
    ) {
      const _externalField = externalFormState.fieldStates[field.attribute];

      if ( isNotEmpty( _externalField ) ) {
        const { updatedValue } = _externalField;
        setMultiSelectValue( updatedValue || [] );
      }
    }
  }, [ externalFormState ] );

  // called onChange to be a go-between to handle value correctly
  const handleSelection = ( e, _value, _displayValue ) => {

    let value;
    // there is no event, the value was passed in directly
    if ( isEmpty( e ) ) {
      value = _value;
    } else {
      value = e.target.valueAsNumber || e.target.value;
    }

    if ( field.multiple && isNotEmpty( e ) ) {
      value = Array.from( e.target.selectedOptions, option => option.value );
      setSelections( value );
    }

    onChange( field, value );

    // since this is not actually a select, need to handle the value manually
    if ( field.asDropdown && isNotEmpty( _displayValue ) ) {
      const optionsKey = ( isEmpty( _displayValue ) || _displayValue === 'null' ) ? '' : _displayValue;
      setTrigger( _displayValue || optionValuesMap[optionsKey] );
    }
  };

  // whenever a multiselection is made with the newer multi variant
  const handleMultiSelect = ( e, value ) => {

    if ( isNotEmpty( e ) ) {
      e.preventDefault();
      e.stopPropagation();
    }
    let _values = [ ...multiSelectValue ];

    if ( _values.includes( value ) ) {
      _values = _values.filter( v => v !== value );
    } else {
      _values.push( value );
    }
    setMultiSelectValue( _values );
    onChange( field, _values );
  };

  const handleToggle = val => {
    setSelections( val );
    onChange( field, val );
  };

  const deselectAll = () => {
    if ( field.multiple ) {
      const value = [];
      setSelections( value );
      onChange( field, value );
    }
  };

  const isOriginal = option => {
    if ( field.originalValueOverride ) {
      return isEqual( field.originalValueOverride, option );
    }
    return isEqual( originalValue, option );
  };

  return (
    <React.Fragment>
      {/* toggle */}
      {
        field.asToggle &&
        <React.Fragment>
          {
            ( field.toggleClass === 'large' && Object.keys( field.options ).length === 2 ) &&
            <div
              className={ `toggleWrapper large ${window.IS_DARK_MODE ? 'darkMode' : ''}` }
            >
              <div className={`${originalValue} toggle`}>
                {
                  Object.entries( field.options ).map( ( [ val, label ], index ) => {
                    return  <button
                      onClick={ () => handleToggle( val ) }
                      key={index}
                      className={`${selections === val ? 'toggled' : ''}`}
                    >
                      { label }
                    </button>;
                  } )
                }
              </div>
            </div>
          }
        </React.Fragment>
      }
      {/* uses the dropdown component instead for more versatility, styled to look like a regular select, mostly  */}
      {
        ( field.asDropdown && isNotEmpty( menuItems ) && isNotEmpty( trigger ) ) &&
        <Dropdown
          asSelect
          trigger={ trigger }
          menuItems={ menuItems }
          scrollOffsetContainerID="reactSetupModal_body"
          disabled={ ( field.disableOnEdit && isNotEmpty( existingRecord ) ) || field?.disabled }
          elementClass={ `selectFieldMenuWrapper ${field.attribute}` }
          menuElementClass={ `selectFieldMenuWrapper ${field.attribute}`}
        />
      }
      {/* either a select or multi-select (html version) */}
      {
        ( !field.asDropdown && !field.asToggle && !field.asMultiple ) &&
        // eslint-disable-next-line max-len
        <div className={ `${field.multiple ? 'isMulptiple' : ''} selectFieldWrapper ${field.asButton ? 'asButton' : ''} ${window.IS_DARK_MODE ? 'darkMode' : ''}` }>
          <select
            multiple={ field.multiple }
            disabled={
              field.disabled
              || formState?.fieldStates[field.attribute]?.disabled
              || ( field.disableOnEdit && hasExistingRecord() )
            }
            onChange={ handleSelection }
            ref={fieldRef}
            { ...field.htmlProps }
          >
            {
              field.allowBlank
                ? <option
                  value={ field.blankDisplayValue ? field.blankDisplayValue : '' }>
                  { field.blankDisplayText ? field.blankDisplayText : 'None' }
                </option>
                : <React.Fragment>
                  {
                    !field.multiple &&
                    <option disabled value="">select...</option>
                  }
                </React.Fragment>
            }
            {
              isNotEmpty( field.options ) &&
              <React.Fragment>
                {
                  Array.isArray( field.options )
                    ? <React.Fragment>
                      {
                        field.options.map( ( group, index ) => {
                          return  <optgroup key={index} label={group.label} >
                            {
                              Object.keys( group.options ).map( ( option, _index ) => {
                                return  <option
                                  className={ `${ isOriginal( option ) ? 'original' : ''}` }
                                  key={_index}
                                  value={option}
                                >
                                  { group.options[option] }
                                </option>;
                              } )
                            }
                          </optgroup>;
                        } )
                      }
                    </React.Fragment>
                    : <React.Fragment>
                      {
                        Object.keys( field.options ).map( ( option, index ) => {
                          return  <option
                            className={ `${ isOriginal( option ) ? 'original' : ''}` }
                            key={index}
                            value={option}
                          >
                            { field.options[option] }
                          </option>;
                        } )
                      }
                    </React.Fragment>
                }
              </React.Fragment>
            }

          </select>
          {
            ( field.multiple && isNotEmpty( selections ) ) &&
            <button
              className="deselectButton"
              onClick={ deselectAll }
            >
              De-select all
            </button>
          }
        </div>
      }
      {/* a styled replacement for standard html multi select fields */}
      {
        field.asMultiple &&
        <React.Fragment>
          <div className={ `${field.disabled ? 'disabled' : '' } multiSelectWrapper` } ref={wrapperRef}>
            {
              ( isEmpty( field.options ) && isNotEmpty( field.emptyStateMessage ) ) &&
              <EmptyState message={ field.emptyStateMessage } />
            }
            {
              isNotEmpty( field.options ) &&
              <div
                className={ `selectFieldWrapper noInput ${ showMultiselectOptions ? 'open' : '' }` }
                onClick={ () => setShowMultiSelectOptions( !showMultiselectOptions ) }
              >
                <div className="selectedOptions">
                  {
                    isEmpty( multiSelectValue ) &&
                    <span className="placeholder">{ field.placeholder || 'Select...' }</span>
                  }
                  {
                    Object.entries( field.options ).map( ( [ key, value ], index ) => {
                      if ( multiSelectValue.includes( key ) ) {
                        return <div
                          className={ `selected ${field.disabled ? 'disabled' : '' }` }
                          key={index}
                          onClick={ e => handleMultiSelect( e, key ) }
                        >
                          {
                            ( isNotEmpty( field ) && field.attribute === 'asset_tag_ids' )
                              ? <TagItem tag={value} minimalVersion />
                              : <strong>{ value }</strong>
                          }
                          <span className="removeButton" >
                            <InlineSVG type="remove" />
                          </span>
                        </div>;
                      }

                    } )
                  }
                </div>
                <InlineSVG type="carretDown" elementClass="carretIcon" />
              </div>
            }
            <PortalSelectOptions
              showMultiselectOptions={ showMultiselectOptions }
              wrapperRef={ wrapperRef }
              field={ field }
              multiSelectValue={ multiSelectValue }
              handleMultiSelect={ handleMultiSelect }
              setShowMultiSelectOptions={ setShowMultiSelectOptions }
            />
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Select;
