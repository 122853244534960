// each of these themes correspondes to a const that is found in global settings, the const needs to have the correct
// key.
// each property is then applied via root.style.setProperty and an scss variable is created from each property
// that scss var is then used throughtout all the scss files
// example: the theme is default_light, the property is --text-color, the value is #000
// the scss var is $text-color: var(--text-color);

import { hexToRGB, isEmpty, isNotEmpty, tintOrShadeHex } from './Utilities';

export const asRGBTriplet = ( hex ) => {
  const rgbValues = hexToRGB( hex );
  return `${rgbValues?.r}, ${rgbValues?.g}, ${rgbValues?.b}`;
};

export const themeCSSVariables = {
  // eslint-disable-next-line camelcase
  default_light: {
    '--primary': '#00AAE9',

    '--text-color-primary': '#334D6E',
    '--text-color-secondary': '#90A0B7',
    '--background-color': '#F7F7FA',
    '--background-color-secondary': '#FCFCFD',
    '--divider-color': '#EBEFF2',
    '--border-color': '#EBEFF2',
    '--foreground-color': '#FFFFFF',
    '--table-secondary': '#f9fbfc',
    '--icon-color-primary': '#C2CFE0',
    '--icon-color-secondary': '#EBEFF2',
    '--link-color': '#00AAE9',

    '--border-radius': '0.375em',
    '--border-radius-input': '0.5em',
    '--border-radius-full': '9999em',
    '--border-radius-button': '0.375em',

    '--box-shadow': '0px 0px 4px 0px rgba(0,0,0, .16)',
    '--box-shadow-input': '1px 2px 8px 1px rgba(0,0,0, .05)',
    '--box-shadow-heavy': '0px 8px 32px 0px rgba(0,0,0, .12)',
    '--box-shadow-light': '1px 2px 8px 1px rgba(0,0,0, .05)',

    '--status-info': '#2d9cdb',
    '--status-success': '#6fcf97',
    '--status-warning': '#f9cd49',
    '--status-moderate': '#f2994a',
    '--status-alert': '#f7685b',

    '--critical': '#c33937',
    '--high': '#ea5e50',
    '--moderate': '#ff961a',
    '--low': '#ffdf0d',
    '--minimal': '#43d988',

    '--gradientColor1': '#00AAE9',
    '--gradientColor2': '#20729F',
    '--gradient-primary': 'linear-gradient(90deg, #00AAE9, #20729F)',
    '--gradient-secondary': 'linear-gradient(90deg, #ddeef4, #e0e8ed)',
  },
  // eslint-disable-next-line camelcase
  default_dark: {
    '--text-color': '#fff',
    '--background-color': '#000',
    '--border-color': '#fff',
    '--border-radius': '0.125em',
    '--box-shadow': '0 0 0.25em rgba(255, 255, 255, 0.25)',
    '--link-color': '#0ff',
    '--link-hover-color': '#0ff',
    '--link-visited-color': '#a020f0',
  },
  // trellix_light: {

  // },
  // eslint-disable-next-line camelcase
  trellix_dark: {
    '--primary': '#87BFFF',

    '--text-color-primary': '#E8E8E8',
    '--text-color-secondary': '#424242',
    '--background-color': '#1A1A1A',
    '--background-color-secondary': '#2E2E2E',
    '--divider-color': '#636363',
    '--border-color': '#636363',
    '--foreground-color': '#424242',
    '--table-secondary': '#2E2E2E',
    '--icon-color-primary': '#707070',
    '--icon-color-secondary': '#636363',
    '--link-color': '#87BFFF',

    '--border-radius': '0.25em',
    '--border-radius-input': '0.25em',
    '--border-radius-full': '9999em',
    '--border-radius-button': '0.125em',

    '--box-shadow': '0px 0px 4px 0px rgba(0,0,0, .16)',
    '--box-shadow-input': '1px 2px 8px 1px rgba(0,0,0, .05)',
    '--box-shadow-heavy': '0px 8px 32px 0px rgba(0,0,0, .12)',
    '--box-shadow-light': '1px 2px 8px 1px rgba(0,0,0, .05)',

    '--status-info': '#52C2FF',
    '--status-success': '#00CC00',
    '--status-warning': '#EBC80A',
    '--status-moderate': '#FA9600',
    '--status-alert': '#FF4053',

    '--critical': '#FF4053',
    '--high': '#FA9600',
    '--moderate': '#EBC80A',
    '--low': '#52C2FF',
    '--minimal': '#7D7D7D',

    '--gradientColor1': '#424242',
    '--gradientColor2': '#424242',
    '--gradient-primary': 'linear-gradient(90deg, #424242, #424242)',
    '--gradient-secondary': 'linear-gradient(90deg, #424242, #424242)',
  },
  // attackiq_light: {

  // },
  // eslint-disable-next-line camelcase
  attackiq_dark: {
    '--primary': '#29B6F6',

    '--text-color-primary': '#FFFFFF',
    '--text-color-secondary': '#060606',
    '--background-color': '#121722',
    '--background-color-secondary': '#2E2E2E',
    '--divider-color': '#636363',
    '--border-color': '#636363',
    '--foreground-color': '#2E394D',
    '--table-secondary': '#212939',
    '--icon-color-primary': '#636363',
    '--icon-color-secondary': '#636363',
    '--link-color': '#29B6F6',

    '--border-radius': '0.375em',
    '--border-radius-input': '0.5em',
    '--border-radius-full': '9999em',
    '--border-radius-button': '0.375em',

    '--box-shadow': '0px 0px 4px 0px rgba(0,0,0, .16)',
    '--box-shadow-input': '1px 2px 8px 1px rgba(0,0,0, .05)',
    '--box-shadow-heavy': '0px 8px 32px 0px rgba(0,0,0, .12)',
    '--box-shadow-light': '1px 2px 8px 1px rgba(0,0,0, .05)',

    '--status-info': '#29B6F6',
    '--status-success': '#66BB6A',
    '--status-warning': '#FDD835',
    '--status-moderate': '#FFA726',
    '--status-alert': '#F44336',

    '--critical': '#F44336',
    '--high': '#FFA726',
    '--moderate': '#FDD835',
    '--low': '#66BB6A',
    '--minimal': '#ADADAD',

    '--gradientColor1': '#212939',
    '--gradientColor2': '#212939',
    '--gradient-primary': 'linear-gradient(90deg, #212939, #212939)',
    '--gradient-secondary': 'linear-gradient(90deg, #212939, #212939)',
  },
};

export const nonColorKeys = [
  '--border-radius',
  '--border-radius-input',
  '--border-radius-full',
  '--border-radius-button',
  '--box-shadow',
  '--box-shadow-input',
  '--box-shadow-heavy',
  '--box-shadow-light',
  '--gradient-primary',
  '--gradient-secondary',
];

export const tintedVariantPrefixes = [
  'status-info',
  'status-success',
  'status-warning',
  'status-moderate',
  'status-alert',

  'critical',
  'high',
  'moderate',
  'low',
  'minimal',
  'primary',
];

export const tintedVariantSuffixes = [
  '100',
  '80',
  '75',
  '60',
  '50',
  '40',
  '25',
  '20',
  '10',
];

export const getThemeColor = ( colorKey ) => {
  let _colorKey = colorKey;

  // allow the color key to start with or without a '--'
  if ( colorKey.startsWith( '--' ) ) {
    _colorKey = colorKey;
  } else {
    _colorKey = `--${colorKey}`;
  }

  const themeKey = window.SELECTED_THEME || 'attackiq_dark';

  // if the theme doesn't exist, return null
  if ( isEmpty( themeCSSVariables[themeKey] ) ) {
    return null;
  }
  const splitColorKey = _colorKey.split( '--' );

  const [ , baseKey, variantKey ] = splitColorKey;

  // if the colorKey is a tinted variant, calculate the tint and return it
  if ( isNotEmpty( variantKey ) && tintedVariantSuffixes.includes( variantKey ) ) {
    if ( variantKey === '100' ) {
      return themeCSSVariables[themeKey][`--${baseKey}`];
    }
    const tintValue = 1 - ( parseInt( variantKey, 10 ) / 100 );
    return tintOrShadeHex( themeCSSVariables[themeKey][`--${baseKey}`], tintValue );
  }

  // if the colorKey doesn't exist, return null
  if ( isEmpty( themeCSSVariables[themeKey][_colorKey] ) ) {
    return null;
  }
  // return the color
  return themeCSSVariables[themeKey][_colorKey];
};