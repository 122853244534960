/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  comparatorOptions,
  fuzzySearch,
  getAllAvailableFiltersForType,
  getFilterKeyFromMatchString,
} from '../shared';
import {
  decodeURLHash,
  encodeURLHash,
  isEmpty,
  isNotEmpty,
  itemIsObject,
  removeFromURLHash,
  shortenedVulnerabilityScannerNameMap,
  vulnerabilityScannerInstanceName,
} from '../../../../shared/Utilities';
import Field from '../../../../shared/Form/Field';

import './style.scss';
import { makeRequest } from '../../../../../legacy/io';
import { hasFeatureAccess } from '../../../App/AccessControl';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser';
import { TagsContext } from '../../../../Contexts/Tags';
import AppliedFilters from '../Appliedfilters';
import InlineSVG from '../../../../shared/InlineSVG';
import {
  applyFilterToHash,
  gtltFilterKeys,
  isGroupInequalityType,
  isInequalityType,
} from '../../../../shared/RecordCache';

const FilterBuilder = ( {
  recordType,
  onRefresh,
  variant='riskInsight',
  osLabels=null,
  externalChangeHandler,
  appliedFiltersFromRecord=[],
  appliedFilters,
  setAppliedFilters,
} ) => {

  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ tags ] = React.useContext( TagsContext );

  const [ selectedFilter, setSelectedFilter ] = React.useState( {} );
  const [ availableFilters, setAvailableFilters ] = React.useState( {} );
  const [ availableComparators, setAvailableComparators ] = React.useState( comparatorOptions );
  const [ comparatorField, setComparatorField ] = React.useState( {} );

  const [ adjustedRecordType, setAdjustedRecordType ] = React.useState( null );
  const [ filtersPool, setFiltersPool ] = React.useState( {} );

  const [ filter, setFilter ] = React.useState( { attribute: '', comparator: '', value: '' } );
  const [ filterIsValid, setFilterIsValid ] = React.useState( false );

  const [ matches, setMatches ] = React.useState( [] );
  const [ currentMatch, setCurrentMatch ] = React.useState( {} );
  const [ focusedSection, setFocusedSection ] = React.useState( 'attribute' );
  const [ filterIsReady, setFilterIsReady ] = React.useState( false );

  const [ showFilterBuilder, setShowFilterBuilder ] = React.useState( false );

  const attributeFieldRef = React.useRef( null );

  const handleHashChange = () => {
    const hash = decodeURLHash();
    if ( isNotEmpty( hash.group_type ) ) {
      setAdjustedRecordType( hash.group_type );
    } else {
      setAdjustedRecordType( recordType );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( recordType ) ) {
      handleHashChange();
      window.addEventListener( 'hashchange', handleHashChange );
      return () => window.removeEventListener( 'hashchange', handleHashChange );
    }
  }, [ recordType ] );

  const matchesSorter = ( a, b ) => {
    if ( variant === 'tagEditor' ) {
      return 0;
    }

    if ( a.matchString < b.matchString ) {
      return -1;
    }
    if ( a.matchString > b.matchString ) {
      return 1;
    }
    return 0;
  };

  // FIRST THING TO HAPPEN get all the options for various filters (tags, os, vuln sources, etc)
  const setupFilterOptions = async () => {
    const _vulnerabilitySourceOptions = {};
    const _availableVulnerabilitySources = {};

    let _filtersPool = {};

    if ( variant === 'tagEditor' && isNotEmpty( osLabels ) ) {
      _filtersPool = getAllAvailableFiltersForType( 'tag' );
      const includeOSFilter = _filtersPool.included_product_names;
      const excludeOSFilter = _filtersPool.excluded_product_names;

      if ( isNotEmpty( includeOSFilter ) && isNotEmpty( excludeOSFilter ) ) {
        includeOSFilter.options = osLabels;
        excludeOSFilter.options = osLabels;
      }
    }

    if ( variant === 'riskInsight' ) {
      _filtersPool = getAllAvailableFiltersForType( adjustedRecordType );
      const osResponse = await makeRequest( 'GROUP', '/analysis/osversion', { project: 'default', model: 'base' } );

      // THIRD PARTY SETTING (VULN. SOURCE) FILTER OPTIONS
      if ( hasFeatureAccess( currentUser, licenseInfo, 'f_third_party_settings' ) ) {
        const vulnResponse = await makeRequest( 'SEARCH', '/project/default/third_party_setting', {
          // eslint-disable-next-line camelcase
          extra_columns: [ 'tool', 'settings', 'credential_id', 'category' ],
        } );

        if ( isNotEmpty( vulnResponse ) && isNotEmpty( vulnResponse.results ) ) {
          vulnResponse.results.map( v => {
            if ( v.category === 'vulnerability_scanner' ) {
              _availableVulnerabilitySources[v.id] = v;
            }
          } );

          // if there are scanners configured, grab their associated creds
          if ( isNotEmpty( _availableVulnerabilitySources ) ) {
            Object.values( _availableVulnerabilitySources ).map( v => {

              const combined = { ...v, settings: { ...v.settings } };
              // eslint-disable-next-line max-len
              _vulnerabilitySourceOptions[v.id] = `${shortenedVulnerabilityScannerNameMap[v.tool]} - ${vulnerabilityScannerInstanceName( combined )}`;
            } );

            if ( isNotEmpty( _vulnerabilitySourceOptions ) ) {
              const scannerFilter = _filtersPool.third_party_setting_ids;

              if ( isNotEmpty( scannerFilter ) ) {
                scannerFilter.options = _vulnerabilitySourceOptions;
              }
            } else {
              delete _filtersPool.third_party_setting_ids;
            }
          }
        }
      }

      // OS FILTER OPTIONS (4 separate filters)
      if ( isNotEmpty( osResponse ) && isNotEmpty( osResponse.results ) ) {
        const osVersionData = osResponse.results;

        if ( isNotEmpty( osVersionData ) ) {

          const vendorFilter = _filtersPool.host_vendor;
          const typeFilter = _filtersPool.host_os_type;
          const archFilter = _filtersPool.host_os_arch;
          const labelFilter = _filtersPool.host_product_name;

          const vendorOptions = {};
          const typeOptions = {};
          const archOptions = {};
          const labelOptions = {};

          if ( isNotEmpty( osVersionData.host_vendor ) && isNotEmpty( vendorFilter ) ) {
            osVersionData.host_vendor.map( o => {
              vendorOptions[o] = o;
            } );
            vendorFilter.options = vendorOptions;
          }

          if ( isNotEmpty( osVersionData.host_os_type ) && isNotEmpty( typeFilter ) ) {
            osVersionData.host_os_type.map( o => {
              typeOptions[o] = o;
            } );
            typeFilter.options = typeOptions;
          }

          if ( isNotEmpty( osVersionData.host_architecture ) && isNotEmpty( archFilter ) ) {
            osVersionData.host_architecture.map( o => {
              archOptions[o] = o;
            } );
            archFilter.options = archOptions;
          }

          if ( isNotEmpty( osVersionData.host_os_label ) && isNotEmpty( labelFilter ) ) {
            osVersionData.host_os_label.map( o => {
              labelOptions[o] = o;
            } );
            labelFilter.options = labelOptions;
          }
        }
      }

      // ASSET TAG OPTIONS
      if ( isNotEmpty( tags ) ) {
        const tagFilter = _filtersPool.asset_tag_ids;

        if ( isNotEmpty( tagFilter ) ) {
          tagFilter.options = tags;
        }
      }
    }
  };

  const handleInputChange = ( inputValue, section ) => {
    if ( section === 'attribute' ) {
      setFilter( { ...filter, attribute: inputValue } );
      if ( isNotEmpty( inputValue ) ) {
        // this is an exact match, set the selected Filter and move on
        if ( attributeIsValid( inputValue ) ) {
          // eslint-disable-next-line max-len
          let _selectedFilter = Object.values( filtersPool ).find( filter => filter.matchString === inputValue );
          if ( variant === 'tagEditor' ) {
            // eslint-disable-next-line max-len
            _selectedFilter = Object.values( filtersPool ).find( filter => filter.matchString === inputValue );
          }

          handleSelectMatch( _selectedFilter, 'attribute' );
        // it can be matched to at least one option
        } else if ( isNotEmpty( availableFilters ) ) {
          // eslint-disable-next-line max-len
          let _matches = Object.values( availableFilters ).filter( option => fuzzySearch( inputValue, option.matchString ) );
          if ( isNotEmpty( _matches ) ) {
            _matches = _matches.sort( ( a, b ) => matchesSorter( a, b ) );
            setMatches( _matches );
            setCurrentMatch( _matches[0] );
          } else {
            setMatches( [] );
            setCurrentMatch( {} );
          }
        // it cannot be matched to any option
        } else {
          setMatches( [] );
          setCurrentMatch( {} );
        }
      // this way it shows all of them when nothing is typed in
      } else {
        let _matches = Object.values( filtersPool );
        _matches = _matches.sort( ( a, b ) => matchesSorter( a, b ) );
        setMatches( _matches );
        setCurrentMatch( _matches[0] );
        setSelectedFilter( {} );
      }
    } else if ( section === 'comparator' ) {
      setFilter( { ...filter, comparator: inputValue } );
      setFocusedSection( 'value' );
    }
  };

  const handleSelectMatch = ( match, section ) => {
    let _availableFilters = { ...availableFilters };
    let _filter = { ...filter };
    const _availableComparators = { };
    const _comparatorFieldOptions = { };

    switch ( section ) {
    case 'attribute':
      setSelectedFilter( match );

      _availableFilters = { ...availableFilters };
      delete _availableFilters[match.attribute];

      match.allowedComparisons.map( comparison => {
        _availableComparators[comparison] = comparatorOptions[comparison];
        const _comparator = comparatorOptions[comparison];
        if ( isNotEmpty( _comparator ) ) {
          _comparatorFieldOptions[_comparator.matchString] = _comparator.displayString;
        }
      } );

      setAvailableComparators( _availableComparators );

      if ( isNotEmpty( _comparatorFieldOptions ) ) {

        const [ _originalValue ] = Object.keys( _comparatorFieldOptions );
        const field = {
          attribute: 'comparator',
          type: 'select2',
          options: _comparatorFieldOptions,
          originalValue: _originalValue,
          label: 'Comparator',
        };
        setComparatorField( field );
      }

      _filter = {
        ..._filter,
        attribute: match.matchString,
        comparator: Object.values( _availableComparators )[0].matchString,
      };

      if ( isNotEmpty( _availableComparators ) && Object.keys( _availableComparators ).length > 1 ) {
        setFocusedSection( 'comparator' );
      } else {
        setFocusedSection( 'value' );
      }
      setFilter( _filter );
      break;
    default:
      break;
    }
  };

  const handleValueFieldChange = ( value ) => {
    if ( isNotEmpty( value ) ) {
      setFilter( { ...filter, value } );
    }
  };

  const handleComparatorFieldChange = ( value ) => {
    if ( isNotEmpty( value ) ) {
      setFilter( { ...filter, comparator: value } );
      setFocusedSection( 'value' );
    }
  };

  const attributeIsValid = ( attribute ) => {
    const allAvailableFilterMatchStrings = Object.values( filtersPool ).map( filter => filter.matchString );
    return allAvailableFilterMatchStrings.includes( attribute );
  };

  const comparatorIsValid = ( comparator ) => {
    const allAvailableComparators = Object.values( comparatorOptions ).map( comparator => comparator.matchString );
    return allAvailableComparators.includes( comparator );
  };

  // on mount, get all the filter options
  React.useEffect( () => {
    if ( isNotEmpty( adjustedRecordType ) ) {
      setupFilterOptions();
    }
  }, [ tags, adjustedRecordType, variant, osLabels ] );

  // determine what filters are available based on the record type and variant
  React.useEffect( () => {
    let _filtersPool = { };
    if ( isNotEmpty( variant ) && variant === 'tagEditor' ) {
      _filtersPool = getAllAvailableFiltersForType( 'tag' );
    } else if ( isNotEmpty( adjustedRecordType ) ) {
      _filtersPool = getAllAvailableFiltersForType( adjustedRecordType );
    }
    setFiltersPool( _filtersPool );
    let _matches = Object.values( _filtersPool );
    _matches = _matches.sort( ( a, b ) => matchesSorter( a, b ) );
    setMatches( _matches );
    setCurrentMatch( _matches[0] );
  }, [ variant, adjustedRecordType ] );

  // when the filter has all the required sections, show the finish button
  React.useEffect( () => {
    if (
      isNotEmpty( filter )
      && isNotEmpty( filter.attribute )
      && isNotEmpty( filter.comparator )
      && isNotEmpty( filter.value )
      && attributeIsValid( filter.attribute )
      && comparatorIsValid( filter.comparator )
    ) {
      setFilterIsReady( true );
      setFilterIsValid( true );
    }
  }, [ filter ] );

  const removeAppliedFilter = ( filter, triggerRefresh=true ) => {

    const adjustGtLtInURLHash = ( filter ) => {
      let existingGtLtFilter;
      let existingGtLtFilterKey;

      if ( isInequalityType.includes( filterKey ) ) {
        if ( filter.comparator === '>' ) {
          existingGtLtFilterKey = 'gt_map';
          existingGtLtFilter = hash.gt_map;
        } else {
          existingGtLtFilterKey = 'lt_map';
          existingGtLtFilter = hash.lt_map;
        }
      // a group gt/lt filter, will be in either group_gt_map key or group_lt_map key
      } else if ( isGroupInequalityType.includes( filterKey ) ) {
        if ( filter.comparator === '>' ) {
          existingGtLtFilterKey = 'group_gt_map';
          existingGtLtFilter = hash.group_gt_map;
        } else {
          existingGtLtFilterKey = 'group_lt_map';
          existingGtLtFilter = hash.group_lt_map;
        }
      }
      if ( isNotEmpty( existingGtLtFilter ) && isNotEmpty( existingGtLtFilterKey ) ) {
        const parsedFilter = JSON.parse( existingGtLtFilter );
        if ( itemIsObject( parsedFilter ) ) {
          delete parsedFilter[filterKey];
          if ( isNotEmpty( parsedFilter ) ) {
            encodeURLHash( { [existingGtLtFilterKey]: parsedFilter } );
          } else {
            removeFromURLHash( existingGtLtFilterKey );
          }
        }
      }
    };

    const _availableFilters = { ...availableFilters };
    const _allAvailableFilters = { ...filtersPool };
    const hash = decodeURLHash();

    // eslint-disable-next-line max-len
    const appliedFilterIndex = appliedFilters.findIndex( f => ( f.attribute === filter.attribute && f.comparator === filter.comparator ) );

    const _appliedFilters = [ ...appliedFilters ];
    let appliedTwice = false;
    const filterKey = getFilterKeyFromMatchString( filter.attribute );

    if ( isNotEmpty( _appliedFilters ) ) {
      appliedTwice = _appliedFilters.filter( f => f.attribute === filter.attribute ).length > 1;
    }

    // it is a gt/lt filter and it has been applied all of the times that it is allowed, need to add the filter back
    // to the available filters and add the comparator back to the available comparators
    if ( appliedTwice && gtltFilterKeys.includes( filterKey ) ) {
      const existingFilter = _allAvailableFilters[filterKey];
      // add the filter back to available filters with only one comparator option
      if ( isNotEmpty( existingFilter ) ) {
        if ( filter.comparator === '>' ) {
          existingFilter.allowedComparisons = [ 'gt' ];
        } else {
          existingFilter.allowedComparisons = [ 'lt' ];
        }
        _availableFilters[filterKey] = existingFilter;
        adjustGtLtInURLHash( filter );
      }
    // has only been applied once, need to fully re-add the available filter with both comparators
    } else if ( gtltFilterKeys.includes( filterKey ) ) {
      const existingFilter = _allAvailableFilters[filterKey];
      // add the filter back to available filters
      if ( isNotEmpty( existingFilter ) ) {
        existingFilter.allowedComparisons = [ 'gt', 'lt' ];
        _availableFilters[filterKey] = existingFilter;
      }
      adjustGtLtInURLHash( filter );
    // just a regular filter that needs to be removed
    } else {
      // remove from the hash
      removeFromURLHash( filterKey );
      const existingFilter = _allAvailableFilters[filterKey];
      // add the filter back to available filters
      if ( isNotEmpty( existingFilter ) ) {
        _availableFilters[filterKey] = existingFilter;
      }
    }

    setAvailableFilters( _availableFilters );
    // revert to the first page unless we are changing the page
    if ( filterKey !== 'rows' ) {
      if ( isNotEmpty( hash.rows ) ) {
        const rowsDelta = parseInt( hash.rows[1] ) - parseInt( hash.rows[0] );
        encodeURLHash( { rows: [ 0, rowsDelta || 100 ] } );
      } else {
        encodeURLHash( { rows: [ 0, 100 ] } );
      }
    }
    _appliedFilters.splice( appliedFilterIndex, 1 );
    setAppliedFilters( _appliedFilters );

    // for alerting the outside world of the applied filters
    if ( isNotEmpty( externalChangeHandler ) && triggerRefresh ) {
      externalChangeHandler( _appliedFilters || [] );
    }
    if ( isNotEmpty( onRefresh ) && triggerRefresh ) {
      onRefresh();
    }
  };

  const addAppliedFilter = ( filter, triggerRefresh=true ) => {
    const hash = decodeURLHash();
    const _availableFilters = { ...availableFilters };
    const _appliedFilters = [ ...appliedFilters ];
    let duplicateFilter = null;
    const filterKey = getFilterKeyFromMatchString( filter.attribute );

    if ( isNotEmpty( _appliedFilters ) ) {
      duplicateFilter = _appliedFilters.find( f => f.attribute === filter.attribute );
    }

    const dateKeys = [
      'vulnerability_created',
      'last_scanned',
      'tp_last_scanned',
    ];

    // the filters that have date selectors need to be converted to unix timestamps
    let _value = filter.value;
    if ( dateKeys.includes( filterKey ) ) {
      _value = filter.value / 1_000;
    }
    const _filter = { ...filter, value: _value };

    // if there is already another filter with the same key, it is a gt/lt key and we have just used both comparators
    if ( isNotEmpty( duplicateFilter ) && gtltFilterKeys.includes( filterKey ) ) {
      delete _availableFilters[filterKey];
    // this is a gt/lt filter but it is the first time using it, this is where we have to do most of the logic.
    } else if ( gtltFilterKeys.includes( filterKey ) ) {
      const existingFilter = _availableFilters[filterKey];

      if ( isNotEmpty( existingFilter ) ) {
        if ( filter.comparator === '>' ) {
          existingFilter.allowedComparisons = [ 'lt' ];
        } else {
          existingFilter.allowedComparisons = [ 'gt' ];
        }
      }
    // this is any other type of filter
    } else {
      delete _availableFilters[filterKey];
    }
    const _updatedAppliedFilters = [ ...appliedFilters, _filter ];
    setAvailableFilters( _availableFilters );
    applyFilterToHash( _filter );
    setAppliedFilters( _updatedAppliedFilters );
    // revert to the first page unless we are changing the page
    if ( filterKey !== 'rows' ) {
      if ( isNotEmpty( hash.rows ) ) {
        const rowsDelta = parseInt( hash.rows[1] ) - parseInt( hash.rows[0] );
        encodeURLHash( { rows: [ 0, rowsDelta || 100 ] } );
      } else {
        encodeURLHash( { rows: [ 0, 100 ] } );
      }
    }
    // for alerting the outside world of the applied filters
    if ( isNotEmpty( externalChangeHandler ) && triggerRefresh ) {
      externalChangeHandler( _updatedAppliedFilters || [] );
    }
    if ( isNotEmpty( onRefresh ) && triggerRefresh ) {
      onRefresh();
    }
  };

  // when the applied filters get added or removed, remove available filters if they have already been applied
  React.useEffect( () => {
    if ( isEmpty( appliedFilters ) ) {
      if ( variant === 'tagEditor' ) {
        setAvailableFilters( getAllAvailableFiltersForType( 'tag' ) );
      } else {
        setAvailableFilters( getAllAvailableFiltersForType( adjustedRecordType ) );
      }
    }
  }, [ appliedFilters ] );

  const handleKeyDown = ( e ) => {
    if ( isNotEmpty( matches ) && isNotEmpty( currentMatch ) ) {
      const currentIndex = matches.findIndex( match => match.attribute === currentMatch.attribute );
      let nextIndex = currentIndex;

      switch ( e.key ) {
      case 'ArrowDown':
        if ( currentIndex < matches.length - 1 ) {
          nextIndex = currentIndex + 1;
        }
        break;
      case 'ArrowUp':
        if ( currentIndex > 0 ) {
          nextIndex = currentIndex - 1;
        }
        break;
      case 'Enter':
        handleSelectMatch( matches[currentIndex], 'attribute' );
        break;
      default:
        break;
      }
      setCurrentMatch( matches[nextIndex] );
    }
  };

  // hide the builder on esc
  const handleEscKeyDown = ( e ) => {
    if ( isNotEmpty( e ) && e.key === 'Escape' ) {
      hideFilterBuilder();
    }
  };

  // show the builder on shift + f key
  const handleFKeyDown = ( e ) => {
    if ( isNotEmpty( e ) && e.key === 'f' && e.altKey ) {
      e.preventDefault();
      revealFilterBuilder();
    }
  };

  // key binding, whenever the matches change, add event listener to the document to listen for the arrow keys, f, esc
  React.useEffect( () => {
    if ( focusedSection === 'attribute' ) {
      window.addEventListener( 'keydown', handleKeyDown );
      window.addEventListener( 'keydown', handleEscKeyDown );
      window.addEventListener( 'keydown', handleFKeyDown );
    } else {
      window.removeEventListener( 'keydown', handleKeyDown );
      window.removeEventListener( 'keydown', handleEscKeyDown );
      window.removeEventListener( 'keydown', handleFKeyDown );
    }
    return () => window.removeEventListener( 'keydown', handleKeyDown );
  }, [ matches, currentMatch, focusedSection, showFilterBuilder ] );

  const resetFilterBuilder = () => {
    let _filtersPool = { };
    if ( variant === 'tagEditor' ) {
      _filtersPool = getAllAvailableFiltersForType( 'tag' );
    } else if ( isNotEmpty( adjustedRecordType ) ) {
      _filtersPool = getAllAvailableFiltersForType( adjustedRecordType );
    }

    let _matches = Object.values( _filtersPool );
    _matches = _matches.sort( ( a, b ) => matchesSorter( a, b ) );

    setFiltersPool( _filtersPool );
    setSelectedFilter( {} );
    setAvailableComparators( comparatorOptions );
    setComparatorField( {} );
    setFilter( { attribute: '', comparator: '', value: '' } );
    setFilterIsReady( false );
    setFilterIsValid( false );
    setMatches( _matches );
    setCurrentMatch( _matches[0] );
    setFocusedSection( 'attribute' );
  };

  // adds the filter as an applied filter ( a use effect hook also removes this filter from available filters )
  // resets the filter builder
  // encodes the filter into the URL hash
  const handleFinishFilter = () => {
    addAppliedFilter( filter );
    resetFilterBuilder();
    setFocusedSection( 'attribute' );
    setShowFilterBuilder( false );
  };

  const handleSectionFocus = ( section ) => {
    setFocusedSection( section );
  };

  const hideFilterBuilder = () => {
    setShowFilterBuilder( false );
    resetFilterBuilder();
  };

  const revealFilterBuilder = () => {
    setShowFilterBuilder( true );
    setFocusedSection( 'attribute' );

    // need to set the matches to only the aviailable filters
    if ( isNotEmpty( appliedFilters ) ) {
      // eslint-disable-next-line max-len
      const appliedFilterKeys = appliedFilters.map( f => getFilterKeyFromMatchString( f.attribute, variant, adjustedRecordType ) );
      const _allMatches = Object.values( filtersPool );
      let _matches = _allMatches.filter( filter => !appliedFilterKeys.includes( filter.attribute ) );
      _matches = _matches.sort( ( a, b ) => matchesSorter( a, b ) );
      setMatches( _matches );
      setCurrentMatch( _matches[0] );
    }
    window.setTimeout( () => {
      attributeFieldRef?.current?.focus();
    }, 100 );
  };

  return (
    <React.Fragment>
      <div className="filterBuilderWrapper">
        <button
          className="filterBuilderTrigger"
          onClick={ revealFilterBuilder }
        >
          <div className="iconWrapper">
            <InlineSVG type="filterAlt"/>
          </div>
          <span>
            <InlineSVG type="addAlt" />
            Add filter
          </span>
        </button>
        {
          showFilterBuilder &&
          <React.Fragment>
            <div className="filterBuilderShade" onClick={ hideFilterBuilder } />
            <div
              className="filterBuilderForm"
            >
              <div
                // eslint-disable-next-line max-len
                className={ `filterAttributeWrapper ${ isNotEmpty( filter?.attribute ) ? 'hasValue' : ''} ${ attributeIsValid( filter?.attribute ) ? 'isValid' : '' } ${ focusedSection === 'attribute' ? 'isFocused' : '' }` }
              >
                {
                  ( isNotEmpty( matches ) && focusedSection === 'attribute' ) &&
                  <ul className="autoCompleteOptionsWrapper">
                    {
                      matches.map( ( option, index ) => {
                        return (
                          <li key={ index }>
                            <button
                              onClick={ () => handleSelectMatch( option, 'attribute' ) }
                              // eslint-disable-next-line max-len
                              className={ `filterBuilderOption ${option.attribute === currentMatch?.attribute ? 'current' : '' }` }
                            >
                              { option.matchString }
                            </button>
                          </li>
                        );
                      } )
                    }
                  </ul>
                }
                <div className="fieldWrapper included text">
                  <input
                    type="text"
                    placeholder="Add a filter..."
                    value={ filter?.attribute }
                    onChange={ e => handleInputChange( e.target.value, 'attribute' ) }
                    // eslint-disable-next-line max-len
                    className={ `filterAttributeInput ${ focusedSection === 'attribute' ? 'focused' : '' } ${ ( isNotEmpty( matches ) && focusedSection === 'attribute' ) ? 'hasMatches' : '' }` }
                    onFocus={ () => handleSectionFocus( 'attribute' ) }
                    ref={ attributeFieldRef }
                  />
                </div>
              </div>
              {
                isNotEmpty( selectedFilter ) &&
                <div
                  className={ `filterComparatorWrapper ${ comparatorIsValid( filter?.comparator ) ? 'isValid' : '' }` }
                >
                  {/* will show either a span with a single possible option, or a select with many options available */}
                  {/* only a single option */}
                  {
                    (
                      isNotEmpty( availableComparators )
                      && Object.keys( availableComparators ).length === 1
                      && isNotEmpty( filter?.comparator )
                    ) &&
                    <span className="singleComparatorWrapper">{ filter?.comparator }</span>
                  }
                  {/* many options */}
                  {
                    (
                      isNotEmpty( availableComparators )
                      && isNotEmpty( comparatorField )
                      && Object.keys( availableComparators ).length > 1
                    ) &&
                    <Field
                      field={ comparatorField }
                      fields={[ comparatorField ]}
                      originalValue={ comparatorField.originalValue }
                      standAlone
                      standAloneOnChangeCallback={ handleComparatorFieldChange }
                      onFocusCallback={ () => handleSectionFocus( 'comparator' ) }
                    />
                  }
                </div>
              }
              {
                ( isNotEmpty( selectedFilter ) && isNotEmpty( filter?.comparator ) ) &&
                <div
                  className={ `filterValueWrapper ${ isNotEmpty( filter?.value ) ? 'isValid' : '' }` }
                  onClick={ () => handleSectionFocus( 'value' ) }
                >
                  <Field
                    field={ selectedFilter }
                    fields={[ selectedFilter ]}
                    standAlone
                    standAloneOnChangeCallback={ handleValueFieldChange }
                  />
                </div>
              }
              {
                ( filterIsReady && filterIsValid ) &&
                <button
                  className="finishFilterButton"
                  onClick={ handleFinishFilter }
                >
                  <InlineSVG type="addAlt" />
                  Add
                </button>
              }
            </div>
          </React.Fragment>
        }
        <AppliedFilters
          appliedFilters={ appliedFilters }
          setAppliedFilters={ setAppliedFilters }
          appliedFiltersFromRecord={ appliedFiltersFromRecord }
          recordType={ adjustedRecordType }
          variant={ variant }
          removeAppliedFilter={ removeAppliedFilter }
          onRefresh={ onRefresh }
          externalChangeHandler={ externalChangeHandler }
        />
      </div>
    </React.Fragment>
  );
};

export default FilterBuilder;