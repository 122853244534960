import { decodeURLHash, isNotEmpty } from '../../../shared/Utilities';

export const reportTypes = {
  hosts: 'host',
  patches: 'patch',
  vulnerabilities: 'vulnerability',
  instances: 'instance',
  users: 'user',
  // eslint-disable-next-line camelcase
  vulnerability_data: 'vulnerability_data',
};

export const availableColumnsByType = {
  host: [
    { value: 'risk', label: 'risk', disabled: false },
    { value: 'local_name', label: 'name', disabled: true },
    { value: 'num_sensitive_nodes', label: 'sensitive_assets', disabled: true },
    { value: 'product_name', label: 'product_name', disabled: false },
    { value: 'ip_addresses', label: 'addresses', disabled: false },
    { value: 'num_patches', label: 'all_patches', disabled: true },
    { value: 'num_unsuperseded_patches', label: 'unsuperseded_patches', disabled: true },
    { value: 'num_vulnerabilities', label: 'vulnerabilities', disabled: true },
    { value: 'last_scanned', label: 'deepsurface_scanning_status', disabled: true },
  ],
  patch: [
    { value: 'risk', label: 'risk', disabled: false },
    { value: 'vendor', label: 'name', disabled: true },
    { value: 'num_supersedes', label: 'superseded_patches', disabled: true },
    { value: 'num_hosts', label: 'affected_hosts', disabled: true },
    { value: 'num_vulnerabilities', label: 'vulnerabilities', disabled: true },
    { value: 'description', label: 'description', disabled: false },
  ],
  vulnerability: [
    { value: 'risk', label: 'risk', disabled: false },
    { value: 'identifier', label: 'name', disabled: true },
    { value: 'exploit_status', label: 'exploit_status', disabled: true },
    { value: 'cvss_base_score', label: 'cvss_score', disabled: true },
    { value: 'num_hosts', label: 'affected_hosts', disabled: true },
    { value: 'num_patches', label: 'all_patches', disabled: true },
    { value: 'num_unsuperseded_patches', label: 'unsuperseded_patches', disabled: true },
    { value: 'description', label: 'description', disabled: false },
  ],
  signature: [
    { value: 'risk', label: 'risk', disabled: false },
    { value: 'scanner', label: 'scanner_signature', disabled: true },
    { value: 'title', label: 'name', disabled: true },
    { value: 'num_hosts', label: 'hosts', disabled: true },
    { value: 'num_vulnerabilities', label: 'vulns', disabled: true },
  ],
  user: [
    { value: 'risk', label: 'risk', disabled: false },
    { value: 'name', label: 'name', disabled: true },
    { value: 'active_hosts', label: 'active_hosts', disabled: true },
    { value: 'domain_groups', label: 'domain_groups', disabled: true },
  ],
  path: [
    { value: 'risk', label: 'risk', disabled: false },
    { value: 'name', label: 'name', disabled: true },
    { value: 'steps', label: 'steps', disabled: true },
  ],
};

export const defaultRemdiationItems = {
  host: {},
  patch: {},
  vulnerability: {},
};

export const remediationItemsKey = type => {
  if ( type === 'instance' || type === 'vulnerability_data' ) {
    const hash = decodeURLHash();
    let groupType = hash.group_type;

    if ( groupType === 'patch_cumulative' ) {
      groupType = 'patch';
    }

    if ( isNotEmpty( groupType ) ) {
      return groupType;
    }
    return 'host';
  }
  return type;
};

export const planOptionsForReportType = {
  host: [ 'remediation', 'acceptedRisk' ],
  patch: [ 'remediation' ],
  vulnerability: [ 'remediation', 'acceptedRisk' ],
  user: [ ],
};