/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  cvssScoreToRating,
  debounce,
  formatNumber,
  formatRiskReduction,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  reportTypeDisplayName,
  riskToRating,
} from '../../../../../shared/Utilities';
import DataTable from '../../../../../shared/DataTable';
import RatingBadge from '../../../../../shared/RatingBadge';
import RiskReduction from '../../../../../shared/RiskReduction';
import ExploitStatus from '../../../../../shared/ExploitStatus';
import InlineSVG from '../../../../../shared/InlineSVG';

import './PriorityRecords.scss';
// import Legend from '../../../../../shared/Charts/Legend';
import Bar from '../../../../../shared/Charts/Bar';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import RecordList from '../../../../../shared/RecordList';
import { RiskContext } from '../../../../../Contexts/Risk';
import { multipleOrderByOptions } from '../../shared';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import { defaultOrderByString, defaultPatchURL, defaultRowsString } from '../../../../App/Routing';
import EmptyState from '../../../../../shared/EmptyState';
import { getThemeColor } from '../../../../../shared/Themes';

const PriorityRecords = ( {
  settings,
  data,
  adjustSVGAspectRatio,
  svgAspectRatio,
  svgContainerRef,
} ) => {

  const [ globalRisk ] = React.useContext( RiskContext );

  const [ records, setRecords ] = React.useState( null );
  const [ tableData, setTableData ] = React.useState( null );
  const [ listData, setListData ] = React.useState( null );
  const [ chartData, setChartData ] = React.useState( null );
  // const [ chartLegendData, setChartLegendData ] = React.useState( null );
  const [ yMax, setYMax ] = React.useState( null );

  const [ noRisk, setNoRisk ] = React.useState( false );
  const [ hasCVSS, setHasCVSS ] = React.useState( false );
  const [ hasUser, setHasUser ] = React.useState( false );
  const [ hasHostType, setHasHostType ] = React.useState( false );

  const [ primaryOrderBy, setPrimaryOrderBy ] = React.useState( 'risk' );
  const [ loading, setLoading ] = React.useState( true );

  const keyToAttrMap = {
    hosts: 'num_hosts',
    vulnerabilities: 'num_vulnerabilities',
    cvss: 'cvss_base_score',
    exploit: 'exploit_status',
  };

  const nameLink = ( item, type ) => {
    // eslint-disable-next-line max-len
    let href = `#.=risk_insight&report=${type}&item=${item.id}${defaultOrderByString}${defaultRowsString}`;

    if ( type === 'patches' ) {
      // eslint-disable-next-line max-len
      href = `${defaultPatchURL}&item=${item.id}`;
    }
    return (
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={ href }
      >
        { reportTypeDisplayName( item, type ) }
        <InlineSVG type="newTabLink" version="primary" />
      </a>
    );
  };

  React.useEffect( () => {
    setLoading( true );
    if ( isNotEmpty( data ) && isNotEmpty( settings ) ) {
      if ( settings.report_type === 'users' && isNotEmpty( data.results ) ) {
        setRecords( data.results );
        setLoading( false );
      } else {
        setRecords( data );
        setLoading( false );
      }
    } else {
      setLoading( false );
    }
  }, [ settings, data, globalRisk ] );

  React.useEffect( () => {
    if (
      isNotEmpty( records )
      && itemIsArray( records )
      && isNotEmpty( settings )
      && isNotEmpty( settings.report_type )
    ) {
      if ( settings.version === 'table' ) {

        let _showRisk = false;
        let _showCVSS = false;
        let _showUser = false;
        let _showHostType = false;

        if ( 'include_risk' in settings && settings.include_risk === true ) {
          _showRisk = true;
        }
        if ( settings?.include_cvss === true ) {
          _showCVSS = true;
        }
        if ( settings?.include_user === true ) {
          _showUser = true;
        }
        if ( settings?.include_os_type === true ) {
          _showHostType = true;
        }

        const _tableData = [];

        records.map( ( item, index ) => {
          if ( index < settings.item_count ) {
            if ( settings.report_type === 'hosts' ) {
              const _row = {};

              if ( settings?.include_risk ) {
                _row.risk = <React.Fragment>
                  <RatingBadge altVersion rating={ riskToRating( item.filtered_risk ) }/>
                  <RiskReduction item={item} riskType="filtered_risk" />
                </React.Fragment>;
              }
              _row.name = nameLink( item, settings.report_type );
              if ( settings?.include_user ) {
                // eslint-disable-next-line camelcase
                _row.last_logged_in_user = isEmpty( item.last_logged_user ) ? 'N/A' : item.last_logged_user;
              }
              if ( settings?.include_os_type ) {
                // eslint-disable-next-line camelcase
                _row.host_type = item.host_os_type;
              }
              _row.vulnerabilities = formatNumber( item.num_vulnerabilities );

              _tableData.push( _row );
            }
            if ( settings.report_type === 'patches' ) {
              const _row = {};

              if ( settings?.include_risk ) {
                _row.risk = <React.Fragment>
                  <RatingBadge altVersion rating={ riskToRating( item.filtered_risk ) }/>
                  <RiskReduction item={item} riskType="filtered_risk" />
                </React.Fragment>;
              }
              _row.name = nameLink( item, settings.report_type );
              _row.hosts = formatNumber( item.num_hosts );
              _row.vulnerabilities = formatNumber( item.num_vulnerabilities );
              _row.description = item.description;

              _tableData.push( _row );
            }
            if ( settings.report_type === 'vulnerabilities' ) {
              const _row = {};

              if ( settings?.include_risk ) {
                _row.risk = <React.Fragment>
                  <RatingBadge altVersion rating={ riskToRating( item.filtered_risk ) }/>
                  <RiskReduction item={item} riskType="filtered_risk" />
                </React.Fragment>;
              }
              if ( settings?.include_cvss ) {
                _row['CVSS'] = <RatingBadge
                  rating={ cvssScoreToRating( item.cvss_base_score ) }
                  // eslint-disable-next-line max-len
                  alternateText={ `CVSS Score: ${item.cvss_base_score}`}
                  elementClass="cvssRatingBadge"
                />;
              }
              _row.name = nameLink( item, settings.report_type );
              // eslint-disable-next-line camelcase
              _row.exploit_status = <ExploitStatus status={item.exploit_status} fullVersion={false} />;
              _row.hosts = formatNumber( item.num_hosts );
              _row.description = item.description;

              _tableData.push( _row );
            }
            if ( settings.report_type === 'users' ) {
              _tableData.push(
                {
                  risk: <React.Fragment>
                    <RatingBadge altVersion rating={ riskToRating( item.risk ) }/>
                    <RiskReduction item={item} riskType="risk" />
                  </React.Fragment>,
                  name: nameLink( item, settings.report_type ),
                  // eslint-disable-next-line camelcase
                  recently_accessed_hosts: formatNumber( item?.active_hosts?.length || 0 ),
                  // eslint-disable-next-line camelcase
                  domain_groups: formatNumber( item?.domain_groups?.length || 0 ),
                },
              );
            }
          }
        } );

        setTableData( _tableData );

        setNoRisk( !_showRisk );
        setHasCVSS( _showCVSS );
        setHasUser( _showUser );
        setHasHostType( _showHostType );
      }
      if ( settings.version === 'list' ) {
        const _listData = [];
        records.map( ( r, i ) => {
          if ( i < settings.item_count ) {
            _listData.push( r );
          }
        } );

        // endpoint doesn't allow asking of risk type, commenting out for now
        // const _riskType = settings.report_type === 'patch' ? 'cumulative_risk' : 'direct_risk';

        // eslint-disable-next-line camelcase
        let order_by = 'filtered_risk';

        if ( isNotEmpty( settings ) && 'order_by' in settings ) {
          ( { order_by } = settings );
        }

        if ( multipleOrderByOptions.includes( order_by ) ) {
          // eslint-disable-next-line camelcase
          const [ primaryKey ] = order_by.split( '_' );
          // eslint-disable-next-line camelcase
          order_by = keyToAttrMap[primaryKey];
        }
        setPrimaryOrderBy( order_by );
        setListData( _listData );
      }
      // we want the y axis to be whatever we are ordering by, this is often risk, but not always, need to figure it
      // out and setup the chart accordingly
      if ( settings.version === 'barchart' ) {

        let _riskType = 'risk';

        // eslint-disable-next-line camelcase
        let order_by = _riskType;

        const _chartData = {};

        if ( isNotEmpty( settings ) && 'order_by' in settings ) {
          ( { order_by } = settings );
        }

        if ( multipleOrderByOptions.includes( order_by ) ) {
          // eslint-disable-next-line camelcase
          const [ primaryKey ] = order_by.split( '_' );
          // eslint-disable-next-line camelcase
          order_by = keyToAttrMap[primaryKey];
        }
        setPrimaryOrderBy( order_by );

        let _yMax = 0;

        const [ first ] = records;

        if ( settings.report_type === 'patches' ) {
          if ( first.filtered_risk ) {
            _riskType = 'filtered_risk';
          } else if ( first.risk ) {
            _riskType = 'risk';
          } else {
            _riskType = 'direct_risk';
          }
        } else if ( first.filtered_risk ) {
          _riskType = 'filtered_risk';
        } else {
          _riskType = 'risk';
        }

        const _legendData = {
          critical: { key: 'critical', label: 'Critical', total: 0, fill: getThemeColor( '--critical' ) },
          high: { key: 'high', label: 'High', total: 0, fill: getThemeColor( '--high' ) },
          moderate: { key: 'moderate', label: 'Moderate', total: 0, fill: getThemeColor( '--moderate' ) },
          low: { key: 'low', label: 'Low', total: 0, fill: getThemeColor( '--low' ) },
          minimal: { key: 'minimal', label: 'Minimal', total: 0, fill: getThemeColor( '--minimal' ) },
        };

        records.map( ( record, index ) => {
          if ( isNotEmpty( record ) && index < settings.item_count ) {
            const rating = riskToRating( record[_riskType] );
            const reduction = record[_riskType];
            if ( reduction > _yMax ) {
              _yMax = reduction;
            }
            _chartData[record.id] = {
              key: record.id,
              original: record,
              label: reportTypeDisplayName( record, settings.report_type ),
              value: record[_riskType],
              fill: getThemeColor( rating ),
            };

            _legendData[rating].total += 1;
          }
        } );
        setYMax( _yMax );
        // setChartLegendData( _legendData );
        setChartData( _chartData );
      }
    }
  }, [ records, settings ] );

  React.useEffect( () => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {
      adjustSVGAspectRatio();
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 100 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, chartData, yMax ] );

  return (
    <React.Fragment>
      <EmptyLoading
        loading={ loading }
        loadingMessage="Loading records..."
        payload={ records }
        emptyMessage="No records found"
      />
      {
        ( isNotEmpty( records ) && isNotEmpty( settings ) && isNotEmpty( settings.version ) ) &&
        <React.Fragment>
          {
            ( settings.version === 'table' && isNotEmpty( tableData ) ) &&
            <DataTable
              data={tableData}
              // eslint-disable-next-line max-len
              elementClass={ `tableWidgetWrapper ${settings?.report_type} riskClass--${ noRisk ? 'no_risk' : 'has_risk' } cvssClass--${ hasCVSS ? 'has_cvss' : 'no_cvss' } hostTypeClass--${ hasHostType ? 'has_host_type' : 'no_host_type' } userClass--${ hasUser ? 'has_user' : 'no_user' }` }
            />
          }
          {
            ( settings.version === 'list' && isNotEmpty( listData ) ) &&
            <RecordList
              orderBy={primaryOrderBy}
              records={listData}
              reportType={settings?.report_type}
            />
          }
          {
            ( settings.version === 'barchart' && isNotEmpty( chartData ) ) &&
            <React.Fragment>
              <div className="priorityChartAndAxisWrapper" ref={svgContainerRef} >
                <YAxisLabels yMax={ yMax } ticFormatter={ ticValue => formatRiskReduction( ticValue, globalRisk ) } />
                <Bar
                  data={chartData}
                  elementClass={ `chartWidgetWrapper ${settings?.report_type}` }
                  svgAspectRatio={svgAspectRatio}
                />
              </div>
              {/* <Legend legendData={chartLegendData} /> */}
            </React.Fragment>

          }
        </React.Fragment>
      }
      { ( settings.report_type === 'users' && isEmpty( listData ) ) && <EmptyState message="No records found" /> }
    </React.Fragment>
  );
};

export default PriorityRecords;