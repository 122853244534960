/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isEmpty, isNotEmpty, itemIsNumber, riskContributionKeys } from '../../../shared/Utilities';
import InlineSVG from '../../../shared/InlineSVG';
import EmptyLoading from '../../../shared/EmptyLoading';
import {
  afterFetchAttributeKeys,
  directAttributeKeys,
  directNumAttributeKeys,
  noCountNeeded,
  thirdPartyAttributes,
} from '../shared.js';
import SectionContent from './SectionContent';

import './CollapsibleSection.scss';

const CollapsibleSection = ( {
  sectionKey,
  sectionInfo,
  record,
  recordInstanceData,
  recordType,
  relatedPaths,
  riskType='direct_risk',
  prefetchedData=null,

  // recordCard variables
  recordCardRecord,
  setRecordCardRecord,
  recordCardType,
  setRecordCardType,
  showRecordCard,
  setShowRecordCard,
} ) => {

  const [ collapsed, setCollapsed ] = React.useState( true );
  const [ hasFetched, setHasFetched ] = React.useState( false );
  const [ sectionBodyData, setSectionBodyData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ count, setCount ] = React.useState( false );
  const [ superseded, setSuperseded ] = React.useState( 'unsuperseded' );

  // if the section is supposed to be open by default, set that first
  React.useEffect( ( ) => {
    if ( isNotEmpty( sectionInfo ) && sectionInfo.open === true ) {
      setCollapsed( false );
    }
  }, [ sectionInfo ] );

  // depending on the section, we need to get the count differently
  const getCount = ( record, sectionKey, sectionBodyData ) => {
    if (
      ( sectionKey === 'attack_scenarios' || sectionKey === 'users_at_risk' || sectionKey === 'sensitive_assets' )
      && isNotEmpty( sectionBodyData ) ) {
      setCount( sectionBodyData.length );
    // eslint-disable-next-line max-len
    } else if ( ( sectionKey === 'sensitive_nodes' || sectionKey === 'sensitive_nodes_at_risk' ) && isNotEmpty( sectionBodyData ) ) {
      const assetsCount = sectionBodyData.assets?.length || 0;
      const exposedCount = sectionBodyData.exposed?.length || 0;
      setCount( assetsCount + exposedCount );
    } else if ( thirdPartyAttributes.includes( sectionKey ) ) {
      setCount( 0 );
    } else if ( directNumAttributeKeys.includes( sectionKey ) ) {
      if ( sectionKey === 'num_patches' ) {
        if ( superseded === 'unsuperseded' ) {
          if ( isNotEmpty( record.num_unsuperseded_patches ) ) {
            setCount( record.num_unsuperseded_patches );
          } else {
            setCount( 0 );
          }
        } else if ( isNotEmpty( record.num_patches ) ) {
          setCount( record.num_patches );
        } else {
          setCount( 0 );
        }

      } else if ( isNotEmpty( record[sectionKey] ) ) {
        setCount( record[sectionKey] );

      } else {
        setCount( 0 );
      }
    } else if ( directAttributeKeys.includes( sectionKey ) ) {
      if ( isNotEmpty( record[sectionKey] ) ) {
        setCount( record[sectionKey].length );
      } else {
        setCount( 0 );
      }
    } else if ( afterFetchAttributeKeys.includes( sectionKey ) && isNotEmpty( sectionBodyData ) ) {
      setCount( sectionBodyData.length );
    } else if ( afterFetchAttributeKeys.includes( sectionKey ) ) {
      setCount( <span className="expandCount">expand to view</span> );
    } else {
      setCount( 0 );
    }
  };

  // the actual fetch functionality pulled out into an async function
  const getData = async ( options={} ) => {
    let _sectionBodyData;
    // need to wait for the promise to resolve
    if ( sectionInfo.isPromise ) {
      // eslint-disable-next-line max-len
      _sectionBodyData = await sectionInfo.getData( record, recordInstanceData, recordType, relatedPaths, riskType, options );
    } else {
      _sectionBodyData = sectionInfo.getData( record, recordInstanceData, recordType, relatedPaths, riskType, options );
    }
    setSectionBodyData( _sectionBodyData );
    setHasFetched( true );
    setLoading( false );
  };

  const handleSupersededClick = async ( superseded ) => {
    const _supersededValue = superseded === 'null' ? null : superseded;
    await getData( { superseded: _supersededValue } );
    setSuperseded( superseded );
  };

  // when the section is opened, check to see if the data has been loaded yet and if it has not, do that now.
  React.useEffect( ( ) => {
    setLoading( true );
    // if ( ( recordType === 'host' || recordType === 'vulnerability' ) && sectionKey === 'num_patches' ) {
    //   getData( { superseded } );
    // } else
    if ( isNotEmpty( prefetchedData ) ) {
      setSectionBodyData( prefetchedData );
      setHasFetched( true );
      setLoading( false );
    } else if ( recordType === 'path' && isNotEmpty( relatedPaths ) ) {
      getData();
    } else if ( !collapsed && isEmpty( sectionBodyData ) && recordType === 'user' && !hasFetched ) {
      getData();
    } else if ( !collapsed && isEmpty( sectionBodyData ) && recordType === 'third_party' && !hasFetched ) {
      getData();
    } else if ( !collapsed && isEmpty( sectionBodyData ) && isNotEmpty( recordInstanceData ) && !hasFetched ) {
      getData();
    // eslint-disable-next-line max-len
    } else if ( !collapsed && isEmpty( sectionBodyData ) && riskContributionKeys.includes( sectionKey ) && !hasFetched ) {
      getData();
    }
  }, [ collapsed, relatedPaths, recordType, recordInstanceData, sectionInfo, sectionKey ] );

  // when the item comes in with the section info, we might be able to set the count on page load before fetching
  // otherwise we can set it after the data comes back
  React.useEffect( ( ) => {
    getCount( record, sectionKey, sectionBodyData );
  }, [ record, sectionKey, sectionBodyData, recordInstanceData, superseded ] );

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( record )
          && isNotEmpty( sectionInfo )
        ) &&
        <div className={ `collapsibleSectionWrapper ${collapsed ? 'collapsed' : ''}`}>
          <div
            className="collapsibleSectionHeader"
            onClick={ () => setCollapsed( !collapsed ) }
          >
            <div className="headerLeft">
              { sectionInfo.icon }
              {
                ( sectionInfo.needsAlert && isNotEmpty( sectionBodyData ) ) &&
                <span className="statWarning" />
              }
              <h3>{ sectionInfo.label }</h3>
              {
                ( sectionKey === 'num_patches' && ( recordType === 'host' || recordType === 'vulnerability' ) ) &&
                <div className={ `${superseded} toggleWrapper ${window.IS_DARK_MODE ? 'darkMode' : ''}` }>
                  <button
                    onClick={ e => {
                      e.stopPropagation();
                      handleSupersededClick( 'unsuperseded' );
                    } }
                    className={ `${superseded === 'unsuperseded' ? 'toggled' : ''} toggleButton` }
                  >
                    <span>Unsuperseded</span>
                  </button>
                  <button
                    onClick={ e => {
                      e.stopPropagation();
                      handleSupersededClick( 'null' );
                    } }
                    className={ `${superseded === 'null' ? 'toggled' : ''} toggleButton` }
                  >
                    <span>All</span>
                  </button>
                </div>
              }
            </div>
            <div className="headerRight">
              {
                !noCountNeeded.includes( sectionKey ) &&
                <strong className="sectionCount">
                  { itemIsNumber( count ) ? formatNumber( count ) : count }
                </strong>
              }
              <span className="carretWrapper">
                <InlineSVG type="carretUp"/>
              </span>
            </div>
          </div>
          <div className="collapsibleSectionBody">
            <EmptyLoading
              loading={ loading }
              payload={ sectionBodyData }
              emptyMessage={ `No ${sectionInfo.label}`}
            />
            {
              isNotEmpty( sectionBodyData ) &&
              <SectionContent
                sectionCount={count}
                sectionKey={sectionKey}
                sectionData={sectionBodyData}
                recordType={recordType}
                record={record}
                relatedPaths={relatedPaths}
                riskType={riskType}
                // setHoveredListItemID={ setHoveredListItemID }
                // setHoveredListItemType={ setHoveredListItemType }
                // setHoveredListItemRating={ setHoveredListItemRating }
                superseded={superseded}
                setSuperseded={setSuperseded}

                // recordCard variables
                recordCardRecord={recordCardRecord}
                setRecordCardRecord={setRecordCardRecord}
                recordCardType={recordCardType}
                setRecordCardType={setRecordCardType}
                showRecordCard={showRecordCard}
                setShowRecordCard={setShowRecordCard}
              />
            }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default CollapsibleSection;