/* **************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { decodeURLHash, getDimensionsAndOffset, isEmpty, isNotEmpty, userDisplayName } from '../../shared/Utilities';
import RatingBadge from '../../shared/RatingBadge';
import InlineSVG from '../../shared/InlineSVG';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import UserDetails from '../../shared/UserDetails';
import { NavigationContext } from '../../Contexts/Navigation';
import { isBetaPage, isBetaRoute, isNewPage } from '../App/Routing';

import './RouteSubNavigation.scss';
import { pagesRemovedInDemoMode } from '../App/DemoModeContent';

const MenuContent = ( {
  handleMouseLeave,
  pageIsCurrent,
  item,
  currentUser,
  subNavigationItems,
  subNavIsCurrent,
  renderContext,
  onSelectCallback,
} ) => {

  const handleClick = () => {
    handleMouseLeave();
    onSelectCallback();
  };

  const handleActionClick = fn => {
    onSelectCallback();
    fn();
  };

  return (
    <React.Fragment>
      {
        renderContext === 'leftNav' &&
        <div
          className={`allowMenuHover routeNavigationHeader ${ pageIsCurrent( item ) ? 'current' : ''} ${item.slug}`}
          onClick={ handleClick }
        >
          <a
            href={ item.slug === 'user_menu' ? `#.=setup&page=users&selected_record=${currentUser.id}`: item.link }
            className="allowMenuHover"
          >
            {
              item.slug === 'user_menu'
                ? <UserDetails user={currentUser} forLeftNav elementClass="allowMenuHover" />
                : <InlineSVG type={ item.iconKey } elementClass="allowMenuHover" />
            }
            <h2
              className="allowMenuHover"
            >
              {
                item.slug === 'user_menu'
                  ? userDisplayName( currentUser )
                  : item.label
              }
              {
                isBetaRoute( item.slug ) &&
                <RatingBadge rating="beta" elementClass="allowMenuHover" />
              }
            </h2>
          </a>
        </div>
      }
      <ul
        className="allowMenuHover"
      >
        {
          Object.values( subNavigationItems ).map( ( subNavItem, snIndex ) => {
            // this item has nested navigation under it, this is the deepest it will get
            if ( isNotEmpty( subNavItem.items ) ) {
              return <React.Fragment key={snIndex} >
                <div
                  // eslint-disable-next-line max-len
                  className={ `routeNavigationItemsWrapper allowMenuHover border--${subNavItem.borderClass || ''}` }
                >
                  <li className="divider allowMenuHover">
                    <label className="allowMenuHover" >{ subNavItem.label }</label>
                  </li>
                  {
                    Object.values( subNavItem.items ).map( ( subSubNavItem, ssnIndex ) => {
                      if ( !pagesRemovedInDemoMode.includes( subSubNavItem.slug ) ) {
                        return <li
                          className="routeNavigationItem allowMenuHover"
                          key={ssnIndex}
                          onClick={ handleClick }
                        >
                          {
                            isNotEmpty( subSubNavItem.action )
                              ? <button
                                className="routeNavActionButton allowMenuHover"
                                onClick={ () => handleActionClick( subSubNavItem.action ) }
                              >
                                { subSubNavItem.label }
                              </button>
                              : <React.Fragment>
                                {
                                  subNavIsCurrent( subSubNavItem )
                                    ? <div
                                      className="notLink allowMenuHover"
                                    >
                                      <span className="allowMenuHover" >{ subSubNavItem.label }</span>
                                      {
                                        isNewPage( item.slug, subSubNavItem.slug ) &&
                                        <RatingBadge rating="new" elementClass="allowMenuHover" />
                                      }
                                      {
                                        isBetaPage( item.slug, subSubNavItem.slug ) &&
                                        <RatingBadge rating="beta" elementClass="allowMenuHover" />
                                      }
                                    </div>
                                    : <a href={ subSubNavItem.link } className="allowMenuHover" >
                                      <span className="allowMenuHover" >{ subSubNavItem.label }</span>
                                      {
                                        isNewPage( item.slug, subSubNavItem.slug ) &&
                                        <RatingBadge rating="new" elementClass="allowMenuHover" />
                                      }
                                      {
                                        isBetaPage( item.slug, subSubNavItem.slug ) &&
                                        <RatingBadge rating="beta" elementClass="allowMenuHover" />
                                      }
                                    </a>
                                }
                              </React.Fragment>
                          }
                        </li>;
                      }


                    } )
                  }
                </div>

              </React.Fragment>;
            }
            if ( !pagesRemovedInDemoMode.includes( subNavItem.slug ) && subNavItem.isHidden !== true ) {
              // this item is itself a page, make it a link
              return <li
                className={ `routeNavigationItem allowMenuHover rootLevel border--${subNavItem.borderClass || ''}` }
                key={snIndex}
                onClick={ handleClick }
              >
                {
                  subNavIsCurrent( subNavItem )
                    ? <div
                      className="notLink allowMenuHover"
                    >
                      <span className="allowMenuHover" >{ subNavItem.label }</span>
                      {
                        isNewPage( item.slug, subNavItem.slug ) &&
                        <RatingBadge rating="new" elementClass="allowMenuHover" />
                      }
                      {
                        isBetaPage( item.slug, subNavItem.slug ) &&
                        <RatingBadge rating="beta" elementClass="allowMenuHover" />
                      }
                    </div>
                    : <React.Fragment>
                      {
                        isNotEmpty( subNavItem.action )
                          ? <div
                            className="notLink withAction allowMenuHover"
                            onClick={ () => handleActionClick( subNavItem.action ) }
                          >
                            <span className="allowMenuHover" >{ subNavItem.label }</span>
                          </div>
                          : <a href={ subNavItem.link } className="allowMenuHover" >
                            <span className="allowMenuHover" >{ subNavItem.label }</span>
                            {
                              isNewPage( item.slug, subNavItem.slug ) &&
                              <RatingBadge rating="new" elementClass="allowMenuHover" />
                            }
                            {
                              isBetaPage( item.slug, subNavItem.slug ) &&
                              <RatingBadge rating="beta" elementClass="allowMenuHover" />
                            }
                          </a>
                      }
                    </React.Fragment>
                }
              </li>;
            }
          } )
        }
      </ul>
    </React.Fragment>
  );
};


const RouteSubNavigation = ( {
  item,
  currentRoute,
  currentPage,
  pageIsCurrent,
  shouldShowMenu,
  passedInSubNavigationItems=null,
  renderContext='leftNav',
  onSelectCallback=() => {},
  // defaultSettingsOpen=false,
} ) => {

  const [ subNavigationItems, setSubNavigationItems ] = React.useState( null );
  const [ menuTop, setMenuTop ] = React.useState( -8 );
  const [ menuHeight, setMenuHeight ] = React.useState( 0 );
  const [ currentUser ] = React.useContext( CurrentUserContext );
  const [ menuStyle, setMenuStyle ] = React.useState( {} );

  const [
    showMenuFor,
    setShowMenuFor,
    routing,
    ,
    customReports,
    ,
    ,
  ] = React.useContext( NavigationContext );

  const svgWidth = 16;

  const menuContainerRef = React.useRef( null );

  React.useEffect( () => {
    if ( item.slug !== 'user_menu' && isEmpty( passedInSubNavigationItems ) ) {
      if ( isNotEmpty( item ) && isNotEmpty( item.items ) ) {

        const _items = { ...item.items };

        setSubNavigationItems( _items );
      }
    } else if ( isNotEmpty( passedInSubNavigationItems ) ) {
      setSubNavigationItems( passedInSubNavigationItems );
    }
  }, [ item, passedInSubNavigationItems, routing, customReports, renderContext ] );

  // if this is being rendered within the pageSelector, we need to find the correct positioning
  React.useEffect( () => {
    if ( renderContext === 'pageSelector' ) {
      const triggerEl = document.getElementById( 'pageSelectorTrigger' );

      if ( isNotEmpty( triggerEl ) ) {
        const triggerDimensions = getDimensionsAndOffset( triggerEl );

        setMenuStyle( {
          top: triggerDimensions.top + triggerDimensions.height + 8,
          left: triggerDimensions.left,
          width: triggerDimensions.width + 48,
        } );
      }
    }
  }, [ renderContext ] );

  // when the menu is visible, grab the dimensions in order to position the menu, and also draw the hover polygon
  React.useEffect( ( ) => {
    if (
      isNotEmpty( menuContainerRef )
      && isNotEmpty( menuContainerRef.current )
      && isNotEmpty( subNavigationItems )
      && shouldShowMenu()
      && renderContext !== 'pageSelector'
    ) {
      const spacing = 16;
      setTimeout( () => {
        const dimensions = getDimensionsAndOffset( menuContainerRef.current );

        setMenuHeight( dimensions.height );

        // if the menu would extend below the screen, need to adjust its placement so that it is 1em up from the bottom
        if ( ( dimensions.top + dimensions.height ) >= ( window.innerHeight + spacing ) ) {
          const diff = ( dimensions.top + dimensions.height ) - ( window.innerHeight - spacing );
          setMenuTop( -diff );
        }
      }, 10 );
    }
  }, [ menuContainerRef, subNavigationItems, showMenuFor, renderContext ] );

  const goToItemLink = () => {
    setShowMenuFor( null );
    onSelectCallback();
    if ( item.slug === 'user_menu' && isNotEmpty( currentUser ) ) {
      window.location.href = `#.=setup&page=users&selected_record=${currentUser.id}`;
    } else if ( item.slug === 'user_menu' ) {
      window.location.href = '#.=setup&page=users';
    } else {
      window.location.href = item.link;
    }
  };

  const subNavIsCurrent = subNavItem => {
    if ( subNavItem.action ) {
      return false;
    }
    const hash = decodeURLHash();
    if ( currentPage === 'custom' ) {
      return hash.item === subNavItem.isCurrent && item.slug === currentRoute;
    }
    return subNavItem.isCurrent === currentPage && item.slug === currentRoute;
  };

  const handleMouseEnter = () => {
    if ( renderContext === 'leftNav' ) {
      setShowMenuFor( item.slug );
    }
  };

  const handleMouseLeave = () => {
    if ( renderContext === 'leftNav' ) {
      setShowMenuFor( null );
    }
  };

  return (
    <React.Fragment>
      {
        <React.Fragment>
          {
            ( isNotEmpty( subNavigationItems ) && renderContext === 'leftNav' ) &&
            <svg
              className="routeNavigationMenuHoverPolygon allowMenuHover"
              viewBox={ `0 0 ${shouldShowMenu() ? svgWidth : 36 } ${ shouldShowMenu() ? menuHeight : 36}` }
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              style={ {
                left: 0,
                top: shouldShowMenu() ? menuTop : 0,
                width: shouldShowMenu() ? 65 : 16 * 3.5,
                height: shouldShowMenu() ? menuHeight : 16 * 3.5,
              } }
            >
              {
                shouldShowMenu()
                  ? <polygon
                    // eslint-disable-next-line max-len
                    points={ `0,${ menuTop === 0 ? '0' : 0 - menuTop } 4.85,${ menuTop === 0 ? '0' : 0 - menuTop } 9.5,0 16,0 16,${menuHeight} 14,${menuHeight} 9,${menuTop === 0 ? 16 * 4.5 : ( 0 - menuTop ) + 16 * 4.5} 0,${menuTop === 0 ? 16 * 3.5 : ( 0 - menuTop ) + 16 * 3.5} 0,${ menuTop === 0 ? '0' : 0 - menuTop }` }
                    fill="#FFF"
                    fillOpacity={ 0 }
                    onMouseEnter={ () => setShowMenuFor( item.slug ) }
                    onMouseLeave={ () => setShowMenuFor( null ) }
                    onClick={ goToItemLink }
                    className="allowMenuHover"
                  />
                  : <rect
                    x={0}
                    y={0}
                    width={36}
                    height={36}
                    fill="#fff"
                    fillOpacity={ 0 }
                    onMouseEnter={ () => setShowMenuFor( item.slug ) }
                    onMouseLeave={ () => setShowMenuFor( null ) }
                    onClick={ goToItemLink }
                    className="allowMenuHover"
                  />
              }
            </svg>
          }
          {
            isNotEmpty( subNavigationItems ) &&
            <div
              // eslint-disable-next-line max-len
              className={ `allowMenuHover routeNavigationMenu ${shouldShowMenu() ? 'visible' : ''} ${renderContext} ${window.IS_DARK_MODE ? 'darkMode' : ''}` }
              ref={menuContainerRef}
              onMouseEnter={ handleMouseEnter }
              onMouseLeave={ handleMouseLeave }
              style={ {
                ...menuStyle,
              } }
            >
              <MenuContent
                handleMouseEnter={ handleMouseEnter }
                handleMouseLeave={ handleMouseLeave }
                pageIsCurrent={ pageIsCurrent }
                item={ item }
                currentUser={ currentUser }
                subNavigationItems={ subNavigationItems }
                subNavIsCurrent={ subNavIsCurrent }
                renderContext={ renderContext }
                onSelectCallback={ onSelectCallback }
              />
            </div>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RouteSubNavigation;