/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import Modal from '../../../../shared/Modal';

import React from 'react';

import {
  isNotEmpty,
  recordSorter,
  reportTypeDisplayName,
  getExternalUsers,
  removeFromURLHash,
  isEmpty,
  itemIsArray,
} from '../../../../shared/Utilities';
import { typeForTask } from '../Shared';

import './style.scss';

import ModalBody from './ModalBody';
import ModalAction from './ModalAction';
import { makeRequest } from '../../../../../legacy/io';
import { getRecords } from '../../../../shared/RecordCache';

const RemediationExportModal = ( {
  show,
  setShow,
  plan,
  activeIntegrations,
} ) => {

  const [ users, setUsers ] = React.useState( null );
  const [ tasks, setTasks ] = React.useState( null );
  const [ loadingTasks, setLoadingTasks ] = React.useState( false );
  const [ hasFetchedTasks, setHasFetchedTasks ] = React.useState( false );

  const [ taskMappings, setTaskMappings ] = React.useState( { } );
  const [ selectedTaskIDs, setSelectedTaskIDs ] = React.useState( [] );

  const [ externalUsers, setExternalUsers ] = React.useState( null );

  // gets all necessary records for the tasks and formats it correctly
  const fetchAndFormatTaskRecordData = async ( externalUsers, tasks, _users, activeIntegrations ) => {
    const _formatted = [];
    const hostIDs = [];
    const patchIDs = [];
    const vulnerabilityIDs = [];

    let fetchedHosts, fetchedPatches, fetchedVulnerabilities;

    tasks.map( task => {
      if ( typeForTask( task ) === 'host' ) {
        hostIDs.push( task.task );
      }
      if ( typeForTask( task ) === 'patch' ) {
        patchIDs.push( task.task );
      }
      if ( typeForTask( task ) === 'vulnerability' ) {
        vulnerabilityIDs.push( task.task );
      }
    } );

    if ( isNotEmpty( hostIDs ) ) {
      const params = {
        filters: {
          // eslint-disable-next-line camelcase
          host_ids: hostIDs,
        },
        columns: [ 'local_name', 'filtered_risk' ],
        rows: [ 0, hostIDs.length ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'filtered_risk', 'DESC' ], [ 'id', 'ASC' ] ],
      };
      // eslint-disable-next-line camelcase
      fetchedHosts = await getRecords( 'host', params, true, true );
    }
    if ( isNotEmpty( patchIDs ) ) {
      const params = {
        filters: {
          // eslint-disable-next-line camelcase
          patch_ids: patchIDs,
        },
        columns: [ 'vendor', 'identifier', 'filtered_risk' ],
        rows: [ 0, patchIDs.length ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'filtered_risk', 'DESC' ], [ 'id', 'ASC' ] ],
      };
      // eslint-disable-next-line max-len, camelcase
      fetchedPatches = await getRecords( 'patch', params, true, true );
    }
    if ( isNotEmpty( vulnerabilityIDs ) ) {
      const params = {
        filters: {
          // eslint-disable-next-line camelcase
          vulnerability_ids: vulnerabilityIDs,
        },
        columns: [ 'identifier', 'filtered_risk' ],
        rows: [ 0, vulnerabilityIDs.length ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'filtered_risk', 'DESC' ], [ 'id', 'ASC' ] ],
      };
      // eslint-disable-next-line max-len, camelcase
      fetchedVulnerabilities = await getRecords( 'vulnerability', params, true, true );
    }

    tasks.map( task => {

      const _type = typeForTask( task );

      let _record;

      if ( _type === 'host' ) {
        _record = fetchedHosts.find( r => r.id === task.task );
      }
      if ( _type === 'vulnerability' ) {
        _record = fetchedVulnerabilities.find( r => r.id === task.task );
      }
      if ( _type === 'patch' ) {
        _record = fetchedPatches.find( r => r.id === task.task );
      }

      let externalUser = null;
      let preferredIntegration = null;
      let ownerUser = null;

      // find the external user if there is one
      if ( isNotEmpty( task.owner ) && isNotEmpty( externalUsers ) ) {
        externalUser = Object.values( externalUsers ).find( e => e.web_user_id === task.owner );
      }

      // find the full owner if there is one
      if ( isNotEmpty( task.owner ) && isNotEmpty( _users ) ) {
        const _ownerUser = _users[task.owner];
        if ( isNotEmpty( _ownerUser ) ) {
          ownerUser = _ownerUser;
        }
      }

      // find the preferred integration if there is one
      if (
        isNotEmpty( ownerUser )
        && isNotEmpty( ownerUser.remediation_export_setting )
        && isNotEmpty( activeIntegrations )
      ) {
        let preferred = activeIntegrations?.jira?.find( i => i.id === ownerUser.remediation_export_setting );

        if ( isEmpty( preferred ) ) {
          preferred = activeIntegrations?.email?.find( i => i.id === ownerUser.remediation_export_setting );
        }
        preferredIntegration = preferred;
      }

      const previouslyExported =
        isNotEmpty( task.external_ticket_id )
        || isNotEmpty( task.last_email_address_exported_to );


      const mostRecentExportType = () => {
        if ( previouslyExported ) {
          if ( isNotEmpty( task.external_ticket_id ) ) {
            return 'jira';
          }
          return 'email';
        }
        return null;
      };

      // combine everything and return as full task object with additional attributes that will be used later
      const _formattedTask = {
        original: task,
        risk: task.risk,
        id: task.id,
        normalizedType: _type,
        label: isNotEmpty( _record ) ? reportTypeDisplayName( _record, _type ) : 'N/A',
        ownerUser,
        externalUser,
        preferredIntegration,
        previouslyExported,
        lastExportType: mostRecentExportType(),
      };
      _formatted.push( _formattedTask );
    } );
    return _formatted;
  };

  const fetchAndFormatTasks = async ( externalUsers=null, activeIntegrations=null, _users=null ) => {
    setLoadingTasks( true );

    let _tasks;
    const taskFetch = await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: plan.id,
    } );

    if ( isNotEmpty( taskFetch ) && isNotEmpty( taskFetch.results ) ) {
      _tasks = taskFetch.results;
    } else {
      _tasks = [];
    }

    if ( isNotEmpty( _tasks ) ) {
      const _formatted = await fetchAndFormatTaskRecordData( externalUsers, _tasks, _users, activeIntegrations );
      _formatted.sort( ( a, b ) => recordSorter( 'risk', false, a, b ) );
      setTasks( _formatted );
      setHasFetchedTasks( true );
      setLoadingTasks( false );
    } else {
      setHasFetchedTasks( true );
      setLoadingTasks( false );
    }
  };

  // once ther users, have been fetched, this formats the tasks and sets them up as we need them
  const externalUsersCallback = ( externalUsers, activeIntegrations, _users ) => {
    setExternalUsers( externalUsers );
    fetchAndFormatTasks( externalUsers, activeIntegrations, _users );
  };

  // called whenever the modal opens, and the plan and exportType have been loaded
  const initialize = async () => {
    const usersRequest = await makeRequest( 'POST', '/fe/user/SELECT', {
      // eslint-disable-next-line camelcase
      columns: [
        'authentication_provider_id',
        'username',
        'given_name',
        'family_name',
        'api_key',
        'setup_wizard_disabled',
        'email_address',
        'remediation_export_setting',
      ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'username', 'ASC' ] ],
    } );

    if (
      isNotEmpty( usersRequest )
      && itemIsArray( usersRequest )
    ) {

      const _users = {};

      usersRequest.map( user => {
        _users[user.id] = user;
      } );

      setUsers( _users );

      getExternalUsers( null, externalUsersCallback, [ activeIntegrations, _users ] );
    }
  };

  React.useEffect( ( ) => {
    if ( show === true && isNotEmpty( plan ) ) {
      initialize();
    }
  }, [ show, plan ] );

  // when the modal closes, clear it all out
  React.useEffect( ( ) => {
    if ( show === false ) {
      setTaskMappings( {} );
      setSelectedTaskIDs( [] );
    }
  }, [ show ] );

  // just a small tweak when opening this modal, make sure to remove an unneeded hash param
  React.useEffect( ( ) => {
    if ( show === true ) {
      removeFromURLHash( 'export_plan_id' );
    }
  }, [ show ] );

  return (
    <React.Fragment>
      <Modal
        visible={show}
        setVisible={setShow}
        body={
          <ModalBody
            plan={plan}
            tasks={tasks}
            users={users}
            externalUsers={externalUsers}
            activeIntegrations={activeIntegrations}
            taskMappings={taskMappings}
            setTaskMappings={setTaskMappings}
            selectedTaskIDs={selectedTaskIDs}
            setSelectedTaskIDs={setSelectedTaskIDs}
            loadingTasks={loadingTasks}
            hasFetchedTasks={hasFetchedTasks}
            show={show}
          />
        }
        action={
          <ModalAction
            tasks={tasks}
            users={users}
            activeIntegrations={activeIntegrations}
            externalUsers={externalUsers}
            taskMappings={taskMappings}
            setTaskMappings={setTaskMappings}
            selectedTaskIDs={selectedTaskIDs}
            setShow={setShow}
          />
        }
        header="Export Remediation Tasks"
        elementClass="remediationExportModal"
        shouldClearSelection={false}
      />

    </React.Fragment>
  );
};

export default RemediationExportModal;