/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './Widgets.scss';
import {
  formatNumber,
  isNotEmpty,
  tagColors,
} from '../../../shared/Utilities';
import EmptyLoading from '../../../shared/EmptyLoading';
import Donut from '../../../shared/Charts/Donut';
import Legend from '../../../shared/Charts/Legend';
import { getThemeColor } from '../../../shared/Themes';

const Widgets = ( {
  tallyLoading,
  scanningMode,
  tallyResults,
  collapsed,
  scanGroups,
  handleScanningAgeClick,
  handleScanGroupClick,
  handleAgentVersionClick,
  selectedScanningAgeIdentifier,
  setSelectedScanningAgeIdentifier,
  selectedScanGroupIdentifier,
  setSelectedScanGroupIdentifier,
  selectedAgentVersionIdentifier,
  setSelectedAgentVersionIdentifier,
  hoveredScanningAgeIdentifier,
  setHoveredScanningAgeIdentifier,
  hoveredScanGroupIdentifier,
  setHoveredScanGroupIdentifier,
  hoveredAgentVersionIdentifier,
  setHoveredAgentVersionIdentifier,
} ) => {

  const [ scanningAgeData, setScanningAgeData ] = React.useState( null );
  const [ scanningAgeTotal, setScanningAgeTotal ] = React.useState( false );
  const [ agentVersionTotal, setAgentVersionTotal ] = React.useState( null );
  const [ agentVersionData, setAgentVersionData ] = React.useState( null );
  const [ scanGroupData, setScanGroupData ] = React.useState( null );
  const [ scanGroupTotal, setScanGroupTotal ] = React.useState( null );

  const scanningAgeOrder = {
    /* eslint-disable camelcase */
    last_24_hours: { label: '24 Hours', fill: getThemeColor( 'status--blue--75' ) },
    last_7_days: { label: '7 Days', fill: getThemeColor( '--status-success--75' ) },
    last_30_days: { label: '30 Days', fill: getThemeColor( 'status--yellow--75' ) },
    greater_than_30_days: { label: '>30 Days', fill: getThemeColor( 'status--orange--75' ) },
    never_scanned: { label: 'Never Scanned', fill: getThemeColor( '--status-alert--75' ) },
    /* eslint-enable camelcase */
  };

  React.useEffect( () => {
    const availableColors = [ ...tagColors.four ];

    availableColors.shift();

    if ( isNotEmpty( tallyResults ) && isNotEmpty( tallyResults.category ) ) {


      setScanningAgeTotal( tallyResults.category.all );

      const zeroed = tallyResults.all === 0;

      const _scanningAgeData = {};

      Object.entries( scanningAgeOrder ).map( ( [ key, data ] ) => {
        _scanningAgeData[key] = {
          stroke: data.fill,
          value: zeroed ? zeroed : ( ( tallyResults.category[key] / tallyResults.category.all ) * 100 ),
          total: tallyResults.category[key],
          key,
        };
      } );

      setScanningAgeData( _scanningAgeData );
    }

    if ( scanningMode === 'agent' && isNotEmpty( tallyResults ) && isNotEmpty( tallyResults.version ) ) {
      const _agentVersionData = {};
      const _agentVersionTotal = Object.values( tallyResults.version ).reduce( ( accum, amount ) => accum += amount );

      Object.entries( tallyResults.version ).map( ( [ versionKey, count ], index ) => {
        _agentVersionData[versionKey] = {
          stroke: availableColors[index % 8],
          value: ( count / _agentVersionTotal ) * 100,
          originalCount: count,
          total: count,
          key: versionKey,
        };
      } );
      setAgentVersionTotal( _agentVersionTotal );
      setAgentVersionData( _agentVersionData );
    }

    if (
      scanningMode === 'agentless'
      && isNotEmpty( tallyResults )
      && isNotEmpty( tallyResults.scan_group )
      && isNotEmpty( scanGroups )
    ) {

      const _scanGroupData = {};
      const _scanGrouTotal = Object.values( tallyResults.scan_group ).reduce( ( accum, amount ) => accum += amount );

      Object.entries( tallyResults.scan_group ).map( ( [ sgID, count ], index ) => {

        const scanGroup = scanGroups.find( s => s.id === sgID );

        _scanGroupData[sgID] = {
          label: scanGroup.label || 'Label',
          stroke: availableColors[index % 8],
          value: ( count / _scanGrouTotal ) * 100,
          originalCount: count,
          total: count,
          key: scanGroup.id,
        };
      } );

      setScanGroupTotal( _scanGrouTotal );
      setScanGroupData( _scanGroupData );
    }
  }, [ tallyResults, scanningMode, scanGroups ] );

  return (
    <div className={ `${collapsed ? 'collapsed' : ''} scanningDashboardWidgetsWrapper` }>
      <div className="dashboardWidgetWrapper noWrapper donut scanningAge">
        <div className="widgetContent">
          <EmptyLoading
            payload={ scanningAgeData }
            loading={ tallyLoading }
            loadingMessage="Loading scanning age data"
            emptyMessage="No scanning age data"
          />
          {
            ( isNotEmpty( scanningAgeData ) && isNotEmpty( scanningAgeTotal ) ) &&
            <React.Fragment>
              <div className="chartAndLabelWrapper">
                <Donut
                  strokeWidth="thin"
                  data={scanningAgeData}
                  total={scanningAgeTotal}
                  displayLabel
                  // eslint-disable-next-line max-len
                  displayLabelOverride= { { value: formatNumber( scanningAgeTotal ), fill: getThemeColor( '--text-color-primary' ) } }
                  onClickCallback={ handleScanningAgeClick }
                  selectedSeriesIdentifier={ selectedScanningAgeIdentifier }
                  setSelectedSeriesIdentifier={ setSelectedScanningAgeIdentifier}
                  hoveredSeriesIdentifier={ hoveredScanningAgeIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredScanningAgeIdentifier}
                />
                <h3>Scanned Hosts</h3>
              </div>
              <Legend
                label="Scanned in the last"
                legendData={ scanningAgeData}
                onClickCallback={ handleScanningAgeClick }
                selectedSeriesIdentifier={ selectedScanningAgeIdentifier }
                setSelectedSeriesIdentifier={ setSelectedScanningAgeIdentifier}
                hoveredSeriesIdentifier={ hoveredScanningAgeIdentifier }
                setHoveredSeriesIdentifier={ setHoveredScanningAgeIdentifier}
              />
            </React.Fragment>
          }
        </div>
      </div>
      {
        scanningMode === 'agent' &&
        <div className="dashboardWidgetWrapper noWrapper donut versions">
          <div className="widgetContent">
            <EmptyLoading
              payload={ agentVersionData }
              loading={ tallyLoading }
              loadingMessage="Loading agent version data"
              emptyMessage="No agent version data"
            />
            {
              ( isNotEmpty( agentVersionData ) && isNotEmpty( agentVersionTotal ) ) &&
              <React.Fragment>
                <div className="chartAndLabelWrapper">
                  <Donut
                    data={agentVersionData}
                    total={agentVersionTotal}
                    onClickCallback={ handleAgentVersionClick }
                    selectedSeriesIdentifier={ selectedAgentVersionIdentifier }
                    setSelectedSeriesIdentifier={ setSelectedAgentVersionIdentifier}
                    hoveredSeriesIdentifier={ hoveredAgentVersionIdentifier }
                    setHoveredSeriesIdentifier={ setHoveredAgentVersionIdentifier}
                  />
                  <h3>Version Breakdown</h3>
                </div>
                <Legend
                  label="Versions"
                  legendData={ agentVersionData }
                  onClickCallback={ handleAgentVersionClick }
                  selectedSeriesIdentifier={ selectedAgentVersionIdentifier }
                  setSelectedSeriesIdentifier={ setSelectedAgentVersionIdentifier}
                  hoveredSeriesIdentifier={ hoveredAgentVersionIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredAgentVersionIdentifier}
                />
              </React.Fragment>
            }
          </div>
        </div>
      }
      {
        scanningMode === 'agentless' &&
        <div className="dashboardWidgetWrapper noWrapper donut versions">
          <div className="widgetContent">
            <EmptyLoading
              payload={ scanGroupData }
              loading={ tallyLoading }
              loadingMessage="Loading scan group data"
              emptyMessage="No scan group data"
            />
            {
              ( isNotEmpty( scanGroupData ) && isNotEmpty( scanGroupTotal ) ) &&
              <React.Fragment>
                <div className="chartAndLabelWrapper">
                  <Donut
                    data={scanGroupData}
                    total={scanGroupTotal}
                    onClickCallback={ handleScanGroupClick }
                    selectedSeriesIdentifier={ selectedScanGroupIdentifier }
                    setSelectedSeriesIdentifier={ setSelectedScanGroupIdentifier}
                    hoveredSeriesIdentifier={ hoveredScanGroupIdentifier }
                    setHoveredSeriesIdentifier={ setHoveredScanGroupIdentifier}
                    allowMultipleSelections
                  />
                  <h3>Scan Group Breakdown</h3>
                </div>

                <Legend
                  label="Scan Groups"
                  legendData={ scanGroupData }
                  onClickCallback={ handleScanGroupClick }
                  selectedSeriesIdentifier={ selectedScanGroupIdentifier }
                  setSelectedSeriesIdentifier={ setSelectedScanGroupIdentifier}
                  hoveredSeriesIdentifier={ hoveredScanGroupIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredScanGroupIdentifier}
                  allowMultipleSelections
                />
                {/* <div className="widgetLegend">
                  <label>Hosts in Scan Group</label>
                  {
                    isNotEmpty( scanGroupData ) &&
                    Object.entries( scanGroupData ).map( ( [ key, data ], index ) => {
                      return <div className="legendItem" key={`${key}_${index}`}>
                        <div className="legendLabel">
                          <span className="legendKey" style={ { background: data.stroke } } />
                          <span>{ data.label } <strong>({ formatNumber( data.originalCount )})</strong></span>
                        </div>
                      </div>;
                    } )
                  }
                </div> */}
              </React.Fragment>
            }
          </div>
        </div>
      }
    </div>
  );
};

export default Widgets;