/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Loading from '../../../shared/Loading';

import Icicle from '../Reports/VulnerabilityInstances/Icicle';
import Widgets from '../Reports/VulnerabilityInstances/Widgets';
import VulnerabilityInstancesCategories from '../../Reporting/Dashboards/Widgets/v2/VulnerabilityInstancesCategories';
import { FullScreenVisualContext } from '../../../Contexts/FullScreenVisual';
import InlineSVG from '../../../shared/InlineSVG';
import { decodeURLHash, isEmpty, isNotEmpty } from '../../../shared/Utilities';

const InstanceVisual = ( {
  visualData,
  collapsed,
  loading,
  onRefresh,
  appliedFilters,
  setAppliedFilters,
} ) => {

  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  const [ data, setData ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( visualData ) ) {
      setData( { tally: { results: visualData } } );
    }
  }, [ visualData ] );

  const onCategoryClick = categoryKey => {
    let _appliedFilters = [ ...appliedFilters ];

    _appliedFilters = _appliedFilters.filter( f => f.attribute !== 'Category' );

    _appliedFilters.push( {
      attribute: 'Category',
      comparator: '=',
      value: categoryKey,
    } );
    setAppliedFilters( _appliedFilters );
    if ( isNotEmpty( onRefresh ) ) {
      onRefresh();
    }
  };

  return (
    <React.Fragment>
      { loading && <Loading /> }
      {
        (
          decodeURLHash().instances_visual_mode === 'categories'
          || isEmpty( decodeURLHash().instances_visual_mode )
        ) &&
        <React.Fragment>
          <VulnerabilityInstancesCategories
            data={data?.tally}
            onRefresh={onRefresh}
            categoryClickCallback={ onCategoryClick }
          />
          {
            !collapsed &&
            <button
              className="roundGlyphButton light"
              onClick={ () => showFullScreenVisual(
                <VulnerabilityInstancesCategories
                  data={data?.tally}
                  onRefresh={onRefresh}
                  shouldIncludeLabels={false}
                />,
                'riskInsightInstances categories',
              ) }
            >
              { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
            </button>
          }

        </React.Fragment>
      }
      {
        decodeURLHash().instances_visual_mode === 'widgets' &&
        <React.Fragment>
          <Widgets visualData={data} onRefresh={onRefresh} />
          {
            !collapsed &&
            <button
              className="roundGlyphButton light"
              onClick={ () => showFullScreenVisual(
                <Widgets visualData={data} onRefresh={onRefresh} />,
                'riskInsightInstances widgets',
              ) }
            >
              { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
            </button>
          }

        </React.Fragment>
      }
      {
        decodeURLHash().instances_visual_mode === 'icicle' &&
        <React.Fragment>
          <Icicle visualData={data} onRefresh={onRefresh} />
          {
            !collapsed &&
            <button
              className="roundGlyphButton light"
              onClick={ () => showFullScreenVisual(
                <Icicle visualData={visualData} onRefresh={onRefresh} />,
                'riskInsightInstances icicle',
              ) }
            >
              { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
            </button>
          }

        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default InstanceVisual;