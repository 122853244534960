/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty } from '../../../shared/Utilities';
import CollapsibleSection from '../Sections/CollapsibleSection';
import { collapsibleSectionDataMap, recordRiskRating } from '../shared';
import NotScannedEmptyState from '../Host/NotScannedEmptyState';
import RemediationInstructions from '../Modals/RemediationInstructions';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { canConfigure, hasFeatureAccess } from '../../App/AccessControl';
import AddToPlanMenu from '../../Remediation/AddToPlanMenu';
import { FullScreenVisualContext } from '../../../Contexts/FullScreenVisual';
import InlineSVG from '../../../shared/InlineSVG';
import EmptyState from '../../../shared/EmptyState';
import PathsGraph from '../Sections/PathsGraph';
import RiskContribution from '../Sections/RiskContribution';

const canAddToRemediation = [
  'host',
  'patch',
  'vulnerability',
];

const RiskAndRemediation = ( {
  record,
  recordType,
  riskType,
  recordInstanceData,
  relatedPaths,
  // setRelatedPaths,
  pathCount,
  // setPathCount,
  handlePathCountChange,

  // recordCard variables
  recordCardRecord,
  setRecordCardRecord,
  recordCardType,
  setRecordCardType,
  showRecordCard,
  setShowRecordCard,
} ) => {

  const [ riskClass, setRiskClass ] = React.useState( 'default' );
  const [ showRemediateModal, setShowRemediateModal ] = React.useState( false );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  const pathsHaveErrors = () => {
    if ( isEmpty( relatedPaths ) ) {
      return true;
    }
    if ( isEmpty( relatedPaths.results ) ) {
      return true;
    }
    if ( isNotEmpty( relatedPaths.errors ) ) {
      return true;
    }
    return false;
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( riskType )  ) {
      const rating = recordRiskRating( record, recordType );
      setRiskClass( rating );
    }
  }, [ record, recordType ] );

  const canViewInstructions = () => {
    if ( recordType === 'user' ) {
      return false;
    }
    if ( recordType === 'host' && !record.has_host ) {
      return false;
    }
    return true;
  };

  return (
    <div className={ `recordDetails__MainPanel__Body_RiskAndRemediation ${recordType}` }>
      <RemediationInstructions
        record={ record }
        recordType={ recordType }
        riskType={riskType}
        show={showRemediateModal}
        setShow={setShowRemediateModal}
        riskClass={riskClass}
      />
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) ) &&
        <React.Fragment>
          {
            ( recordType === 'host' && !record.has_host )
              ? <NotScannedEmptyState />
              : <div className={ `recordDetails__MainPanel_BodyRow pathsAndAssets ${recordType}` }>

                {
                  recordType === 'host' &&
                  <RiskContribution record={record} recordType={recordType} riskType={riskType} />
                }
                <div
                  className={ `criticalPathsWrapper pathCount_${pathCount} ${window.IS_DARK_MODE ? 'darkMode' : '' }` }
                >
                  <h3 className="mainPanel__SectionHeader">
                    <span>Critical Paths</span>
                    <div className="selectFieldWrapper">
                      <select
                        value={pathCount}
                        onChange={ e => handlePathCountChange( e.target.value ) }
                      >
                        <option value={ 3 } >View top 3 paths</option>
                        <option value={ 5 } >View top 5 paths</option>
                      </select>
                    </div>
                  </h3>

                  {
                    pathsHaveErrors() &&
                    <EmptyState message={ `No Attack paths were found for this ${recordType}`} />
                  }
                  <PathsGraph
                    relatedPaths={relatedPaths}
                    item={record}
                    reportType={recordType}

                    // recordCard variables
                    recordCardRecord={recordCardRecord}
                    setRecordCardRecord={setRecordCardRecord}
                    recordCardType={recordCardType}
                    setRecordCardType={setRecordCardType}
                    showRecordCard={showRecordCard}
                    setShowRecordCard={setShowRecordCard}
                  />
                  {
                    isNotEmpty( relatedPaths?.results ) &&
                    <button
                      className="fullScreenVisualToggleButton"
                      onClick={ () => showFullScreenVisual(
                        <PathsGraph
                          relatedPaths={relatedPaths}
                          item={record}
                          reportType={recordType}

                          // recordCard variables
                          recordCardRecord={recordCardRecord}
                          setRecordCardRecord={setRecordCardRecord}
                          recordCardType={recordCardType}
                          setRecordCardType={setRecordCardType}
                          showRecordCard={showRecordCard}
                          setShowRecordCard={setShowRecordCard}
                        />,
                        'riskInsightDetails section pathsGraph',
                      ) }
                    >
                      { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
                    </button>
                  }
                </div>
              </div>
          }
        </React.Fragment>
      }
      <div className="recordDetails__MainPanel_BodyRow recordLists">
        <h3 className="mainPanel__SectionHeader">
          <span>
            Remediation Information
            {
              canViewInstructions() &&
              <button
                disabled={!canConfigure( licenseInfo )}
                // eslint-disable-next-line max-len
                className={ `${!canConfigure( licenseInfo ) ? 'disabled' : ''} remediationInstructionsButton ${ riskClass } ${window.IS_DARK_MODE ? 'darkMode' : ''}` }
                onClick={ () => setShowRemediateModal( true ) }
              >
                View Instructions
              </button>
            }
          </span>
          {
            (
              canAddToRemediation.includes( recordType )
              && isNotEmpty( licenseInfo )
              && hasFeatureAccess( currentUser, licenseInfo, 'f_remediation' )
              && record[riskType] > 0
              && canConfigure( licenseInfo )
            ) &&
            <AddToPlanMenu
              items={ { [record.id]: record } }
              itemsKey={recordType}
              withCount={false}
              elementClass={ riskClass }
              planOptions={ [ 'remediation', 'acceptedRisk' ] }
            />
          }
        </h3>
        <div className="collapsibleSectionsWrapper">
          <div className="column">
            {
              // eslint-disable-next-line max-len
              Object.entries( collapsibleSectionDataMap[recordType].rr_1 ).map( ( [ sectionKey, sectionInfo ], index ) => {
                return <CollapsibleSection
                  key={index}
                  sectionKey={sectionKey}
                  sectionInfo={sectionInfo}
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}

                  // recordCard variables
                  recordCardRecord={recordCardRecord}
                  setRecordCardRecord={setRecordCardRecord}
                  recordCardType={recordCardType}
                  setRecordCardType={setRecordCardType}
                  showRecordCard={showRecordCard}
                  setShowRecordCard={setShowRecordCard}
                />;
              } )
            }
          </div>
          <div className="column">
            {
              // eslint-disable-next-line max-len
              Object.entries( collapsibleSectionDataMap[recordType].rr_2 ).map( ( [ sectionKey, sectionInfo ], index ) => {
                return <CollapsibleSection
                  key={index}
                  sectionKey={sectionKey}
                  sectionInfo={sectionInfo}
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />;
              } )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskAndRemediation;