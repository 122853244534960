/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty } from '../../../../../shared/Utilities';
import CVSSChart from '../../../../../shared/Charts/CVSSChart';

import './CVSSBreakdown.scss';
import Legend from '../../../../../shared/Charts/Legend';
import Donut from '../../../../../shared/Charts/Donut';
import Categories from '../../../../../shared/Charts/Categories';
import EmptyState from '../../../../../shared/EmptyState';
import { getThemeColor } from '../../../../../shared/Themes';

const CVSSBreakdown = ( { data, settings } ) => {

  const order = [
    'Critical',
    'High',
    'Moderate',
    'Low',
    'Minimal',
  ];

  const minMax = {
    critical: {
      maximum: 10,
      minimum: 9,
    },
    high: {
      maximum: 9,
      minimum: 7.5,
    },
    moderate: {
      maximum: 7.5,
      minimum: 6,
    },
    low: {
      maximum: 6,
      minimum: 3,
    },
    minimal: {
      maximum: 3,
      minimum: 0,
    },
  };

  const [ chartData, setChartData ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = data => {
    if ( isNotEmpty( data?.results?.cvss ) ) {
      const _chartData = {};
      let _counts = { critical: 0, high: 0, moderate: 0, low: 0, minimal: 0 };
      let _total = 0;
      if ( isNotEmpty( data ) && isNotEmpty( data.results ) && isNotEmpty( data.results.cvss ) ) {
        _counts = {
          critical: data?.results?.cvss?.critical || 0,
          high: data?.results?.cvss?.high || 0,
          moderate: data?.results?.cvss?.moderate || 0,
          low: data?.results?.cvss?.low || 0,
          minimal: data?.results?.cvss?.minimal || 0,
        };

        _total = Object.values( data.results.cvss ).reduce( ( acc, value ) => acc + value, 0 );
      }

      const zeroed = _total === 0;

      order.map( name => {

        let _value = _counts[name.toLowerCase()];

        if ( settings?.version === 'donut' ) {
          _value = zeroed ? zeroed : ( ( _counts[name.toLowerCase()] / _total ) * 100 );
        }
        _chartData[name.toLowerCase()] = {
          key: name.toLowerCase(),
          label: name,
          // eslint-disable-next-line max-len
          fill: settings?.version === 'horizontal' ? getThemeColor( `${name.toLowerCase()}--75` ) : getThemeColor( name.toLowerCase() ),
          // eslint-disable-next-line max-len
          stroke: settings?.version === 'horizontal' ? getThemeColor( `${name.toLowerCase()}--75` ) : getThemeColor( name.toLowerCase() ),
          total: _counts[name.toLowerCase()],
          value: _value,
          subLabel: `${ minMax[name.toLowerCase()].maximum} - ${ minMax[name.toLowerCase()].minimum}`,
        };
      } );
      setTotal( _total );
      setChartData( _chartData );
    }
  };

  React.useEffect( ( ) => {
    setupData( data );
  }, [ settings, data ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( chartData ) && settings?.version === 'horizontal' ) &&
        <Categories
          data={ chartData }
          total={ total }
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
      {
        ( isNotEmpty( chartData ) && settings?.version === 'barchart' ) &&
        <div className="chartWrapper">
          <CVSSChart
            cvssData={chartData}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </div>

      }
      {
        ( isNotEmpty( chartData ) && settings?.version === 'donut' && isNotEmpty( total ) ) &&
        <Donut
          data={chartData}
          total={total}
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
      {
        isNotEmpty( chartData ) &&
        <Legend
          horizontal={ settings?.version === 'horizontal' }
          legendData={ chartData }
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
      {
        isEmpty( chartData ) &&
        <EmptyState message="No CVSS information available" />
      }
    </React.Fragment>
  );
};

export default CVSSBreakdown;