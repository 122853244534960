/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { capitalize, getDimensionsAndOffset, isNotEmpty, riskToRating } from '../../../../shared/Utilities';
import { getRecordDetails, getTallies } from '../../../../shared/RecordCache';
import InstancesBreakdown from '../../../../shared/Charts/InstancesBreakdown';

import './ScopeContent.scss';
import CVSSCategories from '../../../../shared/CVSSCategories';
import Statistics from '../../Statistics';

const ScopeContent = ( { scope, options, context, containerRef, setStyle, prefetched=false } ) => {

  const [ hostDetails, setHostDetails ] = React.useState( null );
  const [ instanceData, setInstanceData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );

  const svgWrapperRef = React.useRef( null );

  const scopeTypeMap = {
    // eslint-disable-next-line camelcase
    windows_domain: 'Windows Domain',
    host: 'Host',
    subnet: 'Subnet',
    // eslint-disable-next-line camelcase
    listening_ports: 'Listening Ports',
    // eslint-disable-next-line camelcase
    share_smb: 'Share SMB',
    application: 'Application',
    global: 'Global',
  };

  // re-position after the content loads
  React.useEffect( () => {
    if (
      isNotEmpty( containerRef )
      && isNotEmpty( containerRef.current )
      && isNotEmpty( scope )
      && context !== 'explore'
    ) {
      let _clickEvent;
      if ( isNotEmpty( scope.clickEvent ) ) {
        _clickEvent = scope.clickEvent;
      } else if ( isNotEmpty( options?.clickEvent ) ) {
        _clickEvent = options.clickEvent;
      }

      if ( isNotEmpty( _clickEvent ) ) {

        const width = 32 * 16;

        let left = 'unset';
        let right = 16;
        let top = 16;
        let bottom = 'unset';

        setTimeout( () => {
          const dimensions = getDimensionsAndOffset( containerRef.current );

          if ( isNotEmpty( dimensions ) ) {
            const { height } = dimensions;

            const { pageX, pageY } = _clickEvent;

            left = pageX + 16;
            right = 'unset';
            top = pageY;
            bottom = 'unset';

            // it would flow over the right side of the screen, need to put on the left side instead
            if ( ( left + width ) > window.innerWidth ) {
              left = pageX - 16 - width;

              if ( left < 16 ) {
                left = 16;
              }
            }

            // it would be too low on the screen
            if ( ( top + height ) > window.innerHeight ) {
              top = 'unset';
              bottom = 16;
            }

            setStyle( { top, bottom, left, right, width } );
          }
        }, 100 );
      }
    }
  }, [ scope, containerRef, options, context ] );

  React.useEffect( () => {
    if ( isNotEmpty( scope ) && isNotEmpty( scope.id ) && prefetched === false ) {
      if ( scope.type === 'host' ) {
        // if this is a host, get more information about it
        // eslint-disable-next-line camelcase
        getRecordDetails( 'host', scope.id, {} ).then( response => {
          if ( isNotEmpty( response ) ) {
            setHostDetails( response );
          }
        } );

        setLoading( true );
        // if this is a host, get the necessary instance data for the visual
        getTallies(
          'host',
          {
            // eslint-disable-next-line camelcase
            filters: { host_ids: [ scope.id ] },
            types: [ 'category', 'exploit_status', 'cvss' ],
          },
        ).then( tallyData => {
          setLoading( false );
          if ( isNotEmpty( tallyData ) ) {
            const _instanceData = {
              results: { ...tallyData },
            };
            setInstanceData( _instanceData );
          }
        } );
      }
    } else if ( isNotEmpty( scope ) && isNotEmpty( scope.id ) && prefetched === true ) {
      setHostDetails( scope );

      const _instanceData = { results: {} };

      if ( isNotEmpty( scope.cvss_histogram ) ) {
        const cvss = {};

        const cvssLabelOrder = {
          Minimal: 'minimal',
          Low: 'low',
          Moderate: 'moderate',
          High: 'high',
          Critical: 'critical',
        };

        Object.entries( cvssLabelOrder ).map( ( [ label, key ] ) => {
          const histogramEntry = scope.cvss_histogram.find( c => c.name === label );

          cvss[key] = histogramEntry.value;
        } );
        _instanceData.results.cvss = cvss;
      }
      if ( isNotEmpty( scope.category_histogram ) ) {
        _instanceData.results._total = scope.category_histogram.all;
        _instanceData.results.category = scope.category_histogram;
      }
      setInstanceData( _instanceData );
    }
  }, [ scope, prefetched ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( scope ) && scope.type !== 'host' ) &&
        <section>
          <strong>Type:</strong>
          <span>{ scopeTypeMap[scope.type] || capitalize( scope.type ) }</span>
        </section>
      }
      {
        isNotEmpty( options )
          ? <React.Fragment>
            {
              ( 'include_vulnerability_instances' in options && options.include_vulnerability_instances === true ) &&
              <React.Fragment>
                {
                  ( isNotEmpty( scope ) && scope.type === 'host' && isNotEmpty( instanceData ) ) &&
                  <InstancesBreakdown
                    reportType="host"
                    itemID={ scope.id }
                    riskRating={ riskToRating( scope.risk ? scope.risk : scope.filtered_risk ) }
                    instanceData={ instanceData }
                    svgRef={ svgWrapperRef }
                    loading={ loading }
                  />
                }
              </React.Fragment>
            }
          </React.Fragment>
          : <React.Fragment>
            {
              ( isNotEmpty( scope ) && scope.type === 'host' && isNotEmpty( instanceData ) ) &&
              <InstancesBreakdown
                reportType="host"
                itemID={ scope.id }
                riskRating={ riskToRating( scope.risk ? scope.risk : scope.filtered_risk ) }
                instanceData={ instanceData }
                svgRef={ svgWrapperRef }
                loading={ loading }
              />
            }
          </React.Fragment>
      }
      {
        (
          isNotEmpty( scope )
          && isNotEmpty( instanceData )
          && isNotEmpty( options )
          && ( options.include_cvss_breakdown )
        ) &&
        <CVSSCategories data={ instanceData } />
      }
      {
        ( isNotEmpty( scope ) && scope.type === 'host' && isNotEmpty( hostDetails ) ) &&
        <Statistics
          record={ hostDetails }
          recordType="host"
          recordInstanceData={ instanceData }
          includeRisk={ false }
        />
      }
    </React.Fragment>
  );
};

export default ScopeContent;