/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../Utilities';
import Donut from '../Donut';

import './style.scss';
import Legend from '../Legend';
import { getThemeColor } from '../../Themes';

const ExploitStatusBreakdown = ( { instanceData } ) => {

  const [ chartData, setChartData ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );

  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  React.useEffect( ( ) => {
    const formatData = tally => {
      if ( isNotEmpty( tally ) && isNotEmpty( tally.results ) && isNotEmpty( tally.results.exploit_status ) ) {
        const { _total } = tally.results;

        setTotal( _total );

        const zeroed = _total === 0;

        const _chartData = {
          private: {
            key: 'private',
            label: 'Private',
            stroke: getThemeColor( '--divider-color' ),
            value: zeroed ? zeroed : ( ( tally.results.exploit_status.private / _total ) * 100 ),
            total: tally.results.exploit_status.private,
          },
          // eslint-disable-next-line camelcase
          published_details: {
            key: 'published_details',
            label: 'Details Published',
            stroke: getThemeColor( '--low' ),
            value: zeroed ? zeroed : ( ( tally.results.exploit_status.published_details / _total ) * 100 ),
            total: tally.results.exploit_status.published_details,
          },
          poc: {
            key: 'poc',
            label: 'PoC Published',
            stroke: getThemeColor( '--moderate' ),
            value: zeroed ? zeroed : ( ( tally.results.exploit_status.poc / _total ) * 100 ),
            total: tally.results.exploit_status.poc,
          },
          weaponized: {
            key: 'weaponized',
            label: 'Weaponized',
            stroke: getThemeColor( '--high' ),
            value: zeroed ? zeroed : ( ( tally.results.exploit_status.weaponized / _total ) * 100 ),
            total: tally.results.exploit_status.weaponized,
          },
        };
        setChartData( _chartData );
      }
    };

    if ( isNotEmpty( instanceData ) && isNotEmpty( instanceData.tally ) ) {
      formatData( instanceData.tally );
    }
  }, [ instanceData ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( instanceData ) && isNotEmpty( total ) ) &&
        <div className="exploitStatusBreakdownWrapper">
          <Donut
            data={chartData}
            total={total}
            displayLabel={false}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={chartData}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </div>
      }
    </React.Fragment>
  );
};

export default ExploitStatusBreakdown;