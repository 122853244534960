/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../Utilities';
import { EMPTY_OCCURRENCE } from '.';
import InlineSVG from '../../../../InlineSVG';

const Occurrence = ( {
  allowMultiple,
  uuid,
  occurrence,
  formState,
  field,
  occurrences,
  setOccurrences,
} ) => {

  const [ type, setType ] = React.useState( EMPTY_OCCURRENCE.type );
  const [ week, setWeek ] = React.useState( EMPTY_OCCURRENCE.week );
  const [ dow, setDow ] = React.useState( EMPTY_OCCURRENCE.dow );
  const [ time, setTime ] = React.useState( EMPTY_OCCURRENCE.time );

  const handleSubFieldChange = ( value, attr ) => {
    switch ( attr ) {
    case 'type':
      setType( value );
      break;
    case 'week':
      setWeek( value );
      break;
    case 'dow':
      setDow( value );
      break;
    case 'time':
      setTime( value );
      break;
    default:
      break;
    }

    const _occurrence = { ...occurrence, [attr]: value };

    setOccurrences( { ...occurrences, [uuid]: _occurrence } );
  };

  React.useEffect( () => {
    if ( isNotEmpty( occurrence ) ) {
      setType( occurrence.type || EMPTY_OCCURRENCE.type );
      setWeek( occurrence.week || EMPTY_OCCURRENCE.week );
      setDow( occurrence.dow || EMPTY_OCCURRENCE.dow );
      setTime( occurrence.time || EMPTY_OCCURRENCE.time );
    }
  }, [ uuid, occurrence ] );

  const removeOccurrence = e => {
    e.stopPropagation();
    e.preventDefault();
    const _occurrences = { ...occurrences };
    delete _occurrences[uuid];
    setOccurrences( _occurrences );
  };

  return (
    <React.Fragment>
      {/* eslint-disable-next-line max-len */}
      <div className={`${allowMultiple ? 'allowMultiple' : ''} occurrenceWrapper`}>
        <div className="selectFieldWrapper">
          <select
            disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
            value={ type }
            onChange={ e => handleSubFieldChange( e.target.value, 'type' ) }
          >
            <option value="" disabled>Select...</option>
            <option value="manual">Not Scheduled</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        {
          type === 'monthly' &&
          <React.Fragment>
            <span>on the</span>
            <div className="selectFieldWrapper">
              <select
                value={ week }
                onChange={ e => handleSubFieldChange( e.target.value, 'week' ) }
              >
                <option value="" disabled>Select...</option>
                <option value="1st">First</option>
                <option value="2nd">Second</option>
                <option value="3rd">Third</option>
                <option value="4th">Fourth</option>
              </select>
            </div>
          </React.Fragment>

        }
        {
          ( type === 'monthly' || type === 'weekly' ) &&
          <React.Fragment>
            {
              type === 'weekly' &&
              <span>every</span>
            }
            <div className="selectFieldWrapper">
              <select
                value={ dow }
                onChange={ e => handleSubFieldChange( e.target.value, 'dow' ) }
              >
                <option value="" disabled>Select...</option>
                <option value="mon">Monday</option>
                <option value="tue">Tuesday</option>
                <option value="wed">Wednesday</option>
                <option value="thu">Thursday</option>
                <option value="fri">Friday</option>
                <option value="sat">Saturday</option>
                <option value="sun">Sunday</option>
              </select>
            </div>
          </React.Fragment>
        }
        {
          type !== 'manual' &&
          <React.Fragment>
            <span>at</span>
            <input
              type="time"
              value={ time }
              onChange={ e => handleSubFieldChange( e.target.value, 'time' ) }
            />
          </React.Fragment>

        }
        {
          ( allowMultiple ) &&
          <button
            onClick={ removeOccurrence }
            disabled={ field.disabled }
            className={ field.disabled ? 'disabled' : '' }
          >
            <InlineSVG type="remove" />
          </button>
        }
      </div>
    </React.Fragment>
  );
};

export default Occurrence;