/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  isNotEmpty,
  riskToRating,
  shortenedVulnerabilityScannerNameMap,
  vulnerabilityScannerLogo,
} from '../../../../shared/Utilities';
import RatingBadge from '../../../../shared/RatingBadge';
import InlineSVG from '../../../../shared/InlineSVG';

import './SignatureHeader.scss';

const SignatureHeader = ( {
  signature,
  options,
  handleClose,
  context,
  collapsed,
  setCollapsed=() => {},
} ) => {

  return (
    <React.Fragment>
      {
        ( isNotEmpty( signature ) && signature.scanner !== 'internal' )
          ? <React.Fragment>
            <div className="recordCardHeaderRow">
              <h2>
                {
                  ( isNotEmpty( options ) && options.isDraggable ) &&
                  <InlineSVG type="draggable" elementClass="draggableIcon" />
                }
                <div className="recordIconWrapper default" >
                  { vulnerabilityScannerLogo( signature.scanner ) }
                </div>
                <span className="label">{ shortenedVulnerabilityScannerNameMap[signature.scanner ] } Results</span>
              </h2>
              <div className="right">
                <RatingBadge rating={ signature?.risk ? riskToRating( signature?.risk ) : signature.risk_rating } />
                {
                  ( isNotEmpty( options ) && options.isDismissable ) &&
                  <button
                    className="recordCardCloseButton roundGlyphButton light"
                    onClick={ handleClose }
                  >
                    <InlineSVG type="close" elementClass="closeIcon" />
                  </button>
                }
              </div>
            </div>
            <div
              // eslint-disable-next-line max-len
              className={ `recordCardHeaderRow__dividerBar ${ signature?.risk ? riskToRating( signature?.risk ) : signature.risk_rating }` }
            />
            <div className="recordCardHeaderRow">
              <div className="left"></div>
              <div className="right">
                {
                  context === 'explore' &&
                  <React.Fragment>
                    {
                      options?.isCollapsible &&
                      <button
                        className="roundGlyphButton light"
                        // eslint-disable-next-line max-len
                        onClick={ () => setCollapsed( !collapsed ) }
                      >
                        {
                          collapsed
                            ? <InlineSVG type="expand" />
                            : <InlineSVG type="collapse" />
                        }
                      </button>
                    }
                  </React.Fragment>
                }
              </div>
            </div>
          </React.Fragment>
          : <React.Fragment>
            <div className="recordCardHeaderRow">
              <h2>
                {
                  ( isNotEmpty( options ) && options.isDraggable ) &&
                  <InlineSVG type="draggable" elementClass="draggableIcon" />
                }
                <div className="recordIconWrapper primary" >
                  <InlineSVG type="dsLogo" />
                </div>
                <span className="label">DeepSurface Results</span>
              </h2>
            </div>
            <div className="recordCardHeaderRow__dividerBar primary" />
            <div className="recordCardHeaderRow">
              <div className="left"></div>
              <div className="right">
                {
                  context === 'explore' &&
                  <React.Fragment>
                    {
                      options?.isCollapsible &&
                      <button
                        className="roundGlyphButton light"
                        // eslint-disable-next-line max-len
                        onClick={ () => setCollapsed( !collapsed ) }
                      >
                        {
                          collapsed
                            ? <InlineSVG type="expand" />
                            : <InlineSVG type="collapse" />
                        }
                      </button>
                    }
                  </React.Fragment>
                }
              </div>
            </div>
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default SignatureHeader;