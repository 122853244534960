/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';
import InlineSVG from '../InlineSVG';
import {
  ACLRoleLabelMap,
  integrationInstanceName,
  isEmpty,
  isNotEmpty,
  userDisplayName,
} from '../Utilities';

import './style.scss';
import RatingBadge from '../RatingBadge';
import { getThemeColor } from '../Themes';

const avatarPalette = [
  '#E74C3C',
  '#E67E22',
  '#F1C40F',
  '#2ECC71',
  '#1ABC9C',
  '#3498DB',
  '#9B59B6',
  '#34495E',
  '#95A5A6',

  '#C0392B',
  '#D35400',
  '#F39C12',
  '#27AE60',
  '#16A085',
  '#2980B9',
  '#8E44AD',
  '#2C3E50',
  '#7F8C8D',
];

const UserDetails = ( {
  user,
  elementClass='',
  randomizeKey=7,
  includeDetails=false,
  includeEmail=false,
  includeRole=false,
  integration=null,
  externalSource=null,
  showAvatar=true,
  forLeftNav=false,
} ) => {

  const [ initials, setInitials ] = React.useState( '' );

  React.useEffect( () => {
    if ( isNotEmpty( user ) ) {
      if ( isNotEmpty( externalSource ) && externalSource === 'jira' ) {
        setInitials( user.display_name[0] );
      } else if ( user.given_name && user.family_name ) {
        setInitials( `${user.given_name[0]}${user.family_name[0]}` );
      } else if ( user.username ) {
        setInitials( user.username[0] );
      }
    } else {
      setInitials( 'N/A' );
    }
  }, [ user, externalSource, integration ] );

  return (
    <div className={ `userDetailsWrapper ${elementClass}` }>
      {
        showAvatar &&
        <React.Fragment>
          {
            ( isNotEmpty( externalSource ) && isNotEmpty( user.extra_data ) && isNotEmpty( user.extra_data.avatar ) )
              ? <img
                className={ `avatarInitials ${elementClass} avatar` }
                src={ user.extra_data.avatar }
              />
              : <div
                className={ `avatarInitials ${elementClass}` }
                style={ {
                  // eslint-disable-next-line max-len
                  background: `${ forLeftNav ? getThemeColor( '--icon-color-primary' ) : avatarPalette[ randomizeKey % avatarPalette.length ] }`,
                  // eslint-disable-next-line max-len
                  boxShadow: `0 0 0 2px ${ forLeftNav ? getThemeColor( '--icon-color-primary' ) : avatarPalette[ randomizeKey % avatarPalette.length ] }`,
                } }
              >
                { initials }
              </div>
          }
        </React.Fragment>
      }
      {
        includeDetails &&
        <section className="userDetailsSection">
          <h3>
            <InlineSVG type="groupsAlt" />
            {
              isEmpty( user )
                ? 'Unassigned'
                : <div className="userNameWrapper">
                  { userDisplayName( user, false, isNotEmpty( externalSource ) ? externalSource : null ) }
                  {
                    includeRole && <RatingBadge rating={ ACLRoleLabelMap[user?.acl_role] } />
                  }
                </div>
            }
          </h3>
          {
            isNotEmpty( integration ) &&
            <div className="integrationLabel">
              <InlineSVG type={ `${integration.tool}Logo`} version="special" />
              { integrationInstanceName( integration ) }
            </div>
          }
          {
            includeEmail &&
            <span className="emailAddress" >{ user.email_address || 'No email address'}</span>
          }
        </section>
      }
    </div>

  );
};

export default UserDetails;
