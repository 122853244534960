/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  decodeURLHash,
  encodeURLHash,
  formatNumber,
  globalColors,
  isNotEmpty,
  removeFromURLHash,
  triggerHashRefresh,
} from '../../../../../shared/Utilities';

import './VulnerabilityInstancesCategories.scss';
import InlineSVG from '../../../../../shared/InlineSVG';
import { HelpTrigger } from '../../../../HelpDocumentation/ContextualHelp';
import EmptyState from '../../../../../shared/EmptyState';
import { defaultInstanceHostURL } from '../../../../App/Routing';

/* eslint-disable camelcase, max-len */
const categoryDescriptions = {
  deprioritized: <React.Fragment>
    <p>
      Items in this category are likely not a significant concern because they can only cause denial-of-service (DoS) conditions, do not appear to be exploitable in this environment, are duplicates of other issues identified through DeepSurface's more advanced analysis, or are false positives.
    </p>
    <p>
      While these items likely do not need to be addressed, they are broken down into several sub-categories for review if desired.
    </p>
  </React.Fragment>,
  for_review: <React.Fragment>
    <p>
      These vulnerabilities may be significant, but could not be included in DeepSurface's advanced graph-based threat model due to a lack of complete information or other reasons. The reasons they have not been included are broken down further into subcategories below.
    </p>
    <p>
      Please review the items in this category to determine if additional information can be gathered to better prioritize them, or use more traditional methods of prioritization to estimate the potential risk.
    </p>
  </React.Fragment>,
  unrecognized: <p>
    These vulnerabilities have not yet been assessed by DeepSurface staff. Don’t hesitate to contact our support team if there is an item in this set you’re concerned about.
  </p>,
  unsupported: <p>
    DeepSurface staff have reviewed this issue, but have determined that it cannot be added to customer threat models at this time. See the subcategories for more information on the specific reason for this.
  </p>,
  missing_host: <p>
    Your vulnerability scanner reported these instances, but DeepSurface was not able to discover and/or connect to the hosts in question. Please see any relevant Configuration alerts or Scan logs for specific information.
  </p>,
  cannot_model: <p>
    The diversity and complexity of attack scenarios of these vulnerabilities make it very difficult to include these issues in the threat model in a practical and realistic way.
  </p>,
  insufficient_information: <p>
    There is not enough information provided by the vendor, security researchers, or other sources to fully understand the attack scenarios that the vulnerability permits. If you have more information on these issues that might help us add them to your threat model, please contact our support team.
  </p>,
  missing_capability: <p>
    DeepSurface does not currently gather enough contextual information to support this vulnerability, but may in the future. Don’t hesitate to contact our support team if there is an item in this set you’re concerned about and would like us to prioritize our development to add this capability.
  </p>,
  purely_denial_of_service: <p>
    These vulnerabilities are strictly denial of service (DoS) flaws that cannot allow an attack to gain additional privileges. As such, it does not make sense to add these to a computational threat model that relates to chaining attacks and privileges. Consider cross-referencing the hosts and services affected by issues in this category with any publicly-exposed services to identify the DoS flaws that are most relevant to you.
  </p>,
  dos_only: <p>
    These vulnerabilities are strictly denial of service (DoS) flaws that cannot allow an attack to gain additional privileges. As such, it does not make sense to add these to a computational threat model that relates to chaining attacks and privileges. Consider cross-referencing the hosts and services affected by issues in this category with any publicly-exposed services to identify the DoS flaws that are most relevant to you.
  </p>,
  not_exploitable: <p>
    These issues can allow an attacker to gain additional privileges in certain circumstances, but in your environment, the specific instances of vulnerabilities in this category do not appear to be exploitable based on your current configuration and software usage patterns. This could change as the environment and user behavior changes over time, but at this moment these issues are not presenting any significant risk.
  </p>,
  overridden: <p>
    These items were reported by your vulnerability scanner, but are also detected directly by DeepSurface with more detail and analysis. For this reason, these issues are excluded from the model. Please refer to the vulnerability identifier indicated in the "Public Notes" field to determine how they were mapped to the threat model using the improved analysis offered by DeepSurface.
  </p>,
  prioritized: <p>
    Items in this top-level category were included in the DeepSurface graph-based computational threat model, allowing for advanced prioritization of business risk. These are the only items included in the Hosts, Patches, and Vuln. Analysis Risk Insights reports.
  </p>,
  unreachable: <React.Fragment>
    <p>
      While these instances were included in the threat model, path analysis revealed no specific breach scenarios that are likely to leverage the flaws in a realistic attack. This could happen for a variety of reasons, including:
    </p>
    <ul>
      <li>An attacker has no known way of gaining the level of privilege necessary to exploit these vulnerability instances.</li>
      <li>While an attacker may be able to gain the level of privilege necessary to exploit the flaw, the resulting level of access gained would not help the attacker gain access to a sensitive asset.</li>
      <li>An attacker may be able to gain the level of privilege necessary to exploit the flaw, but in all cases the level of privilege gained by doing so would be less than the attacker already has (e.g. it doesn’t make sense for a domain administrator to exploit a local privilege escalation flaw when they already have full control over a system).</li>
    </ul>
  </React.Fragment>,
  carries_risk: <p>
    These instances of vulnerabilities were included in the threat model and were also identified as being useful to an attacker in a realistic attack scenario. Therefore, these issues contribute to the overall risk of a breach.
  </p>,
};

export const categoryLabelsAndDescription = {
  carries_risk: {
    label: 'Confirmed Risk',
    description: categoryDescriptions.carries_risk,
    fill: globalColors['status--red'],
  },
  unreachable: {
    label: 'No Attack Path',
    description: categoryDescriptions.unreachable,
    fill: globalColors['status--red--80'],
  },
  missing_capability: {
    label: 'Missing Capability',
    description: categoryDescriptions.missing_capability,
    fill: globalColors['status--red--60'],
  },
  insufficient_information: {
    label: 'Insufficient Information',
    description: categoryDescriptions.insufficient_information,
    fill: globalColors['status--red--40'],
  },
  cannot_model: {
    label: 'Cannot Model',
    description: categoryDescriptions.cannot_model,
    fill: globalColors['status--red--20'],
  },
  unrecognized: {
    label: 'Unrecognized',
    description: categoryDescriptions.unrecognized,
    fill: globalColors['status--red--10'],
  },
  missing_host: {
    label: 'Not Scanned by DeepSurface',
    description: categoryDescriptions.missing_host,
    fill: globalColors['grey--divider'],
  },
  overridden: {
    label: 'Overridden',
    description: categoryDescriptions.overridden,
    fill: globalColors['status--green--50'],
  },
  not_exploitable: {
    label: 'Mitigated',
    description: categoryDescriptions.not_exploitable,
    fill: globalColors['status--green--75'],
  },
  dos_only: {
    label: 'Purely Denial of Service',
    description: categoryDescriptions.dos_only,
    fill: globalColors['status--green'],
  },

  // parent categories
  deprioritized: {
    label: 'Deprioritized',
    description: categoryDescriptions.deprioritized,
    fill: globalColors['status--green'],
  },
  for_review: {
    label: 'For Review',
    description: categoryDescriptions.for_review,
    fill: globalColors['status--red--60'],
  },

  prioritized: {
    label: 'Prioritized',
    description: categoryDescriptions.prioritized,
    fill: globalColors['status--red'],
  },
};

export const deprioritizedKeys = [
  'dos_only',
  'not_exploitable',
  'overridden',
];

export const forReviewKeys = [
  'cannot_model',
  'insufficient_information',
  'missing_capability',
  'missing_host',
  'unrecognized',
];

export const unsupportedKeys = [
  'cannot_model',
  'insufficient_information',
  'missing_capability',
];

export const prioritizedKeys = [
  'unreachable',
  'carries_risk',
];

export const parentCategoryKeys = [
  'prioritized',
  'deprioritized',
  'for_review',
];

const InstanceCategoryLabel = ( {
  category,
  version='default',
  onRefresh,
  setHoveredCategoryKey,
  hoveredCategoryKey,
  hasRefresh,
} ) => {

  const toggleCategory = e => {
    if ( hasRefresh ) {
      const existingCategory = decodeURLHash()['category'] || '';

      e.preventDefault();
      e.stopPropagation();

      if ( category.key === existingCategory ) {
        removeFromURLHash( 'category' );
      } else {
        encodeURLHash( { category: category.key } );
      }
      triggerHashRefresh();
      onRefresh();
    } else {
      window.open( `${defaultInstanceHostURL}&category=${category.key}` );
    }

  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( category ) )
          ? <div
            className={`${hasRefresh ? 'isClickable' : ''} instanceCategoryLabel ${hoveredCategoryKey === category.key ? 'isHovered' : '' } ${decodeURLHash().category === category.key ? 'isFilteringBy' : ''} ${category.value <= 0 ? 'empty' : '' } ${version} ${category.reviewSection === 'unsupported' ? 'indented' : '' } ${category.key}`}
            onClick={ toggleCategory }
            onMouseEnter={ () => setHoveredCategoryKey( category.key ) }
            onMouseLeave={ () => setHoveredCategoryKey( null ) }
          >
            <div className="instanceCategoryLabelWrapper">
              <span className={ `instanceCategoryColor ${version}` } style={ { background: category.fill } } />
              <span>{ category.label }</span>
              <strong>({ formatNumber( category.value ) })</strong>
              <HelpTrigger helpKey={category.key} />
            </div>
            {
              ( category.value > 0 && decodeURLHash().category === category.key ) &&
              <button
                className="removeCategoryFilterButton"
                onClick={ toggleCategory }
              >
                <InlineSVG type="remove" />
              </button>
            }
          </div>
          : <EmptyState message="No data available" />
      }
    </React.Fragment>
  );
};

const VulnerabilityInstancesCategories = ( {
  data,
  onRefresh,
  settings=null,
  shouldIncludeLabels=true,
  categoryClickCallback=() => {},
} ) => {

  const [ total, setTotal ] = React.useState( 0 );
  const [ categories, setCategories ] = React.useState( null );
  const [ parentCategories, setParentCategories ] = React.useState( null );

  const [ showLabel, setShowLabel ] = React.useState( false );
  const [ labelCategory, setLabelCategory ] = React.useState( null );

  const [ hoveredCategoryKey, setHoveredCategoryKey ] = React.useState( null );

  const [ fullVersion, setFullVersion ] = React.useState( true );
  const [ excludeNotScanned, setExcluedNotScanned ] = React.useState( false );

  const [ labelX, setLabelX ] = React.useState( 100_000_000 );
  const [ labelY, setLabelY ] = React.useState( 100_000_000 );

  const containerWidth = 100;
  const containerHeight = 20;
  const barGap = 0.15;
  const labelOffset = 16;
  let _x, _y;

  const setCategory = ( e, categoryKey ) => {
    const existingCategory = decodeURLHash()['category'] || '';

    if ( categoryKey === existingCategory ) {
      return true;
    }

    e.preventDefault();
    e.stopPropagation();
    encodeURLHash( { category: categoryKey } );
    triggerHashRefresh();
    onRefresh();

    if ( isNotEmpty( categoryKey ) && isNotEmpty( categoryClickCallback ) ) {
      categoryClickCallback( categoryKey );
    }
  };

  const handleMouseLeave = () => {
    setHoveredCategoryKey( null );
    setShowLabel( false );
    setLabelCategory( null );
    setLabelX( 100_000_000 );
    setLabelY( 100_000_000 );
  };

  const handleMouseMove = ( e, category ) => {
    const wrapperEl = document.getElementById( 'categoryLabelContainer' );
    const container = document.getElementById( 'pageContent' );
    if (
      !wrapperEl
      || !container
      || ( Number.isNaN( wrapperEl.innerWidth ) && Number.isNaN( wrapperEl.innerHeight ) )
    ) {
      console.log( 'doing nothing' );
    } else {
      if ( e.pageX + wrapperEl.clientWidth + labelOffset > window.innerWidth ) {
        _x = e.pageX - labelOffset - wrapperEl.clientWidth;
      } else {
        _x = e.pageX + labelOffset;
      }
      if ( _x <= 0 ) {
        _x = labelOffset;
      }
      _y = e.pageY + labelOffset;
    }
    setHoveredCategoryKey( category.key );
    setLabelX( _x );
    setLabelY( _y );
    setLabelCategory( category );
    setShowLabel( true );
  };


  const setupData = data => {
    let _total = 0;
    const _categories = {};
    const _parentCategories = {
      prioritized: {
        key: 'prioritized',
        label: 'Prioritized',
        description: categoryDescriptions.prioritized,
        width: 0,
        count: 0,
        fill: globalColors['status--red'],
        value: 0,
        childValues: [],
      },
      for_review: {
        key: 'for_review',
        label: 'For Review',
        description: categoryDescriptions.for_review,
        width: 0,
        count: 0,
        fill: globalColors['status--red--40'],
        value: 0,
        childValues: [],
      },
      deprioritized: {
        key: 'deprioritized',
        label: 'Deprioritized',
        description: categoryDescriptions.deprioritized,
        width: 0,
        count: 0,
        fill: globalColors['status--green'],
        value: 0,
        childValues: [],
      },
    };
    Object.entries( categoryLabelsAndDescription ).map( ( [ categoryKey, cat ] ) => {
      if ( excludeNotScanned === true ) {
        if ( categoryKey !== 'missing_host' && !parentCategoryKeys.includes( categoryKey ) ) {
          const value = data?.results.category[categoryKey];

          _total += value;

          const _category = {
            ...cat,
            key: categoryKey,
            value,
          };

          _categories[categoryKey] = _category;
        }
      } else if ( !parentCategoryKeys.includes( categoryKey ) && isNotEmpty( data?.results.category ) ) {
        const value = data?.results.category[categoryKey];
        _total += value;

        const _category = {
          ...cat,
          key: categoryKey,
          value,
        };

        _categories[categoryKey] = _category;
      }
    } );

    const ratio = containerWidth / _total;

    // one more loop to set the x, ratio, parent
    Object.values( _categories ).map( ( cat, index ) => {
      const previousTotals = Object.values( _categories ).reduce( ( accum, c, _index ) => {
        if ( _index < index ) {
          return accum += c.value;
        }
        return accum;

      }, 0 );

      const x = ( previousTotals * ratio );
      const width = ( cat.value * ratio ) - barGap;

      let sectionKey = '';
      let subSectionKey = '';
      let parent;

      if ( prioritizedKeys.includes( cat.key ) ) {
        sectionKey = 'prioritized';
        parent = _parentCategories.prioritized;

        // eslint-disable-next-line
        if ( parent.hasOwnProperty( 'x' ) ) {
          console.log( 'x already set' );
        } else {
          parent.x = x;
        }
        parent.width += width;
        parent.value += cat.value;
        parent.count += 1;
        parent.childValues.push( {
          key: cat.key,
          label: cat.label,
          value: cat.value,
          fill: cat.fill,
        } );
      }

      if ( forReviewKeys.includes( cat.key ) ) {
        sectionKey = 'for_review';

        parent = _parentCategories.for_review;

        // eslint-disable-next-line
        if ( parent.hasOwnProperty( 'x' ) ) {
          console.log( 'x already set' );
        } else {
          parent.x = x;
        }

        if ( unsupportedKeys.includes( cat.key ) ) {
          subSectionKey = 'unsupported';
        }

        parent.width += width;
        parent.value += cat.value;
        parent.count += 1;
        parent.childValues.push( {
          key: cat.key,
          label: cat.label,
          value: cat.value,
          fill: cat.fill,
          reviewSection: subSectionKey,
        } );
      }
      if ( deprioritizedKeys.includes( cat.key ) ) {
        sectionKey = 'deprioritized';
        parent = _parentCategories.deprioritized;

        // eslint-disable-next-line
        if ( parent.hasOwnProperty( 'x' ) ) {
          console.log( 'x already set' );
        } else {
          parent.x = x;
        }
        parent.width += width;
        parent.value += cat.value;
        parent.count += 1;
        parent.childValues.push( {
          key: cat.key,
          label: cat.label,
          value: cat.value,
          fill: cat.fill,
        } );
      }

      cat.sectionKey = sectionKey;
      cat.subSectionKey = subSectionKey;
      cat.width = width < 0 ? 0 : width;
      cat.x = x;
    } );

    Object.values( _parentCategories ).map( pc => {
      if ( pc.width > 0 && pc.count > 0 ) {
        pc.width = pc.width + ( barGap * ( pc.count - 2 ) );
      }
    } );

    if ( isNotEmpty( settings ) && settings.version === 'truncated'  ) {
      setFullVersion( false );
    }

    setParentCategories( _parentCategories );
    setCategories( _categories );
    setTotal( _total );
  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      setupData( data );
    }
  }, [ data, settings, fullVersion, excludeNotScanned ] );

  const handleCategoryRectClick = ( e, category ) => {
    if ( isNotEmpty( onRefresh ) ) {
      setCategory( e, category.key );
    } else {
      window.open( `${defaultInstanceHostURL}&category=${category.key}` );
    }
  };

  return (
    <div className={ `${decodeURLHash().page === 'reporting_dashboard' ? 'dashboardVersion' : '' } vulnerabilityInstancesVisualWrapper` }>
      {
        isNotEmpty( total ) &&
        <h3>Total Vulnerability Instances <strong>({ formatNumber( total ) })</strong></h3>
      }
      <label className="excludeNotScannedToggle" onClick={ () => setExcluedNotScanned( !excludeNotScanned ) }>
        <div className={ `checkboxFieldWrapper ${excludeNotScanned ? 'checked' : ''}` } />
        <span className="labelWrapper">Exclude hosts not scanned by DeepSurface?</span>
      </label>
      {
        ( isNotEmpty( categories ) && isNotEmpty( total ) && isNotEmpty( parentCategories ) ) &&
        <svg
          viewBox={ `0 0 ${containerWidth} ${containerHeight}` }
          xmlns="http://www.w3.org/2000/svg"
          className="instancesBreakdown"
          preserveAspectRatio="none"
        >
          {/* each category gets a rect */}
          {
            fullVersion
              ? Object.entries( categories ).map( ( [ key, data ], index ) => {
                if (
                  isNotEmpty( data )
                  && isNotEmpty( data.x )
                  && isNotEmpty( data.width )
                  && !isNaN( data.x )
                  && !isNaN( data.width )
                ) {
                  return <rect
                    key={ `${key}_${index}`}
                    y={0}
                    x={data.x}
                    rx={0.25}
                    ry={0.25}
                    width={data.width}
                    height={containerHeight - 4 }
                    fill={data.fill}
                    onClick={ e => handleCategoryRectClick( e, data ) }
                    onMouseMove={ e => handleMouseMove( e, data ) }
                    onMouseLeave={ handleMouseLeave }
                    className={`instanceCategoryRect ${hoveredCategoryKey === data.key ? 'isHovered' : '' }` }
                  />;
                }
              } )
              : Object.entries( parentCategories ).map( ( [ key, data ], index ) => {
                if (
                  isNotEmpty( data )
                  && isNotEmpty( data.x )
                  && isNotEmpty( data.width )
                  && !isNaN( data.x )
                  && !isNaN( data.width )
                ) {
                  return <rect
                    key={ `${key}_${index}`}
                    y={0}
                    x={data.x}
                    rx={0.25}
                    ry={0.25}
                    width={data.width}
                    height={containerHeight - 0 }
                    fill={data.fill}
                    onClick={ e => handleCategoryRectClick( e, data ) }
                    onMouseMove={ e => handleMouseMove( e, data ) }
                    onMouseLeave={ handleMouseLeave }
                    className={`instanceCategoryRect ${hoveredCategoryKey === data.key ? 'isHovered' : '' }` }
                  />;
                }
              } )
          }
          {/* for each parent category, need to draw some lines to enclose each section visually */}
          {
            ( isNotEmpty( parentCategories ) && fullVersion ) &&
            Object.values( parentCategories ).map( ( pc, index ) => {
              // the first category, this will be below the chart
              if ( pc.key === 'deprioritized' && pc.value > 0 ) {
                return <g
                  key={index}
                >
                  {/* left vertical line */}
                  <line
                    className="legendLine"
                    x1={pc.x + 0.125}
                    x2={pc.x + 0.125}
                    y1={ containerHeight - 2 }
                    y2={ containerHeight - 3 }
                    stroke={ globalColors['status--green--25'] }
                    strokeWidth={ 0.125 }
                  />
                  {/* right vertical line */}
                  <line
                    className="legendLine"
                    x1={pc.x + pc.width }
                    x2={pc.x + pc.width }
                    y1={ containerHeight - 2 }
                    y2={ containerHeight - 3 }
                    stroke={ globalColors['status--green--25'] }
                    strokeWidth={ 0.125 }
                  />
                  {/* horizontal line */}
                  <line
                    className="legendLine"
                    x1={pc.x + 0.125}
                    x2={pc.x + pc.width }
                    y1={ containerHeight - 2 }
                    y2={ containerHeight - 2 }
                    stroke={ globalColors['status--green--25'] }
                    strokeWidth={ 0.25 }
                  />
                  {/* center vertical line */}
                  <line
                    className="legendLine"
                    x1={ ( pc.x + pc.width ) - 5 }
                    x2={ ( pc.x + pc.width ) - 5 }

                    y1={ containerHeight - 2 }
                    y2={ containerHeight - 0 }
                    stroke={ globalColors['status--green--25'] }
                    strokeWidth={ 0.125 }
                  />
                </g>;
              }
              // the second category, this will be below the chart
              if ( pc.key === 'for_review' && pc.value > 0 ) {

                let _x1, _x2;
                // the center line could be off to either the right or the left depending on the shape of the data
                // this will determine the left and right in relation to the legend below it
                // it is below the halfway mark x1 will be the x value
                if ( pc.x < ( containerWidth / 2 ) ) {
                  _x1 = pc.x;
                } else {
                  _x1 = containerWidth / 2;
                }
                // it is larger than the halfway mark on the right
                if ( ( pc.x + pc.width ) < ( containerWidth / 2 ) ) {
                  _x2 = containerWidth / 2;
                } else {
                  _x2 = pc.x + pc.width;
                }

                return <g
                  key={index}
                >
                  {/* left vertical line */}
                  <line
                    className="legendLine"
                    x1={pc.x}
                    x2={pc.x}
                    y1={ containerHeight - 3 }
                    y2={ containerHeight - 1 }
                    stroke={ globalColors['status--red--20'] }
                    strokeWidth={ 0.125 }
                  />
                  {/* right vertical line */}
                  <line
                    className="legendLine"
                    x1={pc.x + pc.width }
                    x2={pc.x + pc.width }
                    y1={ containerHeight - 3 }
                    y2={ containerHeight - 1 }
                    stroke={ globalColors['status--red--20'] }
                    strokeWidth={ 0.125 }
                  />
                  {/* horizontal line */}
                  <line
                    className="legendLine"
                    x1={ _x1 }
                    x2={ _x2 }
                    y1={ containerHeight - 1 }
                    y2={ containerHeight - 1 }
                    stroke={ globalColors['status--red--20'] }
                    strokeWidth={ 0.25 }
                  />
                  {/* center vertical line */}
                  <line
                    className="legendLine"
                    x1={ containerWidth / 2 }
                    x2={ containerWidth / 2 }
                    y1={ containerHeight - 1 }
                    y2={ containerHeight }
                    stroke={ globalColors['status--red--20'] }
                    strokeWidth={ 0.125 }
                  />
                </g>;
              }
              // the third category, this will be below the chart
              if ( pc.key === 'prioritized' && pc.value > 0 ) {
                return <g
                  key={index}
                >
                  {/* left vertical line */}
                  <line
                    className="legendLine"
                    x1={pc.x}
                    x2={pc.x}
                    y1={ containerHeight - 3 }
                    y2={ containerHeight - 2 }
                    stroke={ globalColors['status--red--40'] }
                    strokeWidth={ 0.125 }
                  />
                  {/* right vertical line */}
                  <line
                    className="legendLine"
                    x1={pc.x + pc.width }
                    x2={pc.x + pc.width }
                    y1={ containerHeight - 3 }
                    y2={ containerHeight - 2 }
                    stroke={ globalColors['status--red--40'] }
                    strokeWidth={ 0.125 }
                  />
                  {/* horizontal line */}
                  <line
                    className="legendLine"
                    x1={ pc.x }
                    x2={ pc.x + pc.width }
                    y1={ containerHeight - 2 }
                    y2={ containerHeight - 2 }
                    stroke={ globalColors['status--red--40'] }
                    strokeWidth={ 0.25 }
                  />
                  {/* center vertical line */}
                  <line
                    className="legendLine"
                    x1={pc.x + 5 }
                    x2={pc.x + 5 }
                    y1={ containerHeight - 2 }
                    y2={ containerHeight - 0 }
                    stroke={ globalColors['status--red--40'] }
                    strokeWidth={ 0.125 }
                  />
                </g>;
              }
            } )
          }
        </svg>
      }
      <div className={`legendsContainer ${fullVersion ? '' : 'truncatedVersion' }`}>
        {
          ( isNotEmpty( parentCategories ) && isNotEmpty( parentCategories.prioritized ) ) &&
          <div className="categoryLegendWrapper prioritized">
            <InstanceCategoryLabel
              onRefresh={ isNotEmpty( onRefresh ) ? onRefresh : () => {} }
              category={ parentCategories.prioritized }
              setHoveredCategoryKey={ setHoveredCategoryKey }
              hoveredCategoryKey={ hoveredCategoryKey }
              hasRefresh={ isNotEmpty( onRefresh ) }
            />
            {
              ( isNotEmpty( parentCategories.prioritized.childValues ) && fullVersion ) &&
              <div className="legendItemsWrapper prioritized">
                <div className="legendColumn">
                  {
                    parentCategories.prioritized.childValues.map( ( child, _index ) => {
                      if ( child.reviewSection !== 'unsupported' && _index < 2 ) {
                        return <InstanceCategoryLabel
                          onRefresh={ isNotEmpty( onRefresh ) ? onRefresh : () => {} }
                          key={_index}
                          category={child}
                          setHoveredCategoryKey={setHoveredCategoryKey}
                          hoveredCategoryKey={hoveredCategoryKey}
                          hasRefresh={ isNotEmpty( onRefresh ) }
                        />;
                      }
                    } )
                  }
                </div>
                <div className="legendColumn">
                  {
                    parentCategories.prioritized.childValues.map( ( child, _index ) => {
                      if ( child.reviewSection !== 'unsupported' && _index >= 2 ) {
                        return <InstanceCategoryLabel
                          onRefresh={ isNotEmpty( onRefresh ) ? onRefresh : () => {} }
                          key={_index}
                          category={child}
                          setHoveredCategoryKey={setHoveredCategoryKey}
                          hoveredCategoryKey={hoveredCategoryKey}
                          hasRefresh={ isNotEmpty( onRefresh ) }
                        />;
                      }
                    } )
                  }
                </div>
              </div>
            }
          </div>
        }
        {
          ( isNotEmpty( parentCategories ) && isNotEmpty( parentCategories.for_review ) ) &&
          <div className="categoryLegendWrapper for_review">
            <InstanceCategoryLabel
              onRefresh={ isNotEmpty( onRefresh ) ? onRefresh : () => {} }
              category={ parentCategories.for_review }
              setHoveredCategoryKey={ setHoveredCategoryKey }
              hoveredCategoryKey={ hoveredCategoryKey }
              hasRefresh={ isNotEmpty( onRefresh ) }
            />
            {
              ( isNotEmpty( parentCategories.for_review.childValues ) && fullVersion ) &&
              <div className="legendItemsWrapper for_review">
                <div className="legendColumn">
                  {
                    parentCategories.for_review.childValues.map( ( child, _index ) => {
                      if ( child.reviewSection !== 'unsupported' ) {
                        return <InstanceCategoryLabel
                          onRefresh={ isNotEmpty( onRefresh ) ? onRefresh : () => {} }
                          key={_index}
                          category={child}
                          setHoveredCategoryKey={setHoveredCategoryKey}
                          hoveredCategoryKey={hoveredCategoryKey}
                          hasRefresh={ isNotEmpty( onRefresh ) }
                        />;
                      }
                    } )
                  }
                </div>
                <div className="legendColumn">
                  <label>Unsupported</label>
                  {
                    parentCategories.for_review.childValues.map( ( child, _index ) => {
                      if ( child.reviewSection === 'unsupported' ) {
                        return <InstanceCategoryLabel
                          onRefresh={ isNotEmpty( onRefresh ) ? onRefresh : () => {} }
                          key={_index}
                          category={child}
                          setHoveredCategoryKey={setHoveredCategoryKey}
                          hoveredCategoryKey={hoveredCategoryKey}
                          hasRefresh={ isNotEmpty( onRefresh ) }
                        />;
                      }
                    } )
                  }
                </div>
              </div>
            }
          </div>
        }
        {
          ( isNotEmpty( parentCategories ) && isNotEmpty( parentCategories.deprioritized ) ) &&
          <div className="categoryLegendWrapper deprioritized">
            <InstanceCategoryLabel
              onRefresh={ isNotEmpty( onRefresh ) ? onRefresh : () => {} }
              category={ parentCategories.deprioritized }
              setHoveredCategoryKey={ setHoveredCategoryKey }
              hoveredCategoryKey={ hoveredCategoryKey }
              hasRefresh={ isNotEmpty( onRefresh ) }
            />
            {
              ( isNotEmpty( parentCategories.deprioritized.childValues ) && fullVersion ) &&
              <div className="legendItemsWrapper deprioritized">
                <div className="legendColumn">
                  {
                    parentCategories.deprioritized.childValues.map( ( child, _index ) => {
                      return <InstanceCategoryLabel
                        onRefresh={ isNotEmpty( onRefresh ) ? onRefresh : () => {} }
                        key={_index}
                        category={child}
                        setHoveredCategoryKey={setHoveredCategoryKey}
                        hoveredCategoryKey={hoveredCategoryKey}
                        hasRefresh={ isNotEmpty( onRefresh ) }
                      />;
                    } )
                  }
                </div>
              </div>
            }
          </div>
        }
      </div>
      {
        ( decodeURLHash().page !== 'reporting_dashboard' && shouldIncludeLabels ) &&
        <div
          className={ `categoryLabelContainer ${ ( isNotEmpty( labelCategory ) && showLabel ) ? 'visible' : '' } ` }
          id="categoryLabelContainer"
          style={{
            top: labelY,
            left: labelX,
          }}
        >
          {
            ( isNotEmpty( labelCategory ) && isNotEmpty( labelCategory.value ) && isNotEmpty( labelCategory.label ) ) &&
            <h2>
              <span className="labelColor" style={ { background: labelCategory.fill } } />
              { labelCategory.label }
              <strong>({ formatNumber( labelCategory.value ) })</strong>
            </h2>
          }
          {
            ( isNotEmpty( labelCategory ) && isNotEmpty( labelCategory.description ) ) &&
            labelCategory.description
          }
        </div>
      }

    </div>
  );
};

export default VulnerabilityInstancesCategories;