/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import SetupPage from '../../../shared/SetupComponents/SetupPage';

import {
  isValidPort,
  isUUID,
  isHostName,
} from '../../../shared/Form/Validators';

import {
  AWSRegionsOptions,
  getGlobalSettings,
  isFalsey,
  isNotEmpty,
  isTruthy,
  vulnerabilityScannerLogo,
  vulnerabilityScannerNameMap,
  vulnerabilitySourceLabel,
} from '../../../shared/Utilities';
import InlineSVG from '../../../shared/InlineSVG';

import './style.scss';

import {
  recordData,
} from './data';

import { OnboardingWizardContext } from '../../../Contexts/OnboardingWizard';
import { normalizedAttribute } from '../../../shared/Form/Shared';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';
import Loading from '../../../shared/Loading';
import { makeRequest } from '../../../../legacy/io';
import RatingBadge from '../../../shared/RatingBadge';
import { hasFeatureAccess } from '../../App/AccessControl.js';
import { CurrentUserContext } from '../../../Contexts/CurrentUser.js';

const VulnerabilityScanners = () => {

  const [ scanners, setScanners ] = React.useState( [] );
  const [ loading, setLoading ] = React.useState( false );

  const [ fields, setFields ] = React.useState( null );

  // ContextualHelp getters and setters
  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const EDR_KEYS = [
    'crowdstrikeapi',
    'sentineloneapi',
    'cbc',
    'msatp',
    'wazuhapi',
  ];
  const API_KEYS = [
    'lansweeperapi',
    'nessusapi',
    'tenableio',
    'tenablesc',
    'rapid7api',
    'qualysapi',
    'msatp',
    'cbc',
    'nozomiapi',
    'eclypsiumapi',
    'awsinspectorapi',
  ];
  const MANUAL_KEYS = [
    'nessus',
    'openvas',
    'rapid7',
    'qualys',
  ];
  const EMPTY_FIELDS = {
    selector: {
      fields: [
        {
          type: 'select',
          label: 'External Vulnerability Source',
          attribute: 'tool',
          defaultValue: 'crowdstrikeapi',
          asDropdown: true,
          disableOnEdit: true,
          allowInDemoMode: true,
          options: [
            {
              label: 'EDR/XDR',
              options: {
                crowdstrikeapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'crowdstrikeapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['crowdstrikeapi'] }
                  <RatingBadge rating="beta" />
                </span>,
                sentineloneapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'sentineloneapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['sentineloneapi'] }
                  <RatingBadge rating="new" />
                </span>,
                cbc: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'cbc' ) }
                  </span>
                  { vulnerabilityScannerNameMap['cbc'] }
                </span>,
                msatp: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'msatp' ) }
                  </span>
                  { vulnerabilityScannerNameMap['msatp'] }
                </span>,
                wazuhapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'wazuhapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['wazuhapi'] }
                  <RatingBadge rating="new" />
                </span>,
              },
            },
            {
              label: 'Vulnerability Scanners (API)',
              options: {
                lansweeperapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'lansweeperapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['lansweeperapi'] }
                  <RatingBadge rating="new" />
                </span>,
                nessusapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'nessusapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['nessusapi'] }
                </span>,
                tenableio: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'tenableio' ) }
                  </span>
                  { vulnerabilityScannerNameMap['tenableio'] }
                </span>,
                tenablesc: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'tenablesc' ) }
                  </span>
                  { vulnerabilityScannerNameMap['tenablesc'] }
                </span>,
                rapid7api: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'rapid7api' ) }
                  </span>
                  { vulnerabilityScannerNameMap['rapid7api'] }
                </span>,
                qualysapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'qualysapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['qualysapi'] }
                </span>,
                msatp: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'msatp' ) }
                  </span>
                  { vulnerabilityScannerNameMap['msatp'] }
                </span>,
                cbc: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'cbc' ) }
                  </span>
                  { vulnerabilityScannerNameMap['cbc'] }
                </span>,
                nozomiapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'nozomiapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['nozomiapi'] }
                  <RatingBadge rating="beta" />
                </span>,
                eclypsiumapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'eclypsiumapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['eclypsiumapi'] }
                  <RatingBadge rating="new" />
                </span>,
                awsinspectorapi: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'awsinspectorapi' ) }
                  </span>
                  { vulnerabilityScannerNameMap['awsinspectorapi'] }
                  <RatingBadge rating="new" />
                </span>,

              },
            },
            {
              label: 'Vulnerability Scanners (Manual Import)',
              options: {
                nessus: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'nessus' ) }
                  </span>
                  { vulnerabilityScannerNameMap['nessus'] }
                </span>,
                openvas: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'openvas' ) }
                  </span>
                  { vulnerabilityScannerNameMap['openvas'] }
                </span>,
                rapid7: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'rapid7' ) }
                  </span>
                  { vulnerabilityScannerNameMap['rapid7'] }
                </span>,
                qualys: <span>
                  <span className="logoWrapper">
                    { vulnerabilityScannerLogo( 'qualys' ) }
                  </span>
                  { vulnerabilityScannerNameMap['qualys'] }
                </span>,
              },
            },
          ],
        },
        {
          type: 'hidden',
          attribute: 'outbound_proxy_enabled',
          defaultValue: '0',
        },
        {
          includeIf: { attribute: 'tool', value: 'nessusapi' },
          type: 'contentBlock',
          attribute: 'nessusapi_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'tenableio' },
          type: 'contentBlock',
          attribute: 'tenableio_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'tenablesc' },
          type: 'contentBlock',
          attribute: 'tenablesc_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'rapid7api' },
          type: 'contentBlock',
          attribute: 'rapid7api_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'qualysapi' },
          type: 'contentBlock',
          attribute: 'qualysapi_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'msatp' },
          type: 'contentBlock',
          attribute: 'msatp_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'cbc' },
          type: 'contentBlock',
          attribute: 'cbc_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'nessus' },
          type: 'contentBlock',
          attribute: 'nessus_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'openvas' },
          type: 'contentBlock',
          attribute: 'openvas_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'rapid7' },
          type: 'contentBlock',
          attribute: 'rapid7_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'lansweeperapi' },
          type: 'contentBlock',
          attribute: 'lansweeperapi_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'sentineloneapi' },
          type: 'contentBlock',
          attribute: 'sentinelone_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'qualys' },
          type: 'contentBlock',
          attribute: 'qualys_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'nozomi' },
          type: 'contentBlock',
          attribute: 'nozomi_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'nozomiapi' },
          type: 'contentBlock',
          attribute: 'nozomiapi_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'crowdstrike' },
          type: 'contentBlock',
          attribute: 'crowdstrike_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'crowdstrikeapi' },
          type: 'contentBlock',
          attribute: 'crowdstrikeapi_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'awsinpsector' },
          type: 'contentBlock',
          attribute: 'awsinpsector_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'awsinspectorapi' },
          type: 'contentBlock',
          attribute: 'awsinspectorapi_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'eclypsium' },
          type: 'contentBlock',
          attribute: 'eclypsium_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'eclypsiumapi' },
          type: 'contentBlock',
          attribute: 'eclypsiumapi_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'wazuh' },
          type: 'contentBlock',
          attribute: 'wazuh_help',
        },
        {
          includeIf: { attribute: 'tool', value: 'wazuhapi' },
          type: 'contentBlock',
          attribute: 'wazuhapi_help',
        },
      ],
    },
    nessusapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'nessusapi' },
          type: 'text',
          label: 'Nessus Pro Hostname',
          attribute: 'nessusapi_server',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nessusapi' },
          type: 'number',
          label: 'Nessus Pro TCP Port',
          attribute: 'nessusapi_port',
          defaultValue: 8834,
          required: true,
          validators: [ isValidPort ],
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nessusapi' },
          type: 'text',
          label: 'Access key',
          attribute: 'nessusapi_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nessusapi' },
          type: 'password',
          removeGenerate: true,
          label: 'Secret key',
          attribute: 'nessusapi_secret',
          required:
          true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nessusapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'nessusapi_tofu',
          defaultValue: true,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nessusapi' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'nessusapi_use_proxy',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    tenableio: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'tenableio' },
          type: 'text',
          label: 'Access key',
          attribute: 'tenableio_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'tenableio' },
          type: 'password',
          removeGenerate: true,
          label: 'Secret key',
          attribute: 'tenableio_secret',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'tenableio' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'tenableio_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'tenableio' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'tenableio_use_proxy',
          defaultValue: true,
          allowInDemoMode: true,
        },
      ],
    },
    tenablesc: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'tenablesc' },
          type: 'text',
          label: 'Tenable.sc Hostname',
          attribute: 'tenablesc_server',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'tenablesc' },
          type: 'text',
          label: 'Username',
          attribute: 'tenablesc_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'tenablesc' },
          type: 'password',
          removeGenerate: true,
          label: 'Password',
          attribute: 'tenablesc_secret',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'tenablesc' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'tenablesc_tofu',
          defaultValue: true,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'tenablesc' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'tenablesc_use_proxy',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    rapid7api: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'rapid7api' },
          type: 'text',
          label: 'InsightVM Hostname',
          attribute: 'rapid7api_server',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'rapid7api' },
          type: 'number',
          label: 'InsightVM TCP Port',
          attribute: 'rapid7api_port',
          defaultValue: 3780,
          required: true,
          validators: [ isValidPort ],
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'rapid7api' },
          type: 'text',
          label: 'Username',
          attribute: 'rapid7api_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'rapid7api' },
          type: 'password',
          removeGenerate: true,
          label: 'Password',
          attribute: 'rapid7api_secret',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'rapid7api' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'rapid7api_tofu',
          defaultValue: true,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'rapid7api' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'rapid7api_use_proxy',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    qualysapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'qualysapi' },
          type: 'text',
          label: 'Hostname/Server',
          attribute: 'qualysapi_server',
          required: true,
          defaultValue: 'qualysapi.qualys.com',
          help: <HelpTrigger helpKey="qualys_hostname" />,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'qualysapi' },
          type: 'text',
          label: 'Username',
          attribute: 'qualysapi_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'qualysapi' },
          type: 'password',
          removeGenerate: true,
          label: 'Password',
          attribute: 'qualysapi_secret',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'qualysapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'qualysapi_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'qualysapi' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'qualysapi_use_proxy',
          defaultValue: true,
          allowInDemoMode: true,
        },
      ],
    },
    msatp: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'msatp' },
          type: 'text',
          label: 'Endpoint URI',
          attribute: 'msatp_server',
          required: true,
          defaultValue: 'api.securitycenter.microsoft.com',
          allowInDemoMode: true,
          // help: <HelpTrigger helpKey="msatp_server" />,
        },
        {
          includeIf: { attribute: 'tool', value: 'msatp' },
          type: 'text',
          label: 'Tenant ID',
          attribute: 'msatp_domain',
          required: true,
          validators: [ isUUID ],
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'msatp' },
          type: 'text',
          label: 'App ID',
          attribute: 'msatp_username',
          required: true,
          validators: [ isUUID ],
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'msatp' },
          type: 'password',
          removeGenerate: true,
          label: 'Secret Key',
          attribute: 'msatp_secret',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'msatp' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'msatp_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'msatp' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'msatp_use_proxy',
          defaultValue: true,
          allowInDemoMode: true,
        },
      ],
    },
    cbc: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'cbc' },
          type: 'text',
          label: 'API Hostname',
          attribute: 'cbc_server',
          required: true,
          validators: [ isHostName ],
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'cbc' },
          type: 'text',
          label: 'Organization Key',
          attribute: 'cbc_domain',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'cbc' },
          type: 'text',
          label: 'API ID',
          attribute: 'cbc_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'cbc' },
          type: 'password',
          removeGenerate: true,
          label: 'API Secret',
          attribute: 'cbc_secret',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'cbc' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'cbc_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'cbc' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'cbc_use_proxy',
          defaultValue: true,
          allowInDemoMode: true,
        },
      ],
    },
    sentineloneapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'sentineloneapi' },
          type: 'text',
          label: 'Hostname/Server',
          attribute: 'sentineloneapi_server',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'sentineloneapi' },
          type: 'textarea',
          label: 'Secret',
          attribute: 'sentineloneapi_secret',
          required: true,
          defaultValue: '',
          placeholder: 'Leave blank if editing',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'sentineloneapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'sentineloneapi_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'sentinelone' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'sentinelone_use_proxy',
          defaultValue: true,
          allowInDemoMode: true,
        },
      ],
    },
    lansweeperapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'lansweeperapi' },
          type: 'text',
          label: 'Site ID',
          attribute: 'lansweeperapi_username',
          required: true,
          defaultValue: '',
          validators: [ isUUID ],
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'lansweeperapi' },
          type: 'password',
          removeGenerate: true,
          label: 'Application identity code',
          attribute: 'lansweeperapi_secret',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'lansweeperapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'lansweeperapi_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'lansweeperapi' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'lansweeperapi_use_proxy',
          defaultValue: true,
          allowInDemoMode: true,
        },
      ],
    },
    crowdstrikeapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'crowdstrikeapi' },
          type: 'text',
          label: 'CrowdStrike Hostname',
          attribute: 'crowdstrikeapi_server',
          required: true,
          defaultValue: '',
          validators: [ isHostName ],
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'crowdstrikeapi' },
          type: 'text',
          label: 'Client ID',
          defaultLabel: 'Client ID',
          attribute: 'crowdstrikeapi_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'crowdstrikeapi' },
          type: 'password',
          label: 'Client Secret',
          attribute: 'crowdstrikeapi_secret',
          required: true,
          removeGenerate: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'crowdstrikeapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'crowdstrikeapi_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'crowdstrikeapi' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'crowdstrikeapi_use_proxy',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    wazuhapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'wazuhapi' },
          type: 'text',
          label: 'Hostname (IP Address or DNS Name)',
          attribute: 'wazuhapi_server',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'wazuhapi' },
          type: 'number',
          label: 'Port',
          attribute: 'wazuhapi_port',
          defaultValue: 55000,
          required: true,
          validators: [ isValidPort ],
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'wazuhapi' },
          type: 'text',
          label: 'Username',
          attribute: 'wazuhapi_username',
          defaultValue: '',
          required: true,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'wazuhapi' },
          type: 'password',
          label: 'Password',
          attribute: 'wazuhapi_secret',
          required: true,
          removeGenerate: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'wazuhapi' },
          type: 'checkbox',
          label: 'Include Wazuh VM in scanning results?',
          help: <HelpTrigger helpKey="include_wazuh_host" />,
          attribute: 'wazuhapi_include_wazuh_host',
          defaultValue: true,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'wazuhapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'wazuhapi_tofu',
          defaultValue: true,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'wazuhapi' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'wazuhapi_use_proxy',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    nozomiapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'nozomiapi' },
          type: 'text',
          label: 'Nozomi Guardian Hostname',
          attribute: 'nozomiapi_server',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nozomiapi' },
          type: 'number',
          label: 'Nozomi Guardian Port',
          attribute: 'nozomiapi_port',
          defaultValue: 443,
          required: true,
          validators: [ isValidPort ],
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nozomiapi' },
          type: 'select',
          label: 'Authentication Type',
          attribute: 'nozomiapi_auth_type',
          defaultValue: 'basic',
          required: true,
          options: {
            basic: 'Basic',
            openapi: 'OpenAPI',
          },
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nozomiapi' },
          type: 'text',
          label: 'Username',
          defaultLabel: 'Username',
          conditionalLabel: { attribute: 'nozomiapi_auth_type', labels: { basic: 'Username', openapi: 'Key Name' } },
          attribute: 'nozomiapi_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nozomiapi' },
          type: 'password',
          label: 'Password',
          defaultLabel: 'Password',
          conditionalLabel: { attribute: 'nozomiapi_auth_type', labels: { basic: 'password', openapi: 'Key Secret' } },
          attribute: 'nozomiapi_secret',
          required: true,
          removeGenerate: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nozomiapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'nozomiapi_tofu',
          defaultValue: true,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'nozomiapi' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'nozomiapi_use_proxy',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    eclypsiumapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'eclypsiumapi' },
          type: 'text',
          label: 'Eclyspium Hostname',
          attribute: 'eclypsiumapi_server',
          required: true,
          defaultValue: '',
          validators: [ isHostName ],
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'eclypsiumapi' },
          type: 'text',
          label: 'Username',
          defaultLabel: 'Client ID',
          attribute: 'eclypsiumapi_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'eclypsiumapi' },
          type: 'password',
          label: 'Password',
          attribute: 'eclypsiumapi_secret',
          required: true,
          removeGenerate: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'eclypsiumapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'eclypsiumapi_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'eclypsiumapi' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'eclypsiumapi_use_proxy',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    awsinspectorapi: {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'awsinspectorapi' },
          type: 'text',
          label: 'Access Key ID',
          attribute: 'awsinspectorapi_username',
          required: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'awsinspectorapi' },
          type: 'password',
          label: 'Secret Access Key',
          attribute: 'awsinspectorapi_secret',
          required: true,
          removeGenerate: true,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'awsinspectorapi' },
          type: 'select',
          asMultiple: true,
          label: 'Regions',
          attribute: 'awsinspectorapi_regions',
          required: true,
          options: AWSRegionsOptions,
          defaultValue: [ 'us-east-1', 'us-east-2', 'us-west-1', 'us-west-2', 'ca-central-1' ],
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'awsinspectorapi' },
          type: 'select',
          label: 'Edition',
          attribute: 'awsinspectorapi_edition',
          required: true,
          options: { v1: 'v1', v2: 'v2' },
          defaultValue: 'v1',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'awsinspectorapi' },
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          attribute: 'awsinspectorapi_tofu',
          defaultValue: false,
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'tool', value: 'awsinspectorapi' },
          disableIf: { attribute: 'outbound_proxy_enabled', check: isFalsey },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'awsinspectorapi_use_proxy',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    nessus: { fields: [] },
    openvas: { fields: [] },
    rapid7: { fields: [] },
    qualys: { fields: [] },
  };

  const scannerHelpLinkMap = {
    nessusapi:  {
      description: '',
      helpLink: '#.=help_documentation&main=reference_guide&section=vulnerability_scanners&help_page=nessus',
    },
    tenableio:  {
      description: '',
      helpLink: '#.=help_documentation&main=reference_guide&section=vulnerability_scanners&help_page=tenableio',
    },
    tenablesc:  {
      description: '',
      helpLink: '#.=help_documentation&main=reference_guide&section=vulnerability_scanners&help_page=tenablesc',
    },
    rapid7api:  {
      description: '',
      helpLink: '#.=help_documentation&main=reference_guide&section=vulnerability_scanners&help_page=rapid7',
    },
    qualysapi:  {
      // eslint-disable-next-line max-len
      description:  'Please provide credentials for a user in your Qualys system that has permissions to read scan data for all assets, create and edit report templates, and generate and read reports.',
      helpLink: '#.=help_documentation&main=reference_guide&section=vulnerability_scanners&help_page=qualys',
    },
    msatp:      {
      description: '',
      helpLink: '#.=help_documentation&main=reference_guide&section=vulnerability_scanners&help_page=msde',
    },
    cbc:      {
      description: '',
      helpLink: '#.=help_documentation&main=reference_guide&section=vulnerability_scanners&help_page=cbc',
    },
  };

  const initialize = async() => {
    setLoading( true );

    const latestSettings = await getGlobalSettings( 'global' );

    const proxyEnabled = isTruthy( latestSettings.outbound_proxy_enabled );

    const contentBlocks = {
      nozomi: vulnerabilityScannerLogo( 'nozomi', 'full' ),
      nozomiapi: vulnerabilityScannerLogo( 'nozomiapi', 'full' ),
      crowdstrike: vulnerabilityScannerLogo( 'crowdstrike', 'full' ),
      crowdstrikeapi: vulnerabilityScannerLogo( 'crowdstrikeapi', 'full' ),
      eclypsium: vulnerabilityScannerLogo( 'eclypsium', 'full' ),
      eclypsiumapi: vulnerabilityScannerLogo( 'eclypsiumapi', 'full' ),
      awsinspector: vulnerabilityScannerLogo( 'awsinspector', 'full' ),
      awsinspectorapi: vulnerabilityScannerLogo( 'awsinspectorapi', 'full' ),
      wazuh: vulnerabilityScannerLogo( 'wazuh', 'full' ),
      wazuhapi: vulnerabilityScannerLogo( 'wazuhapi', 'full' ),
      lansweeper: vulnerabilityScannerLogo( 'lansweeper', 'full' ),
      lansweeperapi: vulnerabilityScannerLogo( 'lansweeperapi', 'full' ),
      sentinelone:  vulnerabilityScannerLogo( 'sentineloneapi', 'full' ),
      sentineloneapi: vulnerabilityScannerLogo( 'sentineloneapi', 'full' ),
    };

    Object.entries( scannerHelpLinkMap ).map( ( [ scannerKey, value ], index ) => {

      contentBlocks[scannerKey] =  <React.Fragment key={ index }>
        { vulnerabilityScannerLogo( scannerKey, 'full' ) }
        <p>
          Please provide the following information/credentials
          for <strong>{ vulnerabilityScannerNameMap[scannerKey] }</strong> in
          order to integrate it properly into our system.
        </p>
        { isNotEmpty( value.description ) && <p>{ value.description }</p> }
        <p>
          For more information, please read the full
          documentation
          <a href={ value.helpLink } target="_blank" rel="noreferrer noopener" className="inlineLink inline">
            here <InlineSVG type="newTabLink" version="primary" />
          </a>
        </p>
      </React.Fragment>;
    } );

    const _fields = { ...EMPTY_FIELDS };

    let availableThirdPartySources = [];

    if (
      isNotEmpty( licenseInfo )
      && isNotEmpty( licenseInfo.license_effective_edition )
      && isNotEmpty( licenseInfo.license_effective_edition.third_party_integrations )
    ) {
      availableThirdPartySources = licenseInfo.license_effective_edition.third_party_integrations;
    }

    _fields.selector.fields.map( field => {

      if ( field.attribute === 'outbound_proxy_enabled' ) {
        field.defaultValue = proxyEnabled
          ? '1'
          : '0';
      }

      if( field.type === 'contentBlock' ) {
        // eslint-disable-next-line
        const fieldScanner = field.attribute.split( '_' )[0];

        if ( isNotEmpty( contentBlocks[fieldScanner] ) ) {
          field.defaultValue = contentBlocks[fieldScanner];
        } else {
          field.defaultValue =  <React.Fragment>
            <p>
              To import files, save this vulnerability scanner setting, then click the
              "Import Files" button on the new row
              created for <strong> { vulnerabilityScannerNameMap[fieldScanner] } </strong>.
            </p>
          </React.Fragment>;
        }
      }

      if ( isNotEmpty( availableThirdPartySources ) && field.attribute === 'tool' ) {
        let options = [
          {
            label: 'EDR/XDR',
            options: {},
          },
          {
            label: 'Vulnerability Scanners (API)',
            options: {},
          },
          {
            label: 'Vulnerability Scanners (Manual Import)',
            options: {},
          },
        ];
        availableThirdPartySources.map( scanner => {
          EDR_KEYS.map( key => {
            if ( scanner === key ) {
              options[0].options[scanner] = <span>
                <span className="logoWrapper">
                  { vulnerabilityScannerLogo( scanner ) }
                </span>
                { vulnerabilityScannerNameMap[scanner] }
              </span>;
            }
          } );
          API_KEYS.map( key => {
            if ( scanner === key ) {
              options[1].options[scanner] = <span>
                <span className="logoWrapper">
                  { vulnerabilityScannerLogo( scanner ) }
                </span>
                { vulnerabilityScannerNameMap[scanner] }
              </span>;
            }
          } );
          MANUAL_KEYS.map( key => {
            if ( scanner === key ) {
              options[2].options[scanner] = <span>
                <span className="logoWrapper">
                  { vulnerabilityScannerLogo( scanner ) }
                </span>
                { vulnerabilityScannerNameMap[scanner] }
              </span>;
            }
          } );
        } );
        // remove any sections that have no options
        options = options.filter( group => {
          return Object.keys( group.options ).length > 0;
        } );
        field.defaultValue = 'tenableio';
        field.options = options;
      }
    } );

    // if the proxy is disabled globally, need to adjust the defaults to unchecked for the
    // various scanners
    if ( !proxyEnabled ) {
      Object.values( _fields ).map( group => {
        group.fields.map( field => {
          if ( normalizedAttribute( field, 'vulnerability_scanner' ) === 'use_proxy' ) {
            field.defaultValue = false;
          }
        } );
      } );
    }
    setLoading( false );
    setFields( _fields );
  };

  // need to setup all the descriptions for help links for each vuln scanner
  // also need to check the global settings for the proxy
  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) && isNotEmpty( licenseInfo ) ) {
      initialize( );
    }
  }, [ currentUser, licenseInfo ] );

  const onRefresh = () => {
    makeRequest( 'SEARCH', '/project/default/third_party_setting', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'tool', 'settings', 'credential_id', 'category' ],
    } ).then( vulnResponse => {
      if ( vulnResponse ) {
        if ( vulnResponse['results'] && vulnResponse['results'].length ) {
          const _vulnerabilityScanners = [];
          vulnResponse.results.map( v => {
            if ( v.category !== 'ticketing_software' ) {
              _vulnerabilityScanners.push( v );
            }
          } );
          setScanners( _vulnerabilityScanners );
        } else {
          setScanners( [] );
        }
      }
    } );
  };

  const onSave = (
    selectedScannerWithCred,
    isValid,
    fieldStates,
  ) => {

    if ( isValid && isNotEmpty( fieldStates ) ) {

      const selectedTool = fieldStates.tool.updatedValue;

      const manualTools = [
        'nessus',
        'openvas',
        'rapid7',
        'qualys',
      ];

      // this is a manual tool
      if ( manualTools.includes( selectedTool ) ) {

        const scannerRecord = {
          tool: selectedTool,
          settings: { label: 'Manual Upload' },
        };

        // editing an existing record, add the id
        if ( selectedScannerWithCred ) {
          scannerRecord.id = selectedScannerWithCred.id;
        }

        // eslint-disable-next-line max-len
        makeRequest( 'UPSERT', '/project/default/third_party_setting', { 'records': [ scannerRecord ] } ).then( response => {
          const path = `/var/lib/deepsurface/scan_results/${scannerRecord['tool']}/${response['results'][0]['id']}`;

          makeRequest( 'MKDIR', '/filemanager', { 'path': path } ).then( () => {
            onRefresh();
            if (
              isNotEmpty( currentUser )
              && isNotEmpty( licenseInfo )
              && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' )
            ) {
              refreshWizard();
            }
          } );
        } );

      // this is an api
      } else {
        const credential = {
          protocol: selectedTool,
          // eslint-disable-next-line camelcase
          eval_order: 0,
          domain: '',
          username: selectedTool === 'sentineloneapi'
            ? fieldStates[`${selectedTool}_server`].updatedValue
            : fieldStates[`${selectedTool}_username`].updatedValue,
          secret: fieldStates[`${selectedTool}_secret`].updatedValue,
        };

        if ( isNotEmpty( fieldStates[`${selectedTool}_domain`] ) ) {
          credential.domain = fieldStates[`${selectedTool}_domain`].updatedValue;
        }

        if ( selectedTool === 'msatp' ) {
          credential.domain = credential.domain?.trim();
          credential.username = credential.username?.trim();
        }

        // we are editing an existing record
        if ( isNotEmpty( selectedScannerWithCred ) ) {
          // need to remove the blank password, if it has not been altered
          if ( fieldStates[`${selectedTool}_secret`].updatedValue === '' ) {
            delete credential['secret'];
          }
          // add the id of the existing cred, so that it updates instead of creating a new record
          credential.id = selectedScannerWithCred.settings.credential_id;
        }
        makeRequest( 'UPSERT', '/project/default/credential', { 'records': [ credential ] } ).then( response => {

          const [ credential ] = response?.results;

          const scannerRecord = {
            tool: selectedTool,
            settings: {
              // eslint-disable-next-line camelcase
              credential_id: credential.id,
            },
          };

          // add in additional fields
          if ( fieldStates[`${selectedTool}_server`] && fieldStates[`${selectedTool}_server`].updatedValue ) {
            scannerRecord.settings.server = fieldStates[`${selectedTool}_server`].updatedValue;
          }
          if ( fieldStates[`${selectedTool}_port`] && fieldStates[`${selectedTool}_port`].updatedValue ) {
            scannerRecord.settings.port = fieldStates[`${selectedTool}_port`].updatedValue;
          }
          if ( fieldStates[`${selectedTool}_auth_type`] && fieldStates[`${selectedTool}_auth_type`].updatedValue ) {
            // eslint-disable-next-line camelcase
            scannerRecord.settings.auth_type = fieldStates[`${selectedTool}_auth_type`].updatedValue;
          }
          if ( fieldStates[`${selectedTool}_regions`] && fieldStates[`${selectedTool}_regions`].updatedValue ) {
            // eslint-disable-next-line camelcase
            scannerRecord.settings.regions = fieldStates[`${selectedTool}_regions`].updatedValue;
          }
          if ( fieldStates[`${selectedTool}_edition`] && fieldStates[`${selectedTool}_edition`].updatedValue ) {
            // eslint-disable-next-line camelcase
            scannerRecord.settings.edition = fieldStates[`${selectedTool}_edition`].updatedValue;
          }
          if ( fieldStates[`${selectedTool}_tofu`] ) {
            scannerRecord.settings.tofu = isNotEmpty( fieldStates[`${selectedTool}_tofu`].updatedValue )
              ? fieldStates[`${selectedTool}_tofu`].updatedValue
              : false;
          }
          if ( fieldStates[`${selectedTool}_use_proxy`] ) {
            // eslint-disable-next-line camelcase, max-len
            scannerRecord.settings.use_proxy = isNotEmpty( fieldStates[`${selectedTool}_use_proxy`].updatedValue )
              ? fieldStates[`${selectedTool}_use_proxy`].updatedValue
              : false;
          }
          if ( fieldStates[`${selectedTool}_include_wazuh_host`] ) {
            // eslint-disable-next-line camelcase, max-len
            scannerRecord.settings.include_wazuh_host = isNotEmpty( fieldStates[`${selectedTool}_include_wazuh_host`].updatedValue )
              ? fieldStates[`${selectedTool}_include_wazuh_host`].updatedValue
              : false;
          }
          // editing an existing record, add the id
          if ( selectedScannerWithCred ) {
            scannerRecord.id = selectedScannerWithCred.id;
          }

          const label = vulnerabilitySourceLabel( scannerRecord, credential );

          if ( isNotEmpty( scannerRecord.settings ) ) {
            scannerRecord.settings.label = label;
          } else {
            scannerRecord.settings = { label };
          }

          // eslint-disable-next-line max-len
          makeRequest( 'UPSERT', '/project/default/third_party_setting', { 'records': [ scannerRecord ] } ).then( () => {
            onRefresh();
            if (
              isNotEmpty( currentUser )
              && isNotEmpty( licenseInfo )
              && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' )
            ) {
              refreshWizard();
            }
          } );
        } );

      }
    }
  };

  return (
    <React.Fragment>
      { loading && <Loading /> }
      {
        isNotEmpty( fields ) &&
        <SetupPage
          onRefresh={onRefresh}
          onSave={onSave}
          records={scanners}
          setRecords={setScanners}
          recordType="vulnerability_source"
          recordData={recordData}
          useForm={true}
          fields={fields}
          alternateItemLayout
        />
      }
    </React.Fragment>
  );
};

export default VulnerabilityScanners;
