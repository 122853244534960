/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  formatNumber,
  getPercentileClass,
  isNotEmpty,
  numberToOrdinal,
} from '../../../../../shared/Utilities';
import Gauge from '../../../../../shared/Charts/Gauge';

import './PeerPercentile.scss';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import { getThemeColor } from '../../../../../shared/Themes';

const PeerPercentile = ( { data, settings } ) => {

  const [ chartData, setChartData ] = React.useState( null );
  const [ currentRisk, setCurrentRisk ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );
  const [ fill, setFill ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( true );

  const getFill = percentile => {
    if ( percentile <= 0.25 ) {
      return getThemeColor( '--high' );
    }
    if ( percentile <= 0.5 ) {
      return getThemeColor( '--low' );
    }
    if ( percentile <= 0.75 ) {
      return getThemeColor( '--minimal' );
    }
    return getThemeColor( 'primaryBlue' );
  };

  const setupData = async ( data ) => {
    setLoading( true );
    if ( data.results ) {
      const current = data.results[data.results.length - 1];
      if ( isNotEmpty( current ) ) {
        setChartData( current.percentile );
        setTotal( 1 );
        setCurrentRisk( Math.round( current.risk ) );
        setFill( getFill( current.percentile ) );
        setLoading( false );
      } else {
        setLoading( false );
      }
    } else {
      setLoading( false );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      setupData( data );
    } else {
      setLoading( false );
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        loading={ loading }
        loadingMessage="Loading risk..."
        payload={ chartData }
        emptyMessage="Unable to display percentile"
      />
      {
        ( isNotEmpty( chartData ) && isNotEmpty( total ) && isNotEmpty( currentRisk ) && isNotEmpty( fill ) ) &&
        <div className={ `peerPercentileChartWrapper risk--${getPercentileClass( chartData ) }` }>
          <Gauge
            total={total}
            displayLabel
            data={
              {
                stroke: getFill( chartData ),
                value: ( chartData / total ) * 100,
                rawValue: numberToOrdinal( chartData ),
                displayLabel: 'Peer Percentile',
              }
            }
          />
          <h3 className="chartLabel">Peer Percentile</h3>
          <p className="chartDescription">
            <span>The risk score of </span>
            <strong style={ { color: fill } }>{ formatNumber( Math.round( currentRisk ) ) } </strong>
            <span>is better than </span>
            <strong style={ { color: fill } }>{ Math.round( chartData * 100 ) }% </strong>
            <span>of your peers.</span>
          </p>
        </div>
      }
    </React.Fragment>
  );
};

export default PeerPercentile;