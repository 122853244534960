/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  decodeURLHash,
  encodeURLHash,
  // formatNumber,
  getDimensionsAndOffset,
  isNotEmpty,
  itemIsArray,
  triggerHashRefresh,
} from '../Utilities';

import './style.scss';
import InlineSVG from '../InlineSVG';

const Pagination = ( { totalCount, containerRef, onRefresh=() => {} } ) => {

  const [ currentPage, setCurrentPage ] = React.useState( null );
  const [ totalPages, setTotalPages ] = React.useState( null );
  const [ perPage, setPerPage ] = React.useState( '' );
  const [ pageButtons, setPageButtons ] = React.useState( [] );

  const paginationUnit = 8;
  const paginationPageButtonWidth = paginationUnit * 5;
  const paginationButtonSpacing = paginationUnit;
  // const totalCountWidth = paginationUnit * 16;
  const paginationItemCountSelectorWidth = 16 * paginationUnit;

  const handlePageButtonClick = ( page ) => {
    if ( page === '...' ) {
      return;
    }
    // calculate the new start and end
    const start = ( page - 1 ) * perPage;
    const end = parseInt( start ) + perPage;

    setCurrentPage( page );
    // eslint-disable-next-line camelcase
    encodeURLHash( { rows: [ start, end ] } );
    onRefresh( false );
    triggerHashRefresh();
  };

  const handlePerPageChange = ( count ) => {
    const _count = parseInt( count );
    setPerPage( _count );

    // calculate the new start and end based on the count change
    const start = ( currentPage - 1 ) * _count;
    const end = start + _count;

    // eslint-disable-next-line camelcase
    encodeURLHash( { rows: [ start, end ] } );
    onRefresh( false );
    triggerHashRefresh();
  };

  const updatePaginationLayout = () => {
    let parentEl = document.getElementById( 'pageContent' );
    let parentElDimensions = {};
    let _pageButtons = [];
    if ( isNotEmpty( containerRef ) && isNotEmpty( containerRef.current ) ) {
      parentEl = containerRef.current;
    }

    if ( isNotEmpty( parentEl ) ) {
      parentElDimensions = getDimensionsAndOffset( parentEl );
    }

    if ( isNotEmpty( parentElDimensions ) ) {
      // eslint-disable-next-line max-len

      // the space needed for all the individual page buttons
      const pageButtonsNeededWidth = ( ( paginationPageButtonWidth + paginationButtonSpacing ) * ( totalPages ) );
      // the container minus the foward/backward buttons and the selector
      const remainingContainerWidth = parentElDimensions.width // total width
        - ( paginationItemCountSelectorWidth + paginationUnit ) // width of the selector + padding
        // - ( totalCountWidth + paginationUnit ) // width of the total count + padding
        - ( paginationUnit * 4 ) // padding on the left and right of the container
        - ( ( paginationPageButtonWidth + paginationUnit ) * 2 ); // width of the forward/backward buttons

      // check to see if all the buttons can fit in the alloted space
      // too big, need to truncate
      if ( pageButtonsNeededWidth > remainingContainerWidth ) {
        // the number of buttons that can fit, minus 1, to account for the ellipsis
        const buttonsToDisplay = Math.floor(
          remainingContainerWidth
          / ( paginationPageButtonWidth + paginationButtonSpacing ),
        ) - 1;

        // if the current page is less than the number of buttons to display, we can truncate the end
        if ( currentPage <= buttonsToDisplay ) {
          _pageButtons = Array.from( { length: buttonsToDisplay }, ( v, i ) => i + 1 );
          _pageButtons.push( '...' );
          _pageButtons.push( totalPages );
        // if the current page is at the end and is also wihin the number of buttons to display distance from the end,
        // we can truncate the beginning
        } else if (
          ( currentPage > buttonsToDisplay )
          && ( ( totalPages - currentPage + 1 ) <= buttonsToDisplay )
        ) {
          _pageButtons = [ 1, '...' ];
          _pageButtons = [
            ..._pageButtons,
            ...Array.from( { length: buttonsToDisplay }, ( v, i ) => totalPages - i ).reverse(),
          ];
        // it is in the middle
        } else {
          const previousPages = Array.from(
            { length: ( buttonsToDisplay / 2 ) - 1 },
            ( v, i ) => ( parseInt( currentPage ) - parseInt( i ) ),
          );
          const nextPages = Array.from(
            { length: ( buttonsToDisplay / 2 ) },
            ( v, i ) => ( parseInt( currentPage ) + parseInt( i ) + 1 ),
          );
          _pageButtons = [ 1, '...' ];
          _pageButtons = [ ..._pageButtons, ...previousPages.reverse(), ...nextPages ];
          _pageButtons.push( '...' );
          _pageButtons.push( totalPages );
        }
      // all buttons can fit, no need to truncate
      } else {
        _pageButtons = Array.from( { length: totalPages }, ( v, i ) => i + 1 );
      }

      setPageButtons( _pageButtons );
    }
  };

  // the hash will contain a rows params and based on that param, we will derive all the needed vars
  React.useEffect( () => {
    const hash = decodeURLHash();

    if (
      isNotEmpty( hash )
      && isNotEmpty( hash.rows )
      && itemIsArray( hash.rows )
    ) {
      const [ start, end ] = hash.rows;
      const _perPage = end - start;
      const _currentPage = Math.floor( start / _perPage ) + 1;
      setPerPage( _perPage );
      setCurrentPage( _currentPage );
      setTotalPages( Math.ceil( totalCount / _perPage ) );
    }
  }, [ totalCount, containerRef ] );

  React.useEffect( () => {
    if ( isNotEmpty( totalPages ) && isNotEmpty( perPage ) && isNotEmpty( currentPage ) ) {
      let parentEl = document.getElementById( 'pageContent' );
      let parentElDimensions = {};

      if ( isNotEmpty( containerRef ) && isNotEmpty( containerRef.current ) ) {
        parentEl = containerRef.current;
      }

      if ( isNotEmpty( parentEl ) ) {
        parentElDimensions = getDimensionsAndOffset( parentEl );
      }

      if ( isNotEmpty( parentElDimensions ) ) {
        updatePaginationLayout();
        window.addEventListener( 'resize', updatePaginationLayout );
      }
    }
    return () => {
      window.removeEventListener( 'resize', updatePaginationLayout );
    };
  }, [ totalPages, perPage, currentPage ] );

  return (
    <div
      className={ `${window.IS_DARK_MODE ? 'darkMode' : '' } fullPaginationWrapper` }
      style={ { padding: paginationButtonSpacing * 2 } }
    >
      {/* <span
        className="totalRecordsCountWrapper"
        style={ {
          marginRight: paginationButtonSpacing,
          width: totalCountWidth,
        } }
      >
        <span>Total </span><strong>({ formatNumber( totalCount ) })</strong>
      </span> */}
      <div className="selectFieldWrapper" style={ {
        width: paginationItemCountSelectorWidth,
        marginRight: paginationButtonSpacing,
      } }>
        <select
          className="selectField"
          value={ perPage }
          onChange={ e => handlePerPageChange( e.target.value ) }
        >
          <option value="200">View 200</option>
          <option value="100">View 100</option>
          <option value="50">View 50</option>
          <option value="25">View 25</option>
          <option value="10">View 10</option>
        </select>
      </div>
      <div className="allPaginationButtons">
        <button
          style={ { width: paginationPageButtonWidth, marginRight: paginationButtonSpacing } }
          className="pageButton carretButton"
          onClick={ () => handlePageButtonClick( parseInt( currentPage ) - 1 <= 0 ? 1 : parseInt( currentPage ) - 1 ) }
          disabled={ parseInt( currentPage ) === 1 }
        >
          <InlineSVG type="carretLeft" />
        </button>
        <div className="pageButtons">
          {
            pageButtons.map( ( page, i ) => (
              <button
                key={ `pageButton-${page}-${i}` }
                className={ `pageButton ${ parseInt( page ) === parseInt( currentPage ) ? 'selected' : '' }` }
                style={ { width: paginationPageButtonWidth, marginRight: paginationButtonSpacing } }
                onClick={ () => handlePageButtonClick( page ) }
              >
                {page}
              </button>
            ) )
          }
        </div>
        <button
          style={ { width: paginationPageButtonWidth, marginLeft: paginationButtonSpacing } }
          // eslint-disable-next-line max-len
          onClick={ () => handlePageButtonClick( parseInt( currentPage ) + 1 > totalPages ? totalPages : parseInt( currentPage ) + 1 ) }
          disabled={ parseInt( currentPage ) === totalPages }
          className="pageButton carretButton"
        >
          <InlineSVG type="carretRight" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;