/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Draggable from 'react-draggable';
import {
  makeRequest,
} from '../../../../legacy/io';
import {
  decodeURLHash,
  encodeURLHash,
  isEmpty,
  isNotEmpty,
  pluralizeType,
  promiseAllSequential,
  recordSorter,
  removeFromURLHash,
  riskToRating,
  uniqueArray,
  useLocalStorage,
} from '../../../shared/Utilities';
import {
  addRecordsToCache,
  clearCache,
  getRecord,
  getRecords,
  deleteRecords,
} from '../../../shared/RecordCache';
import FilterForm from '../../../shared/FilterForm';
import SelectedList from './SelectedList';
import ExploreList from './ExploreList';
import Graph from '../Graph/index.js';

import EditSegment from './EditSegment/index.js';
import EditScope from './EditScope';
import EditNode from './EditNode';

import './style.scss';
import InlineSVG from '../../../shared/InlineSVG';
import { deselectExploreItems, getEdgeRatings, selectExploreItems } from './Shared';
import Dialog from '../../../shared/Dialog';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import {
  GLOBAL_SCOPE,
  SCOPE_PADDING,
  SVG_WIDTH,
  SVG_HEIGHT,
  binPackChildren,
  layoutGraph,
  MIN_SCOPE_WIDTH,
  MENU_WIDTH,
  CURRENT_LAYOUT_VERSION,
  NODE_WIDTH,
  NODE_HEIGHT,
} from '../Graph/shared';
import GraphContextMenu from '../Graph/GraphContextMenu';
import PathsFromHereCard from './PathsFromHereCard';

const ExploreModelV2 = ( ) => {
  const [ exploreInputs, setExploreInputs ] = React.useState( [] );
  // const [ riskInputs, setRiskInputs ] = React.useState( [] );
  const [ selected, setSelected ] = React.useState( {
    scope: [],
    patch: [],
    vulnerability: [],
    path: [],
    node: [],
    edge: [],
  } );
  const [ savedLayout, setSavedLayout ] = React.useState( null );
  const [ totalSelectedLength, setTotalSelectedLength ] = React.useState( 0 );
  const [ exploreItems, setExploreItems ] = React.useState( [] );
  const [ combinedGraphData, setCombinedGraphData ] = React.useState( null );
  const [ graphData, setGraphData ] = React.useState( null );
  const [ showAvailableItems, setShowAvailableItems ] = React.useState( true );
  const [ showSelectedItems, setShowSelectedItems ] = React.useState( true );
  const [ loadingGraph, setLoadingGraph ] = React.useState( false );
  const [ externalHoverIDs, setExternalHoverIDs ] = React.useState( [] );
  const [ selectingSecondaryItem, setSelectingSecondaryItem ] = React.useState( null );
  const [ secondaryItem, setSecondaryItem ] = React.useState( null );
  const [ showDeleteDialog, setShowDeleteDialog ] = React.useState( false );
  const [ deletingItem, setDeletingItem ] = React.useState( null );
  const [ deleteMessage, setDeleteMessage ] = React.useState( 'Are you sure you want to delete this item?' );
  const [ deletingItemType, setDeletingItemType ] = React.useState( null );
  const [ showCopyDialog, setShowCopyDialog ] = React.useState( false );
  const [ copyingItem, setCopyingItem ] = React.useState( null );
  const [ copyMessage, setCopyMessage ] = React.useState( 'Are you sure you want to duplicate this item?' );
  const [ copyingItemType, setCopyingItemType ] = React.useState( null );
  const [ editingItem, setEditingItem ] = React.useState( null );
  const [ editingItemType, setEditingItemType ] = React.useState( null );
  const [ showEditSegment, setShowEditSegment ] = React.useState( false );
  const [ showEditNode, setShowEditNode ] = React.useState( false );
  const [ showEditScope, setShowEditScope ] = React.useState( false );
  const [ pathsFromHere, setPathsFromHere ] = React.useState( null );
  const [ showPathsFromHere, setShowPathsFromHere ] = React.useState( true );
  const [ firstClickedItem, setFirstClickedItem ] = React.useState( null );
  const [ secondarySelectCallback, setSecondarySelectCallback ] = React.useState( () => {} );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ isPanning, setIsPanning ] = React.useState( false );
  const [ selectedItem, setSelectedItem ] = React.useState( null );
  const [ selectedItemType, setSelectedItemType ] = React.useState( null );
  const [ contextMenuItem, setContextMenuItem ] = React.useState( null );
  const [ contextMenuItemType, setContextMenuType ] = React.useState( null );
  const [ svgScale, setSVGScale ] = React.useState( 1 );
  const [ svgPanShift, setSVGPanShift ] = React.useState( { x: 0, y: 0 } );
  const [ defaultSVGPanShift, setDefaultSVGPanShift ] = React.useState( { x: 0, y: 0 } );
  const [ defaultSVGScale, setDefaultSVGScale ] = React.useState( 1 );
  const [ svgDimensions, setSVGDimensions ] = React.useState( { w: SVG_WIDTH, h: SVG_HEIGHT } );
  const [ collapsedGraphMenu, setCollapsedGraphMenu ] = useLocalStorage( 'DSExploreCollapsedGraphMenu', false );
  const [ collapsedRecordCard, setCollapsedRecordCard ] = useLocalStorage( 'DSExploreCollapsedRecordCard', false );
  // eslint-disable-next-line max-len
  const [ collapsedPathsFromHere, setCollapsedPathsFromHere ] = useLocalStorage( 'DSExploreCollapsedPathsFromHere', false );
  const [ selectedPathID, setSelectedPathID ] = React.useState( null );
  const exploreMenuRef = React.useRef( null );

  // helper to see if we need to show the dedicated pathsFromHere or not
  const shouldShowPathsFromHere = () => {
    // the recordCard is open, either collapsed or full
    if ( isNotEmpty( selectedItem ) ) {
      return collapsedRecordCard;
    }
    // the recordCard is not open
    return isNotEmpty( pathsFromHere );
  };

  const fullNodeLabel = async ( id, label, scopes=[] ) => {
    let scope;
    if ( isNotEmpty( scopes ) && isNotEmpty( id ) ) {
      scope = scopes.find( s => s.id === id );
    } else if ( isNotEmpty( id ) ) {
      scope = await getRecord( 'scope', id );
    } else {
      return '[Deleted Node]';
    }

    const fullLabel = [ label || '' ];

    if ( scope && scope.label ) {
      fullLabel.push( scope.label );
    }

    if ( scope && isNotEmpty( scope.ancestor_labels ) ) {
      fullLabel.push( scope.ancestor_labels[0] );
    }
    return fullLabel;
  };

  const displayExploreItemNameFor = async ( item, type, options={} ) => {
    let label = <strong></strong>;

    if ( type === 'scope' ) {
      if ( isNotEmpty( item.ancestor_labels ) ) {
        label = <React.Fragment>
          <strong>{ item.label }</strong>
          <span> ({item.ancestor_labels[0]})</span>
        </React.Fragment>;
      } else {
        // eslint-disable-next-line
        label = <strong>{ item.label }</strong>;
      }
    }

    if ( type === 'patch' ) {
      label = <strong>{item.vendor} {item.identifier}</strong>;
    }

    if ( type === 'vulnerability' ) {
      label = <strong>{ item.identifier }</strong>;
    }

    if ( type === 'path' ) {

      const last = item.node_labels[item.node_labels.length - 1];

      const [ scopeID, thisLabel ] = last;

      const _scopeLabel = await fullNodeLabel( scopeID, thisLabel );

      const [ asset, scope, ancestor ] = _scopeLabel;

      label = <React.Fragment>
        { ( isNotEmpty( options ) && options.showPathTo ) && <span>Path to </span>}
        { isNotEmpty( asset ) && <strong>{ asset }</strong> }
        { isNotEmpty( scope ) && <span> - { scope }</span> }
        { isNotEmpty( ancestor ) && <span> ({ ancestor })</span> }
        { ( isNotEmpty( options ) && options.showCount ) && <strong> ({item.edges.length} steps)</strong>}
      </React.Fragment>;
    }

    if ( type === 'node' ) {

      const scopeID = item.scope_id;
      const thisLabel = item.label;

      const _scopeLabel = await fullNodeLabel( scopeID, thisLabel );

      const [ asset, scope ] = _scopeLabel;

      label = <React.Fragment>
        { isNotEmpty( asset ) && <strong>{ asset }</strong> }
        { isNotEmpty( scope ) && <span> - { scope }</span> }
      </React.Fragment>;
    }

    if ( type === 'edge' ) {

      const setEdgeLabel = async ( item ) => {
        if ( isNotEmpty( item ) ) {
          // eslint-disable-next-line camelcase
          let id_list = [ item.from_node, item.to_node ];
          // eslint-disable-next-line camelcase
          id_list = id_list.filter( id => id !== undefined || id !== 'undefined' );
          // eslint-disable-next-line camelcase
          const enodes = await getRecords( 'node', { id_list } );
          if ( isNotEmpty( enodes ) ) {
            const fn = enodes.find( n => n.id === item.from_node );
            const tn = enodes.find( n => n.id === item.to_node );

            const from = await fullNodeLabel( fn ? fn.scope_id : null, fn ? fn.label : '' );
            const to = await fullNodeLabel( tn ? tn.scope_id : null, tn ? tn.label : '' );

            label = <React.Fragment>
              <strong>{from}</strong>
              <span> to </span>
              <strong>{to}</strong>
            </React.Fragment>;
          } else {
            label = <React.Fragment>
              <strong>N/A</strong>
              <span> to </span>
              <strong>N/A</strong>
            </React.Fragment>;
          }
        } else {
          label = <React.Fragment>
            <strong>N/A</strong>
            <span> to </span>
            <strong>N/A</strong>
          </React.Fragment>;
        }
      };

      if ( isNotEmpty( options ) ) {
        if ( isNotEmpty( options.nodes ) && isNotEmpty( options.scopes ) ) {
          const fn = options.nodes.find( n => n.id === item.from_node );
          const tn = options.nodes.find( n => n.id === item.to_node );

          const from = await fullNodeLabel( fn && fn.scope_id, fn ? fn.label : '', options.scopes );
          const to = await fullNodeLabel( tn && tn.scope_id, tn ? tn.label : '', options.scopes );

          label = <React.Fragment>
            <strong>{from}</strong>
            <span> to </span>
            <strong>{to}</strong>
          </React.Fragment>;
        } else {
          await setEdgeLabel( item );
        }
      } else {
        await setEdgeLabel( item );
      }
    }
    return label;
  };

  // migrated this from legacy code to utilize new cache
  // eslint-disable-next-line camelcase
  const loadFragment = async( type, id, max_edges ) => {

    const existingFragment = window.fragmentCache.get( id );
    // first, check the cache
    // eslint-disable-next-line camelcase
    if ( existingFragment && existingFragment.edges === max_edges ) {
      console.log( 'loading fragment from Cache' );
      return existingFragment.fragment;
    // otherwise fetch
    }
    const start = new Date().getTime();
    // eslint-disable-next-line camelcase
    console.log( 'loading fragment max edges: '+ max_edges );

    const response = await makeRequest(
      'FETCH',
      '/analysis/fragment', {
        project: 'default',
        model: 'base',
        type,
        id,
        // eslint-disable-next-line camelcase
        max_edges,
      },
    );

    const seconds = ( ( new Date() ).getTime() - start ) / 1000.0;

    console.log( `Loaded ${type} fragment in ${seconds} seconds` );
    console.log( type, response.results );

    if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
      const newRecords = [];
      Object.values( response.results ).map( typeSet => {
        if ( isNotEmpty( typeSet ) ) {
          newRecords.push( Object.values( typeSet ) );
        }
      } );
      if ( isNotEmpty( newRecords ) ) {
        addRecordsToCache( newRecords );
      }
    }

    // add the just fetched fragment to the cache for the future
    // eslint-disable-next-line camelcase
    window.fragmentCache.set( id, { fragment: response.results, edges: max_edges } );
    return response.results;

  };

  // whenever the filters change, refresh the list
  const onFilterChange = () => {
    refreshExploreItems();
  };

  const getRecordsFromParams = async ( _hash={} ) => {
    setLoadingGraph( true );
    let scope = [];
    let vulnerability = [];
    let patch = [];
    let path = [];
    let node = [];
    let edge = [];

    let hash;
    if ( isEmpty( _hash ) ) {
      hash = decodeURLHash();
    } else {
      hash = _hash;
    }

    if ( isNotEmpty( hash.explore_scope ) ) {
      // eslint-disable-next-line camelcase
      scope = await getRecords( 'scope', { id_list: hash.explore_scope, extra_columns: [ 'type' ] } );
    }
    if ( isNotEmpty( hash.explore_patch ) ) {
      patch = await getRecords(
        'patch',
        {
          filters: {
            // eslint-disable-next-line camelcase
            patch_ids: hash.explore_patch,
          },
        },
        true,
        true,
      );
    }
    if ( isNotEmpty( hash.explore_vulnerability ) ) {
      // eslint-disable-next-line camelcase
      vulnerability = await getRecords(
        'vulnerability',
        {
          filters: {
            // eslint-disable-next-line camelcase
            vulnerability_ids: hash.explore_vulnerability,
          },
        },
        true,
        true,
      );
    }
    if ( isNotEmpty( hash.explore_path ) ) {
      // eslint-disable-next-line camelcase
      path = await getRecords( 'path', { id_list: hash.explore_path, extra_columns: [ 'nodes' ] } );
    }
    if ( isNotEmpty( hash.explore_node ) ) {
      // eslint-disable-next-line camelcase
      let id_list = hash.explore_node;
      // eslint-disable-next-line camelcase
      id_list = id_list.filter( id => id !== undefined || id !== 'undefined' );
      // eslint-disable-next-line camelcase
      node = await getRecords( 'node', { id_list } );
    }
    if ( isNotEmpty( hash.explore_edge ) ) {
      // eslint-disable-next-line camelcase, max-len
      edge = await getRecords( 'edge', { id_list: hash.explore_edge, extra_columns: [ 'edge_analysis.risk_percentile', 'edge_analysis.nofix' ] } );
    }

    const _selected = {
      scope,
      patch,
      vulnerability,
      path,
      node,
      edge,
    };

    const allScopeIDs = [];

    const allNodeIDs = [];

    if ( isNotEmpty( path ) ) {
      path.map( p => {
        const last = p.node_labels[p.node_labels.length - 1];
        // eslint-disable-next-line
        const scopeID = last[0];
        allScopeIDs.push( scopeID );
      } );
    }

    if ( isNotEmpty( node ) ) {
      node.map( n => {
        const scopeID = n.scope_id;
        allScopeIDs.push( scopeID );
      } );
    }

    if ( isNotEmpty( edge ) ) {
      edge.map( e => {
        allNodeIDs.push( e.from_node );
        allNodeIDs.push( e.to_node );
      } );
      // eslint-disable-next-line camelcase
      let id_list = uniqueArray( allNodeIDs );
      // eslint-disable-next-line camelcase
      id_list = id_list.filter( id => id !== undefined || id !== 'undefined' );
      // eslint-disable-next-line camelcase
      const nodesForLabels = await getRecords( 'node', { id_list } );

      edge.map( e => {
        const fn = nodesForLabels.find( n => n.id === e.from_node );
        const tn = nodesForLabels.find( n => n.id === e.to_node );
        allScopeIDs.push( fn.scope_id );
        allScopeIDs.push( tn.scope_id );
      } );
    }

    if ( isNotEmpty( allScopeIDs ) ) {
      // eslint-disable-next-line camelcase
      await getRecords( 'scope', { id_list: uniqueArray( allScopeIDs ), extra_columns: [ 'type' ] } );
    }

    let _total = 0;
    Object.values( _selected ).map( items => {
      if ( isNotEmpty( items ) ) {
        _total += items.length;
      }
    } );

    setSelected( _selected );
    setTotalSelectedLength( _total );
    setLoadingGraph( false );

    return _selected;
  };

  // on page load, setup the inputs and cache, load the graph
  React.useEffect( () => {
    window.fragmentCache = new Map();
    setExploreInputs( [
      {
        type: 'select',
        attribute: 'type',
        label: 'Type',
        options: {
          scope: 'Scope',
          patch: 'Patch',
          vulnerability: 'Vulnerability',
          path: 'Path',
          node: 'Node',
        },
        value: 'scope',
      },
      {
        type: 'textSearch',
        attribute: 'keywords',
        label: 'Keywords',
        placeholder: 'keyword search...',
        value: '',
      },
      {
        type: 'hidden',
        attribute: 'explore_scope',
        value: [],
      },
      {
        type: 'hidden',
        attribute: 'explore_patch',
        value: [],
      },
      {
        type: 'hidden',
        attribute: 'explore_vulnerability',
        value: [],
      },
      {
        type: 'hidden',
        attribute: 'explore_path',
        value: [],
      },
      {
        type: 'hidden',
        attribute: 'explore_node',
        value: [],
      },
      {
        type: 'hidden',
        attribute: 'explore_edge',
        value: [],
      },
    ] );
    fetchGraphData();
    return () => {
      window.fragmentCache = null;
    };
  }, [] );

  // whenever the list of available items needs to be refreshed, this gets called
  const refreshExploreItems = async () => {

    const onCorrectPage = decodeURLHash()['.'] === 'explore';

    if ( onCorrectPage ) {
      const hash = decodeURLHash();

      await getRecordsFromParams( hash );

      const _type = hash.type;

      if ( isNotEmpty( _type ) ) {
        setExploreItems( [] );

        const params = {
          keywords: hash.keywords || '',
        };

        if ( isNotEmpty( hash[`explore_${_type}`] ) ) {
          // eslint-disable-next-line camelcase
          params.not_id_list = hash[`explore_${_type}`];
        }

        if ( _type ==='scope' ) {
          // eslint-disable-next-line camelcase
          params.extra_columns = [ 'type' ];
          if ( isNotEmpty( params.not_id_list ) ) {
            params.not_id_list.push( '00000000-0000-0000-0000-000000000000' );
          } else {
            // eslint-disable-next-line camelcase
            params.not_id_list = [ '00000000-0000-0000-0000-000000000000' ];
          }
        }

        if ( _type === 'patch' ) {
          if ( isNotEmpty( params.extra_columns ) ) {
            params.extra_columns.push( 'patch_analysis.risk' );
          } else {
            // eslint-disable-next-line camelcase
            params.extra_columns = [ 'patch_analysis.risk' ];
          }
        }

        if ( _type === 'vulnerability' ) {
          // eslint-disable-next-line camelcase
          params.field_map = { 'vulnerability_analysis.nofix': false };
        }

        if ( _type === 'node' ) {
          if ( isNotEmpty( params.id_list ) ) {
            // eslint-disable-next-line camelcase
            params.id_list = params.id_list.filter( id => id !== undefined || id !== 'undefined' );
          }
        }
        if ( _type === 'path' ) {
          // eslint-disable-next-line camelcase
          params.extra_columns = [ 'nodes' ];
        }

        const useFastAPI = ( _type === 'vulnerability' || _type === 'patch' );
        const records = await getRecords( _type, params, useFastAPI, true );

        const scopeIDsForLabels = [];

        // prefretch scopes for labels
        if ( _type === 'node' ) {
          records.map( n => {
            const scopeID = n.scope_id;
            scopeIDsForLabels.push( scopeID );
          } );
        }
        // prefretch scopes for labels
        if ( _type === 'path' ) {
          records.map( p => {
            const last = p.node_labels[p.node_labels.length - 1];
            // eslint-disable-next-line
            const scopeID = last[0];
            scopeIDsForLabels.push( scopeID );
          } );
        }

        if ( isNotEmpty( scopeIDsForLabels ) ) {
          // eslint-disable-next-line camelcase
          await getRecords( 'scope', { id_list: uniqueArray( scopeIDsForLabels ), extra_columns: [ 'type' ] } );
        }

        setExploreItems( records );
      }
    }
  };

  // main item selection mechanism for what to add and remove from the graph
  const toggleSelection = async ( item, type ) => {
    if ( isNotEmpty( type ) ) {
      const _currentType = decodeURLHash()['type'];
      const existingSelected = decodeURLHash()[`explore_${type}`];

      let _selected = [];
      let _currentItems = [];

      const selectItem = item => {
        _selected.push( item.id );

        if ( isNotEmpty( exploreItems ) ) {
          _currentItems = [ ...exploreItems ];
        }

        // need to remove the item from the list so it cannot be selected again
        if ( _currentItems.map( i => i.id ).includes( item.id ) ) {
          _currentItems = _currentItems.filter( i => i.id !== item.id );
        }
        setSelected( { ...selected, [type]: [ ...selected[type], item ]} );
      };

      const desSelectItem = item => {
        _selected = _selected.filter( id => id !== item.id );

        if ( isNotEmpty( exploreItems ) ) {
          _currentItems = [ ...exploreItems ];
        }

        // if you are deselecting an item that is current of the visible type,
        // need to re-add to the list and sort
        if ( _currentType === type ) {
          if ( !_currentItems.map( i => i.id ).includes( item.id ) ) {
            _currentItems.push( item );
          }
          // eslint-disable-next-line max-len
          _currentItems = _currentItems.sort( ( a, b ) => recordSorter( 'risk', false, a, b ) );
        }

        let _existing = selected[type];

        if ( isNotEmpty( _existing ) ) {
          _existing = _existing.filter( e => e.id !== item.id );
          setSelected( { ...selected, [type]: _existing } );
        }
      };

      // there are selected Items of this type
      if ( isNotEmpty( existingSelected ) ) {
        _selected = existingSelected;
        // deselecting
        if ( _selected.includes( item.id ) ) {
          desSelectItem( item );
        // selecting
        } else {
          selectItem( item );
        }
      // there are no selected items of this type
      } else {
        selectItem( item );
      }

      setExploreItems( _currentItems );

      if ( isNotEmpty( _selected ) ) {
        encodeURLHash( { [`explore_${type}`]: _selected } );
      } else {
        removeFromURLHash( `explore_${type}` );
      }
      fetchGraphData( false );
    }
  };

  const fetchGraphData =  async ( ) => {

    const hash = decodeURLHash();

    let riskLevel = hash.risk_level || 16;

    if ( riskLevel < 8 ) {
      riskLevel = 8;
    } else if ( riskLevel > 200 ) {
      riskLevel = 200;
    }

    const fragmentPromises = [];

    const selectedItems = await getRecordsFromParams();

    if ( isNotEmpty( selectedItems ) ) {
      Object.keys( selectedItems ).map( type => {
        if ( isNotEmpty( type ) && isNotEmpty( selectedItems[type] ) ) {
          selectedItems[type].map( item => {
            fragmentPromises.push( () => loadFragment( type, item.id, riskLevel ) );
          } );
        }
      } );

      if ( isNotEmpty( fragmentPromises ) ) {

        const resolvedFragmentPromises = await promiseAllSequential( fragmentPromises );

        const allEdges = {};
        const allNodes = {};

        resolvedFragmentPromises.map( fragment => {
          if ( isNotEmpty( fragment ) ) {
            if ( isNotEmpty( fragment.edges ) ) {
              Object.entries( fragment.edges ).map( ( [ id, edge ] ) => {
                allEdges[id] = edge;
              } );
            }
            if ( isNotEmpty( fragment.nodes ) ) {
              Object.entries( fragment.nodes ).map( ( [ id, node ] ) => {
                allNodes[id] = { ...node, fromEdges: [], toEdges: [] };
              } );
            }
          }
        } );

        const globalScope = {
          id: '00000000-0000-0000-0000-000000000000',
          label: 'Global',
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        };

        const scopes = {};

        if ( isNotEmpty( allNodes ) ) {
          const scopeIDs = Object.values( allNodes ).map( n => n.scope_id );

          const scopesResponse = await getRecords( 'scope', {
            // eslint-disable-next-line camelcase
            extra_columns: [ 'label', 'parent', 'risk', 'type' ],
            // eslint-disable-next-line camelcase
            id_list: uniqueArray( scopeIDs ),
          } );

          if ( isNotEmpty( scopesResponse ) ) {
            scopesResponse.map( s => {
              scopes[s.id] = { ...s, riskRating: riskToRating( s.risk ) };
            } );
          }

          // need to get even more scopes because some parents only exist as the parent of a scope and have not yet been
          // fetched
          let parentScopeIDs = uniqueArray( Object.values( scopes ).map( s => s.parent ) );

          parentScopeIDs = parentScopeIDs.filter( id => id !== globalScope.id );

          if ( isNotEmpty( parentScopeIDs ) ) {
            // returns the needed scope labels and parent ids
            const parentScopesResponse = await getRecords( 'scope', {
              // eslint-disable-next-line camelcase
              extra_columns: [ 'label', 'parent', 'risk', 'type' ],
              // eslint-disable-next-line camelcase
              id_list: parentScopeIDs,
            } );
            if ( isNotEmpty( parentScopesResponse ) ) {
              parentScopesResponse.map( s => {
                scopes[s.id] = { ...s, riskRating: riskToRating( s.risk ) };
              } );
            }
          }
        }

        if ( isNotEmpty( allEdges ) ) {
          Object.values( allEdges ).map( edge => {
            const toNode = allNodes[edge.to_node];
            const fromNode = allNodes[edge.from_node];

            toNode.fromEdges.push( edge.id );
            fromNode.toEdges.push( edge.id );
          } );
        }

        const combined = {
          edges: allEdges,
          nodes: allNodes,
          scopes,
        };

        const layoutKey = generateGraphLocalStorageKey( combined );


        if ( isNotEmpty( layoutKey ) ) {
          const _savedLayout = localStorage.getItem( layoutKey );

          if ( isNotEmpty( _savedLayout ) ) {
            setSavedLayout( JSON.parse( _savedLayout ) );
          } else {
            setSavedLayout( null );
          }
        }

        setCombinedGraphData( combined );
        return combined;
      }
      setCombinedGraphData( { edges: {}, nodes: {}, truncated: 1 } );
      return( { edges: {}, nodes: {}, truncated: 1 } );
    }
  };

  // wraps the fetch and the redrawing of the graph
  const fetchAndRedrawGraph = async ( hard=false, val=null ) => {
    if ( hard ) {
      window.location.reload();
    } else {
      expireCaches();
      await fetchGraphData( val );
      redrawGraph();
    }

  };

  const expireCaches = () => {
    clearCache();
    window.fragmentCache.clear();
  };

  // when a user chooses to edit a scope, node, or edge from the options menu for each
  const editItem = ( type, item, callback=null ) => {
    setEditingItem( item );
    setEditingItemType( type );
    setSelectingSecondaryItem( null );
    setIsPanning( false );
    if ( type === 'segment' || type === 'edge' ) {
      setShowEditSegment( true );
    }
    if ( type === 'node' ) {
      setShowEditNode( true );
    }
    if ( type === 'scope' ) {
      setShowEditScope( true );
    }

    if ( isNotEmpty( callback ) ) {
      callback();
    }
  };

  // when a user chooses to delete a scope, node, or edge from the options menu for each
  const deleteItem = async ( type, item, callback=null ) => {
    let message = '';
    let _type = type;

    if ( type === 'segment' || type === 'edge' ) {
      message = 'Are you sure you wish to delete this segment';
      _type = 'edge';
    }

    if ( type === 'node' ) {
      message = `Are you sure you wish to delete the node ${item.label}?`;
    }

    setDeletingItem( item );
    setDeletingItemType( _type );
    setDeleteMessage( message );
    setShowDeleteDialog( true );

    if ( isNotEmpty( callback ) ) {
      callback();
    }
  };

  // after a user confirms they want to delete an item
  const onDeleteItem = async () => {
    if ( isNotEmpty( deletingItem ) && isNotEmpty( deletingItemType ) ) {
      await deleteRecords( deletingItemType, [ deletingItem.id ] );
      deselectExploreItems( deletingItemType, [ deletingItem ], true );
      setDeletingItem( null );
      setDeletingItemType( null );
      setDeleteMessage( '' );
      setShowDeleteDialog( false );
      fetchGraphData();
    }
  };

  // when a user chooses to copy a scope or node from the options menu for each
  const copyItem = async ( type, item, callback=null ) => {
    let message = '';
    let _type = type;

    if ( type === 'scope' ) {
      message = `Are you sure you wish to duplicate the scope ${item.label}`;
      _type = 'scope';
    }

    if ( type === 'node' ) {
      message = `Are you sure you wish to duplicate the node ${item.label}?`;
    }

    setCopyingItem( item );
    setCopyingItemType( _type );
    setCopyMessage( message );
    setShowCopyDialog( true );

    if ( isNotEmpty( callback ) ) {
      callback();
    }
  };

  // after a user confirms they want to delete an item
  const onCopyItem = async () => {
    if ( isNotEmpty( copyingItem ) && isNotEmpty( copyingItemType ) ) {

      let copyResponse;

      if ( copyingItemType === 'node' ) {

        copyResponse = await makeRequest(
          'COPY',
          '/model/node',
          {
            project: 'default',
            model: 'base',
            // eslint-disable-next-line camelcase
            record_ids: [ copyingItem.id ],
          },
        );
      }

      if ( copyingItemType === 'scope' ) {
        copyResponse = await makeRequest(
          'COPY',
          '/model/scope',
          {
            project: 'default',
            model: 'base',
            // eslint-disable-next-line camelcase
            record_id: [ copyingItem.id ],
          },
        );
      }

      if ( isNotEmpty( copyResponse ) ) {
        // success
        if ( isNotEmpty( copyResponse.results ) ) {
          selectExploreItems( copyingItemType, [ copyResponse.results[0] ] );
          addFlashMessage( {
            type: 'success',
            body: `Successfully duplicated ${copyingItemType}`,
          } );
        // error
        } else if ( isNotEmpty( copyResponse.error ) ) {
          addFlashMessage( {
            type: 'alert',
            body: copyResponse.error,
          } );
        }

      // likely 500
      } else {
        addFlashMessage( {
          type: 'alert',
          body: `an error occured when trying to duplicate the ${copyingItemType}`,
        } );
      }

      setCopyingItem( null );
      setCopyingItemType( null );
      setCopyMessage( '' );
      setShowCopyDialog( false );
      fetchGraphData();
    }
  };

  // when a user chooses to add a node/segment/scope fragment to graph
  const selectItem = ( type, item, callback=null ) => {
    selectExploreItems( type, [ item ] );
    fetchGraphData();
    if ( isNotEmpty( callback ) ) {
      callback();
    }
  };

  // When a user chooses to add a segment between 2 nodes from the node options menu
  const addSegment = async ( startingNode, endingNode ) => {
    let edge;

    const existingEdges = await makeRequest(
      'FETCH',
      '/model/edge',
      {
        project: 'default',
        model: 'base',
        ids: [ startingNode.id ],
        // eslint-disable-next-line camelcase
        key_field: 'from_node',
      },
    );

    if (
      isNotEmpty( existingEdges )
      && isNotEmpty( existingEdges.results )
    ) {
      const existingEdge = existingEdges.results.find( e => e.to_node === endingNode.id );
      if ( isNotEmpty( existingEdge ) ) {
        edge = existingEdge;
      } else {
        // eslint-disable-next-line camelcase
        edge = { from_node: startingNode.id, to_node: endingNode.id };
      }
    } else {
      // eslint-disable-next-line camelcase
      edge = { from_node: startingNode.id, to_node: endingNode.id };
    }
    editItem( 'segment', edge );
  };

  // When a user wants to find all the paths between two different nodes in the graph
  const findPathsFromHere = async ( startingNode, endingNode ) => {
    if (
      isNotEmpty( startingNode )
      && isNotEmpty( endingNode )
      && isNotEmpty( combinedGraphData )
    ) {
      const from = startingNode.id;
      const to = endingNode.id;

      const start = new Date().getTime();

      const { edges } = combinedGraphData;

      const trimmedEdges = {};

      Object.entries( edges ).map( ( [ id, { from_node, to_node, nofix, likelihood } ] ) => {
        trimmedEdges[id] = {
          id,
          // eslint-disable-next-line camelcase
          from_node,
          // eslint-disable-next-line camelcase
          to_node,
          nofix,
          likelihood,
        };
      } );

      const computeParams = {
        project: 'default',
        model: 'base',
        // eslint-disable-next-line camelcase
        edge_ids: Object.keys( edges ),
        // eslint-disable-next-line camelcase
        from_id: from,
        // eslint-disable-next-line camelcase
        to_id: to,
      };

      const response = await makeRequest( 'COMPUTE', '/model/path', computeParams );

      const seconds = ( new Date().getTime() - start ) / 1000.0;

      console.log( `Pathfinding time: ${seconds} seconds` );

      setFirstClickedItem( null );
      if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
        addFlashMessage( {
          type: 'success',
          body: `Found ${response.results.length} paths`,
        } );
        setPathsFromHere( response.results );

        // selecting the first returned path involves setting that id, and then "hovering" all of the items of that path
        if ( isNotEmpty( response.results ) ) {
          const [ first ] = response.results;

          setSelectedPathID( first.id );

          let ids = [];

          if ( isNotEmpty( first.edges ) ) {
            ids = [ ...ids, ...first.edges ];
          }
          if ( isNotEmpty( first.nodes ) ) {
            ids =[ ...ids, ...first.nodes ];
          }

          setExternalHoverIDs( ids );
        }
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'No paths found',
        } );
        setPathsFromHere( null );
      }
    } else {
      addFlashMessage( {
        type: 'alert',
        body: 'An error occured in pathfinding',
      } );
      setPathsFromHere( null );
    }
  };

  const generateGraphLocalStorageKey = data => {

    if ( isNotEmpty( data ) && isNotEmpty( data.edges ) ) {

      const _edges = Object.values( data.edges );

      const sorted = _edges.sort( ( a, b ) => a.id.localeCompare( b.id, 'en' ) );

      const sortedIDs = sorted.map( e => e.id );

      // eslint-disable-next-line max-len
      const key = sortedIDs.join( '_' );

      return key;
    }
    return 'couldNotGenerateKey';
  };

  // saves the current layout to localStorage for retrieval
  const saveGraphLayout = async ( showFlash=true ) => {

    const dsExploreSVGChildScopes = {};
    const dsExploreSVGParentScopes = {};
    const dsExploreSVGEdges = {};
    const dsExploreSVGNodes = {};

    // need to break up what we need to store so that we don't have too much in any one localStorage item
    if ( isNotEmpty( graphData ) ) {
      Object.entries( graphData ).map( ( [ key, entries ] ) => {
        if ( key === 'nodes' && isNotEmpty( entries ) ) {
          Object.entries( entries ).map( ( [ id, data ] ) => {
            dsExploreSVGNodes[id] = {
              w: data.w,
              h: data.h,
              x: data.x,
              y: data.y,
              center: data.center,
              labelPosition: data.labelPosition,
            };
          } );
        }

        if ( key === 'edges' && isNotEmpty( entries ) ) {
          Object.entries( entries ).map( ( [ id, data ] ) => {
            dsExploreSVGEdges[id] = {
              x1: data.x1,
              x2: data.x2,
              y1: data.y1,
              y2: data.y2,
            };
          } );
        }

        if ( key === 'childScopes' && isNotEmpty( entries ) ) {
          Object.entries( entries ).map( ( [ id, data ] ) => {
            dsExploreSVGChildScopes[id] = {
              w: data.w,
              h: data.h,
              x: data.x,
              y: data.y,
              rectangle: data.rectangle,
            };
          } );
        }

        // need to add the global scope if it exists to the saved layout, it will be saved as a child scope
        if ( key === 'scopes' && isNotEmpty( entries ) && isNotEmpty( entries[GLOBAL_SCOPE.id] ) ) {
          const globalScope = entries[GLOBAL_SCOPE.id];

          dsExploreSVGChildScopes[globalScope.id] = {
            w: globalScope.w,
            h: globalScope.h,
            x: globalScope.x,
            y: globalScope.y,
            rectangle: globalScope.rectangle,
          };
        }

        if ( key === 'parentScopes' && isNotEmpty( entries ) ) {
          Object.entries( entries ).map( ( [ id, data ] ) => {
            dsExploreSVGParentScopes[id] = {
              w: data.w,
              h: data.h,
              x: data.x,
              y: data.y,
              rectangle: data.rectangle,
            };
          } );
        }
      } );

      const storageKey = generateGraphLocalStorageKey( graphData );

      const _savedLayout = {
        scale: svgScale,
        panShift: svgPanShift,
        nodes: dsExploreSVGNodes,
        edges: dsExploreSVGEdges,
        childScopes: dsExploreSVGChildScopes,
        parentScopes: dsExploreSVGParentScopes,
        version: CURRENT_LAYOUT_VERSION,
      };

      localStorage.setItem(
        storageKey,
        JSON.stringify( _savedLayout ),
      );

      setSavedLayout( _savedLayout );

      if ( showFlash ) {
        addFlashMessage( {
          type: 'success',
          body: 'Saved graph layout',
        } );
      }

      return savedLayout;
    }
  };

  // retrieves layout from localStorage and reloads the graph with the correct coords and scale
  const restoreGraphLayout = ( data ) => {

    const _data = isNotEmpty( data ) ? data : graphData;

    if ( isNotEmpty( _data ) ) {
      const key = generateGraphLocalStorageKey( _data );
      let layout = localStorage.getItem( key );

      if ( isNotEmpty( layout ) ) {
        layout = JSON.parse( layout );

        redrawGraph( layout );

        addFlashMessage( {
          type: 'success',
          body: 'Restored graph layout',
        } );
      }
    }
  };

  const fitToScreen = (
    packedGraph,
    shouldSetDefault=false,
    savedLayout,
    // isReset=false,
  ) => {
    let panX = SCOPE_PADDING;

    if ( !collapsedGraphMenu ) {
      panX = MENU_WIDTH + SCOPE_PADDING;
    }

    const { w, h, x, y } = packedGraph;

    const availableW = svgDimensions.w - panX - ( SCOPE_PADDING * 2 );
    const availableH = svgDimensions.h - ( SCOPE_PADDING * 2 );

    const hScale = availableH / h;
    const wScale = availableW / w;

    const scale = Math.min( hScale, wScale );

    const panShift = {
      x: panX - ( x ? x * scale : 0 ),
      y: SCOPE_PADDING - ( y ? y * scale : 0 ),
    };

    // if we are restoring an existing layout, just use the stored vars
    if ( isNotEmpty( savedLayout ) && savedLayout.version === CURRENT_LAYOUT_VERSION ) {

      setSVGPanShift( savedLayout.panShift );
      setSVGScale( savedLayout.scale );
      if ( shouldSetDefault ) {
        setDefaultSVGPanShift( savedLayout.panShift );
        setDefaultSVGScale( savedLayout.scale );
      }
      return true;
    }

    if ( shouldSetDefault ) {
      setDefaultSVGPanShift( panShift );
      setDefaultSVGScale( scale );
    }

    setSVGScale( scale );
    setSVGPanShift( panShift );
  };

  // redraws the graph whenever new data (or other situations) change, main layout algorithm
  const redrawGraph = ( savedLayout=null ) => {

    if ( isNotEmpty( combinedGraphData ) ) {
      setLoadingGraph( true );

      const { nodes, edges, scopes } = combinedGraphData;

      // early return when clearing graph
      if ( isEmpty( nodes ) && isEmpty( edges ) && isEmpty( scopes ) ) {
        setLoadingGraph( false );
        setGraphData( null );
      }

      if ( isNotEmpty( scopes ) && isEmpty( scopes[GLOBAL_SCOPE.id] ) ) {
        scopes[GLOBAL_SCOPE.id] = GLOBAL_SCOPE;
      }

      const childScopes = {};
      const parentScopes = {};

      // 1) divide the scopes by child or parent
      if ( isNotEmpty( scopes ) ) {
        Object.values( scopes ).map( scope  => {
          const _scope = {
            ...scope,
            children: [],
            parentScope: scopes[scope.parent],
            rating: scope.riskRating,
          };

          // this scope has another scope that is in the graph
          if ( scope.parent !== GLOBAL_SCOPE.id ) {
            _scope.scopeType = 'childScope';
            _scope.childType = 'scope';
            childScopes[scope.id] = _scope;
          } else {
            _scope.scopeType = 'parentScope';
            parentScopes[scope.id] = _scope;
          }
        } );
      }

      // 2) put the nodes into the correct scopes
      if ( isNotEmpty( nodes ) ) {
        Object.values( nodes ).map( node => {

          let parentScope = childScopes[node.scope_id];

          if ( isEmpty( parentScope ) ) {
            parentScope = parentScopes[node.scope_id];
          }

          // size the nodes extra wide to give them more horizontal space from each other.
          // add some vertical space as well to stagger the node layouts
          let w = NODE_WIDTH;
          let h = NODE_HEIGHT;

          if (
            isNotEmpty( savedLayout )
            && isNotEmpty( savedLayout.nodes )
            && savedLayout.version === CURRENT_LAYOUT_VERSION
          ) {
            const savedNode = savedLayout.nodes[node.id];

            if ( isNotEmpty( savedNode ) ) {
              ( { w, h } = savedNode );
            }
          }

          const _node = {
            ...node,
            rating: riskToRating( node.risk ),
            parentScope,
            w,
            h,
            childType: 'node',
            fromEdgeRatings: getEdgeRatings( node.fromEdges, edges ),
            toEdgeRatings: getEdgeRatings( node.toEdges, edges ),
          };

          parentScope.children.push( _node );
        } );
      }

      // 3) pack the childScopes and add to parents
      if ( isNotEmpty( childScopes ) ) {
        Object.values( childScopes ).map( scope => {

          let w, h, x, y, rectangle;

          if (
            isNotEmpty( savedLayout )
            && isNotEmpty( savedLayout.childScopes )
            && savedLayout.version === CURRENT_LAYOUT_VERSION
            && isNotEmpty( savedLayout.childScopes[scope.id] )
          ) {
            const savedScope = savedLayout.childScopes[scope.id];

            if ( isNotEmpty( savedScope ) ) {
              ( { w, h, x, y, rectangle } = savedScope );
            }
          } else {
            // this packs the child scope exactly
            const packed = binPackChildren( scope.children );
            ( { w, h } = packed );
            ( { x, y } = scope );

            if ( w < MIN_SCOPE_WIDTH ) {
              w = MIN_SCOPE_WIDTH;
            }

            rectangle = {
              w: w + ( SCOPE_PADDING * 2 ),
              h: h + ( SCOPE_PADDING * 3 ),
              x,
              y,
            };

            // the exact dimensions of the scope need to be adjusted so that it is not too tight.
            // need to add room for a header above all the nodes, and padding around all other sides
            h = rectangle.h + ( SCOPE_PADDING );
            w = rectangle.w + ( SCOPE_PADDING );
          }

          scope.w = w;
          scope.h = h;
          scope.x = x;
          scope.y = y;
          scope.rectangle = rectangle;

          if ( isNotEmpty( scope.parentScope ) ) {
            parentScopes[scope.parentScope.id].children.push( scope );
          }
        } );
      }

      // 4) pack the parents
      if ( isNotEmpty( parentScopes ) ) {

        Object.values( parentScopes ).map( scope => {

          let w, h, x, y, rectangle;

          if (
            isNotEmpty( savedLayout )
            && isNotEmpty( savedLayout.parentScopes )
            && savedLayout.version === CURRENT_LAYOUT_VERSION
            && isNotEmpty( savedLayout.parentScopes[scope.id] )
          ) {
            const savedScope = savedLayout.parentScopes[scope.id];

            if ( isNotEmpty( savedScope ) ) {
              ( { w, h, x, y, rectangle } = savedScope );
            }
          } else {
            const packed = binPackChildren( scope.children );

            // calculate the circumcircle for this rect ( needed for packSiblings)
            // eslint-disable-next-line max-len
            ( { w, h } = packed );
            ( { x, y } = scope );

            if ( w < MIN_SCOPE_WIDTH ) {
              w = MIN_SCOPE_WIDTH;
            }

            // once the scope has been packed, we need to create an inner rectangle and outer bounds so that the
            // scope can be shifted within it.
            // create the rectangle that needs to be drawn, for the inner scopes, it is the same as the scope dimensions
            // because they will not be shifted around and there is enough padding within the node containers
            rectangle = {
              w: w + ( SCOPE_PADDING * 2 ),
              h: h + ( SCOPE_PADDING * 3 ),
              x,
              y,
            };

            // the exact dimensions of the scope need to be adjusted so that it is not too tight.
            // need to add room for a header above all the nodes, and padding around all other sides
            h = rectangle.h + ( SCOPE_PADDING * 3 );
            w = rectangle.w + ( SCOPE_PADDING );
          }

          scope.w = w;
          scope.h = h;
          scope.x = x;
          scope.y = y;
          scope.rectangle = rectangle;
        } );
      }

      // 5) just a check to make sure the parents are not null, everything else happens within this
      if ( isNotEmpty( parentScopes ) ) {

        // 6) pack the rectangles of the graph
        // uses a custom column packing algorithm that attempts to adhere to the given aspect ratio of the screen
        const packedColumns = layoutGraph( Object.values( parentScopes ), svgDimensions, collapsedGraphMenu );

        const globalScope =  Object.values( parentScopes ).find( s => s.id === GLOBAL_SCOPE.id );

        const translatedColumnChildren = [];
        // packedColumns returns the rectangle dimensions it was packed into and the columns that each scope was put in
        // need to give each child within a column its own x,y coords
        if ( isNotEmpty( packedColumns ) && isNotEmpty( packedColumns.columns ) ) {
          Object.values( packedColumns.columns ).map( ( column, index ) => {
            const x = Object.values( packedColumns.columns ).reduce( ( accum, current, _index ) => {
              if ( _index < index ) {
                return accum + current.w;
              }
              return accum;
            }, isNotEmpty( globalScope ) ? globalScope.w : 0 );

            column.children.map( ( child, cIndex ) => {

              const y = column.children.reduce( ( accum, current, _cIndex ) => {
                if ( _cIndex < cIndex ) {
                  return accum + current.h;
                }
                return accum;
              }, 0 );

              const _child = {
                ...child,
                x,
                y,
              };

              translatedColumnChildren.push( _child );
            } );

          } );
        }

        if (
          isNotEmpty( savedLayout )
          && isNotEmpty( savedLayout.childScopes )
          && savedLayout.version === CURRENT_LAYOUT_VERSION
          && isNotEmpty( childScopes[GLOBAL_SCOPE.id] )
        ) {
          const _global = {
            ...globalScope,
            x: childScopes[GLOBAL_SCOPE.id].x,
            y: childScopes[GLOBAL_SCOPE.id].y,
          };
          translatedColumnChildren.push( _global );
          packedColumns.container.w += _global.w;
        } else if ( isNotEmpty( globalScope ) ) {
          const _global = {
            ...globalScope,
            x: 0,
            y: ( packedColumns.container.h / 2 ) - ( globalScope.h / 2 ),
          };
          translatedColumnChildren.push( _global );
          packedColumns.container.w += _global.w;
        }

        translatedColumnChildren?.map( ( parentScope ) => {

          let x, y, rectangle;

          if ( isNotEmpty(
            isNotEmpty( savedLayout )
            && isNotEmpty( savedLayout.parentScopes )
            && savedLayout.version === CURRENT_LAYOUT_VERSION,
          ) ) {
            const savedScope = savedLayout.parentScopes[parentScope.id];

            if ( isNotEmpty( savedScope ) ) {
              ( { x, y, rectangle } = savedScope );
              parentScope.rectangle = rectangle;
            }
          } else {
            ( { x, y } = parentScope );
            parentScope.rectangle.x = x;
            parentScope.rectangle.y = y;
          }

          // 7) adjust the node and inner scope positions
          parentScope?.children?.map( ( child ) => {
            // node within parent scope
            if ( child.childType === 'node' ) {
              let x, y, center;

              if (
                isNotEmpty( savedLayout )
                && isNotEmpty( savedLayout.nodes )
                && savedLayout.version === CURRENT_LAYOUT_VERSION
              ) {
                const savedNode = savedLayout.nodes[child.id];

                if ( isNotEmpty( savedNode ) ) {
                  ( { x, y, center } = savedNode );
                }
                child.x = x;
                child.y = y;
                child.center = center;
              } else {
                const newX = child.x + parentScope.x + SCOPE_PADDING;
                const newY = child.y + parentScope.y + ( SCOPE_PADDING * 2 );

                center = {
                  x: newX + ( child.w / 2 ),
                  y: newY + ( NODE_HEIGHT / 3 ),
                  r: NODE_HEIGHT / 3,
                };

                child.x = newX;
                child.y = newY;
                child.center = center;
              }

            // childscope
            } else {
              let x, y, rectangle;

              if (
                isNotEmpty( savedLayout )
                && isNotEmpty( savedLayout.childScopes )
                && savedLayout.version === CURRENT_LAYOUT_VERSION
              ) {
                const savedScope = savedLayout.childScopes[child.id];

                if ( isNotEmpty( savedScope ) ) {
                  ( { x, y, rectangle } = savedScope );
                }
                child.x = x;
                child.y = y;
                child.rectangle = rectangle;
              } else {
                const newX = child.x + parentScope.x + SCOPE_PADDING;
                const newY = child.y + parentScope.y + ( SCOPE_PADDING * 2 );

                child.x = newX;
                child.y = newY;

                if ( isNotEmpty( child.rectangle ) ) {
                  child.rectangle.x = newX;
                  child.rectangle.y = newY;
                }
              }
            }
          } );
        } );

        // need to reset all the children of the child scopes
        translatedColumnChildren?.map( parentScope => {
          parentScope?.children?.map( child => {

            if ( child.childType === 'scope' ) {

              child.children.map( ( _child ) => {
                let x, y, center;
                if (
                  isNotEmpty( savedLayout )
                  && isNotEmpty( savedLayout.nodes )
                  && savedLayout.version === CURRENT_LAYOUT_VERSION
                ) {
                  const savedNode = savedLayout.nodes[_child.id];

                  if ( isNotEmpty( savedNode ) ) {
                    ( { x, y, center } = savedNode );
                  }

                  _child.x = x;
                  _child.y = y;
                  _child.center = center;
                } else {
                  const newX = _child.x + child.x + SCOPE_PADDING;
                  const newY = _child.y + child.y + ( SCOPE_PADDING * 2 );
                  _child.x += newX;
                  _child.y += newY;

                  center = {
                    x: newX + ( _child.w / 2 ),
                    y: newY + ( NODE_HEIGHT / 3 ),
                    r: NODE_HEIGHT / 3,
                  };

                  // this is the Node
                  _child.center = center;
                  _child.x = newX;
                  _child.y = newY;
                }
              } );
            }
          } );
        } );

        // flatten each of the layers of the tree for rendering
        const _parentScopes = {};
        const _childScopes = {};
        const _allScopes = {};
        const _nodes = {};
        const _edges = {};

        // pull out the child scopes and nodes
        translatedColumnChildren?.map( scope => {
          _allScopes[scope.id] = scope;
          _parentScopes[scope.id] = scope;
          scope?.children?.map( child => {
            if ( child.childType === 'scope' ) {
              _childScopes[child.id] = child;
              _allScopes[child.id] = child;
            } else {
              _nodes[child.id] = child;
            }
          } );
        } );

        // pull the children from the child scopes
        if ( isNotEmpty( _childScopes ) ) {
          Object.values( childScopes ).map( scope => {
            _allScopes[scope.id] = scope;
            scope?.children?.map( child => {
              _nodes[child.id] = child;
            } );
          } );
        }

        // map the x, y of the edges
        if ( isNotEmpty( edges ) ) {
          Object.values( edges ).map( edge => {
            const fromNode = _nodes[edge.from_node];
            const toNode = _nodes[edge.to_node];

            let x1 = fromNode?.center?.x;
            let y1 = fromNode?.center?.y;
            let x2 = toNode?.center?.x;
            let y2 = toNode?.center?.y;

            if (
              isNotEmpty( savedLayout )
              && isNotEmpty( savedLayout.edges )
              && savedLayout.version === CURRENT_LAYOUT_VERSION
            ) {
              const savedEdge = savedLayout.edges[edge.id];

              if ( isNotEmpty( savedEdge ) ) {
                ( { x1, y1, x2, y2 } = savedEdge );
              }
            }

            const _edge = {
              ...edge,
              x1,
              y1,
              x2,
              y2,
              fromNode,
              toNode,
            };

            _edges[edge.id] =  _edge;
          } );
        }

        setLoadingGraph( false );

        fitToScreen( packedColumns.container, true, savedLayout );

        // save the graph for rendering
        setGraphData( {
          parentScopes: _parentScopes,
          childScopes: _childScopes,
          nodes: _nodes,
          edges: _edges,
          scopes: _allScopes,
          packedGraph: packedColumns,
        } );
      }
    }
  };

  const handlePathsArrowKey = event => {
    if ( isNotEmpty( event ) && isNotEmpty( pathsFromHere ) ) {

      let newPath = null;

      // up arrow, going "up/back" in paths list
      if ( event.keyCode === 38 ) {
        if ( isNotEmpty( selectedPathID ) ) {
          if ( parseInt( selectedPathID ) > 0 ) {
            newPath = pathsFromHere[ parseInt( selectedPathID ) - 1 ];
          } else {
            newPath = pathsFromHere[ pathsFromHere.length - 1 ];
          }
        } else {
          ( [ newPath ] = pathsFromHere );
        }
      }
      // down arrow, going "down/forward" in paths list
      if ( event.keyCode === 40 ) {
        if ( isNotEmpty( parseInt( selectedPathID ) ) ) {
          if ( parseInt( selectedPathID ) < pathsFromHere.length - 1 ) {
            newPath = pathsFromHere[ parseInt( selectedPathID ) + 1 ];
          } else {
            ( [ newPath ] = pathsFromHere );
          }
        } else {
          ( [ newPath ] = pathsFromHere );
        }
      }

      if ( isNotEmpty( newPath ) ) {
        setSelectedPathID( newPath.id );

        let ids = [];

        if ( isNotEmpty( newPath.edges ) ) {
          ids = [ ...ids, ...newPath.edges ];
        }
        if ( isNotEmpty( newPath.nodes ) ) {
          ids = [ ...ids, ...newPath.nodes ];
        }

        setExternalHoverIDs( ids );
      }
    }
  };

  // whenever the paths from here change, setup the arrow key listeners
  React.useEffect( ( ) => {
    if ( isNotEmpty( pathsFromHere ) ) {
      window.addEventListener( 'keyup', handlePathsArrowKey );
    } else {
      window.removeEventListener( 'keyup', handlePathsArrowKey );
    }
    return () => window.removeEventListener( 'keyup', handlePathsArrowKey );
  }, [ pathsFromHere, selectedPathID ] );

  return (
    <React.Fragment>
      <Dialog
        type="confirm"
        visible={ showDeleteDialog }
        setVisible={ setShowDeleteDialog }
        content={ deleteMessage }
        action={ () => onDeleteItem() }
      />
      <Dialog
        type="confirm"
        visible={ showCopyDialog }
        setVisible={ setShowCopyDialog }
        content={ copyMessage }
        action={ () => onCopyItem() }
      />
      <EditNode
        selectedItem={editingItem}
        selectedItemType={editingItemType}
        show={showEditNode}
        setShow={setShowEditNode}
        fetchAndRedrawGraph={fetchAndRedrawGraph}
      />
      <EditSegment
        selectedItem={editingItem}
        setSelectedItem={setEditingItem}
        selectedItemType={editingItemType}
        show={showEditSegment}
        setShow={setShowEditSegment}
        fetchAndRedrawGraph={fetchAndRedrawGraph}
        nodes={ combinedGraphData?.nodes }
      />
      <EditScope
        selectedItem={editingItem}
        selectedItemType={editingItemType}
        show={showEditScope}
        setShow={setShowEditScope}
        fetchAndRedrawGraph={fetchAndRedrawGraph}
      />
      <div
        id="exploreModelContentWrapper"
      >
        <Draggable
          disabled={collapsedGraphMenu}
          handle=".panelHeader"
        >
          <div
            className={ `collapsiblePanel ${ collapsedGraphMenu ? 'collapsed' : '' }` }
            ref={ exploreMenuRef }
            style= { { width: MENU_WIDTH } }
          >
            <div className="panelHeader">
              <h2>
                <span>
                  <InlineSVG type="dsRisk" />
                  <span>Graph Menu</span>
                  {
                    collapsedGraphMenu &&
                    <span className="selectedCount">({totalSelectedLength} items)</span>
                  }
                </span>
              </h2>
              <button
                onClick={ () => setCollapsedGraphMenu( !collapsedGraphMenu ) }
                className="panelCollapseButton roundGlyphButton light"
              >
                {
                  collapsedGraphMenu
                    ? <InlineSVG type="expand" />
                    : <InlineSVG type="collapse" />
                }
              </button>
              {
                !collapsedGraphMenu &&
                <button className="roundGlyphButton draggableButton light">
                  <InlineSVG type="draggable" elementClass="draggableIcon" />
                </button>
              }
            </div>
            <FilterForm
              inputs={exploreInputs}
              reportType="explore"
              onRefresh={ onFilterChange }
            />
            <div
              // eslint-disable-next-line max-len
              className={ `${ showSelectedItems && showAvailableItems ? 'bothOpen' : '' } collapsibleSectionWrapper ${showAvailableItems ? '' : 'collapsed' }` }
            >
              <div
                className="collapsibleSectionHeader"
                onClick={ () => setShowAvailableItems( !showAvailableItems ) }
              >
                <div className="headerLeft">
                  <InlineSVG
                    elementClass="itemTypeIcon"
                    type={`${pluralizeType( decodeURLHash()['type'] || 'scope' )}Alt`}
                  />
                  <h3>
                    { `Available ${pluralizeType( decodeURLHash()['type'] || 'scope', true )} ` }
                  </h3>
                </div>
                <div className="headerRight">
                  <strong className="sectionCount">
                    ({exploreItems ? exploreItems.length : 0})
                  </strong>
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp"/>
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                <ExploreList
                  items={exploreItems}
                  selected={selected}
                  setSelected={setSelected}
                  displayExploreItemNameFor={displayExploreItemNameFor}
                  refreshExploreItems={refreshExploreItems}
                  exploreItems={exploreItems}
                  setExploreItems={setExploreItems}
                  toggleSelection={toggleSelection}
                />
              </div>
            </div>
            <div
              // eslint-disable-next-line max-len
              className={ `${ showSelectedItems && showAvailableItems ? 'bothOpen' : '' } collapsibleSectionWrapper ${showSelectedItems ? '' : 'collapsed' }` }
            >
              <div
                className="collapsibleSectionHeader"
                onClick={ () => setShowSelectedItems( !showSelectedItems ) }
              >
                <div className="headerLeft">
                  <InlineSVG type="explore_nav" />
                  <h3>
                    Included Items
                  </h3>
                </div>
                <div className="headerRight">
                  <strong className="sectionCount">
                    ({totalSelectedLength})
                  </strong>
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp"/>
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                <SelectedList
                  items={selected}
                  selected={selected}
                  setSelected={setSelected}
                  displayExploreItemNameFor={displayExploreItemNameFor}
                  refreshExploreItems={refreshExploreItems}
                  exploreItems={exploreItems}
                  setExploreItems={setExploreItems}
                  toggleSelection={toggleSelection}
                  setExternalHoverIDs={ setExternalHoverIDs }
                />
              </div>
            </div>
            <div className="graphOptionsHeader">
              <InlineSVG type="filterAlt" />
              Graph Options
            </div>
            <ul className="graphOptions">
              <li
                onClick={ () => editItem( 'scope', { parent: GLOBAL_SCOPE.id } ) }
              >
                Add scope
              </li>
              <li
                onClick={ () => editItem( 'node', { ...GLOBAL_SCOPE, isScope: true } ) }
              >
                Add node
              </li>
              <li
                onClick={ saveGraphLayout }
              >
                Save current layout
              </li>
              {
                ( isNotEmpty( savedLayout ) && savedLayout.version === CURRENT_LAYOUT_VERSION ) &&
                <li
                  onClick={ () => restoreGraphLayout( graphData ) }
                >
                  Restore saved layout
                </li>
              }
            </ul>
          </div>
        </Draggable>
        <Graph
          data={ combinedGraphData }
          editItem={editItem}
          deleteItem={deleteItem}
          copyItem={copyItem}
          selectItem={selectItem}
          addSegment={addSegment}
          findPathsFromHere={findPathsFromHere}
          loadingGraph={ loadingGraph }
          setLoadingGraph={ setLoadingGraph }
          externalHoverIDs={ externalHoverIDs }
          selectingSecondaryItem={selectingSecondaryItem}
          setSelectingSecondaryItem={setSelectingSecondaryItem}
          secondaryItem={secondaryItem}
          setSecondaryItem={setSecondaryItem}
          secondarySelectCallback={secondarySelectCallback}
          setSecondarySelectCallback={setSecondarySelectCallback}
          isPanning={isPanning}
          setIsPanning={setIsPanning}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          selectedItemType={selectedItemType}
          setSelectedItemType={setSelectedItemType}
          pathsFromHere={pathsFromHere}
          setPathsFromHere={setPathsFromHere}
          showPathsFromHere={showPathsFromHere}
          setShowPathsFromHere={setShowPathsFromHere}
          setExternalHoverIDs={setExternalHoverIDs}
          displayExploreItemNameFor={displayExploreItemNameFor}
          graphData={graphData}
          setGraphData={setGraphData}
          svgScale={svgScale}
          setSVGScale={setSVGScale}
          svgPanShift={svgPanShift}
          setSVGPanShift={setSVGPanShift}
          redrawGraph={redrawGraph}
          defaultSVGScale={defaultSVGScale}
          defaultSVGPanShift={defaultSVGPanShift}
          svgDimensions={svgDimensions}
          setSVGDimensions={setSVGDimensions}
          collapsedRecordCard={collapsedRecordCard}
          setCollapsedRecordCard={setCollapsedRecordCard}
          collapsedGraphMenu={collapsedGraphMenu}
          contextMenuItem={ contextMenuItem }
          setContextMenuItem={setContextMenuItem}
          setContextMenuType={setContextMenuType}
          saveGraphLayout={ saveGraphLayout }
          savedLayout={ savedLayout }
          restoreGraphLayout={ restoreGraphLayout }
          firstClickedItem={firstClickedItem}
          setFirstClickedItem={setFirstClickedItem}
          exploreMenuRef={ exploreMenuRef }
          setDefaultSVGPanShift={ setDefaultSVGPanShift }
          setDefaultSVGScale={ setDefaultSVGScale }
          fitToScreen={fitToScreen}
          selectedPathID={ selectedPathID }
          setSelectedPathID={ setSelectedPathID }
        />
      </div>
      {
        ( isNotEmpty( contextMenuItem ) && isNotEmpty( contextMenuItemType ) ) &&
        // right-click menu, this is the same options that appear when viewing an item, but duplicated
        // for continuity with the old behavior
        <GraphContextMenu
          item={ contextMenuItem }
          type={ contextMenuItemType }
          selectItem={ selectItem }
          editItem={ editItem }
          copyItem={ copyItem }
          deleteItem={ deleteItem }
          setSelectingSecondaryItem={ setSelectingSecondaryItem }
          setContextMenuItem={ setContextMenuItem }
          setContextMenuType={ setContextMenuType }
          saveGraphLayout={ saveGraphLayout }
          savedLayout={ savedLayout }
          graphData={ graphData }
          restoreGraphLayout={ restoreGraphLayout }
        />
      }
      {
        shouldShowPathsFromHere &&
        <PathsFromHereCard
          disabled={collapsedPathsFromHere }
          show={ shouldShowPathsFromHere }
          collapsed={ collapsedPathsFromHere }
          setCollapsed={ setCollapsedPathsFromHere }
          pathsFromHere={ pathsFromHere }
          displayExploreItemNameFor={displayExploreItemNameFor}
          setExternalHoverIDs={setExternalHoverIDs}
          selectedPathID={ selectedPathID }
          setSelectedPathID={ setSelectedPathID }
        />
      }
    </React.Fragment>
  );
};

export default ExploreModelV2;