/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import CVSSChart from '../../../../shared/Charts/CVSSChart';
import EmptyLoading from '../../../../shared/EmptyLoading';
import {
  decodeURLHash,
  encodeURLHash,
  isNotEmpty,
  removeFromURLHash,
  triggerHashRefresh,
} from '../../../../shared/Utilities';

import './Widgets.scss';
import ExploitStatusBreakdown from '../../../Reporting/Dashboards/Widgets/v2/ExploitStatusBreakdown';
import Legend from '../../../../shared/Charts/Legend';
import VulnerabilityInstancesTotal from '../../../Reporting/Dashboards/Widgets/v2/VulnerabilityInstancesTotal';
import { getThemeColor } from '../../../../shared/Themes';

const order = [
  'Critical',
  'High',
  'Moderate',
  'Low',
  'Minimal',
];

const minMax = {
  critical: {
    maximum: 10,
    minimum: 9,
  },
  high: {
    maximum: 9,
    minimum: 7.5,
  },
  moderate: {
    maximum: 7.5,
    minimum: 6,
  },
  low: {
    maximum: 6,
    minimum: 3,
  },
  minimal: {
    maximum: 3,
    minimum: 0,
  },
};

const Widgets = ( { visualData, onRefresh } ) => {

  const [ barData, setBarData ] = React.useState( null );
  const [ instanceTotal, setInstanceTotal ] = React.useState( null );

  const [ barLoading, setBarLoading ] = React.useState( true );
  const [ donutLoading, setDonutLoading ] = React.useState( true );

  const svgContainerRef = React.useRef( null );

  // whenever the visualData changes (refresh, filters, etc.), we need to re-render the charts
  React.useEffect( ( ) => {
    setBarLoading( true );
    setDonutLoading( true );
    const _barData = {};

    // sets up the data for the cvss
    if (
      isNotEmpty( visualData )
      && isNotEmpty( visualData.tally )
      && isNotEmpty( visualData.tally.results )
      && isNotEmpty( visualData.tally.results.cvss )
    ) {
      const _counts = {
        critical: visualData?.tally?.results?.cvss?.critical || 0,
        high: visualData?.tally?.results?.cvss?.high || 0,
        moderate: visualData?.tally?.results?.cvss?.moderate || 0,
        low: visualData?.tally?.results?.cvss?.low || 0,
        minimal: visualData?.tally?.results?.cvss?.minimal || 0,
      };

      order.map( name => {
        _barData[name.toLowerCase()] = {
          label: name,
          fill: getThemeColor( name.toLocaleLowerCase() ),
          value: _counts[name.toLowerCase()],
          total: _counts[name.toLowerCase()],
          subLabel: `${ minMax[name.toLowerCase()].maximum} - ${ minMax[name.toLowerCase()].minimum}`,
        };
      } );
    }

    if ( isNotEmpty( visualData?.tally?.results?._total ) ) {
      setInstanceTotal( visualData?.tally?.results?._total );
    }

    setBarData( _barData );
    setBarLoading( false );
    setDonutLoading( false );
  }, [ visualData ] );

  const onClickCallback = sectionKey => {
    if ( isNotEmpty( sectionKey ) ) {
      const hash = decodeURLHash();
      if ( hash.exploit_statuses === sectionKey ) {
        removeFromURLHash( 'exploit_statuses' );
      } else {
        // eslint-disable-next-line camelcase
        encodeURLHash( { exploit_statuses: sectionKey } );
      }
    } else {
      removeFromURLHash( 'exploit_statuses' );
    }
    triggerHashRefresh();
    onRefresh( false );
  };

  return (
    <div className="instancesWidgetsWrapper">
      {
        isNotEmpty( instanceTotal ) &&
        <div className="bigNumber">
          <VulnerabilityInstancesTotal data={ visualData?.tally } />
        </div>
      }
      <div className="dashboardWidgetWrapper bar">
        <EmptyLoading
          payload={ barData }
          loading={ barLoading }
          loadingMessage="Loading CVSS data"
          emptyMessage="No CVSS data"
        />
        {
          isNotEmpty( barData ) &&
          <React.Fragment>
            <div>
              <CVSSChart
                cvssData={barData}
                svgRef={svgContainerRef}
                containerWidth={ 230 }
                containerHeight={ 90 }
              />
            </div>

            <Legend
              legendData={barData}
            />
          </React.Fragment>
        }
      </div>
      <div className="dashboardWidgetWrapper donut">
        <EmptyLoading
          payload={ visualData }
          loading={ donutLoading }
          loadingMessage="Loading exploit status data"
          emptyMessage="No exploit status data"
        />
        <div className="widgetContent">
          {
            ( isNotEmpty( visualData ) && isNotEmpty( visualData.tally.results.exploit_status ) ) &&
            <ExploitStatusBreakdown
              data={ visualData?.tally }
              onClickCallback={ onClickCallback }
            />
          }
        </div>
      </div>
    </div>
  );
};

export default Widgets;