/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import {
  isNotEmpty,
  debounce,
} from '../../../Utilities';

const DebouncedText = ( {
  field,
  formState,
  originalValue,
  onChange,
  setIsFocused,
  existingRecord,
  fieldRef,
} ) => {

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  // debounces the value so it does not hammer the server
  const handleTextInputChange = debounce( value => {
    onChange( field, value );
  }, 1000 );

  return (
    <div className={ 'debouncedTextFieldWrapper'}>
      <input
        onFocus={ () => setIsFocused( true ) }
        onBlur={ () => setIsFocused( false ) }
        ref={fieldRef}
        placeholder={ field.placeholder ? field.placeholder : ''}
        disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
        type="text"
        onChange={ e => handleTextInputChange( e.target.value ) }
        { ...field.htmlProps }
      />
    </div>
  );
};

export default DebouncedText;
