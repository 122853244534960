/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// const selectOptions = {
//   multiple: true || false,
//   recordFetchNeeded: true || false,
//   recordType: 'host' || 'vulnerability' || 'patch' || 'signature',
//   enableKeyboardShortcuts: true || false,
// };

import React from 'react';
import { isEmpty, isNotEmpty, itemIsArray, itemIsObject, recordTypeDisplayName } from '../../../../Utilities';
import Options from './Options';
import InlineSVG from '../../../../InlineSVG';
import SelectedOption from './SelectedOption';
import { fetchNeededRecords } from './Shared';

import './style.scss';
import TagItem from '../../../../../components/RiskInsight/Tags/Item';

const SelectV2 = ( {
  field,
  // externalFormState,
  // formState,
  originalValue,
  onChange,
  // existingRecord,
  // fieldRef,
} ) => {

  const MAX_SELECTIONS = 5;

  const isSelected = option => isNotEmpty( selections ) && isNotEmpty( selections[option.value] );
  const isHovered = option => isNotEmpty( hoveredOption ) && hoveredOption.value === option.value;

  // used as an intermediary to store selections, needs to be a key value pair for lookup and display
  const [ selections, setSelections ] = React.useState( {} );
  // these will be the actual options that are displayed, can be an array or object
  const [ options, setOptions ] = React.useState( null );
  const [ showOptions, setShowOptions ] = React.useState( false );
  const [ hoveredOption, setHoveredOption ] = React.useState( null );
  const [ isMultiSelect, setIsMultiSelect ] = React.useState( false );

  const wrapperRef = React.useRef( null );

  const [ allowedValues, setAllowedValues ] = React.useState( null );

  const getPlaceholderText = field => {
    if ( isNotEmpty( field ) ) {
      if ( isNotEmpty( field.selectOptions?.placeholder ) ) {
        return field.selectOptions.placeholder;
      }
      if ( isNotEmpty( field.selectOptions?.recordFetchNeeded ) ) {
        return 'Type to search...';
      }
    }
    return 'Select...';
  };

  // if the field is a type to search, then we need to fetch the records that match the query
  const handleTypeToSearch = async ( query ) => {
    if ( isNotEmpty( query ) ) {
      const _allRecordsFetch = await fetchNeededRecords( null, field.selectOptions.recordType, query );

      if ( isNotEmpty( _allRecordsFetch ) ) {
        const _options = {};

        _allRecordsFetch.map( record => {
          _options[record.id] = record;
        } );

        if ( isNotEmpty( _options ) ) {
          setOptions( _options );
          setShowOptions( true );
        } else {
          setOptions( null );
        }
      } else {
        setOptions( null );
      }
    } else {
      setOptions( null );
    }
  };

  // need to handle a few situations,
  // 1. if the field is a single/multi select, then the trigger click will show the options
  // 2. if the field is a single/multi select, and the options are already shown, then hide the options
  // 3. if the field is a type to search, then the trigger click will do nothing
  const handleTriggerClick = () => {
    if (
      isNotEmpty( field )
      && isNotEmpty( field.selectOptions )
      && field.selectOptions.recordFetchNeeded
    ) {
      return;
    } else if ( isNotEmpty( options ) ) {
      setShowOptions( !showOptions );
    }
  };

  // add the option to the selections
  const addOption = option => {

    let optionLabel = option.label;

    // if the option is a recordFetch, format the name of the record
    if (
      itemIsObject( optionLabel )
      && isNotEmpty( field )
      && isNotEmpty( field.selectOptions )
      && isNotEmpty( field.selectOptions.recordFetchNeeded )
      && isNotEmpty( field.selectOptions.recordType )
    ) {
      optionLabel = recordTypeDisplayName( optionLabel, field.selectOptions.recordType );
    } else if (
      itemIsObject( optionLabel )
      && field.attribute === 'asset_tag_ids'
    ) {
      optionLabel = <TagItem tag={optionLabel} minimalVersion />;
    }

    if ( isNotEmpty( option ) ) {
      // multiselect, add or remove
      if ( isMultiSelect ) {
        const _selections = { ...selections };
        if ( isSelected( option ) ) {
          delete _selections[option.value];
        } else {
          _selections[option.value] = optionLabel;
        }
        setSelections( _selections );
      // if this is a single select, set the selection and close the options, replace the current selection
      } else {
        const _selections = { [option.value]: optionLabel };
        setSelections( _selections );
        setShowOptions( false );
      }
    }
  };

  // handle keyboard shortcuts for selecting options
  // up/down to move the selection and set the hoveredSelection, enter to select
  const handleKeyDown = ( e ) => {
    if ( isNotEmpty( e ) && isNotEmpty( options ) && showOptions ) {
      const _options = Object.entries( options );
      let _newOption = null;
      let _newIndex = isNotEmpty( hoveredOption )
        ? _options.findIndex( option => option[0] === hoveredOption.value )
        : 0;

      switch ( e.key ) {
      case 'ArrowUp': {
        _newIndex = _newIndex === 0 ? _options.length - 1 : _newIndex - 1;
        _newOption = { value: _options[_newIndex][0], label: _options[_newIndex][1] };
        setHoveredOption( _newOption );
        break;
      }
      case 'ArrowDown': {
        _newIndex = _newIndex === _options.length - 1 ? 0 : _newIndex + 1;
        _newOption = { value: _options[_newIndex][0], label: _options[_newIndex][1] };
        setHoveredOption( _newOption );
        break;
      }
      case 'Enter': {
        if ( isNotEmpty( hoveredOption ) ) {
          addOption( hoveredOption );
        }
        break;
      }
      default:
        break;
      }
    }
  };

  // 1. set allowedValues to 'multiple' if field.selectOptions.multiple === true
  React.useEffect( () => {
    if ( isNotEmpty( field ) && isNotEmpty( field.selectOptions ) ) {
      if ( field.selectOptions.multiple ) {
        setAllowedValues( 'multiple' );
        setIsMultiSelect( true );
      } else {
        setAllowedValues( 'single' );
        setIsMultiSelect( false );
      }
    } else {
      setAllowedValues( 'single' );
      setIsMultiSelect( false );
    }
  }, [ field ] );

  // 2. set the options. Even if it is grouped options, need to flatten them to an array, and put intermediate labels in
  React.useEffect( () => {
    if ( isNotEmpty( field ) && isNotEmpty( field.options ) ) {

      const _flattenedOptions = {};
      // options can have 2 forms, an array of ojbects, indicating that the options will be grouped
      // or an object, indicated that the options will not be grouped
      // GROUPED OPTIONS
      if ( itemIsArray( field.options ) ) {
        field.options.map( group => {
          // add a group label as an option
          _flattenedOptions[group.label] = { isLabelOption: true, label: group.label };
          // map over the options
          Object.entries( group.options ).map( ( [ value, content ] ) => {
            _flattenedOptions[value] = content;
          } );
        } );
      // UNGROUPED OPTIONS
      } else if ( itemIsObject( field.options ) ) {
        Object.entries( field.options ).map( ( [ value, content ] ) => {
          _flattenedOptions[value] = content;
        } );
      }

      // set the first option that is not a label to be the hovered option
      const _firstOption = Object.entries( _flattenedOptions ).find( ( [ , label ] ) => !label.isLabelOption );

      if ( isNotEmpty( _firstOption ) && field.selectOptions?.enableKeyboardShortcuts === true ) {
        setHoveredOption( { value: _firstOption[0], label: _firstOption[1] } );
      }
      setOptions( _flattenedOptions );
    }
  }, [ field ] );

  // 3. once the options have been figured out, and the flattenedOptions have been set, we can set the initial value
  // if there is one
  React.useEffect( () => {
    const setupInitinalValue = async () => {
      const _selections = {};
      // there is an original value
      if ( isNotEmpty( originalValue ) && isNotEmpty( allowedValues ) ) {
        // standard case, there are options passed in
        if (
          isNotEmpty( options )
        ) {
          // multiple selections
          if ( itemIsArray( originalValue ) && allowedValues === 'multiple' ) {
            originalValue.map( value => {
              const label = options[value];
              if ( isNotEmpty( label ) ) {
                _selections[value] = label;
              }
            } );
          // single selection
          } else {
            const label = options[originalValue];
            if ( isNotEmpty( label ) ) {
              _selections[originalValue] = label;
            }
          }
        // the value was originally fetched from the server, so the value is a uuid or something, the displayed
        // value needs to be constructed from some sort of fetch
        } else if ( field.selectOptions.recordFetchNeeded && isNotEmpty( field.selectOptions.recordType ) ) {
          let _allRecordsFetch = [];
          if ( itemIsArray( originalValue ) ) {
            _allRecordsFetch = await fetchNeededRecords( originalValue, field.selectOptions.recordType );

            if ( isNotEmpty( _allRecordsFetch ) ) {
              originalValue.map( value => {
                const _selection = _allRecordsFetch.find( record => record.id === value );
                if ( isNotEmpty( _selection ) ) {
                  _selections[value] = recordTypeDisplayName( _selection, field.selectOptions.recordType );
                }
              } );
            }
          } else {
            _allRecordsFetch = await fetchNeededRecords( [ originalValue ], field.selectOptions.recordType );

            if ( isNotEmpty( _allRecordsFetch ) ) {
              const _selection = _allRecordsFetch.find( record => record.id === originalValue );
              if ( isNotEmpty( _selection ) ) {
                _selections[originalValue] = recordTypeDisplayName( _selection, field.selectOptions.recordType );
              }
            }
          }
        }
        setSelections( _selections );
      // no originalValue
      } else {
        setSelections( {} );
      }
    };

    setupInitinalValue();

  }, [ field, options, originalValue, allowedValues ] );

  // 4. once the selections have been set, we can update the onChange
  React.useEffect( () => {
    if ( isNotEmpty( onChange ) ) {
      let _value = Object.keys( selections );
      _value = allowedValues === 'multiple' ? _value : _value[0];
      onChange( field, _value );
    }
  }, [ selections ] );

  // 5. any time the options change, bind the up/down and enter keys as keyboard shortcuts for selecting options
  React.useEffect( () => {
    if (
      isNotEmpty( options )
      && showOptions
      && isNotEmpty( field )
      && isNotEmpty( field.selectOptions )
      && ( 'enableKeyboardShortcuts' in field.selectOptions )
      && field.selectOptions.enableKeyboardShortcuts === true
    ) {
      window.addEventListener( 'keydown', handleKeyDown );
    } else {
      window.removeEventListener( 'keydown', handleKeyDown );
    }
    return () => {
      window.removeEventListener( 'keydown', handleKeyDown );
    };
  }, [ options, field, showOptions, hoveredOption ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( field ) &&
        <div className="selectV2Wrapper" ref={wrapperRef} >
          <span className="labelWrapper">
            <label>{ field.label }</label>
          </span>
          <div
            // eslint-disable-next-line max-len
            className={ `selectV2Trigger ${showOptions ? 'showOptions' : ''} ${ field?.selectOptions?.recordFetchNeeded ? 'fetchNeeded' : ''} ${ field?.selectOptions?.multiple ? 'isMultiSelect' : ''}` } onClick={ handleTriggerClick }
          >
            {
              isNotEmpty( selections ) &&
              <div className="selectionsWrapper">
                {
                  Object.entries( selections ).map( ( [ value, label ], index ) => {
                    const selection = { value, label };
                    if ( index < MAX_SELECTIONS ) {
                      return <SelectedOption
                        key={index}
                        field={field}
                        selection={ selection }
                        selections={selections}
                        setSelections={setSelections}
                      />;
                    }
                  } )
                }
              </div>
            }
            {/* if it is not a search, show the carret even if there are selection(s) */}
            {
              ( isNotEmpty( selections ) && !field.selectOptions?.recordFetchNeeded ) &&
              <InlineSVG type="carretDown" elementClass="selectCarretIcon" />
            }
            {
              ( isNotEmpty( selections ) && Object.keys( selections ).length > MAX_SELECTIONS ) &&
              <span className="selectionOverflow">
                +{Object.keys( selections ).length - MAX_SELECTIONS} more...
              </span>
            }
            {
              isEmpty( selections ) &&
              <React.Fragment>
                {/* either show a text field to search or the placeholder w/carret icon if mimicking a select */}
                {
                  ( field.selectOptions?.recordFetchNeeded && isNotEmpty( field.selectOptions?.recordType ) )
                    // type to search
                    ? <input
                      type="text"
                      placeholder={ getPlaceholderText( field ) }
                      onChange={ e => handleTypeToSearch( e.target.value ) }
                    />
                    // mimicking a select
                    : <React.Fragment>
                      <span className="placeholder">{ getPlaceholderText( field ) }</span>
                      <InlineSVG type="carretDown" elementClass="selectCarretIcon" />
                    </React.Fragment>
                }
              </React.Fragment>
            }
          </div>
          {
            isNotEmpty( options ) &&
            // to be rendered in a portal
            <Options
              options={options}
              field={field}
              wrapperRef={wrapperRef}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              selections={selections}
              setSelections={setSelections}
              addOption={ addOption }
              isSelected={ isSelected }
              isHovered={ isHovered }
              isMultiSelect={ isMultiSelect }
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default SelectV2;