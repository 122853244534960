/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../InlineSVG';
import { FlashMessageQueueContext } from '../../Contexts/FlashMessageQueue';
import { isNotEmpty } from '../Utilities';

import './FlashMessage.scss';
import CopyToClipBoard from '../CopyToClipBoard';

const headerForFlashType = type => {
  const headers = {
    'alert': 'Alert',
    'warning': 'Warning',
    'success': 'Success',
    'info': 'Notification',
  };
  return headers[type];
};

const flashMessageIconMap = {
  alert: 'notificationAlertV2',
  warning: 'notificationWarningV2',
  success: 'notificationSuccessV2',
  info: 'notificationInfoV2',
};

const FlashMessage = ( { flashMessage, messageKey } ) => {

  const [ , removeFlashMessage, , ] = React.useContext( FlashMessageQueueContext );

  let removalTimeout = null;

  // on init, once the flashMessage comes in, set up a timer to remove itself from the queue
  React.useEffect( ( ) => {
    if ( isNotEmpty( flashMessage ) && isNotEmpty( messageKey ) ) {
      stopTimeout();
      startTimeout();
    }
    return () => stopTimeout();
  }, [ flashMessage, messageKey ] );

  const stopTimeout = () => {
    clearTimeout( removalTimeout );
  };

  const startTimeout = ( ) => {
    const _timeout = flashMessage.duration || window.DEFAULT_FLASH_MESSAGE_DURATION;

    removalTimeout = window.setTimeout( () => {
      removeFlashMessage( messageKey );
    }, _timeout );
  };

  return <div
    // eslint-disable-next-line max-len
    className={ `flashMessage queued ${flashMessage.type} ${flashMessage.opacityClass} ${window.IS_DARK_MODE ? 'darkMode' : '' }` }
    id={ `queuedFlashMessage_${messageKey}` }
    onMouseEnter={ stopTimeout }
    onMouseLeave={ startTimeout }
  >
    {
      isNotEmpty( flashMessage.type )
        ? <InlineSVG type={ flashMessageIconMap[flashMessage.type] } />
        : <InlineSVG type={ flashMessageIconMap.info } />
    }
    <div
      className="flashMessageBody"
    >
      {
        isNotEmpty( flashMessage.header )
          ? <React.Fragment>
            <h2>{ flashMessage.header }</h2>
          </React.Fragment>
          : <h2>{ headerForFlashType( flashMessage.type ) }</h2>
      }
      { flashMessage.body }
    </div>
    <div className="flashMessageActions">
      <button
        onClick={ () => removeFlashMessage( messageKey ) }
      >
        <InlineSVG type="close" />
      </button>
      <CopyToClipBoard text={flashMessage.body} flash={false} />
    </div>
  </div>;
};

export default FlashMessage;