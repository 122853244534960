/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';

import './CheckButton.scss';

const CheckButton = ( { checked, handleCheck, elementClass, disabled, label } ) => {

  const handleChange = ( newValue ) => {
    if ( handleCheck ) {
      handleCheck( newValue );
    }
  };

  return (
    <div
      className={ `checkButton ${checked ? 'checked' : ''} ${elementClass} ${disabled ? 'disabled' : ''}`}
      onClick={ () => handleChange( !checked ) }
    >
      <svg
        width={ 16 }
        height={ 16 }
        // eslint-disable-next-line max-len
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {
          checked
            // eslint-disable-next-line max-len
            ? <path fillRule="evenodd" clipRule="evenodd" d="M2.55556 1H13.4444C14.3 1 15 1.7 15 2.55556V13.4444C15 14.3 14.3 15 13.4444 15H2.55556C1.7 15 1 14.3 1 13.4444V2.55556C1 1.7 1.7 1 2.55556 1ZM11.5628 4.5625L12.6595 5.66694L6.43728 11.8892L3.32617 8.78583L4.43062 7.68917L6.43728 9.68806L11.5628 4.5625Z" fill="#334D6E"/>
            // eslint-disable-next-line max-len
            : <path className="checkbox" d="M2.5 1.75H13.5C13.9142 1.75 14.25 2.08579 14.25 2.5V13.5C14.25 13.9142 13.9142 14.25 13.5 14.25H2.5C2.08579 14.25 1.75 13.9142 1.75 13.5V2.5C1.75 2.08579 2.08579 1.75 2.5 1.75Z" stroke="#334D6E" strokeWidth="1.5"/>
        }
      </svg>
      <span>
        {
          isNotEmpty( label )
            ? label
            : <React.Fragment>
              { checked ? 'Selected' : 'Not selected' }
            </React.Fragment>
        }
      </span>
    </div>
  );
};

export default CheckButton;