/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import PageTitle from './PageTitle';

import {
  decodeURLHash,
  getGlobalSettings,
  isNotEmpty,
} from '../../shared/Utilities';
import RiskScoreMenu from '../App/RiskScoreMenu';
import PageSelector from './PageSelector';
import { NavigationContext } from '../../Contexts/Navigation';
import { HelpContext } from '../../Contexts/Help';
import InlineSVG from '../../shared/InlineSVG';
import { ContextualHelpContainer } from '../HelpDocumentation/ContextualHelp';

const TopBar = ( { currentLocation, hoveringOverLeftNav } ) => {

  const [ global, setGlobal ] = React.useState( null );
  const [ expandLeftNavigation ] = React.useContext( NavigationContext );
  const [ , , , , showHelp, setShowHelp, , , , ] = React.useContext( HelpContext );

  React.useEffect( ( ) => {
    getGlobalSettings( 'global' ).then( _global => {
      if ( isNotEmpty( _global ) ) {
        setGlobal( _global );
      }
    } );
  }, [] );

  return (
    <div
      id="topBar"
      className={ `${expandLeftNavigation ? 'leftNavExpanded' : ''} ${window.IS_DARK_MODE ? 'darkMode' : ''}` }
    >
      <div className={ `innerTopBarWrapper ${ window.IS_DARK_MODE ? 'darkMode' : '' }` }>
        <div id="globalHeaderContainer">
          <div className="titleAndSelectorWrapper">
            <div id="pageCreatebuttonRootLeft" />
            <PageTitle currentLocation={currentLocation} />
            <PageSelector
              currentRoute={ decodeURLHash()['.'] }
              currentPage={ decodeURLHash().report ? decodeURLHash().report : decodeURLHash().page }
              hoveringOverLeftNav={hoveringOverLeftNav}
            />
            {/* empty divs used as a portals for the create button and any alerts */}
            <div id="pageAlertsRoot" />
            {
              !global?.hide_risk_score &&
              <RiskScoreMenu />
            }
          </div>
          <div className="rightHeaderContent">
            <div id="pageCreatebuttonRoot" />
            <div className="roundGlyphButton light" title="Help" onClick={ () => setShowHelp( !showHelp ) }>
              <InlineSVG type="help_center_nav" />
            </div>
          </div>
        </div>
        {/* empty div used as a portal when a child page needs to insert anything into the top bar (filters, etc.) */}
        <div id="pageHeaderRoot" />
      </div>
      <ContextualHelpContainer />
    </div>
  );
};

export default TopBar;