/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import SearchBar from './SearchBar';
import SearchResults from './SearchResults';

import {
  displayValueForResultType,
  getRecordForType,
} from './SearchData';

import {
  isNotEmpty,
  isEmpty,
  itemIsArray,
  itemIsString,
  itemIsObject,
  decodeURLHash,
} from '../../../../Utilities';

import InlineSVG from '../../../../InlineSVG';

// for the following attributes, we need different clearing behavior, we need a real fix for this,
// but needed to get this working -DMC 2023-10-25
const noClearAttributes = [
  // specific record path widgets
  'record_id_host',
  'record_id_patch',
  'record_id_user',
  'record_id_vulnerability',
  'record_id_widget',

  // remediation/accepted risk
  'task_vulnerability',
  'task_host',
];

const SearchWithResults = ( {
  field,
  setItems,
  formState,
  onChange,
  alreadySelectedItems={},
  existingRecord,
  fieldRef,
  needsLabel=true,
} ) => {

  const [ results, setResults ] = React.useState( [] );
  const [ term, setTerm ] = React.useState( '' );
  const [ selectedResult, setSelectedResult ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ noResults, setNoResults ] = React.useState( false );
  const [ shouldShowResults, setShouldShowResults ] = React.useState( false );

  const isRequired = field => formState?.fieldStates[field.attribute]?.required || field.required;

  const clearValue = e => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedResult( null );
    setShouldShowResults( false );
  };

  React.useEffect( () => {
    let _value = null;
    const hash = decodeURLHash();
    if ( hash.page !== 'explore_model' ) {
      if ( isNotEmpty( selectedResult ) ) {
        if ( field.selectCallback ) {
          field.selectCallback( selectedResult, field, onChange );
          if ( field.clearOnSelect ) {
            setSelectedResult( null );
          }
        } else {
          _value = selectedResult.id;
          onChange( field, _value );
        }
      } else if ( !field.clearOnSelect ) {
        onChange( field, _value );
      }
      setShouldShowResults( false );
    }

  }, [ selectedResult ] );

  // if there is already a value
  React.useEffect( () => {
    if (
      isNotEmpty( formState )
      && isNotEmpty( field )
      // && isEmpty( selectedResult )
      && isNotEmpty( formState.fieldStates )
      && isNotEmpty( formState.fieldStates[field.attribute] )
      // && isNotEmpty( formState.fieldStates[field.attribute].updatedValue )
      && !field.clearOnSelect
    ) {
      let recordID;
      if ( itemIsArray( ( formState?.fieldStates[field.attribute]?.updatedValue ) ) ) {
        // eslint-disable-next-line
        recordID = formState?.fieldStates[field.attribute]?.updatedValue[0];
      } else if (
        isNotEmpty( formState?.fieldStates[field.attribute]?.updatedValue )
        && itemIsString( formState?.fieldStates[field.attribute]?.updatedValue )
      ) {
        // eslint-disable-next-line
        recordID = formState.fieldStates[field.attribute]?.updatedValue?.split( ',' )[0];
      } else if (
        isNotEmpty( formState?.fieldStates[field.attribute]?.updatedValue )
        && itemIsObject( formState?.fieldStates[field.attribute]?.updatedValue )
      ) {
        // eslint-disable-next-line
        recordID = formState.fieldStates[field.attribute]?.updatedValue?.id;
      }
      if ( isNotEmpty( recordID ) ) {
        if ( field.attribute === 'scope_id' ) {
          getRecordForType( field.recordType, recordID, existingRecord ).then( response => {
            setSelectedResult( response );
          } );
        } else if ( isEmpty( selectedResult ) ) {
          getRecordForType( field.recordType, recordID, existingRecord ).then( response => {
            setSelectedResult( response );
          } );
        }
      }
    // essentially acts as a clear when the existing record gets cleared
    } else if (
      ( isEmpty( selectedResult ) || isEmpty( existingRecord ) )
      && ( !noClearAttributes.includes( field.attribute ) )
    ) {
      setSelectedResult( null );
    }
  }, [ existingRecord, formState, field ] );

  return (
    <React.Fragment>
      <div
        // eslint-disable-next-line max-len
        className={`searchWithResultsWrapper ${ isNotEmpty( results ) ? 'hasResults' : ''} ${ field?.inlineVersion ? 'inlineVersion' : '' }`}
      >
        {
          needsLabel &&
          <span className="labelWrapper">
            {
              field.label
            }
            {
              isRequired( field ) &&
              <span className="required">*</span>
            }
            { field.help && field.help }
          </span>
        }
        {
          field.disabled
            ? <React.Fragment>
              {
                selectedResult &&
                <div className="disabledWrapper">
                  {/* special logic for a specific field that has an obj. for a value */}
                  {
                    field.attribute === 'reason_vulnerability'
                      ? selectedResult.identifier
                      : displayValueForResultType( field.recordType, selectedResult )
                  }
                </div>
              }
            </React.Fragment>

            : <React.Fragment>
              {
                isNotEmpty( selectedResult )
                  ? <div className="selectedSearchResult">
                    { displayValueForResultType( field.recordType, selectedResult )}
                    <button
                      onClick={ clearValue }
                    >
                      <InlineSVG type="remove" />
                    </button>
                  </div>
                  : <SearchBar
                    recordType={field.recordType}
                    term={term}
                    setTerm={setTerm}
                    results={results}
                    setResults={setResults}
                    fieldRef={fieldRef}
                    field={field}
                    formState={formState}
                    alreadySelectedItems={alreadySelectedItems}
                    existingRecord={existingRecord}
                    setNoResults={setNoResults}
                    setLoading={setLoading}
                    onChange={onChange}
                    setItems={setItems}
                    setShouldShowResults={setShouldShowResults}
                  />
              }
              <SearchResults
                field={field}
                recordType={field.recordType}
                results={results}
                setResults={setResults}
                term={term}
                setTerm={setTerm}
                setSelectedResult={setSelectedResult}
                fieldRef={fieldRef}
                noResults={noResults}
                loading={loading}
                shouldShowResults={shouldShowResults}
                setShouldShowResults={setShouldShowResults}
              />
            </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
};

export default SearchWithResults;