/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import FilterForm from '../../../shared/FilterForm';
import IndeterminantPagination, { pageIterator, getRowNums } from '../../../shared/Pagination/IndeterminantPagination';

import { v4 as uuidv4 } from 'uuid';

import './style.scss';

import {
  paramsToFilters,
  isNotEmpty,
  isEmpty,
  encodeURLHash,
  triggerHashRefresh,
  formatUnixTime,
  decodeURLHash,
  itemIsArray,
  isTruthy,
} from '../../../shared/Utilities';


import {
  data,
  categoryOptions,
  priorityOptions,
  typeMap,
  typeLabelMap,
} from './data';

import { makeRequest } from '../../../../legacy/io';
import Modal from '../../../shared/Modal';
import PageHeader from '../../../shared/PageHeader';
import { descriptionFormatters, needsAwait } from './Formatters';
import ActivityList from '../ActivityList';
import InlineSVG from '../../../shared/InlineSVG';
import EmptyLoading from '../../../shared/EmptyLoading';
import ReportCreator, { openReportCreator } from '../../../shared/ReportCreator';

const DescriptionModalBody = ( { selectedAlert } ) => {

  const [ description, setDescription ] = React.useState( null );

  const labels = typeMap[selectedAlert?.source] || {};

  React.useEffect( () => {
    if ( isNotEmpty( selectedAlert ) ) {
      const formatter = descriptionFormatters[ selectedAlert?.original?.source ];

      if ( isNotEmpty( formatter ) ) {
        if ( needsAwait.includes( selectedAlert?.original?.source ) ) {
          formatter( selectedAlert?.original ).then( response => {
            setDescription( response );
          } );
        } else {
          const _description = formatter( selectedAlert?.original );
          setDescription( _description );
        }
      } else {
        setDescription( { content: <pre>{ JSON.stringify( selectedAlert?.original, null, 2 ) }</pre> } );
      }
    }
  }, [ selectedAlert ] );

  return (
    <div className="alertDescriptionWrapper">
      <div className={ `header ${selectedAlert?.priority}`}>
        <div className="iconWrapper">
          <InlineSVG type="configurationAlert" />
        </div>
        Configuration Alert
      </div>
      {
        isNotEmpty( selectedAlert ) &&
        <React.Fragment>
          <div className="upper">
            <span>
              <strong>Type:</strong> { labels[selectedAlert?.type] || selectedAlert?.type || 'N/A' }
            </span>
            <span>
              <strong>Subject:</strong> { selectedAlert?.details?.label || selectedAlert?.subject || 'N/A' }
            </span>
          </div>
          {
            isNotEmpty( description ) &&
            <section>
              <label>Details</label>
              <div className="detailsWrapper">
                { ( isNotEmpty( description ) && isNotEmpty( description.content ) ) && description.content }
              </div>
            </section>
          }
        </React.Fragment>
      }
    </div>
  );
};

const ModalActions = ( { selectedAlert, setVisible } ) => {
  const [ description, setDescription ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( selectedAlert ) ) {
      const formatter = descriptionFormatters[ selectedAlert?.original?.source ];

      if ( isNotEmpty( formatter ) ) {
        if ( needsAwait.includes( selectedAlert?.original?.source ) ) {
          formatter( selectedAlert?.original ).then( response => {
            setDescription( response );
          } );
        } else {
          const _description = formatter( selectedAlert?.original );
          setDescription( _description );
        }
      }
    }
  }, [ selectedAlert ] );

  return (
    <div className="alertActionsWrapper">
      {
        ( isNotEmpty( description ) && isNotEmpty( description.actions ) )
          ? description.actions.map( ( a, i ) => <React.Fragment key={i} >
            { a }
          </React.Fragment>,
          )
          : <button className="submitButton" onClick={ () => setVisible( false ) }>Okay</button>
      }
    </div>
  );
};

const ConfigurationAlerts = () => {

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  const [ selectedAlert, setSelectedAlert ] = React.useState( null );
  const [ showDescriptionModal, setShowDescriptionModal ] = React.useState( false );

  // const [ categoryFilterOptions, setCategoryFilterOptions ] = React.useState( null );
  // const [ filterTypeMap, setFilterTypeMap ] = React.useState( null );
  const [ filterInputs, setFilterInputs ] = React.useState( data.filterInputs );

  // exports vars
  const [ existingReport, setExistingReport ] = React.useState( null );

  const [ loading, setLoading ] = React.useState( true );

  const toggleItemVisibility = result => {

    if ( isNotEmpty( result ) ) {
      const { type, source, id, subject } = result?.original;

      let ignore = true;

      if ( isTruthy( result.original?.ignore ) ) {
        ignore = false;
      } else {
        ignore = true;
      }

      const _result = { type, source, id, subject, ignore };

      makeRequest( 'UPSERT', '/configuration_alert', {'records':[ _result ] } ).then( () => {
        onRefresh();
      } );
    }
  };

  const showDescriptionFor = alert => {
    setSelectedAlert( alert );
    setShowDescriptionModal( true );
  };

  React.useEffect( () => {
    if ( showDescriptionModal === false ) {
      setSelectedAlert( null );
    }
  }, [ showDescriptionModal ] );

  const onRefresh = async ( adjustedFilters=[] ) => {

    // if the filter that was just changed is not the current page, set it back to page 1
    if (
      isNotEmpty( adjustedFilters )
      && itemIsArray( adjustedFilters )
      && !adjustedFilters.some( attr => attr === 'current_page ' )
    ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { current_page: 1 } );
    }

    const _filterValues = paramsToFilters();

    setLoading( true );

    const onCorrectPage = decodeURLHash().page === 'configuration_alerts';

    if ( onCorrectPage ) {
      const params = {
        // eslint-disable-next-line camelcase
        field_map: {},
        keywords: '',
        // eslint-disable-next-line camelcase
        order_by: [ [ _filterValues.sort_by || 'priority', _filterValues.sort_direction || 'ASC' ] ],
        // eslint-disable-next-line camelcase
        extra_columns: [
          'id',
          'project_id',
          'source',
          'type',
          'subject',
          'priority',
          'details',
          'ignore',
          'created',
          'modified',
        ],
      };

      Object.entries( _filterValues ).map( ( [ key, val ] ) => {

        if ( key === 'ignore' ) {
          params.field_map.ignore = !!( val === true || val === 'true' );
        }
        if ( key === 'source' ) {
          if ( isNotEmpty( val ) ) {
            params.field_map.source = val;
          }
        }
        if ( key === 'type' ) {
          if ( isNotEmpty( val ) ) {
            params.field_map.type = val;
          }
        }
        if ( key === 'priority' ) {
          if ( isNotEmpty( val ) ) {
            params.field_map.priority = val;
          }
        }
        if ( key === 'keywords' ) {
          params.keywords = val;
        }
        if ( key ==='modified_start' ) {
          // eslint-disable-next-line camelcase
          params.gt_map = { modified: val };
        }
        if ( key === 'modified_end' ) {
          // eslint-disable-next-line camelcase
          params.lt_map = { modified: val };
        }

        const _rowNums = getRowNums( _filterValues );

        if ( key === 'item_count' ) {
          params.rownums = _rowNums;
        }
      } );

      if ( isEmpty( params.field_map.ignore ) ) {
        // eslint-disable-next-line camelcase
        params.field_map = { ...params.field_map, ignore: false };
      }

      const records = await makeRequest( 'SEARCH', '/configuration_alert', params );

      if ( isNotEmpty( records ) && isNotEmpty( records.results ) ) {
        const pagedResults = pageIterator( records.results, _filterValues );

        setCurrentPageNumber( _filterValues.current_page );
        setCurrentPageResults( adjustPageResults( pagedResults.firstPage ) );
        setNextPageResults( adjustPageResults( pagedResults.secondPage ) );
      } else {
        setCurrentPageResults( null );
        setNextPageResults( null );
      }
      setLoading( false );
    }
  };

  React.useEffect( () => {

    const setupFilters = async () => {
      const params = {
        // eslint-disable-next-line camelcase
        field_map: {},
        keywords: '',
        // eslint-disable-next-line camelcase
        order_by: [ [ 'priority', 'ASC' ] ],
        // eslint-disable-next-line camelcase
        extra_columns: [
          'id',
          'project_id',
          'source',
          'type',
          'subject',
          'priority',
          'details',
          'ignore',
          'created',
          'modified',
        ],
      };

      const tallies = await makeRequest( 'TALLY', '/configuration_alert', params );

      const _typeMap = { ...typeMap };
      const _categoryOptions = { ...categoryOptions };

      if ( isNotEmpty( _typeMap ) && isNotEmpty( tallies ) && isNotEmpty( tallies.results ) ) {
        Object.entries( _typeMap ).map( ( [ categoryKey, types ] ) => {

          if ( isNotEmpty( tallies.results[categoryKey] ) ) {
            const tallyCategory = tallies.results[categoryKey];

            _categoryOptions[categoryKey] = `${_categoryOptions[categoryKey]} (${tallyCategory._total})`;

            Object.entries( types ).map( ( [ typeKey, typeLabel ] ) => {
              if ( isNotEmpty( tallyCategory[typeKey] ) ) {
                _typeMap[categoryKey][typeKey] = `${typeLabel} (${tallyCategory[typeKey]})`;
              } else {
                _typeMap[categoryKey][typeKey] = `${typeLabel} (0)`;
              }
            } );
          } else {
            _categoryOptions[categoryKey] = `${_categoryOptions[categoryKey]} (0)`;
            Object.entries( types ).map( ( [ typeKey, typeLabel ] ) => {
              _typeMap[categoryKey][typeKey] = `${typeLabel} (0)`;
            } );
          }
        } );
      }

      const _filterInputs = [ ...data.filterInputs ];

      if ( isNotEmpty( _filterInputs ) ) {
        const categoryFilter = _filterInputs.find( f => f.attribute === 'source' );
        const typeFilter = _filterInputs.find( f => f.attribute === 'type' );

        if ( isNotEmpty( categoryFilter ) ) {
          categoryFilter.options = _categoryOptions;
        }
        if ( isNotEmpty( typeFilter ) ) {
          typeFilter.conditionalOptions = { attribute: 'source', options: _typeMap };
        }
      }

      setFilterInputs( _filterInputs );

      // setFilterTypeMap( _typeMap );
      // setCategoryFilterOptions( _categoryOptions );
    };

    setupFilters();

  }, [] );

  // whenever the results change, adjust the data for display in the table
  const adjustPageResults = ( results ) => {
    if ( results ) {
      return results.map( transformRowData );
    }
  };

  const transformRowData = ( row ) => {
    const { source } = row;
    const labels = typeLabelMap[source] || {};

    return {
      priority: priorityOptions[row.priority].label.toLowerCase() || row.priority.toLowerCase(),
      label: labels[row?.type] || row?.type || 'No Type',
      type: row?.type || 'No Type',
      subLabel: row?.details?.label || row?.subject || 'No Subject',
      subject: row?.details?.label || row?.subject || 'No Subject',
      category: categoryOptions[source] || source,
      source,
      timestamp: formatUnixTime( row.modified ),
      id: uuidv4(),
      original: row,
    };
  };

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ) } );
    triggerHashRefresh();
    // onRefresh();
  };

  React.useEffect( ( ) => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { item_count: 100,  current_page: 1 } );
    triggerHashRefresh();

    const hash = decodeURLHash();

    if ( hash.creating_report ) {
      const project = 'default';
      const model = 'base';
      const filters = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'created',
          'email_recipients',
          'format',
          'filters',
          'id',
          'label',
          'last_finished',
          'last_started',
          'schedule',
          'expiration',
          'type',
          'owner',
          'state',
        ],
        // eslint-disable-next-line camelcase
        id_list: [ hash.report_id ],
      };

      if ( isNotEmpty( hash.report_id ) ) {
        makeRequest( 'SEARCH', '/model/base/exported_report', {
          project,
          model,
          filters,
        } ).then( response => {
          if ( response && response.results ) {
            setExistingReport( response.results[0] );
          } else {
            setExistingReport( null );
          }
          openReportCreator();
        } );
      } else {
        setExistingReport( null );
        openReportCreator();
      }
    }
  }, [] );

  const handleExportButtonClick = () => {
    openReportCreator();
  };

  return (
    <React.Fragment>
      <ReportCreator
        advanced
        existingReport={existingReport}
        setExistingReport={setExistingReport}
      />
      <Modal
        elementClass="configAlertDescriptionModal"
        visible={showDescriptionModal}
        setVisible={setShowDescriptionModal}
        body={ <DescriptionModalBody selectedAlert={selectedAlert} /> }
        action={ <ModalActions selectedAlert={selectedAlert} setVisible={setShowDescriptionModal} />}
        size="small"
      />
      <PageHeader elementClass="configurationAlertsHeader">
        {
          isNotEmpty( filterInputs ) &&
          <FilterForm
            inputs={filterInputs}
            onRefresh={onRefresh}
            reportType="configuration_alerts"
          />
        }

        <IndeterminantPagination
          currentPageNumber={currentPageNumber}
          nextPageResults={nextPageResults}
          goToPage={goToPage}
        />
        <button
          className="exportMenuTrigger"
          onClick={ handleExportButtonClick }
        >
          <InlineSVG type="exportFile" />
          <span>Export</span>
        </button>
      </PageHeader>
      <EmptyLoading
        loading={ loading }
        payload={ currentPageResults }
        emptyMessage="No alerts matched the current filters"
      />
      {
        isNotEmpty( currentPageResults ) &&
        <ActivityList
          items={ currentPageResults }
          viewItem={ showDescriptionFor }
          toggleItemVisibility={ toggleItemVisibility }
          itemType="configurationAlert"
        />
      }
    </React.Fragment>
  );
};

export default ConfigurationAlerts;
