/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, itemIsArray } from '../../../shared/Utilities';
import NotScannedEmptyState from '../Host/NotScannedEmptyState';
import ScanningStatusIndicator from '../ScanningStatusIndicator';

import './DeepSurfaceScanning.scss';
import ScanLogViewer from '../../Activity/ScanLogs/ScanLogViewer';
import { makeRequest } from '../../../../legacy/io';

const DeepSurfaceScanning = ( { record } ) => {

  const [ thirdPartyIdentifiers, setThirdPartyIdentifiers ] = React.useState( null );
  const [ scanGroupMemberships, setScanGroupMemberships ] = React.useState( null );
  const [ agentVersion, setAgentVersion ] = React.useState( null );
  const [ hostNames, setHostNames ] = React.useState( null );

  const setupAdditionalHostInfo = async () => {

    // grab all scan groups
    const scanGroupsResponse = await makeRequest( 'FIND', '/project/default/scan_group', {} );

    const agentParams = {
      filters: {
        // eslint-disable-next-line camelcase
        host_ids: [ record.id ],
        rownums: [ 0, 1 ],
        // eslint-disable-next-line camelcase
        extra_columns: [
          'host_id',
          'local_name',
          'vendor',
          'version',
        ],
      },
    };

    const agentlessParams = {
      filters: {
        // eslint-disable-next-line camelcase
        host_ids: [ record.id ],
        rownums: [ 0, 1 ],
        // eslint-disable-next-line camelcase
        extra_columns: [
          'local_name',
          'vendor',
          'scan_groups',
        ],
      },
    };

    const agentStatusResponse = await makeRequest( 'SEARCH', '/scan_status/agent/default', agentParams );
    const agentlessStatusResponse = await makeRequest( 'SEARCH', '/scan_status/agentless/default', agentlessParams );

    const hostResponse = await makeRequest( 'POST', 'fe/insights/host/SELECT', {
      columns: [ 'host_names', 'third_party_identifiers' ],
      filters: {
        // eslint-disable-next-line camelcase
        field_map: {
          id: record.host_id,
        },
      },
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'risk', 'DESC' ],
        [ 'id', 'ASC' ],
      ],
      rows: [ 0, 100 ],
    } );

    if (
      isNotEmpty( agentlessStatusResponse )
      && isNotEmpty( agentlessStatusResponse.results )
      && itemIsArray( agentlessStatusResponse.results )
      && isNotEmpty( scanGroupsResponse )
    ) {
      const [ result ] = agentlessStatusResponse.results;

      const _scanGroups = [];

      if ( isNotEmpty( result.scan_groups ) ) {
        result.scan_groups.map( sgID => {
          const scanGroup = scanGroupsResponse?.results?.find( sg => sg.id === sgID );
          if ( isNotEmpty( scanGroup ) ) {
            _scanGroups.push( scanGroup );
          }
        } );
      }

      setScanGroupMemberships( _scanGroups );
    }

    if (
      isNotEmpty( agentStatusResponse )
      && isNotEmpty( agentStatusResponse.results )
      && itemIsArray( agentStatusResponse.results )
    ) {
      const [ result ] = agentStatusResponse.results;

      if ( isNotEmpty( result.version ) ) {
        setAgentVersion( result.version );
      }
    }

    if ( isNotEmpty( hostResponse ) && itemIsArray( hostResponse ) ) {
      const [ hostInfo ] = hostResponse;

      if ( isNotEmpty( hostInfo ) ) {
        if ( isNotEmpty( hostInfo.host_names ) ) {
          setHostNames( hostInfo.host_names );
        } else {
          setHostNames( null );
        }
        if ( isNotEmpty( hostInfo.third_party_identifiers ) ) {
          setThirdPartyIdentifiers( hostInfo.third_party_identifiers );
        } else {
          setThirdPartyIdentifiers( null );
        }
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( record.host_id ) ) {
      setupAdditionalHostInfo();
    }
  }, [ record ] );

  return (
    <React.Fragment>
      <div className="recordDetails__MainPanel__Body_DeepSurfaceScanning">
        <div className="recordDetails__MainPanel_BodyRow scanningStatusSummary">
          <h3 className="mainPanel__SectionHeader">{ window.COMPANY_NAME } Scanner Status</h3>
          <div className="bodySectionItem">
            <label>
              <span>Associated Host Names</span>
            </label>
            {
              isNotEmpty( hostNames )
                ? <pre>{ hostNames.join( ', ' ) }</pre>
                : <span>N/A</span>
            }
          </div>
          <div className="bodySectionItem">
            <label>
              <span>Associated Identifiers</span>
            </label>
            {
              isNotEmpty( thirdPartyIdentifiers )
                ? <pre>{ thirdPartyIdentifiers.join( ', ' ) }</pre>
                : <span>N/A</span>
            }
          </div>
          <div className="bodySectionItem">
            <label>
              <span>Associated Agent Version</span>
            </label>
            {
              isNotEmpty( agentVersion )
                ? <pre>{ agentVersion }</pre>
                : <span>N/A</span>
            }
          </div>
          <div className="bodySectionItem">
            <label>
              <span>Associated Scan Group(s)</span>
            </label>
            {
              isNotEmpty( scanGroupMemberships )
                ? <pre>{ scanGroupMemberships.map( sg => sg.label ).join( ', ' ) }</pre>
                : <span>N/A</span>
            }
          </div>
          <div className="bodySectionItem">
            <label>
              <span>Latest scan status</span>
            </label>
            <ScanningStatusIndicator timestamp={ record?.last_scanned } />
          </div>
        </div>
        {
          ( isNotEmpty( record?.last_scanned ) && isNotEmpty( record?.last_scanned_address ) )
            ? <div className="recordDetails__MainPanel_BodyRow latestScanLog">
              <h3 className="mainPanel__SectionHeader">
                <span>Latest Scan Log</span>
              </h3>
              <ScanLogViewer logIdentifier={ record?.last_scanned_address } />
            </div>
            : <NotScannedEmptyState />
        }
      </div>
    </React.Fragment>
  );
};

export default DeepSurfaceScanning;