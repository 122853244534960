/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import EmptyState from '../../../shared/EmptyState';
import DropdownButton from '../../../shared/DropdownButton';
import { getConfigurationAlertsFor } from '../../../shared/ConfigurationAlert';

import './style.scss';

import {
  userDisplayName,
  isNotEmpty,
  capitalize,
  decodeURLHash,
  removeFromURLHash,
  formatUnixTime,
  itemIsArray,
  getEncodedURLString,
} from '../../../shared/Utilities';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';
import ExportItem from './ExportItem';
import Modal from '../../../shared/Modal';
import InlineSVG from '../../../shared/InlineSVG';
import Notification from '../../../shared/Notification';
import PageCreateButton from '../../../shared/PageCreateButton';
import PageAlerts from '../../../shared/PageAlerts';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { hasFeatureAccess } from '../../App/AccessControl';
import {
  baseHostURL,
  baseInstanceHostURL,
  baseInstancePatchCumulativeURL,
  baseInstancePatchURL,
  baseInstanceSignatureURL,
  baseInstanceVulnerabilityURL,
  basePatchCumulativeURL,
  basePatchURL,
  baseVulnerabilityURL,
  defaultHostURL,
  defaultInstanceHostURL,
  defaultPatchURL,
  defaultVulnerabilityURL,
} from '../../App/Routing';
import { getEncodedHashStringForFilters } from '../../../shared/RecordCache';

const displayReportName = report => {
  if ( isNotEmpty( report ) ) {
    if ( isNotEmpty( report.label ) ) {
      // eslint-disable-next-line
      const _display = report.label.split( '---' )[0];

      return _display;

    }
    return `${capitalize( report.type )} Report`;

  }
};

const Exports = () => {

  /* eslint-disable max-len, camelcase */
  const REPORT_OPTIONS = {
    host: <a href={ `${defaultHostURL}&creating_report=true&return_to_reports=true` }>Hosts</a>,
    patch: <a href={ `${defaultPatchURL}&creating_report=true&return_to_reports=true` }>Patches</a>,
    vulnerability: <a href={ `${defaultVulnerabilityURL}&creating_report=true&return_to_reports=true` }>Vulnerabilities</a>,
    instance: <a href={ `${defaultInstanceHostURL}&creating_report=true&return_to_reports=true` }>Instances</a>,
    user: <a href="#.=risk_insight&report=users&item_count=100&order_by=filtered_risk&order_direction=DESC&creating_report=true&current_page=1&return_to_reports=true">Domain Users</a>,
  };

  const BASE_REPORT_URLS = {
    host: baseHostURL,
    patch: basePatchURL,
    patch_cumulative: basePatchCumulativeURL,
    vulnerability: baseVulnerabilityURL,

    user: '#.=risk_insight&report=users',
    remediation: '#.=remediation&page=remediation_plans',
    dashboard: '#.=reporting&page=reporting_dashboard',
    configuration_alerts: '#.=activity&page=configuration_alerts',

    instance_host: baseInstanceHostURL,
    instance_patch: baseInstancePatchURL,
    instance_patch_cumulative: baseInstancePatchCumulativeURL,
    instance_vulnerability: baseInstanceVulnerabilityURL,
    instance_signature: baseInstanceSignatureURL,
  };
  /* eslint-enable max-len, camelcase */

  const [ results, setResults ] = React.useState( [] );
  const [ allUsers, setAllUsers ] = React.useState( {} );
  const [ validUsers, setValidUsers ] = React.useState( {} );
  const [ focusedReport, setFocusedReport ] = React.useState( {} );
  const [ hasNotifications, setHasNotifications ] = React.useState( null );
  const [ showNotificationsModal, setShowNotificationsModal ] = React.useState( null );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  let listPoll = null;

  const removeReportFocus = () => {
    removeFromURLHash( 'report_id' );
    setFocusedReport( {} );
  };

  const refreshList = () => {

    const project = 'default';
    const model = 'base';
    const filters = {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'created',
        'email_recipients',
        'format',
        'filters',
        'group_filters',
        'item_count',
        'order_by',
        'id',
        'label',
        'last_finished',
        'last_started',
        'schedule',
        'expiration',
        'type',
        'owner',
        'state',
        'display_options',
      ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'created', 'DESC' ] ],
    };

    makeRequest( 'SEARCH', '/model/base/exported_report', {
      project: project,
      model: model,
      filters: filters,
    } ).then( response => {
      if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
        response.results.map( r => {
          r.reportEditURL = getEditURL( r );
        } );
        setResults( response.results );
      } else {
        setResults( [] );
      }
    } );
  };

  React.useEffect( () => {
    if ( isNotEmpty( results ) && itemIsArray( results ) ) {

      const hash = decodeURLHash();
      if ( isNotEmpty( hash.report_id ) ) {
        const _focused = results.find( report => report.id === hash.report_id );

        if ( isNotEmpty( _focused ) ) {
          setFocusedReport( _focused );
        }
      }
    }
  }, [ results ] );


  React.useEffect( () => {
    let isMounted = true;

    makeRequest( 'POST', '/fe/user/SELECT', {
      // eslint-disable-next-line camelcase
      columns: [
        'authentication_provider_id',
        'username',
        'given_name',
        'family_name',
        'api_key',
        'setup_wizard_disabled',
        'email_address',
      ],
      'order_by':[ [ 'username', 'ASC' ] ],
    } ).then( response => {
      if ( isNotEmpty( response ) && itemIsArray( response ) ) {
        const _validUsers = {};
        const _allUsers = {};

        response.map( user => {
          _allUsers[user.id] = userDisplayName( user );
          if ( isNotEmpty( user.email_address ) ) {
            _validUsers[user.id] = userDisplayName( user );
          }
        } );

        if ( isMounted ) {
          setAllUsers( _allUsers );
          setValidUsers( _validUsers );
        }
      }
    } );

    clearInterval( listPoll );

    if ( isMounted ) {
      refreshList();
    }

    listPoll = setInterval( function () {
      refreshList();
    }, 2000 );
    return () => {
      isMounted = false;
      clearInterval( listPoll );
    };
  }, [] );

  React.useEffect( ( ) => {
    if ( isNotEmpty( currentUser ) && hasFeatureAccess( currentUser, licenseInfo, 'f_configuration_alerts' ) ) {
      getConfigurationAlertsFor( 'email' ).then( response => {
        if ( isNotEmpty( response ) ) {
          setHasNotifications( true );
        }
      } );
    }
  }, [ currentUser, licenseInfo ] );

  const updateAndRunReport = async ( report ) => {
    // legacy method
    if ( report.type === 'dashboard' || report.type === 'remediation' || report.type === 'configuration_alerts' ) {

      const _report  = { ...report };

      const originalLabel = _report.label;

      let timestamp = new Date();
      let newLabel = '';

      if ( isNotEmpty( _report.last_finished ) ) {
        timestamp = new Date( _report.last_finished * 1000 );
      }

      const date = formatUnixTime( timestamp / 1000, false, null, null, '-' );

      if ( isNotEmpty( originalLabel ) ) {
        newLabel = `${originalLabel.split( '---' )[0]}---${date}`;
      }

      // eslint-disable-next-line max-len, camelcase
      await makeRequest( 'UPDATE', '/model/base/exported_report', { record: { ..._report, label: newLabel, risk_type: 'risk' } } );
      await makeRequest( 'START', '/model/base/exported_report', { id: _report.id } );
      refreshList();
    // new method
    } else {
      const _report  = { ...report };

      const originalLabel = _report.label;

      let timestamp = new Date();
      let newLabel = '';

      if ( isNotEmpty( _report.last_finished ) ) {
        timestamp = new Date( _report.last_finished * 1000 );
      }

      const date = formatUnixTime( timestamp / 1000, false, null, null, '-' );

      if ( isNotEmpty( originalLabel ) ) {
        newLabel = `${originalLabel.split( '---' )[0]}---${date}`;
      }

      if ( _report.owner === null || _report.owner === undefined || _report.owner === '' || _report.owner === 'null' ) {
        _report.owner = null;
      }

      delete _report.reportEditURL;
      delete _report.last_started;
      delete _report.last_finished;
      delete _report.state;
      delete _report.created;

      if ( isNotEmpty( _report.filters ) ) {
        delete _report.filters.include_risk;
        delete _report.filters.creating_report;
      }

      await makeRequest( 'PUT', '/fe/exported_report/UPDATE', [ { ..._report, label: newLabel } ] );
      await makeRequest( 'START', '/model/base/exported_report', { id: _report.id } );
      refreshList();
    }
  };

  const deleteReport = report => {
    if ( confirm( 'Are you sure you want to delete this report?' ) ) {
      makeRequest( 'PUT', '/fe/exported_report/DELETE', [ report.id ] ).then( response => {
        if ( response && response.results ) {
          addFlashMessage( {
            body: 'Successfully deleted report configuration',
            type: 'success',
          } );
          refreshList();
        }
      } );
    }
  };

  const getEditURL = report => {
    const _type = report.type;
    let commonParams = '&creating_report=true&return_to_reports=true';

    if ( _type.includes( 'instance' ) ) {
      const [ , _groupType ] = report.type.split( '_' );
      commonParams = `&group_type=${_groupType}${commonParams}`;
    }

    const baseURL = BASE_REPORT_URLS[_type];

    let filters = '';
    let identifier = '';

    let rows = '&rows=%5B0%2C200%5D';
    // eslint-disable-next-line camelcase
    let order_by = '&order_by=%5B%5B"filtered_risk"%2C"DESC"%5D%5D';
    if ( _type !== 'remediation' ) {
      if ( isNotEmpty( report.filters ) ) {
        filters = getEncodedHashStringForFilters( report.filters, report.group_filters );
      }
      if ( isNotEmpty( report.item_count ) ) {
        const end = report.item_count > 200 ? 200 : report.item_count;
        rows = getEncodedURLString( { rows: [ 0, end ] } );
      }
      if ( isNotEmpty( report.order_by && itemIsArray( report.order_by ) ) ) {
        // eslint-disable-next-line camelcase
        order_by = getEncodedURLString( { order_by: report.order_by} );
      }
    }

    if ( _type === 'remediation' ) {
      identifier = `&selected_record=${report.filters.plan_id}`;
    } else {
      identifier = `&report_id=${report.id}`;
    }
    let _url = `${baseURL}${filters}${commonParams}${identifier}`;
    if ( isNotEmpty( rows ) ) {
      _url += rows;
    }
    if ( isNotEmpty( order_by ) ) {
      // eslint-disable-next-line camelcase
      _url += order_by;
    }
    return _url;
  };

  const reRunReport = report => {
    updateAndRunReport( report );
  };

  const displaySchedule = report => {
    if ( isNotEmpty( report.schedule ) && isNotEmpty( report.schedule.recurring ) ) {
      // eslint-disable-next-line
      const schedule = report.schedule.recurring[0];

      if ( schedule.type === 'manual' ) {
        return 'N/A';
      }
      if ( schedule.type === 'monthly' ) {
        return  <React.Fragment>
          <strong>Monthly:</strong>
          <span>
            { ` on the ${schedule.week} ${capitalize( schedule.dow )}. @ ${schedule.time}` }
          </span>
        </React.Fragment>;
      }
      if ( schedule.type === 'weekly' ) {
        return  <React.Fragment>
          <strong>Weekly:</strong>
          <span>{ ` every ${capitalize( schedule.dow )}. @ ${schedule.time}` }</span>
        </React.Fragment>;
      }
      if ( schedule.type === 'daily' ) {
        return  <React.Fragment>
          <strong>Daily:</strong>
          <span>{ ` @ ${schedule.time}` }</span>
        </React.Fragment>;
      }
    } else {
      return 'N/A';
    }
  };

  const displayUsers = report => {
    if ( isNotEmpty( report.email_recipients ) ) {
      const names = [];

      report.email_recipients?.map( uuid => {
        names.push( validUsers[uuid] );
      } );

      if ( report.email_recipients.length > 3 ) {
        names.push( `+ ${report.email_recipients.length - 3 } more...` );
      }

      return names.join( ', ' );
    }
    return 'No Recipients';
  };

  return (
    <div className="setupPageContainer">
      {
        hasNotifications &&
        <React.Fragment>
          <Modal
            visible={ showNotificationsModal }
            setVisible={ setShowNotificationsModal }
            elementClass="setupNotificationsModal"
            body={
              <Notification
                options={
                  {
                    type: 'alert',
                    // eslint-disable-next-line max-len
                    message: <span>The most recent attempt to send email resulted in an SMTP failure. Please see the <a href="#.=activity&page=configuration_alerts&source=email"> relevant configuration alerts </a> for more information.</span>,
                  }
                }
              />
            }
          />
          <PageAlerts>
            <button className="showNotificationsButton" onClick={ () => setShowNotificationsModal( true ) }>
              <InlineSVG type="notifications_nav" />
              <span className="notificationsCount">1</span>
            </button>
          </PageAlerts>
        </React.Fragment>
      }
      <PageCreateButton>
        <DropdownButton
          trigger={ <React.Fragment>
            <span>New Export</span>
          </React.Fragment>
          }
          options={ Object.values( REPORT_OPTIONS ) }
        />
      </PageCreateButton>
      {
        isNotEmpty( results )
          ? <ul className="setupRecordsList">
            {
              results.map( ( report, index ) => {

                return  <ExportItem
                  report={report}
                  key={index}
                  focusedReport={focusedReport}
                  removeReportFocus={removeReportFocus}
                  displayReportName={displayReportName}
                  allUsers={allUsers}
                  displaySchedule={displaySchedule}
                  displayUsers={displayUsers}
                  deleteReport={deleteReport}
                  reRunReport={reRunReport}
                  updateAndRunReport={updateAndRunReport}
                  refreshList={refreshList}
                />;
              } )
            }
          </ul>
          : <EmptyState />
      }
    </div>
  );
};

export default Exports;
