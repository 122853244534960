/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  capitalize,
  decodeURLHash,
  isNotEmpty,
  itemIsArray,
  userDisplayName,
} from '../../../shared/Utilities';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { defaultRemdiationItems, planOptionsForReportType, remediationItemsKey, reportTypes } from '../Table/shared';
import { makeRequest } from '../../../../legacy/io';
import ReportCreator, { openReportCreator } from '../../../shared/ReportCreator';
import InlineSVG from '../../../shared/InlineSVG';
import Dropdown from '../../../shared/Dropdown';
// import InsightVisual from '../Visual';
import './style.scss';
import AddToPlanMenu from '../../Remediation/AddToPlanMenu';
import { canConfigure } from '../../App/AccessControl';
import { DOES_NOT_EXPIRE } from '../../../shared/ReportCreator/data';
import FilterBuilder from '../Filters/FilterBuilder';
import InsightVisual from '../Visual';
import { buildParamsForFastApiEndpoints } from '../../../shared/RecordCache';

const ReportHeader = ( {
  reportType,
  onRefresh,
  visualCollapsed,
  setVisualCollapsed=() => {},
  selectRecord,
  loading,
  riskType=null,
  instancesVisualData=null,
  remediationItems,
  setRemediationItems,
  records,
  appliedFilters,
  setAppliedFilters,
} ) => {

  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const [ existingReport, setExistingReport ] = React.useState( {} );
  const [ creatingReport, setCreatingReport ] = React.useState( false );

  const exportReport = format => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;

    const hash = decodeURLHash();

    const reportID = uuidv4();

    const reportType = isNotEmpty( hash.report ) ? hash.report : 'vulnerability_data';

    let _type = reportTypes[reportType];

    if ( _type === 'instance' ) {
      _type = `instance_${ hash.group_type || 'host'}`;
    }

    const newReport = {
      owner: currentUser.id,
      // eslint-disable-next-line max-len
      label: `${userDisplayName( currentUser, true )}_${capitalize( reportTypes[reportType] )}_Report---${date}`,
      format,
      type: _type,
      id: reportID,
      expiration: DOES_NOT_EXPIRE,
      // eslint-disable-next-line camelcase
      email_recipients: [],
      // eslint-disable-next-line camelcase
      display_options: {},
    };

    if ( reportTypes[reportType] === 'instance' ) {
      // eslint-disable-next-line camelcase
      newReport.display_options.group_type = hash.group_type || 'host';
    }

    if (
      newReport.owner === null
      || newReport.owner === undefined
      || newReport.owner === ''
      || newReport.owner === 'null'
    ) {
      newReport.owner = null;
    }

    let rt = reportTypes[reportType];

    if ( rt === 'instance' ) {
      rt = hash.group_type || 'host';
    }

    const params = buildParamsForFastApiEndpoints( rt );

    if ( isNotEmpty( params ) ) {
      const { filters, order_by, risk_type, rows, group_filters } = params;
      newReport.filters = filters;
      // eslint-disable-next-line camelcase
      newReport.order_by = order_by;
      // eslint-disable-next-line camelcase
      newReport.risk_type = risk_type;
      // eslint-disable-next-line
      newReport.item_count = rows[1];
      if ( isNotEmpty( group_filters ) ) {
        // eslint-disable-next-line camelcase
        newReport.group_filters = group_filters;
      }
    }

    if ( format === 'xlsx' ) {
      // eslint-disable-next-line camelcase
      newReport.display_options = { full_xlsx_results: true };
      // eslint-disable-next-line camelcase
      newReport.item_count = 200_000;
    }

    if ( isNotEmpty( newReport.filters ) ) {
      delete newReport.filters.include_risk;
      delete newReport.filters.creating_report;
    }

    makeRequest( 'PUT', '/fe/exported_report/INSERT', [ newReport ] ).then( response => {
      if ( response && itemIsArray( response ) ) {
        makeRequest( 'START', '/model/base/exported_report', { id: reportID } ).then( () => {
          window.location.href = `#.=reporting&page=exports&report_id=${reportID}`;
        } );
      }
    } );
  };

  const handleConfigureExport = () => {
    openReportCreator();
    setCreatingReport( true );
    setVisualCollapsed( false );
  };

  // for the table actions (currently only has export options)
  const getExportMenuItems = () => {
    const items = [
      <div
        onClick={ () => exportReport( 'xlsx' ) }
        title={`Export ${ capitalize( reportType ) } Report`}
      >
        <InlineSVG type="xlsx" size="xlarge" version="special" />
        <span>Quick Export as .xlsx</span>
      </div>,
    ];

    items.push(
      <div
        onClick={ () => exportReport( 'pdf' ) }
        title={`Export ${ capitalize( reportType ) } Report`}
      >
        <InlineSVG type="pdf" size="xlarge" version="special" />
        <span>Quick Export as .pdf</span>
      </div>,
    );

    items.push(
      <div onClick={ handleConfigureExport }>
        <InlineSVG type="setup" />
        Configure Export
      </div>,
    );

    return items;
  };

  const addToPlanMenuCallback = ( isAcceptedRisk=false ) => {
    setRemediationItems( defaultRemdiationItems );
    if ( isAcceptedRisk ) {
      onRefresh();
    }
  };

  const allowsRemediation = [
    'host',
    'patch',
    'vulnerability',
    'instance',
  ];

  const handleReportCreatorParams = () => {
    const hash = decodeURLHash();

    if ( hash.creating_report ) {
      setCreatingReport( true );
    } else {
      setCreatingReport( false );
    }
  };

  // 1) sets up the report creator if needed
  React.useEffect( ( ) => {
    const hash = decodeURLHash();
    if ( hash.creating_report ) {
      setCreatingReport( true );
      const project = 'default';
      const model = 'base';
      const filters = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'created',
          'email_recipients',
          'format',
          'filters',
          'id',
          'label',
          'last_finished',
          'last_started',
          'schedule',
          'expiration',
          'type',
          'owner',
          'state',
          'display_options',
        ],
        // eslint-disable-next-line camelcase
        id_list: [ hash.report_id ],
      };

      if ( hash.report_id ) {
        makeRequest( 'SEARCH', '/model/base/exported_report', {
          project,
          model,
          filters,
        } ).then( response => {
          if ( response && response.results ) {
            setExistingReport( response.results[0] );
          } else {
            setExistingReport( {} );
          }
          openReportCreator( );
        } );
      } else {
        setExistingReport( {} );
        openReportCreator( );
      }
    }
    window.addEventListener( 'hashchange', handleReportCreatorParams );
    return () => {
      window.removeEventListener( 'hashchange', handleReportCreatorParams );
    };
  }, [ reportType ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( reportType ) &&
        <div className={ `riskInsightIndexReportHeader ${reportType} ${visualCollapsed ? 'visualCollapsed' : ''}` }>
          {
            (
              allowsRemediation.includes( reportType )
              && isNotEmpty( remediationItems[remediationItemsKey( reportType )] )
            ) &&
            <AddToPlanMenu
              items={ remediationItems[ remediationItemsKey( reportType ) ] }
              itemsKey={ remediationItemsKey( reportType ) }
              callback={ addToPlanMenuCallback }
              planOptions={ planOptionsForReportType[remediationItemsKey( reportType )] }
            />
          }

          <div className="riskInsightIndexHeaderRow">
            <div className="left">
              {
                ( reportType !== 'path' && reportType !== 'user' ) &&
                <FilterBuilder
                  recordType={reportType}
                  onRefresh={onRefresh}
                  appliedFilters={appliedFilters}
                  setAppliedFilters={setAppliedFilters}
                />
              }
            </div>
            {
              reportType !== 'path' &&
              <Dropdown
                disabled={ !canConfigure( licenseInfo ) }
                trigger={
                  // eslint-disable-next-line max-len
                  <button disabled={!canConfigure( licenseInfo )} className={ `${ !canConfigure( licenseInfo ) ? 'disabled' : ''} exportOptionsMenuTrigger ${ window.IS_DARK_MODE ? 'darkMode' : ''}` }>
                    <span>Export as filtered</span>
                  </button>
                }
                menuItems={ getExportMenuItems() }
                elementClass="exportOptionsMenuWrapper"
                menuElementClass="exportOptionsMenu"
              />
            }
          </div>
          <ReportCreator
            existingReport={existingReport}
            setExistingReport={setExistingReport}
            filterBuilderVariant
          />
          <button
            className={ `roundGlyphButton light showVisualToggle ${ visualCollapsed ? 'visualDisabled' : ''}` }
            onClick={ () => setVisualCollapsed( !visualCollapsed )}
            title="View/Hide Chart"
          >
            {
              visualCollapsed
                ? <React.Fragment>
                  <InlineSVG type="expand" />
                </React.Fragment>
                : <React.Fragment>
                  <InlineSVG type="collapse" />
                </React.Fragment>
            }
          </button>
          {
            !creatingReport &&
            <React.Fragment>
              <InsightVisual
                reportType={reportType}
                selectRecord={selectRecord}
                onRefresh={onRefresh}
                records={records}
                riskType={ isNotEmpty( riskType ) ? riskType : 'filtered_risk' }
                loading={loading}
                visualData={instancesVisualData}
                collapsed={visualCollapsed}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
              />
            </React.Fragment>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default ReportHeader;