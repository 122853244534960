/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { integrationNameMap, isNotEmpty } from '../../../../shared/Utilities';
import UserRow from './UserRow';

import './UserMapping.scss';

const UserMapping = ( {
  selectedRecord,
  userMappings,
  setUserMappings,
  externalUsers,
} ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( userMappings ) &&
        <div className="userMappingContainer">
          <h2>Map { window.COMPANY_NAME } Users to {integrationNameMap[selectedRecord?.tool] || 'External'} Users</h2>
          <ul>
            {
              Object.values( userMappings ).map( ( user, index ) => {
                return <UserRow
                  key={index}
                  user={user}
                  userIndex={index}
                  userMappings={userMappings}
                  setUserMappings={setUserMappings}
                  existingRecord={selectedRecord}
                  externalUsers={externalUsers}
                />;
              } )
            }
          </ul>
        </div>
      }
    </React.Fragment>
  );
};

export default UserMapping;