/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import EmptyState from '../../../shared/EmptyState';
import DataTable from '../../../shared/DataTable';
import IndeterminantPagination from '../../../shared/Pagination/IndeterminantPagination';

import {
  isEmpty,
  isNotEmpty,
  credentialDisplayName,
  formatUnixTime,
  decodeURLHash,
  encodeURLHash,
} from '../../../shared/Utilities';

import {
  scanningModeOptionsMap,
} from './data';
import InlineSVG from '../../../shared/InlineSVG';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import Loading from '../../../shared/Loading';
import { makeRequest } from '../../../../legacy/io';
import ScanningStatusIndicator from '../../RecordDetails/ScanningStatusIndicator';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { canConfigure } from '../../App/AccessControl';
import { defaultHostURL } from '../../App/Routing';

const Table = ( {
  scanningMode,
  results,
  nextResults,
  currentPageNumber,
  credentials,
  scanGroups,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  onPageChange,
  onRefresh,
  setSelectedScanGroups,
  setSelectedIPAddresses,
  setShowConnectivityModal,
} ) => {

  const [ adjustedData, setAdjustedData ] = React.useState( [] );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ loading, setLoading ] = React.useState( false );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  // pagination scroll behavior
  const [ scrollToTableTop, setScrollToTableTop ] = React.useState( false );

  const emptyStateMessage = () => {
    if ( scanningMode === 'agent' ) {
      return <span>
        Your Filters did not return any results. Have you deployed any agents?
        You can do that <a href="#.=scanning&page=agents">here</a>.
      </span>;
    }
    return <span>
      Your Filters did not return any results. Have you configured any scans?
      You can do that <a href="#.=scanning&page=scan_groups">here</a>.
    </span>;
  };
  // eslint-disable-next-line camelcase
  const getFormattedIPs = ( addresses, last_scanned ) => {
    const formatted = [];
    // eslint-disable-next-line camelcase
    let toFormat = [ last_scanned, ...addresses ];
    if ( isEmpty( last_scanned ) ) {
      toFormat = addresses;
    }
    if ( isNotEmpty( toFormat ) ) {
      toFormat.map( address => {
        // eslint-disable-next-line
        const _formattedIP = address.split( '/' )[0];
        if ( !formatted.includes( _formattedIP ) ) {
          return formatted.push( _formattedIP );
        }
      } );
    }
    return formatted;
  };

  const scanGroupLabels = scanGroupUUIDs => {

    if ( isEmpty( scanGroupUUIDs ) ) {
      return '';
    }

    const _scanGroups = [];

    scanGroupUUIDs.map( uuid => {
      const sg = scanGroups.find( sg => sg.id === uuid );

      if ( isNotEmpty( sg ) ) {
        _scanGroups.push( sg.label );
      }
    } );

    return _scanGroups.join( ', ' );
  };

  const getCredentialLabel = uuid => {
    const cred = credentials.find( c => c.id === uuid );

    if ( cred ) {
      return <a href={`#.=scanning&page=credentials&selected_record=${uuid}`}>
        { credentialDisplayName( cred ) }
      </a>;
    }
    return 'N/A';
  };

  const getScanGroups = scanGroupUUIDs => {
    if ( isEmpty( scanGroupUUIDs ) ) {
      return [];
    }

    const _scanGroups = [];

    scanGroupUUIDs.map( uuid => {
      const sg = scanGroups.find( sg => sg.id === uuid );

      if ( isNotEmpty( sg ) ) {
        _scanGroups.push( sg );
      }
    } );

    return _scanGroups;
  };

  const showConnectivity = row => {
    setSelectedScanGroups( getScanGroups( row.scan_groups ) );
    setSelectedIPAddresses( getFormattedIPs( row.ip_addresses, row.last_scanned_address ) );
    setShowConnectivityModal( true );
  };

  const decommissionHost = hostID => {
    const agentlessString= 'Are you sure you want to decommision this host?';
    setLoading( true );
    // eslint-disable-next-line max-len
    const decommissionHostString = `Deleting an agent will deregister it from ${ window.COMPANY_NAME } and remove it from the threat model. Some host-related vulnerability data will remain until your vulnerability scanners stop reporting those vulnerabilities.\nThis action will not uninstall the ${ window.COMPANY_NAME } agent software from the agent host. To do that, use the Add or remove programs dialog or equivalent.\n\nAre you sure you want to delete the agent from the main console?`;
    if ( confirm( scanningMode === 'agent' ? decommissionHostString : agentlessString ) ) {
      // eslint-disable-next-line camelcase
      makeRequest( 'DELETE', '/host', { host_ids:[ hostID ]} ).then( () => {
        onRefresh();
        setLoading( false );
        addFlashMessage( {
          type: 'success',
          body: 'Successfully decommissioned host',
        } );
      } );
    } else {
      setLoading( false );
    }
  };

  const hostNameLink = ( row ) => {
    if ( isEmpty( row.host_id ) || !row.has_host ) {
      return <span className="notLink">
        <div className="iconWrapper notScanned">
          <InlineSVG type="notScanned" />
        </div>
        { row.local_name }
      </span>;
    }
    return <a
      // eslint-disable-next-line max-len
      href={`${defaultHostURL}&item=${row.host_id}`}
      className="tableReportLink"
      target="_blank"
      rel="noopener noreferrer"
    >
      { row.local_name }
    </a>;
  };

  const goToHost = id => {
    // eslint-disable-next-line max-len
    window.open( `${defaultHostURL}&item=${id}` );
  };

  const transformRowData = ( row, scanningMode ) => {
    if ( scanningMode === 'agent' || scanningMode === 'user_managed' ) {
      return {
        // eslint-disable-next-line camelcase
        host_name: isNotEmpty( row.local_name )
          // eslint-disable-next-line max-len
          ? hostNameLink( row )
          : 'N/A',
        // eslint-disable-next-line camelcase
        addresses:  isNotEmpty( row.ip_addresses )
          ? <ul>
            {
              getFormattedIPs( row.ip_addresses, null ).map( ( ip, i ) => {
                return  <li key={i}>
                  { ip }
                </li>;
              } )
            }
          </ul>
          : 'N/A',
        version: row.version,
        // eslint-disable-next-line camelcase
        last_check_in: isNotEmpty( row.last_acs_update )
          ? formatUnixTime( row.last_acs_update )
          : 'N/A',
        // eslint-disable-next-line camelcase
        // last_scanned: ( isNotEmpty( row.last_successful_scan ) && row.last_successful_scan > 0 )
        //   ? formatUnixTime( row.last_successful_scan )
        //   : 'N/A',
        // eslint-disable-next-line camelcase
        last_processed: isNotEmpty( row.last_scan_processed_time )
          ? formatUnixTime( row.last_scan_processed_time )
          : 'N/A',
        // eslint-disable-next-line camelcase
        'Scanning Status': <ScanningStatusIndicator hideText timestamp={ row.last_successful_scan} />,
        actions:  <div className="tableActionsWrapper">
          <button
            disabled={ !canConfigure( licenseInfo ) }
            className={ `${!canConfigure( licenseInfo ) ? 'disabled' : ''} roundGlyphButton light` }
            title={'Decomission host'}
            onClick={ () => decommissionHost( row.host_id ) }
          >
            <InlineSVG type="delete" />
          </button>
          <button
            title="View Full Host Details"
            className="roundGlyphButton light"
            onClick={ () => goToHost( row.host_id ) }
          >
            <InlineSVG type="carretRight" />
          </button>
        </div>,
      };
    } else if ( scanningMode === 'agentless' ) {
      return {
        name: isNotEmpty( row.local_name )
          // eslint-disable-next-line max-len
          ? hostNameLink( row )
          : 'N/A',
        // eslint-disable-next-line camelcase
        addresses:  isNotEmpty( row.ip_addresses )
          ? <ul>
            {
              getFormattedIPs( row.ip_addresses, row.last_scanned_address ).map( ( ip, i ) => {
                if ( i === 0 ) {
                  return  <li key={i}>
                    { ip }
                  </li>;
                }
                return  <li key={i}>
                  <span>{ ip }</span>
                  {
                    i !== getFormattedIPs( row.ip_addresses, row.last_scanned_address ).length - 1 &&
                                          <span>, </span>
                  }
                </li>
                ;
              } )
            }
          </ul>
          : 'N/A',
        // eslint-disable-next-line camelcase
        last_credential: isNotEmpty( row.last_credential )
          ? getCredentialLabel( row.last_credential )
          : 'N/A',
        // eslint-disable-next-line camelcase
        scan_groups: isNotEmpty( row.scan_groups )
          ? scanGroupLabels( row.scan_groups )
          : 'N/A',
        // eslint-disable-next-line camelcase
        // last_scanned: isNotEmpty( row.last_successful_scan )
        //   ? formatUnixTime( row.last_successful_scan )
        //   : 'N/A',
        // eslint-disable-next-line camelcase
        last_processed: isNotEmpty( row.last_scan_processed_time )
          ? formatUnixTime( row.last_scan_processed_time )
          : 'N/A',
        // eslint-disable-next-line camelcase
        'Scanning Status': <ScanningStatusIndicator hideText timestamp={ row.last_successful_scan} />,
        actions:  <div className="tableActionsWrapper">
          {
            isNotEmpty( row.scan_groups ) &&
            <button
              disabled={ !canConfigure( licenseInfo ) }
              className={ `${!canConfigure( licenseInfo ) ? 'disabled' : ''} roundGlyphButton light` }
              title={'Test Scan Configuration'}
              onClick={ () => showConnectivity( row ) }
            >
              <InlineSVG type="network" />
            </button>
          }
          <button
            disabled={ !canConfigure( licenseInfo ) }
            className={ `${!canConfigure( licenseInfo ) ? 'disabled' : ''} roundGlyphButton light` }
            title={'Decomission host'}
            onClick={ () => decommissionHost( row.host_id ) }
          >
            <InlineSVG type="delete" />
          </button>
          <button
            title="View Full Host Details"
            className="roundGlyphButton light"
            onClick={ () => goToHost( row.host_id ) }
          >
            <InlineSVG type="carretRight" />
          </button>
        </div>,
      };
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( results ) ) {
      const newData = results.map( row => {
        const rowData = transformRowData( row, scanningMode );
        return { ...rowData };
      } );
      setAdjustedData( newData );
    } else {
      setAdjustedData( [] );
    }

    if ( onPageChange ) {
      setScrollToTableTop( true );
    }
  }, [ results, scanningMode, credentials ] );

  const reportTableHeader = () => {
    const hash = decodeURLHash();

    const pageNumber = hash.current_page || 1;
    const itemsPerPage = hash.item_count || 100;
    const recordCount = results?.length || 0;

    const beginning = itemsPerPage * ( pageNumber - 1 );
    const end = beginning + recordCount;

    let title = '';

    if ( scanningMode === 'agent' ) {
      title = `Agents/User Managed Scripts (${beginning + 1} - ${end})`;
    } else {
      title = `Hosts (${beginning + 1} - ${end})`;
    }
    return title;
  };

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ) } );
    setScrollToTableTop( true );
    onRefresh();
  };

  return (
    <div className=" riskInsightTableWrapper">
      <div className={`filtersHeader ${scanningMode}`}>
        <h2>
          { reportTableHeader() }
        </h2>
        <span><span>*</span> Last Scanned Addresses</span>
        <IndeterminantPagination
          currentPageNumber={currentPageNumber}
          nextPageResults={nextResults}
          goToPage={goToPage}
          elementClass="riskInsightPagination"
        />
      </div>
      <div className="tableWrapper">
        {
          ( isEmpty( adjustedData ) && isEmpty( results ) ) &&
          <EmptyState message={ emptyStateMessage() }/>
        }
        { loading && <Loading /> }
        {
          ( isNotEmpty( results ) && isNotEmpty( adjustedData ) ) &&
          <DataTable
            sortableColumns= {scanningModeOptionsMap[scanningMode].sortableColumns}
            sortBy= {sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            data={adjustedData}
            scrollToTableTop={scrollToTableTop}
            noRefresh
            allowHover
          />
        }
      </div>
    </div>
  );
};

export default Table;
