/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  capitalize,
  decodeURLHash,
  encodeURLHash,
  formatNumber,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  pluralizeType,
  recordTypeIconKey,
  removeFromURLHash,
  // recordTypeIconKey,
  triggerHashRefresh,
} from '../../../shared/Utilities';
import InlineSVG from '../../../shared/InlineSVG';
import Field from '../../../shared/Form/Field';
import Form from '../../../shared/Form';
import { getFieldValues } from '../../../shared/Form/Shared';
import { availableColumnsByType } from './shared';

const TableHeader = ( {
  reportType,
  recordCount,
  onRefresh,
  // tallyCount,
} ) => {

  const [ headerFields, setHeaderFields ] = React.useState( [] );
  const [ tableColumnOptions, setTableColumnOptions ] = React.useState( {} );

  const [ recordType, setRecordType ] = React.useState( null );
  const [ showColumnOptions, setShowColumnOptions ] = React.useState( false );

  const [ showOrderingOptions, setShowOrderingOptions ] = React.useState( false );
  const [ existingOrder, setExistingOrder ] = React.useState( null );
  const [ orderFields, setOrderFields ] = React.useState( [] );
  const [ updatedOrderForm, setUpdatedOrderForm ] = React.useState( null );

  const [ groupBy, setGroupBy ] = React.useState( null );
  const [ riskType, setRiskType ] = React.useState( null );

  const orderOptionsMap = {
    host: {
      // eslint-disable-next-line camelcase
      filtered_risk: 'Risk',
      // eslint-disable-next-line camelcase
      local_name: 'Name',
      // eslint-disable-next-line camelcase
      num_patches: 'All Patches',
      // eslint-disable-next-line camelcase
      num_unsuperseded_patches: 'Unsuperseded Patches',
      // eslint-disable-next-line camelcase
      num_vulnerabilities: 'Vulnerabilities',
      // eslint-disable-next-line camelcase
      last_scanned: 'DeepSurface Scanning Status',
    },
    patch: {
      // eslint-disable-next-line camelcase
      filtered_risk: 'Risk',
      // eslint-disable-next-line camelcase
      identifier: 'Name',
      // eslint-disable-next-line camelcase
      num_hosts: 'Affected Hosts',
      // eslint-disable-next-line camelcase
      num_vulnerabilities: 'Vulnerabilities',
    },
    vulnerability: {
      // eslint-disable-next-line camelcase
      filtered_risk: 'Risk',
      // eslint-disable-next-line camelcase
      identifier: 'Name',
      // eslint-disable-next-line camelcase
      exploit_status: 'Exploit Status',
      // eslint-disable-next-line camelcase
      cvss_base_score: 'CVSS Score',
      // eslint-disable-next-line camelcase
      num_hosts: 'Affected Hosts',
    },
    signature: {
      // eslint-disable-next-line camelcase
      filtered_risk: 'Risk',
      // eslint-disable-next-line camelcase
      scanner: 'Name',
      // eslint-disable-next-line camelcase
      num_hosts: 'Hosts',
      // eslint-disable-next-line camelcase
      num_vulnerabilities: 'Vulns.',
    },
  };

  const ORDER_FIELDS = {
    primary: {
      fields: [
        {
          label: 'Primary Order By',
          attribute: 'primary_order_by',
          type: 'select2',
          selectOptions: {
            disableKeyboardShortcuts: true,
          },
        },
        {
          label: 'Direction',
          attribute: 'primary_order_direction',
          type: 'select2',
          selectOptions: {
            disableKeyboardShortcuts: true,
          },
          options: {
            ASC: 'Ascending',
            DESC: 'Descending',
          },
        },
      ],
    },
    secondary: {
      fields: [
        {
          label: 'Secondary Order By',
          attribute: 'secondary_order_by',
          type: 'select2',
          selectOptions: {
            disableKeyboardShortcuts: true,
          },
        },
        {
          label: 'Direction',
          attribute: 'secondary_order_direction',
          type: 'select2',
          selectOptions: {
            disableKeyboardShortcuts: true,
          },
          options: {
            ASC: 'Ascending',
            DESC: 'Descending',
          },
        },
      ],
    },
  };

  const handleGroupTypeChange = ( value ) => {
    if ( isNotEmpty( value ) && value !== groupBy ) {
      setGroupBy( value );
      const columns = defaultColumnKeysByType[`${value}_instance`];
      // eslint-disable-next-line camelcase
      encodeURLHash( { group_type: value, columns } );
      // triggerHashRefresh();
      onRefresh( false );
      triggerHashRefresh();
    }
  };

  const defaultColumnKeysByType = {
    host: [
      'has_host',
      'filtered_risk',
      'risk_rating',
      'local_name',
      'num_sensitive_nodes',
      'num_patches',
      'num_unsuperseded_patches',
      'num_vulnerabilities',
      'last_scanned',
    ],
    // eslint-disable-next-line camelcase
    host_instance: [
      'has_host',
      'filtered_risk',
      'risk_rating',
      'local_name',
      'num_sensitive_nodes',
      'product_name',
      'ip_addresses',
      'num_patches',
      'num_unsuperseded_patches',
      'num_vulnerabilities',
      'last_scanned',
    ],
    patch: [
      'filtered_risk',
      'risk_rating',
      'identifier',
      'vendor',
      'num_supersedes',
      'num_hosts',
      'num_vulnerabilities',
    ],
    // eslint-disable-next-line camelcase
    patch_instance: [
      'filtered_risk',
      'risk_rating',
      'identifier',
      'vendor',
      'num_supersedes',
      'num_hosts',
      'num_vulnerabilities',
      'description',
    ],
    vulnerability: [
      'filtered_risk',
      'risk_rating',
      'identifier',
      'exploit_status',
      'cvss_base_score',
      'num_hosts',
      'num_patches',
      'num_unsuperseded_patches',
    ],
    // eslint-disable-next-line camelcase
    vulnerability_instance: [
      'filtered_risk',
      'risk_rating',
      'identifier',
      'exploit_status',
      'cvss_base_score',
      'num_hosts',
      'num_patches',
      'num_unsuperseded_patches',
      'description',
    ],
    // eslint-disable-next-line camelcase
    signature_instance: [
      'filtered_risk',
      'risk_rating',
      'scanner',
      'signature',
      'title',
      'num_hosts',
      'num_vulnerabilities',
    ],
  };

  const handleColumnOptionChange = ( option ) => {
    const hash = decodeURLHash();

    let _columns = hash.columns || [];
    let newOrderByKey;

    if ( isNotEmpty( option ) ) {
      // these actual stand for 2 columns that have been combined in the table
      // all ( filtered_risk, risk_rating )
      if ( option.value === 'risk' ) {
        if ( recordType === 'user' || recordType === 'path' ) {
          if ( _columns.includes( 'risk' ) ) {
            _columns = _columns.filter( col => col !== 'risk' );
          } else {
            _columns.push( 'risk' );
          }
        // we were including risk, now we are not
        // if we are removing risk... but we were ordering by it, we need to change the ordering to something else
        } else if ( _columns.includes( 'filtered_risk' ) ) {
          _columns = _columns.filter( col => col !== 'filtered_risk' );
          _columns = _columns.filter( col => col !== 'risk_rating' );

          if ( isNotEmpty( hash.order_by ) && itemIsArray( hash.order_by ) ) {
            const orderBys = hash.order_by.map( order => order[0] );

            // we were ordering by risk, so we need to change the ordering to something else
            if ( orderBys.includes( 'filtered_risk' ) && isNotEmpty( orderOptionsMap[recordType] ) ) {
              let availableOrderBys = Object.keys( orderOptionsMap[recordType] );
              availableOrderBys = availableOrderBys.filter( orderBy => orderBy !== 'filtered_risk' );
              // eslint-disable-next-line camelcase
              [ newOrderByKey ] = availableOrderBys;
            }
          }
        // we were not including risk, now we are
        } else {
          _columns.push( 'filtered_risk' );
          _columns.push( 'risk_rating' );
        }
      // signature ( scanner, signature )
      } else if ( option.value === 'scanner' ) {
        if ( _columns.includes( 'scanner' ) ) {
          _columns = _columns.filter( col => col !== 'scanner' );
          _columns = _columns.filter( col => col !== 'signature' );
        } else {
          _columns.push( 'scanner' );
          _columns.push( 'signature' );
        }
      // patches ( vendor, identifier )
      } else if ( option.value === 'vendor' ) {
        if ( _columns.includes( 'vendor' ) ) {
          _columns = _columns.filter( col => col !== 'vendor' );
          _columns = _columns.filter( col => col !== 'identifier' );
        } else {
          _columns.push( 'vendor' );
          _columns.push( 'identifier' );
        }
      } else if ( _columns.includes( option.value ) ) {
        _columns = _columns.filter( col => col !== option.value );
      } else {
        _columns.push( option.value );
      }
    }
    const newHashParams = { columns: _columns };

    if ( isNotEmpty( newOrderByKey ) ) {
      // eslint-disable-next-line camelcase
      newHashParams.order_by = [ [ newOrderByKey, 'DESC' ] ];
    }
    encodeURLHash( newHashParams );
    triggerHashRefresh();
    onRefresh();
  };

  const columnIsChecked = ( key ) => {
    const hash = decodeURLHash();
    const _columns = hash.columns || [];
    if ( key === 'risk' ) {
      if ( recordType === 'user' || recordType === 'path' ) {
        return _columns.includes( 'risk' );
      }
      return _columns.includes( 'filtered_risk' ) && _columns.includes( 'risk_rating' );
    }
    if ( key === 'signature' ) {
      return _columns.includes( 'scanner' ) && _columns.includes( 'signature' );
    }
    if ( key === 'vendor' ) {
      return _columns.includes( 'vendor' ) && _columns.includes( 'identifier' );
    }
    return _columns.includes( key );
  };

  React.useEffect( () => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'instance' ) {
        setHeaderFields( [
          {
            label: 'Change Table Grouping',
            attribute: 'group_type',
            type: 'select2',
            options: {
              host: 'Host',
              patch: 'Patch',
              vulnerability: 'Vulnerability',
              signature: 'Scanner Signature',
            },
          },
        ] );
      }

      const hash = decodeURLHash();
      let _recordType = reportType;
      if ( isNotEmpty( hash.group_type ) ) {
        _recordType = hash.group_type;
        if ( _recordType === 'patch_cumulative' ) {
          _recordType = 'patch';
        }
      }
      removeFromURLHash( 'included_columns' );
      removeFromURLHash( 'order_direction' );

      if ( isNotEmpty( hash.order_by ) && !itemIsArray( hash.order_by ) ) {
        // eslint-disable-next-line camelcase
        encodeURLHash( { order_by: [ [ 'filtered_risk', 'DESC' ] ] } );
      }

      if ( isNotEmpty( hash ) && isEmpty( hash.columns ) ) {
        if ( reportType === 'instance' ) {
          encodeURLHash( { columns: defaultColumnKeysByType[`${_recordType}_instance`] } );
        } else {
          encodeURLHash( { columns: defaultColumnKeysByType[_recordType] } );
        }
      }
      setRecordType( _recordType );
      setGroupBy( _recordType );
    }
  }, [ reportType ] );

  React.useEffect( () => {
    const hash = decodeURLHash();

    setRiskType( hash.risk_type || 'direct_risk' );

    if ( isNotEmpty( recordType ) && isNotEmpty( availableColumnsByType[recordType] ) ) {
      setTableColumnOptions( availableColumnsByType[recordType] );
    }
    if ( isNotEmpty( recordType ) && isNotEmpty( orderOptionsMap[recordType] ) ) {
      const hash = decodeURLHash();
      const _fields = { ...ORDER_FIELDS };

      let orderBy;

      if ( isEmpty( hash.order_by ) || !itemIsArray( hash.order_by ) ) {
        orderBy = [ [ 'filtered_risk', 'DESC' ] ];
      } else if ( itemIsArray( hash.order_by ) ) {
        orderBy = hash.order_by;
      }

      const _existingRecord = {
        // eslint-disable-next-line camelcase
        primary_order_by: orderBy[0][0] || 'filtered_risk',
        // eslint-disable-next-line camelcase
        primary_order_direction: orderBy[0][1] || 'DESC',
      };

      if ( orderBy.length > 1 ) {
        // eslint-disable-next-line
        _existingRecord.secondary_order_by = orderBy[1][0];
        // eslint-disable-next-line
        _existingRecord.secondary_order_direction = orderBy[1][1];
      }

      const primaryOrderBy = _fields.primary.fields.find( field => field.attribute === 'primary_order_by' );
      const primaryDirection = _fields.primary.fields.find( field => field.attribute === 'primary_order_direction' );
      if ( isNotEmpty( primaryOrderBy ) ) {
        primaryOrderBy.options = orderOptionsMap[recordType];
        primaryOrderBy.defaultValue = _existingRecord.primary_order_by;
      }
      if ( isNotEmpty( primaryDirection ) ) {
        primaryDirection.defaultValue = _existingRecord.primary_order_direction;
      }

      const secondaryOrderBy = _fields.secondary.fields.find( field => field.attribute === 'secondary_order_by' );
      // eslint-disable-next-line max-len
      const secondaryDirection = _fields.secondary.fields.find( field => field.attribute === 'secondary_order_direction' );

      if ( isNotEmpty( secondaryOrderBy ) ) {
        secondaryOrderBy.options = orderOptionsMap[recordType];
        secondaryOrderBy.defaultValue = _existingRecord.secondary_order_by || null;
      }
      if ( isNotEmpty( secondaryDirection ) ) {
        secondaryDirection.defaultValue = _existingRecord.secondary_order_direction || null;
      }
      setExistingOrder( _existingRecord );
      setOrderFields( _fields );
    }
  }, [ recordType ] );

  const handleApplyOrdering = () => {
    if ( isNotEmpty( updatedOrderForm ) ) {
      const values = getFieldValues( updatedOrderForm.fieldStates, 'order_by' );
      if ( isNotEmpty( values ) ) {

        // eslint-disable-next-line camelcase
        const order_by = [ ];

        const primaryOrderBy = values.primary_order_by || 'filtered_risk';
        const primaryOrderDirection = values.primary_order_direction || 'DESC';
        // eslint-disable-next-line camelcase
        order_by.push( [ primaryOrderBy, primaryOrderDirection ] );

        if (
          isNotEmpty( values.secondary_order_by )
          && values.secondary_order_by !== values.primary_order_by
          && isNotEmpty( values.secondary_order_direction )
        ) {
          // eslint-disable-next-line camelcase
          order_by.push( [ values.secondary_order_by, values.secondary_order_direction ] );
        }
        // eslint-disable-next-line camelcase
        encodeURLHash( { order_by } );
        triggerHashRefresh();
        onRefresh();
        setShowOrderingOptions( false );
      }
    }
  };

  const handleRiskTypeToggle = ( type ) => {
    setRiskType( type );
    // eslint-disable-next-line camelcase
    encodeURLHash( ( { risk_type: type } ) );
    onRefresh();
  };

  return (
    <div className="riskInsightTableHeaderWrapper">
      <div className="left">
        <div className="recordIconWrapper">
          <InlineSVG type={ recordTypeIconKey[recordType] } />
        </div>
        <h2>
          { capitalize( pluralizeType( recordType ) ) }
          {
            ( recordType !== 'path' && recordType !== 'user' ) &&
            <strong>({ formatNumber( recordCount ) })</strong>
          }
        </h2>
      </div>
      <div className="right">
        {
          isNotEmpty( headerFields ) &&
          headerFields.map( ( field, index ) => {
            return (
              <Field
                field={ field }
                fields={ headerFields }
                key={ index }
                originalValue={ groupBy }
                standAlone
                standAloneOnChangeCallback={ handleGroupTypeChange }
              />
            );
          } )
        }
        {
          isNotEmpty( riskType ) &&
          <div className={ `${riskType} toggleWrapper` }>
            <button
              onClick={ () => handleRiskTypeToggle( 'direct_risk' ) }
              className={ `${riskType === 'direct_risk' ? 'toggled' : ''} toggleButton` }
            >
              <span>Direct Risk</span>
            </button>
            <button
              onClick={ () => handleRiskTypeToggle( 'cumulative_risk' ) }
              className={ `${riskType === 'cumulative_risk' ? 'toggled' : ''} toggleButton` }
            >
              <span>Cumulative Risk</span>
            </button>
          </div>
        }
        {
          ( isNotEmpty( orderFields ) && isNotEmpty( existingOrder ) ) &&
          <React.Fragment>
            <button
              className="roundGlyphButton light"
              title="Advanced Table Ordering"
              onClick={ () => setShowOrderingOptions( !showOrderingOptions ) }
            >
              <InlineSVG type="order" />
            </button>
            {
              showOrderingOptions &&
              <React.Fragment>
                <div className="tableOptionsShade" onClick={ () => setShowOrderingOptions( false ) }/>
                <div className="tableOptionsMenu ordering">
                  {
                    isNotEmpty( orderFields ) &&
                    <Form
                      fields={ orderFields }
                      trackUpdates={ false }
                      existingRecord={ existingOrder }
                      onChangeCallback={ setUpdatedOrderForm}
                    />
                  }
                  <button onClick={ handleApplyOrdering }>
                    Apply Ordering
                  </button>
                </div>

              </React.Fragment>
            }
          </React.Fragment>
        }
        {
          isNotEmpty( tableColumnOptions ) &&
          <React.Fragment>
            <button
              className="roundGlyphButton light"
              title="Additional Table Columns"
              onClick={ () => setShowColumnOptions( !showColumnOptions ) }
            >
              <InlineSVG type="setup" />
            </button>
            {
              showColumnOptions &&
              <React.Fragment>
                <div className="tableOptionsShade" onClick={ () => setShowColumnOptions( false ) }/>
                <div className="tableOptionsMenu">
                  <label>Table Columns:</label>
                  <ul>
                    {
                      tableColumnOptions.map( ( option, index ) => {
                        return (
                          <li
                            key={ index }
                            onClick={ () => handleColumnOptionChange( option ) }
                            className={`${ option.disabled ? 'disabled' : '' }`}
                          >
                            <label>
                              <div
                                // eslint-disable-next-line max-len
                                className={`${ option.disabled ? 'disabled' : '' } checkboxFieldWrapper ${ columnIsChecked( option.value ) ? 'checked' : ''}`}
                              ></div>
                              <span className="labelWrapper">{ capitalize( option.label ) }</span>
                            </label>
                          </li>
                        );
                      } )
                    }
                  </ul>
                </div>
              </React.Fragment>
            }
          </React.Fragment>
        }
      </div>
    </div>
  );
};

export default TableHeader;