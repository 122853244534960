/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  debounce,
  formatUnixDate,
  globalColors,
  isNotEmpty,
  itemIsArray,
} from '../../../../../shared/Utilities';
import Area from '../../../../../shared/Charts/Area';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import Legend from '../../../../../shared/Charts/Legend';

import './Overtime.scss';
import {
  categoryLabelsAndDescription,
  deprioritizedKeys,
  forReviewKeys,
  parentCategoryKeys,
  prioritizedKeys,
} from './VulnerabilityInstancesCategories';
import MultiArea from '../../../../../shared/Charts/MultiArea';
import YAxisLines from '../../../../../shared/Charts/AxisLines/YAxisLines';
import EmptyState from '../../../../../shared/EmptyState';
import XAxisLabels from '../../../../../shared/Charts/AxisLabels/XAxisLabels';
import XAxisLines from '../../../../../shared/Charts/AxisLines/XAxisLines';
import ChartHoverIndicators from '../../../../../shared/Charts/ChartHoverIndicators';

const OverTimeAnalysis = ( {
  item,
  settings,
  data,
  adjustSVGAspectRatio,
  svgAspectRatio,
  svgContainerRef,
} ) => {

  const [ widgetVersion, setWidgetVersion ] = React.useState( null );
  const [ chartData, setChartData ] = React.useState( null );
  const [ seriesLegendData, setSeriesLegendData ] = React.useState( null );

  const [ currentClickPoints, setCurrentClickPoints ] = React.useState( [] );
  const [ currentHoverPoints, setCurrentHoverPoints ] = React.useState( [] );

  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( 400 );
  const svgContainerWidth = 1_000;

  const [ yMax, setYMax ] = React.useState( null );
  const [ xAxisLabels, setXAxisLabels ] = React.useState( null );

  // figure out which version of the widget we need to display, there are three options:
  // 1. an area chart over time
  // 2. a multiArea chart over time
  React.useEffect( () => {
    if ( isNotEmpty( settings ) ) {
      if ( settings.report_type === 'vulnerability_instances' ) {
        if ( settings.category_version === 'specific_categories' ) {
          setWidgetVersion( 'multiArea' );
        } else {
          setWidgetVersion( 'area' );
        }
      } else {
        setWidgetVersion( 'area' );
      }
    }
  }, [ settings ] );

  // once we know what version we are dealing with, we need to figure out what data to look and format the data
  // according to how it needs to be displayed
  React.useEffect( () => {
    if (
      isNotEmpty( settings )
      && 'report_type' in settings
      && isNotEmpty( widgetVersion )
      && isNotEmpty( data )
    ) {

      let attribute = '';
      if ( settings.report_type === 'hosts' ) {
        attribute = 'num_hosts';
      }
      if ( settings.report_type === 'patches' ) {
        attribute = 'num_patches';
      }
      if ( settings.report_type === 'vulnerabilities' ) {
        attribute = 'num_vulnerabilities';
      }
      if ( settings.report_type === 'vulnerability_instances' ) {
        attribute = 'num_instances';
      }
      if ( settings.report_type === 'risk' ) {
        attribute = 'risk';
      }

      // used for all chart types as an intermediary for transforming data
      const strippedData = [];
      // used for area charts
      const transformed = {};
      // final form of the data for all versions
      let _chartData = {};
      const _seriesLegendData = {};

      // for full over time version (area), the data will be a flat array of points
      if ( itemIsArray( data ) ) {
        if ( isNotEmpty( settings ) && settings.report_type === 'vulnerability_instances' ) {
          if ( settings.category_version === 'specific_categories' && isNotEmpty( settings.included_categories ) ) {
            settings.included_categories.map( c => {
              _seriesLegendData[c] = {
                fill: fillForInstanceCategory( c, true ),
                stroke: fillForInstanceCategory( c, true ),
                label: categoryLabelsAndDescription[c].label,
              };
            } );

            setSeriesLegendData( _seriesLegendData );

            data.map( point => {
              const _dataPoint = {
                total: point[attribute],
                timestamp: point.created,
                original: point,
                series: {},
              };

              let _pointMax = 0;

              settings.included_categories.map( categoryKey => {

                const value = isNotEmpty( point.instance_categories )
                  ? point?.instance_categories[categoryKey]
                  : 0;

                if ( value > _pointMax ) {
                  _pointMax = value;
                }

                _dataPoint.series[categoryKey] = {
                  value,
                  fill: fillForInstanceCategory( categoryKey ),
                };
              } );

              // make the max ( in this case the 'value' ) the highest category of all the series
              _dataPoint.value = _pointMax;

              strippedData.push( _dataPoint );
            } );
          } else {
            _seriesLegendData[settings?.report_type] = {
              fill: fillForReportType( settings?.report_type, true ),
              stroke: fillForReportType( settings?.report_type, true ),
              label: labelForReportType( settings?.report_type ),
            };
            setSeriesLegendData( _seriesLegendData );
            data.map( point => {
              strippedData.push( { value: point[attribute], timestamp: point.created, original: point } );
            } );
          }
        } else {
          _seriesLegendData[settings?.report_type] = {
            fill: fillForReportType( settings?.report_type, true ),
            stroke: fillForReportType( settings?.report_type, true ),
            label: labelForReportType( settings?.report_type ),
          };
          setSeriesLegendData( _seriesLegendData );
          data.map( point => {
            strippedData.push( { value: point[attribute], timestamp: point.created, original: point } );
          } );
        }
      }

      if ( isNotEmpty( strippedData ) ) {
        const firstTimestamp = strippedData[0].timestamp;
        const lastTimeStamp = strippedData[strippedData.length - 1].timestamp;

        if ( isNotEmpty( firstTimestamp ) && isNotEmpty( lastTimeStamp ) ) {
          const xlabels = [];

          const timestampDelta = lastTimeStamp - firstTimestamp;

          xlabels.push( formatUnixDate( firstTimestamp ) );
          xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 2 / 6 ) ) ) );
          xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 3 / 6 ) ) ) );
          xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 4 / 6 ) ) ) );
          xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 5 / 6 ) ) ) );
          xlabels.push( formatUnixDate( lastTimeStamp ) );

          setXAxisLabels( xlabels );
        }

        const _max = Math.max( ...strippedData.map( p => p.value ) );

        if ( widgetVersion === 'area' ) {
          strippedData.map( ( point, index ) => {
            const _transformedPoint = {
              value: point.value,
              timestamp: point.timestamp,
              original: point.original,
              max: _max,
              originalIndex: index,
              totalPoints: strippedData.length,
              id: `${point.timestamp}_${point.value}_${attribute}`,
            };
            transformed[point.timestamp] = _transformedPoint;
          } );

          _chartData = {
            max: _max,
            original: data,
            transformed,
            xAxis: 'timestamp',
            yAxis: 'value',
          };
        }
        if ( widgetVersion === 'multiArea' ) {

          strippedData.map( ( point, index ) => {
            const _transformedPoint = {
              series: point.series,
              timestamp: point.timestamp,
              original: point.original,
              max: _max,
              pointTotal: point.value,
              originalIndex: index,
              totalPoints: strippedData.length,
              id: `${point.timestamp}_${point.value}_${attribute}`,
            };
            transformed[point.timestamp] = _transformedPoint;
          } );

          _chartData = {
            max: _max,
            original: data,
            transformed,
            xAxis: 'timestamp',
            yAxis: 'value',
          };
        }
        setYMax( _max );
        setChartData( _chartData );
      }
    }
  }, [ settings, widgetVersion, data ] );

  const labelForReportType = reportType => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'hosts' ) {
        return 'Hosts Count';
      }
      if ( reportType === 'patches' ) {
        return 'Outstanding Patches';
      }
      if ( reportType === 'vulnerabilities' ) {
        return 'Outstanding Vulnerabilities';
      }
      if ( reportType === 'vulnerability_instances' ) {
        return 'Total Instances';
      }
      if ( reportType === 'risk' ) {
        return 'Risk';
      }
      return 'Count';
    }
    return 'Count';
  };

  const fillForReportType = ( reportType, asHex=false ) => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'hosts' ) {
        if ( asHex ) {
          return globalColors['status--yellow'];
        }
        return 'status--yellow';
      }
      if ( reportType === 'patches' ) {
        if ( asHex ) {
          return globalColors['status--green'];
        }
        return 'status--green';
      }
      if ( reportType === 'vulnerabilities' ) {
        if ( asHex ) {
          return globalColors['status--blue'];
        }
        return 'status--blue';
      }
      if ( reportType === 'vulnerability_instances' ) {
        if ( asHex ) {
          return globalColors['darkBlue'];
        }
        return 'darkBlue';
      }
      if ( reportType === 'risk' ) {
        if ( asHex ) {
          return globalColors['status--red'];
        }
        return 'status--red';
      }
      if ( asHex ) {
        return globalColors['darkBlue'];
      }
      return 'darkBlue';
    }
    if ( asHex ) {
      return globalColors['darkBlue'];
    }
    return 'darkBlue';
  };

  const fillForInstanceCategory = ( category, asHex=false, asComparison=false ) => {
    if ( isNotEmpty( category ) ) {
      if ( parentCategoryKeys.includes( category ) ) {
        if ( category === 'prioritized' ) {
          if ( asHex ) {
            if ( asComparison ) {
              return globalColors['status--red--10'];
            }
            return globalColors['status--red'];
          }
          return 'status--red';
        }
        if ( category === 'deprioritized' ) {
          if ( asHex ) {
            if ( asComparison ) {
              return globalColors['status--green--10'];
            }
            return globalColors['status--green'];
          }
          return 'status--green';
        }
        if ( category === 'for_review' ) {
          if ( asHex ) {
            if ( asComparison ) {
              return globalColors['grey--divider'];
            }
            return globalColors['grey'];
          }
          return 'grey';
        }
        return 'darkBlue';
      } else if ( prioritizedKeys.includes( category ) ) {
        if ( asHex ) {
          if ( asComparison ) {
            return globalColors['status--red--10'];
          }
          return globalColors['status--red--75'];
        }
        return 'status--red--75';
      } else if ( deprioritizedKeys.includes( category ) ) {
        if ( asHex ) {
          if ( asComparison ) {
            return globalColors['status--green--10'];
          }
          return globalColors['status--green--75'];
        }
        return 'status--green--75';
      } else if ( forReviewKeys.includes( category ) ) {
        if ( asHex ) {
          if ( asComparison ) {
            return globalColors['grey--background'];
          }
          return globalColors['grey--icon'];
        }
        return 'grey--icon';
      }
      if ( asHex ) {
        if ( asComparison ) {
          return globalColors['grey--divider'];
        }
        return globalColors['darkBlue'];
      }
      return 'darkBlue';
    }
    if ( asHex ) {
      if ( asComparison ) {
        return globalColors['grey--divider'];
      }
      return globalColors['darkBlue'];
    }
    return 'darkBlue';
  };

  const onHoverCallback = ( points ) => {
    if ( isNotEmpty( points ) && itemIsArray( points ) ) {
      setCurrentHoverPoints( points );
    } else {
      setCurrentHoverPoints( [] );
    }
  };

  const onClickCallback = ( points ) => {
    if ( isNotEmpty( points ) && itemIsArray( points ) ) {
      setCurrentClickPoints( points );
    } else {
      setCurrentClickPoints( [] );
    }
  };

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {
      adjustSVGAspectRatio();
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 100 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, settings, widgetVersion, chartData, yMax ] );

  React.useEffect( () => {
    if ( isNotEmpty( svgAspectRatio ) && svgAspectRatio !== 0 ) {
      setSVGContainerHeight( svgContainerWidth / svgAspectRatio );
    } else {
      setSVGContainerHeight( 400 );
    }
  }, [ svgAspectRatio ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( chartData ) && isNotEmpty( widgetVersion ) && isNotEmpty( yMax ) )
          ? <React.Fragment>
            {
              ( widgetVersion === 'area' ) &&
              <React.Fragment>
                {
                  Object.keys( chartData.transformed ).length > 1
                    ? <div
                      id={ `historyOverTimeSVGWrapper-${item.i}` }
                      className={ `overtimeAreaWrapper ${ settings?.include_legend ? 'withLegend' : '' }`}
                      ref={svgContainerRef}
                    >
                      <YAxisLabels yMax={ yMax } />
                      <YAxisLines lineCount={ settings?.count_version === 'percent' ? 4 : 3 } />
                      {
                        isNotEmpty( xAxisLabels ) &&
                        <XAxisLines lineCount={xAxisLabels.length - 1} variant="area" />
                      }
                      {
                        ( isNotEmpty( svgContainerHeight ) && isNotEmpty( svgContainerWidth ) ) &&
                        <svg
                          viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight}` }
                          xmlns="http://www.w3.org/2000/svg"
                          className="areaPlusBarWrapper"
                          id="areaPlusBarWrapper"
                          preserveAspectRatio="none"
                        >
                          <Area
                            data={ chartData }
                            elementClass={`overChartAreaWidget_${settings?.report_type}`}
                            fill={ fillForReportType( settings?.report_type ) }
                            stroke={ fillForReportType( settings?.report_type ) }
                            withoutWrapper
                            containerHeight={svgContainerHeight}
                            containerWidth={svgContainerWidth}
                            fullHeight
                            noXAxis
                            adjustYMin
                            thick
                          />
                        </svg>
                      }
                      <ChartHoverIndicators
                        data={ chartData }
                        onClickCallback={ onClickCallback }
                        onHoverCallback={ onHoverCallback }
                        currentHoverPoints={currentHoverPoints}
                        currentClickPoints={currentClickPoints}
                        fill={ fillForReportType( settings?.report_type ) }
                        stroke={ fillForReportType( settings?.report_type ) }
                        containerHeight={ svgContainerHeight }
                        containerWidth={ svgContainerWidth }
                        heightClass="100"
                        areaHeight="100"
                        areaPosition="top"
                        withLegend={ settings.include_legend }
                        withXAxis
                        recordType={ settings?.report_type }
                        svgContainerRef={svgContainerRef}
                      />
                      {
                        ( settings.include_legend ) &&
                        <Legend legendData={ seriesLegendData } />
                      }
                    </div>
                    : <EmptyState message="Insufficient data" />
                }
              </React.Fragment>
            }
            {
              ( widgetVersion === 'multiArea' ) &&
              <React.Fragment>
                {
                  Object.keys( chartData.transformed ).length > 1
                    ? <div
                      id={ `historyOverTimeSVGWrapper-${item.i}` }
                      className={ `overtimeMultiAreaWrapper ${ settings?.include_legend ? 'withLegend' : '' }`}
                      ref={svgContainerRef}
                    >
                      <YAxisLabels yMax={ yMax } percentageTics={ settings?.count_version === 'percent' }/>
                      <YAxisLines
                        lineCount={ settings?.count_version === 'percent' ? 4 : 3 }
                        elementClass={ settings.include_legend ? '' : 'fullWidth' }
                      />
                      {
                        isNotEmpty( xAxisLabels ) &&
                        <XAxisLines lineCount={xAxisLabels.length - 1} variant="area" />
                      }
                      {
                        ( isNotEmpty( svgContainerHeight ) && isNotEmpty( svgContainerWidth ) ) &&
                        <svg
                          viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight}` }
                          xmlns="http://www.w3.org/2000/svg"
                          className="multiAreaPlusBarWrapper"
                          id="areaPlusBarWrapper"
                          preserveAspectRatio="none"
                        >
                          <MultiArea
                            data={chartData}
                            containerHeight={svgContainerHeight}
                            containerWidth={svgContainerWidth}
                            onClickCallback={ onClickCallback }
                            onHoverCallback={ onHoverCallback }
                            currentHoverPoints={currentHoverPoints}
                            currentClickPoints={currentClickPoints}
                            version={ settings?.count_version === 'percent' ? 'stacked' : 'overlapping' }
                          />
                        </svg>
                      }
                      <ChartHoverIndicators
                        data={ chartData }
                        onClickCallback={ onClickCallback }
                        onHoverCallback={ onHoverCallback }
                        currentHoverPoints={currentHoverPoints}
                        currentClickPoints={currentClickPoints}
                        fill={ fillForReportType( settings?.report_type ) }
                        stroke={ fillForReportType( settings?.report_type ) }
                        containerHeight={ svgContainerHeight }
                        containerWidth={ svgContainerWidth }
                        heightClass="100"
                        areaHeight="100"
                        areaPosition="top"
                        withLegend={ settings.include_legend }
                        withXAxis
                        multiArea
                        recordType={ settings?.report_type }
                        svgContainerRef={svgContainerRef}
                      />
                      {
                        ( isNotEmpty( seriesLegendData ) && settings.include_legend ) &&
                        <Legend legendData={ seriesLegendData } />
                      }
                    </div>
                    : <EmptyState message="Insufficient data" />
                }
              </React.Fragment>
            }
          </React.Fragment>
          : <EmptyState message="No data available" />
      }
      {
        ( isNotEmpty( xAxisLabels ) && Object.keys( chartData?.transformed ).length > 1 ) &&
        <XAxisLabels labels={xAxisLabels} variant="area" elementClass={ settings.include_legend ? '' : 'fullWidth' } />
      }
    </React.Fragment>
  );
};

export default OverTimeAnalysis;