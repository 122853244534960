/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  capitalize,
  isNotEmpty,
  pluralizeItem,
  recordTypeDisplayName,
  removeFromURLHash,
} from '../../../shared/Utilities';
import InlineSVG from '../../../shared/InlineSVG';
import RatingBadge from '../../../shared/RatingBadge';
import TagList from '../../RiskInsight/Tags/List';
import { TagsContext } from '../../../Contexts/Tags';
import PageCreateButton from '../../../shared/PageCreateButton';
import Statistics from '../Statistics';
import { recordRiskRating } from '../shared';

const Header = ( {
  record,
  recordType,
  riskType,
  isCollapsed,
  renderContext,
  options,
  selectRecord,
  toggleCollapse,
  recordInstanceData,
} ) => {

  const [ riskClass, setRiskClass ] = React.useState( 'default' );
  const [ tagMemberships, setTagMemberships ] = React.useState( null );

  const [ tags ] = React.useContext( TagsContext );

  const recordHasMoreInfo = [
    'host',
    'patch',
    'vulnerability',
    'user',
    'path',
  ];

  const recordHasExplore = [
    'host',
    'patch',
    'vulnerability',
    'path',
    'node',
    'edge',
    'scope',
  ];

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( tags ) && recordType === 'host' ) {
      const _tags = {};
      if ( isNotEmpty( record.asset_tags ) ) {
        record.asset_tags.map( id => {
          _tags[id] = tags[id];
        } );

        setTagMemberships( _tags );
      }
    }
  }, [ tags, record, recordType ] );

  const getExploreLink = ( record, recordType ) => {
    // eslint-disable-next-line max-len
    return `#.=explore&page=explore_model&type=scope&explore_${ recordType === 'host' ? 'scope' : recordType }=%5B"${record.id}"%5D`;
  };

  const handleExploreClick = () => {
    if ( isNotEmpty( record ) && isNotEmpty( recordType ) ) {
      window.open( getExploreLink( record, recordType ) );
    }
  };

  const handleBackButtonClick = () => {
    removeFromURLHash( 'panel_tab' );
    selectRecord( null );
  };

  const recordTypeIconKey = {
    host: 'hosts_record',
    patch: 'patches_record',
    vulnerability: 'vulnerabilities_record',
    user: 'users_record',
    path: 'paths_record',
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) ) {
      setRiskClass( recordRiskRating( record, recordType ) );
    }
  }, [ record, recordType ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( recordType ) &&
        <PageCreateButton placement="left" elementClass={ riskClass } >
          <button
            className={ `riskInsightBackButton ${window.IS_DARK_MODE ? 'darkMode' : '' }` }
            onClick={ handleBackButtonClick }
          >
            <InlineSVG type="carretLeft" />
            <span>All { capitalize( pluralizeItem( recordType ) ) }</span>
          </button>
        </PageCreateButton>
      }
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( riskType ) && isCollapsed() === true ) &&
        <div
          className={ `recordDetails__InformationPanel__Header ${recordType} collapsed` }
        >
          <div className={ `recordDetails__InformationPanel__Header__dividerBar ${riskClass}` } />
          <div className="headerRow">
            <div className="left">
              {
                isNotEmpty( recordTypeIconKey[recordType] ) &&
                <div className={ `recordIconWrapper ${riskClass}` }>
                  <InlineSVG type={ recordTypeIconKey[recordType] } />
                </div>
              }
              <h2>{ recordTypeDisplayName( record, recordType )}</h2>
              {
                ( recordType === 'host' && isNotEmpty( tagMemberships ) ) &&
                <TagList tags={tagMemberships} truncation={ 2 } />
              }
            </div>
            <div className="right">
              {
                ( isNotEmpty( options ) && options.include_statistics ) &&
                <Statistics
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              <RatingBadge item={ record } itemType={ recordType } rating={ riskClass } />
              {
                ( renderContext !== 'explore' && recordHasExplore.includes( recordType ) ) &&
                <button className="roundGlyphButton light" onClick={ handleExploreClick }>
                  <InlineSVG type="explore_nav" />
                </button>
              }
              {/* collapse/expand button */}
              {
                ( isNotEmpty( options ) && options.isCollapsible ) &&
                <button className="roundGlyphButton light" onClick={ toggleCollapse }>
                  <InlineSVG type="collapse" />
                </button>
              }
            </div>
          </div>
        </div>
      }
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( riskType ) && isCollapsed() === false ) &&
        <div
          className={ `recordDetails__InformationPanel__Header ${recordType}` }
        >
          <div className="headerRow">
            <div className="left">
              {
                ( isNotEmpty( options ) && options.isDraggable ) &&
                <button className="roundGlyphButton minimal">
                  <InlineSVG type="draggable"/>
                </button>
              }
              {
                isNotEmpty( recordTypeIconKey[recordType] ) &&
                <div className={ `recordIconWrapper ${riskClass}` }>
                  <InlineSVG type={ recordTypeIconKey[recordType] } />
                </div>
              }
              <h2>{ recordTypeDisplayName( record, recordType )}</h2>
            </div>
            <div className="right">
              {
                recordType !== 'path' &&
                <RatingBadge item={ record } itemType={ recordType } rating={ riskClass } />
              }
              {
                ( isNotEmpty( options ) && options.isDismissable ) &&
                <button className="roundGlyphButton light">
                  <InlineSVG type="close"/>
                </button>
              }
            </div>
          </div>
          <div className={ `recordDetails__InformationPanel__Header__dividerBar ${riskClass}` } />
          <div className="headerRow">
            <div className="left">
              {
                recordType === 'path' &&
                <RatingBadge item={ record } itemType={ recordType } rating={ riskClass } />
              }
              {
                ( recordType === 'host' && isNotEmpty( tagMemberships ) ) &&
                <TagList tags={tagMemberships} truncation={ 2 } />
              }
            </div>
            <div className="right">
              {/* view in explore button */}
              {
                ( renderContext !== 'explore' && recordHasExplore.includes( recordType ) ) &&
                <button className="roundGlyphButton light" onClick={ handleExploreClick }>
                  <InlineSVG type="explore_nav" />
                </button>
              }
              {/* collapse/expand button */}
              {
                ( isNotEmpty( options ) && options.isCollapsible ) &&
                <button className="roundGlyphButton light" onClick={ toggleCollapse }>
                  <InlineSVG type="collapse" />
                </button>
              }
              {/* view more button */}
              {
                ( renderContext !== 'risk_insight' && recordHasMoreInfo.includes( recordType ) ) &&
                <button className="roundGlyphButton light">
                  <InlineSVG type="newTabLink" />
                </button>
              }
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Header;