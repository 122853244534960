/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import './Password.scss';

import {
  generateSecureRandomCode, isNotEmpty,
} from '../../../Utilities';

import InlineSVG from '../../../InlineSVG';

const Password = ( {
  field,
  formState,
  originalValue,
  onChange,
  setIsFocused,
  existingRecord,
  fieldRef,
} ) => {

  const [ passwordVisible, setPasswordVisible ] = React.useState( false );

  const [ editMode, setEditMode ] = React.useState( false );
  const [ overrideDisabled, setOverrideDisabled ] = React.useState( false );

  // set the value on init, place in edit mode if we are editing an existing record, this is important so that
  // users don't think that they need to re-enter a password
  React.useEffect( () => {

    if ( isNotEmpty( existingRecord ) && isNotEmpty( existingRecord.id ) ) {
      setEditMode( true );
    }

    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  // when the form changes, need to sync the value
  //  (this is important on forms where the underlying tool changes the fields)
  React.useEffect( ( ) => {
    if (
      isNotEmpty( formState )
      && isNotEmpty( formState.fieldStates )
      && isNotEmpty( formState.fieldStates[field.attribute] )
      && isNotEmpty( field )
    ) {
      fieldRef.current.value = formState?.fieldStates[field.attribute].updatedValue;
    }
  }, [ formState, field ] );

  const generateAndFillPassword = field => {
    const newPassword = generateSecureRandomCode( 16 );
    fieldRef.current.value = newPassword;
    setPasswordVisible( true );
    onChange( field, newPassword );
  };

  const handlePasswordVisibleClick = () => {
    setPasswordVisible( !passwordVisible );
    fieldRef.current.focus();
  };

  const getPlaceholder = () => {
    if ( overrideDisabled ) {
      return '';
    }
    if ( editMode ) {
      return 'Unchanged unless edited';
    }
    if ( isNotEmpty( field.placeholder ) ) {
      return field.placeholder;
    }
    return '';
  };

  const isDisabled = () => {
    if ( overrideDisabled ) {
      return false;
    }
    if ( editMode ) {
      return true;
    }
    if ( field.disabled ) {
      return true;
    }
    return formState?.fieldStates[field.attribute].disabled;
  };

  const handlePasswordEditButton = () => {
    setOverrideDisabled( true );
  };

  return (
    <React.Fragment>
      <div
        className={`passwordFieldWrapper ${editMode ? 'editMode' : ''} ${field.removeGenerate ? '' : 'needsGenerate'}`}
      >
        <input
          onFocus={ () => setIsFocused( true ) }
          onBlur={ () => setIsFocused( false ) }
          placeholder={ getPlaceholder() }
          disabled={ isDisabled() }
          type={ passwordVisible ? 'text' : 'password' }
          onChange={ e => onChange( field, e.target.value ) }
          ref={fieldRef}
          { ...field.htmlProps }
        />
        <button
          disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
          className={`setPasswordVisibleButton ${passwordVisible ? 'visible' : ''}`}
          onClick={handlePasswordVisibleClick}
        >
          <InlineSVG type="visible" elementClass="visible"/>
          <InlineSVG type="notVisible" elementClass="notVisible" />
        </button>
        {
          // a bit awkward... but the default should be to not have to pass in any option to turn this on,
          // should be on by default.
          !field.removeGenerate &&
          <button
            disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
            className="generatePasswordButton"
            onClick={ () => generateAndFillPassword( field )}
          >
            Generate password
          </button>
        }
        {
          editMode &&
          <button
            className="passwordEditButton"
            disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
            onClick={ handlePasswordEditButton }
          >
            Edit
          </button>
        }
      </div>
    </React.Fragment>
  );
};

export default Password;
