/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './Paths.scss';
import PathsGraph from '../../../../RecordDetails/Sections/PathsGraph';
import { isNotEmpty } from '../../../../../shared/Utilities';
import EmptyLoading from '../../../../../shared/EmptyLoading';

const Paths = ( { data } ) => {
  const loading = false;
  return (
    <React.Fragment>
      <EmptyLoading
        loading={ loading }
        loadingMessage="Loading paths..."
        payload={ data }
        emptyMessage="Unable to display paths"
      />
      {
        isNotEmpty( data ) &&
        <div className="pathsWidgetWrapper">
          <PathsGraph
            relatedPaths={ data }
            reportType="dashboard"
          />
        </div>
      }
    </React.Fragment>
  );
};

export default Paths;