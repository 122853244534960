/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { RemediationContext } from '../../../Contexts/Remediation';

import {
  decodeURLHash,
  isNotEmpty,
  pluralizeType,
  reportTypeDisplayName,
  riskToRating,
  uniqueArray,
} from '../../../shared/Utilities';

import InlineSVG from '../../../shared/InlineSVG';
import EmptyState from '../../../shared/EmptyState';
import Dropdown from '../../../shared/Dropdown';
import Accordion from '../../../shared/Accordion';
import Loading from '../../../shared/Loading';

import { nameForPlan } from '../RemediationPlans/Shared';

import { getFieldValues } from '../../../shared/Form/Shared';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';
import Form from '../../../shared/Form';
import { getRecords } from '../../../shared/RecordCache';
import { RiskContext } from '../../../Contexts/Risk';
import RatingBadge from '../../../shared/RatingBadge';
import RiskReduction from '../../../shared/RiskReduction';

const defaultCollapsedState = { host: true, patch: true, vulnerability: true };

const RemediationWorkflowMenu = ( {
  items,
  itemsKey,
  callback=() => {},
  setTotalCount,
  show,
  setShow,
  collapsed,
  setCollapsed,
  menuRef,
  menuPosition,
  resetMenu,
} ) => {
  const [ currentUser, , ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ formattedDraftMenuItems, setFormattedDraftMenuItems ] = React.useState( null );

  const [ selectedItems, setSelectedItems ] = React.useState( null );
  const [ createMode, setCreateMode ] = React.useState( false );
  const [ showAfterSave, setShowAfterSave ] = React.useState( false );
  const [ fields, setFields ] = React.useState( null );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ , targetRisk ] = React.useContext( RiskContext );

  const [
    workingDraftPlan,
    setWorkingDraftPlan,
    allRemediationDrafts,
    ,
    refreshAllDrafts,
  ] = React.useContext( RemediationContext );

  // called whenever the workingDraft changes so that we can properly display the labels and risk for items
  const getItems = async ( plan ) => {
    let host = [];
    let patch = [];
    let vulnerability = [];

    const hash = decodeURLHash();

    // eslint-disable-next-line camelcase
    const risk_type = hash.risk_type || 'direct_risk';

    if ( isNotEmpty( plan.host_ids ) ) {
      const params = {
        rows: [ 0, plan.host_ids.length ],
        filters: {
          // eslint-disable-next-line camelcase
          host_has_sensitive_nodes: 'any',
          // eslint-disable-next-line camelcase
          has_host: true,
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          // eslint-disable-next-line camelcase
          host_ids: plan.host_ids,
        },
        columns: [ 'id', 'local_name', 'filtered_risk' ],
        // eslint-disable-next-line camelcase
        risk_type,
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'local_name', 'ASC' ],
        ],
      };

      host = await getRecords( 'host', params, true, true );
      host = uniqueArray( host );
      host = host.sort( ( a, b ) => b.filtered_risk - a.filtered_risk );
    }
    if ( isNotEmpty( plan.patch_ids ) ) {

      const params = {
        rows: [ 0, plan.patch_ids.length ],
        filters: {
          superseded: 'unsuperseded',
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          patch_ids: plan.patch_ids,
        },
        columns: [ 'id', 'vendor', 'identifier', 'filtered_risk' ],
        // eslint-disable-next-line camelcase
        risk_type,
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ],
      };

      patch = await getRecords( 'patch', params, true, true );
      patch = uniqueArray( patch );
      patch = patch.sort( ( a, b ) => b.filtered_risk - a.filtered_risk );
    }
    if ( isNotEmpty( plan.vulnerability_ids ) ) {
      const params = {
        rownums: [ 0, plan.vulnerability_ids.length ],
        filters: {
          patchable: null,
          // eslint-disable-next-line camelcase
          accepted_risk: false,
          // eslint-disable-next-line camelcase
          exploit_status: null,
          // eslint-disable-next-line camelcase
          vulnerability_ids: plan.vulnerability_ids,
        },
        columns: [ 'id', 'identifier', 'filtered_risk' ],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'identifier', 'ASC' ],
        ],
      };

      vulnerability = await getRecords( 'vulnerability', params, true, true );
      vulnerability = uniqueArray( vulnerability );
      vulnerability = vulnerability.sort( ( a, b ) => b.filtered_risk - a.filtered_risk );
    }
    setTotalCount( host.length + patch.length + vulnerability.length );
    return { host, patch, vulnerability };
  };

  const isCollapsed = type => {
    return collapsed[type];
  };

  const toggleCollapseSection = type => {
    setCollapsed( { ...defaultCollapsedState, [type]: !collapsed[type] } );
  };

  // called after saving, if the user chooses to now open this plan up in the remediation interface
  const goToRemediation = () => {
    setShowAfterSave( false );
    setCreateMode( false );
    setShow( false );
    resetMenu();
    const currentHash = decodeURLHash();

    // eslint-disable-next-line max-len
    window.location.href = `#.=remediation&page=remediation_plans&create_from_saved=true&selected_record=${workingDraftPlan.id}`;

    if ( currentHash['.'] === 'remediation' ) {
      window.location.reload();
    }
  };

  // from the dropdown creating a new plan, or from the start if none yet exist
  const createNewDraft = () => {
    setCreateMode( true );
    setFields( [
      {
        type: 'text',
        attribute: 'label',
        label: 'Plan name (optional)',
        defaultValue: '',
      },
    ] );
  };

  // called when choosing to not make a new plan
  const cancel = () => {
    setCreateMode( false );
    setShowAfterSave( false );
    setFields( null );
    resetMenu();
  };

  // called when clicking on the shade (or the x button)
  const cancelAndClose = () => {
    cancel();
    setShow( false );
    resetMenu();
  };

  // when creating a new plan with the selected items or creating a new draft from the change menu
  const onSave = async ( e, isNew=true ) => {

    e.stopPropagation();

    setLoading( true );

    const planParams = {
      status: 'draft',
    };

    if ( isNew === true ) {
      const values = getFieldValues( updatedForm?.fieldStates, 'remediation_plan' );
      planParams.label =  values.label;
      planParams.owner = currentUser.id;
      if ( isNotEmpty( items ) ) {
        planParams[`${itemsKey}_ids`] = Object.keys( items );
      }
    } else if ( isNotEmpty( itemsKey ) ) {
      planParams.id = workingDraftPlan.id;
      if ( isNotEmpty( items ) ) {
        planParams[`${itemsKey}_ids`] = [ ...workingDraftPlan[`${itemsKey}_ids` ], ...Object.keys( items ) ];
      }
    }

    // eslint-disable-next-line max-len, camelcase
    const _planRequest = await makeRequest( 'UPSERT', '/project/default/model/base/remediation_plan', {
      records: [ planParams ],
    } );

    if ( isNotEmpty( _planRequest ) && isNotEmpty( _planRequest.results ) ) {
      setLoading( false );
      setFields( null );
      setCreateMode( false );
      setWorkingDraftPlan( _planRequest.results[0] );
      refreshAllDrafts();
      setShowAfterSave( true );
    } else {
      setLoading( false );
      addFlashMessage( {
        type: 'alert',
        body: 'There was an error creating the remediation plan.',
      } );
    }
  };

  // basically just a cancel and close,
  // calls this when giving the choice after saving and the user choose "keep looking"
  const keepLooking = () => {
    callback();
    setShow( false );
    setShowAfterSave( false );
    setCreateMode( false );
    resetMenu();
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( workingDraftPlan ) ) {
      getItems( workingDraftPlan ).then( items => {
        setSelectedItems( items );
      } );
    } else {
      setTotalCount( 0 );
    }
  }, [ workingDraftPlan ] );

  React.useEffect( ( ) => {
    if ( isNotEmpty( allRemediationDrafts ) ) {
      const items = allRemediationDrafts.map( plan => <div
        className="existingDraftPlan"
        onClick={ () => setWorkingDraftPlan( plan ) }
      >
        <span className={ `${ plan.id === workingDraftPlan?.id ? 'current' : ''}` }>
          {/* eslint-disable-next-line max-len */}
          { `${nameForPlan( plan )} (${ uniqueArray( plan.host_ids ).length + uniqueArray( plan.patch_ids ).length + uniqueArray( plan.vulnerability_ids ).length} items)` }
        </span>
      </div> );
      items.push(
        <button
          className="menuCreateNewButton"
          onClick={ createNewDraft }
        >
          Start a New Plan
        </button>,
      );

      setFormattedDraftMenuItems( items );
    } else {
      setTotalCount( 0 );
    }
  }, [ allRemediationDrafts, workingDraftPlan ] );

  return (
    <React.Fragment>
      { show && <div className="remediationWorkflowShade addToPlanShade" onClick={ cancelAndClose } /> }
      <div
        ref={ menuRef }
        className={ `remediationMenu ${show ? 'visible' : ''} addToPlanMenu`}
        style={ menuPosition }
      >
        { loading && <Loading text="" /> }
        {
          showAfterSave
            ? <div
              className="afterSaveWrapper"
            >
              {
                isNotEmpty( items )
                  ? <strong>Items Saved to Remediation Plan</strong>
                  : <strong>Remediation Plan Created</strong>
              }
              <div
                className="successImageWrapper"
              >
                <InlineSVG type="remediationList" />
                <div className="successIconWrapper">
                  <InlineSVG type="checkmark" version="light" />
                </div>
              </div>
              <div className="actions">
                <button
                  className="cancelButton"
                  onClick={ keepLooking }
                >
                  {
                    isNotEmpty( items )
                      ? <span>Add More Items</span>
                      : <span>Find Items</span>
                  }
                </button>
                <button
                  className="goToPlanButton"
                  onClick={ goToRemediation }
                >
                  Go to Plan
                </button>
              </div>
            </div>
            : <React.Fragment>
              {
                createMode
                  ? <div className="createModeWrapper">
                    {
                      isNotEmpty( fields ) &&
                      <React.Fragment>
                        <h2>New Remediation Plan</h2>
                        <Form
                          fields={fields}
                          recordType="remediation_plan"
                          trackUpdates={ false }
                          onChangeCallback={ setUpdatedForm }
                        />
                        <div className="formActionsWrapper">
                          <button
                            className="secondary"
                            onClick={ cancel }
                          >
                            Cancel
                          </button>
                          <button
                            className="primary"
                            onClick={ e => onSave( e, true ) }
                          >
                            Save
                          </button>
                        </div>
                      </React.Fragment>
                    }
                  </div>
                  : <React.Fragment>
                    {
                      isNotEmpty( workingDraftPlan )
                        ? <React.Fragment>
                          <div className="remediationWorkflowHeader">
                            <div className="titleWrapper">
                              <InlineSVG type="remediationCart" />
                              <h2>
                                Working Remediation Plan
                              </h2>
                            </div>
                            <button
                              className="closeButton"
                              onClick={ cancelAndClose }
                            >
                              <InlineSVG type="close" />
                            </button>
                          </div>
                          {/* <StatusBadge text="draft" /> */}
                          <div className="planNameWrapper" >
                            <strong>Current: </strong>
                            {
                              isNotEmpty( formattedDraftMenuItems ) &&
                              <Dropdown
                                trigger={
                                  <React.Fragment>
                                    <span>
                                      { nameForPlan( workingDraftPlan ) }
                                    </span>
                                    <InlineSVG type="carretDown" version="primary" />
                                  </React.Fragment>
                                }
                                menuItems={ formattedDraftMenuItems }
                                elementClass="workingDraftPicker"
                                menuElementClass="workingDraftPicker"
                              />
                            }
                          </div>
                          {/* Items selected in this draft */}
                          {
                            isNotEmpty( selectedItems )
                              ?<React.Fragment>
                                <strong className="itemsCount" >Items: </strong>
                                <Accordion>
                                  {
                                    Object.entries( selectedItems ).map( ( [ type, items ], index ) => {
                                      return  <div
                                        key={index}
                                        // eslint-disable-next-line max-len
                                        className={`${isCollapsed( type ) ? 'collapsed' : ''} accordionWrapper alternate`}
                                      >
                                        <div
                                          className="accordionHeader"
                                          onClick={ () => toggleCollapseSection( type ) }
                                        >
                                          <h3>
                                            <InlineSVG type={ `${pluralizeType( type )}Alt` } />
                                            { `${pluralizeType( type, true )} (${items.length})`}
                                          </h3>
                                          <button
                                            onClick={ () => toggleCollapseSection( type ) }
                                          >
                                            <InlineSVG type="carretUp" />
                                          </button>
                                        </div>
                                        <div className="accordionBody">
                                          <ul>
                                            {
                                              isNotEmpty( items )
                                                // eslint-disable-next-line max-len
                                                ? items.map( ( item, _index ) => {
                                                  return <li
                                                    key={_index}
                                                    className="selectedRemediationItem"
                                                  >
                                                    <span>
                                                      <RatingBadge
                                                        altVersion
                                                        // eslint-disable-next-line
                                                        rating={ riskToRating( item[type === 'patch' ? 'risk' : 'filtered_risk'], targetRisk ) }
                                                      />
                                                      <strong>{ reportTypeDisplayName( item, type )}</strong>
                                                    </span>
                                                    <RiskReduction
                                                      item={item}
                                                      riskType={ type === 'patch' ? 'risk' : 'filtered_risk' }
                                                    />
                                                  </li>;
                                                } )
                                                : <EmptyState message={`No ${pluralizeType( type )} Added`} />
                                            }
                                          </ul>
                                        </div>
                                      </div>;
                                    } )
                                  }
                                </Accordion>
                              </React.Fragment>
                              : <EmptyState message="No Items Saved" />
                          }
                        </React.Fragment>
                        : <EmptyState message="No Working Remediation Plans" />
                    }
                    {
                      isNotEmpty( workingDraftPlan )
                        ? <React.Fragment>
                          {
                            isNotEmpty( items )
                              ? <button
                                className="goToPlanButton"
                                onClick={ e => onSave( e, false ) }
                              >
                                Add to Plan
                              </button>
                              : <button
                                className="goToPlanButton"
                                onClick={ goToRemediation }
                              >
                                Go to Plan
                              </button>
                          }
                        </React.Fragment>
                        : <button
                          className="goToPlanButton"
                          onClick={ createNewDraft }
                        >
                        Start a New Plan
                        </button>
                    }
                  </React.Fragment>
              }
            </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
};

export default RemediationWorkflowMenu;