/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { decodeURLHash, formatUnixTime, isNotEmpty, itemIsError, serializeURLHash } from '../../shared/Utilities';
import { makeRequest } from '../../../legacy/io';
import { getFieldValues } from '../../shared/Form/Shared';
import Form from '../../shared/Form';
import EulaModal from './EulaModal';

import './Login.scss';
import FlashMessageQueue from '../../shared/FlashMessageQueue';
import Notification from '../../shared/Notification';

const FIELDS = [
  {
    type: 'text',
    attribute: 'username',
    label: 'Username',
    required: true,
    defaultValue: '',
  },
  {
    type: 'password',
    attribute: 'password',
    label: 'Password',
    removeGenerate: true,
    required: true,
    defaultValue: '',
  },
];

const Login = ( { sid, setSid, expiration, setExpiration } ) => {

  const [ fields, setFields ] = React.useState( [] );
  const [ updatedLogin, setUpdatedLogin ] = React.useState( null );
  const [ valid, setValid ] = React.useState( false );
  const [ loginStep, setLoginStep ] = React.useState( 1 );
  const [ eula, setEULA ] = React.useState( {} );
  const [ showEULA, setShowEULA ] = React.useState( false );
  const [ notificationOptions, setNotificationOptions ] = React.useState( {} );

  const sha256 = async ( data ) => {
    // eslint-disable-next-line max-len
    return Array.from( new Uint8Array( await crypto.subtle.digest( 'SHA-256', new TextEncoder().encode( data ) ) ) ).map( b => b.toString( 16 ).padStart( 2, '0' ) ).join( '' );
  };

  React.useEffect( () => {
    // check to see if there are any errors in the url params (these could be from a redirect on the BE)
    // that encountered a 503 for a SAML POST
    const params = decodeURLHash( document.location.href, '?', true );

    if ( isNotEmpty( params ) && isNotEmpty( params.error ) ) {
      setNotificationOptions( {
        message: params.error,
        type: 'alert',
      } );
    }
    // get the server time, to make sure it is in sync with the client
    makeRequest( 'FETCH', '/status' ).then( status => {
      if ( isNotEmpty( status ) && isNotEmpty( status.results ) && isNotEmpty( status.results.time ) ) {
        const clientNow = ( new Date() ).getTime() / 1000;
        const serverNow = status.results.time;

        formatUnixTime( serverNow );
        formatUnixTime( clientNow );
        // if the client and server are more than a minute off, show the alert
        if ( Math.abs( clientNow - serverNow ) >= 60 ) {
          setNotificationOptions( {
            message: <React.Fragment>
              <p>The { window.COMPANY_NAME } client and your server times are out of sync.</p>
              <p>
                {/* eslint-disable-next-line max-len */}
                { `The ${ window.COMPANY_NAME } Client is reporting the time of ${formatUnixTime( clientNow )} and your server is reporting a time of ${formatUnixTime( serverNow )}` }
              </p>
              <p>This will need to be fixed in order for the { window.COMPANY_NAME } application to work properly.</p>
            </React.Fragment>,
            type: 'success',
          } );
        }
      }
    } );

    makeRequest( 'GET', '/eula.txt' ).then( eResponse => {
      sha256( eResponse ).then( eHash => {
        // eslint-disable-next-line camelcase
        setEULA( { eula: eResponse, eula_hash: eHash } );
      } );
    } );
  }, [] );

  React.useEffect( () => {

    let _fields = [ FIELDS[0] ];

    if ( loginStep === 1 ) {
      setTimeout( () => {
        const nameWrapper = document.getElementById( 'text_username_input' );
        nameWrapper.focus();
      }, 100 );
    }
    // need to carry over the valid username from previous step and add the password field
    if ( loginStep === 2 ) {

      const _values = getFieldValues( updatedLogin?.fieldStates, 'login' );


      _fields = [ ..._fields, FIELDS[1] ];
      const uInput = _fields.find( f => f.attribute === 'username' );
      uInput.disabled = true;
      uInput.defaultValue = _values.username;
      setTimeout( () => {
        const nameWrapper = document.getElementById( 'password_password_input' );
        nameWrapper.focus();
      }, 100 );
    }
    setFields( _fields );
  }, [ loginStep ] );

  const startOver = () => {
    setLoginStep( 1 );
    window.history.go();
  };

  const successfulLoginRedirect = () => {
    const hash  = decodeURLHash();

    setShowEULA( false );

    if ( isNotEmpty( hash ) && isNotEmpty( hash.return_url ) ) {
      const _hash = `#${serializeURLHash( hash.return_url )}`;
      window.location = `/${_hash}`;
    }
    window.location = '/';
  };

  const handleLogin = async () => {
    const loginValues = getFieldValues( updatedLogin?.fieldStates, 'login' );
    // do a preauth check to see if a redirect is needed
    if ( loginStep === 1 ) {
      let hash = '';

      const returnHash = decodeURLHash();

      if ( isNotEmpty( returnHash ) ) {
        hash = `#${serializeURLHash( returnHash )}`;
      }

      const returnURL = window.location.origin
        ? `${window.location.origin}/${window.location.hash}`
        : `${window.origin}/${hash}`;

      const loginResponse = await makeRequest( 'PREAUTH', '/session', {
        username: loginValues.username,
        returnURL,
      } );

      if ( itemIsError( loginResponse ) && 'cause' in loginResponse ) {
        setNotificationOptions( {
          message: <React.Fragment>
            {
              loginResponse.errors.map( ( error, i ) => (
                <p key={ i }>{ error }</p>
              ) )
            }
          </React.Fragment>,
          type: 'alert',
        } );
      } else if ( isNotEmpty( loginResponse ) ) {
        if ( isNotEmpty( loginResponse.results ) ) {
          if ( isNotEmpty( loginResponse.results.redirect_url ) ) {
            // do redirect to saml
            window.location = loginResponse.results.redirect_url;
          } else {
            setLoginStep( 2 );
          }
        } else if ( isNotEmpty( loginResponse.errors ) ) {
          setNotificationOptions( {
            message: <React.Fragment>
              {
                loginResponse.errors.map( ( error, i ) => (
                  <p key={ i }>{ error }</p>
                ) )
              }
            </React.Fragment>,
            type: 'alert',
          } );
        } else {
          setNotificationOptions( {
            message: 'An error occurred, please try again',
            type: 'alert',
          } );
        }
      } else {
        setNotificationOptions( {
          message: 'An error occurred, please try again',
          type: 'alert',
        } );
      }
    // preauth is already done, need to login for real
    } else {
      const authenticationResponse =  await makeRequest( 'AUTHENTICATE', '/session', {
        username: loginValues.username,
        password: loginValues.password,
      } );

      if ( itemIsError( authenticationResponse ) && 'cause' in authenticationResponse ) {
        setNotificationOptions( {
          message: authenticationResponse.cause,
          type: 'alert',
        } );
      } else if ( itemIsError( authenticationResponse ) && isNotEmpty( authenticationResponse.message ) ) {
        const parsedMessage = JSON.parse( authenticationResponse.message );

        if ( isNotEmpty( parsedMessage.errors ) ) {
          setNotificationOptions( {
            message: <React.Fragment>
              {
                parsedMessage.errors.map( ( error, i ) => (
                  <p key={ i }>{ error }</p>
                ) )
              }
            </React.Fragment>,
            type: 'alert',
          } );
        }

      } else if ( isNotEmpty( authenticationResponse ) ) {

        if ( isNotEmpty( authenticationResponse.sid ) ) {
          setSid( authenticationResponse.sid );
          setExpiration( authenticationResponse.expiration );

          const clientNow = ( new Date() ).getTime() / 1000;
          const serverExpiration = authenticationResponse.expiration;

          // edge case where the server is setting an expiration that is before the current
          // time and login is not possible
          if ( serverExpiration < clientNow ) {
            setNotificationOptions( {
              message: <React.Fragment>
                <p>The { window.COMPANY_NAME } client and your server times are out of sync.</p>
                <p>
                  {/* eslint-disable-next-line max-len */}
                  { `The ${ window.COMPANY_NAME } Client is reporting the time of ${formatUnixTime( clientNow )} and your server is reporting a time of ${formatUnixTime( serverExpiration )}` }
                </p>
                <p>This will need to be fixed in order for the { window.COMPANY_NAME } application to work properly.</p>
              </React.Fragment>,
              type: 'success',
            } );
          } else {
            // update global settings now that we're auth'd
            const { user } = authenticationResponse;

            if ( eula.eula_hash !== user.eula_hash ) {
              setShowEULA( true );
              return false;
            }
            setShowEULA( false );
            successfulLoginRedirect();
            return true;

          }
        } else if ( isNotEmpty( authenticationResponse.errors ) ) {
          setNotificationOptions( {
            message: <React.Fragment>
              {
                authenticationResponse.errors.map( ( error, i ) => (
                  <p key={ i }>{ error }</p>
                ) )
              }
            </React.Fragment>,
            type: 'alert',
          } );
        } else {
          setNotificationOptions( {
            message: 'An error occurred, please try again',
            type: 'alert',
          } );
        }
      } else {
        setNotificationOptions( {
          message: 'There was a connection error, please try again',
          type: 'alert',
        } );
      }
    }
  };

  const handleKeyPress = e => {
    if ( e.keyCode === 13 ) {
      e.preventDefault();
      if ( valid ) {
        handleLogin();
      }
    }
  };

  React.useEffect( () => {
    window.removeEventListener( 'keyup', handleKeyPress );
    window.addEventListener( 'keyup', handleKeyPress );
    return () => {
      window.removeEventListener( 'keyup', handleKeyPress );
    };
  }, [ updatedLogin, valid ] );

  return (
    <React.Fragment>
      <EulaModal
        eula={eula}
        sid={sid}
        setSid={setSid}
        expiration={expiration}
        setExpiration={setExpiration}
        successfulLoginRedirect={successfulLoginRedirect}
        showModal={showEULA}
        setShowModal={setShowEULA}
      />
      <div id="login">
        <div className="loginWrapper">
          <div className="formColumn">
            <div className="logoWrapper">
              {
                isNotEmpty( window.LOGO_IMAGE_LARGE_PATH ) &&
                  <img src={window.LOGO_IMAGE_LARGE_PATH} alt="logo" className="logoImage" />
              }
            </div>
            { isNotEmpty( notificationOptions ) &&
              <Notification options={notificationOptions} />
            }
            {
              isNotEmpty( fields ) &&
              <Form
                fields={fields}
                onChangeCallback={setUpdatedLogin}
                setUpdatedForm={setUpdatedLogin}
                setIsValid={setValid}
                recordType="login"
                trackUpdates={ false }
              />
            }
            <div className="actions">
              {
                loginStep === 2 &&
                <button
                  className="cancelButton"
                  onClick={ startOver }
                >
                  Start Over
                </button>
              }
              <button
                id="login-submit"
                disabled={ !valid }
                type="button"
                className="loginButton"
                onClick={ handleLogin }
              >
                { loginStep === 1 ? 'Next' : 'Sign In' }
              </button>
            </div>
            <div id="login-copyright">
              <p>This software is protected by U.S. Pat. No. 12,015,631. &bull; Confidential and Proprietary</p>
              <p>Copyright &copy; 2024 DeepSurface Security, Inc. All Rights Reserved.</p>
              <p>DeepSurface is a registered trademark of DeepSurface Security, Inc.</p>
            </div>
          </div>
          <div className={ `messageColumn ${window.IS_DARK_MODE ? 'darkMode' : '' }` }>
            <h2>Actionable Risk Intelligence</h2>
            <p>
              DeepSurface RiskAnalyzer is a risk-based automated vulnerability analysis and prioritization platform.
            </p>
          </div>
        </div>
        <FlashMessageQueue />
      </div>
    </React.Fragment>
  );
};

export default Login;