/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import InsightTable from '../../Table';

import {
  isNotEmpty,
  decodeURLHash,
  encodeURLHash,
  removeFromURLHash,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue.js';
import { getRecordDetails, getRecords, getRecordsCount } from '../../../../shared/RecordCache';
import PageHeader from '../../../../shared/PageHeader';
import RecordDetails from '../../../RecordDetails/index.js';
import ReportHeader from '../../ReportHeader/index.js';
import { defaultRemdiationItems } from '../../Table/shared.js';

const Vulnerabilities = ( ) => {

  const [ selectedRecord, setSelectedRecord ] = React.useState( null );

  const [ records, setRecords ] = React.useState( [] );
  const [ recordCount, setRecordCount ] = React.useState( 0 );

  const [ loading, setLoading ] = React.useState( true );
  const [ remediationItems, setRemediationItems ] = React.useState( defaultRemdiationItems );

  const [ appliedFilters, setAppliedFilters ] = React.useState( [] );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualCollapsed, setVisualCollapsed ] = React.useState( false );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const isMounted = true;

  const fetchExistingRecord = async( recordID ) => {
    const tagIDs = decodeURLHash()['asset_tag_ids'];

    const additionalParams = {
      filters: {},
    };

    if ( isNotEmpty( tagIDs ) ) {
      // eslint-disable-next-line camelcase
      additionalParams.filters.asset_tag_ids = tagIDs;
    }

    const _record = (
      await getRecordDetails(
        'vulnerability',
        recordID,
        additionalParams,
      )
    );
    return _record;
  };

  const selectRecord = async ( recordID ) => {
    if ( isNotEmpty( recordID ) ) {

      const fetchedRecord = await fetchExistingRecord( recordID );

      if ( isNotEmpty( fetchedRecord ) ) {
        setSelectedRecord( fetchedRecord );
        encodeURLHash( { item: recordID } );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'The record you are looking for is no longer part of the risk model',
        } );
        setSelectedRecord( {} );
        removeFromURLHash( 'item' );
        onRefresh();
      }
    } else {
      setSelectedRecord( {} );
      removeFromURLHash( 'item' );
      onRefresh();
    }
  };

  /* eslint-disable camelcase */
  const onRefresh = async( ) => {

    const hash = decodeURLHash();
    const onCorrectPage = hash.report === 'vulnerabilities';

    setLoading( true );

    if ( isMounted && onCorrectPage ) {
      if ( isNotEmpty( hash.item ) ) {
        selectRecord( hash.item );
      } else {
        const _count = await getRecordsCount( 'vulnerability' );
        const _records = await getRecords( 'vulnerability', null, true );
        setRecords( _records );
        setRecordCount( _count );
      }
    }
    setLoading( false );
  };
  /* eslint-enable camelcase */

  React.useEffect( () => {
    onRefresh();
  }, [] );

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedRecord )
          ? <RecordDetails
            record={selectedRecord}
            recordType="vulnerability"
            riskType="filtered_risk"
            selectRecord={selectRecord}
            options={ {
              isCollapsible: true,
              renderContext: 'risk_insight',
              // eslint-disable-next-line camelcase
              include_vulnerability_instances: true,
              // eslint-disable-next-line camelcase
              include_details: true,
              // eslint-disable-next-line camelcase
              include_statistics: true,
            } }
          />
          : <React.Fragment>
            <PageHeader>
              <ReportHeader
                reportType="vulnerability"
                onRefresh={onRefresh}
                visualCollapsed={visualCollapsed}
                setVisualCollapsed={setVisualCollapsed}
                records={records}
                recordCount={recordCount}
                selectRecord={selectRecord}
                loading={loading}
                remediationItems={remediationItems}
                setRemediationItems={setRemediationItems}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
              />
            </PageHeader>
            <div className="riskInsightTableAndVisualWrapper">
              <InsightTable
                records={records}
                recordCount={recordCount}
                loading={loading}
                reportType="vulnerability"
                onRefresh={onRefresh}
                refreshTable={onRefresh}
                visualCollapsed={visualCollapsed}
                selectedRecord={selectedRecord}
                selectRecord={selectRecord}
                remediationItems={remediationItems}
                setRemediationItems={setRemediationItems}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
              />
            </div>
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Vulnerabilities;