/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  globalColors,
  isNotEmpty,
  tintOrShadeHex,
} from '../../../shared/Utilities';

const TagItem = ( { tag, minimalVersion=false } ) => {

  const getTagStyle = ( tag ) => {
    // tag color styling
    if ( isNotEmpty( tag ) ) {
      let style = {
        border: `1px solid ${isNotEmpty( tag.color ) ? tag.color : globalColors['grey']}`,
        background: isNotEmpty( tag.color ) ? tintOrShadeHex( tag.color, 0.85, 'tint' ) : globalColors['grey--icon'],
        color: isNotEmpty( tag.color ) ? tag.color : globalColors['grey'],
        borderRadius: '99999px',
        padding: '0.5em 1em',
        marginRight: '0.75em !important',
      };

      if ( minimalVersion ) {
        style = {
          color: isNotEmpty( tag.color ) ? tag.color : globalColors['grey'],
          background: isNotEmpty( tag.color ) ? tintOrShadeHex( tag.color, 0.85, 'tint' ) : globalColors['grey--icon'],
          borderRadius: '0.375em',
          padding: '0.125em 0.5em',
          marginRight: '0.5em !important',
        };
      }
      return style;
    }
    return {};
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( tag ) &&
        <li
          className="assetTagListItem"
          style={
            getTagStyle( tag )
          }
        >
          { !tag.truncated && tag.label }
        </li>
      }
    </React.Fragment>
  );
};

export default TagItem;