/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  getDimensionsAndOffset,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  itemIsObject,
  itemIsString,
  recordTypeDisplayName,
  recordTypeIcon,
  riskToRating,
  tintOrShadeHex,
  uniqueArrayByProperty,
} from '../../../../shared/Utilities';
import { TagsContext } from '../../../../Contexts/Tags';
import {
  appliedFiltersTransformMap,
  deprioritizedCategoryClasses,
  excludedTagFilterAttributes,
  forReviewCategoryClasses,
  getFilterKeyFromMatchString,
  includedTagFilterAttributes,
  needsRecordLabel,
  prioritizedCategoryClasses,
} from '../shared';
import InlineSVG from '../../../../shared/InlineSVG';

import './AppliedFilter.scss';
import { getThemeColor } from '../../../../shared/Themes';

const AppliedFilter = ( { filter, removeAppliedFilter, variant, recordType } ) => {

  const MAX_SELECTIONS = 5;

  const [ tags ] = React.useContext( TagsContext );
  const [ transformedValue, setTransformedValue ] = React.useState( null );
  const [ attributeKey, setAttributeKey ] = React.useState( null );
  const [ showAllValues, setShowAllValues ] = React.useState( false );
  const [ allValuesStyle, setAllValuesStyle ] = React.useState( {} );

  const allValuesTriggerRef = React.useRef( null );

  // formats the value of the filter based on the attribute
  React.useEffect( () => {
    if ( isNotEmpty( filter ) ) {
      const { value } = filter;
      const _attributeKey = getFilterKeyFromMatchString( filter.attribute, variant, recordType );

      // we have a key and value, safe to move on
      if ( isNotEmpty( value ) && isNotEmpty( _attributeKey ) ) {
        // asset tags get built against the tags context
        if ( isNotEmpty( tags ) && _attributeKey === 'asset_tag_ids' && itemIsArray( value ) ) {
          const _tags = [];
          value.map( tagID => {
            const tag = tags[tagID];

            if ( isNotEmpty( tag ) ) {
              _tags.push( { label: tag.label, color: tag.color, tag } );
            }
          } );
          if ( isNotEmpty( _tags ) ) {
            setTransformedValue( _tags );
            setAttributeKey( _attributeKey );
          } else {
            setTransformedValue( null );
            setAttributeKey( null );
          }
        // if there is a transform for this filter, use it
        } else if (
          isNotEmpty( appliedFiltersTransformMap )
          && isNotEmpty( appliedFiltersTransformMap[_attributeKey] )
        ) {
          const _transform = appliedFiltersTransformMap[_attributeKey];

          if ( isNotEmpty( _transform ) ) {
            // this transform is a promise, need to fetch something
            if ( _transform.isPromise ) {
              _transform.formatter( value ).then( response => {
                if ( needsRecordLabel.includes( _attributeKey ) && itemIsArray( response ) ) {

                  let [ recordType ] = _attributeKey.split( '_' );

                  if ( _attributeKey === 'included_host_ids' || _attributeKey === 'excluded_host_ids' ) {
                    recordType = 'host';
                  }

                  let _value = [];
                  response.map( record => {
                    _value.push( {
                      label: recordTypeDisplayName( record, recordType ),
                      icon: recordTypeIcon[recordType],
                      type: recordType,
                      record,
                    } );
                  } );

                  _value = uniqueArrayByProperty( _value, 'label' );
                  setTransformedValue( _value );
                  setAttributeKey( _attributeKey );
                } else if ( _attributeKey === 'third_party_setting_ids' ) {
                  const _value = response.map( item => {
                    return ( { ...item } );
                  } );
                  setTransformedValue( _value );
                  setAttributeKey( _attributeKey );
                } else if ( itemIsArray( response ) ) {
                  const _value = response.map( item => {
                    return { label: item };
                  } );
                  setTransformedValue( _value );
                  setAttributeKey( _attributeKey );
                } else if ( isEmpty( response ) ) {
                  const _value = 'N/A';
                  setTransformedValue( _value );
                  setAttributeKey( _attributeKey );
                } else {
                  const _value = response;
                  setTransformedValue( _value );
                  setAttributeKey( _attributeKey );
                }
              } );
            // not a promise, can call the formatter directly and set it
            } else {
              const _value = _transform.formatter( value );
              setTransformedValue( { label: _value } );
              setAttributeKey( _attributeKey );
            }
          } else if ( itemIsArray( value ) ) {
            const _value = value.map( item => {
              return { label: item };
            } );
            setTransformedValue( _value );
            setAttributeKey( _attributeKey );
          } else {
            setTransformedValue( null );
            setAttributeKey( null );
          }
        // there is no value transform, just set the value
        } else {
          setTransformedValue( { label: value } );
          setAttributeKey( _attributeKey );
        }
      // something went wrong, just set all nulls
      } else {
        setTransformedValue( null );
        setAttributeKey( null );
      }
    }
  }, [ filter, tags ] );

  const getAttributeValueClass = ( attribute, value ) => {

    if ( isNotEmpty( attribute ) && isNotEmpty( value ) ) {
      // for tag included and excluded, need to make it status--green or status--red
      if ( includedTagFilterAttributes.includes( attribute ) ) {
        return 'included';
      }
      if ( excludedTagFilterAttributes.includes( attribute ) ) {
        return 'excluded';
      }

      // for exploit status, return the class based on the level
      if ( attribute === 'Exploit Status' ) {
        if ( value === 'null' || value === 'private' ) {
          return 'divider';
        }
        if ( value === 'published_details' ) {
          return 'low';
        }
        if ( value === 'poc' ) {
          return 'moderate';
        }
        if ( value === 'weaponized' ) {
          return 'high';
        }
        return 'default';
      }
      // for cvss, return the cvss rating class
      if ( attribute === 'CVSS Score' ) {
        const _value = value;

        if ( isNotEmpty( _value ) && itemIsString( _value ) ) {
          const gtlt = _value.substr( 0, 1 );
          const number = parseFloat( _value.substr( 1 ) );
          // less than amounts
          if ( gtlt === '<' ) {
            if ( number <= 3 ) {
              return 'minimal';
            }
            if ( number <= 6 ) {
              return 'low';
            }
            if ( number <= 7.5 ) {
              return 'moderate';
            }
            if ( number <= 9 ) {
              return 'high';
            }
            return 'critical';
          // more than amounts
          } else if ( gtlt === '>' ) {
            if ( number > 9 ) {
              return 'critical';
            }
            if ( number > 7.5 ) {
              return 'high';
            }
            if ( number > 6 ) {
              return 'moderate';
            }
            if ( number > 3 ) {
              return 'low';
            }
            return 'minimal';
          }
          return 'default';
        }
        return 'default';
      }
      if ( needsRecordLabel.includes( attribute ) ) {
        return riskToRating( value?.record?.filtered_risk || 'default' );
      }
      if ( attribute === 'Category' ) {
        if ( deprioritizedCategoryClasses.includes( value ) ) {
          return 'green';
        }
        if ( forReviewCategoryClasses.includes( value ) ) {
          return 'red50';
        }
        if ( prioritizedCategoryClasses.includes( value ) ) {
          return 'red';
        }
        return 'default';
      }
      if ( attribute === 'Tags' ) {
        return 'asset_tag';
      }
      return 'default';
    }
    return 'default';
  };

  const getTagStyle = ( attribute, tag ) => {
    // tag color styling
    if ( isNotEmpty( attribute ) && attribute === 'Tags' && isNotEmpty( tag ) ) {
      const tintAmount = window.IS_DARK_MODE ? 1 : 0.85;
      let background = 'none';

      if ( isNotEmpty( tag.color ) && !window.IS_DARK_MODE ) {
        if ( window.IS_DARK_MODE ) {
          background = 'none';
        } else {
          background = tintOrShadeHex( tag.color, tintAmount, 'tint' );
        }
      } else {
        background = getThemeColor( '--icon-color-primary' );
      }
      const style = {
        color: isNotEmpty( tag.color ) ? tag.color : getThemeColor( '--text-color-secondary' ),
        // eslint-disable-next-line max-len
        background,
      };

      return style;
    }
    return {};
  };

  const hasMultipleValues = transformedValue => {
    if ( itemIsArray( transformedValue ) ) {
      return true;
    } else if ( itemIsObject( transformedValue ) && itemIsArray( transformedValue.label ) ) {
      return true;
    }
    return false;
  };

  // when the user wants to see all the values, set the style so that the allValuesWrapper is positioned correctly
  React.useEffect( () => {
    if ( showAllValues && isNotEmpty( allValuesTriggerRef ) && isNotEmpty( allValuesTriggerRef.current ) ) {
      const triggerDimensions = getDimensionsAndOffset( allValuesTriggerRef.current );
      const valuesWrapperWidth = 200;
      if ( isNotEmpty( triggerDimensions ) ) {
        const { top, left } = triggerDimensions;

        // ideal location is to the right and down of the trigger,
        // if there is enough space, otherwise, go left and/or up
        // not enough space to the right, go left
        if ( left + valuesWrapperWidth > window.innerWidth ) {
          setAllValuesStyle( { width: valuesWrapperWidth, top: top - 20, left: left - valuesWrapperWidth - 20 } );
        // there is enough space to the right
        } else {
          setAllValuesStyle( { width: valuesWrapperWidth, top: top - 20, left: left + 20 } );
        }
      }
    } else {
      setAllValuesStyle( {} );
    }

  }, [ showAllValues, allValuesTriggerRef ] );


  return (
    <React.Fragment>
      {
        ( isNotEmpty( filter ) && isNotEmpty( transformedValue ) && isNotEmpty( attributeKey ) ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `${window.IS_DARK_MODE ? 'darkMode' : ''} appliedFilterWrapper ${hasMultipleValues( transformedValue ) ? 'multipleValues' : '' } appliedFilterClass--${filter.attribute}` }
        >
          <div className="filterValueWrapper">
            <span className="filterAttribute">{ filter.attribute }</span>
            <span className="filterComparator">{ filter.comparator === 'any' ? ':' : filter.comparator }</span>
            <span className="filterValue">
              {
                itemIsArray( transformedValue ) &&
                transformedValue.map( ( value, index ) => {
                  if ( index < MAX_SELECTIONS ) {
                    if ( itemIsObject( value ) && isNotEmpty( value.label ) ) {
                      return (
                        <span
                          // eslint-disable-next-line max-len
                          key={ index } className={ `multiValueItemWrapper ${ getAttributeValueClass( filter.attribute, value.label ) }`}
                          style={ getTagStyle( filter.attribute, value.tag ) }
                        >
                          { value.label }
                        </span>
                      );
                    }
                    if ( itemIsString( value ) ) {
                      return (
                        <span key={ index } >
                          { value }
                        </span>
                      );
                    }
                  }
                } )
              }
              {
                (
                  isNotEmpty( transformedValue )
                  && itemIsArray( transformedValue )
                  && transformedValue.length > MAX_SELECTIONS
                ) &&
                <span
                  className="selectionOverflow"
                  ref={allValuesTriggerRef}
                  onClick={ () => setShowAllValues( true ) }
                >
                  +{transformedValue.length - MAX_SELECTIONS} more...
                </span>
              }
              {
                showAllValues &&
                <React.Fragment>
                  <div className="allValuesShade" onClick={ () => setShowAllValues( false ) } />
                  <div
                    className="allValuesWrapper"
                    style={ allValuesStyle }
                  >
                    <label className="headerLabel">
                      <span>All Values</span>
                      <span
                        className="roundGlyphButton dark"
                        onClick={ () => setShowAllValues( false ) }
                      >
                        <InlineSVG type="close" />
                      </span>
                    </label>
                    {
                      transformedValue.map( ( value, index ) => {
                        if ( itemIsObject( value ) && isNotEmpty( value.label ) ) {
                          return (
                            <span
                              // eslint-disable-next-line max-len
                              key={ index } className={ `multiValueItemWrapper ${ getAttributeValueClass( filter.attribute, value.label ) }`}
                              style={ getTagStyle( filter.attribute, value.tag ) }
                            >
                              { value.label }
                            </span>
                          );
                        }
                        if ( itemIsString( value ) ) {
                          return (
                            <span key={ index } >
                              { value }
                            </span>
                          );
                        }
                      } )
                    }
                  </div>
                </React.Fragment>

              }
              {
                (
                  isNotEmpty( transformedValue )
                  && isNotEmpty( transformedValue.label )
                  && itemIsArray( transformedValue.label )
                )
                  ? transformedValue.label.map( ( value, index ) => {
                    return <span
                      // eslint-disable-next-line max-len
                      key={ index } className={ `multiValueItemWrapper ${ getAttributeValueClass( filter.attribute, value.label ) }`}
                    >
                      { itemIsString( value ) && value }
                    </span>;
                  } )
                  : <React.Fragment>
                    {
                      itemIsString( transformedValue ) && <span>{ transformedValue }</span>
                    }
                    {
                      ( itemIsObject( transformedValue ) && isNotEmpty( transformedValue.label ) ) &&
                      <span>{ transformedValue.label }</span>
                    }
                  </React.Fragment>
              }
            </span>
          </div>
          <button className="removeAppliedFilterButton" onClick={ () => removeAppliedFilter( filter ) }>
            <InlineSVG type="remove" />
          </button>
        </div>
      }
    </React.Fragment>
  );
};

export default AppliedFilter;