/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import { getThemeColor } from '../../../shared/Themes';

export const categoryLabelsMap = {
  'analysis': 'Analysis',
  'authentication-provider': 'Authentication Provider',
  'scan-host': 'Host Scan',
  'scan-nas': 'NAS Scan',
  'sync-license': 'License Sync',
  'scan-mssql': 'MSSQL Scan',
  'scan-mysqld': 'MySQL Scan',
  'sync-feed': 'Rule Feed',
  'setup-wizard': 'Setup Wizard',
  'system-clock-sync': 'System Clock Sync',
  'scan-subordinate': 'Subordinate Scanner',
  'third-party-api': 'Third Party API',
  'third-party-vuln': 'Third Party Scan',
  'third-party-host': 'Third Party Host',
  'scan-domain': 'Windows Domain Scan',
  'domain-discovery': 'Windows Domain Discovery',
  'scan-windows_clients': 'Windows Client Application Scan',
  'cloud-aws': 'AWS Account Scan',
  'email': 'Email',
  'license-expired': 'License Expired',
  'license-exceeded': 'License Exceeded',
  'license': 'Licensing',
  'health-check': 'System Health Check',
  'remediation-ticketing': 'Remediation Ticketing Exports',
  'remediation-email-export': 'Remediation Email Exports',
  'agent-host': 'Agent Host',
};

// source => types selection options mapping
export const typeLabelMap = {
  'analysis':{
    'stale-model':'Stale Model',
    'no-assets':'No Sensitive Assets Configured'},
  'authentication-provider':{},
  'scan-host':{
    'connection':'Connection Failure',
    'authentication':'Authentication Failure',
    'authorization':'Insufficient Access',
    'configuration':'Configuration Problem',
    'elevate':'Elevation Failure',
    'recipients-refused':'Recipients Refused',
    'sender-refused':'Sender Refused',
    'unsupported':'Unsupported OS/Platform',
    'other':'Other Error',
    'agent':'Agent Error',
  },
  'agent-host': {
    'bad-relay-token':'Agent Token Failure',
    'non-existent-relay-token':'Unknown Agent Relay Token',
    'missing-host-id':'Unknown host ID',
  },
  'scan-nas': {
    'identification': 'NAS Identification Failure',
  },
  'email':{
    'configuration':'Configuration Problem',
    'connection':'Connection Failure',
    'authentication':'Authentication Failure',
    'authorization':'Unauthorized',
    'unknown':'Unknown Error',
  },
  'sync-license':{},
  'scan-mssql':{
    'missing_component':'Missing Component',
    'instance-failures':'Instance Failure(s)'},
  'scan-mysql':{},
  'sync-feed':{},
  'system-clock-sync':{},
  'setup-wizard':{
    'no-hosts':'Authenticated Scans - No Hosts Found',
    'no-authenticated-scans':'Authenticated Scans - Not Yet Run',
    'no-connectivity-probe-settings':'Network Connectivity - None Scheduled',
    'no-connectivity-probe-history':'Network Connectivity - Not Yet Run',
    'unconfigured-credentials':'Scan Credentials - Not Configured',
    'unconfigured-scan-groups':'Scan Groups - Not Configured',
    'no-scheduled-scans':'Scan Groups - None Scheduled',
    'unconfigured-third-party':'Third-Party Scanner - Not Configured',
    'unconfigured-agent-endpoints':'Agents - Not Configured',
    'agent-data-missing':'Agents - No Host Data',
    'unconfigured-windows-domain':'Windows Domains - Not Configured',
  },
  'scan-subordinate':{
    'unavailable':'Unavailable',
    'went-offline':'Went Offline'},
  'cloud-aws':{
    'authentication-failure':'Authentication Failed - Incorrect Credentials',
    'authorization-failure':'Authorization Failed - Insufficient Permissions',
    'connection-failure':'HTTPS Connection',
  },
  'third-party-api': {},
  'third-party-vuln': {
    'connect-smb':'SMB Connection',
    'connect-ssh':'SSH Connection',
    'auth-smb':'SMB Authentication',
    'auth-ssh':'SSH Authentication',
    'authz-registry':'SMB Registry',
    'authz-adminshares':'SMB Admin Shares',
    'ssh-elevate':'SSH Elevation',
    'unknown-host': 'Unknown Host',
  },
  'third-party-host': {
    'rejected-due-to-age': 'Rejected due to age',
  },
  'scan-domain':{},
  'domain-discovery':{},
  'license-expired':{},
  'license-exceeded':{},
  'scan-windows_clients':{},
  'license':{
    'aws-metering-failed':'AWS Metering Failure',
    'f_cloud_integrations': 'Cloud Integrations',
    'f_credentialed_scan': 'Credentialed Scan',
    'f_general_configuration': 'General Configuration',
    'f_third_party_manual': 'Third-Party Manual Imports',
    'device_limit': 'Device Limit',
    'third_party_limit': 'Vulnerability Source Limit',
  },
  'health-check':{'disk-space-low':'Disk Space Low', 'disk-space-critical':'Disk Space Critically Low'},
  'remediation-ticketing':{ 'jira-sync-users': 'Jira Users Syncing Job',
    'jira-assign-ticket': 'Jira Assign Ticket',
    'jira-attach-file-to-ticket': 'Jira Attach File To Ticket',
    'jira-create-ticket': 'Jira Create Ticket',
    'jira-update-ticket': 'Jira Update Ticket Status' },
  'remediation-email-export':{ 'task-email-export': 'Task Email Export' },
};

export const categoryOptions = {
  'analysis': 'Analysis',
  'authentication-provider': 'Authentication Provider',
  'scan-host': 'Host Scan',
  'scan-nas': 'NAS Scan',
  'sync-license': 'License Sync',
  'scan-mssql': 'MSSQL Scan',
  'scan-mysqld': 'MySQL Scan',
  'sync-feed': 'Rule Feed',
  'setup-wizard': 'Setup Wizard',
  'system-clock-sync': 'System Clock Sync',
  'scan-subordinate': 'Subordinate Scanner',
  'third-party-api': 'Third Party API',
  'third-party-vuln': 'Third Party Scan',
  'third-party-host': 'Third Party Host',
  'scan-domain': 'Windows Domain Scan',
  'domain-discovery': 'Windows Domain Discovery',
  'scan-windows_clients': 'Windows Client Application Scan',
  'cloud-aws': 'AWS Account Scan',
  'email': 'Email',
  'license-expired': 'License Expired',
  'license-exceeded': 'License Exceeded',
  'license': 'Licensing',
  'health-check': 'System Health Check',
  'remediation-ticketing': 'Remediation Ticketing Exports',
  'remediation-email-export': 'Remediation Email Exports',
  'agent-host': 'Agent Host',
};

// source => types selection options mapping
export const typeMap = {
  'analysis':{
    'stale-model':'Stale Model',
    'no-assets':'No Sensitive Assets Configured'},
  'authentication-provider':{},
  'scan-host':{
    'connection':'Connection Failure',
    'authentication':'Authentication Failure',
    'authorization':'Insufficient Access',
    'configuration':'Configuration Problem',
    'elevate':'Elevation Failure',
    'recipients-refused':'Recipients Refused',
    'sender-refused':'Sender Refused',
    'unsupported':'Unsupported OS/Platform',
    'other':'Other Error',
    'agent':'Agent Error',
  },
  'agent-host': {
    'bad-relay-token':'Agent Token Failure',
    'non-existent-relay-token':'Unknown Agent Relay Token',
    'missing-host-id':'Unknown host ID',
  },
  'scan-nas': {
    'identification': 'NAS Identification Failure',
  },
  'email':{
    'configuration':'Configuration Problem',
    'connection':'Connection Failure',
    'authentication':'Authentication Failure',
    'authorization':'Unauthorized',
    'unknown':'Unknown Error',
  },
  'sync-license':{},
  'scan-mssql':{
    'missing_component':'Missing Component',
    'instance-failures':'Instance Failure(s)'},
  'scan-mysql':{},
  'sync-feed':{},
  'system-clock-sync':{},
  'setup-wizard':{
    'no-hosts':'Authenticated Scans - No Hosts Found',
    'no-authenticated-scans':'Authenticated Scans - Not Yet Run',
    'no-connectivity-probe-settings':'Network Connectivity - None Scheduled',
    'no-connectivity-probe-history':'Network Connectivity - Not Yet Run',
    'unconfigured-credentials':'Scan Credentials - Not Configured',
    'unconfigured-scan-groups':'Scan Groups - Not Configured',
    'no-scheduled-scans':'Scan Groups - None Scheduled',
    'unconfigured-third-party':'Third-Party Scanner - Not Configured',
    'unconfigured-agent-endpoints':'Agents - Not Configured',
    'agent-data-missing':'Agents - No Host Data',
    'unconfigured-windows-domain':'Windows Domains - Not Configured'},

  'scan-subordinate':{
    'unavailable':'Unavailable',
    'went-offline':'Went Offline'},
  'cloud-aws':{
    'authentication-failure':'Authentication Failed - Incorrect Credentials',
    'authorization-failure':'Authorization Failed - Insufficient Permissions',
    'connection-failure':'HTTPS Connection',
  },
  'third-party-api':{},
  'third-party-vuln':{
    'connect-smb':'SMB Connection',
    'connect-ssh':'SSH Connection',
    'auth-smb':'SMB Authentication',
    'auth-ssh':'SSH Authentication',
    'authz-registry':'SMB Registry',
    'authz-adminshares':'SMB Admin Shares',
    'ssh-elevate':'SSH Elevation',
    'unknown-host': 'Unknown Host',
  },
  'third-party-host': {
    'rejected-due-to-age': 'Rejected due to age',
  },
  'scan-domain':{},
  'domain-discovery':{},
  'license-expired':{},
  'license-exceeded':{},
  'scan-windows_clients':{},
  'license':{
    'aws-metering-failed':'AWS Metering Failure',
    'f_cloud_integrations': 'Cloud Integrations',
    'f_credentialed_scan': 'Credentialed Scan',
    'f_general_configuration': 'General Configuration',
    'f_third_party_manual': 'Third-Party Manual Imports',
    'device_limit': 'Device Limit',
    'third_party_limit': 'Vulnerability Source Limit',
  },
  'health-check':{'disk-space-low':'Disk Space Low', 'disk-space-critical':'Disk Space Critically Low'},
  'remediation-ticketing':{ 'jira-sync-users': 'Jira Users Syncing Job',
    'jira-assign-ticket': 'Jira Assign Ticket',
    'jira-attach-file-to-ticket': 'Jira Attach File To Ticket',
    'jira-create-ticket': 'Jira Create Ticket',
    'jira-update-ticket': 'Jira Update Ticket Status' },
  'remediation-email-export':{ 'task-email-export': 'Task Email Export' },
};


export const priorityOptions = {
  all: { fill: getThemeColor( '--text-color-primary' ), label: 'All' },
  high: { fill: getThemeColor( '--high' ), label: 'High' },
  medium: { fill: getThemeColor( '--moderate' ), label: 'Medium' },
  low: { fill: getThemeColor( '--low' ), label: 'Low' },
};


export const data = {
  filterInputs: [
    {
      type: 'buttons',
      label: 'Priority',
      attribute: 'priority',
      value: '',
      options: priorityOptions,
      allowBlank: true,
      blankDisplayText: 'Any',
    },
    {
      type: 'select',
      label: 'Category',
      attribute: 'source',
      value: '',
      options: categoryOptions,
      allowBlank: true,
      blankDisplayText: 'Any',
    },
    {
      type: 'select',
      label: 'Type',
      attribute: 'type',
      value: '',
      options: {},
      allowBlank: true,
      blankDisplayText: 'Any',
      conditionalOptions: { attribute: 'source', options: typeMap },
    },
    {
      type: 'date',
      label: 'Date Range',
      preLabel: 'From',
      attribute: 'modified_start',
      value: '',
      htmlProps: { max: new Date().toISOString().split( 'T' )[0] },
      needsUnixTransform: true,
    },
    {
      type: 'date',
      preLabel: 'To',
      attribute: 'modified_end',
      value: '',
      needsUnixTransform: true,
    },
    {
      type: 'debouncedText',
      label: 'Subject',
      attribute: 'keywords',
    },
    {
      type: 'checkbox',
      label: 'Show Hidden Alerts?',
      attribute: 'ignore',
    },
    {
      type: 'hidden',
      attribute: 'item_count',
      value: 100,
    },
    {
      type: 'hidden',
      attribute: 'current_page',
      value: 1,
    },
  ],
};
