/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import CopyToClipBoard from '../../../shared/CopyToClipBoard';
import EmptyState from '../../../shared/EmptyState';

import './style.scss';

import {
  isEmpty,
  isNotEmpty,
  focusForOnboarding,
  unFocusForOnboarding,
  generateSecureRandomCode,
} from '../../../shared/Utilities';

import { OnboardingWizardContext } from '../../../Contexts/OnboardingWizard';
import FormModal from '../../../shared/SetupComponents/FormModal';
import { getFieldValues } from '../../../shared/Form/Shared';
import InlineSVG from '../../../shared/InlineSVG';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';
import Accordion from '../../../shared/Accordion';
import PageCreateButton from '../../../shared/PageCreateButton';
import { hasFeatureAccess } from '../../App/AccessControl';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

const Agents = () => {

  const [ collapsed, setCollapsed ] = React.useState( {
    'windows-optional': true,
    'linux-optional': true,
    'macos-optional': true,
  } );

  const dayMap = {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  };

  const EMPTY_FIELDS = {
    domain: {
      fields: [
        {
          type:'contentBlock',
          attribute: 'help',
          defaultValue: <>
            {/* eslint-disable-next-line max-len */}
            <p>Please configure your agent settings below. For more in-depth documentation and explanations on agents, refer to our agent documentation <a href="#.=help_documentation&main=user_guide&section=scanning&help_page=agents" target="_blank" rel="noopener noreferrer" className="inlineLink inline">here <InlineSVG type="newTabLink" version="primary" /></a></p>
          </>,
        },
        {
          type: 'text',
          label: 'Registration Code',
          attribute: 'registration_code',
          defaultValue: '',
          disabled: true,
          generateOptions: {
            generate: () => generateNewRegistrationCode(),
            buttonText: 'Generate new code',
          },
        },
        {
          type: 'collection',
          label: 'Domain / IP : Port',
          attribute: 'endpoints',
          required: true,
          defaultValue: [],
          needsPort: true,
          needsIPAddressOrHostname: true,
          defaultPort: 44305,
          internalValidationMessage: 'All Domains / IPs must be valid.',
        },
      ],
    },
    schedule: {
      fields: [
        {
          type: 'select',
          label: 'Frequency',
          attribute: 'frequency',
          required: true,
          defaultValue: 'weekly',
          options: { daily: 'Daily', weekly: 'Weekly' },
        },
        {
          type: 'checkbox',
          label: 'Use blackout schedule',
          attribute: 'blackout_enabled',
          defaultValue: false,
        },
        {
          type: 'weeklySchedule',
          label: 'Blackout Schedule',
          attribute: 'blackouts',
          defaultValue: {},
          disableIf: { attribute: 'blackout_enabled', value: false },
          requireIf: { attribute: 'blackout_enabled', value: true },
        },
      ],
    },
  };
  const generateNewRegistrationCode = () => {
    // eslint-disable-next-line max-len
    if ( window.confirm( 'Are you sure you want to generate a new registration code? \n Any previously downloaded registration files will cease to work and the registration URLs will change.  In addition, all settings currently shown on this page will be saved.' ) ) {
      const newCode = generateSecureRandomCode( 40, 'hex' );
      return newCode;
    }
  };

  const [ osVersion, setOsVersion ] = React.useState( 'windows' );
  const [ thumbprint, setThumbprint ] = React.useState( null );

  const [ fields, setFields ] = React.useState( null );

  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ showModal, setShowModal ] = React.useState( false );

  // sections of the record that come from the server
  const [ existingRecord, setExistingRecord ] = React.useState( {} );

  const [ isValid, setIsValid ] = React.useState( true );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );


  const initializeModal = async () => {
    const _fields = { ...EMPTY_FIELDS };
    setFields( _fields );
    onRefresh();
    const elementToFocus = document.getElementById( 'agentSettingsButton' );
    focusForOnboarding( elementToFocus );
  };

  // On init, grab any settings from the server, setup the help and the form fields
  React.useEffect( () => {
    initializeModal();
  }, [] );

  // register and remove onleave listener
  React.useEffect( () => {
    const parentEl = document.getElementById( 'pageContent' );
    if ( isNotEmpty( parentEl ) ) {
      parentEl.onleave = async () => {
        if ( isValid ) {
          const test = await makeRequest( 'FETCH', '/project/default', {} );

          if ( isNotEmpty( test ) ) {
            onSave( false );
            return true;
          }
          window.location.href = `#=login${window.location.hash}`;
          return false;
        }
      };
    }

    return () => {
      if ( isNotEmpty( parentEl ) ) {
        parentEl.onleave = null;
      }
    };
  }, [ updatedForm, isValid ] );

  // if there is already an existing record setup, grab the thumbprint once
  React.useEffect( ( ) => {
    if ( isNotEmpty( existingRecord ) && isEmpty( thumbprint ) ) {
      makeRequest( 'COMPUTE', '/ca' ).then( response => {
        setThumbprint( response.thumbprint );
      } );
    }
  }, [ existingRecord ] );

  const onRefresh = async( ) => {
    const response = await makeRequest( 'FIND', '/project/default/agent_setting', {} );
    if ( isNotEmpty( response.results ) ) {
      setExistingRecord( response.results[0] );
    }
  };

  const onSave = async( shouldRefresh=true ) => {
    if ( isValid ) {
      if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
        const _values = getFieldValues( updatedForm.fieldStates );

        const record = {
          id: existingRecord.id,
          schedule: {
            // eslint-disable-next-line camelcase
            blackout_enabled: ( _values.blackout_enabled && _values.blackout_enabled !== undefined )
              ? _values.blackout_enabled
              : false,
            frequency: _values.frequency ? _values.frequency : 'weekly',
            // eslint-disable-next-line camelcase
            start_time: ( _values.blackouts && isNotEmpty( _values.blackouts ) ) ? _values.blackouts.start_time : '',
            // eslint-disable-next-line camelcase
            end_time: ( _values.blackouts && isNotEmpty( _values.blackouts ) ) ? _values.blackouts.end_time : '',
            // eslint-disable-next-line camelcase
            blackouts: ( _values.blackouts && isNotEmpty( _values.blackouts ) ) ? _values.blackouts.blackouts : [],
          },
          // eslint-disable-next-line camelcase
          registration_code: _values.registration_code,
          endpoints: _values.endpoints,
        };

        // eslint-disable-next-line camelcase
        makeRequest( 'UPSERT', '/project/default/agent_setting', { records: [ record ] } ).then( response => {
          if ( response && response.results && response.results.length ) {
            if ( shouldRefresh ) {
              onRefresh( response.results[0] );
              addFlashMessage( {
                body: 'Successfully saved agent settings',
                type: 'success',
              } );
            }
          } else if ( shouldRefresh ) {
            addFlashMessage( {
              body: 'Error saving agent settings',
              type: 'alert',
            } );
          }
          if (
            isNotEmpty( currentUser )
            && isNotEmpty( licenseInfo )
            && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' )
          ) {
            refreshWizard();
          }
          unFocusForOnboarding( document.getElementById( 'agentSettingsButton' ) );
        } );
      }
    }
  };

  React.useEffect( ( ) => {
    onRefresh();
    initializeModal();
  }, [ showModal ] );

  const toggleCollapseSection = section => {
    const prev = collapsed[section];
    setCollapsed( { ...collapsed, [section]: !prev } );
  };

  const isCollapsed = section => collapsed[section] === true;

  return (
    <React.Fragment>
      <FormModal
        recordType="agent"
        showModal={showModal}
        setShowModal={setShowModal}
        isValid={isValid}
        setIsValid={setIsValid}
        selectedRecord={existingRecord}
        onSave={onSave}
        fields={fields}
        formChangeCallBack={setUpdatedForm}
      />
      <div className="setupFormContainer agentsConfigurationsPage">
        <PageCreateButton>
          <button
            id="agentSettingsButton"
            className="agentSettingsButton newItemButton"
            onClick={ () => setShowModal( true ) }
          >
            Edit Agent Configuration
          </button>
        </PageCreateButton>
        {
          isNotEmpty( existingRecord.endpoints )
            ? <React.Fragment>
              <section className="domainsSection">
                {
                  isEmpty( existingRecord ) &&
                  <EmptyState
                    // eslint-disable-next-line camelcase, max-len
                    message="Edit your configuration settings to add valid endpoints for your agents"
                  />
                }
                {
                  isNotEmpty( existingRecord ) &&
                  <React.Fragment>
                    <div
                      className={ `${osVersion} toggleWrapper optionCount--3 ${window.IS_DARK_MODE ? 'darkMode' : ''}` }
                    >
                      <button
                        onClick={ () => setOsVersion( 'windows' ) }
                        className={ `${osVersion === 'windows' ? 'toggled' : ''} toggleButton` }
                      >
                        <InlineSVG type="windows" version="light" />
                        <span>Windows</span>
                      </button>
                      <button
                        onClick={ () => setOsVersion( 'linux' ) }
                        className={ `${osVersion === 'linux' ? 'toggled' : ''} toggleButton` }
                      >
                        <InlineSVG type="linux" version="light" />
                        <span>Linux</span>
                      </button>
                      <button
                        onClick={ () => setOsVersion( 'macOS' ) }
                        className={ `${osVersion === 'macOS' ? 'toggled' : ''} toggleButton` }
                      >
                        <InlineSVG type="macos" version="light" />
                        <span>macOS</span>
                      </button>
                    </div>
                    <div className="sectionHeader">Deployment</div>
                    {
                      osVersion === 'windows' &&
                      <React.Fragment>
                        <div className="tabWrapper documentationWrapper">
                          <div className="documentationStep">
                            <h3>Step 1</h3>
                            <p>
                              Distribute the following setup Powershell script to the Windows
                              host where you wish to install it:
                            </p>
                            <div className="downloadButtons">
                              {
                                isEmpty( existingRecord.endpoints )
                                  ? <div className="disabledButton">
                                    Download
                                  </div>
                                  : <a
                                    className="downloadButton secondary"
                                    target="_blank"
                                    href="/agent-setup/deepsurface-windows.ps1"
                                    download="deepsurface-windows.ps1"
                                  >
                                    <InlineSVG type="downloadAlt" version="risk--green" />
                                    Download
                                  </a>

                              }
                            </div>
                          </div>
                          {
                            ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                            <div className="documentationStep">
                              <h3>Step 2</h3>
                              <p>
                                {/* eslint-disable-next-line max-len */}
                                <strong>Be sure to execute the following commands in Powershell opened as an Administrator.</strong> Run the downloaded script with the following arguments to install and register the agent with your { window.COMPANY_NAME } main console:
                              </p>
                              <pre>
                                {/* eslint-disable-next-line max-len */}
                                { `powershell .\\deepsurface-windows.ps1 ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                <CopyToClipBoard
                                  // eslint-disable-next-line max-len
                                  text={ `powershell .\\deepsurface-windows.ps1 ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                />
                              </pre>
                            </div>
                          }
                          {
                            ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                            <div className="documentationStep">
                              <h3>Note</h3>
                              <p>
                                You may need to relax Powershell Execution Policy in order to run this script.
                                This can be achieved by either adding <code>-ExecutionPolicy bypass</code> to the
                                above command, or by first running:
                              </p>
                              <pre>
                                Set-ExecutionPolicy -Scope Process Bypass -force
                                <CopyToClipBoard
                                  text="Set-ExecutionPolicy -Scope Process Bypass -force"
                                />
                              </pre>
                              <p>
                                If you should ever need to re-register an agent after the software has been
                                installed, you can run the following:
                              </p>
                              <pre>
                                {/* eslint-disable-next-line max-len */}
                                { `& 'C:\\Program Files\\DeepSurface\\DSAgent\\DSAgent.exe' register ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                <CopyToClipBoard
                                  // eslint-disable-next-line max-len
                                  text={ `& 'C:\\Program Files\\DeepSurface\\DSAgent\\DSAgent.exe' register ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                />
                              </pre>
                            </div>
                          }
                          <div className="documentationStep">
                            <h3>Step 3</h3>
                            {/* eslint-disable-next-line max-len */}
                            <p>Once agents have successfully installed on machines, you can check on their status <a href="#.=scanning&page=scanning_dashboard&current_page=1&scanning_mode=agent&item_count=100" target="_blank" rel="noopener noreferrer" className="inlineLink inline">here <InlineSVG type="newTabLink" version="primary" /></a>.</p>
                            {/* eslint-disable-next-line max-len */}
                            <p>For more in-depth documentation and explanations on agents, refer to our agent documentation <a href="#.=help_documentation&main=user_guide&section=scanning&help_page=agents" target="_blank" rel="noopener noreferrer" className="inlineLink inline">here <InlineSVG type="newTabLink" version="primary" /></a></p>
                          </div>

                        </div>
                        <Accordion>
                          <div
                            // eslint-disable-next-line
                            className={`${isCollapsed( 'windows-optional' ) ? 'collapsed' : ''} accordionWrapper alternate`}
                          >
                            <div
                              className="accordionHeader"
                              onClick={ () => toggleCollapseSection( 'windows-optional' ) }
                            >
                              <h3>Other installation methods</h3>
                              <button
                                onClick={ () => toggleCollapseSection( 'windows-optional' ) }
                              >
                                <InlineSVG type="carretUp" />
                              </button>
                            </div>
                            <div className="accordionBody">
                              <div className="documentationStep">
                                <p>
                                  If you should ever need to re-register an agent after the software has been
                                  installed, you can download the .msi which is located at:
                                </p>
                                <pre>
                                  {
                                    existingRecord.endpoints.map( ( e, i ) => {
                                      return <React.Fragment key={i} >
                                        { `https://${e}/agent/DeepSurfaceAgent-win64.msi` }
                                        <CopyToClipBoard
                                          text={ `https://${e}/agent/DeepSurfaceAgent-win64.msi` }
                                        />
                                      </React.Fragment>;
                                    } )
                                  }
                                </pre>
                              </div>
                            </div>
                          </div>
                        </Accordion>
                      </React.Fragment>
                    }
                    {
                      osVersion === 'linux' &&
                      <React.Fragment>
                        <div className="tabWrapper documentationWrapper">
                          <div className="documentationStep">
                            <h3>Step 1</h3>
                            <p>
                              Distribute the following setup shell script to the linux host where you wish to
                              install it:
                            </p>
                            <div className="downloadButtons">
                              {
                                isEmpty( existingRecord.endpoints )
                                  ? <div className="disabledButton">
                                    Download
                                  </div>
                                  : <a
                                    className="downloadButton secondary"
                                    target="_blank"
                                    href="/agent-setup/deepsurface-linux.sh"
                                    download="deepsurface-linux.sh"
                                  >
                                    <InlineSVG type="downloadAlt" version="risk--green" />
                                    Download
                                  </a>
                              }
                            </div>
                          </div>
                          {
                            ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                            <div className="documentationStep">
                              <h3>Step 2</h3>
                              <p>
                                Run the following command to install and register the agent with your
                                { window.COMPANY_NAME } main console:
                              </p>
                              <pre>
                                {/* eslint-disable-next-line max-len */}
                                { `sudo sh deepsurface-linux.sh ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                <CopyToClipBoard
                                  // eslint-disable-next-line max-len
                                  text={ `sudo sh deepsurface-linux.sh ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                />
                              </pre>
                            </div>
                          }
                          {
                            ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                            <div className="documentationStep">
                              <h3>Note</h3>
                              <p>
                                If you should ever need to re-register an agent after the software has been installed,
                                you can run the following:
                              </p>
                              <pre>
                                {/* eslint-disable-next-line max-len */}
                                { `sudo /opt/deepsurface/agent/bin/dsagent_linux --debug register ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                <CopyToClipBoard
                                  // eslint-disable-next-line max-len
                                  text={ `sudo /opt/deepsurface/agent/bin/dsagent_linux --debug register ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                />
                              </pre>
                            </div>
                          }
                          <div className="documentationStep">
                            <h3>Step 3</h3>
                            {/* eslint-disable-next-line max-len */}
                            <p>Once agents have successfully installed on machines, you can check on their status <a href="#.=scanning&page=scanning_dashboard&current_page=1&scanning_mode=agent&item_count=100" target="_blank" rel="noopener noreferrer" className="inlineLink inline">here <InlineSVG type="newTabLink" version="primary" /></a>.</p>
                            {/* eslint-disable-next-line max-len */}
                            <p>For more in-depth documentation and explanations on agents, refer to our agent documentation <a href="#.=help_documentation&main=user_guide&section=scanning&help_page=agents" target="_blank" rel="noopener noreferrer" className="inlineLink inline">here <InlineSVG type="newTabLink" version="primary" /></a></p>
                          </div>
                        </div>
                        <Accordion>
                          <div
                            // eslint-disable-next-line
                            className={`${isCollapsed( 'macos-optional' ) ? 'collapsed' : ''} accordionWrapper alternate`}
                          >
                            <div
                              className="accordionHeader"
                              onClick={ () => toggleCollapseSection( 'macos-optional' ) }
                            >
                              <h3>Other installation methods</h3>
                              <button
                                onClick={ () => toggleCollapseSection( 'macos-optional' ) }
                              >
                                <InlineSVG type="carretUp" />
                              </button>
                            </div>
                            <div className="accordionBody">
                              <div className="documentationStep">
                                <p>
                                  If you should ever need to re-register an agent after the software has been
                                  installed, you can download the .msi which is located at:
                                </p>
                                <pre>
                                  {
                                    existingRecord.endpoints.map( ( e, i ) => {
                                      return <React.Fragment key={i} >
                                        { `https://${e}/agent/deepsurface-agent.pkg` }
                                        <CopyToClipBoard
                                          text={ `https://${e}/agent/deepsurface-agent.pkg` }
                                        />
                                      </React.Fragment>;
                                    } )
                                  }
                                </pre>
                              </div>
                            </div>
                          </div>
                        </Accordion>
                      </React.Fragment>
                    }
                    {
                      osVersion === 'macOS' &&
                      <React.Fragment>
                        <div className="tabWrapper documentationWrapper">
                          <div className="documentationStep">
                            <h3>Step 1</h3>
                            <p>
                              Distribute the following setup shell script to the macOS host where you wish to
                              install it:
                            </p>
                            <div className="downloadButtons">
                              {
                                isEmpty( existingRecord.endpoints )
                                  ? <div className="disabledButton">
                                    Download
                                  </div>
                                  : <a
                                    className="downloadButton secondary"
                                    target="_blank"
                                    href="/agent-setup/deepsurface-macos.sh"
                                    download="deepsurface-macos.sh"
                                  >
                                    <InlineSVG type="downloadAlt" version="risk--green" />
                                    Download
                                  </a>
                              }
                            </div>
                          </div>
                          <div className="documentationStep">
                            <h3>Step 2 (Arm-based MacOS machines only)</h3>
                            <p>
                              If you have an M1, M2, M3, etc. Arm-based Macos machine, ensure that it has
                              Rosetta 2 installed
                            </p>
                            <pre>
                              sudo softwareupdate --install-rosetta
                              <CopyToClipBoard
                                // eslint-disable-next-line max-len
                                text="sudo softwareupdate --install-rosetta"
                              />
                            </pre>
                          </div>
                          {
                            ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                            <div className="documentationStep">
                              <h3>Step 3</h3>
                              <p>
                                Run the following command to install and register the agent with your
                                DeepSurface main console:
                              </p>
                              <pre>
                                {/* eslint-disable-next-line max-len */}
                                { `sudo bash deepsurface-macos.sh ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                <CopyToClipBoard
                                  // eslint-disable-next-line max-len
                                  text={ `sudo bash deepsurface-macos.sh ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                />
                              </pre>
                            </div>
                          }
                          {
                            ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                            <div className="documentationStep">
                              <h3>Note</h3>
                              <p>
                                If you should ever need to re-register an agent after the software has been installed,
                                you can run the following:
                              </p>
                              <pre>
                                {/* eslint-disable-next-line max-len */}
                                { `sudo /Library/DeepSurface/DSAgent/deepsurface_agent --debug register ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                <CopyToClipBoard
                                  // eslint-disable-next-line max-len
                                  text={ `sudo /Library/DeepSurface/DSAgent/deepsurface_agent --debug register ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                                />
                              </pre>
                            </div>
                          }
                          <div className="documentationStep">
                            <h3>Step 4</h3>
                            {/* eslint-disable-next-line max-len */}
                            <p>Once agents have successfully installed on machines, you can check on their status <a href="#.=scanning&page=scanning_dashboard&current_page=1&scanning_mode=agent&item_count=100" target="_blank" rel="noopener noreferrer" className="inlineLink inline">here <InlineSVG type="newTabLink" version="primary" /></a>.</p>
                            {/* eslint-disable-next-line max-len */}
                            <p>For more in-depth documentation and explanations on agents, refer to our agent documentation <a href="#.=help_documentation&main=user_guide&section=scanning&help_page=agents" target="_blank" rel="noopener noreferrer" className="inlineLink inline">here <InlineSVG type="newTabLink" version="primary" /></a></p>
                          </div>
                        </div>
                        <Accordion>
                          <div
                            // eslint-disable-next-line
                            className={`${isCollapsed( 'macos-optional' ) ? 'collapsed' : ''} accordionWrapper alternate`}
                          >
                            <div
                              className="accordionHeader"
                              onClick={ () => toggleCollapseSection( 'macos-optional' ) }
                            >
                              <h3>Other installation methods</h3>
                              <button
                                onClick={ () => toggleCollapseSection( 'macos-optional' ) }
                              >
                                <InlineSVG type="carretUp" />
                              </button>
                            </div>
                            <div className="accordionBody">
                              <div className="documentationStep">
                                <p>
                                  If you should ever need to re-register an agent after the software has been
                                  installed, you can download the .msi which is located at:
                                </p>
                                <pre>
                                  {
                                    existingRecord.endpoints.map( ( e, i ) => {
                                      return <React.Fragment key={i} >
                                        { `https://${e}/agent/deepsurface-agent.pkg` }
                                        <CopyToClipBoard
                                          text={ `https://${e}/agent/deepsurface-agent.pkg` }
                                        />
                                      </React.Fragment>;
                                    } )
                                  }
                                </pre>
                              </div>
                            </div>
                          </div>
                        </Accordion>
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
              </section>
              <section className="scheduleSection">
                <div className="sectionHeader">Schedule</div>
                {
                  isNotEmpty( existingRecord.schedule )
                    ? <ul className="scheduleWrapper">
                      <li>
                        <strong>Schedule Frequency</strong>
                        <span>{ existingRecord.schedule.frequency }</span>
                      </li>
                      <li>
                        <strong>Blackout Schedule Enabled? </strong>
                        <span>{ existingRecord.schedule.blackout_enabled ? 'yes' : 'no' }</span>
                      </li>
                      {
                        existingRecord.schedule.blackout_enabled &&
                        <li>
                          <strong>Blackout Schedule </strong>
                          <ul>
                            {
                              existingRecord.schedule.blackouts.map( ( day, index ) => {
                                return  <li key={index}>
                                  <strong>{ dayMap[day.day] }</strong>
                                  { ` between ${day.start_time} and ${day.end_time}` }
                                </li>;
                              } )
                            }
                          </ul>
                        </li>
                      }
                    </ul>
                    : <EmptyState message="No schedule"/>
                }

              </section>
            </React.Fragment>
            : <EmptyState message="Agent settings have not been configured" />
        }
      </div>
    </React.Fragment>
  );
};

export default Agents;
