/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  decodeURLHash,
  defaultTagColor,
  encodeURLHash,
  formatNumber,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  recordTypeDisplayName,
  removeFromURLHash,
  tagColors,
} from '../../../shared/Utilities';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import Form from '../../../shared/Form';
import { HelpTrigger } from '../../HelpDocumentation/ContextualHelp';
import { getFieldValues } from '../../../shared/Form/Shared';
import InlineSVG from '../../../shared/InlineSVG';

import './TagEditorV3.scss';
import IndeterminantPagination, { getRowNums, pageIterator } from '../../../shared/Pagination/IndeterminantPagination';
import { makeRequest } from '../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import EmptyState from '../../../shared/EmptyState';
import Loading from '../../../shared/Loading';
import DataTable from '../../../shared/DataTable';
import ScanningStatusIndicator from '../../RecordDetails/ScanningStatusIndicator';
import { tagFilterAttributes } from '../../../shared/FilterForm/shared';
import { canConfigure } from '../../App/AccessControl';
import FilterBuilder from '../../RiskInsight/Filters/FilterBuilder';
import {
  allAvailableTagFilters,
  getAllAvailableFiltersForType,
  getFilterKeyFromMatchString,
  includedTagFilterAttributes,
} from '../../RiskInsight/Filters/shared';
import { defaultHostURL } from '../../App/Routing';
import Notification from '../../../shared/Notification';

const TagEditorV3 = ( {
  selectedRecord,
  showModal,
  setShowModal,
  onSave,
  osLabels,
  users,
} ) => {

  // current user from the context
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const EMPTY_TAG_REQUIRED_FIELDS = {
    name: {
      fields: [
        {
          type: 'text',
          label: 'Name',
          attribute: 'label',
          required: true,
          defaultValue: '',
          help: <HelpTrigger helpKey="name_tag" />,
        },
        {
          type: 'colorSelect',
          label: 'Color',
          attribute: 'color',
          defaultValue: defaultTagColor,
          required: true,
          allowBlank: false,
          options: {
            one: [ ...tagColors.one ],
            three: [ ...tagColors.three ],
          },
          help: <HelpTrigger helpKey="color" />,
        },
      ],
    },
    owner: {
      fields: [
        {
          type: 'select',
          label: 'Owner',
          attribute: 'remediation_manager',
          defaultValue: 'null',
          allowBlank: true,
          blankDisplayValue: 'null',
          help: <HelpTrigger helpKey="owner_tag" />,
        },
      ],
    },
  };

  const [ sanitizedRecord, setSanitizedRecord ] = React.useState( {} );
  const [ requiredFields, setRequiredFields ] = React.useState( null );
  const [ requiredValid, setRequiredValid ] = React.useState( false );
  const [ updatedRequiredForm, setUpdatedRequiredForm ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  const [ appliedDefinitionFilters, setAppliedDefinitionFilters ] = React.useState( [] );
  const [ definitionValid, setDefinitionValid ] = React.useState( false );

  const [ appliedFilters, setAppliedFilters ] = React.useState( [] );

  const [ existingRecordAppliedFilters, setExistingRecordAppliedFilters ] = React.useState( [] );

  const clearAppliedFiltersFromHash = () => {
    const hash = decodeURLHash();

    const availableFilters = getAllAvailableFiltersForType( 'tag' );
    if ( isNotEmpty( availableFilters ) ) {
      Object.keys( availableFilters ).map( key => {
        if ( isNotEmpty( hash[key] ) ) {
          removeFromURLHash( key );
        }
      } );
    }
  };

  // for some reason the records have null values instead of empty arrays and it is messing up the fields,
  // this will sanitize those situations so that the form does not get messed up
  React.useEffect( () => {
    if ( isNotEmpty( selectedRecord ) && showModal === true ) {
      const _sanitizedRecord = { ...selectedRecord };

      const _existingRecordAppliedFilters = [];

      Object.entries( selectedRecord ).map( ( [ key, value ] ) => {
        // this is one of the definition fields, need to create an applied filter for it
        if ( tagFilterAttributes.includes( key ) ) {

          // strange empty or null value, need to clear it out
          if ( isEmpty( value ) || !itemIsArray( value ) ) {
            _sanitizedRecord[key] = [];
          // has a regular value, need to create an applied filter for it
          } else {
            const { matchString } = allAvailableTagFilters[key];

            if ( isNotEmpty( matchString ) ) {
              const _filter = {
                attribute: matchString,
                comparator: 'any',
                value,
              };
              _existingRecordAppliedFilters.push( _filter );
            }
          }
        }
      } );
      setExistingRecordAppliedFilters( _existingRecordAppliedFilters );
      handleAppliedFiltersChange( _existingRecordAppliedFilters );
      setSanitizedRecord( _sanitizedRecord );
    } else {
      setSanitizedRecord( {} );
      setExistingRecordAppliedFilters( [] );
      setAppliedFilters( [] );
      clearAppliedFiltersFromHash();
    }
    setCurrentPageNumber( 1 );
    setCurrentPageResults( null );
    setNextPageResults( null );
  }, [ selectedRecord, showModal ] );

  // set the appropriate options for the os version fields
  // set the appropriate options for the owner field
  // sets up the fields needed for the forms
  React.useEffect( () => {
    const _requiredFields = { ...EMPTY_TAG_REQUIRED_FIELDS };

    const ownerField = _requiredFields?.owner?.fields?.find( f => f.attribute === 'remediation_manager' );

    if (
      isNotEmpty( ownerField )
      && isNotEmpty( currentUser )
    ) {
      ownerField.defaultValue = currentUser.id;
      ownerField.options = users;

      setRequiredFields( _requiredFields );
    }
  }, [ osLabels, users, currentUser ] );

  const selectHost = hostID => {
    // eslint-disable-next-line max-len
    window.location.href = `${defaultHostURL}&item=${hostID}`;
  };

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ), item_count: 100 } );
    testTagDefinition( parseInt( page ) );
  };

  const testTagDefinition = async ( page=1 ) => {
    setLoading( true );
    setCurrentPageNumber( page );
    setCurrentPageResults( [] );
    setNextPageResults( [] );

    // eslint-disable-next-line camelcase
    const filterValues = { current_page: page, item_count: 100 };

    const params =  {
      // eslint-disable-next-line camelcase
      order_by: [ [ 'label', 'ASC' ] ],
      rownums: getRowNums( filterValues, 100 ),
    };

    if ( isNotEmpty( appliedDefinitionFilters ) ) {
      appliedDefinitionFilters.map( filter => {
        const attr = getFilterKeyFromMatchString( filter.attribute, 'tagEditor' );
        params[attr] = filter.value;
      } );
    }

    if ( !definitionValid ) {
      addFlashMessage( {
        header: 'Missing Information',
        // eslint-disable-next-line max-len
        body: 'Must have at least 1 Include Filter applied (IP Ranges, Specific Hosts, Host Wildcards, Operating Systems)',
        type: 'alert',
      } );
      setLoading( false );
    } else {
      // eslint-disable-next-line camelcase
      encodeURLHash( { current_page: page } );

      const testFiltersResponse = await makeRequest( 'COMPUTE', '/project/default/asset_tag', params );

      setLoading( false );

      if (
        isNotEmpty( testFiltersResponse )
        && isNotEmpty( testFiltersResponse.results )
        && isNotEmpty( testFiltersResponse.results.target_hosts )
      ) {

        const hostData = [];

        testFiltersResponse.results.target_hosts.map( host => {

          const _host = { ...host, type: 'host' };

          hostData.push(
            {
              name: <a
                // eslint-disable-next-line max-len
                href={ `${defaultHostURL}&item=${host.id}` }
              >
                { recordTypeDisplayName( _host, 'host' ) }
              </a>,
              addresses: <span
                className="ipAddressesWrapper"
              >
                { isNotEmpty( _host.ip_addresses ) ? _host.ip_addresses.join( ', ' ) : '' }
              </span>,
              // eslint-disable-next-line camelcase
              operating_system: host.product_name,
              'DeepSurface Scanning Status': <ScanningStatusIndicator hideText timestamp={ _host.last_scanned } />,
              actions: <React.Fragment>
                {
                  isNotEmpty( _host.id ) &&
                  <React.Fragment>
                    <button
                      title="View Full Host Details"
                      className="roundGlyphButton light selectRecordButton"
                      onClick={ () => selectHost( _host.id ) }
                    >
                      <InlineSVG type="carretRight" />
                    </button>
                  </React.Fragment>
                }
              </React.Fragment>,
              id: _host.id,
              originalRecord: _host,
            },
          );
        } );
        const pageResults = pageIterator( hostData, filterValues );

        setCurrentPageResults( pageResults.firstPage );
        setNextPageResults( pageResults.secondPage );

        addFlashMessage( {
          type: 'success',
          // eslint-disable-next-line max-len
          body: `Applied filters returned ${ formatNumber( testFiltersResponse.results?.target_hosts?.length ) } host(s)`,
        } );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'Applied filters returned no hosts',
        } );
      }
    }

  };

  const tableHeader = () => {
    if ( isEmpty( currentPageResults ) ) {
      return 'Matching Hosts (0)';
    }

    const hash = decodeURLHash();

    const pageNumber = hash.current_page || 1;
    const itemsPerPage = hash.item_count || 100;
    const recordCount = currentPageResults?.length || 0;

    const beginning = itemsPerPage * ( pageNumber - 1 );
    const end = beginning + recordCount;

    return `Matching Hosts (${beginning + 1} - ${end})`;
  };

  // external callback, passed to the filter builder, to handle changes to the applied filters
  const handleAppliedFiltersChange = _appliedFilters => {
    setAppliedDefinitionFilters( _appliedFilters );
    setCurrentPageNumber( 1 );
    setCurrentPageResults( null );
    setNextPageResults( null );
    if ( isNotEmpty( _appliedFilters ) ) {
      const definitionKeys = _appliedFilters.map( filter => filter.attribute );
      // eslint-disable-next-line max-len
      const valid = definitionKeys.some( key => includedTagFilterAttributes.includes( getFilterKeyFromMatchString( key, 'tagEditor' ) ) );
      setDefinitionValid( valid );
    } else {
      setDefinitionValid( false );
    }
  };

  return (
    <React.Fragment>
      { loading && <Loading /> }
      <Notification
        options={ {
          type: 'info',
          header: 'Important',
          message: <React.Fragment>
            {/* eslint-disable-next-line max-len */}
            For performance reasons, changes to tags may not take complete effect immediately in risk insights, dashboards, and reports until after another analysis job has fully executed. After making desired changes to tags, consider <a href="#.=activity&page=tasks">launching another analysis job</a>.
          </React.Fragment>,
        } }
      />
      {
        ( isNotEmpty( requiredFields ) ) &&
        <React.Fragment>
          <label className="nameAndColorLabel">Name and Color</label>
          <Form
            fields={requiredFields}
            trackUpdates={false}
            recordType="tag"
            onChangeCallback={ setUpdatedRequiredForm }
            existingRecord={ sanitizedRecord }
            setIsValid={ setRequiredValid }
            elementClass="requiredFieldsFormWrapper"
          />
        </React.Fragment>
      }
      <div className="tagDefinitionWrapper">
        <h2>Tag Definition</h2>
        <FilterBuilder
          variant="tagEditor"
          osLabels={osLabels}
          recordType="host"
          externalChangeHandler={ handleAppliedFiltersChange }
          appliedFiltersFromRecord={ existingRecordAppliedFilters }
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
        <button
          disabled={ isEmpty( appliedDefinitionFilters ) || !definitionValid }
          // eslint-disable-next-line max-len
          className={ `testDefinitionButton ${( isEmpty( appliedDefinitionFilters ) || !definitionValid ) ? 'disabled' : ''}`}
          onClick={ () => testTagDefinition( 1 ) }
        >
          Test tag definition
        </button>
      </div>
      {
        isNotEmpty( currentPageResults )
          ? <React.Fragment>
            <div className="tagTestTableDescription">
              The Above filters matched the following host(s)
            </div>
            <div className="tagTestTableHeader">
              <h2>{ tableHeader() }</h2>
              <IndeterminantPagination
                currentPageNumber={currentPageNumber}
                nextPageResults={nextPageResults}
                goToPage={goToPage}
                elementClass="tagTestPagination"
              />
            </div>
            <div className="tagTestTableWrapper">
              <DataTable
                data={currentPageResults}
                elementClass="policyTestDataTable"
              />
            </div>
          </React.Fragment>
          : <EmptyState message="Add filters to create a tag definition"/>
      }
      <div className="modalActions">
        <button
          disabled={ ( !requiredValid && !definitionValid ) || !canConfigure( licenseInfo ) }
          // eslint-disable-next-line max-len
          className={`${ ( requiredValid && definitionValid ) ? '' : 'disabled'} ${ !canConfigure( licenseInfo ) ? 'disabled' : ''} submitButton`}
          onClick={ () => onSave(
            selectedRecord,
            ( requiredValid && definitionValid ),
            {
              ...getFieldValues( updatedRequiredForm?.fieldStates, 'tag' ),
              definition: appliedDefinitionFilters,
            },
            () => setShowModal( false ),
            setLoading,
          ) }
        >
          Save Changes
        </button>
        <button
          className="cancelButton"
          onClick={ () => setShowModal( false ) }
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

export default TagEditorV3;