/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty, shortenedVulnerabilityScannerNameMap } from '../../../shared/Utilities';
import ScanningStatusIndicator from '../ScanningStatusIndicator';

const ScanningStatus = ( { record, latestThirdParty } ) => {

  const [ thirdPartyLastScanned, setThirdPartyLastScanned ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( record ) ) {
      if ( isNotEmpty( record.tp_last_scanned ) ) {
        setThirdPartyLastScanned( record.tp_last_scanned );
      } else if ( isNotEmpty( latestThirdParty ) ) {
        setThirdPartyLastScanned( latestThirdParty.timestamp || 0 );
      }
    }
  }, [ record, latestThirdParty ] );


  return (
    <React.Fragment>
      {
        isNotEmpty( record ) &&
        <div className="recordDetails__InformationPanel__Body_Section scanningStatus">
          <div className="bodySectionItem">
            <label>
              <span>Third Party Status</span>
              <span>
                {
                  isNotEmpty( latestThirdParty )
                    ? shortenedVulnerabilityScannerNameMap[latestThirdParty.scannerKey] || 'Third Party'
                    : 'N/A'
                }
              </span>
            </label>
            <ScanningStatusIndicator
              timestamp={ thirdPartyLastScanned }
              hasThirdPartyHosts={ isNotEmpty( record?.third_party_hosts ) }
              preformatted={ isEmpty( record.tp_last_scanned ) }
              version="thirdParty"
            />
          </div>
          <div className="bodySectionItem">
            <label>{ window.COMPANY_NAME } Scanning Status</label>
            <ScanningStatusIndicator timestamp={ record?.last_scanned } />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default ScanningStatus;