/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty } from '../../../../../shared/Utilities';
import ComparisonAnalysis from './ComparisonAnalysis';
import ComparisonTag from './ComparisonTag';
import ComparisonCompliance from './ComparisonCompliance';
import EmptyState from '../../../../../shared/EmptyState';

// this is the parent to the 2 different comparison widgets, it was getting too complicated to try to combine
// tag logic and analysis logic into the same component, this just needs to figure out which to use and passes
// off all logic and rendering responsibilities to the child
const Comparison = ( {
  item,
  settings,
  data,
  adjustSVGAspectRatio,
  svgAspectRatio,
  svgContainerRef,
} ) => {

  const [ comparisonVersion, setComparisonVersion ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( item ) && isNotEmpty( settings ) ) {
      // for vuln. instances, check to see what the category_version attr is set to
      // 1. specific_categories && included_categories = analysis
      // 2. tag && asset_tag_ids = tag
      if ( settings.report_type === 'vulnerability_instances' ) {
        if ( settings.category_version === 'specific_categories' && isNotEmpty( settings.included_categories ) ) {
          setComparisonVersion( 'analysis' );
        } else if (  settings.category_version === 'tag' ) {
          setComparisonVersion( 'tag' );
        } else {
          setComparisonVersion( 'analysis' );
        }
      // for host, vuln, patch, check to see if include_tag_breakdown is checked
      // 1. false or missing = analysis
      // 2. true && asset_tag_ids = tag
      } else if (
        settings.report_type === 'hosts'
        || settings.report_type === 'patches'
        || settings.report_type === 'vulnerabilities'
        || settings.report_type === 'risk'
      ) {
        if ( isEmpty( settings.include_tag_breakdown ) || settings.include_tag_breakdown === false ) {
          setComparisonVersion( 'analysis' );
        } else if ( settings.include_tag_breakdown === true ) {
          setComparisonVersion( 'tag' );
        }
      } else if ( settings.report_type === 'compliance' ) {
        setComparisonVersion( 'compliance' );
      }
    }
  }, [ item, settings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( comparisonVersion ) &&
        <React.Fragment>
          {
            comparisonVersion === 'analysis' &&
            <ComparisonAnalysis
              item={item}
              settings={settings}
              data={data}
              adjustSVGAspectRatio={adjustSVGAspectRatio}
              svgAspectRatio={svgAspectRatio}
              svgContainerRef={svgContainerRef}
            />
          }
          {
            comparisonVersion === 'tag' &&
            <ComparisonTag
              item={item}
              settings={settings}
              data={data}
              adjustSVGAspectRatio={adjustSVGAspectRatio}
              svgAspectRatio={svgAspectRatio}
              svgContainerRef={svgContainerRef}
            />
          }
          {
            comparisonVersion === 'compliance' &&
            <ComparisonCompliance
              item={item}
              settings={settings}
              data={data}
              adjustSVGAspectRatio={adjustSVGAspectRatio}
              svgAspectRatio={svgAspectRatio}
              svgContainerRef={svgContainerRef}
            />
          }
        </React.Fragment>
      }
      {
        isEmpty( data ) &&
        <EmptyState message="No comparison data available" />
      }
    </React.Fragment>
  );
};

export default Comparison;