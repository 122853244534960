/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';

import './style.scss';
import {
  capitalize,
  formatNumber,
  formatRiskReduction,
  getDimensionsAndOffset,
  isNotEmpty,
} from '../Utilities';
import ReductionAndRating from './ReductionAndRating';
import InlineSVG from '../InlineSVG';

const RiskExplanation = ( {
  riskClass,
  record,
  recordType,
  riskType='formatted_risk',
  clickEvent,
  show,
  setShow,
} ) => {

  const recordTypeIconKey = {
    host: 'hosts_record',
    patch: 'patches_record',
    vulnerability: 'vulnerabilities_record',
    user: 'users_record',
    path: 'paths_record',
  };

  const [ style, setStyle ] = React.useState( {} );
  const [ description, setDescription ] = React.useState( null );

  const containerRef = React.useRef( null );

  const portalRoot = document.getElementById( 'riskExplanationPortal' );

  // set the correct description based on the record and type
  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( recordType ) && show ) {

      switch ( recordType ) {
      case 'host':
        setDescription(
          <p>
            {/* eslint-disable-next-line max-len */}
            Applying all <strong>{ isNotEmpty( record.num_patches ) ? formatNumber( record.num_patches ) : '' } missing patch(es)</strong> on this host will eliminate <strong>{ isNotEmpty( record.num_vulnerabilities ) ? `${formatNumber( record.num_vulnerabilities )} vulnerability instances` : 'all of this host\'s vulnerability instances' }</strong> in your environment and lower your total global risk by <strong>{ formatRiskReduction( record[riskType] )}</strong>.
          </p>,
        );
        break;
      case 'patch':
        setDescription(
          <p>
            {/* eslint-disable-next-line max-len */}
            Applying this patch to all <strong>{ isNotEmpty( record.num_hosts ) ? formatNumber( record.num_hosts ) : '' } host(s)</strong> where it is currently missing will eliminate <strong>{ isNotEmpty( record.num_hosts ) ? `${formatNumber( record.num_hosts )} vulnerability instances` : 'all of this patch\'s vulnerability instances' }</strong> in your environment and lower your total global risk by <strong>{ formatRiskReduction( record[riskType] )}</strong>.
          </p>,
        );
        break;
      case 'vulnerability':
        setDescription(
          <p>
            {/* eslint-disable-next-line max-len */}
            Patching this vulnerability on all <strong>{ isNotEmpty( record.num_hosts ) ? formatNumber( record.num_hosts ) : '' } host(s)</strong> where it is present will eliminate <strong>{ isNotEmpty( record.num_hosts ) ? `${formatNumber( record.num_hosts )} vulnerability instances` : 'all of this vulnerability\'s instances' }</strong> in your environment and lower your total global risk by <strong>{ formatRiskReduction( record[riskType] )}</strong>.
          </p>,
        );
        break;
      case 'user':
        setDescription(
          <p>
            {/* eslint-disable-next-line max-len */}
            Patching all of the vulnerabilities on all of the critical attack paths that this user is part of will lower your total global risk by <strong>{ formatRiskReduction( record[riskType] )}</strong>.
          </p>,
        );
        break;
      case 'path':
        setDescription(
          <p>
            {/* eslint-disable-next-line max-len */}
            Because of the impact score given to the sensitive asset at the end of this path and the vulnerabilities present, patching all vulnerabilities on this path will lower your total global risk by <strong>{ formatRiskReduction( record[riskType] )}</strong>.
          </p>,
        );
        break;
      default:
        break;
      }
    } else {
      setDescription( null );
    }
  }, [ record, recordType, riskType, show ] );

  // re-position after the content loads
  React.useEffect( () => {
    if (
      isNotEmpty( containerRef )
      && isNotEmpty( containerRef.current )
      && isNotEmpty( record )
    ) {
      let _clickEvent;
      if ( isNotEmpty( record.clickEvent ) ) {
        _clickEvent = record.clickEvent;
      } else if ( isNotEmpty( clickEvent ) ) {
        _clickEvent = clickEvent;
      }

      if ( isNotEmpty( _clickEvent ) ) {

        const width = 24 * 16;

        let left = 'unset';
        let right = 16;
        let top = 16;
        let bottom = 'unset';

        setTimeout( () => {
          const dimensions = getDimensionsAndOffset( containerRef.current );

          if ( isNotEmpty( dimensions ) ) {
            const { height } = dimensions;

            const { pageX, pageY } = _clickEvent;

            left = pageX + 16;
            right = 'unset';
            top = pageY;
            bottom = 'unset';

            // it would flow over the right side of the screen, need to put on the left side instead
            if ( ( left + width ) > window.innerWidth ) {
              left = pageX - 16 - width;

              if ( left < 16 ) {
                left = 16;
              }
            }

            // it would be too low on the screen
            if ( ( top + height ) > window.innerHeight ) {
              top = 'unset';
              bottom = 16;
            }

            setStyle( { top, bottom, left, right, width } );
          }
        }, 100 );
      }
    }
  }, [ record, containerRef, clickEvent, description ] );

  return ReactDOM.createPortal(
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && show ) &&
        <React.Fragment>
          <div className="recordCardContainerShade" onClick={ () => setShow( false ) } />
          <div
            className={ `riskExplanationContainer ${riskClass} ${window.IS_DARK_MODE ? 'darkMode' : ''}` }
            style={ style }
            ref={containerRef}
          >
            <div className="riskExplanationHeader">
              <div className="left">
                {
                  isNotEmpty( recordTypeIconKey[recordType] ) &&
                  <div className={ `recordIconWrapper ${riskClass}` }>
                    <InlineSVG type={ recordTypeIconKey[recordType] } />
                  </div>
                }
                <h2>{ capitalize( recordType ) } Risk Explanation</h2>
              </div>
              <button className="roundGlyphButton light" onClick={ () => setShow( false ) }>
                <InlineSVG type="close" />
              </button>
            </div>
            <ReductionAndRating
              record={record}
              recordType={recordType}
              riskClass={riskClass}
              riskType={riskType}
            />
            { isNotEmpty( description ) && description }
          </div>
        </React.Fragment>
      }
    </React.Fragment>,
    portalRoot,
  );
};

export default RiskExplanation;