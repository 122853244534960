/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import SetupPage from '../../../../shared/SetupComponents/SetupPage';

import {
  CurrentUserContext,
} from '../../../../Contexts/CurrentUser';

import {
  getUserParams,
  recordData,
  refresh,
} from './data';

import {
  isNotEmpty, getExternalUsers,
  decodeURLHash,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';
import { getFieldValues } from '../../../../shared/Form/Shared';
import UserDetail from './UserDetail';

const Users = () => {
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ currentUser, setCurrentUser ] = React.useContext( CurrentUserContext );
  const [ providers, setProviders ] = React.useState( null );
  const [ externalUsers, setExternalUsers ] = React.useState( null );
  const [ activeIntegrations, setActiveIntegrations ] = React.useState( null );

  const [ users, setUsers ] = React.useState( [] );
  const [ showUserDetails, setShowUserDetails ] = React.useState( false );
  const [ fields, setFields ] = React.useState( null );
  const [ responseWithStatus, setResponseWithStatus ] = React.useState( null );

  const handleUsersCallback = ( _externalUsers, _users ) => {
    if ( isNotEmpty( _users ) && isNotEmpty( _externalUsers ) ) {
      _users.map( user => {
        user.externalUser = null;
        // eslint-disable-next-line camelcase
        user.mapped_user_id = '';
      } );

      Object.values( _externalUsers ).map( eu  => {
        if ( isNotEmpty( eu.web_user_id ) ) {
          const user = _users.find( u => u.id === eu.web_user_id );
          if ( isNotEmpty( user ) ) {
            user.externalUser = eu;
            // eslint-disable-next-line camelcase
            user.mapped_user_id = eu.id;
          }
        }
      } );
    }
    setUsers( _users );
    setExternalUsers( _externalUsers );
  };

  const onInit = async () => {
    const hash = decodeURLHash();

    if ( isNotEmpty( hash ) && isNotEmpty( hash.item ) ) {
      setShowUserDetails( true );
    } else {
      refresh().then( response => {
        if ( isNotEmpty( response ) ) {
          if ( isNotEmpty( response.responseDetails ) ) {
            setResponseWithStatus( response.responseDetails );
          }
          setProviders( response.providers );
          setFields( response.fields );
          setActiveIntegrations( response.activeIntegrations );
          getExternalUsers( null, handleUsersCallback, [ response.users ] );
        }
      } );
    }
  };

  // on page load
  React.useEffect( () => {
    onInit();
  }, [] );

  // after updates or edits, etc.
  const onRefresh = () => {
    const hash = decodeURLHash();
    setShowUserDetails( false );
    refresh( isNotEmpty( hash?.item ) ? hash.item : null ).then( response => {
      if ( isNotEmpty( response ) ) {
        if ( isNotEmpty( response.responseDetails ) ) {
          setResponseWithStatus( response.responseDetails );
        }
        setFields( response.fields );
        getExternalUsers( null, handleUsersCallback, [ response.users ] );
      }
    } );
  };

  const onSave = async (
    user,
    isValid,
    fieldStates,
    onSaveCallback,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      const userParams = getUserParams( user, fieldStates );
      let userRequest;

      const includedValues = getFieldValues( fieldStates, 'user' );
      // this is an update
      if ( isNotEmpty( userParams?.id ) ) {

        delete userParams.authentication_provider_id;

        const profileVersion = isNotEmpty( currentUser )
          && currentUser.id === userParams.id;

        if ( profileVersion ) {
          delete userParams.id;
          userRequest = await makeRequest( 'PUT', 'fe/profile/UPDATE', userParams );
        } else {
          userRequest = await makeRequest( 'PUT', '/fe/user/UPDATE', [ userParams ] );
        }

      // this is a create
      } else {
        userRequest = await makeRequest( 'PUT', '/fe/user/INSERT', [ userParams ] );
      }

      // success
      if ( isNotEmpty( userRequest ) ) {
        addFlashMessage( {
          body: 'Successfully saved user',
          type: 'success',
        } );
        if ( user && user.id === currentUser.id ) {
          setCurrentUser( userParams );
        }

        // if the user also mapped to an external user, need to send that request now as well before finishing
        // need to check for it this way because the default value is the null literal and it breaks other checks
        if (
          Object.keys( includedValues ).includes( 'mapped_user_id' )
        ) {

          let externalID;
          let webID;

          const records = [];

          // if we are removing the external mapping, then we need to do some tricky logic to clear it out.
          if ( includedValues.mapped_user_id === null && isNotEmpty( externalUsers ) ) {
            // need to find what the original mapping was and then clear out this user id,
            // (rather than the other way around)
            // eslint-disable-next-line max-len
            const externalUser = Object.values( externalUsers ).find( eu => eu.web_user_id === userRequest.results[0].id );

            if ( isNotEmpty( externalUser ) ) {
              externalID = externalUser.id;
            }
            webID = null;

            if ( isNotEmpty( externalID ) ) {
              // eslint-disable-next-line camelcase
              records.push( { id: externalID, web_user_id: webID } );
            }
          // if we are changing the mapped user, we need to clear out the old mapping and additionally add this mapping
          // again, tricky lookup logic becuase it is backwards
          } else if ( isNotEmpty( user ) && user.mapped_user_id !== includedValues.mapped_user_id ) {
            // need to find what the original mapping was and then clear out this user id,
            // (rather than the other way around)
            // eslint-disable-next-line max-len
            const originalMapping = Object.values( externalUsers ).find( eu => eu.id === user.mapped_user_id );
            // clear out the old mapping
            if ( isNotEmpty( originalMapping ) ) {
              // eslint-disable-next-line camelcase
              records.push( { id: originalMapping.id, web_user_id: null } );
            }
            // create the new mapping
            externalID = includedValues.mapped_user_id;
            webID = userRequest.results[0].id;
            // eslint-disable-next-line camelcase
            records.push( { id: externalID, web_user_id: webID } );
          }

          if ( isNotEmpty( records ) ) {
            const mappedUsersResponse = await makeRequest( 'UPDATE', '/model/base/external_users', { records } );

            if ( isNotEmpty( mappedUsersResponse ) && isNotEmpty( mappedUsersResponse.results ) ) {
              addFlashMessage( {
                type: 'success',
                body: 'Successfully mapped users',
              } );
              onRefresh();
              onSaveCallback();
            } else {
              addFlashMessage( {
                type: 'alert',
                // eslint-disable-next-line max-len
                body: 'There was an error mapping users, please make sure you have entered all of the details correctly',
              } );
            }
          } else {
            onRefresh();
            onSaveCallback();
          }
        } else {
          onRefresh();
          onSaveCallback();
        }
      // there are errors
      } else if ( userRequest.errors ) {
        userRequest.errors.map( e => {
          addFlashMessage( {
            type: 'alert',
            body: e,
          } );
        } );
      // not a correctly formatted response, likely a 500
      } else {
        addFlashMessage( {
          body: 'There was an error saving, please check the form values and try again.',
          type: 'alert',
        } );
      }
    }
  };

  const showDetailSetter = record => {
    if ( isNotEmpty( record ) && isNotEmpty( record.id ) ) {
      setShowUserDetails( true );
      window.location.href = `#.=setup&page=users&item=${record.id}`;
    }
  };

  return (
    <React.Fragment>
      {
        showUserDetails
          ? <UserDetail setShowUserDetails={ setShowUserDetails } refreshAllUsers={onInit} />
          : <React.Fragment>
            {
              isNotEmpty( fields ) &&
              <SetupPage
                onRefresh={onRefresh}
                onSave={onSave}
                records={users}
                setRecords={setUsers}
                recordType="user"
                recordData={recordData}
                useForm={true}
                fields={fields}
                modalClass="twoColumn"
                providers={providers}
                alternateItemLayout
                activeIntegrations={activeIntegrations}
                externalUsers={externalUsers}
                showDetailSetter={showDetailSetter}
                responseWithStatus={responseWithStatus}
                noCreate={ currentUser.acl_role !== 'admin' }
              />
            }
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Users;
