/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/
import React from 'react';

import { decodeURLHash, isNotEmpty, logout, triggerHashRefresh } from '../../shared/Utilities';

import './style.scss';
import InlineSVG from '../../shared/InlineSVG';

import { NavigationContext } from '../../Contexts/Navigation';
import { canConfigure, hasFeatureAccess, hasRouteAccess } from '../App/AccessControl';
import AddToPlanMenu from '../Remediation/AddToPlanMenu';
// import { HelpContext } from '../../Contexts/Help';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import MenuItemV2 from './MenuItemV2';
import { OnboardingWizardContext } from '../../Contexts/OnboardingWizard';
import UserDetails from '../../shared/UserDetails';
import RatingBadge from '../../shared/RatingBadge';

const LeftNav = ( ) => {

  const [ currentRoute, setCurrentRoute ] = React.useState( '' );
  const [ currentPage, setCurrentPage ] = React.useState( '' );
  const [
    expandLeftNavigation,
    setExpandLeftNavigation,
    routing,
  ] = React.useContext( NavigationContext );
  const [ userLink, setUserLink ] = React.useState( null );
  const [ progressClass, setProgressClass ] = React.useState( 'default' );
  const [ showWarningBadge, setShowWarningBadge ] = React.useState( false );
  const [ onboardingProgress, setOnboardingProgress ] = React.useState( 0 );

  const [
    wizardStatus,
    ,
    ,
    ,
    setShowOnboarding,
    ,
    ,
    scanningMode,
    ,
  ] = React.useContext( OnboardingWizardContext );

  const [ showUserMenu, setShowUserMenu ] = React.useState( false );

  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const onboardingProgressClass = ( _progress ) => {
    let _class = 'minimal';

    const percent = Math.round( ( _progress / wizardStatus[scanningMode].length ) * 100 );

    if ( percent <= 75 ) {
      _class = 'low';
    }
    if ( percent <= 50 ) {
      _class = 'moderate';
    }
    if ( percent <= 25 ) {
      _class = 'high';
    }
    return _class;
  };

  const setupUserAndOptions = async () => {

    if ( isNotEmpty( currentUser ) ) {
      setUserLink( `#.=setup&page=users&item=${currentUser.id}` );
    }

    if ( isNotEmpty( scanningMode ) && isNotEmpty( wizardStatus ) ) {
      // onboarding wizard
      let _progress = 0;

      wizardStatus[scanningMode].map( s => {
        if ( s.status === 'configured' ) {
          _progress += 1;
        }
      } );

      const _progressClass = onboardingProgressClass( _progress );

      setOnboardingProgress( _progress );
      setProgressClass( _progressClass );

      const totalSteps = scanningMode === 'agent' || scanningMode === 'user_managed' ? 5 : 6;

      if ( _progress >= totalSteps ) {
        setShowWarningBadge( false );
      } else {
        setShowWarningBadge( true );
      }
    }
  };

  const refreshRouteData = () => {
    const pageParams = decodeURLHash();

    setCurrentRoute( pageParams['.'] );
    if ( pageParams['page'] || pageParams['report'] ) {
      setCurrentPage( pageParams['page'] || pageParams['report'] );
    } else {
      setCurrentPage( '' );
    }
  };

  React.useEffect( () => {
    refreshRouteData();
    window.addEventListener( 'hashchange', refreshRouteData );
    return () => {
      window.removeEventListener( 'hashchange', refreshRouteData );
    };
  }, [] );

  React.useEffect( () => {
    setupUserAndOptions();
  }, [ currentUser, wizardStatus, scanningMode ] );

  const handleLogout = () => {
    if ( confirm( 'Are you sure you want to logout of DeepSurface?' ) ) {
      logout();
    }
  };

  const handleProfileClick = () => {
    window.location.href = userLink;
    triggerHashRefresh();
  };

  return (
    <React.Fragment>
      <div
        // onMouseEnter={ () => setHoveringOverLeftNav( true ) }
        // onMouseLeave={ () => setHoveringOverLeftNav( false ) }
        id="navigationMenuWrapper" className={ expandLeftNavigation ? 'expanded' : '' }>
        <div
          id="navigationMenu"
        >
          <div className="leftNavUpper">
            <div className="logoContainer">
              <a href="#.=reporting&page=reporting_dashboard">
                <span>
                  <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" />
                  {
                    !canConfigure( licenseInfo )
                      ? <InlineSVG
                        type="primaryLogoTextDemo"
                        version="bug"
                        size="logoTextDemo"
                        elementClass={ `logoText ${!canConfigure( licenseInfo ) ? 'demoVersion' : ''}`}
                      />
                      : <InlineSVG
                        type="primaryLogoText"
                        version="bug"
                        size="logoText"
                        elementClass={ `logoText ${!canConfigure( licenseInfo ) ? 'demoVersion' : ''}`}
                      />
                  }
                </span>
              </a>
            </div>
            <div id="menuItems">
              {
                Object.values( routing ).map( ( routeInfo, index ) => {
                  if (
                    !routeInfo.lower
                    && !routeInfo.settings
                    && hasRouteAccess( currentUser, licenseInfo, routeInfo?.slug )
                  ) {
                    return  <MenuItemV2
                      item={routeInfo}
                      key={index}
                      currentRoute={currentRoute}
                      currentPage={currentPage}
                    />;
                  }
                } )
              }
            </div>
          </div>
          <div className="leftNavLower">

            <div id="lowerItems">
              {
                !canConfigure( licenseInfo )
                  ? <span
                    className="logoutButton"
                    // eslint-disable-next-line camelcase
                    onClick={ handleLogout }
                    title="Logout"
                  >
                    <InlineSVG type="logout" />
                  </span>
                  : <React.Fragment>
                    <div className="lowerItemWrapper userMenu" onClick={ () => setShowUserMenu( !showUserMenu ) }>
                      <UserDetails user={currentUser} forLeftNav elementClass="allowMenuHover" />
                      {
                        showWarningBadge &&
                        <span className={ `onboardingWarning ${progressClass}`} />
                      }
                      {
                        showUserMenu &&
                        <React.Fragment>
                          <div className="userMenuShade" onClick={ () => setShowUserMenu( false ) } />
                          <ul className="userMenuWrapper">
                            <li onClick={ () => setShowUserMenu( false ) } >
                              <span className="notLink" onClick={ handleProfileClick }>
                                Profile
                              </span>
                            </li>
                            {
                              hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' ) &&
                              <li onClick={ () => setShowUserMenu( false ) } >
                                <span className="notLink" onClick={ () => setShowOnboarding( true ) }>
                                  Onboarding
                                  {
                                    (
                                      isNotEmpty( wizardStatus )
                                      && isNotEmpty( scanningMode )
                                      && isNotEmpty( wizardStatus[scanningMode] )
                                    ) &&
                                    <RatingBadge
                                      // eslint-disable-next-line max-len
                                      alternateText={ `Step ${onboardingProgress} of ${wizardStatus[scanningMode].length}` }
                                      rating={ onboardingProgressClass( onboardingProgress ) }
                                      elementClass="wizardStatusProgress allowMenuHover"
                                    />
                                  }
                                </span>
                              </li>
                            }
                            {
                              hasRouteAccess( currentUser, licenseInfo, 'about' ) &&
                              <li onClick={ () => setShowUserMenu( false ) } >
                                <a href="#.=about">
                                  About
                                </a>
                              </li>
                            }
                            <li onClick={ () => setShowUserMenu( false ) } >
                              <span
                                className="notLink"
                                // eslint-disable-next-line camelcase
                                onClick={ handleLogout }
                              >
                                Log Out
                              </span>
                            </li>
                          </ul>
                        </React.Fragment>
                      }
                    </div>
                    <button className="expandButton" onClick={ () => setExpandLeftNavigation( !expandLeftNavigation ) }>
                      {
                        expandLeftNavigation
                          ? <InlineSVG type="slidein" />
                          : <InlineSVG type="slideout" />
                      }
                    </button>
                    {
                      (
                        isNotEmpty( licenseInfo )
                        && hasFeatureAccess( currentUser, licenseInfo, 'f_remediation' )
                      ) &&
                      <div className="lowerItemWrapper addToPlan">
                        <AddToPlanMenu
                          planOptions={ [ 'remediation' ] }
                          elementClass="leftNavAddToPlanMenu"
                          positionOverride={ { bottom: '1em', left: '6em' } }
                        />
                      </div>
                    }
                  </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeftNav;