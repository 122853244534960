/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// Main component for the application:
// - Sets up the initial state of the application
// - Handles hash changes
// - Handles beforeunload events
// - Handles errors
// - Renders the TopBar, LeftNav, Content, and FullScreenVisual components
// - Wraps the application in an ErrorBoundary component

import React from 'react';
import ErrorStackParser from 'error-stack-parser';

import Content from './Content';
import OnboardingWizard from '../OnboardingWizard';
import ContextProviderWrapper from './ContextProviderWrapper';

// eslint-disable-next-line camelcase
import defaultTheme from '../../../../../../share/webroot/themes/attackiq_dark/theme.json';

import { ErrorBoundary } from 'react-error-boundary';

import {
  decodeURLHash,
  getGlobalSettings,
  isEmpty,
  isNotEmpty,
  itemIsFunction,
  tintOrShadeHex,
  // tintOrShadeHex,
  useLocalStorage,
} from '../../shared/Utilities';

import './Scanning.scss';
import './Reporting.scss';
import { makeRequest } from '../../../legacy/io';
import ErrorFallback from './Error';
import TopBar from '../Navigation/TopBar';
import LeftNav from '../Navigation/LeftNav';
import FullScreenVisual from '../../shared/FullScreenVisual';
import Login from './Login';
import {
  asRGBTriplet,
  nonColorKeys,
  tintedVariantPrefixes,
  tintedVariantSuffixes,
} from '../../shared/Themes';
import Loading from '../../shared/Loading';

const App = () => {

  const [ currentLocation, setCurrentLocation ] = React.useState( { } );
  const [ syncHelpURL, setSyncHelpURL ] = React.useState( null );
  const [ hoveringOverLeftNav, setHoveringOverLeftNav ] = React.useState( false );

  const [ globalsFetched, setGlobalsFetched ] = React.useState( false );
  const [ waitedForTheme, setWaitedForTheme ] = React.useState( false );
  const [ needsLogin, setNeedsLogin ] = React.useState( true );
  const [ sid, setSid ]= useLocalStorage( 'sid', 'asdf' );
  const [ expiration, setExpiration ] = useLocalStorage( 'sid_expiration', 'asdf' );

  const setupTheme = async () => {
    let logoImageLargeContent = '';
    let logoImageSmallContent = '';
    let logoTextContent = '';
    let attempts = 0;

    const logoImageLargePath = '/themes/attackiq_dark/logoImageLarge.svg';
    const logoImageSmallPath = '/themes/attackiq_dark/logoImageSmall.svg';
    const logoTextPath = '/themes/attackiq_dark/logoText.svg';
    const rootStyles = {};

    const root = document.documentElement;

    const maxAttempts = 10;

    const logoImageLargeFetch = await fetch( logoImageLargePath );
    const logoImageSmallFetch = await fetch( logoImageSmallPath );
    const logoTextFetch = await fetch( logoTextPath );
    if ( logoImageLargeFetch ) {
      logoImageLargeContent = await logoImageLargeFetch.text();
    }
    if ( logoImageSmallFetch  ) {
      logoImageSmallContent = await logoImageSmallFetch.text();
    }
    if ( logoTextFetch ) {
      logoTextContent = await logoTextFetch.text();
    }

    const themeInterval = setInterval( async () => {
      attempts++;

      if ( isNotEmpty( window.SELECTED_THEME ) ) {
        clearInterval( themeInterval );
        setWaitedForTheme( true );
      } else if ( attempts >= maxAttempts ) {
        if ( isNotEmpty( defaultTheme ) ) {

          // set all the CSS variables
          if ( isNotEmpty( defaultTheme ) ) {
            Object.entries( defaultTheme ).map( ( [ property, value ] ) => {
              // special logic for tinted or shaded variants
              const splitPropertyKey = property.split( '--' );

              const [ , baseKey ] = splitPropertyKey;

              // if the colorKey needs a tinted variant, calculate the tints and create them,
              if ( isNotEmpty( baseKey ) && tintedVariantPrefixes.includes( baseKey ) ) {
                // first create the base property
                rootStyles[`--${baseKey}`] = asRGBTriplet( value );

                // map over all the tint variantns and create them
                tintedVariantSuffixes.map( ( suffix ) => {
                  const tintValue = 1 - ( parseInt( suffix, 10 ) / 100 );
                  const newProperty = `--${baseKey}--${suffix}`;
                  const newPropertyValue = tintOrShadeHex( value, tintValue, 'tint', true );
                  rootStyles[newProperty] = newPropertyValue;
                } );
              // just set the property directly
              } else if ( nonColorKeys.includes( property ) ) {
                rootStyles[property] = value;
              } else {
                rootStyles[property] = asRGBTriplet( value );
              }
            } );

            Object.entries( rootStyles ).map( ( [ property, value ] ) => {
              root.style.setProperty( property, value );
            } );
          }
          window.COMPANY_NAME = 'AttackIQ';
          window.PRODUCT_NAME = 'RiskAnalyzer';
          window.SELECTED_THEME = 'attackiq_dark';
          window.LOGO_IMAGE_LARGE = logoImageLargeContent;
          window.LOGO_IMAGE_SMALL = logoImageSmallContent;
          window.LOGO_TEXT = logoTextContent;
          window.LOGO_IMAGE_LARGE_PATH = logoImageLargePath;
          window.LOGO_IMAGE_SMALL_PATH = logoImageSmallPath;
          window.LOGO_TEXT_PATH = logoTextPath;
          window.THEME_VARIABLES = defaultTheme;
          window.IS_DARK_MODE = true;
        }

        setWaitedForTheme( true );
        clearInterval( themeInterval );
      }
    }, 100 );

    return () => clearInterval( themeInterval );
  };

  // need to poll to see if the theme has been set, look for document.SELECTED_THEME, if is there, allow
  // the application to render, if not, poll again
  React.useEffect( () => {
    setupTheme();
  }, [] );

  // main hash change handler for setting the current location
  const handleHashChange = () => {
    const hash = decodeURLHash();
    const route = hash['.'];
    const { page, report } = hash;

    if ( isEmpty( route ) ) {
      window.location.href = '#.=reporting&page=reporting_dashboard';
    } else {
      setCurrentLocation( { route, page, report } );
    }
    const pageContentElement = document.getElementById( 'pageContent' );

    if (
      isNotEmpty( pageContentElement )
      && isNotEmpty( pageContentElement.onleave )
      && itemIsFunction( pageContentElement.onleave )
    ) {
      handleOnLeave( pageContentElement );
    }
  };

  // some forms have onleave functions that need to be called when the user navigates away from a page
  const handleOnLeave = async ( element ) => {
    if (
      isNotEmpty( element )
      && isNotEmpty( element.onleave )
      && itemIsFunction( element.onleave )
    ) {
      await element.onleave();
    }
    return undefined;
  };

  // handle the beforeunload event
  const handleBeforeUnload = async () => {
    const pageContentElement = document.getElementById( 'pageContent' );

    if (
      isNotEmpty( pageContentElement )
      && isNotEmpty( pageContentElement.onleave )
      && itemIsFunction( pageContentElement.onleave )
    ) {
      handleOnLeave( pageContentElement );
    }
    return undefined;
  };

  // If the FE encounters an error, this function will be called and passed to the ErrorBoundary component
  const handleError = ( errorEvent ) => {
    if ( isNotEmpty( errorEvent ) ) {
      const errorObject = new Error( errorEvent.message );
      const stackFrames = ErrorStackParser.parse( errorEvent );
      let stackFramesString = '';

      const { message, name } = errorEvent;

      const errorParams = {
        error: {
          message: message || '',
        },
      };

      if ( isNotEmpty( stackFrames ) ) {
        const stackFrameStrings = [];
        stackFrames.forEach( ( frame ) => {
          // create a string that concatenates the stack frame properties
          // eslint-disable-next-line max-len
          let stackFrameAsString = `${frame.fileName}:${frame.lineNumber}:${frame.columnNumber}`;

          if ( isNotEmpty( frame.functionName ) ) {
            stackFrameAsString = `${stackFrameAsString} ${frame.functionName}`;
          }
          stackFrameAsString = `${stackFrameAsString}  ${frame.source}`;
          stackFrameStrings.push( stackFrameAsString );
        } );

        stackFramesString = stackFrameStrings.join( '\n' );
      }

      if (
        isNotEmpty( errorParams.error )
        && isNotEmpty( errorObject )
        && isNotEmpty( stackFramesString )
      ) {
        errorParams.error.name = name;
        errorParams.error.location = window.location.href;
        errorParams.error.stack = stackFramesString;
      }

      makeRequest( 'ERROR', '/front_end_reports', errorParams );
    }
  };

  // need to fetch the global settings and among other things, set the theme, before rendering the application
  React.useEffect( () => {
    if ( !needsLogin && waitedForTheme ) {
      getGlobalSettings( 'global' ).then( response => {
        window.globalSettings = response;

        setGlobalsFetched( true );
      } );

      // global recordCache var
      if ( !window.recordCache ) {
        window.recordCache = new Map();
      }

      // global dashboard recordCache var
      if ( !window.dashboardCache ) {
        window.dashboardCache = {};
      }
      // global flashMessageQueue var
      if ( !window.flashMessageQueue ) {
        window.flashMessageQueue = new Map();
      }
      window.CACHE_CAPACITY = 10_000;
      window.DEFAULT_FLASH_MESSAGE_DURATION = 5_000;
      window.FLASH_MESSAGE_CAPACITY = 4;
    }
  }, [ needsLogin, waitedForTheme ] );

  // initial load of the application, need to setup a few things
  // 1. get the help url for clock sync
  // 2. setup the recordCache, flashMessageQueue, capacity, and duration globals
  // 4. setup hashChange listener
  React.useEffect( () => {
    if ( globalsFetched && waitedForTheme ) {
      setSyncHelpURL( '#.=help_documentation&main=reference_guide&section=other_reference_items&help_page=clock_sync' );

      const hash = decodeURLHash();
      if ( isEmpty( hash['.'] ) ) {
        window.location.href = '#.=reporting&page=reporting_dashboard';
      }
      handleHashChange();
      window.addEventListener( 'hashchange', handleHashChange );
      window.addEventListener( 'beforeunload', handleBeforeUnload );
      return () => {
        window.removeEventListener( 'hashchange', handleHashChange );
        window.removeEventListener( 'beforeunload', handleBeforeUnload );
        window.dashboardCache = {};
      };
    }
  }, [ globalsFetched, waitedForTheme ] );

  const pageClassName = () => {
    if ( isNotEmpty( currentLocation.report ) ) {
      return currentLocation.report;
    } else if ( isNotEmpty( currentLocation.page ) ) {
      return currentLocation.page;
    }
    return 'default';
  };

  React.useEffect( () => {
    const hash = decodeURLHash();
    if ( isEmpty( sid ) ) {
      setNeedsLogin( true );
      window.location.href = '#.=login';
    } else if ( hash['.'] === 'login' ) {
      setNeedsLogin( true );
      window.location.href = '#.=login';
    } else {
      setNeedsLogin( false );
    }
  }, [ sid ] );

  return (
    <ErrorBoundary
      FallbackComponent={ ErrorFallback }
      onError={ handleError }
    >
      <ContextProviderWrapper>
        {
          waitedForTheme
            ? <React.Fragment>
              {
                needsLogin
                  ? <Login sid={ sid } setSid={ setSid } expiration={expiration} setExpiration={setExpiration} />
                  : <React.Fragment>
                    {
                      isNotEmpty( currentLocation ) &&
                      <React.Fragment>
                        <OnboardingWizard />
                        <TopBar
                          currentLocation={currentLocation}
                          hoveringOverLeftNav={hoveringOverLeftNav}
                          setHoveringOverLeftNav={setHoveringOverLeftNav}
                        />
                        <LeftNav
                          hoveringOverLeftNav={hoveringOverLeftNav}
                          setHoveringOverLeftNav={setHoveringOverLeftNav}
                        />
                        <Content
                          currentLocation={currentLocation}
                          pageClassName={ pageClassName }
                          syncHelpURL={syncHelpURL}
                        />
                        <FullScreenVisual />
                      </React.Fragment>
                    }
                  </React.Fragment>
              }
            </React.Fragment>
            : <Loading />
        }
      </ContextProviderWrapper>
    </ErrorBoundary>
  );
};

export default App;