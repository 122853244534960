/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// The applied filters show up as pills so that user can see what filters have been applied for a given report,
// and remove them if needed. This component is used in conjunction with the FilterBuilder component.
// future functionality will allow the user to edit an existing applied filter, --DMC 2024-12-16

import React from 'react';
import {
  isNotEmpty,
} from '../../../../shared/Utilities';
import AppliedFilter from './AppliedFilter';
import {
  excludedTagFilterAttributes,
  getFilterKeyFromMatchString,
  includedTagFilterAttributes,
} from '../shared';
import InlineSVG from '../../../../shared/InlineSVG';
import { TagsContext } from '../../../../Contexts/Tags';
import {
  transformHashToFilters,
} from '../../../../shared/RecordCache';

import './style.scss';

const AppliedFilters = ( {
  appliedFilters,
  setAppliedFilters,
  recordType,
  variant,
  appliedFiltersFromRecord,
  removeAppliedFilter,
  onRefresh,
  externalChangeHandler,
} ) => {

  const [ tags ] = React.useContext( TagsContext );
  const [ orderedFilters, setOrderedFilters ] = React.useState( [] );

  // on mount, get all the filter options, build applied filters from url hash
  React.useEffect( () => {
    const _appliedFilters = transformHashToFilters();
    console.log( 'AppliedFilters: ', _appliedFilters );
    setAppliedFilters( _appliedFilters );
  }, [ tags, recordType ] );

  React.useEffect( () => {
    if ( isNotEmpty( appliedFiltersFromRecord ) ) {
      setAppliedFilters( appliedFiltersFromRecord );
    }
  }, [ appliedFiltersFromRecord ] );

  const clearAllFilters = () => {
    const _appliedFilters = [];

    if ( isNotEmpty( appliedFilters ) ) {
      // eslint-disable-next-line max-len

      if ( isNotEmpty( appliedFilters ) ) {
        appliedFilters.map( f => removeAppliedFilter( f, false ) );
      }
    }
    setAppliedFilters( _appliedFilters );

    if ( isNotEmpty( onRefresh ) ) {
      onRefresh();
    }
    if ( isNotEmpty( externalChangeHandler ) ) {
      externalChangeHandler();
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( variant ) && variant === 'tagEditor' && isNotEmpty( appliedFilters ) ) {
      // need to put the include filters first always
      const _includeFilters = [];
      const _excludeFilters = [];

      appliedFilters.map( filter => {
        const  filterAttribute = getFilterKeyFromMatchString( filter.attribute, variant, recordType );
        if ( includedTagFilterAttributes.includes( filterAttribute ) ) {
          _includeFilters.push( filter );
        } else if ( excludedTagFilterAttributes.includes( filterAttribute ) ) {
          _excludeFilters.push( filter );
        }
      } );
      const _orderedFilters = [ ..._includeFilters, ..._excludeFilters ];
      setOrderedFilters( _orderedFilters );
    } else if ( isNotEmpty( appliedFilters ) ) {
      setOrderedFilters( appliedFilters );
    } else {
      setOrderedFilters( [] );
    }
  }, [ appliedFilters, variant ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( orderedFilters ) &&
        <div className="appliedFiltersWrapper">
          {
            orderedFilters.map( ( filter, index ) => {
              return <AppliedFilter
                key={ index }
                filter={ filter }
                removeAppliedFilter={removeAppliedFilter}
                variant={ variant }
                recordType={ recordType }
              />;
            } )
          }
          <button
            className="clearAllFiltersButton roundGlyphButton dark"
            onClick={clearAllFilters}
          >
            <InlineSVG type="delete" />
          </button>
        </div>
      }
    </React.Fragment>
  );
};

export default AppliedFilters;