/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import DataTable from '../../../../shared/DataTable';
import EmptyLoading from '../../../../shared/EmptyLoading';
import { VALIDATION_REGEXS } from '../../../../shared/Form/Validators';
import InlineSVG from '../../../../shared/InlineSVG';
import {
  integrationInstanceName,
  isEmpty,
  isNotEmpty,
  riskToRating,
  userDisplayName,
} from '../../../../shared/Utilities';
import Dropdown from '../../../../shared/Dropdown';
import { taskIcon } from '../EditModal/RemediationSteps/TaskItem';

const ModalBody = ( {
  tasks,
  users,
  activeIntegrations,
  externalUsers,
  taskMappings,
  setTaskMappings,
  selectedTaskIDs,
  setSelectedTaskIDs,
  show,
  loadingTasks,
} ) => {

  const [ integrationOptions, setIntegrationOptions ] = React.useState( {
    'null': { label: 'Email Owner Directly', logo: <InlineSVG type="mail" /> },
  } );
  const [ ownerOptions, setOwnerOptions ] = React.useState( {} );
  const [ externalUserOptions, setExternalUserOptions ] = React.useState( {} );

  // ones that have not been exported already, visible by default
  const [ availableTasks, setAvailableTasks ] = React.useState( [] );
  // ones that have already been exported, hidden by default
  const [ exportedTasks, setExportedTasks ] = React.useState( [] );
  // the current tasks the user can see, only the available by default, but can also be available and exported
  // when toggled
  const [ visibleTasks, setVisibleTasks ] = React.useState( [] );

  // toggle to see previously exported tasks or not, the will show up at the bottom
  const [ viewExported, setViewExported ] = React.useState( false );

  // 1) onload once the tasks are present, setup the mappings
  React.useEffect( ( ) => {

    const getEmailAddress = task => {
      // get the email address from the integration first ( email type only )
      if (
        isNotEmpty( task )
        && isNotEmpty( task.preferredIntegration )
        && isNotEmpty( task.preferredIntegration.destination_email_address )
      ) {
        return task.preferredIntegration.destination_email_address;
      }
      // get the email from the user next
      if ( isNotEmpty( task.ownerUser ) && isNotEmpty( task.ownerUser.email_address ) ) {
        return task.ownerUser.email_address;
      }
      return '';
    };

    const getIntegrationID = task => {
      if ( isNotEmpty( task.preferredIntegration ) ) {
        return task.preferredIntegration.id;
      }
      if ( isNotEmpty( task.ownerUser ) ) {
        if ( isNotEmpty( task.ownerUser.remediation_export_setting ) ) {
          return task.ownerUser.remediation_export_setting;
        }
        return 'null';
      }
      return '';
    };

    const _taskMappings = {};
    const defaultSelected = [];
    if (
      isNotEmpty( tasks )
    ) {
      tasks.map( task => {
        _taskMappings[task.id] = {
          ownerID: isNotEmpty( task.ownerUser ) ? task.ownerUser.id : null,
          externalUserID: isNotEmpty( task.externalUser ) ? task.externalUser.id : null,
          integrationID: getIntegrationID( task ),
          integrationType: isNotEmpty( task.preferredIntegration ) ? task.preferredIntegration.tool : null,
          emailAddress: getEmailAddress( task ),
        };
        if ( !task.previouslyExported ) {
          defaultSelected.push( task.id );
        }
      } );
    }
    setSelectedTaskIDs( defaultSelected );
    setTaskMappings( _taskMappings );
  }, [ tasks ] );

  // 2) any time the mappings change, setup the table again.
  React.useEffect( ( ) => {
    const exported = [];
    const available = [];
    if ( isNotEmpty( tasks ) ) {
      tasks.map( task => {
        if ( task.previouslyExported ) {
          exported.push( transformRowData( task ) );
        } else {
          available.push( transformRowData( task ) );
        }
      } );
    }
    setAvailableTasks( available );
    setExportedTasks( exported );
    setVisibleTasks( available );
  }, [ taskMappings ] );

  // on init, happens only once, sets up the options for the select integration select
  React.useEffect( ( ) => {
    const _integrationOptions = {
      'null': { label: 'Email Owner Directly', logo: <InlineSVG type="mail" /> },
    };

    if ( isNotEmpty( activeIntegrations ) ) {
      Object.values( activeIntegrations ).map( intGroup => {
        intGroup.map( int => {

          let logo;

          if ( int.tool === 'jira' ) {
            logo = <InlineSVG type="jiraLogo" version="special" />;
          } else if ( int.tool === 'tanium' ) {
            logo = <InlineSVG type="taniumLogo" version="special" />;
          } else {
            logo = <InlineSVG type="mail" />;
          }
          _integrationOptions[int.id ] = {
            label: integrationInstanceName( int, true ),
            logo,
          };

        } );
      } );
    }
    setIntegrationOptions( _integrationOptions );
  }, [ activeIntegrations ] );

  // on init, happens only once, sets up the options for the select owner select
  React.useEffect( ( ) => {
    const options = { 'null': 'Unassigned' };
    if ( isNotEmpty( users ) ) {
      Object.values( users ).map( user => {
        options[user.id] = userDisplayName( user );
      } );
    }
    setOwnerOptions( options );
  }, [ users ] );

  // on init, happens only once, sets up the options for the select external user select
  React.useEffect( ( ) => {
    const options = { 'null': 'Unassigned' };
    if ( isNotEmpty( externalUsers ) ) {
      Object.values( externalUsers ).map( user => {
        options[user.id] = userDisplayName( user, false, 'jira' );
      } );
    }
    setExternalUserOptions( options );
  }, [ externalUsers ] );

  // whatcher whenever the view exported toggle is flipped, if true, the visible tasks will be set to all, with
  // the exported at the bottom
  React.useEffect( ( ) => {
    let _visible = [];
    if ( viewExported ) {
      if ( isNotEmpty( availableTasks ) ) {
        _visible = availableTasks;
      }
      if ( isNotEmpty( exportedTasks ) ) {
        _visible = [ ...availableTasks, ...exportedTasks ];
      }
    } else if ( isNotEmpty( availableTasks ) ) {
      _visible = availableTasks;
    } else {
      _visible = [];
    }
    setVisibleTasks( _visible );
  }, [ viewExported, availableTasks, exportedTasks ] );

  // sentence that describes what the task is
  const taskActionSentence = task => {
    let _text = '';
    if ( task.original ) {
      if ( task.original.type === 'host_patches' ) {
        _text = `Apply ${task.original.num_items} ${task.original.type.split( '_' )[1]}`;
      }
      if ( task.original.type === 'patch_hosts' ) {
        _text = `Apply to ${task.original.num_items} ${task.original.type.split( '_' )[1]}`;
      }
      if ( task.original.type === 'host_vulnerabilities' ) {
        _text = `Fix ${task.original.num_items} ${task.original.type.split( '_' )[1]}`;
      }
      if ( task.original.type === 'vulnerability_hosts' ) {
        _text = `Fix on ${task.original.num_items} ${task.original.type.split( '_' )[1]}`;
      }
    }
    return _text;
  };

  // when the modal closes, clear it all out
  React.useEffect( ( ) => {
    if ( show === false ) {
      setAvailableTasks( [] );
      setExportedTasks( [] );
      setVisibleTasks( [] );
      setViewExported( false );
      setTaskMappings( {} );
    }
  }, [ show ] );

  // callback for when the checkbox for a row is checked
  const onSelect = id => {
    if ( selectedTaskIDs.includes( id ) ) {
      setSelectedTaskIDs( selectedTaskIDs.filter( i => i !== id ) );
    } else {
      setSelectedTaskIDs( [ ...selectedTaskIDs, id ] );
    }
  };

  // callbacks for the change of each field in the table
  // when the owner changes, change/add the owner, and reset any associated values (integration, esternalUser)
  const handleOwnerChange = ( task, value ) => {

    let externalUserID = null;
    let integrationID = null;
    let integrationType = null;
    let emailAddress = null;

    const ownerUser = users[value];

    if ( isNotEmpty( ownerUser ) && isNotEmpty( ownerUser.email_address ) ) {
      emailAddress = ownerUser.email_address;
    }

    // find the preferred integration if there is one
    if (
      isNotEmpty( ownerUser )
      && isNotEmpty( activeIntegrations )
    ) {

      if ( isNotEmpty( ownerUser.remediation_export_setting ) ) {
        let preferred = activeIntegrations?.jira?.find( i => i.id === ownerUser.remediation_export_setting );

        if ( isEmpty( preferred ) ) {
          preferred = activeIntegrations?.email?.find( i => i.id === ownerUser.remediation_export_setting );
        }

        // overwrite email address with the integration one
        if ( isNotEmpty( preferred ) && isNotEmpty( preferred.destination_email_address ) ) {
          emailAddress = preferred.destination_email_address;
        }
        integrationID = preferred?.id || '';
        integrationType = preferred?.tool || null;
      } else {
        integrationID = 'null';
        integrationType = null;
      }
    } else {
      integrationID = '';
      integrationType = null;
    }

    // find the external user if there is one
    if ( isNotEmpty( value ) && isNotEmpty( externalUsers ) ) {
      const external = Object.values( externalUsers ).find( e => e.web_user_id === value );
      externalUserID = external?.id || null;
    }

    setTaskMappings( {
      ...taskMappings,
      [task.id]: {
        integrationType,
        externalUserID,
        integrationID,
        emailAddress,
        ownerID: value,
      },
    } );
  };

  // when the integration changes, clear out the mapped user if it is a different integration, otherwise, fetch
  // that mapped user, keep the owner the same
  const handleIntegrationChange = ( task, value ) => {

    let externalUserID = null;
    let integrationType = null;
    let emailAddress = null;
    let ownerUser = null;

    if (
      isNotEmpty( taskMappings )
      && isNotEmpty( taskMappings[task.id] )
      && isNotEmpty( taskMappings[task.id].ownerID )
      && isNotEmpty( users )
    ) {
      ownerUser = users[taskMappings[task.id].ownerID];
    }

    if ( isNotEmpty( ownerUser ) && isNotEmpty( ownerUser.email_address ) ) {
      emailAddress = ownerUser.email_address;
    }


    // determine if this is an integration change or not, if it is new, clear the mapped user, if it is not, retain
    if (
      isNotEmpty( task )
      && isNotEmpty( task.preferredIntegration )
      && task.preferredIntegration.id === value
      && isNotEmpty( ownerUser )
      && isNotEmpty( externalUsers )
    ) {
      const external = Object.values( externalUsers ).find( e => e.web_user_id === ownerUser.id );
      externalUserID = external?.id || null;
    }

    // if this is an email integration, overwrite the emailAddress from the user
    if (
      isNotEmpty( activeIntegrations )
      && isNotEmpty( activeIntegrations.email )
    ) {
      const integration = activeIntegrations.email.find( i => i.id === value );
      if ( isNotEmpty( integration ) && isNotEmpty( integration.destination_email_address ) ) {
        emailAddress = integration.destination_email_address;
        integrationType = integration.tool;
      }
    }

    // if this is a jira integration, overwrite the emailAddress from the user
    if (
      isNotEmpty( activeIntegrations )
      && isNotEmpty( activeIntegrations.jira )
    ) {
      const integration = activeIntegrations?.jira?.find( i => i.id === value );
      if ( isNotEmpty( integration ) ) {
        integrationType = integration.tool;
      }
    }

    // if this is a tanium integration, overwrite the emailAddress from the user
    if (
      isNotEmpty( activeIntegrations )
      && isNotEmpty( activeIntegrations.tanium )
    ) {
      const integration = activeIntegrations?.tanium?.find( i => i.id === value );
      if ( isNotEmpty( integration ) ) {
        integrationType = integration.tool;
      }
    }

    setTaskMappings( {
      ...taskMappings,
      [task.id]: {
        ...taskMappings[task.id],
        integrationType,
        externalUserID,
        emailAddress,
        integrationID: value,
      },
    } );
  };

  // when the email changes just change the emailAddress
  const handleEmailChange = ( task, value ) => {
    setTaskMappings( {
      ...taskMappings,
      [task.id]: {
        ...taskMappings[task.id],
        emailAddress: value,
      },
    } );
  };

  // when the mapping changes, just change the mappedUser
  const handleMappingChange = ( task, value ) => {
    setTaskMappings( {
      ...taskMappings,
      [task.id]: {
        ...taskMappings[task.id],
        externalUserID: value,
      },
    } );
  };

  // the contents for the owner cell of the table, a select field
  const ownerCellContent = task => {

    const integration = taskMappings[task.id]?.integrationID;
    const taniumIDs = activeIntegrations?.tanium?.map( integration => integration.id );
    // eslint-disable-next-line max-len
    const hasTaniumIntegration = isNotEmpty( integration ) && isNotEmpty( taniumIDs ) && taniumIDs.includes( integration );

    const defaultValue = taskMappings[task.id]?.ownerID || 'null';

    return (
      <div className="selectFieldWrapper">
        {
          hasTaniumIntegration
            ?  <span className="noOptions">N/A</span>
            : <select
              value={defaultValue}
              onChange={ e => handleOwnerChange( task, e.target.value ) }
            >
              {
                isNotEmpty( ownerOptions ) &&
                Object.entries( ownerOptions ).map( ( [ value, label ], index ) => {
                  return <option key={index} value={value}>{ label }</option>;
                } )
              }
            </select>
        }
      </div>
    );

  };

  // the contents of the integration cell of the table, a select field.
  // a few considerations:
  // 1. DIRECT will not be an option if there is no owner
  // 2. This will be pre-selected to the owner's preferred method (if any)
  const integrationCellContent = task => {
    const selectedIntegrationID = taskMappings[task.id]?.integrationID || '';

    let selectedIntegrationObject = integrationOptions['null'];

    if ( isNotEmpty( selectedIntegrationID ) && isNotEmpty( integrationOptions ) ) {
      selectedIntegrationObject = integrationOptions[selectedIntegrationID];
    }

    if ( isEmpty( selectedIntegrationObject ) ) {
      selectedIntegrationObject = {
        logo: <React.Fragment></React.Fragment>,
        label: 'Select an integration...',
      };
    }

    const allowDirect = isNotEmpty( taskMappings[task.id]?.ownerID ) && taskMappings[task.id]?.ownerID !== 'null';
    const taniumIDs = activeIntegrations?.tanium?.map( integration => integration.id );

    const options = [];

    if ( isNotEmpty( integrationOptions ) ) {
      Object.entries( integrationOptions ).map( ( [ key, intObj ] ) => {

        if ( allowDirect && key === 'null' ) {
          options.push(
            <span
              className="selectIemWrapper"
              onClick={ () => handleIntegrationChange( task, 'null' ) }
            >
              <InlineSVG type="mail" />
              Email Owner Directly
            </span>,
          );
        } else if ( taniumIDs?.includes( key ) ) {
          if ( task.original?.tanium_compatible ) {
            options.push(
              <span
                className="selectIemWrapper"
                onClick={ () => handleIntegrationChange( task, key ) }
              >
                { intObj.logo }
                { intObj.label }
              </span>,
            );
          }
        } else {
          options.push(
            <span
              className="selectIemWrapper"
              onClick={ () => handleIntegrationChange( task, key ) }
            >
              { intObj.logo }
              { intObj.label }
            </span>,
          );
        }
      } );
    }

    return (
      <div className="selectFieldWrapper">
        <Dropdown
          asSelect
          trigger={
            <span className={ selectedIntegrationObject?.label === 'Select an integration...' ? 'placeholder' : ''}>
              { selectedIntegrationObject?.logo }
              { selectedIntegrationObject?.label }
            </span>
          }
          menuItems={ options }
        />
      </div>
    );
  };

  // the contents of the options cell of the table, the most complicated of all of them,
  // this will have one of the following things present:
  // 1. No owner + JIRA: 'Unassigned'
  // 2. No Owner + EMAIL: destination email of integration
  // 3. No Owner + DIRECT: not possible
  //
  // 4. Owner + JIRA: SearchResult input with mapped owner (if any) prepopulated
  // 5. Owner + EMAIL: destination email of integration
  // 6. Owner + DIRECT: owner email as input prepoluated with owner email if present
  const integrationOptionsCellContent = task => {
    const owner = taskMappings[task.id]?.ownerID;
    const integration = taskMappings[task.id]?.integrationID;
    const mappedUser = taskMappings[task.id]?.externalUserID;
    const emailAddress = taskMappings[task.id]?.emailAddress;

    const jiraIDs = activeIntegrations?.jira?.map( integration => integration.id );
    const taniumIDs = activeIntegrations?.tanium?.map( integration => integration.id );
    const emailIDS = activeIntegrations?.email?.map( integration => integration.id );

    const hasOwner = isNotEmpty( owner );
    const hasJiraIntegration = isNotEmpty( integration ) && isNotEmpty( jiraIDs ) && jiraIDs?.includes( integration );
    // eslint-disable-next-line max-len
    const hasTaniumIntegration = isNotEmpty( integration ) && isNotEmpty( taniumIDs ) && taniumIDs?.includes( integration );
    // eslint-disable-next-line max-len
    const hasEmailIntegration = isNotEmpty( integration ) && isNotEmpty( emailIDS ) && emailIDS?.includes( integration );

    const isValid = emailAddress?.match( VALIDATION_REGEXS.email );

    // const hasMappedUser = isNotEmpty( mappedUser );

    const getFullIntegration = () => {
      if ( hasJiraIntegration ) {
        return activeIntegrations?.jira?.find( i => i.id === integration );
      }
      if ( hasEmailIntegration ) {
        return activeIntegrations?.email?.find( i => i.id === integration );
      }
      return null;
    };

    const getCellContents = () => {
      // 1. tanium 'N/A' no options
      if ( hasTaniumIntegration ) {
        return <span className="noOptions">N/A</span>;
      // 2. no owner + JIRA, displays externalUser select
      } else if ( !hasOwner && hasJiraIntegration ) {
        return <React.Fragment>
          <span className="labelWrapper">Jira Owner</span>
          <div className="selectFieldWrapper">
            <select
              value={mappedUser || 'null'}
              onChange={ e => handleMappingChange( task, e.target.value ) }
            >
              {
                isNotEmpty( externalUserOptions ) &&
                Object.entries( externalUserOptions ).map( ( [ value, label ], index ) => {
                  return <option key={index} value={value}>{ label }</option>;
                } )
              }
            </select>
          </div>
        </React.Fragment>;
      // 3. no owner + EMAIL, displays destination email
      } else if ( !hasOwner && hasEmailIntegration && isNotEmpty( getFullIntegration() ) ) {
        return <span>{ getFullIntegration().destination_email_address }</span>;
      // 4. owner + JIRA, displays externalUser select
      } else if ( hasOwner && hasJiraIntegration ) {
        return <React.Fragment>
          <span className="labelWrapper">Jira Owner</span>
          <div className="selectFieldWrapper">
            <select
              value={mappedUser || 'null'}
              onChange={ e => handleMappingChange( task, e.target.value ) }
            >
              {
                isNotEmpty( externalUserOptions ) &&
                Object.entries( externalUserOptions ).map( ( [ value, label ], index ) => {
                  return <option key={index} value={value}>{ label }</option>;
                } )
              }
            </select>
          </div>
        </React.Fragment>;
      // 5. owner + EMAIL, displays destination email
      } else if ( hasOwner && hasEmailIntegration && isNotEmpty( getFullIntegration() ) ) {
        return <span>{ getFullIntegration().destination_email_address }</span>;
      }
      // 6. owner + DIRECT, displays text input, prepulated with owner email
      return <React.Fragment>
        <span className="labelWrapper">Email Address</span>
        <input
          className={ `${ isValid ? '' : 'invalid' }` }
          type="email"
          value={ emailAddress || '' }
          onChange={ e => handleEmailChange( task, e.target.value ) }
        />
      </React.Fragment>;
    };

    return(
      <React.Fragment>
        { getCellContents() }
      </React.Fragment>
    );
  };

  // mapped over for each row
  // sets up the very complex table based on the state of the task in question, contains 5 columns (4 visible)
  // 1. id
  // 2. task (task name)
  // 3. owner
  // 4. integration to export to
  // 5. depends on 4 either an email, or user, or unassigned
  const transformRowData = ( task ) => {
    return {
      task: <React.Fragment>
        <h3 className={ `risk--${riskToRating( task.risk )}` }>
          <div className="recordIconWrapper">
            { taskIcon( task ) }
          </div>
          { task.label }
          {
            task.original?.tanium_compatible &&
            <div className="taniumBadge">
              <InlineSVG type="taniumLogo" version="special" />
              <strong>Tanium</strong>
            </div>
          }
        </h3>
        <span className="action">{ taskActionSentence( task ) }</span>
      </React.Fragment>,
      'Owner': ownerCellContent( task ),
      // eslint-disable-next-line camelcase,
      export_to: integrationCellContent( task ),
      options: integrationOptionsCellContent( task ),
      id: task.id,
    };
  };

  return (
    <React.Fragment>
      <EmptyLoading loading={loadingTasks} payload={visibleTasks} emptyMessage="No remaining tasks for export" />
      {
        (
          isNotEmpty( tasks )
          && isNotEmpty( visibleTasks )
          && isNotEmpty( taskMappings )
        ) &&
        <div className="exportRemediationTicketsTableWrapper">
          {
            isNotEmpty( exportedTasks ) &&
            <div className="tableActionsContainer">
              <button
                className="toggleExportedButton"
                onClick={ () => setViewExported( !viewExported ) }
              >
                {
                  viewExported
                    ? <React.Fragment>
                      Hide exported tasks
                      <InlineSVG type="carretUp" version="primary" />
                    </React.Fragment>
                    : <React.Fragment>
                      View exported tasks
                      <InlineSVG type="carretDown" version="primary" />
                    </React.Fragment>
                }
              </button>
            </div>
          }

          <DataTable
            data={visibleTasks}
            withSelect
            variant="minimal"
            onSelect={onSelect}
            selectedIDs={selectedTaskIDs}
            setSelectedIDs={setSelectedTaskIDs}
            elementClass="exportRemediationTicketsTable"
          />
        </div>
      }
    </React.Fragment>
  );
};

export default ModalBody;