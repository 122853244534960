/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { cloudScannerLogo, cloudScannerVendorNameMap } from '../../../shared/Utilities';

import './CloudScanning.scss';

const CloudScanningItem = ( { cloudScanner } ) => {

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        { cloudScannerLogo( cloudScanner ) }
        <div className="column">
          <h2>{ cloudScannerVendorNameMap[cloudScanner.vendor] }</h2>
          <span><strong>Name: </strong>{ cloudScanner.label ?? 'N/A' }</span>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CloudScanningItem;