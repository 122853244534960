/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { getDimensionsAndOffset, isNotEmpty, riskToRating } from '../../../../shared/Utilities';
import InstancesBreakdown from '../../../../shared/Charts/InstancesBreakdown';
import CVSSCategories from '../../../../shared/CVSSCategories';

import './PatchContent.scss';
import Statistics from '../../Statistics';
import { getTallies } from '../../../../shared/RecordCache';

const PatchContent = ( { patch, options, context, containerRef, setStyle, prefetched=false } ) => {

  const [ instanceData, setInstanceData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );

  const svgWrapperRef = React.useRef( null );

  // re-position after the content loads
  React.useEffect( () => {
    if (
      isNotEmpty( containerRef )
      && isNotEmpty( containerRef.current )
      && isNotEmpty( patch )
      && context !== 'explore'
    ) {
      let _clickEvent;
      if ( isNotEmpty( patch.clickEvent ) ) {
        _clickEvent = patch.clickEvent;
      } else if ( isNotEmpty( options?.clickEvent ) ) {
        _clickEvent = options.clickEvent;
      }

      if ( isNotEmpty( _clickEvent ) ) {

        const width = 32 * 16;

        let left = 'unset';
        let right = 16;
        let top = 16;
        let bottom = 'unset';

        setTimeout( () => {
          const dimensions = getDimensionsAndOffset( containerRef.current );

          if ( isNotEmpty( dimensions ) ) {
            const { height } = dimensions;

            const { pageX, pageY } = _clickEvent;

            left = pageX + 16;
            right = 'unset';
            top = pageY;
            bottom = 'unset';

            // it would flow over the right side of the screen, need to put on the left side instead
            if ( ( left + width ) > window.innerWidth ) {
              left = pageX - 16 - width;

              if ( left < 16 ) {
                left = 16;
              }
            }

            // it would be too low on the screen
            if ( ( top + height ) > window.innerHeight ) {
              top = 'unset';
              bottom = 16;
            }

            setStyle( { top, bottom, left, right, width } );
          }
        }, 100 );
      }
    }
  }, [ patch, containerRef, options, context ] );

  const riskClass = () => {
    if ( isNotEmpty( patch ) ) {
      if ( 'risk' in patch ) {
        return riskToRating( patch.risk );
      }
      if ( 'direct_risk' in patch ) {
        return riskToRating( patch.direct_risk );
      }
      if ( 'filtered_risk' in patch ) {
        return riskToRating( patch.filtered_risk );
      }
      return 'unknown';
    }
    return 'unknown';
  };

  React.useEffect( () => {
    if ( isNotEmpty( patch ) && prefetched === false ) {
      setLoading( true );

      getTallies(
        'patch',
        {
          // eslint-disable-next-line camelcase
          filters: { patch_ids: [ patch.id ] },
          types: [ 'category', 'exploit_status', 'cvss' ],
        },
      ).then( tallyData => {
        setLoading( false );
        if ( isNotEmpty( tallyData ) ) {
          const _instanceData = {
            results: { ...tallyData },
          };
          setInstanceData( _instanceData );
        }
      } );
    } else if ( isNotEmpty( patch ) && prefetched === true ) {
      const _instanceData = { results: {} };

      if ( isNotEmpty( patch.cvss_histogram ) ) {
        const cvss = {};

        const cvssLabelOrder = {
          Minimal: 'minimal',
          Low: 'low',
          Moderate: 'moderate',
          High: 'high',
          Critical: 'critical',
        };

        Object.entries( cvssLabelOrder ).map( ( [ label, key ] ) => {
          const histogramEntry = patch.cvss_histogram.find( c => c.name === label );

          cvss[key] = histogramEntry.value;
        } );
        _instanceData.results.cvss = cvss;
      }
      if ( isNotEmpty( patch.category_histogram ) ) {
        _instanceData.results._total = patch.category_histogram.all;
        _instanceData.results.category = patch.category_histogram;
      }
      setInstanceData( _instanceData );
    }
  }, [ patch, prefetched ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( options )
          ? <React.Fragment>
            {
              ( 'include_vulnerability_instances' in options && options.include_vulnerability_instances === true ) &&
              <React.Fragment>
                {
                  ( isNotEmpty( patch ) && isNotEmpty( instanceData ) ) &&
                  <InstancesBreakdown
                    reportType="host"
                    itemID={ patch.id }
                    riskRating={ riskClass() }
                    instanceData={ instanceData }
                    svgRef={ svgWrapperRef }
                    loading={ loading }
                  />
                }
              </React.Fragment>
            }
          </React.Fragment>
          : <React.Fragment>
            {
              ( isNotEmpty( patch ) && isNotEmpty( instanceData ) ) &&
              <InstancesBreakdown
                reportType="host"
                itemID={ patch.id }
                riskRating={ riskToRating( riskClass() ) }
                instanceData={ instanceData }
                svgRef={ svgWrapperRef }
                loading={ loading }
              />
            }
          </React.Fragment>
      }
      {
        (
          isNotEmpty( patch )
          && isNotEmpty( instanceData )
          && isNotEmpty( options )
          && ( options.include_cvss_breakdown )
        ) &&
        <CVSSCategories data={ instanceData } />
      }

      {
        ( isNotEmpty( patch ) ) &&
        <React.Fragment>
          <p className="patchDescription">
            { patch.description }
          </p>
          <Statistics
            record={ patch }
            recordType="patch"
            recordInstanceData={ instanceData }
            includeRisk={ false }
          />
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default PatchContent;