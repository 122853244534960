/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { RemediationContext } from '../../../Contexts/Remediation';

import SetupPage from '../../../shared/SetupComponents/SetupPage';
import {
  getActiveIntegrations,
  isNotEmpty,
  itemIsArray,
  userDisplayName,
} from '../../../shared/Utilities';
import { recordData } from './data';

import './style.scss';
import { makeRequest } from '../../../../legacy/io';
import { getConfigurationAlertsFor } from '../../../shared/ConfigurationAlert';
import { hasFeatureAccess } from '../../App/AccessControl';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

const RemediationPlans = ( ) => {

  const [ remediationPlans, setRemediationPlans ] = React.useState( [] );
  const [ users, setUsers ] = React.useState( null );
  const [ remediationAccess, setRemediationAccess ] = React.useState( null );
  const [ activeIntegrations, setActiveIntegrations ] = React.useState( null );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const [ workingDraftPlan, setWorkingDraftPlan, , setAllRemediationDrafts ] = React.useContext( RemediationContext );

  // on init, sets up the page and help
  React.useEffect( () => {
    const _allUsers = {};
    const _remediationAccess = {};

    // fetch any dashboards that this user has access to
    const accessParams = {
      columns: [
        'id',
        'modified',
        'user_id',
        'access_level',
        'asset_tag_id',
        'dashboard_id',
        'remediation_plan_id',
      ],
    };

    makeRequest( 'POST', '/fe/object_access/SELECT', accessParams ).then( response => {
      if ( isNotEmpty( response ) && itemIsArray( response ) ) {
        response.map( accessItem => {
          if (
            isNotEmpty( accessItem.access_level )
            && accessItem.access_level === 'owner'
            && isNotEmpty( accessItem.remediation_plan_id )
          ) {
            _remediationAccess[accessItem.remediation_plan_id] = accessItem;
          }
        } );

        setRemediationAccess( _remediationAccess );
      }
    } );

    makeRequest( 'POST', '/fe/user/SELECT', {
      // eslint-disable-next-line camelcase
      columns: [
        'authentication_provider_id',
        'username',
        'given_name',
        'family_name',
        'api_key',
        'setup_wizard_disabled',
        'email_address',
      ],
      'order_by':[ [ 'username', 'ASC' ] ],
    } ).then( response => {
      if ( isNotEmpty( response ) && itemIsArray( response ) ) {
        response.map( user => {
          _allUsers[user.id] = userDisplayName( user );
        } );
      }
      setUsers( _allUsers );
    } );

    if (
      isNotEmpty( currentUser )
      && isNotEmpty( licenseInfo )
      && hasFeatureAccess( currentUser, licenseInfo, 'f_third_party_settings' )
    ) {
      getActiveIntegrations().then( setActiveIntegrations );
    }

    // check for any relevant config alerts and add them to the recordData so that the page can display them
    if ( isNotEmpty( currentUser ) && hasFeatureAccess( currentUser, licenseInfo, 'f_configuration_alerts' ) ) {
      getConfigurationAlertsFor( [ 'remediation-email-export', 'remediation-ticketing' ] ).then( response => {
        if ( isNotEmpty( response ) ) {
          recordData.notifications = [
            {
              type: 'alert',
              // eslint-disable-next-line max-len
              message: <p>The latest attempt to export remediation tasks resulted in errors and configuration alerts have been issued. For further information please refer to the relevant configuration alerts concerning <a target="_blank" rel="noopener noreferrer" href="#.=activity&page=configuration_alerts&source=remediation-email-export">Remediation Email Exports</a> and/or <a target="_blank" rel="noopener noreferrer" href="#.=activity&page=configuration_alerts&source=remediation-ticketing">Remediation Ticketing Exports</a>.</p>,
            },
          ];
        }
      } );
    }
  }, [ currentUser, licenseInfo ] );

  const onRefresh = async () => {
    const response = await makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} );
    const sortedResults = {
      draft: [],
      active: [],
      closed: [],
    };

    if ( response && isNotEmpty( response.results ) ) {
      response.results.map( plan => {
        sortedResults[plan.status].push( plan );
      } );
      setRemediationPlans( sortedResults );
      if ( isNotEmpty( sortedResults.draft ) ) {
        setAllRemediationDrafts( sortedResults.draft );
        const currentDraftID = workingDraftPlan?.id;

        if ( currentDraftID && !sortedResults.draft.find( p => p.id === currentDraftID ) ) {
          setWorkingDraftPlan( sortedResults.draft[0] );
        }
      } else {
        setAllRemediationDrafts( null );
        setWorkingDraftPlan( null );
      }
    } else {
      setRemediationPlans( {} );
      setAllRemediationDrafts( null );
      setWorkingDraftPlan( null );
    }
  };

  return (
    <React.Fragment>
      <SetupPage
        onRefresh={onRefresh}
        onSave={ () => {} }
        records={remediationPlans}
        setRecords={setRemediationPlans}
        recordType="remediation_plan"
        recordData={recordData}
        users={users}
        remediationAccess={remediationAccess}
        alternateItemLayout
        activeIntegrations={activeIntegrations}
      />
    </React.Fragment>
  );
};

export default RemediationPlans;