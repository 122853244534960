/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { riskToRating } from '../Utilities';

import './AttackScenarioCountLink.scss';
import InlineSVG from '../InlineSVG';
import { defaultUserColumnsString, legacyOrderByString, legacyRowsString } from '../../components/App/Routing';

const UserAtRiskLink = ( { user } ) => {
  return (
    <React.Fragment>
      <a
        // eslint-disable-next-line max-len
        href={ `#.=risk_insight&report=users&item=${user?.id}${legacyOrderByString}${legacyRowsString}${defaultUserColumnsString}` }
        target="_blank"
        rel="noopener noreferrer"
        className={ `attackScenarioCountLink user ${ riskToRating( user.risk )}` }
      >
        <div className="iconAndCountWrapper">
          <InlineSVG type="user_record" />
        </div>
        <div className="labelAndLinkWrapper">
          <span>{ user?.domain_name }\{ user?.name }</span>
          <InlineSVG type="newTabLink" />
        </div>
      </a>
    </React.Fragment>
  );
};

export default UserAtRiskLink;