import { getRecords } from '../../../../RecordCache';
import { isNotEmpty, itemIsArray } from '../../../../Utilities';

export const fetchNeededRecords = async ( recordIDs, recordType, query ) => {
  if ( ( isNotEmpty( recordIDs ) || isNotEmpty( query ) ) && isNotEmpty( recordType ) ) {
    const params = {
      filters: {},
      // eslint-disable-next-line camelcase
      risk_type: recordType === 'patch' ? 'cumulative_risk' : 'direct_risk',
    };

    if ( isNotEmpty( recordIDs ) ) {
      params.filters[`${recordType}__id`] = recordIDs;
      params.rows = [ 0, recordIDs.length ];
    } else if ( isNotEmpty( query ) ) {
      params.filters[`${recordType}_keywords`] = query;
      params.rows = [ 0, 100 ];
    }

    const response = await getRecords( recordType, params, true, true );

    if ( isNotEmpty( response ) && itemIsArray( response ) ) {
      return response;
    }
    return [];
  }
  return [];
};