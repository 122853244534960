/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty, riskToRating } from '../../../shared/Utilities';
import { ADVERSARY_NODE, ICON_SIZE, MENU_WIDTH, NODE_HEIGHT, SCOPE_PADDING } from './shared';
import './GraphNode.scss';
import { getNodeIcon } from '../../RecordDetails/shared';
import { getThemeColor } from '../../../shared/Themes';

const GraphNode = ( {
  node,
  handleItemMouseUp,
  handleItemMouseDown,
  focusNodes=[],
  setFocusNodes,
  focusEdges=[],
  setFocusEdges,
  externalHoverIDs,
  setSelectedItem,
  setSelectedItemType,
  selectingSecondaryItem,
  setSelectingSecondaryItem,
  ignoreClick,
  setIgnoreClick,
  setSVGScale,
  setSVGPanShift,
  svgDimensions,
  collapsedGraphMenu,
  setIsZoomed,
  setContextMenuItem,
  setContextMenuType,
  setFirstClickedItem,
} ) => {

  const [ iconSize, setIconSize ] = React.useState( ICON_SIZE );
  const [ hovered, setHovered ] = React.useState( false );

  const handleNodeRightClick = ( e, node ) => {
    if ( isNotEmpty( e ) && e.button === 2 ) {
      e.stopPropagation();
      e.preventDefault();
      const _item = { ...node, clickEvent: e };
      setFirstClickedItem( _item );
      setContextMenuItem( _item );
      setContextMenuType( 'node' );
      return false;
    }
  };

  // when double-clicking a node, we want to zoom into the node
  const handleNodeDoubleClick = ( e, node ) => {

    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();
      e.preventDefault();

      const additionalX = collapsedGraphMenu ? SCOPE_PADDING * 4 : ( MENU_WIDTH + ( SCOPE_PADDING * 4 ) );
      const additionalY = collapsedGraphMenu ? 5 * 16 : 2 * 16;

      // find the optimal scale, then cut it in half because we don't need to be that zoomed into a node
      // eslint-disable-next-line max-len
      const scale = ( svgDimensions.w - additionalX ) / ( node?.w ) * 0.5;

      const x = ( ( node?.x ) * scale * -1 ) + additionalX;
      const y = ( node?.y ) * scale * -1 + additionalY;

      setSVGScale( scale );
      setSVGPanShift( { x, y } );
      setIsZoomed( true );
    }
  };

  // when clicking on a node it is for 2 reasons:
  // 1: We want to select this node to interact with it in some way
  // 2: We want to select this node as a secondary node for creating an edge, or finding paths
  const handleNodeClick = ( e, node ) => {
    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();
      e.preventDefault();

      const _item = { ...node, clickEvent: e };

      // DO NOT REMOVE!!! used by devs to debug explore behavior -DMC 2023-07-27
      console.log( _item );

      if ( ignoreClick ) {
        setIgnoreClick( false );
      } else if ( isEmpty( selectingSecondaryItem ) ) {
        const allNodeEdges = [ ..._item.fromEdges, ..._item.toEdges ];
        // deselecting
        if ( isNotEmpty( focusNodes && focusNodes.includes( _item.id ) ) ) {
          let _nodes = [ ...focusNodes ];
          _nodes = _nodes.filter( n => n !== _item.id );
          setFocusNodes( _nodes );
          if ( isNotEmpty( focusEdges ) ) {
            let _edges = [ ...focusEdges ];
            _edges = _edges.filter( e => !allNodeEdges.includes( e ) );
            setFocusEdges( _edges );
          }
          setSelectedItem( null );
          setSelectedItemType( null );
        // selecting
        } else {
          setSelectedItem( _item );
          setSelectedItemType( 'node' );
          setFocusNodes( [ _item.id ] );
          setFocusEdges( allNodeEdges );
        }
      } else {
        setSelectingSecondaryItem( null );
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( node ) && node.id === ADVERSARY_NODE.id ) {
      setIconSize( ICON_SIZE * 1.25 );
    }
  }, [ node ] );

  const getNodeRating = node => {
    if ( isNotEmpty( node ) ) {
      let allRatings = [];

      if ( isNotEmpty( node.fromEdgeRatings ) ) {
        allRatings = node.fromEdgeRatings;
      }
      if ( isNotEmpty( node.toEdgeRatings ) ) {
        allRatings = [ ...allRatings, ...node.toEdgeRatings ];
      }

      if ( isNotEmpty( allRatings ) ) {
        if ( allRatings.includes( 'high' ) ) {
          return 'high';
        }
        if ( allRatings.includes( 'moderate' ) ) {
          return 'moderate';
        }
        if ( allRatings.includes( 'low' ) ) {
          return 'low';
        }
        if ( allRatings.includes( 'minimal' ) ) {
          return 'minimal';
        }
        if ( allRatings.includes( 'primary' ) ) {
          return 'primary';
        }
        return 'default';
      }
    }
    return 'default';
  };

  const isSensitiveAsset = node => {
    let { type, combined_impact, impact } = node;
    const { isSensitive } = node;

    if ( isNotEmpty( node.original ) ) {
      ( { type, combined_impact, impact } = node.original );
    }

    if ( type === 'database' || type === 'shared_folder_permission' ) {
      // eslint-disable-next-line camelcase
      if ( combined_impact > 0 || impact > 0 || isSensitive ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const hasIndicatorIcon = node => {
    let { flags, type, combined_impact, impact } = node;
    const { isSensitive } = node;

    if ( isNotEmpty( node.original ) ) {
      ( { flags, type, combined_impact, impact } = node.original );
    }


    if ( type === 'database' || type === 'shared_folder_permission' ) {
      // eslint-disable-next-line camelcase
      if ( combined_impact > 0 || impact > 0 || isSensitive ) {
        return true;
      }
      return false;
    }
    if ( flags['recently_active'] ) {
      return true;
    }
    return false;
  };

  const shouldHighlight = node => externalHoverIDs.includes( node.id ) || hovered;

  return (
    <React.Fragment>
      {
        isNotEmpty( node ) &&
        <g
          // eslint-disable-next-line max-len
          className={ `${getNodeRating( node ) } ${ isSensitiveAsset( node ) ? 'sensitive' : '' } ${ shouldHighlight( node ) ? 'highlight' : ''} ${ node.id === ADVERSARY_NODE.id ? 'isAdversary' : ''} graphModelNodeGroup risk-${riskToRating( node.risk ) } ${ focusNodes?.includes( node.id ) ? 'focused' : '' }` }
          onMouseDown={ handleItemMouseDown }
          onMouseUp={ handleItemMouseUp }
        >
          {
            node.id === ADVERSARY_NODE.id &&
            <circle
              className="nodeIconCircle"
              cx={ node.center.x }
              cy={ node.center.y }
              r={ ( NODE_HEIGHT / 2.5 ) }
              fill={ getThemeColor( 'high-75' ) }
              fillOpacity={ 0.2 }
            />
          }
          {
            ( shouldHighlight( node ) && !isSensitiveAsset( node ) ) &&
            <circle
              className="nodeHoverCircle"
              cx={ node.center.x }
              cy={ node.center.y }
              r={ ( NODE_HEIGHT / 2.5 ) }
              fill={ getThemeColor( 'high-75' ) }
              fillOpacity={ 0.2 }
            />
          }
          {
            isSensitiveAsset( node )
              ? <React.Fragment>
                <rect
                  className="nodeIconCircle asRect"
                  x={ node.center?.x - ( NODE_HEIGHT / ( 1.45 * 2 ) ) }
                  y={ node.center?.y - ( NODE_HEIGHT / ( 1.45 * 2 ) ) }
                  rx={ 11.5 }
                  width={ NODE_HEIGHT / 1.45 }
                  height={ NODE_HEIGHT / 1.45 }
                  fill={ getThemeColor( '--primary' ) }
                  fillOpacity={ 0.2 }
                />
                <rect
                  className="nodeIconCircle asRect"
                  x={ node.center?.x - ( NODE_HEIGHT / 3.5 ) }
                  y={ node.center?.y - ( NODE_HEIGHT / 3.5 ) }
                  rx={ 8 }
                  width={ NODE_HEIGHT / 1.75 }
                  height={ NODE_HEIGHT / 1.75 }
                  fill={ getThemeColor( '--primary' ) }
                />
              </React.Fragment>
              : <circle
                className="nodeIconCircle"
                cx={ node.center?.x }
                cy={ node.center?.y }
                r={ ( NODE_HEIGHT / 3 ) }
                fill={ getThemeColor( 'darkBlue--75' )}
              />
          }
          <svg
            width={ iconSize }
            height={ iconSize }
            x={ node.center?.x - ( iconSize / 2 ) }
            y={ node.center?.y - ( iconSize / 2 ) }
            viewBox="0 0 32 32"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            className="svgNodeIcon"
          >
            { getNodeIcon( node, true ) }
          </svg>
          {
            hasIndicatorIcon( node ) &&
            <circle
              className="nodeIndicatorDot"
              cx={ node.center?.x + ( NODE_HEIGHT / 4 ) }
              cy={ node.center?.y - ( NODE_HEIGHT / 4 ) }
              r={ ( NODE_HEIGHT / 10 ) }
              fill={ getThemeColor( '--status-success' ) }
              stroke="#fff"
              strokeWidth={ 4 }
            />
          }
          {
            node.id !== ADVERSARY_NODE.id &&
            <rect
              x={ node.x + 10 }
              y={ node.y + ( node.h - 45 ) }
              width={ node.w - 20 }
              height={ 40 }
              rx={ 4 }
              fill="#fff"
              fillOpacity={ 0 }
              style={{ pointerEvents: 'none', userSelect: 'none', WebkitUserSelect: 'none' }}
            />
          }
          <text
            x={ node.center?.x }
            // eslint-disable-next-line max-len
            y={ node.center?.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? 12 : 24 ) ) }
            fill={ getThemeColor( '--text-color-primary' ) }
            // fillOpacity={ 0.7 }
            fontSize={ node.id === ADVERSARY_NODE.id ? iconSize / 2 : iconSize / 1.75 }
            textAnchor="middle"
            fontWeight={ 600 }
            style={{ pointerEvents: 'none' }}
          >
            { node.label?.length > 20 ? `${node.label?.slice( 0, 19 )}...` : node.label }
          </text>
          {/* this is what actually gets dragged and clicked, wraps the text and node */}
          <path
            className="pathDraggingMask"
            // eslint-disable-next-line max-len
            d={ `M ${node.center?.x - ( NODE_HEIGHT * 0.4 )} ${node.center?.y - ( NODE_HEIGHT * 0.4 )} L ${node.center?.x + ( NODE_HEIGHT * 0.4 )} ${node.center?.y - ( NODE_HEIGHT * 0.4 )} L ${node.center?.x + ( NODE_HEIGHT * 0.4 )} ${node.center?.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -30 : -6 ) )} L ${ node.x + node.w } ${node.center?.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -30 : -6 ) )} L ${ node.x + node.w } ${node.center?.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? 6 : 30 ) )} L ${node.x} ${node.center?.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? 6 : 30 ) )} L ${node.x} ${node.center?.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -30 : -6 ) )} L ${node.center?.x - ( NODE_HEIGHT * 0.4 )} ${node.center?.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -30 : -6 ) )} L ${node.center?.x - ( NODE_HEIGHT * 0.4 )} ${node.center?.y - ( NODE_HEIGHT * 0.4 )}`}
            fill={ getThemeColor( '--primary' ) }
            fillOpacity={ 0 }
            onMouseEnter={ () => setHovered( true ) }
            onMouseLeave={ () => setHovered( false ) }
            onClick={ e => {
              e.preventDefault();
              e.stopPropagation();
              handleNodeClick( e, node );
              return false;
            } }
            onDoubleClick={ e => {
              e.preventDefault();
              e.stopPropagation();
              handleNodeDoubleClick( e, node );
              return false;
            } }
            // need to prevent here before passed to the handler or it will still trigger the default right-click menu
            onContextMenu={ e => {
              e.preventDefault();
              e.stopPropagation();
              handleNodeRightClick( e, node );
              return false;
            } }
            id={ node.id }
          />
        </g>
      }
    </React.Fragment>
  );
};

export default GraphNode;

