/* eslint-disable camelcase */
import React from 'react';

import {
  attackScenarioLabelMap,
  categoryLabelMap,
  exploitStatusLabelMap,
  formatUnixDate,
  isNotEmpty,
  itemIsArray,
  riskRatingLabelMap,
  shortenedVulnerabilityScannerNameMap,
  vulnerabilityScannerInstanceName,
  vulnerabilityScannerLogo,
} from '../../../shared/Utilities';
import { HelpTrigger } from '../../HelpDocumentation/ContextualHelp';
import { getRecords } from '../../../shared/RecordCache';
import { makeRequest } from '../../../../legacy/io';

// const defaultBooleanOptions = {
//   true: 'Yes',
//   false: 'No',
// };

const defaultBooleanNullOptions = {
  'null': 'Any',
  true: 'Yes',
  false: 'No',
};

const superSededOptions = {
  'null': 'Any',
  fully: 'Fully',
  partial: 'Partially',
  unsuperseded: 'Unsuperseded',
};

const acceptedRiskOptions = {
  false: 'Hide accepted risk items',
  true: 'Show accepted risk items only',
  'null': 'Show all items',
};

export const filtersWithArrayValues = [
  'asset_tag_ids',
  'exploit_statuses',
  'exploit_status',
  'third_party_setting_ids',
  'host_ids',
  'patch_ids',
  'vulnerability_ids',
  'signature_ids',

  'host_names',
  'host_ip_addresses',
  'host_mac_addresses',
];

export const specificRecordFilters = [
  'host_ids',
  'patch_ids',
  'vulnerability_ids',
  'signature_ids',
];

export const comparatorOptions = {
  eq: {
    matchString: '=',
    displayString: '= (equals)',
  },
  // not: {
  //   matchString: '\u2260',
  //   displayString: '\u2260 (does not equal)',
  // },
  gt: {
    matchString: '>',
    displayString: '> (greater than)',
  },
  lt: {
    matchString: '<',
    displayString: '< (less than)',
  },
  any: {
    matchString: 'any',
    displayString: 'is any of',
  },
};

export const comparatorOptionsLabelOverride = {
  vulnerability_created: {
    gt: {
      matchString: '>',
      displayString: '> (newer than)',
    },
    lt: {
      matchString: '<',
      displayString: '< (older than)',
    },
  },
  last_scanned: {
    gt: {
      matchString: '>',
      displayString: '> (newer than)',
    },
    lt: {
      matchString: '<',
      displayString: '< (older than)',
    },
  },
  tp_last_scanned: {
    gt: {
      matchString: '>',
      displayString: '> (newer than)',
    },
    lt: {
      matchString: '<',
      displayString: '< (older than)',
    },
  },
};

export const comparatorLabel = ( attribute, comparator ) => {
  if (
    isNotEmpty( comparatorOptionsLabelOverride[attribute]
      && isNotEmpty( comparatorOptionsLabelOverride[attribute][comparator] ) )
  ) {
    return comparatorOptionsLabelOverride[attribute][comparator].displayString;
  }
  return comparatorOptions[comparator].displayString;
};

// simple fuzzy search algorithm that takes an input string against a possible autocomplete option
// the input string is split into chars and the possible match is considered a match if the char appears
// in the possiblematch and appears after the previous char
export const fuzzySearch = ( userInput, possibleMatch ) => {
  // make sure the input and the match are present
  if ( isNotEmpty( possibleMatch ) ) {
    // early return for exact match
    if ( userInput.toLowerCase() === possibleMatch.toLowerCase() ) {
      return true;
    }

    // will be set to true if the remainder of the string matches a char
    let match = false;

    // takes the string input from the user, downcases, and splits into its chars
    const input = userInput.toLowerCase().split( '' );

    // starts as the entire possible match as an entire string, will get cut short each time a match is found
    let stringRemainder = possibleMatch.toLowerCase();

    // loop through each char and if there is a match, cut the string short from that point forward, ensuring
    // that any subsequent matches come after this match
    for ( const char of input ) {

      const matchIndex = stringRemainder.indexOf( char );

      // word does not contain char, short circuit out and return false
      if ( matchIndex === -1 ) {
        return false;
      // word contains char and it is after the previous char, slice the string from this point forward
      } else if ( matchIndex > -1 ) {
        stringRemainder = stringRemainder.slice( matchIndex + 1 );
        match = true;
      } else {
        return false;
      }
    }
    return match;
  }
  return false;
};

export const getFilterKeyFromMatchString = matchString => {
  const filter = Object.values( allCombinedAvailableFilters ).find( f => f.matchString === matchString );
  return filter?.attribute;
};

export const getMatchStringForFilterKey = filterKey => {
  const filter = allCombinedAvailableFilters[filterKey];
  return filter?.matchString;
};

export const allAvailableTagFilters = {
  included_ranges: {
    type: 'textarea',
    attribute: 'included_ranges',
    placeholder: 'No IP ranges included',
    label: 'Included IP Range(s)',
    matchString: 'Included IP Ranges',
    defaultValue: [],
    needsSplit: true,
    elementClass: 'includeFilter',
    help: <HelpTrigger helpKey="ip_ranges" />,
    allowedComparisons: [ 'any' ],
  },
  included_host_patterns: {
    type: 'textarea',
    attribute: 'included_host_patterns',
    placeholder: 'No wildcards included',
    label: 'Included Host Wildcard(s)',
    matchString: 'Included Host Wildcards',
    defaultValue: [],
    needsSplit: true,
    elementClass: 'includeFilter',
    help: <HelpTrigger helpKey="host_wildcards" />,
    allowedComparisons: [ 'any' ],
    allowInDemoMode: true,
  },
  included_host_ids: {
    type: 'select2',
    label: 'Included Specific Host(s)',
    matchString: 'Included Specific Hosts',
    attribute: 'included_host_ids',
    placeholder: 'Find hosts by name...',
    elementClass: 'includeFilter',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
      recordType: 'host',
      recordFetchNeeded: true,
    },
    help: <HelpTrigger helpKey="specific_hosts" />,
    allowedComparisons: [ 'any' ],
    allowInDemoMode: true,
  },
  included_product_names: {
    type: 'select2',
    label: 'Included OS Version(s)',
    matchString: 'Included OS Versions',
    attribute: 'included_product_names',
    value: [],
    elementClass: 'includeFilter',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
    },
    helpItem: <HelpTrigger helpKey="os_versions" />,
    allowedComparisons: [ 'any' ],
    allowInDemoMode: true,
  },

  excluded_ranges: {
    type: 'textarea',
    attribute: 'excluded_ranges',
    placeholder: 'No IP ranges excluded',
    label: 'Excluded IP Range(s)',
    matchString: 'Excluded IP Ranges',
    defaultValue: [],
    needsSplit: true,
    elementClass: 'excludeFilter',
    help: <HelpTrigger helpKey="ip_ranges" />,
    allowedComparisons: [ 'any' ],
    allowInDemoMode: true,
  },
  excluded_host_patterns: {
    type: 'textarea',
    attribute: 'excluded_host_patterns',
    placeholder: 'No wildcards excluded',
    label: 'Excluded Host Wildcard(s)',
    matchString: 'Excluded Host Wildcards',
    defaultValue: [],
    needsSplit: true,
    elementClass: 'excludeFilter',
    help: <HelpTrigger helpKey="host_wildcards" />,
    allowedComparisons: [ 'any' ],
    allowInDemoMode: true,
  },
  excluded_host_ids: {
    type: 'select2',
    label: 'Excluded Specific Host(s)',
    matchString: 'Excluded Specific Hosts',
    attribute: 'excluded_host_ids',
    placeholder: 'Find hosts by name...',
    elementClass: 'excludeFilter',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
      recordType: 'host',
      recordFetchNeeded: true,
    },
    help: <HelpTrigger helpKey="specific_hosts" />,
    allowedComparisons: [ 'any' ],
    allowInDemoMode: true,
  },
  excluded_product_names: {
    type: 'select2',
    label: 'Excluded OS Version(s)',
    matchString: 'Excluded OS Versions',
    attribute: 'excluded_product_names',
    value: [],
    elementClass: 'excludeFilter',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
    },
    helpItem: <HelpTrigger helpKey="os_versions" />,
    allowedComparisons: [ 'any' ],
    allowInDemoMode: true,
  },
};

// allowedComparisons: [ 'eq', 'gt', 'lt', 'any', 'not ],
// commenting out 'not' for now, need to figure out the best way to represent this in the params and still
// be backwards compatible with the old filter system
export const allAvailableFilters = {
  accepted_risk: {
    type: 'select2',
    label: 'Include Accepted Risk Items?',
    matchString: 'Accepted Risk',
    attribute: 'accepted_risk',
    value: false,
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    options: acceptedRiskOptions,
    helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
    allowInDemoMode: true,
  },
  attack_scenario: {
    type: 'select2',
    label: 'Attack Scenario',
    matchString: 'Attack Scenario',
    attribute: 'attack_scenario',
    value: '',
    allowBlank: true,
    blankDisplayText: 'All Scenarios',
    options: attackScenarioLabelMap,
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="attack_scenario" />,
    allowInDemoMode: true,
  },
  category: {
    type: 'select2',
    label: 'Category',
    matchString: 'Category',
    attribute: 'category',
    value: '',
    allowBlank: true,
    blankDisplayText: 'All',
    options: categoryLabelMap,
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="category" />,
    allowInDemoMode: true,
  },
  exploit_statuses: {
    type: 'select2',
    label: 'Exploit Status',
    matchString: 'Exploit Status',
    attribute: 'exploit_statuses',
    value: [],
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
    },
    options: exploitStatusLabelMap,
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="exploit_status" />,
    allowInDemoMode: true,
  },
  // has_host: {
  //   type: 'hidden',
  //   attribute: 'has_host',
  //   // allowedComparisons: [ 'eq', 'not' ],
  //   allowedComparisons: [ 'eq' ],
  //   value: true,
  // },
  // has_instances: {
  //   type: 'select',
  //   attribute: 'has_instances',
  // // allowedComparisons: [ 'eq', 'not' ],
  // allowedComparisons: [ 'eq' ],
  //   value: true,
  //   options: defaultBooleanOptions,
  // },
  host_has_sensitive_nodes: {
    type: 'select2',
    label: 'Host Has Sensitive Assets?',
    matchString: 'Host Has Sensitive Assets',
    attribute: 'host_has_sensitive_nodes',
    value: 'null',
    options: defaultBooleanNullOptions,
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="sensitive_assets" />,
    allowInDemoMode: true,
  },
  host_globs: {
    type: 'text',
    label: 'Host Globs',
    matchString: 'Host Globs',
    attribute: 'host_globs',
    value: [],
    textOptions: {
      needsSplit: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="host_globs" />,
    allowInDemoMode: true,
  },

  host_os_arch: {
    type: 'select2',
    label: 'Host OS Architecture',
    matchString: 'Host OS Architecture',
    attribute: 'host_os_arch',
    value: '',
    allowBlank: true,
    blankDisplayText: 'Any',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="host_os_arch" />,
    allowInDemoMode: true,
  },
  host_os_type: {
    type: 'select2',
    label: 'Host OS Type',
    matchString: 'Host OS Type',
    attribute: 'host_os_type',
    value: '',
    allowBlank: true,
    blankDisplayText: 'Any',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="host_os_type" />,
    allowInDemoMode: true,
  },
  host_product_name: {
    type: 'select2',
    label: 'Host Product Name',
    matchString: 'Host Product Name',
    attribute: 'host_product_name',
    value: '',
    allowBlank: true,
    blankDisplayText: 'Any',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="host_product_name" />,
    allowInDemoMode: true,
  },
  host_vendor: {
    type: 'select2',
    label: 'Host Vendor',
    matchString: 'Host Vendor',
    attribute: 'host_vendor',
    value: '',
    allowBlank: true,
    blankDisplayText: 'Any',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="host_vendor" />,
    allowInDemoMode: true,
  },
  // host_os_family: {
  //   type: 'select2',
  //   label: 'Host OS Family',
  //   matchString: 'Host OS Family',
  //   attribute: 'host_os_family',
  //   value: '',
  //   allowBlank: true,
  //   blankDisplayText: 'Any',
  //   // allowedComparisons: [ 'eq', 'not' ],
  //   allowedComparisons: [ 'eq' ],
  //   helpItem: <HelpTrigger helpKey="host_os_family" />,
  // },
  patch_globs: {
    type: 'text',
    label: 'Patch Globs',
    matchString: 'Patch Globs',
    attribute: 'patch_globs',
    value: [],
    textOptions: {
      needsSplit: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="patch_globs" />,
    allowInDemoMode: true,
  },

  patchable: {
    type: 'select2',
    label: 'Patchable?',
    matchString: 'Patchable',
    attribute: 'patchable',
    value: 'null',
    options: defaultBooleanNullOptions,
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="patchable" />,
    allowInDemoMode: true,
  },
  filtered_risk: {
    type: 'number',
    label: 'Raw Risk Score',
    matchString: 'Raw Risk Score',
    attribute: 'filtered_risk',
    value: '',
    allowBlank: true,
    allowedComparisons: [ 'gt', 'lt' ],
    allowInDemoMode: true,
  },
  risk_rating: {
    type: 'select2',
    label: 'Risk Rating',
    matchString: 'Risk Rating',
    attribute: 'risk_rating',
    value: '',
    allowBlank: true,
    blankDisplayText: 'Any',
    options: riskRatingLabelMap,
    allowedComparisons: [ 'gt', 'lt' ],
    helpItem: <HelpTrigger helpKey="risk_rating" />,
    allowInDemoMode: true,
  },
  risk_reduction: {
    type: 'number',
    label: 'Risk Reduction',
    matchString: 'Risk Reduction',
    attribute: 'risk_reduction',
    value: '',
    allowedComparisons: [ 'gt', 'lt' ],
    htmlProps: { min: '0', max: '100' },
    helpItem: <HelpTrigger helpKey="risk_reduction" />,
    allowInDemoMode: true,
  },
  signature_globs: {
    type: 'text',
    label: 'Signature Globs',
    matchString: 'Signature Globs',
    attribute: 'signature_globs',
    value: [],
    textOptions: {
      needsSplit: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="signature_globs" />,
    allowInDemoMode: true,
  },
  host_ids: {
    type: 'select2',
    label: 'Specific Hosts',
    matchString: 'Specific Hosts',
    attribute: 'host_ids',
    placeholder: 'Find hosts by name...',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
      recordType: 'host',
      recordFetchNeeded: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="specific_hosts" />,
    allowInDemoMode: true,
  },
  patch_ids: {
    type: 'select2',
    label: 'Specific Patches',
    matchString: 'Specific Patches',
    attribute: 'patch_ids',
    placeholder: 'Find patches by name...',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
      recordType: 'patch',
      recordFetchNeeded: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="specific_patches" />,
    allowInDemoMode: true,
  },
  signature_ids: {
    type: 'select2',
    label: 'Specific Signatures',
    matchString: 'Specific Signatures',
    attribute: 'signature_ids',
    placeholder: 'Find signatures by name...',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
      recordType: 'signature',
      recordFetchNeeded: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="specific_signatures" />,
    allowInDemoMode: true,
  },
  vulnerability_ids: {
    type: 'select2',
    label: 'Specific Vulnerabilities',
    matchString: 'Specific Vulnerabilities',
    attribute: 'vulnerability_ids',
    placeholder: 'Find vulnerabilities by name...',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
      recordType: 'vulnerability',
      recordFetchNeeded: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="specific_vulnerabilities" />,
    allowInDemoMode: true,
  },
  superseded: {
    type: 'select2',
    label: 'Superseded Status',
    matchString: 'Superseded Status',
    attribute: 'superseded',
    value: false,
    options: superSededOptions,
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="superseded" />,
    allowInDemoMode: true,
  },
  asset_tag_ids: {
    type: 'select2',
    label: 'Tags',
    matchString: 'Tags',
    attribute: 'asset_tag_ids',
    value: [],
    allowBlank: true,
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="tag" />,
    allowInDemoMode: true,
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
    },
  },
  vulnerability_created: {
    type: 'date',
    label: 'Vulnerability Age',
    matchString: 'Vulnerability Age',
    attribute: 'vulnerability_created',
    value: '',
    allowedComparisons: [ 'gt', 'lt' ],
    helpItem: <HelpTrigger helpKey="vulnerability_created" />,
    allowInDemoMode: true,
  },
  vulnerability_globs: {
    type: 'text',
    label: 'Vulnerability Globs',
    matchString: 'Vulnerability Globs',
    attribute: 'vulnerability_globs',
    value: [],
    textOptions: {
      needsSplit: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="vulnerability_globs" />,
    allowInDemoMode: true,
  },

  third_party_setting_ids: {
    type: 'select2',
    label: 'Included Vulnerability Source(s)',
    matchString: 'Vulnerability Source',
    attribute: 'third_party_setting_ids',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
    },
    allowedComparisons: [ 'any' ],
    helpItem: <HelpTrigger helpKey="vulnerability_sources" />,
    allowInDemoMode: true,
  },
};

// these are additional filters that are specific to the host, patch, and vulnerabilities
const additionalHostFilters = {
  host_keywords: {
    type: 'text',
    label: 'Host Keywords',
    matchString: 'Host Keywords',
    attribute: 'host_keywords',
    placeholder: 'Filter hosts by keyword...',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="host_keywords" />,
    allowInDemoMode: true,
  },

  host_names: {
    type: 'textarea',
    label: 'Host Names',
    matchString: 'Host Names',
    attribute: 'host_names',
    placeholder: 'Filter hosts by host name...',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    needsSplit: true,
    helpItem: <HelpTrigger helpKey="host_hames" />,
    allowInDemoMode: true,
  },

  host_ip_addresses: {
    type: 'textarea',
    label: 'Host IP Addresses',
    matchString: 'Host IP Addresses',
    attribute: 'host_ip_addresses',
    placeholder: 'Filter hosts by host ip addresses...',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    needsSplit: true,
    helpItem: <HelpTrigger helpKey="host_ip_addresses" />,
    allowInDemoMode: true,
  },

  host_mac_addresses: {
    type: 'textarea',
    label: 'Host MAC Addresses',
    matchString: 'Host MAC Addresses',
    attribute: 'host_mac_addresses',
    placeholder: 'Filter hosts by host MAC addresses...',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    needsSplit: true,
    helpItem: <HelpTrigger helpKey="host_mac_addresses" />,
    allowInDemoMode: true,
  },

  host_agent_versions: {
    type: 'select2',
    label: 'Agent Version(s)',
    matchString: 'Agent Versions',
    attribute: 'host_agent_versions',
    value: '',
    allowBlank: true,
    blankDisplayText: 'Any',
    selectOptions: {
      enableKeyboardShortcuts: true,
      multiple: true,
    },
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="host_agent_versions" />,
    allowInDemoMode: true,
  },
};
const additionalPatchFilters = {
  patch_keywords: {
    type: 'text',
    label: 'Patch Keywords',
    matchString: 'Patch Keywords',
    attribute: 'patch_keywords',
    placeholder: 'Filter patches by keyword...',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="patch_keywords" />,
    allowInDemoMode: true,
  },
};
const additionalVulnerabilityFilters = {
  vulnerability_keywords: {
    type: 'text',
    label: 'Vulnerability Keywords',
    matchString: 'Vulnerability Keywords',
    attribute: 'vulnerability_keywords',
    placeholder: 'Filter vulnerabilities by keyword...',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="vulnerability_keywords" />,
    allowInDemoMode: true,
  },
};
const additionalSignatureFilters = {
  signature_keywords: {
    type: 'text',
    label: 'Signature Keywords',
    matchString: 'Signature Keywords',
    attribute: 'signature_keywords',
    placeholder: 'Filter signatures by keyword...',
    // allowedComparisons: [ 'eq', 'not' ],
    allowedComparisons: [ 'eq' ],
    helpItem: <HelpTrigger helpKey="signature_keywords" />,
    allowInDemoMode: true,
  },
};


// filtered_risk, risk_reduction, risk_rating are common to all types, these are in addition to those
const additionalHostGroupFilters = {
  // filterd_risk: { },
  // risk_reduction: { },
  // risk_rating: { },
  last_scanned: {
    type: 'date',
    label: `Last ${ window.COMPANY_NAME } Scan`,
    matchString: `Last ${ window.COMPANY_NAME } Scan`,
    attribute: 'last_scanned',
    value: '',
    allowedComparisons: [ 'gt', 'lt' ],
    htmlProps: { min: '0' },
    allowInDemoMode: true,
  },
  tp_last_scanned: {
    type: 'date',
    label: 'Last Third Party Scan',
    matchString: 'Last Third Party Scan',
    attribute: 'tp_last_scanned',
    value: '',
    allowedComparisons: [ 'gt', 'lt' ],
    htmlProps: { min: '0' },
    allowInDemoMode: true,
  },
};
const additionalPatchGroupFilters = {
  // filterd_risk: { },
  // risk_reduction: { },
  // risk_rating: { },
  num_hosts: {
    type: 'number',
    label: 'Affected Hosts',
    matchString: 'Affected Hosts',
    attribute: 'num_hosts',
    value: '',
    allowedComparisons: [ 'gt', 'lt' ],
    htmlProps: { min: '0' },
    helpItem: <HelpTrigger helpKey="affected_hosts" />,
    allowInDemoMode: true,
  },
};
const additionalVulnerabilityGroupFilters = {
  // filterd_risk: { },
  // risk_reduction: { },
  // risk_rating: { },
  cvss_base_score: {
    type: 'number',
    label: 'CVSS Score',
    matchString: 'CVSS Score',
    attribute: 'cvss_base_score',
    value: '',
    allowedComparisons: [ 'gt', 'lt' ],
    htmlProps: { min: '0', max: '10' },
    helpItem: <HelpTrigger helpKey="cvss_base_score" />,
    allowInDemoMode: true,
  },
  num_hosts:{
    type: 'number',
    label: 'Affected Hosts',
    matchString: 'Affected Hosts',
    attribute: 'num_hosts',
    value: '',
    allowedComparisons: [ 'gt', 'lt' ],
    htmlProps: { min: '0' },
    helpItem: <HelpTrigger helpKey="affected_hosts" />,
    allowInDemoMode: true,
  },
};

// combined all filters for easier matchString retrieval
export const allCombinedAvailableFilters = {
  ...allAvailableTagFilters,
  ...allAvailableFilters,
  ...additionalHostGroupFilters,
  ...additionalPatchGroupFilters,
  ...additionalVulnerabilityGroupFilters,
  ...additionalHostFilters,
  ...additionalPatchFilters,
  ...additionalVulnerabilityFilters,
  ...additionalSignatureFilters,
};
// no additional filters for signature
// const additionalSignatureGroupFilters = {};

export const getAllAvailableFiltersForType = type => {
  if ( type === 'tag' ) {
    return allAvailableTagFilters;
  }
  const _allAvailableFilters = { ...allAvailableFilters };
  if ( type === 'host' ) {
    return { ..._allAvailableFilters, ...additionalHostFilters, ...additionalHostGroupFilters };
  }
  if ( type === 'patch' ) {
    return { ..._allAvailableFilters, ...additionalPatchFilters, ...additionalPatchGroupFilters };
  }
  if ( type === 'vulnerability' ) {
    return { ..._allAvailableFilters, ...additionalVulnerabilityFilters, ...additionalVulnerabilityGroupFilters };
  }
  if ( type === 'signature' ) {
    return { ..._allAvailableFilters, ...additionalSignatureFilters };
  }
  if ( type === 'instance' ) {
    return {
      ..._allAvailableFilters,
      ...additionalHostFilters,
      ...additionalPatchFilters,
      ...additionalVulnerabilityFilters,
      ...additionalSignatureFilters,
      ...additionalHostGroupFilters,
      ...additionalPatchGroupFilters,
      ...additionalVulnerabilityGroupFilters,
    };
  }
  return allAvailableFilters;
};

export const separateValues = [
  'exploit_status',
  'exploit_statuses',

  // asset tags needs to be done in the applied filter componenet, so that it can utilize the react context
  'asset_tag_ids',
  'third_party_setting_ids',

  // 'filters' in the tag editor (v3)
  'included_ranges',
  'included_host_patterns',
  'included_host_ids',
  'included_product_names',
  'excluded_ranges',
  'excluded_host_patterns',
  'excluded_host_ids',
  'excluded_product_names',
];

// 'filters' in the tag editor (v3) used to display values and a few other things
export const tagFilterAttributes = [
  'included_ranges',
  'included_host_patterns',
  'included_host_ids',
  'included_product_names',
  'excluded_ranges',
  'excluded_host_patterns',
  'excluded_host_ids',
  'excluded_product_names',
];

// 'filters' in the tag editor (v3) used to display values and a few other things
export const includedTagFilterAttributes = [
  'included_ranges',
  'included_host_patterns',
  'included_host_ids',
  'included_product_names',
];

// 'filters' in the tag editor (v3) used to display values and a few other things
export const excludedTagFilterAttributes = [
  'excluded_ranges',
  'excluded_host_patterns',
  'excluded_host_ids',
  'excluded_product_names',
];

export const needsRecordLabel = [
  'host_ids',
  'patch_ids',
  'vulnerability_ids',
  'signature_ids',
  'included_host_ids',
  'excluded_host_ids',
];

export const omitLabels = [
  ...needsRecordLabel,
  'asset_tag_ids',
  'third_party_setting_ids',
];

export const deprioritizedCategoryClasses = [
  'deprioritized',
  'overridden',
  'not_exploitable',
  'dos_only',
];
export const forReviewCategoryClasses = [
  'for_review',
  'missing_host',
  'unrecognized',
  'cannot_model',
  'insufficient_information',
  'missing_capability',
];
export const prioritizedCategoryClasses = [
  'prioritized',
  'carries_risk',
  'unreachable',
];

const recordTypeMap = type => {
  if ( type === 'scanner_signature' ) {
    return 'signature';
  }
  return type;
};

export const getRecordsForType = async( type, ids ) => {
  const params = {
    filters: {
      [`${type}_ids`]: ids,
    },
    rows: [ 0, ids.length ],
  };
  const records = await getRecords( recordTypeMap( type ), params, true, true, false );
  return records;
};

const getThirdPartySettings = async( settings ) => {
  if ( isNotEmpty( settings ) ) {
    const thirdPartyResponse = await makeRequest( 'SEARCH', '/project/default/third_party_setting', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'tool', 'settings', 'credential_id', 'category' ],
    } );


    if ( isNotEmpty( thirdPartyResponse ) && isNotEmpty( thirdPartyResponse.results ) ) {
      const appliedSettings = [];

      thirdPartyResponse.results.map( s => {
        if ( settings.includes( s.id ) ) {

          const setting = {
            icon: vulnerabilityScannerLogo( s.tool ),
            record: s,
          };

          const combined = { ...s, settings: { ...s.settings } };
          // eslint-disable-next-line max-len
          setting.label = <div className="vulnScannerInstanceNameWrapper">
            <strong>{shortenedVulnerabilityScannerNameMap[s.tool]}</strong>
            <span>({vulnerabilityScannerInstanceName( combined )})</span>
          </div>;

          appliedSettings.push( setting );
        }
      } );
      return appliedSettings;
    }
    return [];
  }
  return [];
};

export const appliedFiltersTransformMap = {
  published_before: { formatter: date => formatUnixDate( date ) },
  published_after: { formatter: date => formatUnixDate( date ) },
  // eslint-disable-next-line max-len
  exploit_status: { formatter: statuses => ( isNotEmpty( statuses ) && itemIsArray( statuses ) ) ? statuses.map( s => exploitStatusLabelMap[s] ) : [] },
  // eslint-disable-next-line max-len
  exploit_statuses: { formatter: statuses => ( isNotEmpty( statuses ) && itemIsArray( statuses ) ) ? statuses.map( s => exploitStatusLabelMap[s] ) : [] },
  host_ids: { isPromise: true, formatter: ids => getRecordsForType( 'host', ids ) },
  patch_ids: { isPromise: true, formatter: ids => getRecordsForType( 'patch', ids ) },
  vulnerability_ids: { isPromise: true, formatter: ids => getRecordsForType( 'vulnerability', ids ) },
  signature_ids: { isPromise: true, formatter: ids => getRecordsForType( 'scanner_signature', ids ) },
  // eslint-disable-next-line max-len
  asset_tag_ids: { formatter: ids => isNotEmpty( ids ) ? ids : [] },
  third_party_setting_ids: { isPromise: true, formatter: settings => getThirdPartySettings( settings ) },
  accepted_risk: { formatter: value => acceptedRiskOptions[value] },
  superseded: { formatter: value => superSededOptions[value] },

  age: { formatter: value => `${value} day(s)` },

  risk_reduction: { formatter: value => `${value}%` },
  num_hosts: { formatter: value => `${value} hosts` },
  cvss_base_score: { formatter: value => `${value}` },

  // 'filters' in the tag editor (v3) that also use the applied filter buttons
  included_ranges: { formatter: ips => isNotEmpty( ips ) ? ips : [] },
  excluded_ranges: { formatter: ips => isNotEmpty( ips ) ? ips : [] },

  included_host_patterns: { formatter: patterns => isNotEmpty( patterns ) ? patterns : [] },
  excluded_host_patterns: { formatter: ids => isNotEmpty( ids ) ? ids : [] },

  included_host_ids: { isPromise: true, formatter: ids => getRecordsForType( 'host', ids ) },
  excluded_host_ids: { isPromise: true, formatter: ids => getRecordsForType( 'host', ids ) },

  included_product_names: { formatter: versions => isNotEmpty( versions ) ? versions : [] },
  excluded_product_names: { formatter: versions => isNotEmpty( versions ) ? versions : [] },

  risk_type: { formatter: type => type === 'direct_risk' ? 'Direct' : 'Cumulative' },

  attack_scenario: { formatter: scenario => attackScenarioLabelMap[scenario] },

  category: { formatter: category => categoryLabelMap[category] },

  // date types
  vulnerability_created: { formatter: date => formatUnixDate( date ) },
  last_scanned: { formatter: date => formatUnixDate( date ) },
  tp_last_scanned: { formatter: date => formatUnixDate( date ) },
};