/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  debounce,
  formatUnixDate,
  globalColors,
  isEmpty,
  isNotEmpty,
  itemIsArray,
} from '../../../../../shared/Utilities';
import Area from '../../../../../shared/Charts/Area';
import XAxisLabels from '../../../../../shared/Charts/AxisLabels/XAxisLabels';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import YAxisLines from '../../../../../shared/Charts/AxisLines/YAxisLines';
import XAxisLines from '../../../../../shared/Charts/AxisLines/XAxisLines';
import Legend from '../../../../../shared/Charts/Legend';
import CenterBar from '../../../../../shared/Charts/CenterBar';

import './RiskOverTimeV3.scss';
import ChartHoverIndicators from '../../../../../shared/Charts/ChartHoverIndicators';
import EmptyState from '../../../../../shared/EmptyState';

// vertical lines representing a patch tuesday during the period of time represented on the graph
const PatchTuesdaysOverlay = ( { data, containerHeight, containerWidth } ) => {

  const [ dataPoints, setDataPoints ] = React.useState( null );

  const svgHeight = containerHeight;
  const svgWidth = containerWidth - 10;
  const xGutter = ( containerWidth - svgWidth ) / 2;

  React.useEffect( ( ) => {
    const { startDate, endDate, patchTuesdaysInRange } = data;

    const _dataPoints = [];

    if ( isNotEmpty( startDate ) && isNotEmpty( endDate ) && isNotEmpty( patchTuesdaysInRange ) ) {
      const widthRatio = svgWidth / ( endDate - startDate );
      patchTuesdaysInRange.map( timestamp => {
        const x = ( ( timestamp - startDate ) * widthRatio ) + xGutter;

        _dataPoints.push( { x, timestamp } );
      } );

      setDataPoints( _dataPoints );
    }
  }, [ data, containerHeight, containerWidth ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( dataPoints ) &&
        <React.Fragment>
          {
            dataPoints.map( ( p, i ) => {
              return <g
                className="patchTuesdayGroup"
                key={i}
              >
                <line
                  x1={ p.x || 0 }
                  x2={ p.x || 0 }
                  y1={ 0 }
                  y2={ svgHeight }
                  stroke={ globalColors['status--blue--60'] }
                  strokeDasharray="4 2"
                  strokeWidth={ 1 }
                />
              </g>;
            } )
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

const RiskOverTimeV3 = ( {
  item,
  settings,
  data,
  adjustSVGAspectRatio,
  svgAspectRatio,
  svgContainerRef,
} ) => {

  const [ xAxisLabels, setXAxisLabels ] = React.useState( null );
  const [ color, setColor ] = React.useState( 'darkBlue' );
  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( 400 );
  const svgContainerWidth = 1_000;

  const [ addedEscalationsMax, setAddedEscalationsMax ] = React.useState( null );
  const [ removedEscalationsMax, setRemovedEscalationsMax ] = React.useState( null );
  const [ addedHostsMax, setAddedHostsMax ] = React.useState( null );
  const [ removedHostsMax, setRemovedHostsMax ] = React.useState( null );
  const [ riskOverTimeData, setRiskOverTimeData ] = React.useState( null );
  const [ riskOverTimeLegendData, setRiskOverTimeLegendData ] = React.useState( null );
  const [ patchTuesdayData, setPatchTuesdayData ] = React.useState( null );

  const [ currentClickPoints, setCurrentClickPoints ] = React.useState( [] );
  const [ currentHoverPoints, setCurrentHoverPoints ] = React.useState( [] );

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {
      adjustSVGAspectRatio();
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 100 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, settings, riskOverTimeData ] );

  React.useEffect( () => {
    if ( isNotEmpty( svgAspectRatio ) && svgAspectRatio !== 0 ) {
      setSVGContainerHeight( svgContainerWidth / svgAspectRatio );
    } else {
      setSVGContainerHeight( 400 );
    }
  }, [ svgAspectRatio ] );

  const setupData = async ( data ) => {

    let firstTimestamp;
    let lastTimeStamp;

    const formatData = ( riskData ) => {
      let addedE = 1;
      let removedE = 1;
      let addedH = 1;
      let removedH = 1;

      // loop through the data and set the max of all relevant values
      Object.values( riskData.transformed ).map( ( point, index ) => {

        if ( isEmpty( firstTimestamp ) || point.timestamp < firstTimestamp ) {
          firstTimestamp = point.timestamp;
        }
        if ( isEmpty( lastTimeStamp ) || point.timestamp > lastTimeStamp ) {
          lastTimeStamp = point.timestamp;
        }
        const aE = Math.abs( point.addedEscalations );
        const rE = Math.abs( point.removedEscalations );
        const aH = Math.abs( point.addedHosts );
        const rH = Math.abs( point.removedHosts );

        if ( aE >= addedE ) {
          addedE = aE;
        }
        if ( rE >= removedE ) {
          removedE = rE;
        }
        if ( aH >= addedH ) {
          addedH = aH;
        }
        if ( rH >= removedH ) {
          removedH = rH;
        }

        setAddedEscalationsMax( addedE );
        setRemovedEscalationsMax( removedE );
        setAddedHostsMax( addedH );
        setRemovedHostsMax( removedH );

        if ( index === Object.values( riskData.transformed ).length - 1 ) {
          // const _color = globalRiskColor( point.risk, projectData.settings.risk_target );
          setColor( 'darkBlue' );
        }
      } );

      if ( isNotEmpty( firstTimestamp ) && isNotEmpty( lastTimeStamp ) ) {
        const xlabels = [];

        const timestampDelta = lastTimeStamp - firstTimestamp;

        xlabels.push( formatUnixDate( firstTimestamp ) );
        xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 2 / 6 ) ) ) );
        xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 3 / 6 ) ) ) );
        xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 4 / 6 ) ) ) );
        xlabels.push( formatUnixDate( firstTimestamp + ( timestampDelta * ( 5 / 6 ) ) ) );
        xlabels.push( formatUnixDate( lastTimeStamp ) );

        setXAxisLabels( xlabels );
      }

      setRiskOverTimeData( riskData );
    };

    const setupPatchTuesdayData = riskData => {
      if ( isNotEmpty( riskData ) ) {

        const startDate = riskData.original[0].timestamp * 1_000;
        const endDate = riskData.original[riskData.original.length - 1].timestamp * 1_000;

        let currentDate = startDate;
        const patchTuesdaysInRange = [];

        // going through every day between the start and end of the ranges
        while ( currentDate <= endDate ) {
          const date = new Date( currentDate );
          date.setHours( 0, 0, 0, 0 );
          const dow = date.getDay();
          const dom = date.getDate();
          // if the day of the week is tuesday (2) and it is not the first tuesday ( > 7 ) and is
          // the second tuesday ( <= 14 ) then add it is a patch tuesday (second tuesdy of each month)
          if ( dow === 2 && ( dom > 7 && dom <= 14 ) ) {
            patchTuesdaysInRange.push( currentDate );
          }
          currentDate = date.setDate( date.getDate() + 1 );
        }

        setPatchTuesdayData( { startDate, endDate, patchTuesdaysInRange } );
      }
    };

    if (
      isNotEmpty( data )
      && isNotEmpty( data.risk )
      && isNotEmpty( data.project )
    ) {
      formatData( data.risk, data.project );
      if ( isNotEmpty( settings ) && settings?.patch_tuesday === true ) {
        setupPatchTuesdayData( data.risk );
      }

      const legendData = {
        risk: {
          fill: globalColors['darkBlue'],
          stroke: globalColors['darkBlue'],
          label: 'Risk Score',
        },
      };

      if ( settings?.include_legend ) {
        if ( settings?.patch_tuesday === true ) {
          // eslint-disable-next-line camelcase
          legendData.patch_tuesday = {
            fill: '#ffffff',
            stroke: globalColors['status--blue--60'],
            label: 'Patch Tuesday',
            dashed: true,
          };
        }
        if ( settings?.include_hosts === true ) {
          // eslint-disable-next-line camelcase
          legendData.added_hosts = {
            fill: globalColors['status--blue'],
            stroke: globalColors['status--blue'],
            label: 'Added Hosts',
          };
          // eslint-disable-next-line camelcase
          legendData.removed_hosts = {
            fill: globalColors['status--yellow'],
            stroke: globalColors['status--yellow'],
            label: 'Removed Hosts',
          };
        }
        if ( settings?.include_escalations === true ) {
          // eslint-disable-next-line camelcase
          legendData.added_instances = {
            fill: globalColors['status--red'],
            stroke: globalColors['status--red'],
            label: 'Added Instances',
          };
          // eslint-disable-next-line camelcase
          legendData.removed_instances = {
            fill: globalColors['status--green'],
            stroke: globalColors['status--green'],
            label: 'Removed Instances',
          };
        }
      }
      setRiskOverTimeLegendData( legendData );
    }
  };

  const onHoverCallback = ( points ) => {
    if ( isNotEmpty( points ) && itemIsArray( points ) ) {
      setCurrentHoverPoints( points );
    } else {
      setCurrentHoverPoints( [] );
    }
  };

  const onClickCallback = ( points ) => {
    if ( isNotEmpty( points ) && itemIsArray( points ) ) {
      setCurrentClickPoints( points );
    } else {
      setCurrentClickPoints( [] );
    }
  };

  // find the max values for the different charts when the data comes in
  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) ) {
      setupData( data );
    }
  }, [ data, settings, item ] );

  const getHeightClass = settings => {
    if ( isNotEmpty( settings ) ) {
      if ( settings.include_escalations ) {
        if ( settings.include_hosts ) {
          return '100';
        }
        return '75';
      } else if ( settings.include_hosts ) {
        return '75';
      }
      return '50';
    }
    return '50';
  };

  const getAreaHeight = settings => {
    if ( isNotEmpty( settings ) ) {
      if ( settings.include_escalations ) {
        if ( settings.include_hosts ) {
          return '50';
        }
        return '66';
      } else if ( settings.include_hosts ) {
        return '66';
      }
      return '100';
    }
    return '100';
  };
  return (
    <div className={ `riskOverTimeV3Wrapper areaHeight--${getAreaHeight( settings )}`}>
      {
        ( isNotEmpty( riskOverTimeData ) && isNotEmpty( color ) )
          ? <div
            id={ `riskOverTimeSVGWrapper-${item.i}` }
            className={ `${settings?.include_legend ? 'withLegend' : '' } riskOverTimeAreaWrapper` }
            ref={svgContainerRef}
          >
            <YAxisLabels yMax={ riskOverTimeData?.max } />
            <YAxisLines lineCount={ 3 } />
            {
              ( isNotEmpty( svgContainerHeight ) && isNotEmpty( svgContainerWidth ) ) &&
              <svg
                viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight / 2 }` }
                xmlns="http://www.w3.org/2000/svg"
                className={ `areaSVG ${ getAreaHeight( settings ) === '100' ? 'noDash' : '' }`}
                id="areaPlusBarWrapper"
                preserveAspectRatio="none"
              >
                <Area
                  data={ riskOverTimeData }
                  stroke={ color || 'darkBlue' }
                  fill={ color || 'darkBlue' }
                  containerHeight={ svgContainerHeight / 2 }
                  containerWidth={ svgContainerWidth }
                  withoutWrapper
                  fullHeight
                  noXAxis
                  adjustYMin
                />
              </svg>
            }
            {
              ( settings?.include_legend ) &&
              <Legend legendData={ riskOverTimeLegendData } />
            }
          </div>
          : <EmptyState message="No risk over time data available" />
      }
      {
        ( isNotEmpty( settings?.include_hosts ) && isNotEmpty( riskOverTimeData ) ) &&
        <div className={ `${ settings?.include_legend ? 'withLegend' : '' } centerBarWrapper hosts` }>
          <svg
            viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight / 4 }` }
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <CenterBar
              data={ riskOverTimeData }
              max={ Math.max( addedHostsMax, removedHostsMax ) }
              upperKey="addedHosts"
              lowerKey="removedHosts"
              upperFill={ globalColors['status--blue'] }
              lowerFill={ globalColors['status--yellow'] }
              elementClass="hostsOverTime"
              withoutWrapper
              containerHeight={ svgContainerHeight / 4 }
              containerWidth={ svgContainerWidth }
            />
          </svg>
        </div>
      }
      {
        ( isNotEmpty( settings?.include_escalations )  && isNotEmpty( riskOverTimeData ) ) &&
        <div className={ `${ settings?.include_legend ? 'withLegend' : '' } centerBarWrapper instances` }>
          <svg
            viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight / 4 }` }
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <CenterBar
              data={ riskOverTimeData }
              max={ Math.max( addedEscalationsMax, removedEscalationsMax ) }
              upperKey="addedEscalations"
              lowerKey="removedEscalations"
              elementClass="escalationsOverTime"
              withoutWrapper
              upperFill={ globalColors['status--red'] }
              lowerFill={ globalColors['status--green'] }
              containerHeight={ svgContainerHeight / 4 }
              containerWidth={ svgContainerWidth }
            />
          </svg>
        </div>
      }
      {/* hover indicators and patchtuesday, will be absolutely positioned right on top of the content */}
      <div
        className={ `${settings?.include_legend ? 'withLegend' : ''} patchTuesdayAndHoverIndicatorsWrapper`}
      >
        <svg
          viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight }` }
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"

        >
          {
            ( settings?.patch_tuesday && isNotEmpty( patchTuesdayData ) ) &&
            <PatchTuesdaysOverlay
              data={ patchTuesdayData }
              containerHeight={ svgContainerHeight }
              containerWidth={ svgContainerWidth }
            />
          }
        </svg>
        <ChartHoverIndicators
          data={ riskOverTimeData }
          onClickCallback={ onClickCallback }
          onHoverCallback={ onHoverCallback }
          currentHoverPoints={currentHoverPoints}
          currentClickPoints={currentClickPoints}
          fill="darkBlue"
          stroke="darkBlue"
          containerHeight={ svgContainerHeight }
          containerWidth={ svgContainerWidth }
          heightClass={ getHeightClass( settings ) }
          areaHeight={ getAreaHeight( settings ) }
          areaPosition="top"
          withLegend={ settings.include_legend }
          withXAxis
          recordType="riskOverTime"
          svgContainerRef={svgContainerRef}
        />
      </div>
      {
        isNotEmpty( xAxisLabels ) &&
        <XAxisLines
          lineCount={xAxisLabels.length - 1}
          variant="area"
          elementClass={ `${ settings?.include_legend ? '' : 'fullWidth'}`}
        />
      }
      {
        isNotEmpty( xAxisLabels ) &&
        <XAxisLabels
          labels={xAxisLabels}
          variant="area"
          elementClass={ `${ settings?.include_legend ? '' : 'fullWidth'}`}
        />
      }
    </div>
  );
};

export default RiskOverTimeV3;