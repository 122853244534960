/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  complianceFrameworkLabelMap,
  debounce,
  isEmpty,
  isNotEmpty,
  riskToRating,
} from '../../../../../shared/Utilities';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import Legend from '../../../../../shared/Charts/Legend';

import './Comparison.scss';

import EmptyState from '../../../../../shared/EmptyState';
import InlineSVG from '../../../../../shared/InlineSVG';
import Bar from '../../../../../shared/Charts/Bar';
import HorizontalBar from '../../../../../shared/Charts/HorizontalBar';
import { getThemeColor } from '../../../../../shared/Themes';
// import XAxisLabels from '../../../../../shared/Charts/AxisLabels/XAxisLabels';

const ComparisonCompliance = ( {
  item,
  settings,
  data,
  svgAspectRatio,
  adjustSVGAspectRatio,
  svgContainerRef,
} ) => {
  const [ widgetVersion, setWidgetVersion ] = React.useState( null );
  const [ trendDirection, setTrendDirection ] = React.useState( 'up' );
  const [ trendDelta, setTrendDelta ] = React.useState( 0 );
  const [ chartData, setChartData ] = React.useState( null );

  const [ yMax, setYMax ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {
      adjustSVGAspectRatio();
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 100 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, widgetVersion, chartData, yMax ] );

  // we need to figure out what data to look and format the data
  // according to how it needs to be displayed
  React.useEffect( () => {
    if (
      isNotEmpty( settings )
      && isNotEmpty( data )
      && isNotEmpty( item )
    ) {
      if ( isNotEmpty( data ) ) {
        let _yMax;
        let sortedControls = [];
        let _widgetVersion = 'barchart';
        let _chartData = {};
        let _trendDirection = 'up';
        let _trendDelta = 0;

        const comparisonLabelMap = {
          day: 'Yesterday',
          week: 'Last Week',
          month: 'Last Month',
          quarter: 'Last Quarter',
          year: 'Last Year',
        };

        // both the current and comparison widgets use the same widget for simplicity, the current version will be
        // a horizontal barchart
        if ( item.key === 'compliance_current' ) {
          const _groupedComplianceData = {};

          _widgetVersion = 'horizontal';

          if ( isNotEmpty( data ) && isNotEmpty( data.end ) ) {
            data.end.map( point => {
              if ( isNotEmpty( point ) && isNotEmpty( point.regulation ) && isNotEmpty( point.created ) ) {
                // very first one
                if ( isEmpty( _groupedComplianceData[point.created] ) ) {
                  _groupedComplianceData[point.created] = { [point.regulation]: { [point.control]: point } };
                // timestamp already exists, first of this regulation
                } else if (
                  isNotEmpty( _groupedComplianceData[point.created] )
                  && isEmpty( _groupedComplianceData[point.created][point.regulation] )
                ) {
                  _groupedComplianceData[point.created][point.regulation] = { [point.control]: point };
                // timestamp and regulation already exist
                } else {
                  _groupedComplianceData[point.created][point.regulation][point.control] = point;
                }
              }
            } );

            if ( isNotEmpty( _groupedComplianceData ) ) {
              const mostCurrent = Math.max( ...Object.keys( _groupedComplianceData ) );
              if (
                isNotEmpty( settings )
                && isNotEmpty( settings.regulation )
                && isNotEmpty( settings.control_segment )
                && isNotEmpty( mostCurrent )
                && isNotEmpty( _groupedComplianceData[mostCurrent] )
                && isNotEmpty( _groupedComplianceData[mostCurrent][settings.regulation] )
              ) {
                Object.values( _groupedComplianceData[mostCurrent][settings.regulation] ).map( control => {
                  const controlWithPercentage = {
                    ...control,
                    passingPercentage: control.num_passing_hosts + control.num_failing_hosts === 0
                      ? 0
                      : control.num_passing_hosts / ( control.num_passing_hosts + control.num_failing_hosts ),
                  };
                  sortedControls.push( controlWithPercentage );
                } );

                if ( settings?.control_segment === 'lowest' ) {
                  sortedControls = sortedControls.sort( ( a, b ) => a.passingPercentage - b.passingPercentage );
                } else {
                  sortedControls = sortedControls.sort( ( a, b ) => b.passingPercentage - a.passingPercentage );
                }

                if ( isNotEmpty( settings.item_count ) ) {
                  sortedControls = sortedControls.slice( 0, parseInt( settings.item_count ) );
                }

                // resort after slicing to get the lowest on the bottom
                sortedControls = sortedControls.sort( ( a, b ) => b.passingPercentage - a.passingPercentage );

                _yMax = Math.max( ...sortedControls.map( c => ( c.passingPercentage * 100 ).toFixed( 2 ) ) );

                sortedControls.map( control => {
                  _chartData[control.control] = {
                    // eslint-disable-next-line max-len
                    label: <span>{ control.control } <strong>({ ( control.passingPercentage * 100 ).toFixed( 2 ) }%)</strong></span>,
                    stroke: settings.control_segment === 'highest'
                      ? getThemeColor( '--status-success' )
                      : getThemeColor( '--status-alert' ),
                    fill: settings.control_segment === 'highest'
                      ? getThemeColor( '--status-success' )
                      : getThemeColor( '--status-alert' ),
                    value: ( control.passingPercentage * 100 ).toFixed( 2 ),
                    originalCount: ( control.passingPercentage * 100 ).toFixed( 2 ),
                    total: ( control.passingPercentage * 100 ).toFixed( 2 ),
                    key: control.control,
                  };
                } );
              }
            }
          }

        }
        // compliance comparison will be an aggregate number or single barchart (like the default analysis comparison)
        if ( item.key === 'compliance_comparison' ) {
          const _groupedStartComplianceData = {};
          const _groupedEndComplianceData = {};

          let comparisonPoint = {};
          let currentPoint = {};
          _widgetVersion = settings?.comparison_version;

          // there has to at least be an end (current point, or there is nothing to show)
          if ( isNotEmpty( data.end ) ) {
            data.end.map( point => {
              if ( isNotEmpty( point ) && isNotEmpty( point.regulation ) && isNotEmpty( point.created ) ) {
                // very first one
                if ( isEmpty( _groupedEndComplianceData[point.created] ) ) {
                  _groupedEndComplianceData[point.created] = { [point.regulation]: { [point.control]: point } };
                // timestamp already exists, first of this regulation
                } else if (
                  isNotEmpty( _groupedEndComplianceData[point.created] )
                  && isEmpty( _groupedEndComplianceData[point.created][point.regulation] )
                ) {
                  _groupedEndComplianceData[point.created][point.regulation] = { [point.control]: point };
                // timestamp and regulation already exist
                } else {
                  _groupedEndComplianceData[point.created][point.regulation][point.control] = point;
                }
              }
            } );

            if ( isNotEmpty( _groupedEndComplianceData ) ) {
              const mostCurrent = Math.max( ...Object.keys( _groupedEndComplianceData ) );

              if ( isNotEmpty( mostCurrent ) ) {
                if (
                  isNotEmpty( settings )
                  && isNotEmpty( settings.regulation )
                  && isNotEmpty( mostCurrent )
                  && isNotEmpty( _groupedEndComplianceData[mostCurrent] )
                  && isNotEmpty( _groupedEndComplianceData[mostCurrent][settings.regulation] )
                ) {
                  const currentGroup = Object.values( _groupedEndComplianceData[mostCurrent][settings.regulation] );

                  const percents = currentGroup.map( control => {
                    if ( control.num_passing_hosts + control.num_failing_hosts === 0 ) {
                      return 0;
                    }
                    return control.num_passing_hosts / ( control.num_passing_hosts + control.num_failing_hosts );
                  } );

                  if ( isNotEmpty( percents ) && isNotEmpty( currentGroup.length ) && currentGroup.length > 0 ) {
                    const sumPercents = percents.reduce( ( accum, current ) => accum + current, 0 );
                    const averagePercent = sumPercents / currentGroup.length;
                    currentPoint = {
                      label: 'Current',
                      stroke: getThemeColor( '--text-color-secondary' ),
                      fill: getThemeColor( '--text-color-secondary' ),
                      value: averagePercent * 100,
                      originalCount: averagePercent * 100,
                      total: `${( averagePercent * 100 ).toFixed( 2 )}%`,
                      key: 'current',
                    };
                  }
                }
              }
            }

            if ( isNotEmpty( data.start ) ) {
              data.start.map( point => {
                if ( isNotEmpty( point ) && isNotEmpty( point.regulation ) && isNotEmpty( point.created ) ) {
                  // very first one
                  if ( isEmpty( _groupedStartComplianceData[point.created] ) ) {
                    _groupedStartComplianceData[point.created] = { [point.regulation]: { [point.control]: point } };
                  // timestamp already exists, first of this regulation
                  } else if (
                    isNotEmpty( _groupedStartComplianceData[point.created] )
                    && isEmpty( _groupedStartComplianceData[point.created][point.regulation] )
                  ) {
                    _groupedStartComplianceData[point.created][point.regulation] = { [point.control]: point };
                  // timestamp and regulation already exist
                  } else {
                    _groupedStartComplianceData[point.created][point.regulation][point.control] = point;
                  }
                }
              } );

              if ( isNotEmpty( _groupedStartComplianceData ) ) {
                const mostCurrent = Math.max( ...Object.keys( _groupedStartComplianceData ) );

                if ( isNotEmpty( mostCurrent ) ) {
                  if (
                    isNotEmpty( settings )
                    && isNotEmpty( settings.regulation )
                    && isNotEmpty( mostCurrent )
                    && isNotEmpty( _groupedStartComplianceData[mostCurrent] )
                    && isNotEmpty( _groupedStartComplianceData[mostCurrent][settings.regulation] )
                  ) {
                    const currentGroup = Object.values( _groupedStartComplianceData[mostCurrent][settings.regulation] );

                    const percents = currentGroup.map( control => {
                      if ( control.num_passing_hosts + control.num_failing_hosts === 0 ) {
                        return 0;
                      }
                      return control.num_passing_hosts / ( control.num_passing_hosts + control.num_failing_hosts );
                    } );

                    if ( isNotEmpty( percents ) && isNotEmpty( currentGroup.length ) && currentGroup.length > 0 ) {
                      const sumPercents = percents.reduce( ( accum, current ) => accum + current, 0 );
                      const averagePercent = sumPercents / currentGroup.length;
                      comparisonPoint = {
                        label: comparisonLabelMap[settings?.comparison_date || 'month' ],
                        stroke: getThemeColor( '--text-color-secondary' ),
                        fill: getThemeColor( '--text-color-secondary' ),
                        outline: true,
                        value: averagePercent * 100,
                        originalCount: averagePercent * 100,
                        total: `${( averagePercent * 100 ).toFixed( 2 )}%`,
                        key: 'comparison',
                      };
                    }
                  }
                }
              }

              if (
                isNotEmpty( comparisonPoint )
                && isNotEmpty( currentPoint )
              ) {
                if ( currentPoint.value === comparisonPoint.value ) {
                  _trendDirection = 'even';
                  _trendDelta = 0;
                } else if ( currentPoint.value > comparisonPoint.value ) {
                  _trendDirection = 'up';
                  _trendDelta = currentPoint.value - comparisonPoint.value;
                } else {
                  _trendDirection = 'down';
                  _trendDelta = comparisonPoint.value - currentPoint.value;
                }

                if ( settings.report_type === 'risk' ) {
                  _trendDelta = Math.floor( _trendDelta );
                }

                const strokeForTrendDirection = {
                  up: getThemeColor( '--status-success--75' ),
                  down: getThemeColor( '--status-alert--75' ),
                  even: getThemeColor( '--text-color-secondary' ),
                };

                const comparisonFillForTrendDirection = {
                  up: getThemeColor( 'status--green--10' ),
                  down: getThemeColor( '--status-alert--10' ),
                  even: getThemeColor( '--divider-color' ),
                };

                currentPoint.stroke = strokeForTrendDirection[_trendDirection];
                currentPoint.fill = strokeForTrendDirection[_trendDirection];
                comparisonPoint.stroke = strokeForTrendDirection[_trendDirection];
                comparisonPoint.fill = comparisonFillForTrendDirection[_trendDirection];
              }
            // nothing to compare to
            } else {
              _trendDelta = 'unknown';
              _trendDirection = 'unknown';
              if ( isNotEmpty( _chartData ) && isNotEmpty( currentPoint ) ) {
                currentPoint.fill = getThemeColor( '--text-color-secondary' );
                currentPoint.stroke = getThemeColor( '--text-color-secondary' );
              }
              comparisonPoint = {
                label: comparisonLabelMap[settings?.comparison_date || 'month' ],
                stroke: getThemeColor( '--text-color-secondary' ),
                fill: getThemeColor( '--divider-color' ),
                outline: true,
                value: 0,
                originalCount: 0,
                total: 0,
                isUnknown: true,
                key: 'comparison',
              };
            }

            _yMax = Math.max( currentPoint.value, comparisonPoint.current );

            _chartData = {
              comparison: comparisonPoint,
              current: currentPoint,
            };
          }
        }
        setTrendDelta( _trendDelta );
        setTrendDirection( _trendDirection );
        setWidgetVersion( _widgetVersion );
        setYMax( _yMax );
        setChartData( _chartData );
      }
    }
  }, [ settings, data, item ] );

  const getHeaderForNumberVariant = reportType => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'compliance' ) {
        return <React.Fragment>
          <strong>{complianceFrameworkLabelMap[settings?.regulation]}: </strong><span>Average Passing Rate</span>
        </React.Fragment>;
      }
      return 'Current Count';
    }
    return 'Current Count';
  };

  const getIconForNumberVariant = reportType => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'compliance' ) {
        return <InlineSVG type="compliance" />;
      }
      return <InlineSVG type="dsRisk" />;
    }
    return <InlineSVG type="dsRisk" />;
  };

  const wordsForComparisonDate = key => {
    if ( isNotEmpty( key ) ) {
      if ( key === 'day' ) {
        return 'yesterday';
      }
      if ( key === 'week' ) {
        return 'last week';
      }
      if ( key === 'month' ) {
        return 'last month';
      }
      if ( key === 'quarter' ) {
        return 'last quarter';
      }
      return 'last year';
    }
    return 'last month';
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( chartData ) && isNotEmpty( widgetVersion ) && isNotEmpty( yMax ) )
          ? <React.Fragment>
            {
              widgetVersion === 'number' &&
              <div
                id={ `historyOverTimeSVGWrapper-${item.i}` }
                className="overtimeNumberWrapper"
              >
                <h2>{ getHeaderForNumberVariant( settings?.report_type ) }</h2>
                <div
                  // eslint-disable-next-line max-len
                  className={ `countWrapper ${settings?.report_type} ${ ( settings?.report_type === 'risk' && isNotEmpty( chartData.current?.value ) ) ? `riskClass--${ riskToRating( chartData.current.value ) }` : '' }` }
                >
                  { getIconForNumberVariant( settings?.report_type ) }
                  <strong>{ ( chartData.current?.value || 0 ).toFixed( 2 )}%</strong>
                </div>
                <div className="trendWrapper">
                  <div className={ `trendIconWrapper ${trendDirection} compliance` }>
                    {
                      ( trendDirection === 'even' || trendDirection === 'unknown' )
                        ? <span>--</span>
                        : <InlineSVG type={ `trending_${trendDirection}` } />
                    }
                  </div>
                  <span>
                    {
                      trendDirection === 'unknown'
                        // eslint-disable-next-line max-len
                        ? `insufficient data to show comparison to ${ wordsForComparisonDate( settings?.comparison_date ) }`
                        : <React.Fragment>
                          {/* eslint-disable-next-line max-len */}
                          { trendDirection === 'down' ? 'Decreased' : 'Increased' } by <strong>{ ( trendDelta ).toFixed( 2 ) }%</strong> since { wordsForComparisonDate( settings?.comparison_date ) }
                        </React.Fragment>
                    }

                  </span>
                </div>
              </div>
            }
            {
              widgetVersion === 'barchart' &&
              <div
                id={ `historyOverTimeSVGWrapper-${item.i}` }
                className="overtimeBarWrapper"
                ref={svgContainerRef}
              >
                <YAxisLabels percentageTics ticsCount={ 4 } />
                <Bar
                  data={chartData}
                  hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                  svgAspectRatio={svgAspectRatio}
                />
                <Legend
                  legendData={chartData}
                  hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                />
              </div>
            }

            {
              widgetVersion === 'horizontal' &&
              <div
                id={ `curentComparisonContentWrapper-${item.i}` }
                className="curentComparisonContentWrapper"
                ref={svgContainerRef}
              >
                <YAxisLabels percentageTics ticsCount={ 4 } />
                {/* <XAxisLabels withTic above labels={ [ '25%', '50%', '75%', '100%' ] } variant="area" /> */}
                <HorizontalBar
                  data={chartData}
                  hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                  svgAspectRatio={svgAspectRatio}
                  maxOverride={ 100 }
                  gradient
                  reverseGradient={ settings.control_segment === 'lowest' }
                />
                {/* {
                  Object.values( chartData ).length > 1 &&
                  <XAxisLabels withTic labels={ [ '25%', '50%', '75%', '100%' ] } variant="area" />
                } */}
              </div>
            }
          </React.Fragment>
          : <EmptyState message="Insufficient data" />
      }
    </React.Fragment>
  );
};

export default ComparisonCompliance;