/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

import DataTable from '../../../shared/DataTable';

import InlineSVG from '../../../shared/InlineSVG';

import {
  isNotEmpty,
  isEmpty,
  reportTypeDisplayName,
  riskToRating,
  formatNumber,
  decodeURLHash,
  paramsToFilters,
  removeFromURLHash,
  encodeURLHash,
  vulnScannerNameMap,
  vulnerabilityScannerLogo,
  singularizeItem,
  // cvssScoreToRating,
} from '../../../shared/Utilities';

import './style.scss';
import Loading from '../../../shared/Loading';
import ExploitStatus from '../../../shared/ExploitStatus';
import { featureAccessLevel, hasFeatureAccess } from '../../App/AccessControl';
import { availableColumnsByType, remediationItemsKey } from './shared';
import RatingBadge from '../../../shared/RatingBadge';
import TagList from '../Tags/List';
import { TagsContext } from '../../../Contexts/Tags';
import RiskReduction from '../../../shared/RiskReduction';
import RecordCard from '../../RecordDetails/RecordCard';
import ScanningStatusIndicator from '../../RecordDetails/ScanningStatusIndicator';
import Pagination from '../../../shared/Pagination';
import IndeterminantPagination from '../../../shared/Pagination/IndeterminantPagination';
import { recordRiskRating } from '../../RecordDetails/shared';
import TableHeader from './TableHeader';
import { columnsForType, defaultOrderByString, defaultRiskTypeString, defaultRowsString } from '../../App/Routing';
import { transformHashToFilters } from '../../../shared/RecordCache';

const InsightTable = ( {
  records,
  nextRecords=[], // for legacy pagination
  currentPageNumber=1, // for legacy pagination
  useLegacyPagination=false,
  reportType, // passed in, host, vulnerability, patch, path, user, instance
  loading=false,
  selectRecord,
  hoverRecord,
  setHoverRecord,
  onRefresh,
  visualCollapsed,
  riskType='risk',
  setSelectedSignature,
  remediationItems,
  setRemediationItems,
  recordCount,
  tallyCount,
  // appliedFilters,
  setAppliedFilters,
} ) => {

  const [ adjustedData, setAdjustedData ] = React.useState( [] );
  const [ recordType, setRecordType ] = React.useState( null );

  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const [ tags ] = React.useContext( TagsContext );

  // for recordCard
  const [ recordCardRecord, setRecordCardRecord ] = React.useState( null );
  const [ recordCardType, setRecordCardType ] = React.useState( null );
  const [ showRecordCard, setShowRecordCard ] = React.useState( null );

  const tableWrapperRef = React.useRef( null );

  const viewRecord = ( e, record, type ) => {
    setRecordCardRecord( { ...record, clickEvent: e } );
    setRecordCardType( type );
    setShowRecordCard( true );
  };

  React.useEffect( () => {
    if ( showRecordCard === false ) {
      setRecordCardRecord( null );
      setRecordCardType( null );
    }
  }, [ showRecordCard ] );

  // source of truth for which columns are sortable by report type (used for all except instances)
  const recordTypeSortableColumns = {
    /* eslint-disable camelcase */
    host: {
      risk: 'filtered_risk',
      name: 'local_name',
      vulnerabilities: 'num_vulnerabilities',
      all_patches: 'num_patches',
      unsuperseded_patches: 'num_unsuperseded_patches',
      product_name: 'product_name',
      'Scanning Status': 'last_scanned',
      agent_version: 'agent_version',
    },
    patch: {
      risk: 'filtered_risk',
      name: 'identifier',
      // superseded_patches: 'num_superseded_patches',
      affected_hosts: 'num_hosts',
      vulnerabilities: 'num_vulnerabilities',
    },
    vulnerability: {
      risk: 'filtered_risk',
      name: 'identifier',
      exploit_status: 'exploit_status',
      affected_hosts: 'num_hosts',
      // all_patches: 'num_patches',
      // unsuperseded_patches: 'num_unsuperseded_patches',
      'CVSS': 'cvss_base_score',
    },
    path: {
      risk: 'risk',
    },
    user: {
      risk: 'risk',
      // name: 'name',
    },
    signature: {
      risk: 'filtered_risk',
      scanner_signature: 'scanner',
      hosts: 'num_hosts',
      vulns: 'num_vulnerabilities',
    },
    /* eslint-enable camelcase */
  };

  const formatIpAddresses = addresses => {
    const formatted = [];
    if ( addresses ) {
      addresses.map( address => {
        return formatted.push( address.split( '/' )[0] );
      } );
    }
    return formatted.join( ', ' );
  };

  const insightGoToRecord = ( row, _groupType ) => {
    let reportType;
    if ( _groupType === 'host' ) {
      reportType = 'hosts';
    } else if ( _groupType === 'patch' || _groupType === 'patch_cumulative' ) {
      reportType = 'patches';
    } else {
      reportType = 'vulnerabilities';
    }

    const hash = decodeURLHash();

    if ( hash.report === 'instances' && isNotEmpty( _groupType ) ) {
      // eslint-disable-next-line max-len
      window.location.href = `#.=risk_insight&report=${reportType}${defaultRowsString}${defaultOrderByString}&item=${row.id}${ reportType === 'patches' ? '&risk_type=cumulative_risk' : defaultRiskTypeString }${columnsForType[ singularizeItem( reportType ) ]}`;
    } else if ( isNotEmpty( selectRecord ) ) {
      selectRecord( row.id );
    }
  };

  const reportTypeNameLink = ( row, _groupType ) => {
    if (
      isEmpty( row.id )
    ) {
      return <span className="notLink">
        {
          (
            ( ( reportType === 'host' ) && !row.has_host )
            || ( reportType === 'instance' && _groupType === 'host' && !row.has_host )
          ) &&
          <div className="iconWrapper notScanned">
            <InlineSVG type="notScanned" />
          </div>
        }
        { reportTypeDisplayName( row, _groupType ) }
      </span>;
    }
    return <div
      className="nameLink"
      onClick={ () => insightGoToRecord( row, _groupType ) }
    >
      {
        (
          ( ( reportType === 'host' ) && !row.has_host )
          || ( reportType === 'instance' && _groupType === 'host' && !row.has_host )
        ) &&
        <div className="iconWrapper notScanned">
          <InlineSVG type="notScanned" />
        </div>
      }
      { reportTypeDisplayName( row, _groupType || reportType ) }
    </div>;
  };

  // remediation selection functions
  const onRemediationSelect = id => {
    if ( includeRemediationSelect( licenseInfo ) ) {
      const rKey = remediationItemsKey( reportType );
      const selectedIDs = Object.keys( remediationItems[rKey] );
      if ( selectedIDs.includes( id ) ) {

        delete remediationItems[rKey][id];
        setRemediationItems( {
          ...remediationItems,
          [rKey]: remediationItems[rKey],
        } );
      } else {
        const newItem = records.find( r => r.id === id );
        setRemediationItems( {
          ...remediationItems,
          [rKey]: { ...remediationItems[rKey], [id]: newItem },
        } );
      }
    } else {
      return false;
    }
  };

  const setRemediationItemsForType = ids => {
    const rKey = remediationItemsKey( reportType );
    const _items = {};

    if ( isNotEmpty( ids ) ) {
      ids.map( id => {
        _items[id] = records.find( r => r.id === id );
      } );
    }
    setRemediationItems( { ...remediationItems, [rKey]: _items } );
  };

  // The add/remove button for each row in the table (instances)
  const AddRemoveFilterAction = ( { row } ) => {

    const hash = decodeURLHash();

    let _groupType = hash.group_type || 'host';

    if ( _groupType === 'patch_cumulative' ) {
      _groupType = 'patch';
    }

    const existingFilter = hash[`${_groupType}_ids`] && hash[`${_groupType}_ids`].includes( row.id );

    const addOrRemoveFilter = () => {
      if ( existingFilter ) {
        removeFromURLHash( `${_groupType}_ids` );
        const _appliedFilters = transformHashToFilters();
        setAppliedFilters( _appliedFilters );
      } else {
        encodeURLHash( { [`${_groupType}_ids`]: JSON.stringify( [ row.id ] ) } );
        const _appliedFilters = transformHashToFilters();
        setAppliedFilters( _appliedFilters );
      }
      onRefresh();
    };

    return (
      <button
        title={ existingFilter ? 'remove filter' : 'add filter' }
        className={`${ existingFilter ? 'removeFilterButton' : 'addFilterButton'}`}
        onClick={ () => addOrRemoveFilter() }
      >
        {
          existingFilter
            ? <InlineSVG type="removeFilter" elementClass="filterIcon" />
            : <InlineSVG type="addFilter" elementClass="filterIcon" />
        }
      </button>
    );
  };

  // formatted name of signature (instances)
  const ScannerSignature = ( { row, link=true } ) => {
    let scanner;

    if ( row.scanner in vulnScannerNameMap ) {
      scanner = vulnScannerNameMap[row.scanner];
    }

    if ( !link ) {
      return <p>{`${ scanner ?? row.scanner } - ${ row.signature }`}</p>;
    }

    return (
      <button
        title={`${ scanner } - ${ row.signature }`}
        className={ `relatedSignatureButton ${riskToRating( row?.risk ) }`}
        onClick={ ( e ) => setSelectedSignature( { ...row, clickEvent: e } ) }
      >
        { vulnerabilityScannerLogo( row?.scanner ) }
        <span className="name">{`${ scanner ?? row.scanner } - ${ row.signature }`}</span>
      </button>
    );
  };

  // The data returned from the server needs to be massaged and adjusted for display
  // the table will now adjust the row order depending on what we are ordering by so that
  // the flow of information makes a bit more sense, ie: sorting by num_vulnerabilities, will put
  // those in the second column... etc.
  // RULE of thumb:
  // 1. remediation check (if included)
  // 2. risk (if included)
  // 3. label
  // 4. primary sort (if other than risk)
  // 5. secondary sort
  const transformRowData = ( row, _recordType ) => {

    const riskRating = recordRiskRating( row, _recordType );
    // only used for instances
    const _tags = {};

    let formattedRow = {};

    if (
      isNotEmpty( tags )
      && _recordType === 'host'
      && isNotEmpty( row )
      && isNotEmpty( row.asset_tags )
    ) {
      row.asset_tags.map( id => _tags[id] = tags[id] );
    }

    let rowColumnClass = 'includedColumns';

    const hash = decodeURLHash();

    const { columns } = hash;

    if (
      isNotEmpty( columns )
      && isNotEmpty( availableColumnsByType )
      && isNotEmpty( availableColumnsByType[_recordType] )
    ) {
      const columnKeysClass = [];

      const enabledColumns = availableColumnsByType[_recordType].filter( option => option.disabled === false );
      const orderedColumnKeys = enabledColumns.map( option => option.value );

      if ( isNotEmpty( orderedColumnKeys ) ) {
        orderedColumnKeys.map( key => {
          if ( key === 'risk' && columns.includes( 'filtered_risk' ) && columns.includes( 'risk_rating' ) ) {
            columnKeysClass.push( 'risk' );
          } else if ( columns.includes( key ) ) {
            columnKeysClass.push( key );
          }
        } );
      }

      if ( isNotEmpty( columnKeysClass ) ) {
        rowColumnClass = `includedColumns--${columnKeysClass.join( '-' )}`;
      }
    }

    if ( reportType === 'instances' || reportType === 'instance' ) {
      rowColumnClass = `${rowColumnClass} instanceActionsVersion`;
    }

    /* eslint-disable camelcase */
    // for the following different record types, going to make all tables the same and format the rows to include
    // everything possible, will handle hiding certain columns in the table component
    switch ( _recordType ) {
    case 'host':
      if ( 'filtered_risk' in row && 'risk_rating' in row ) {
        formattedRow.risk = <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge
            item={ row }
            itemType={ recordType }
            altVersion
            rating={ riskRating }
          />
          <RiskReduction item={ row } itemType={ recordType } riskType="filtered_risk" />
        </div>;
      }
      formattedRow = {
        ...formattedRow,
        name: <React.Fragment>
          { reportTypeNameLink( row, _recordType ) }
          <TagList tags={_tags} truncation={ 2 } />
        </React.Fragment>,
        sensitive_assets: <span
          className={ `sensitiveAssetsCount ${ row.num_sensitive_nodes !== 0 ? 'hasAssets' : ''}`}
        >
          { formatNumber( row.num_sensitive_nodes || 0 ) }
        </span>,
      };
      if ( 'product_name' in row ) {
        formattedRow.product_name = row.product_name;
      }
      if ( 'ip_addresses' in row ) {
        formattedRow.addresses = isNotEmpty( row?.ip_addresses )
          ? formatIpAddresses( row?.ip_addresses )
          : <span></span>;
      }
      formattedRow = {
        ...formattedRow,
        // eslint-disable-next-line camelcase
        all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
        unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
        vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
        'Scanning Status': <ScanningStatusIndicator hideText timestamp={ row.last_scanned } />,
      };

      if ( 'agent_version' in row ) {
        formattedRow.agent_version = row.agent_version || 'N/A';
      }
      formattedRow = {
        ...formattedRow,
        actions: <React.Fragment>
          {
            isNotEmpty( row.id ) &&
            <React.Fragment>
              {
                reportType === 'instance' &&
                <AddRemoveFilterAction row={ row } />
              }
              <button
                title="Quick View Host"
                className="roundGlyphButton light viewRecordButton"
                onClick={ ( e ) => viewRecord( e, row, 'host' ) }
              >
                <InlineSVG type="moreHorizontal" />
              </button>
              <button
                title="View Full Host Details"
                className="roundGlyphButton light selectRecordButton"
                onClick={ () => selectRecord( row.id ) }
              >
                <InlineSVG type="carretRight" />
              </button>
            </React.Fragment>
          }
        </React.Fragment>,
        id: row.id,
        originalRecord: row,
        rowColumnClass,
      };
      return formattedRow;
    case 'vulnerability':
      if ( 'filtered_risk' in row && 'risk_rating' in row ) {
        formattedRow.risk = <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge
            item={ row }
            itemType={ reportType }
            altVersion
            rating={ riskRating }
          />
          <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
        </div>;
      }
      formattedRow = {
        ...formattedRow,
        name: reportTypeNameLink( row, _recordType ),
        exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
        'CVSS': <span>{ row.cvss_base_score }</span>,
        affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
        all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
        unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
      };
      if ( 'description' in row ) {
        formattedRow.description = isNotEmpty( row.description )
          ? <pre>{ row.description }</pre>
          : <span></span>;
      }
      formattedRow = {
        ...formattedRow,
        actions: <React.Fragment>
          {
            isNotEmpty( row.id ) &&
            <React.Fragment>
              {
                reportType === 'instance' &&
                <AddRemoveFilterAction row={ row } />
              }
              <button
                title="Quick View Vulnerability"
                className="roundGlyphButton light viewRecordButton"
                onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
              >
                <InlineSVG type="moreHorizontal" />
              </button>
              <button
                title="View Full Vulnerability Details"
                className="roundGlyphButton light selectRecordButton"
                onClick={ () => selectRecord( row.id ) }
              >
                <InlineSVG type="carretRight" />
              </button>
            </React.Fragment>
          }
        </React.Fragment>,
        id: row.id,
        originalRecord: row,
        rowColumnClass,
      };

      return formattedRow;
    case 'patch':
      if ( 'filtered_risk' in row && 'risk_rating' in row ) {
        formattedRow.risk = <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskRating } />
          <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
        </div>;
      }
      formattedRow = {
        ...formattedRow,
        name: reportTypeNameLink( row, _recordType ),
        superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
        affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
        vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
      };
      if ( 'description' in row ) {
        formattedRow.description = isNotEmpty( row.description )
          ? <pre>{ row.description }</pre>
          : <span></span>;
      }
      formattedRow = {
        ...formattedRow,
        actions: <React.Fragment>
          {
            isNotEmpty( row.id ) &&
            <React.Fragment>
              {
                reportType === 'instance' &&
                <AddRemoveFilterAction row={ row } />
              }
              <button
                title="Quick View Patch"
                className="roundGlyphButton light viewRecordButton"
                onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
              >
                <InlineSVG type="moreHorizontal" />
              </button>
              <button
                title="View Full Patch Details"
                className="roundGlyphButton light selectRecordButton"
                onClick={ () => selectRecord( row.id ) }
              >
                <InlineSVG type="carretRight" />
              </button>
            </React.Fragment>
          }
        </React.Fragment>,
        id: row.id,
        originalRecord: row,
        rowColumnClass,
      };
      return formattedRow;
    case 'path':
      if ( columns.includes( 'risk' ) ) {
        formattedRow.risk = <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskRating } />
          <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
        </div>;
      }
      formattedRow = {
        ...formattedRow,
        name: reportTypeNameLink( row, _recordType ),
        steps: <span>{ row.edges.length }</span>,
        // eslint-disable-next-line max-len
        actions: <React.Fragment>
          {
            isNotEmpty( row.id ) &&
            <button
              className="roundGlyphButton light selectRecordButton"
              onClick={ () => selectRecord( row.id ) }
            >
              <InlineSVG type="carretRight" version="primary"/>
            </button>
          }
        </React.Fragment>,
        id: row.id,
        originalRecord: row,
        rowColumnClass,
      };

      return formattedRow;
    case 'user':
      if ( columns.includes( 'risk' ) ) {
        formattedRow.risk = <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskRating } />
          <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
        </div>;
      }
      formattedRow = {
        ...formattedRow,
        name: reportTypeNameLink( row, _recordType ),
        // eslint-disable-next-line max-len
        recently_accessed_hosts: <span>{ formatNumber( row.active_hosts?.length || 0 ) }</span>,
        domain_groups: <span>{ formatNumber( row.domain_groups?.length || 0 ) }</span>,
        actions: <React.Fragment>
          {
            isNotEmpty( row.id ) &&
            <React.Fragment>
              <button
                title="View Full User Details"
                className="roundGlyphButton light selectRecordButton"
                onClick={ () => selectRecord( row.id ) }
              >
                <InlineSVG type="carretRight" />
              </button>
            </React.Fragment>
          }
        </React.Fragment>,
        id: row.id,
        originalRecord: row,
        rowColumnClass,
      };
      return formattedRow;
    case 'signature':
      if ( 'filtered_risk' in row && 'risk_rating' in row ) {
        formattedRow.risk = <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge
            item={ row }
            itemType={ _recordType }
            altVersion
            rating={ riskToRating( row?.filtered_risk ) }
          />
          <RiskReduction item={ row } itemType={ _recordType } riskType="filtered_risk" />
        </div>;
      }
      formattedRow = {
        ...formattedRow,
        scanner_signature: <ScannerSignature row={ row } />,
        name: <p title={ row.title }>{ row.title }</p>,
        hosts: formatNumber( row.num_hosts ),
        vulns: formatNumber( row.num_vulnerabilities ),

        actions: <React.Fragment>
          {
            isNotEmpty( row.id ) &&
            <React.Fragment>
              <AddRemoveFilterAction row={ row } />
              <button
                title="View Full Signature Details"
                className="roundGlyphButton light selectRecordButton"
                onClick={ ( e ) => setSelectedSignature( { ...row, clickEvent: e } ) }
              >
                <InlineSVG type="carretRight" version="primary"/>
              </button>
            </React.Fragment>
          }
        </React.Fragment>,
        id: row.id,
        originalRecord: row,
        rowColumnClass,
      };
      return formattedRow;
    }
  /* eslint-enable camelcase */
  };

  const setupTable = () => {
    let _recordType = isNotEmpty( decodeURLHash().group_type ) ? decodeURLHash().group_type : reportType;
    if ( _recordType === 'patch_cumulative' ) {
      _recordType = 'patch';
    }
    setRecordType( _recordType );
    if ( isNotEmpty( records ) ) {
      const newData = records.map( row => {
        const rowData = transformRowData( row, _recordType );
        return { ...rowData };
      } );
      setAdjustedData( newData );
    } else {
      setAdjustedData( [] );
    }
  };

  // whenever the table data changes (ie filters, etc.)
  // this sets the adjusted data that the table will render
  // also needs to figure out the order by class and risk class and set that in order to render the correct information
  React.useEffect( () => {
    setupTable();
  }, [ records, tags, reportType ] );

  // only some reports and group types support remediation workflow, this dictates which
  const includeRemediationSelect = licenseInfo => {
    if ( isNotEmpty( licenseInfo ) ) {
      if ( hasFeatureAccess( currentUser, licenseInfo, 'f_remediation' ) ) {
        if ( reportType === 'instance' ) {
          return paramsToFilters()['group_type'] !== 'signature';
        }
        if ( reportType === 'path' || reportType === 'user' || reportType === 'vulnerability_data' ) {
          return false;
        }
        return true;
      }
      return false;
    }
    return false;
  };

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ) } );
    onRefresh();
  };

  // hash refresh is being triggered by the table column options, listen for it and refresh what columns are included
  React.useEffect( () => {
    window.addEventListener( 'hashchange', setupTable );
    return () => window.removeEventListener( 'hashchange', setupTable );
  }, [ records, tags, recordType ] );

  return (
    <React.Fragment>
      <RecordCard
        record={ recordCardRecord }
        type={ recordCardType }
        context="risk_insight_table"
        show={ showRecordCard }
        setShow={ setShowRecordCard }
        fetchRecordDetails
        options={ {
          isDismissable: true,
          isDraggable: true,
          // eslint-disable-next-line camelcase
          include_shade: true,
          // eslint-disable-next-line camelcase
          include_risk: true,
          // eslint-disable-next-line camelcase
          include_vulnerability_instances: true,
          // eslint-disable-next-line camelcase
          include_cvss_breakdown: true,
          // eslint-disable-next-line camelcase
          include_details_link: true,
        } }
      />
      {
        isNotEmpty( licenseInfo ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `${visualCollapsed ? 'visualCollapsed' : ''} riskInsightTableWrapper` }
          ref={tableWrapperRef}
        >
          { loading && <Loading /> }
          { !loading &&
            <React.Fragment>
              <TableHeader
                reportType={reportType}
                recordCount={recordCount}
                onRefresh={onRefresh}
                tallyCount={tallyCount}
              />
              <DataTable
                sortableColumns={ recordTypeSortableColumns[recordType] }
                sortBy= { decodeURLHash().order_by }
                data={ adjustedData }
                // eslint-disable-next-line max-len
                elementClass={ `${ ( includeRemediationSelect( licenseInfo ) && featureAccessLevel( 'f_remediation', licenseInfo ) === 'enabled' ) ? 'hasRemediation' : '' }  ${recordType}_riskInsightDataTable ${paramsToFilters().group_type || 'host'}_groupBy riskInsightDataTable` }
                externalHoverRecord={hoverRecord}
                setExternalHoverRecord={setHoverRecord}
                // eslint-disable-next-line max-len
                withSelect={ includeRemediationSelect( licenseInfo ) && featureAccessLevel( 'f_remediation', licenseInfo ) === 'enabled' }
                onSelect={ onRemediationSelect }
                // eslint-disable-next-line max-len
                selectedIDs={ includeRemediationSelect( licenseInfo ) ? Object.keys( remediationItems[remediationItemsKey( reportType )] ) : [] }
                setSelectedIDs={ setRemediationItemsForType }
                allowHover
                onRefresh={onRefresh}
              />
              {
                useLegacyPagination
                  ? <IndeterminantPagination
                    currentPageNumber={currentPageNumber}
                    nextPageResults={nextRecords}
                    goToPage={goToPage}
                    elementClass="riskInsightPagination"
                    onRefresh={onRefresh}
                  />
                  : <Pagination totalCount={recordCount} containerRef={tableWrapperRef} onRefresh={onRefresh}/>
              }
            </React.Fragment>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default InsightTable;