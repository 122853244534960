/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  debounce,
  formatNumber,
  hexToRGB,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  itemIsObject,
  riskToRating,
  tagColors,
} from '../../../../../shared/Utilities';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import Legend from '../../../../../shared/Charts/Legend';

import './Comparison.scss';

import MultiBar from '../../../../../shared/Charts/MultiBar';
import EmptyState from '../../../../../shared/EmptyState';
import { TagsContext } from '../../../../../Contexts/Tags';
import InlineSVG from '../../../../../shared/InlineSVG';

const ComparisonTag = ( {
  item,
  settings,
  data,
  svgAspectRatio,
  adjustSVGAspectRatio,
  svgContainerRef,
} ) => {
  const [ widgetVersion, setWidgetVersion ] = React.useState( null );
  const [ trendDirection, setTrendDirection ] = React.useState( 'up' );
  const [ trendDelta, setTrendDelta ] = React.useState( 0 );
  const [ chartData, setChartData ] = React.useState( null );
  const [ seriesLegendData, setSeriesLegendData ] = React.useState( null );

  const [ yMax, setYMax ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const [ tags ] = React.useContext( TagsContext );

  React.useEffect( () => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {
      adjustSVGAspectRatio();
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 100 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, widgetVersion, chartData, yMax ] );

  // we need to figure out what data to look and format the data
  // according to how it needs to be displayed
  React.useEffect( () => {
    if (
      isNotEmpty( settings )
      && 'report_type' in settings
      && isNotEmpty( tags )
      && isNotEmpty( data )
    ) {
      let attribute = '';
      if ( settings.report_type === 'hosts' ) {
        attribute = 'num_hosts';
      }
      if ( settings.report_type === 'patches' ) {
        attribute = 'num_patches';
      }
      if ( settings.report_type === 'vulnerabilities' ) {
        attribute = 'num_vulnerabilities';
      }
      if ( settings.report_type === 'vulnerability_instances' ) {
        attribute = 'num_instances';
      }
      if ( settings.report_type === 'risk' ) {
        attribute = 'risk';
      }

      const availableColors = [ ...tagColors.three ];
      availableColors.shift();

      const { comparison_version } = settings;

      setWidgetVersion( comparison_version );

      let _max = 0;

      const includedAssetTags = isNotEmpty( settings?.asset_tag_ids )
        ? settings.asset_tag_ids
        : Object.keys( tags );

      if ( itemIsObject( data ) ) {
        let comparisonGrouped = [];
        let currentGrouped = [];

        let lowestTimestamp, highestTimestamp;

        // go through all of the start points, find the lowest timestamp and then group all points together that have
        // that timestamp
        if ( isNotEmpty( data.start ) && itemIsArray( data.start ) ) {
          data.start.map( p => {
            if ( isEmpty( lowestTimestamp ) ) {
              lowestTimestamp = p.created;
            } else if ( p.created < lowestTimestamp ) {
              lowestTimestamp = p.created;
            }
          } );
          if ( isNotEmpty( lowestTimestamp ) ) {
            comparisonGrouped = data.start.filter( p => p.created === lowestTimestamp );
          }
        }

        if ( isNotEmpty( data.end ) && itemIsArray( data.end ) ) {
          // go through all of the end points, find the highest timestamp and then group all points together that have
          // that timestamp
          data.end.map( p => {
            if ( isEmpty( highestTimestamp ) ) {
              highestTimestamp = p.created;
            } else if ( p.created > highestTimestamp ) {
              highestTimestamp = p.created;
            }
          } );
          if ( isNotEmpty( highestTimestamp ) ) {
            currentGrouped = data.end.filter( p => p.created === highestTimestamp );
          }
        }

        // have to have at least the current amount for this to do anything at all
        if ( isNotEmpty( currentGrouped ) ) {
          // eslint-disable-next-line camelcase
          if ( comparison_version === 'number' ) {
            const comparisonNum = comparisonGrouped.reduce( ( accum, thisPoint ) => accum + thisPoint[attribute], 0 );
            let currentNum = currentGrouped.reduce( ( accum, thisPoint ) => accum + thisPoint[attribute], 0 );

            if ( settings?.report_type === 'risk' ) {
              currentNum = Math.floor( currentNum );
            }
            const _seriesLegendData = {};

            // need to create a series for each included asset_tag_id
            if ( isNotEmpty( includedAssetTags ) ) {
              includedAssetTags.map( ( tagID, _tagIndex ) => {
                const tag = tags[tagID];

                const currentTagPoint = currentGrouped.find( p => p.asset_tag_id === tagID );

                if ( isNotEmpty( tag ) && isNotEmpty( currentTagPoint ) ) {
                  _seriesLegendData[tagID] = {
                    label: tag.label,
                    stroke: tag.color || availableColors[_tagIndex % 8],
                    fill: tag.color || availableColors[_tagIndex % 8],
                    total: currentTagPoint[attribute],
                    isTag: true,
                  };
                }
              } );
              setSeriesLegendData( _seriesLegendData );
            }

            setYMax( currentNum );

            if ( isEmpty( comparisonGrouped ) ) {
              setTrendDirection( 'unknown' );
              setTrendDelta( 'unknown' );
              setChartData( {
                current: {
                  label: 'Current',
                  value: currentNum,
                  key: 'current',
                },
              } );
            } else {
              let _trendDirection = 'up';

              let _trendDelta = 0;
              if ( currentNum === comparisonNum ) {
                _trendDirection = 'even';
                _trendDelta = 0;
              } else if ( currentNum > comparisonNum ) {
                _trendDirection = 'up';
                _trendDelta = currentNum - comparisonNum;
              } else {
                _trendDirection = 'down';
                _trendDelta = comparisonNum - currentNum;
              }

              if ( settings.report_type === 'risk' ) {
                _trendDelta = Math.floor( _trendDelta );
              }

              setTrendDirection( _trendDirection );
              setTrendDelta( _trendDelta );
              setChartData( {
                current: {
                  label: 'Current',
                  value: currentNum,
                  key: 'current',
                },
              } );
            }
          }
          // eslint-disable-next-line camelcase
          if ( comparison_version === 'barchart' ) {

            // filter the groups down to only the included asset_tag_ids
            comparisonGrouped = comparisonGrouped.filter( p => includedAssetTags.includes( p.asset_tag_id ) );
            currentGrouped = currentGrouped.filter( p => includedAssetTags.includes( p.asset_tag_id ) );

            const _comparisonMax = Math.max( ...comparisonGrouped.map( p => p[attribute] ) );
            const _currentMax = Math.max( ...currentGrouped.map( p => p[attribute] ) );

            if ( isNotEmpty( _currentMax ) ) {
              _max = Math.max( _comparisonMax, _currentMax );

              const _seriesData = [];
              const _seriesLegendData = {};

              // need to create a series for each included asset_tag_id
              if ( isNotEmpty( includedAssetTags ) ) {
                includedAssetTags.map( ( tagID, _tagIndex ) => {
                  const tag = tags[tagID];
                  const comparisonPoint = comparisonGrouped.find( p => p.asset_tag_id === tagID );
                  const currentPoint = currentGrouped.find( p => p.asset_tag_id === tagID );
                  const pointData = [];

                  if ( isNotEmpty( currentPoint ) && isNotEmpty( tag ) ) {

                    const colorRGB = hexToRGB( tag.color || availableColors[_tagIndex % 8] );

                    if ( isNotEmpty( tag ) ) {
                      _seriesLegendData[tagID] = {
                        label: tag.label,
                        stroke: tag.color || availableColors[_tagIndex % 8],
                        comparisonFill: `rgba(${colorRGB.r},${colorRGB.g},${colorRGB.b}, 0.1)`,
                        currentFill: tag.color || availableColors[_tagIndex % 8],
                        isSplit: true,
                        isTag: true,
                      };
                    }

                    // comparison point
                    if ( isNotEmpty( comparisonPoint ) ) {
                      pointData.push(
                        {
                          fill: `rgba(${colorRGB.r},${colorRGB.g},${colorRGB.b}, 0.1)`,
                          isTag: true,
                          original: comparisonPoint,
                          seriesKey: tagID,
                          stroke: tag.color || availableColors[_tagIndex % 8],
                          timestamp: comparisonPoint.created,
                          value: comparisonPoint[attribute],
                        },
                      );
                    } else {
                      pointData.push(
                        {
                          fill: `rgba(${colorRGB.r},${colorRGB.g},${colorRGB.b}, 0.1)`,
                          isTag: true,
                          original: comparisonPoint,
                          seriesKey: tagID,
                          stroke: tag.color || availableColors[_tagIndex % 8],
                          timestamp: 0,
                          value: 0,
                        },
                      );
                    }

                    // current point
                    pointData.push(
                      {
                        fill: tag.color || availableColors[_tagIndex % 8],
                        isTag: true,
                        original: currentPoint,
                        seriesKey: tagID,
                        stroke: tag.color || availableColors[_tagIndex % 8],
                        timestamp: currentPoint.created,
                        value: currentPoint[attribute],
                      },
                    );
                    _seriesData.push( pointData );
                  }
                } );
                setSeriesLegendData( _seriesLegendData );
                setChartData( {
                  max: _max,
                  series: _seriesData,
                } );
                setYMax( _max );
              }
            }
          }
        }
      }
    }
  }, [ settings, tags, data ] );

  const getHeaderForNumberVariant = reportType => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'hosts' ) {
        return <React.Fragment>
          <strong>Current Host Count: </strong><span>with Tag Breakdown</span>
        </React.Fragment>;
      }
      if ( reportType === 'patches' ) {
        return <React.Fragment>
          <strong>Current Outstanding Patches Count: </strong><span>with Tag Breakdown</span>
        </React.Fragment>;
      }
      if ( reportType === 'vulnerabilities' ) {
        return <React.Fragment>
          <strong>Current Outstanding Vulnerabilities Count: </strong><span>with Tag Breakdown</span>
        </React.Fragment>;
      }
      if ( reportType === 'vulnerability_instances' ) {
        return <React.Fragment>
          <strong>Current Outstanding Vulnerability Instances Count: </strong><span>with Tag Breakdown</span>
        </React.Fragment>;
      }
      if ( reportType === 'risk' ) {
        return <React.Fragment>
          <strong>Current Risk Score: </strong><span>with Tag Breakdown</span>
        </React.Fragment>;
      }
      return 'Current Count';
    }
    return 'Current Count';
  };

  const getIconForNumberVariant = reportType => {
    if ( isNotEmpty( reportType ) ) {
      if ( reportType === 'hosts' ) {
        return <InlineSVG type="host_record" />;
      }
      if ( reportType === 'patches' ) {
        return <InlineSVG type="patch_record" />;
      }
      if ( reportType === 'vulnerabilities' ) {
        return <InlineSVG type="vulnerability_record" />;
      }
      if ( reportType === 'vulnerability_instances' ) {
        return <InlineSVG type="vulnerability_record" />;
      }
      return <InlineSVG type="dsRisk" />;
    }
    return <InlineSVG type="dsRisk" />;
  };

  const wordsForComparisonDate = key => {
    if ( isNotEmpty( key ) ) {
      if ( key === 'day' ) {
        return 'yesterday';
      }
      if ( key === 'week' ) {
        return 'last week';
      }
      if ( key === 'month' ) {
        return 'last month';
      }
      if ( key === 'quarter' ) {
        return 'last quarter';
      }
      return 'last year';
    }
    return 'last month';
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( chartData ) && isNotEmpty( tags ) && isNotEmpty( widgetVersion ) && isNotEmpty( yMax ) )
          ? <React.Fragment>
            {
              widgetVersion === 'number' &&
              <div
                id={ `historyOverTimeSVGWrapper-${item.i}` }
                className="overtimeNumberWrapper"
              >
                <h2>{ getHeaderForNumberVariant( settings?.report_type ) }</h2>
                <div
                  // eslint-disable-next-line max-len
                  className={ `countWrapper ${settings?.report_type} ${ ( settings?.report_type === 'risk' && isNotEmpty( chartData.current?.value ) ) ? `riskClass--${ riskToRating( chartData.current.value ) }` : '' }` }
                >
                  { getIconForNumberVariant( settings?.report_type ) }
                  <strong>{ formatNumber( chartData.current?.value || 0 )}</strong>
                </div>
                <div className="trendWrapper">
                  <div className={ `trendIconWrapper ${trendDirection}` }>
                    {
                      ( trendDirection === 'even' || trendDirection === 'unknown' )
                        ? <span>--</span>
                        : <InlineSVG type={ `trending_${trendDirection}` } />
                    }
                  </div>
                  <span>
                    {
                      trendDirection === 'unknown'
                        // eslint-disable-next-line max-len
                        ? `insufficient data to show comparison to ${ wordsForComparisonDate( settings?.comparison_date ) }`
                        : <React.Fragment>
                          {/* eslint-disable-next-line max-len */}
                          { trendDirection === 'down' ? 'Decreased' : 'Increased' } by <strong>{ formatNumber( trendDelta ) }</strong> since { wordsForComparisonDate( settings?.comparison_date ) }
                        </React.Fragment>
                    }

                  </span>
                </div>
                {
                  isNotEmpty( seriesLegendData ) &&
                  <Legend
                    legendData={seriesLegendData}
                    hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                    setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                  />
                }
              </div>
            }
            {
              widgetVersion === 'barchart' &&
              <div
                id={ `historyOverTimeSVGWrapper-${item.i}` }
                className="overtimeMultiBarWrapper"
                ref={svgContainerRef}
              >
                <YAxisLabels yMax={ yMax } />
                <MultiBar
                  data={chartData}
                  hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                  setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                  svgAspectRatio={svgAspectRatio}
                />
                {
                  isNotEmpty( seriesLegendData ) &&
                  <Legend
                    legendData={seriesLegendData}
                    hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                    setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                  />
                }
              </div>
            }
          </React.Fragment>
          : <EmptyState message="Insufficient data" />
      }
    </React.Fragment>
  );
};

export default ComparisonTag;