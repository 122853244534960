/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Form from '../../../../shared/Form';
import { getFieldValues } from '../../../../shared/Form/Shared';
import UserDetails from '../../../../shared/UserDetails';
import { isEmpty, isNotEmpty } from '../../../../shared/Utilities';

const UserRow = ( {
  user,
  // userIndex,
  userMappings,
  setUserMappings,
  existingRecord,
  externalUsers,
} ) => {

  const FIELDS = [
    {
      type: 'searchResults',
      defaultValue: user.external_user_id || '',
      attribute: `external_user_id_for_${user.id}`,
      placeholder: 'Search external users...',
      recordType: 'external_user',
      noRisk: true,
      // eslint-disable-next-line camelcase
      additionalSearchParams: { third_party_setting_id: existingRecord?.id },
    },
  ];

  const [ fields, setFields ] = React.useState( null );
  const [ mappingRecord, setMappingRecord ] = React.useState( null );

  const handleFormChange = formState => {
    if ( isNotEmpty( formState ) ) {
      const values = getFieldValues( formState.fieldStates, 'external_user' );

      let _userMappings = { ...userMappings };

      if ( isNotEmpty( values ) ) {
        _userMappings = {
          ..._userMappings,
          [user.id]: { ..._userMappings[user.id], externalUserID: values[`external_user_id_for_${user.id}`] || null },
        };
      }

      setUserMappings( _userMappings );
    }
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( user ) && isNotEmpty( existingRecord ) && isNotEmpty( externalUsers ) ) {

      const _mappingRecord = {};
      let defaultGuess;

      // if the external_user_id has not yet been mapped, we are going to guess and default any that have
      // the same email
      if ( isEmpty( user.externalUserID ) && isNotEmpty( user.email_address ) ) {
        defaultGuess = Object.values( externalUsers ).find( u => u.email_address === user.email_address );
        if ( isNotEmpty( defaultGuess ) ) {
          _mappingRecord[`external_user_id_for_${user.id}`] = defaultGuess.id;
        } else {
          _mappingRecord[`external_user_id_for_${user.id}`] = '';
        }
      } else if ( isNotEmpty( user.externalUserID ) ) {
        _mappingRecord[`external_user_id_for_${user.id}`] = user.externalUserID;
      } else {
        _mappingRecord[`external_user_id_for_${user.id}`] = '';
      }

      // eslint-disable-next-line camelcase
      setMappingRecord( _mappingRecord );
      setFields( FIELDS );
    }
  }, [ ] );

  const hasMapping = () => isNotEmpty( userMappings ) && isNotEmpty( userMappings[user.id].externalUserID );

  return (
    <li className={ `${hasMapping() ? 'isMapped' : ''}`}>
      {
        ( isNotEmpty( mappingRecord ) && isNotEmpty( fields ) ) &&
        <React.Fragment>
          <UserDetails user={user} randomizeKey={7} includeDetails includeEmail />
          <Form
            fields={ fields }
            recordType="external_user"
            trackUpdates={false}
            onChangeCallback={ handleFormChange }
            existingRecord={ mappingRecord }
          />
        </React.Fragment>
      }
    </li>
  );
};

export default UserRow;