/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import './style.scss';

import {
  isNotEmpty,
} from '../Utilities';
import InlineSVG from '../InlineSVG';

const Notification = ( {
  options,
} ) => {

  const headerForFlashType = type => {
    const headers = {
      'alert': 'Alert',
      'warning': 'Warning',
      'success': 'Success',
      'info': 'Notification',
    };
    return headers[type];
  };

  const notificationIconMap = {
    alert: 'notificationAlertV2',
    warning: 'notificationWarningV2',
    success: 'notificationSuccessV2',
    info: 'notificationInfoV2',
  };

  return (
    <React.Fragment>
      <div className={ `flashMessage queued ${options.type} asNotification ${window.IS_DARK_MODE ? 'darkMode' : ''}` } >
        {
          isNotEmpty( options.type )
            ? <InlineSVG type={ notificationIconMap[options.type] } />
            : <InlineSVG type={ notificationIconMap.info } />
        }
        <div
          className="flashMessageBody"
        >
          {
            isNotEmpty( options.header )
              ? <React.Fragment>
                <h2>{ options.header }</h2>
              </React.Fragment>
              : <h2>{ headerForFlashType( options.type ) }</h2>
          }
          {
            ( options.message && Array.isArray( options.message ) )
              ? <React.Fragment>
                {
                  options.message.map( ( message, key ) => {
                    return <p key={key}>{ message }</p>;
                  } )
                }
              </React.Fragment>
              : <React.Fragment>
                { options.message }
              </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notification;
