/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
// eslint-disable-next-line max-len
/* eslint-disable max-len, block-scoped-var, camelcase, space-in-parens, indent, brace-style, eqeqeq, semi, quotes, curly, prefer-destructuring, comma-spacing, no-else-return, spaced-comment, comma-dangle, no-extra-semi, array-bracket-spacing, no-nested-ternary */
'use strict';

import {
  global_target_risk_to_label,
} from './util';

import {
  $e,
  $get,
  clear_tag,
  parse_url_hash,
} from './dom';
import { decodeURLHash } from '../react/shared/Utilities';

/*
 * Core functions that manage interactions with the server
 */

/**
 * Makes an HTTP request with Authorization information automatically set if
 * the user is logged in. Useful for interacting with the API. It automatically
 * attempts to parse/decode JSON.
 *
 * Parameters:
 * method (string): the HTTP request method, e.g. 'UPDATE'
 * path (string): url path to make request to
 * params (object): a key => value dictionary object mapping URL parameters
 * callback (function): a callback, e.g. function(results) { ... }
 *
 * Example:
 * makeRequest('FETCH', '/api/v1/example', { 'query':'test' }, function(results) {
 *     console.log('Results:', results['results'])
 * });
 *
 */
// XXX: an async version of this function would be really nice.
export const makeRequest = ( method, path, params, callback, retries=16 ) => {
  const myHeaders = { 'Content-Type':'application/json; charset=utf-8' };
  const sid = window.localStorage.getItem('sid');

  if ( sid ) {
    myHeaders['Authorization'] = `kanchil ${sid}`;
  };

  const myInit = { method: method, headers: new Headers( myHeaders ) };

  if ( params ) {
    myInit.body = JSON.stringify( params );
  };

  if (callback) {
    return (
      fetch( path, myInit )
        .then( response => {
          // if it encounters a 503, retry until it hits 0 attempts
          if (response.status === 503) {
            if (retries > 0) {
              makeRequest(method, path, params, callback, retries - 1);
            } else {
              throw new Error(response.status)
            }
          } else {
            return processJSONResponse( response );
          };
        } )
        .then( callback )
        .catch( ( error ) => {
          if (error.message === 'Session timed out') {
            window.location.href = '#.=login';
          } else {
            console.log(error);
          };
        } )
    );
  };
  return (
    fetch( path, myInit ).then( response => {
      // if it encounters a 503, retry until it hits 0 attempts
      if ( response.status === 503 ) {
        if ( retries > 0 ) {
          makeRequest( method, path, params, callback, retries - 1 );
        } else {
          throw new Error(response.status);
        }
      } else {
        return processJSONResponse( response );
      };
    } )
    .catch( ( error ) =>  {
      return ( error );
    })
  );
};

const processJSONResponse = async( response ) => {
  return await response.text().then( ( body ) => {
    const hash = decodeURLHash();

    if ( hash['.'] !== 'login' && response.status == 401 ) {
      window.location.href = '#.=login';
      window.location.reload();
      const parsedBody = JSON.parse( body );
      if ( parsedBody?.errors ) {
        throw new Error( body );
      } else {
        throw new Error("Session timed out");
      }
    }

    const xrs = response.headers.get('X-Refresh-Session');

    if ( xrs ) {
      const new_session = JSON.parse(xrs);
      window.localStorage.setItem( 'sid', new_session['sid'] );
      window.localStorage.setItem( 'sid_expiration', new String( new_session['expiration'] ) );
    }

    const contentType = response.headers.get( "content-type" );

    if ( contentType && contentType.includes( "application/json" ) ) {
      if ( response?.status !== 200 ) {
        try {
          const _body = JSON.parse( body );
          _body.status = response.status;
          _body.statusText = response.statusText;
          return _body;
        } catch( e ) {
          throw new TypeError("JSON response couldn't be parsed: " + body);
        }
      } else {
        try {
          return JSON.parse( body )
        } catch( e ) {
          throw new TypeError("JSON response couldn't be parsed: " + body);
        }
      }
    } else {
      return body;
    }
  } );
};

// XXX phase this out
var hosts = {};

const project ='default';
const model = 'base';

var _scan_results = {};
export var model_meta = null;
var _model_cache = {scope: {}, patch: {}, vulnerability: {}, path: {}, node: {}, edge: {}, escalation: {}, escalation_details: {}};
var _model_cache_complete = {};
var _global_settings = {};



export async function load_global_settings()
{
    var response = await makeRequest('INDEX', '/global_setting', {});
    if(response['results'])
    {
        _global_settings = response['results'];
        return Object.assign({}, response['results']);
    }
    else
        console.log("ERROR: Failed to load global settings");

    return {};
}

function load_model_meta(project, model)
{
    var ret_val;

    function _cb_wrapper(response)
    {
        // XXX: catch server errors here and report
        //console.log(response['results']);
        return response['results'];
    }

    return makeRequest('FETCH', '/model',
                        {'project':project, 'model':model},
                        _cb_wrapper);
}

function load_model(project, model, callback)
{
    if(window?.location?.protocol?.startsWith('http'))
        return load_model_live(project, model, callback);
    else
        return load_model_batch(project, model, callback);
}

async function load_model_live(project, model, callback)
{
    //var files = ['host_report.js','patch_report.js','path_report.js',
    //             'scan_results.js','scopes.js','vulnerability_report.js'];
    var files = ['host_report.js'];

    var promises = [];
    for(var i=0; i<files.length; i++)
    {
        var path = "/reports/"+project+"/"+model+"/"+files[i];
        promises.push(makeRequest('GET', path));
    }

    for(var i=0; i<promises.length; i++)
    {
        // XXX: Ugly hack to remain backward compatible with batch formatted files.
        //      In the future, switch to plain JSON
        var blob = await promises[i];
        var eq = blob.indexOf('=');
        var name = blob.slice(4,eq).trim();
        var value = blob.slice(eq+1, blob.lastIndexOf(';')).trim();
        //console.log(name, value);
        window[name] = JSON.parse(value);
    }

    if(callback)
        callback();
}

async function load_model_batch(project, model, callback)
{
    var files = ['host_report.js','patch_report.js','path_report.js',
                 'scan_results.js','scopes.js','vulnerability_report.js'];
    var fl;

    for(var i=0; i<files.length; i++)
    {
        fl = $get(files[i]+'-loader');
        if(fl)
            document.head.removeChild(fl);
    }

    var promises = [];
    for(var i=0; i<files.length; i++)
    {
        promises.push(new Promise(function(resolve, reject) {
            var path = "reports/"+project+"/"+model+"/"+files[i];
            fl = $e('script',
                    {'id':files[i]+'-loader',
                     'type':'text/javascript'});
            document.head.appendChild(fl);
            //console.log(path);
            fl.onload = function() {
                console.log('loaded '+path);
                resolve(path);
            };
            fl.onerror = function() {
                reject(path);
            };
            //fl.addEventListener("load", _cb_wrapper);
            //fl.addEventListener("error", _cb_wrapper);
            fl.async = true;
            fl.setAttribute('src', path);
        }));
    }

    if(callback)
        return Promise.all(promises).then(callback);
    else
        await Promise.all(promises);
}

export async function fetch_project_record()
{
    var response = await makeRequest('FETCH', '/project/'+project, {});
    return response['results'];
}

export async function fetch_scan_results(ids)
{
    if(!ids)
        return {};

    var ret_val = {};
    var to_fetch = {};
    for(var id of ids)
    {
        if(!id)
            continue;

        if(_scan_results[id])
            ret_val[id] = _scan_results[id];
        else
            to_fetch[id] = null;
    }

    if(Object.keys(to_fetch).length > 0)
    {
        //console.log('attempting to fetch:', to_fetch);
        var start = (new Date()).getTime();

        const params = {
            model: 'base',
            project: 'default',
            filters: {
                extra_columns: [
                    'scanner',
                    'signature',
                    'title',
                    'signature_analysis.risk',
                    'manual_html',
                    'scanner_rating',
                    'description',
                    'service_info',
                    'urls',
                    'recommendation',
                    ],
              rownums: [],
              order_by: [['signature_analysis.risk', 'DESC']],
              id_list: Object.keys(to_fetch)
            },
          };
        var response = await makeRequest('SEARCH', '/model/signature', params );

        const responseAsObject = {};
        if (response.results) {
            response.results.map( r => {
                responseAsObject[r.id] = r;
            });
        };

        Object.assign(ret_val, responseAsObject);
        Object.assign(_scan_results, responseAsObject);
        //console.log(to_fetch.length+' '+type+' records fetched in '
        //            +(((new Date()).getTime()-start)/1000.0)+' seconds');
    }

    return ret_val;
}

export async function fetch_model_records(type, ids)
{
    var ret_val = {};
    if(!ids)
    {
        if(_model_cache_complete[type])
            return _model_cache[type];

        var start = (new Date()).getTime();
        var response = await makeRequest('INDEX', '/model/'+type, {'project':project, 'model':model, 'ids':[]});
        //console.log('fetch_model_records:', response['results']);
        _model_cache[type] = response['results'];
        _model_cache_complete[type] = true;
        ret_val = _model_cache[type];
        console.log('All '+type+' records fetched in '+(((new Date()).getTime()-start)/1000.0)+' seconds');
    }
    else
    {
        var to_fetch = {};
        ids.forEach(function (id) {
            if(_model_cache[type][id])
                ret_val[id] = _model_cache[type][id];
            else
                to_fetch[id] = null;
        });

        if(Object.keys(to_fetch).length > 0)
        {
            //console.log('attempting to fetch:', to_fetch);
            var start = (new Date()).getTime();
            var response = await makeRequest('INDEX', '/model/'+type,
                                              {'project':project, 'model':model, 'ids':Object.keys(to_fetch)});

            Object.assign(ret_val, response['results']);
            Object.assign(_model_cache[type], response['results']);
            //console.log(to_fetch.length+' '+type+' records fetched in '
            //            +(((new Date()).getTime()-start)/1000.0)+' seconds');
        }
    }
    /*
    console.log('fetch_model_records ('+type+') result:', ret_val, 'from ids:', ids);
    if(ids[0] == null)
        console.trace();
    */
    return ret_val;
}
export var fetch_paths = fetch_model_records.bind(null, 'path');
export var fetch_nodes = fetch_model_records.bind(null, 'node');
export var fetch_edges = fetch_model_records.bind(null, 'edge');
export var fetch_escalations = fetch_model_records.bind(null, 'escalation');
export var fetch_escalation_details = fetch_model_records.bind(null, 'escalation_details');
/*
var fetch_patches = fetch_model_records.bind(null, 'patch');
var fetch_vulnerabilities = fetch_model_records.bind(null, 'vulnerability');
var fetch_scopes = fetch_model_records.bind(null, 'scope');
*/

// XXX: Migrating away from fetch_model_records and over to this to better control what columns are returned on records
//      (for efficiency reasons)
var _fetch_fields = {};
_fetch_fields['patch'] = [
    'advisory_id', 'vendor', 'description','type', 'identifier', 'url', 'effort', 'additional_actions'].concat([
        'model_id', 'patch_id', 'direct_hosts', 'hosts', 'vulnerabilities', 'products',
        'supersedes','superseded_by','fully_supersedes','fully_superseded_by',
        'scan_results', 'direct_risk', 'risk', 'risk_percentile'].map((f) => 'patch_analysis.'+f));

_fetch_fields['scope'] = [
    'project_id', 'parent', 'name', 'type', 'label', 'flags', 'extra'].concat([
        'model_id', 'scope_id', 'ancestors', 'ancestor_labels', 'descendants', 'direct_patches','patches',
        'vulnerabilities', 'sensitive_nodes',
        'scan_results', 'risk', 'risk_percentile'].map((f) => 'scope_analysis.'+f));

_fetch_fields['vulnerability'] = [
    'identifier', 'description', 'urls', 'cvss_base_score', 'public_notes',
    'allows_escalation', 'effort', 'incomplete_reason'].concat([
        'model_id', 'vulnerability_id', 'patches', 'hosts',
        'scan_results', 'risk', 'risk_percentile'].map((f) => 'vulnerability_analysis.'+f));

async function retrieve_model_records(type, ids, ignoreCache=false, additionalFilters={})
{
    const riskType = parse_url_hash()['risk_type'];

    if (riskType && type === 'patch') {
        if (riskType === 'direct') {
            _fetch_fields[type].push('patch_analysis.direct_risk');
            _fetch_fields[type].push('patch_analysis.direct_hosts$count');
        } else {
            _fetch_fields[type].push('patch_analysis.risk');
            _fetch_fields[type].push('patch_analysis.hosts$count');
        };
    };

    var ret_val = {};
    if(!ids || ids.length == 0)
        return {};

    var to_fetch = {};

    if (ignoreCache) {
      ids.map( id => {
        to_fetch[id] = null;
      });

    } else {
      for(var id of ids)
      {
          if(!id)
              continue;

          if(_model_cache[type][id])
              ret_val[id] = _model_cache[type][id];
          else
              to_fetch[id] = null;
      }
    }


    if(Object.keys(to_fetch).length > 0)
    {
        //console.log('attempting to fetch:', to_fetch);
        var start = (new Date()).getTime();
        var filters = {'id_list':Object.keys(to_fetch), 'extra_columns':_fetch_fields[type], 'rownums':[], ...additionalFilters };
        var response = await makeRequest('SEARCH', '/model/'+type,
                                          {'project':project, 'model':model, 'filters':filters});
        if('errors' in response)
        {
            console.log('Unexpected errors occurred in retrieve_model_records (type='+type+'):', response['errors']);
            return null;
        }

        for(var record of response['results'])
        {
            ret_val[record['id']] = record;
            _model_cache[type][record['id']] = record;
        }
        //console.log(to_fetch.length+' '+type+' records fetched in '
        //            +(((new Date()).getTime()-start)/1000.0)+' seconds');
    }

    /*
    console.log('retrieve_model_records ('+type+') result:', ret_val, 'from ids:', ids);
    if(ids.length == 1)
        console.trace();
    */
    return ret_val;
}
export var fetch_patches = retrieve_model_records.bind(null, 'patch');
export var fetch_vulnerabilities = retrieve_model_records.bind(null, 'vulnerability');
export var fetch_scopes = retrieve_model_records.bind(null, 'scope');

export function global_setting(key)
{
    return _global_settings[key];
}

export async function reload_current_model()
{
    var gs_thread = load_global_settings();
    var pr_thread = fetch_project_record();
    var new_meta = await load_model_meta(project, model);
    new_meta.project = await pr_thread;
    await gs_thread;

    var old_meta = model_meta;
    model_meta = new_meta;
    //console.log('model_meta:'+JSON.stringify(model_meta));
    update_model_recently_changed(model_meta.project['settings']['risk_target']);

    if(!old_meta || old_meta['last_analyzed'] < model_meta['last_analyzed'])
    {
        clear_model_cache();
        await load_model(project, model, prepare_report_data);
    }

    return null;
}

export async function reload_current_model_periodic(gro)
{
    if(!gro)
        gro = $get('global-risk-outer');

    await reload_current_model();

    if(gro == $get('global-risk-outer'))
    {
        var delay = 10000;
        //console.log('refreshing model in '+delay+'...');
        window.setTimeout(reload_current_model_periodic, delay, gro);
    }
}

export var global_risk = 0.0;
function update_model_recently_changed(risk_target)
{
    if (model_meta)
    {
        var grwo = $get('global-risk-warning-outer');
        var warning_exists = $get('warningtip-link-model-recently-changed') !== null;

        if(model_meta['analysis_stale']) {
            if(!warning_exists && grwo) {
                // add_warning(grwo, 'model-recently-changed');
            }
        } else {
            // this will clear the warning sign if it exists
            clear_tag(grwo);
        }

        global_risk = model_meta['risk'];
        if(global_risk)
        {
            const percentRiskEl = $get('percent-risk');
            clear_tag(percentRiskEl);
            var gr = $get('global-risk');
            if (gr) {
              clear_tag(gr);
              gr.append(""+Number(global_risk.toFixed(0)).toLocaleString());
              var global_risk_label = global_target_risk_to_label(risk_target);
              percentRiskEl.parentNode.removeAttribute('class');

              var tr = $get('target-risk');
              clear_tag(tr);
              tr.append( "" + Number(Math.round(risk_target)).toLocaleString());
              let riskPercent = 0;
              if (risk_target && global_risk) {
                if (risk_target < global_risk) {
                  riskPercent = Math.round(((global_risk - risk_target) / risk_target ) * 100);
                  percentRiskEl.parentNode.setAttribute('class', `risk-up risk-${global_risk_label}`)
                } else {
                  riskPercent = Math.round(((risk_target - global_risk) / risk_target ) * 100);
                  percentRiskEl.parentNode.setAttribute('class', `risk-down risk-${global_risk_label}`)
                }
              }
              percentRiskEl.append(`${riskPercent}%`)
            }

        }
    }
}

export async function search_model_records(type, filters) {
  let params = {
    filters,
    project,
    model,
  };

  const start = new Date().getTime();

  const response = await makeRequest('SEARCH', `/model/${type}`, params);
  //console.log(response);
  const seconds = ( new Date().getTime() - start ) / 1000.0;
  //console.trace('Search time: '+seconds, params, response['results']);
  console.log('Search time: '+ seconds, type, params, response['results']);

  return response.results;
}

/*  */
export async function add_model_records(type, additions)
{
    return await makeRequest('ADD', '/model/'+type, {'project':project, 'model':model, 'additions':additions});
}

/* Changes is a list of record diffs.
 * Every diff must include the id field.
 * Be sure to include values for *only* the fields that have actually been altered by the user.
 */
export async function update_model_records(type, changes)
{
    return await makeRequest('UPDATE', '/model/'+type, {'project':project, 'model':model, 'changes':changes});
}

/*  */
export async function delete_model_records(type, record_ids)
{
    return await makeRequest('DELETE', '/model/'+type, {'project':project, 'model':model, 'ids':record_ids});
}

async function getBranchVersion() {
  const info = (await makeRequest('FETCH', '/about', {}))['results'];
  const branchRegex = /[^-]*/;

  const branchVersion = info ? info['version'].match(branchRegex)[0] : '';
  return branchVersion;
}

export async function generateHelpURI(path) {
  const pathParts = path.split("#");
  const branchVersion = await getBranchVersion();
  const token = await current_docs_token();
  return `https://docs.deepsurface.com/deepsurface/${branchVersion}${pathParts[0]}?t=${token}${ pathParts[1] ? '#' + pathParts[1] : ''}`;
};

/* Grab a fresh authentication token for use with the documentation site, granting the user access without having to log in */
var _docs_token = null;
var _docs_token_last = Math.floor(Date.now() / 1000);
export async function current_docs_token()
{
    var now = Math.floor(Date.now() / 1000);

    if(!_docs_token || (now > _docs_token_last + 15*60)) // 15min refresh
    {
        var response = await makeRequest('COMPUTE', '/profile', {'type':'docs_token'});
        _docs_token = response['results'];
        _docs_token_last = now;
    }

    return _docs_token;
}
