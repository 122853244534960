/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { cvssScoreToRating, isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import RatingBadge from '../../../../shared/RatingBadge';

import './VulnerabilityHeader.scss';
import { defaultVulnerabilityURL } from '../../../App/Routing';

const VulnerabilityHeader = ( {
  vulnerability,
  options,
  handleClose,
  context,
  collapsed=false,
  setCollapsed=() => {},
} ) => {

  const getExploreLink = ( record, recordType ) => {
    // eslint-disable-next-line max-len
    return `#.=explore&page=explore_model&type=vulnerability&explore_${ recordType === 'host' ? 'scope' : recordType }=%5B"${record.id}"%5D`;
  };

  const handleExploreClick = () => {
    if ( isNotEmpty( vulnerability ) ) {
      window.open( getExploreLink( vulnerability, 'vulnerability' ) );
    }
  };

  const riskClass = () => {
    if ( isNotEmpty( vulnerability ) ) {
      if ( 'filtered_risk' in vulnerability ) {
        return riskToRating( vulnerability.filtered_risk );
      }
      return riskToRating( vulnerability.risk );
    }
    return 'unknown';
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( vulnerability ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              {
                ( isNotEmpty( options ) && options.isDraggable ) &&
                <InlineSVG type="draggable" elementClass="draggableIcon" />
              }
              <div className={ `recordIconWrapper ${ riskClass() }` } >
                <InlineSVG type="vulnerability_record" />
              </div>
              <span className="label">
                { reportTypeDisplayName( vulnerability, 'vulnerability' ) }
              </span>
            </h2>
            <div className="right">
              {
                (
                  isNotEmpty( vulnerability )
                  && isNotEmpty( options )
                  && ( options.include_cvss_breakdown )
                ) &&
                <RatingBadge
                  rating={ cvssScoreToRating( vulnerability.cvss_base_score ) }
                  alternateText={ `CVSS Score: ${vulnerability.cvss_base_score}`}
                  elementClass="cvssRatingBadge"
                />
              }
              {
                ( isNotEmpty( options ) && 'include_risk' in options )
                  ? <React.Fragment>
                    {
                      options.include_risk === true &&
                      <RatingBadge rating={ riskClass() } />
                    }
                  </React.Fragment>
                  : <RatingBadge rating={ riskClass() } />
              }
              {
                ( isNotEmpty( options ) && options.isDismissable ) &&
                <button
                  className="recordCardCloseButton roundGlyphButton light"
                  onClick={ handleClose }
                >
                  <InlineSVG type="close" elementClass="closeIcon" />
                </button>
              }
            </div>
          </div>
          <div className={ `recordCardHeaderRow__dividerBar ${ riskClass() }` } />
          <div className="recordCardHeaderRow">
            <div className="left"></div>
            <div className="right">
              {
                context !== 'explore' &&
                <button
                  className="roundGlyphButton light"
                  // eslint-disable-next-line max-len
                  onClick={ handleExploreClick }
                >
                  <InlineSVG type="explore_nav" />
                </button>
              }
              {
                context === 'explore' &&
                <React.Fragment>
                  {
                    options?.isCollapsible &&
                    <button
                      className="roundGlyphButton light"
                      // eslint-disable-next-line max-len
                      onClick={ () => setCollapsed( !collapsed ) }
                    >
                      {
                        collapsed
                          ? <InlineSVG type="expand" />
                          : <InlineSVG type="collapse" />
                      }
                    </button>
                  }
                </React.Fragment>
              }
              {
                ( context !== 'record_details' || options.include_details_link ) &&
                <button
                  className="roundGlyphButton light"
                  // eslint-disable-next-line max-len
                  onClick={ () => window.open( `${defaultVulnerabilityURL}&item=${vulnerability?.id}&panel_tab=risk_and_remediation` ) }
                >
                  <InlineSVG type="newTabLink" />
                </button>
              }
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default VulnerabilityHeader;