/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Bar from '../../../shared/Charts/Bar';

import {
  capitalize,
  cvssScoreToRating,
  decodeURLHash,
  formatNumber,
  formatRiskReduction,
  getDimensionsAndOffset,
  globalColors,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  pluralizeType,
  reportTypeDisplayName,
  riskColorMap,
  riskToRating,
} from '../../../shared/Utilities';
import Legend from '../../../shared/Charts/Legend';
import YAxisLabels from '../../../shared/Charts/AxisLabels/YAxisLabels';

const exploitStatusFillMap = {
  private: globalColors['grey--divider'],
  // eslint-disable-next-line camelcase
  published_details: globalColors['low'],
  poc: globalColors['moderate'],
  weaponized: globalColors['high'],
};

const exploitStatusValueTranslationMap = {
  private: 25,
  // eslint-disable-next-line camelcase
  published_details: 50,
  poc: 75,
  weaponized: 100,
};

const exploitStatusLabelTranslationMap = {
  25: 'Private',
  50: 'Details Published',
  75: 'PoC Published',
  100: 'Weaponized',
};

const orderingKeysThatAffectsVisual = [
  'risk',
  'filtered_risk',
  'direct_risk',
  'cumulative_risk',
  'cvss_base_score',
  'exploit_status',
  'num_vulnerabilities',
  'num_hosts',
  'num_patches',
  'num_unsuperseded_patches',
];

const DefaultVisual = ( {
  records,
  // collapsed,
  reportType,
  selectRecord,
  // hoverRecord,
  riskType,
} ) => {
  const [ barChartData, setBarChartData ] = React.useState( {} );

  const [ yMax, setYMax ] = React.useState( null );
  const [ svgContainerWidth, setSVGContainerWidth ] = React.useState( 1 );
  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( 1 );
  const [ legendData, setLegendData ] = React.useState( null );
  const [ shouldOrderByTiers, setShouldOrderByTiers ] = React.useState( false );
  const [ primaryOrderBy, setPrimaryOrderBy ] = React.useState( null );
  const [ widgetTitle, setWidgetTitle ] = React.useState( '' );
  const [ legendLabel, setLegendLabel ] = React.useState( '' );

  const [ hoveredSeries, setHoveredSeries ] = React.useState( null );

  const svgContainerRef = React.useRef( null );

  let windowWidth = window.innerWidth;

  // fires on all resize events, throttles to changes greater than 100px
  const adjustSVGAspectRatio = ( onPageLoad=false ) => {

    const adjustmentThreshold = 100;

    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {
      if ( ( Math.abs( window.innerWidth - windowWidth ) > adjustmentThreshold ) || onPageLoad ) {
        const dimensions = getDimensionsAndOffset( svgContainerRef.current );
        setSVGContainerWidth( dimensions.width );
        setSVGContainerHeight( dimensions.height );

        windowWidth = window.innerWidth;
      }
    }
  };

  const getTierKey = ( value, orderBy, max, record ) => {
    if (
      orderBy === 'risk'
      || orderBy === 'filtered_risk'
      || orderBy === 'direct_risk'
      || !orderingKeysThatAffectsVisual.includes( orderBy )
    ) {
      if ( reportType === 'patch' && orderBy === 'filtered_risk' ) {
        return riskToRating( record[riskType] );
      }
      if ( reportType === 'user' ) {
        return riskToRating( record.risk );
      }
      if ( reportType === 'host' && isNotEmpty( record ) && !record.has_host ) {
        return 'unknown';
      }
      return riskToRating( value );
    }
    if ( orderBy === 'cvss_base_score' ) {
      return cvssScoreToRating( value );
    }
    if ( orderBy === 'exploit_status' ) {
      return value;
    }
    const valuePercentile = value / max;
    let tierKey = 20;
    if ( valuePercentile >= 0.8 ) {
      tierKey = 100;
    } else if ( valuePercentile >= 0.6 ) {
      tierKey = 80;
    } else if ( valuePercentile >= 0.4 ) {
      tierKey = 60;
    } else if ( valuePercentile >= 0.2 ) {
      tierKey = 40;
    }
    return tierKey;
  };

  const getFillForAttribute = ( value, orderBy, max, record ) => {
    if ( isNotEmpty( orderBy ) && isNotEmpty( max ) ) {

      // use the risk criticality scale
      if ( orderBy === 'risk' || orderBy === 'filtered_risk' || orderBy === 'direct_risk' ) {
        if ( reportType === 'host' && isNotEmpty( record ) && !record.has_host ) {
          return globalColors['grey--icon'];
        }
        if ( reportType === 'patch' && orderBy === 'filtered_risk' ) {
          const rating = riskToRating( record[riskType] );
          return riskColorMap[rating];
        }
        if ( reportType === 'user' && orderBy === 'filtered_risk' ) {
          const rating = riskToRating( record.risk );
          return riskColorMap[rating];
        }
        const rating = riskToRating( value );
        return riskColorMap[rating];
      }
      // use the risk criticality scale
      if ( orderBy === 'cvss_base_score' ) {
        const rating = cvssScoreToRating( value );
        return riskColorMap[rating];
      }
      // use the risk criticality scale
      if ( orderBy === 'exploit_status' ) {
        return exploitStatusFillMap[value];
      }
      if ( orderBy === 'num_vulnerabilities' ) {
        return globalColors[`status--blue--${getTierKey( value, orderBy, max )}`];
      }
      if ( orderBy === 'num_hosts' ) {
        return globalColors[`status--yellow--${getTierKey( value, orderBy, max )}`];
      }
      if ( orderBy === 'num_patches' ) {
        return globalColors[`status--green--${getTierKey( value, orderBy, max )}`];
      }
      if ( orderBy === 'num_unsuperseded_patches' ) {
        return globalColors[`status--green--${getTierKey( value, orderBy, max )}`];
      }
    }
    // default
    return globalColors[`darkBlue--${getTierKey( value, orderBy, max )}`];
  };

  const getValueForAttribute = ( value, orderBy, record ) => {
    if ( orderBy === 'filtered_risk' && reportType === 'patch' ) {
      return record[riskType];
    }
    if ( orderBy === 'filtered_risk' && reportType === 'user' ) {
      return record.risk;
    }
    if ( orderBy === 'exploit_status' ) {
      return exploitStatusValueTranslationMap[value];
    }
    return value;
  };

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( records ) ) {
      windowWidth = window.innerWidth;

      setTimeout( () => {
        adjustSVGAspectRatio( true );
      }, 100 );

      window.addEventListener( 'resize', adjustSVGAspectRatio );
      return () => window.removeEventListener( 'resize', adjustSVGAspectRatio );
    }
  }, [ svgContainerRef, records ] );

  // eslint-disable-next-line camelcase
  const translateOrderBy = ( order_by ) => {

    const orderByTranslationMap = {
      // eslint-disable-next-line camelcase
      num_hosts: 'affected hosts',
      // eslint-disable-next-line camelcase
      num_vulnerabilities: 'number of vulnerabilities',
      // eslint-disable-next-line camelcase
      num_patches: 'number of patches',
      // eslint-disable-next-line camelcase
      num_unsuperseded_patches: 'number of unsuperseded patches',
      // eslint-disable-next-line camelcase
      last_scanned: 'deepsurface scanning status',
      // eslint-disable-next-line camelcase
      filtered_risk: 'risk',
      // eslint-disable-next-line camelcase
      local_name: 'name',
      // eslint-disable-next-line camelcase
      identifier: 'name',
      // eslint-disable-next-line camelcase
      exploit_status: 'exploit status',
      // eslint-disable-next-line camelcase
      cvss_base_score: 'cvss base score',
      // // eslint-disable-next-line camelcase
      // 'cvss_exploit': 'CVSS score followed by exploit status',
      // // eslint-disable-next-line camelcase
      // 'cvss_hosts': 'CVSS score followed by affected hosts',
      // // eslint-disable-next-line camelcase
      // 'exploit_cvss': 'exploit status followed by CVSS score',
      // // eslint-disable-next-line camelcase
      // 'exploit_hosts': 'exploit status followed by affected hosts',
      // // eslint-disable-next-line camelcase
      // 'hosts_cvss': 'affected hosts followed by CVSS score',
      // // eslint-disable-next-line camelcase
      // 'hosts_exploit': 'affected hosts followed by exploit status',
    };

    return orderByTranslationMap[order_by] || capitalize( order_by );
  };

  const setupWidgetTitle = ( reportType ) => {
    const hash = decodeURLHash();
    if ( isNotEmpty( reportType ) ) {
      let _recordType = reportType;
      if ( isNotEmpty( hash.group_type ) ) {
        _recordType = hash.group_type;
      }

      if ( reportType === 'path' ) {
        setWidgetTitle(  <React.Fragment>
          <strong>{ capitalize( pluralizeType( _recordType ) ) }: </strong>
          <span>Ordered by risk</span>
        </React.Fragment>  );
      } else {
        const orders = [];

        if ( isNotEmpty( hash.order_by ) && itemIsArray( hash.order_by ) ) {
          hash.order_by.map( orderPair => {
            const [ orderBy, orderDirection ] = orderPair;
            // eslint-disable-next-line camelcase
            const direction = orderDirection === 'ASC' ? 'ascending' : 'descending';
            orders.push( `${translateOrderBy( orderBy )} ${direction}` );
          } );
        }
        // eslint-disable-next-line max-len
        setWidgetTitle(  <React.Fragment>
          <strong>{ capitalize( pluralizeType( _recordType ) ) }: </strong>
          {
            isNotEmpty( orders ) &&
            <span>{ `Ordered by ${ orders.join( ', ' ) }` }</span>
          }
        </React.Fragment>  );
      }

    }
  };

  // formats all of the data so that it conforms to what the bar chart and legend expect
  React.useEffect( () => {
    if ( isNotEmpty( records ) && isNotEmpty( reportType ) ) {
      setupWidgetTitle( reportType );

      const _barData = {};
      let _legendData = {};

      // eslint-disable-next-line camelcase
      let order_by = riskType;
      // if we are ordering by something other than risk, need to adjust the data accordingly
      const params = decodeURLHash();

      if ( isNotEmpty( params ) ) {
        ( { order_by } = params );
      }

      if ( isEmpty( order_by ) ) {
        // eslint-disable-next-line camelcase
        order_by = 'filtered_risk';
      } else if ( itemIsArray( order_by ) ) {
        // eslint-disable-next-line camelcase
        [ [ order_by ] ] = order_by;
      } else {
        // eslint-disable-next-line camelcase
        order_by = 'filtered_risk';
      }

      if ( !orderingKeysThatAffectsVisual.includes( order_by ) ) {
        // eslint-disable-next-line camelcase
        order_by = 'filtered_risk';
      }

      if ( reportType === 'path' ) {
        // eslint-disable-next-line camelcase
        order_by = 'risk';
      }

      setPrimaryOrderBy( order_by );

      const _yMax = Math.max( ...records.map( r => getValueForAttribute( r[order_by], order_by, r ) ) );

      if (
        // eslint-disable-next-line camelcase
        order_by === 'risk'
        // eslint-disable-next-line camelcase
        || order_by === 'filtered_risk'
        // eslint-disable-next-line camelcase
        || order_by === 'direct_risk'
        || !orderingKeysThatAffectsVisual.includes( order_by )
      ) {
        setShouldOrderByTiers( false );
        setLegendLabel( 'Risk Rating' );
        _legendData = {
          critical: { key: 'critical', label: 'Critical', total: 0, fill: globalColors.critical },
          high: { key: 'high', label: 'High', total: 0, fill: globalColors.high },
          moderate: { key: 'moderate', label: 'Moderate', total: 0, fill: globalColors.moderate },
          low: { key: 'low', label: 'Low', total: 0, fill: globalColors.low },
          minimal: { key: 'minimal', label: 'Minimal', total: 0, fill: globalColors.minimal },
        };
      // eslint-disable-next-line camelcase
      } else if ( order_by === 'cvss_base_score' ) {
        setShouldOrderByTiers( false );
        setLegendLabel( 'CVSS Base Score' );
        _legendData = {
          critical: { key: 'critical', label: 'Critical: 9 - 10', total: 0, fill: globalColors.critical },
          high: { key: 'high', label: 'High: 9 - 7.5', total: 0, fill: globalColors.high },
          moderate: { key: 'moderate', label: 'Moderate: 7.5 - 6', total: 0, fill: globalColors.moderate },
          low: { key: 'low', label: 'Low: 6 - 3', total: 0, fill: globalColors.low },
          minimal: { key: 'minimal', label: 'Minimal: 3 - 0', total: 0, fill: globalColors.minimal },
        };
      // eslint-disable-next-line camelcase
      } else if ( order_by === 'exploit_status' ) {
        setShouldOrderByTiers( false );
        setLegendLabel( 'Exploit Status' );
        _legendData = {
          weaponized: { key: 'weaponized', label: 'Weaponized', total: 0, fill: globalColors.high },
          poc: { key: 'poc', label: 'PoC Published', total: 0, fill: globalColors.moderate },
          // eslint-disable-next-line camelcase
          published_details: { key: 'published_details', label: 'Details Published', total: 0, fill: globalColors.low },
          private: { key: 'private', label: 'Private', total: 0, fill: globalColors['grey--divider'] },
        };
      // percentage tiers
      } else {
        setShouldOrderByTiers( true );
        setLegendLabel( 'Percentile' );
        _legendData = {
          100: { key: 100, label: '>80%', total: 0, fill: getFillForAttribute( 81, order_by, 100 ) },
          80: { key: 80, label: '60% - 80%', total: 0, fill: getFillForAttribute( 61, order_by, 100 ) },
          60: { key: 60, label: '40% - 60%', total: 0, fill: getFillForAttribute( 41, order_by, 100 ) },
          40: { key: 40, label: '20% - 40%', total: 0, fill: getFillForAttribute( 21, order_by, 100 ) },
          20: { key: 20, label: '<20%', total: 0, fill: getFillForAttribute( 11, order_by, 100 ) },
        };
      }

      if (
        reportType === 'host'
        // eslint-disable-next-line camelcase
        && (
          // eslint-disable-next-line camelcase
          order_by === 'risk'
          // eslint-disable-next-line camelcase
          || order_by === 'filtered_risk'
          // eslint-disable-next-line camelcase
          || order_by === 'direct_risk'
          || !orderingKeysThatAffectsVisual.includes( order_by )
        )
      ) {
        _legendData.unknown = { key: 'unknown', label: 'Unknown', total: 0, fill: globalColors['grey--icon'] };
      }

      // need to go through the data and format to what the barchart needs,
      // for the most part, just grab the values of what we are ordering by, but the
      // legend and the coloring need to be handled differently depending on what the order_by is,
      // the colors will align with the associated color for that record type in the dashboard widget, ie:

      // hosts => status--yellow
      // patches => status--green
      // vulns => status--blue

      // cvss => risk colors
      // exploit status => exploit status pallete

      // vuln instances => darkBlue
      // risk => status--red

      records.map( record => {
        if ( isNotEmpty( record ) ) {
          const key = getTierKey( record[order_by], order_by, _yMax, record );
          const fill = getFillForAttribute( record[order_by], order_by, _yMax, record );

          _barData[record.id] = {
            original: record,
            label: reportTypeDisplayName( record, reportType ),
            value: getValueForAttribute( record[order_by], order_by, record ),
            fill,
            stroke: fill,
            key,
          };
          _legendData[key].total += 1;
        }
      } );

      setLegendData( _legendData );
      setBarChartData( _barData );
      setYMax( _yMax );
    } else {
      setBarChartData( null );
    }
  }, [ records, reportType ] );

  const onClick = element => {
    if ( isNotEmpty( element ) ) {
      // eslint-disable-next-line
      const _id = element.id.split( '_' )[1];
      selectRecord( _id );
    }
  };

  const ticFormatter = tic => {
    if ( primaryOrderBy === 'risk' || primaryOrderBy === 'filtered_risk' || primaryOrderBy === 'direct_risk' ) {
      return formatRiskReduction( tic );
    } else if ( primaryOrderBy === 'cvss_base_score' ) {
      return tic.toFixed( 1 );
    } else if ( primaryOrderBy === 'exploit_status' ) {
      return exploitStatusLabelTranslationMap[tic];
    }
    return formatNumber( Math.floor( tic ) );
  };

  const getTicCount = () => {
    if ( primaryOrderBy === 'cvss_base_score' || primaryOrderBy === 'exploit_status' ) {
      return 4;
    }
    return 3;
  };

  const getYAxisMax = () => {
    if ( primaryOrderBy === 'cvss_base_score' ) {
      return 10;
    }
    if ( primaryOrderBy === 'exploit_status' ) {
      return 100;
    }
    return yMax;
  };


  return (
    <React.Fragment>
      {
        isNotEmpty( barChartData ) &&
        <div className="dashboardWidgetWrapper noWrapper">
          <h3 className="widgetTitle">{ widgetTitle }</h3>
          <div className="widgetContent" ref={svgContainerRef} >
            <YAxisLabels yMax={ getYAxisMax() } ticsCount={ getTicCount() } ticFormatter={ ticFormatter } />
            <Bar
              data={barChartData}
              containerHeight={ svgContainerHeight }
              containerWidth={ svgContainerWidth }
              onClick={ onClick }
              maxOverride={ getYAxisMax() }
              insightVersion
              hoveredSeriesIdentifier={ hoveredSeries }
              // setHoveredSeriesIdentifier={ setHoveredSeries }
            />
            <Legend
              label={ legendLabel }
              legendData={legendData}
              hoveredSeriesIdentifier={ hoveredSeries }
              setHoveredSeriesIdentifier={ setHoveredSeries}
              orderByTiers={ shouldOrderByTiers }
            />

          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default DefaultVisual;