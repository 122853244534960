/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import {
  isNotEmpty,
  formatUnixDate,
} from '../../../Utilities';

const Date = ( {
  field,
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {
  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      if ( field.needsUnixTransform ) {
        if ( isNotEmpty( originalValue ) ) {
          fieldRef.current.value = formatUnixDate( originalValue );
        } else {
          fieldRef.current.value = '';
        }
      } else {
        fieldRef.current.value = originalValue || '';
      }
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  // transform going out
  const handleDateChange = ( field, e ) => {
    if ( field.needsUnixTransform ) {
      if ( Number.isNaN( e.target.valueAsNumber ) ) {
        onChange( field, '' );
      } else {
        onChange( field, e.target.valueAsNumber / 1000.0 );
      }
    } else {
      onChange( field, e.target.valueAsNumber );
    }
  };

  return (
    <React.Fragment>
      <input
        ref={fieldRef}
        placeholder={ field.placeholder ? field.placeholder : ''}
        disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
        type={field.type}
        onChange={ e => handleDateChange( field, e ) }
        { ...field.htmlProps }
      />
    </React.Fragment>
  );
};

export default Date;
