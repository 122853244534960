/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../shared/Utilities';
import CVSSChart from '../../shared/Charts/CVSSChart';
import Legend from '../../shared/Charts/Legend';
import { getThemeColor } from '../../shared/Themes';

const order = [
  'Critical',
  'High',
  'Moderate',
  'Low',
  'Minimal',
];

const categoryFillMap = {
  critical: getThemeColor( '--critical' ),
  high: getThemeColor( '--high' ),
  moderate: getThemeColor( '--moderate' ),
  low: getThemeColor( '--low' ),
  minimal: getThemeColor( '--minimal' ),
};

const minMax = {
  critical: {
    maximum: 10,
    minimum: 9,
  },
  high: {
    maximum: 9,
    minimum: 7.5,
  },
  moderate: {
    maximum: 7.5,
    minimum: 6,
  },
  low: {
    maximum: 6,
    minimum: 3,
  },
  minimal: {
    maximum: 3,
    minimum: 0,
  },
};

const CVSSBreakdown = ( { recordInstanceData } ) => {

  const [ chartData, setChartData ] = React.useState( null );
  const [ hoveredSectionIdentifier, setHoveredSectionIdentifier ] = React.useState( null );
  const svgContainerRef = React.useRef( null );

  React.useEffect( () => {
    if ( isNotEmpty( recordInstanceData ) ) {
      const _chartData = {};
      const { cvss } = recordInstanceData.tally?.results;
      const _counts = {
        critical: cvss?.critical,
        high: cvss?.high,
        moderate: cvss?.moderate,
        low: cvss?.low,
        minimal: cvss?.minimal,
      };

      order.map( name => {

        console.log( categoryFillMap[name.toLowerCase()] );
        _chartData[name.toLowerCase()] = {
          key: name.toLowerCase(),
          label: name,
          fill: getThemeColor( name.toLowerCase() ),
          value: _counts[name.toLowerCase()],
          total: _counts[name.toLowerCase()],
          subLabel: `${ minMax[name.toLowerCase()].maximum} - ${ minMax[name.toLowerCase()].minimum}`,
        };
      } );
      setChartData( _chartData );
    }
  }, [ recordInstanceData ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( chartData ) &&
        <div className="recordDetails__InformationPanel__Body_Section cvssBreakdown">
          <div className="bodySectionItem">
            <label>CVSS Score Breakdown</label>
            <div className="chartAndLegendWrapper">
              <CVSSChart
                cvssData={ chartData }
                svgRef={ svgContainerRef }
                containerWidth={ 230 }
                containerHeight={ 100 }
                hoveredSeriesIdentifier={ hoveredSectionIdentifier }
                setHoveredSeriesIdentifier={ setHoveredSectionIdentifier }
              />
              <Legend
                legendData={ chartData }
                hoveredSeriesIdentifier={ hoveredSectionIdentifier }
                setHoveredSeriesIdentifier={ setHoveredSectionIdentifier }
              />
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default CVSSBreakdown;