/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  ACLRoleLabelMap,
  formatNumber,
  generateAPICredentials,
  isEmpty,
  isNotEmpty,
  itemIsArray,
} from '../../../../../shared/Utilities';
import { isAdmin } from '../../../../App/AccessControl';
import { makeRequest } from '../../../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../../../Contexts/FlashMessageQueue';
import { CurrentUserContext } from '../../../../../Contexts/CurrentUser';
import AccessItem from './AccessItem';
import EmptyState from '../../../../../shared/EmptyState';
import InlineSVG from '../../../../../shared/InlineSVG';
import Form from '../../../../../shared/Form';
import { getFieldValues } from '../../../../../shared/Form/Shared';
import CheckButton from '../../../../../shared/Form/CheckButton';

const AccessAndCredentials = ( { onRefresh, user } ) => {

  // eslint-disable-next-line camelcase
  const AC_Fields = {
    left: {
      fields: [
        {
          type: 'select',
          label: 'Role',
          attribute: 'acl_role',
          options: ACLRoleLabelMap,
          required: true,
          defaultValue: 'report_consumer',
        },
        // {
        //   type: 'checkbox',
        //   label: 'Allow viewing of all dashboards?',
        //   attribute: 'acl_all_dashboards',
        //   defaultValue: false,
        // },
        // {
        //   type: 'checkbox',
        //   label: 'Allow viewing of all asset tags?',
        //   attribute: 'acl_all_asset_tags',
        //   defaultValue: false,
        // },
        // {
        //   type: 'checkbox',
        //   label: 'Allow viewing of all remediation plans?',
        //   attribute: 'acl_all_remediation_plans',
        //   defaultValue: false,
        // },
      ],
    },
    right: {
      fields: [
        {
          type: 'apiCredentials',
          label: 'API Credentials',
          attribute: 'api_credentials',
          generateOptions: {
            generate: () => generateAPICredentials(),
            buttonText: 'Generate API Credentials',
          },
          defaultValue: { key: '',  secret: '' },
        },
      ],
    },
  };

  const [ credFields, setCredFields ] = React.useState( [] );

  const [ updatedForm, setUpdatedForm ] = React.useState( {} );

  const [ dashboardsCollapsed, setDashboardsCollapsed ] = React.useState( false );
  const [ tagsCollapsed, setTagsCollapsed ] = React.useState( false );
  const [ remediationPlansCollapsed, setRemediationPlansCollapsed ] = React.useState( false );

  const [ formattedDashboards, setFormattedDashboards ] = React.useState( [] );
  const [ formattedTags, setFormattedTags ] = React.useState( [] );
  const [ formattedRemediationPlans, setFormattedRemediationPlans ] = React.useState( [] );

  const [ userAllAccessValues, setUserAllAccessValues ] = React.useState( null );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ currentUser ] = React.useContext( CurrentUserContext );

  const getExistingAccessLevel = ( existingAccess, id, type, userAllAccessValues ) => {
    const _accessItem = existingAccess.find( access => access[`${type}_id`] === id );
    const override = userAllAccessValues[`acl_all_${type}s`] || null;
    const existing = _accessItem?.access_level || null;

    return ( { existing, override } );
  };

  const setupAccessInformation = async ( userAllAccess ) => {

    const _formattedDashboards = {};
    const _formattedTags = {};
    const _formattedRemediationPlans = {};

    const accessParams = {
      columns: [
        'id',
        'modified',
        'user_id',
        'access_level',
        'asset_tag_id',
        'dashboard_id',
        'remediation_plan_id',
      ],
      // eslint-disable-next-line camelcase
      filters: { field_map: { user_id: user.id } },
    };

    const dashboardParams = {
      columns: '*',
      filters: {},
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'created', 'DESC' ],
      ],
      rows: [ 0, 100 ],
    };

    const existingAccess = await makeRequest( 'POST', '/fe/object_access/SELECT', accessParams );

    // this will need to be refactored at some point, for now, these endpoints do not allow you to get only
    // the items that a particular user has access to, so we need to get all of them and then filter them out
    const dashboardsRequest = await makeRequest( 'POST', '/fe/dashboard/SELECT', dashboardParams );
    const tagsRequest = await makeRequest( 'SEARCH', '/project/default/asset_tag', {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'label',
        'color',
        'included_ranges',
        'excluded_ranges',
        'included_host_patterns',
        'excluded_host_patterns',
        'included_host_ids',
        'excluded_host_ids',
        'included_product_names',
        'excluded_product_names',
        'remediation_manager',
      ],
      // eslint-disable-next-line camelcase
      order_by:[ [ 'label', 'ASC' ] ],
    } );
    const remediationPlansRequest = await makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} );

    if ( isNotEmpty( dashboardsRequest ) && itemIsArray( dashboardsRequest ) ) {
      dashboardsRequest.map( dashboard => {
        const { existing, override } = getExistingAccessLevel(
          existingAccess,
          dashboard.id,
          'dashboard',
          userAllAccess,
        );

        if ( isAdmin( currentUser ) ) {
          _formattedDashboards[dashboard.id] = {
            id: dashboard.id,
            label: dashboard.label,
            itemType: 'dashboard',
            existing,
            override,
          };
        }
      } );
      setFormattedDashboards( _formattedDashboards );
    }

    if ( isNotEmpty( tagsRequest?.results ) ) {
      tagsRequest.results.map( tag => {
        const { existing, override } = getExistingAccessLevel(
          existingAccess,
          tag.id,
          'asset_tag',
          userAllAccess,
        );

        if ( isAdmin( currentUser ) ) {
          _formattedTags[tag.id] = {
            id: tag.id,
            label: tag.label,
            color: tag.color,
            itemType: 'asset_tag',
            existing,
            override,
          };
        }
      } );
      setFormattedTags( _formattedTags );
    }

    if ( isNotEmpty( remediationPlansRequest?.results ) ) {
      remediationPlansRequest.results.map( plan => {
        const { existing, override } = getExistingAccessLevel(
          existingAccess,
          plan.id,
          'remediation_plan',
          userAllAccess,
        );

        if ( isAdmin( currentUser ) ) {
          _formattedRemediationPlans[plan.id] = {
            id: plan.id,
            label: plan.label,
            status: plan.status,
            itemType: 'remediation_plan',
            existing,
            override,
          };
        }
      } );
      setFormattedRemediationPlans( _formattedRemediationPlans );
    }
  };

  const handleAccessLevelChange = ( accessItem, accessItemType, accessLevel ) => {
    switch ( accessItemType ) {
    case 'dashboard':
      setFormattedDashboards( { ...formattedDashboards, [accessItem.id]: { ...accessItem, existing: accessLevel } } );
      break;
    case 'asset_tag':
      setFormattedTags( { ...formattedTags, [accessItem.id]: { ...accessItem, existing: accessLevel } } );
      break;
    case 'remediation_plan':
      setFormattedRemediationPlans( {
        ...formattedRemediationPlans,
        [accessItem.id]: { ...accessItem, existing: accessLevel },
      } );
      break;
    default:
      break;
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( user ) && isNotEmpty( currentUser ) ) {
      // eslint-disable-next-line camelcase
      const _fields = { ...AC_Fields };

      const roleField = _fields.left.fields.find( field => field.attribute === 'acl_role' );

      if ( user.id === currentUser.id || !isAdmin( currentUser ) ) {
        roleField.disabled = true;
      } else {
        roleField.disabled = false;
      }

      setCredFields( _fields );
      const _accessLevels = {
        // eslint-disable-next-line camelcase
        acl_all_asset_tags: user.acl_all_asset_tags,
        // eslint-disable-next-line camelcase
        acl_all_dashboards: user.acl_all_dashboards,
        // eslint-disable-next-line camelcase
        acl_all_remediation_plans: user.acl_all_remediation_plans,
      };
      setUserAllAccessValues( _accessLevels );
      setupAccessInformation( _accessLevels );
    }
  }, [ user, currentUser ] );

  const handleViewAllCheck = ( e,  sectionKey ) => {

    const newValue = !userAllAccessValues[sectionKey];

    const _userAllAccessValues = {
      ...userAllAccessValues,
      [sectionKey]: !userAllAccessValues[sectionKey],
    };

    const _formattedDashboards = { ...formattedDashboards };
    const _formattedTags = { ...formattedTags };
    const _formattedRemediationPlans = { ...formattedRemediationPlans };

    const _newDashboards = {};
    const _newTags = {};
    const _newRemediationPlans = {};

    switch ( sectionKey ) {
    case 'acl_all_dashboards':
      Object.values( _formattedDashboards ).map( dashboard => {
        const _dashboard = { ...dashboard, override: newValue };
        _newDashboards[dashboard.id] = _dashboard;
      } );
      setFormattedDashboards( _newDashboards );
      break;
    case 'acl_all_asset_tags':
      Object.values( _formattedTags ).map( tag => {
        const _tag = { ...tag, override: newValue };
        _newTags[tag.id] = _tag;
      } );
      setFormattedTags( _newTags );
      break;
    case 'acl_all_remediation_plans':
      Object.values( _formattedRemediationPlans ).map( plan => {
        const _plan = { ...plan, override: newValue };
        _newRemediationPlans[plan.id] = _plan;
      } );
      setFormattedRemediationPlans( _newRemediationPlans );
      break;
    default:
      break;
    }
    setUserAllAccessValues( _userAllAccessValues );
  };

  React.useEffect( () => {
    if ( userAllAccessValues?.acl_all_dashboards === true ) {
      setDashboardsCollapsed( true );
    } else {
      setDashboardsCollapsed( false );
    }
    if ( userAllAccessValues?.acl_all_asset_tags === true ) {
      setTagsCollapsed( true );
    } else {
      setTagsCollapsed( false );
    }
    if ( userAllAccessValues?.acl_all_remediation_plans === true ) {
      setRemediationPlansCollapsed( true );
    } else {
      setRemediationPlansCollapsed( false );
    }
  }, [ userAllAccessValues ] );

  const canGrantSpecificAccess = () => {
    if ( isEmpty( currentUser ) || !isAdmin( currentUser ) ) {
      return false;
    }
    if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      const values = getFieldValues( updatedForm.fieldStates, 'user' );
      if ( values.acl_role === 'report_consumer' ) {
        return true;
      }
      return false;
    } else if ( isNotEmpty( user ) ) {
      if ( user.acl_role === 'report_consumer' ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleAccessSave = async () => {

    const accessItemsToUpsert = [];
    const accessItemsToDelete = [];

    const accessItems = [
      ...Object.values( formattedDashboards ),
      ...Object.values( formattedTags ),
      ...Object.values( formattedRemediationPlans ),
    ];

    accessItems.map( item => {
      const accessItem = {
        // eslint-disable-next-line camelcase
        user_id: user.id,
        // eslint-disable-next-line camelcase
        access_level: item.existing,
        [`${item.itemType}_id`]: item.id,
      };

      if ( item.existing === 'viewer' || item.existing === 'owner' ) {
        accessItemsToUpsert.push( accessItem );
      } else {
        delete accessItem.access_level;
        accessItemsToDelete.push( accessItem );
      }
    } );


    // save all access additions
    if ( isNotEmpty( accessItemsToUpsert ) ) {
      await makeRequest( 'PUT', '/fe/object_access/UPSERT', accessItemsToUpsert );
    }
    // save all access deletions
    if ( isNotEmpty( accessItemsToDelete ) ) {
      await makeRequest( 'PUT', '/fe/object_access/DELETE', accessItemsToDelete );
    }
    if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {

      const userParams = getFieldValues( updatedForm.fieldStates, 'user' );
      userParams.id = user.id;
      // eslint-disable-next-line camelcase
      userParams.api_key = userParams.api_key.key;
      // eslint-disable-next-line camelcase
      userParams.api_secret = userParams.api_key.secret;

      if ( isNotEmpty( userAllAccessValues ) ) {
        // eslint-disable-next-line camelcase
        userParams.acl_all_asset_tags = userAllAccessValues.acl_all_asset_tags;
        // eslint-disable-next-line camelcase
        userParams.acl_all_dashboards = userAllAccessValues.acl_all_dashboards;
        // eslint-disable-next-line camelcase
        userParams.acl_all_remediation_plans = userAllAccessValues.acl_all_remediation_plans;
      }

      const profileVersion = isNotEmpty( currentUser )
        && currentUser.id === userParams.id;

      if ( profileVersion ) {
        delete userParams.id;
        delete userParams.acl_role;
        delete userParams.acl_all_asset_tags;
        delete userParams.acl_all_dashboards;
        delete userParams.acl_all_remediation_plans;

        await makeRequest( 'PUT', 'fe/profile/UPDATE', userParams );
      } else {
        await makeRequest( 'PUT', '/fe/user/UPDATE', [ userParams ] );
      }
    }
    addFlashMessage( {
      body: 'Saved access levels and credentials',
      type: 'success',
    } );

    onRefresh();
  };

  return (
    <React.Fragment>
      <h2 className="userDetails__MainPanel__Header">Access and Credentials</h2>
      {
        isAdmin( currentUser ) &&
        <Form
          recordType="user"
          fields={credFields}
          existingRecord={user}
          onChangeCallback={setUpdatedForm}
        />
      }
      {
        canGrantSpecificAccess() &&
        <div className="accessActionsWrapper">
          <div className="accessActionsHeader">Specific Record Access</div>
          <div className={`collapsibleSectionWrapper ${dashboardsCollapsed ? 'collapsed' : ''}`}>
            <div
              className="collapsibleSectionHeader"
              onClick={() => setDashboardsCollapsed( !dashboardsCollapsed )}
            >
              <div className="headerLeft">
                <CheckButton
                  checked={ userAllAccessValues?.acl_all_dashboards }
                  handleCheck={ e => handleViewAllCheck( e, 'acl_all_dashboards' ) }
                  elementClass="viewer"
                  label="View all"
                />
                <InlineSVG type="reporting_nav" />
                <h3>Dashboards</h3>
              </div>
              <div className="headerRight">
                <strong className="sectionCount">
                  {/* eslint-disable-next-line max-len */}
                  { formatNumber( isNotEmpty( formattedDashboards ) ? Object.keys( formattedDashboards ).length : 0 ) }
                </strong>
                <span className="carretWrapper">
                  <InlineSVG type="carretUp" />
                </span>
              </div>
            </div>
            <div className="collapsibleSectionBody">
              <ul>
                { isNotEmpty( formattedDashboards ) && Object.values( formattedDashboards ).map( dashboard => (
                  <AccessItem
                    key={dashboard.id}
                    accessItem={dashboard}
                    accessItemType="dashboard"
                    callback={handleAccessLevelChange}
                    userAllAccessValues={userAllAccessValues}
                  />
                ) )}
              </ul>
              {
                isEmpty( formattedDashboards ) &&
                <EmptyState message="This user does not have access to any dashboards" />
              }
            </div>
          </div>
          <div className={`collapsibleSectionWrapper ${tagsCollapsed ? 'collapsed' : ''}`}>
            <div
              className="collapsibleSectionHeader"
              onClick={() => setTagsCollapsed( !tagsCollapsed )}
            >
              <div className="headerLeft">
                <CheckButton
                  checked={ userAllAccessValues?.acl_all_asset_tags }
                  handleCheck={ e => handleViewAllCheck( e, 'acl_all_asset_tags' ) }
                  elementClass="viewer"
                  label="View all"
                />
                <InlineSVG type="host_record" />
                <h3>Tags</h3>
              </div>
              <div className="headerRight">
                <strong className="sectionCount">
                  {/* eslint-disable-next-line max-len */}
                  { formatNumber( isNotEmpty( formattedTags ) ? Object.keys( formattedTags ).length : 0 ) }
                </strong>
                <span className="carretWrapper">
                  <InlineSVG type="carretUp" />
                </span>
              </div>
            </div>
            <div className="collapsibleSectionBody">
              <ul>
                { isNotEmpty( formattedTags ) && Object.values( formattedTags ).map( tag => (
                  <AccessItem
                    key={tag.id}
                    accessItem={tag}
                    accessItemType="asset_tag"
                    callback={handleAccessLevelChange}
                    userAllAccessValues={userAllAccessValues}
                  />
                ) )}
              </ul>
              {
                isEmpty( formattedTags ) &&
                <EmptyState message="This user does not have access to any tags" />
              }
            </div>
          </div>
          <div className={`collapsibleSectionWrapper ${remediationPlansCollapsed ? 'collapsed' : ''}`}>
            <div
              className="collapsibleSectionHeader"
              onClick={() => setRemediationPlansCollapsed( !remediationPlansCollapsed )}
            >
              <div className="headerLeft">
                <CheckButton
                  checked={ userAllAccessValues?.acl_all_remediation_plans }
                  handleCheck={ e => handleViewAllCheck( e, 'acl_all_remediation_plans' ) }
                  elementClass="viewer"
                  label="View all"
                />
                <InlineSVG type="remediation_nav" />
                <h3>Remediation Plans</h3>
              </div>
              <div className="headerRight">
                <strong className="sectionCount">
                  {/* eslint-disable-next-line max-len */}
                  { formatNumber( isNotEmpty( formattedRemediationPlans ) ? Object.keys( formattedRemediationPlans ).length : 0 ) }
                </strong>
                <span className="carretWrapper">
                  <InlineSVG type="carretUp" />
                </span>
              </div>
            </div>
            <div className="collapsibleSectionBody">
              <ul>
                {
                  isNotEmpty( formattedRemediationPlans )
                  && Object.values( formattedRemediationPlans ).map( plan => (
                    <AccessItem
                      key={plan.id}
                      accessItem={plan}
                      accessItemType="remediation_plan"
                      callback={handleAccessLevelChange}
                      userAllAccessValues={userAllAccessValues}
                    />
                  ) )
                }
              </ul>
              {
                isEmpty( formattedRemediationPlans ) &&
                <EmptyState message="This user does not have access to any remediation plans" />
              }
            </div>
          </div>
        </div>
      }
      {
        isAdmin( currentUser ) &&
        <div className="accessActions">
          <button
            onClick={ handleAccessSave }
            className="submitButton"
          >
            Save Changes
          </button>
        </div>
      }
    </React.Fragment>
  );
};

export default AccessAndCredentials;