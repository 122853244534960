/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import InsightTable from '../../Table';

import { filterSet } from './data';

import {
  isNotEmpty,
  decodeURLHash,
  encodeURLHash,
  removeFromURLHash,
  paramsToFilters,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue.js';
import { makeRequest } from '../../../../../legacy/io.js';
import { getRowNums, pageIterator } from '../../../../shared/Pagination/IndeterminantPagination';
import PageHeader from '../../../../shared/PageHeader';
import RecordDetails from '../../../RecordDetails/index.js';
import ReportHeader from '../../ReportHeader/index.js';

const Vulnerabilities = ( ) => {
  const [ selectedRecord, setSelectedRecord ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( true );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  const [ appliedFilters, setAppliedFilters ] = React.useState( [] );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualCollapsed, setVisualCollapsed ] = React.useState( false );

  const isMounted = true;

  const fetchExistingRecord = async( recordID ) => {
    const tagIDs = decodeURLHash()['asset_tag_ids'];
    /* eslint-disable camelcase */
    const _clonedFilterSet = { ...filterSet };

    _clonedFilterSet.filters.id_list = [ recordID ];
    // eslint-disable-next-line camelcase
    _clonedFilterSet.filters.extra_columns = [
      ..._clonedFilterSet.filters.extra_columns,
      'domain_user_analysis.hosts',
      'domain_user_analysis.nodes',
    ];
    if ( isNotEmpty( tagIDs ) ) {
      _clonedFilterSet.asset_tag_ids = tagIDs;
    }

    /* eslint-enable camelcase */
    const response = await makeRequest( 'SEARCH', '/model/domain_user', _clonedFilterSet );

    if ( response && response['results'] ) {
      delete _clonedFilterSet['filters']['id_list'];
      return response.results[0];
    }
    /* eslint-enable camelcase */
  };

  const selectRecord = async ( recordID ) => {
    if ( isNotEmpty( recordID ) ) {

      const fetchedRecord = await fetchExistingRecord( recordID );

      if ( isNotEmpty( fetchedRecord ) ) {
        setSelectedRecord( fetchedRecord );
        encodeURLHash( { item: recordID } );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'The record you are looking for is no longer part of the risk model',
        } );
        setSelectedRecord( {} );
        removeFromURLHash( 'item' );
        onRefresh();
      }
    } else {
      setSelectedRecord( {} );
      removeFromURLHash( 'item' );
      onRefresh();
    }
  };

  const onRefresh = async( adjustedFilters=[] ) => {

    const onCorrectPage = decodeURLHash()['report'] === 'users';

    if ( onCorrectPage ) {
      setLoading( true );

      // if the filter that was just changed is not the current page, set it back to page 1
      if ( isNotEmpty( adjustedFilters ) && !adjustedFilters.some( attr => attr === 'current_page ' ) ) {
        // eslint-disable-next-line camelcase
        encodeURLHash( { current_page: 1 } );
      }

      const filterValues = paramsToFilters();

      /* eslint-disable camelcase */
      const params = {
        model: 'base',
        project: 'default',
        filters: {
          field_map: { type: 'domain_user' },
          extra_columns: [
            'name',
            'node_id',
            'domain_user_analysis.domain_name',
            'domain_user_analysis.risk',
            'domain_user_analysis.active_hosts',
            'domain_user_analysis.domain_groups',
            'sid',
          ],
          order_by: [
            [ 'domain_user_analysis.risk', 'DESC' ],
            [ 'name', 'ASC' ],
          ],
        },
        rownums: [ 0, 100 ],
      };
      /* eslint-enable camelcase */

      const _rowNums = getRowNums( filterValues );

      if ( isNotEmpty( filterValues ) ) {
        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( key === 'keywords' ) {
            params.filters.keywords = val;
          }
          if ( key === 'item_count' ) {
            params.rownums = _rowNums;
          }
        } );
      }

      const _sortBy = filterValues.sort_by || [ 'domain_user_analysis.risk' ];
      const _sortDirection = filterValues.sort_direction || 'DESC';

      if (
        isNotEmpty( _sortBy )
        && isNotEmpty( _sortDirection )
        && Array.isArray( _sortBy )
      ) {
        _sortBy.map( s => {
          params.filters.order_by.push( [ s === 'risk' ? 'domain_user_analysis.risk' : s, _sortDirection ] );
          params.filters.order_by.push( [ 'name', 'ASC' ] );
        } );
      } else if ( _sortBy === 'risk' ){
        // eslint-disable-next-line camelcase
        params.filters.order_by = [ [ 'domain_user_analysis.risk', _sortDirection ] ];
        params.filters.order_by.push( [ 'name', 'ASC' ] );
      } else if ( _sortBy === 'name' ){
        // eslint-disable-next-line camelcase
        params.order_by = [ [ 'name', _sortDirection ] ];
        params.order_by.push( [ 'domain_user_analysis.risk', 'DESC' ] );
      }

      let recordList = [];
      let pagedResults = [];

      // on page load, need to first see if we are on an item detail page and load that item instead of all the items
      if ( isNotEmpty( filterValues.item ) ) {
        if ( isMounted ) {
          selectRecord( filterValues.item );
        }
      } else {
        const response = await makeRequest( 'SEARCH', '/model/domain_user', params );
        if ( response && response['results'] ) {
          recordList = response.results;
          pagedResults = pageIterator( recordList, filterValues );
        }
      }
      if ( isMounted ) {
        setCurrentPageNumber(
          pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1,
        );
        setCurrentPageResults( pagedResults.firstPage );
        setNextPageResults( pagedResults.secondPage );
        setLoading( false );
      }
    }
  };

  React.useEffect( () => {
    onRefresh();
  }, [] );

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedRecord )
          ? <RecordDetails
            record={selectedRecord}
            recordType="user"
            riskType="risk"
            selectRecord={selectRecord}
            options={ {
              isCollapsible: true,
              renderContext: 'risk_insight',
              // eslint-disable-next-line camelcase
              include_details: true,
              // eslint-disable-next-line camelcase
              include_statistics: true,
            } }
          />
          : <React.Fragment>
            <PageHeader>
              <ReportHeader
                reportType="user"
                onRefresh={onRefresh}
                visualCollapsed={visualCollapsed}
                setVisualCollapsed={setVisualCollapsed}
                currentPageNumber={currentPageNumber}
                nextPageResults={nextPageResults}
                selectRecord={selectRecord}
                records={currentPageResults}
                loading={loading}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
              />
            </PageHeader>
            <div className="riskInsightTableAndVisualWrapper">
              <InsightTable
                records={currentPageResults}
                nextRecords={nextPageResults}
                useLegacyPagination
                currentPageNumber={currentPageNumber}
                loading={loading}
                reportType="user"
                onRefresh={onRefresh}
                refreshTable={onRefresh}
                visualCollapsed={visualCollapsed}
                selectedRecord={selectedRecord}
                selectRecord={selectRecord}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
              />
            </div>
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Vulnerabilities;