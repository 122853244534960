/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  formatNumber,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  reportTypeDisplayName,
  // shortenedVulnerabilityScannerNameMap,
  vulnerabilityScannerInstanceName,
  vulnerabilityScannerLogo,
  vulnerabilityScannerNameMap,
} from '../../../shared/Utilities';
// import InlineSVG from '../../../shared/InlineSVG';
// import ScanningStatusIndicator from '../ScanningStatusIndicator';
import ScannerSignatureContent from './SignatureContent';
import InlineSVG from '../../../shared/InlineSVG';

import './ThirdPartyScanning.scss';
import NoThirdPartyEmptyState from './NoThirdPartyEmptyState';
import { makeRequest } from '../../../../legacy/io';

const ThirdPartyScanning = ( {
  record,
  recordType,
  // riskType,
  // recordInstanceData,
  currentScannerSignature,
  // setCurrentScannerSignature,
  thirdPartyData,
  // setThirdPartyData,
  // latestThirdParty,
  getScannerSignatureInfoAndSetAsCurrent,
  thirdPartySettings,
} ) => {

  const [ scannerSignatureOptions, setScannerSignatureOptions ] = React.useState( null );
  const [ uniqueScanners, setUniqueScanners ] = React.useState( null );
  const [ uniqueIPs, setUniqueIPs ] = React.useState( [ ] );
  const [ uniqueIdentifiers, setUniqueIdentifiers ] = React.useState( [ ] );
  const [ uniqueHostNames, setUniqueHostNames ] = React.useState( [ ] );
  const [ showScannerSignatureOptions, setShowScannerSignatureOptions ] = React.useState( false );

  const handleScannerSignatureSelect = async ( thirdPartyID ) => {
    const selectedThirdParty = thirdPartyData[thirdPartyID];
    if ( isNotEmpty( selectedThirdParty ) ) {
      await getScannerSignatureInfoAndSetAsCurrent( selectedThirdParty );
      setShowScannerSignatureOptions( false );
    }
  };

  const setupThirdPartyHostInfo = async ( host ) => {
    const _uniqueScanners = [];
    const _uniqueIPs = [];
    const _uniqueIdentifiers = [];
    const _uniqueHostNames = [];
    const _ids = [];

    if ( isNotEmpty( host ) && isNotEmpty( host.third_party_hosts ) ) {
      const thirdPartyHostsResponse = await makeRequest( 'POST', 'fe/insights/third_party_host/SELECT', {
        columns: [ 'host_names', 'ip_addresses', 'third_party_identifiers', 'third_party_setting_id' ],
        filters: {
          // eslint-disable-next-line camelcase
          field_in_map: {
            id: host.third_party_hosts,
          },
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'id', 'ASC' ],
        ],
        rows: [ 0, 100 ],
      } );

      if ( isNotEmpty( thirdPartyHostsResponse ) && itemIsArray( thirdPartyHostsResponse ) ) {
        thirdPartyHostsResponse.map( tph => {
          let tps = {};

          if ( isNotEmpty( thirdPartySettings ) ) {
            tps = thirdPartySettings[tph.third_party_setting_id];
          }

          if ( isNotEmpty( tps ) && !_ids.includes( tps.id ) ) {
            _ids.push( tps.id );
            _uniqueScanners.push( {
              icon: vulnerabilityScannerLogo( tps.tool ),
              // eslint-disable-next-line max-len
              label: <span> { vulnerabilityScannerNameMap[tps.tool] } <strong>({ vulnerabilityScannerInstanceName( tps ) })</strong></span>,
              id: tps.id,
            } );
          }

          if ( isNotEmpty( tph.ip_addresses ) ) {
            tph.ip_addresses.map( ip => {
              if ( !_uniqueIPs.includes( ip ) ) {
                _uniqueIPs.push( ip );
              }
            } );
          }

          if ( isNotEmpty( tph.third_party_identifiers ) ) {
            tph.third_party_identifiers.map( id => {
              if ( !_uniqueIdentifiers.includes( id ) ) {
                _uniqueIdentifiers.push( id );
              }
            } );
          }

          if ( isNotEmpty( tph.host_names ) ) {
            tph.host_names.map( hn => {
              if ( !_uniqueHostNames.includes( hn ) ) {
                _uniqueHostNames.push( hn );
              }
            } );
          }
        } );
      }

      setUniqueIPs( _uniqueIPs );
      setUniqueIdentifiers( _uniqueIdentifiers );
      setUniqueHostNames( _uniqueHostNames );
      setUniqueScanners( _uniqueScanners );
    }
  };

  React.useEffect( () => {
    const keys = [];

    if (
      isNotEmpty( record )
      && isNotEmpty( recordType )
      && isNotEmpty( thirdPartyData )
    ) {
      const options = {};
      if ( isNotEmpty( thirdPartyData ) ) {
        Object.entries( thirdPartyData ).map( ( [ signatureID, t ] ) => {
          options[signatureID] = {
            icon: vulnerabilityScannerLogo( t.scannerKey ),
            label: <span><strong>{ t.label }</strong> {t.signature}</span>,
            timestamp: t.timestamp,
          };

          if ( !keys.includes( t.scannerKey ) ) {
            keys.push( t.scannerKey );
          }
        } );
      }
      if ( isNotEmpty( options ) ) {
        setScannerSignatureOptions( options );
      }
    }

    // if this is a host record, we do LOTS more things, if not, we just list the scanners that "maybe" scanned this
    // box
    if (
      isNotEmpty( record )
      && recordType === 'host'
      && isNotEmpty( record.third_party_hosts )
    ) {
      setupThirdPartyHostInfo( record );
    } else if ( isNotEmpty( keys ) ) {
      const _unique = [];

      keys.map( k => {
        _unique.push( {
          icon: vulnerabilityScannerLogo( k ),
          label: vulnerabilityScannerNameMap[k],
        } );
      } );

      if ( isNotEmpty( _unique ) ) {
        setUniqueScanners( _unique );
      }
    }
  }, [ thirdPartyData, record, recordType, thirdPartySettings ] );

  return (
    <React.Fragment>
      <div className={ `recordDetails__MainPanel__Body_ThirdPartyScanning ${recordType}` }>
        <div className="recordDetails__MainPanel_BodyRow scanningStatusSummary">
          <h3 className="mainPanel__SectionHeader">Third Party Vulnerability Source Status and Data</h3>
          <p className="mainPanel__SectionDescription" >
            {/* eslint-disable-next-line max-len */}
            The following information is the raw data that { window.COMPANY_NAME } received from your vulnerability source(s) prior to de-duplication.
          </p>
          {
            recordType === 'host' &&
            <React.Fragment>
              <div className="bodySectionItem">
                <label>
                  <span>Associated Host Names</span>
                </label>
                {
                  isNotEmpty( uniqueHostNames )
                    ? <pre>{ uniqueHostNames.join( ', ' ) }</pre>
                    : <span>N/A</span>
                }
              </div>
              <div className="bodySectionItem">
                <label>
                  <span>Associated Identifiers</span>
                </label>
                {
                  isNotEmpty( uniqueIdentifiers )
                    ? <pre>{ uniqueIdentifiers.join( ', ' ) }</pre>
                    : <span>N/A</span>
                }
              </div>
              <div className="bodySectionItem">
                <label>
                  <span>Associated IP Addresses</span>
                </label>
                {
                  isNotEmpty( uniqueIPs )
                    ? <pre>{ uniqueIPs.join( ', ' ) }</pre>
                    : <span>N/A</span>
                }
              </div>
            </React.Fragment>
          }
          <div className="bodySectionItem">
            <label>
              <span>Coverage</span>
            </label>
            {
              isNotEmpty( uniqueScanners ) &&
              <span className={ `bodySectionDescription ${window.IS_DARK_MODE ? 'darkMode' : ''}` }>
                {/* eslint-disable-next-line max-len*/}
                <strong>{ reportTypeDisplayName( record, recordType ) }</strong> is related to data imported from the following <strong>{ uniqueScanners.length } vulnerability source(s)</strong>.
              </span>
            }
            {
              isNotEmpty( uniqueScanners )
                ? <ul>
                  {
                    uniqueScanners.map( ( s, i ) => {
                      return <li key={i} className="uniqueScanner">
                        <div className="logoAndLabel">
                          { s.icon }
                          <span>{ s.label }</span>
                        </div>
                        {
                          isNotEmpty( s.id ) &&
                          <a
                            target="_blank"
                            href={ `#.=scanning&page=vulnerability_scanners&selected_record=${s.id}` }
                            className="roundGlyphButton light"
                          >
                            <InlineSVG type="setup" />
                          </a>
                        }
                      </li>;
                    } )
                  }
                </ul>
                : <span>N/A</span>
            }

          </div>
          {
            isEmpty( thirdPartyData ) &&
            <NoThirdPartyEmptyState hasThirdPartyHosts={ isNotEmpty( record.third_party_hosts ) }/>
          }
          {/* {
            ( isNotEmpty( thirdPartyData ) && isNotEmpty( latestThirdParty ) && recordType === 'host' ) &&
            <div className="bodySectionItem">
              <label>
                <span>Latest status</span>
                <span>
                  { shortenedVulnerabilityScannerNameMap[latestThirdParty.scannerKey] || 'Third Party' }
                </span>
              </label>
              <ScanningStatusIndicator timestamp={ latestThirdParty.timestamp } version="thirdParty" preformatted />
            </div>
          } */}


          {/* need a big number count or something of related signatures, don't want that to get lost */}

        </div>
        {/* we do not yet have the capability to link scanners to config alerts */}
        {/* <div className="recordDetails__MainPanel_BodyRow relatedConfigAlerts">
        </div> */}
        {
          isNotEmpty( currentScannerSignature ) &&
          <div className="recordDetails__MainPanel_BodyRow currentSignatureDetails">
            <h3 className="mainPanel__SectionHeader">
              <span>Vulnerability Source Output</span>
              {
                ( isNotEmpty( scannerSignatureOptions ) && isNotEmpty( currentScannerSignature ) ) &&
                <div className={ `scannerSignatureSelector ${ showScannerSignatureOptions ? 'open' : ''}` }>
                  <div
                    onClick={ () => setShowScannerSignatureOptions( !showScannerSignatureOptions ) }
                    className={ `scannerSignatureSelectorTrigger ${ showScannerSignatureOptions ? 'open' : ''}`}
                  >
                    {/* <span className="scannerSignatureLogoNameAndTimestamp">
                      <span className="label">
                        { vulnerabilityScannerLogo( currentScannerSignature.record?.scannerKey ) }
                        { currentScannerSignature.record?.label } {currentScannerSignature.record?.signature}
                      </span>
                      <span className="timestamp">{ currentScannerSignature.record?.timestamp }</span>
                    </span> */}
                    <span>
                      {/* eslint-disable-next-line max-len */}
                      View a different scan signature ({ formatNumber( Object.values( scannerSignatureOptions ).length ) })
                    </span>
                    <InlineSVG type="carretDown" />
                  </div>
                  {
                    showScannerSignatureOptions &&
                    <React.Fragment>
                      <div
                        onClick={ () => setShowScannerSignatureOptions( false ) }
                        className={ `scannerSignatureSelectorShade ${ showScannerSignatureOptions ? 'open' : ''}`}
                      />
                      <ul className="scannerSignatureSelectorOptions">
                        {
                          Object.entries( scannerSignatureOptions ).map( ( [ key, value ], index ) => {
                            return <li
                              className="scannerSignatureSelectorOption"
                              key={index}
                              onClick={ () => handleScannerSignatureSelect( key ) }
                            >
                              <span className="label">
                                { value.icon }
                                { value.label }
                              </span>
                              <span className="timestamp">{ value.timestamp }</span>
                            </li>;
                          } )
                        }
                      </ul>
                    </React.Fragment>
                  }
                </div>
              }
            </h3>
            <ScannerSignatureContent scannerSignature={currentScannerSignature } />
          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default ThirdPartyScanning;