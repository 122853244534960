/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../../../shared/InlineSVG';
import { isNotEmpty } from '../../../shared/Utilities';

const Trigger = ( {
  asMinimalButton,
  withCount,
  items,
  triggerRef,
  show,
  setShow,
  totalCount,
  buttonText,
  elementClass,
} ) => {

  return (
    <div
      // eslint-disable-next-line max-len
      className={ `${elementClass} ${asMinimalButton ? 'asMinimalButton' : '' } addToPlanMenuTriggerWrapper ${( isNotEmpty( items ) && withCount ) ? 'asButtonWithCount' : ''} ${window.IS_DARK_MODE ? 'darkMode' : ''}` }
      onClick={ () => setShow( !show ) }
      ref={triggerRef}
    >
      {
        isNotEmpty( items )
          ? <button
            className={ `${elementClass}  addToPlanMenuTrigger` }
            onClick={ () => setShow( !show ) }
          >
            {
              asMinimalButton
                ? <InlineSVG type="remediationAdd" size="large" />
                : <InlineSVG type="remediation" version="primary" />
            }
            {
              !asMinimalButton &&
              <React.Fragment>
                <span>{ buttonText }</span>
                {
                  ( totalCount > 0 && withCount ) &&
                  <span className="notificationCount">{ Object.keys( items ).length }</span>
                }

              </React.Fragment>
            }

          </button>
          : <React.Fragment>
            <InlineSVG type="remediationCart" />
            {
              ( totalCount > 0 && withCount ) &&
              <span className="notificationCount">{ totalCount }</span>
            }

          </React.Fragment>
      }
    </div>
  );
};

export default Trigger;