/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/

// About page for the application:
// - Displays information about the application
// - Displays the EULA
// - Displays the Open Source Licenses
// - Allows the user to download support information
// - Displays the license usage information

import React from 'react';

import Modal from '../../shared/Modal';
import Notification from '../../shared/Notification';

import InlineSVG from '../../shared/InlineSVG';

import {
  isNotEmpty,
  isSafari,
  isFirefox,
  formatUnixDate,
  getGlobalSettings,
} from '../../shared/Utilities';

import './style.scss';

import { makeRequest } from '../../../legacy/io';
import PageCreateButton from '../../shared/PageCreateButton';
import ConfigurationAlert, { getConfigurationAlertsFor } from '../../shared/ConfigurationAlert';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import { hasFeatureAccess } from '../App/AccessControl';

const About = () => {

  const [ aboutInfo, setAboutInfo ] = React.useState( {} );
  const [ licenseEdition, setLicenseEdition ] = React.useState( null );
  const [ eula, setEula ] = React.useState( '' );
  const [ licenseInfo, setLicenseInfo ] = React.useState( {} );
  const [ currentModalBody, setCurrentModalBody ] = React.useState( '' );
  const [ currentModalBodyHeader, setCurrentModalHeader ] = React.useState( '' );
  const [ showModal, setShowModal ] = React.useState( false );
  const [ generatingSupport, setGeneratingSupport ] = React.useState( false );
  const [ hasDiskAlert, setHasDiskAlert ] = React.useState( false );

  const [ currentUser ] = React.useContext( CurrentUserContext );

  const supportLink = React.useRef( null );

  React.useEffect( () => {
    makeRequest( 'FETCH', '/about', {} ).then( response => {
      setAboutInfo( response.results );
    } );

    getGlobalSettings( 'global' ).then( globalSettings => {
      console.log( globalSettings );
      setLicenseEdition( globalSettings );
    } );

    makeRequest( 'GET', '/eula.txt' ).then( response => {
      setEula( response );
    } );

    makeRequest( 'FETCH', '/license_info' ).then( response => {
      setLicenseInfo( response );
    } );

    if ( isNotEmpty( currentUser ) && hasFeatureAccess( currentUser, licenseInfo, 'f_configuration_alerts' ) ) {
      getConfigurationAlertsFor( 'health-check' ).then( response => {
        if ( isNotEmpty( response ) ) {
          setHasDiskAlert( true );
        }
      } );
    }
  }, [ currentUser, licenseInfo ] );

  const EulaContent = ( { eula } ) => {
    return (
      <React.Fragment>
        <p>Please carefully review the following agreement:</p>
        <pre className="eulaContent">{ eula }</pre>
      </React.Fragment>

    );
  };

  const OpenSourceLicensesContent = ( { info } ) => {
    return (
      <div className="openSourceContent">
        {
          ( info && info.licenses ) &&

          Object.keys( info.licenses ).map( ( license, index ) => {
            return  <div
              key={index}
            >
              <h2>
                {
                  // eslint-disable-next-line
                  `This software may contain portions of the ${license} project, which is released under the following license:`
                }
              </h2>
              <pre>
                { info.licenses[license] }
              </pre>
            </div>;
          } )

        }
      </div>
    );
  };

  const modalContentMap = {
    eula: {
      header: <span>{ window.COMPANY_NAME } <sup> { '\u00AE' } </sup> End-User License Agreement</span>,
      body: <EulaContent eula={eula} />,
    },
    license: {
      header: 'Open Source Licenses',
      body: <OpenSourceLicensesContent info={aboutInfo} />,
    },
  };

  const showModalFor = type => {
    setCurrentModalHeader( modalContentMap[type].header );
    setCurrentModalBody( modalContentMap[type].body );
    setShowModal( true );
  };

  const getBarPercentage = type => {
    const limit = parseInt( licenseInfo.license_element_limit );
    const elType = type === 'host' ? licenseInfo.observed_hosts : licenseInfo.observed_active_users;
    if ( limit >= 0 ) {
      return Math.round( ( elType / limit ) * 100 );
    }
    return 0;
  };

  const generateSupportInfo = e => {
    e.preventDefault();

    setGeneratingSupport( true );

    const sid = window.localStorage.getItem( 'sid' );
    const oReq = new XMLHttpRequest();
    oReq.open( 'GENERATE', '/support_file', true );
    oReq.setRequestHeader( 'Authorization', 'kanchil ' + sid );
    oReq.responseType = 'arraybuffer';

    oReq.onload = function( ) {
      const arrayBuffer = oReq.response;
      const blob = new Blob( [ arrayBuffer ], {type: 'application/zip'} );
      // eslint-disable-next-line
      const filename = 'logs-' + new Date().toLocaleString().replaceAll( '/', '_' ).replaceAll( ':', '_' ).replaceAll( ' ', '-' ).replaceAll( ',', '' ) + '.zip';

      const hiddenLinkEl = supportLink.current;

      hiddenLinkEl.setAttribute( 'href', window.URL.createObjectURL( blob ) );

      if( isFirefox || isSafari ){
        hiddenLinkEl.setAttribute( 'download', filename );
        hiddenLinkEl.removeAttribute( 'target' );
      }

      hiddenLinkEl.click();

      setGeneratingSupport( false );
    };
    oReq.send();
  };

  const licenseExpired = new Date() > new Date( parseFloat( licenseInfo.license_expiration ) * 1000 );
  const overLicense = licenseInfo.observed_elements > licenseInfo.license_element_limit;
  // eslint-disable-next-line
  const usagePercentage = parseInt( licenseInfo.license_element_limit ) === 0 ? 100 : Math.round( ( licenseInfo.observed_elements / parseInt( licenseInfo.license_element_limit ) ) * 100 );

  return (
    <div className="reactAboutPage" >
      <Modal
        elementClass="reactAboutPageModal"
        visible={showModal}
        setVisible={setShowModal}
        header={currentModalBodyHeader}
        body={currentModalBody}
      />
      <PageCreateButton>
        <div className="aboutHeaderActionsWrapper">
          <div
            className="modalTrigger"
            onClick={ () => showModalFor( 'eula' )}
          >
              End-User License Agreement
          </div>
          <div
            className="modalTrigger"
            onClick={ () => showModalFor( 'license' )}
          >
              Open Source Licenses
          </div>

          <button
            className={ `${ generatingSupport ? 'disabled' : '' } downloadButton` }
            disabled={ generatingSupport }
            onClick={ generateSupportInfo }
          >
            {
              generatingSupport
                ? <React.Fragment>
                  <InlineSVG type="cycle" version="light" />
                  <span>Preparing, please wait...</span>
                </React.Fragment>
                : <span>Download Support Info</span>
            }
          </button>
          <a
            ref={supportLink}
            className="hiddenLink"
            target="_blank"
          ></a>
        </div>

      </PageCreateButton>
      <section>
        {
          hasDiskAlert &&
          <ConfigurationAlert
            sourceType="health-check"
          />
        }
        <h2>
          DeepSurface <sup>{ '\u00AE' }</sup> is Copyright <span>{ '\u00A9' }</span> 2024
          DeepSurface Security, Inc. All Rights Reserved.
        </h2>
        <p>This software is protected by U.S. Pat. No. 12,015,631.</p>
        <p>DeepSurface is a registered trademark of DeepSurface Security, Inc.</p>
        <div className="infoSection">
          <label >{ window.COMPANY_NAME } <sup>{ '\u00AE' }</sup> Version</label>
          <code>{ aboutInfo.version }</code>
        </div>
        <div className="infoSection">
          <label >Installation ID</label>
          <code>{ aboutInfo.installation_id }</code>
        </div>
        <p>
          For more help with the { window.COMPANY_NAME } { window.PRODUCT_NAME }, please vist the <a
            href="#.=help_documentation"
            target="_blank"
            rel="noreferrer noopener"
            className={ `inlineLink newTabLink ${ window.IS_DARK_MODE ? 'darkMode' : '' }` }
          >
            Help Center
            <InlineSVG type="newTabLink" version="primary" />
          </a>
        </p>
      </section>
      <section>
        <h2>{ window.COMPANY_NAME } API</h2>
        <p>
          To generate API credentials for users in the system, edit or create an existing
          user
          <a
            href="#.=setup&page=users"
            className={ `inlineLink newTabLink ${ window.IS_DARK_MODE ? 'darkMode' : '' }` }>
              here
          </a>
          and click "Generate API Credentials".
        </p>
        <p>
          For more detailed instructions and documentation about the { window.COMPANY_NAME } API, visit the
          documentation
          <a href="/api/v1/docs" className={ `inlineLink newTabLink ${ window.IS_DARK_MODE ? 'darkMode' : '' }` }>
            here <InlineSVG type="newTabLink" version="primary" />
          </a>
        </p>
      </section>
      <section>
        <h2>License Usage</h2>
        {
          isNotEmpty( licenseInfo ) &&
          <div className="usageDataContainer" >
            {
              overLicense &&
              <Notification
                options={{
                  message: 'You are over your license element limit',
                  type: 'alert',
                }}
              />
            }
            {
              licenseExpired &&
              <Notification
                options={{
                  message: 'Your license has expired',
                  type: 'alert',
                }}
              />
            }
            <div className="infoSection">
              <label>License Holder</label>
              <strong>{licenseInfo.license_owner}</strong>
            </div>
            <div className="infoSection">
              <label>License Issued</label>
              <strong>
                { formatUnixDate( licenseInfo.license_issued ) }
              </strong>
            </div>
            {
              isNotEmpty( licenseEdition ) &&
              <React.Fragment>
                <div className="infoSection">
                  <label >Product Edition</label>
                  <strong>{ licenseEdition.license_effective_edition?.edition_label }</strong>
                </div>
                <div className="infoSection">
                  <label >Product Edition Expiration</label>
                  <strong>{ formatUnixDate( licenseEdition.license_expiration ) }</strong>
                </div>
                <div className="infoSection">
                  <label >Trial Edition</label>
                  <strong>{ licenseEdition.license_effective_edition?.trial_label }</strong>
                </div>
                <div className="infoSection">
                  <label >Trial Edition Expiration</label>
                  <strong>{ formatUnixDate( licenseEdition.license_trial_expiration ) }</strong>
                </div>
              </React.Fragment>
            }
          </div>
        }
        {
          isNotEmpty( licenseInfo ) &&
          <div className="usageVisual">
            <div className="legend">
              <div className="items">
                <div className="legendItem hosts">
                  <span className="color" />
                  <span className="label">
                    Hosts Count: <strong>{licenseInfo.observed_hosts}</strong>
                  </span>
                </div>
                <div className="legendItem users">
                  <span className="color" />
                  <span className="label">
                    Active Users: <strong>{licenseInfo.observed_active_users}</strong>
                  </span>
                </div>
              </div>
              <div className="otherInfo">
                <span className="label">
                  Total Elements: <strong>{licenseInfo.observed_elements}</strong>
                </span>
                <span className="label">
                  License Element Limit: <strong>{licenseInfo.license_element_limit}</strong>
                </span>
                <span className="label">
                  Percentage Used: <strong>{ `${usagePercentage}%` }</strong>
                </span>
              </div>
            </div>

            <div className={`progressWrapper ${overLicense ? 'over' : ''}`}>
              <div
                className="hostUsageBar"
                style={
                  { width: `${getBarPercentage( 'host' )}%`}
                }
              />
              <div
                className="userUsageBar"
                style={
                  { width: `${getBarPercentage( 'user' )}%`}
                }
              />
            </div>
          </div>
        }
      </section>
    </div>
  );
};

export default About;
