/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';
import RiskReduction from '../RiskReduction';
import RatingBadge from '../RatingBadge';

import './ReductionAndRating.scss';
import RiskExplanation from '.';

const ReductionAndRating = ( {
  riskClass,
  record,
  recordType,
  riskType,
  orientation='horizontal',
  includeExplanationLink=false,
} ) => {

  const [ showExplanation, setShowExplanation ] = React.useState( false );
  const [ explanationClick, setExplanationClick ] = React.useState( null );

  const handleExplanationClick = e => {
    setExplanationClick( e );
    setShowExplanation( true );
  };

  return (
    <React.Fragment>
      {
        includeExplanationLink &&
        <RiskExplanation
          record={record}
          recordType={ recordType }
          riskClass={ riskClass }
          riskType={riskType}
          clickEvent={ explanationClick }
          show={ showExplanation }
          setShow={ setShowExplanation }
        />
      }
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( riskType ) ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `recordRiskAndRatingWrapper riskClass--${riskClass} ${orientation} ${ window.IS_DARK_MODE ? 'darkMode' : '' }` }
        >
          {
            includeExplanationLink &&
            <button
              className={ `riskExplanationTriggerButton riskClass--${riskClass}`}
              onClick={ handleExplanationClick }
            >
              What is this?
            </button>
          }
          <div className="recordStatisticWrapper risk_reduction">
            <span className="statisticLabel">
              Risk Reduction
            </span>
            <span className="statisticValue">
              <RiskReduction item={record} itemType={recordType} riskType={riskType} />
            </span>
          </div>
          <div className="recordStatisticWrapper risk_rating">
            <span className="statisticLabel">
              Risk Rating
            </span>
            <span className="statisticValue">
              <RatingBadge
                altVersion
                item={record}
                itemType={recordType}
                riskType={riskType}
                rating={riskClass || 'unknown'}
              />
            </span>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default ReductionAndRating;