/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { HelpTrigger } from '../../components/HelpDocumentation/ContextualHelp';

import { isNotEmpty } from '../Utilities';
// import InlineSVG from '../InlineSVG';

export const DEFAULT_EXPIRATION = new Date().setMonth( new Date().getMonth() + 1 ) / 1000;
export const DOES_NOT_EXPIRE = 30000000000;

export const EMPTY_FIELDS = [
  {
    type: 'text',
    label: 'Name',
    defaultValue: '',
    attribute: 'label',
    help: <HelpTrigger helpKey="name_report_creator" />,
  },
  {
    type: 'select2',
    label: 'Owner',
    attribute: 'owner',
    defaultValue: '',
    help: <HelpTrigger helpKey="owner" />,
  },
  {
    type: 'radioGroup',
    label: 'Format',
    attribute: 'format',
    required: true,
    options: { xlsx: '.xlsx', pdf: '.pdf' },
    defaultValue: 'xlsx',
    help: <HelpTrigger helpKey="format" />,
  },
  {
    type: 'checkbox',
    label: 'Include All Results',
    attribute: 'full_xlsx_results',
    includeIf: { attribute: 'format', value: 'xlsx' },
    defaultValue: true,
    help: <HelpTrigger helpKey="include_all_results" />,
  },
  {
    type: 'schedule',
    label: 'Schedule',
    attribute: 'schedule',
    defaultValue: { enabled: false, recurring: [] },
    help: <HelpTrigger helpKey="schedule" />,
  },

  {
    type: 'date',
    label: 'Expires',
    attribute: 'expiration',
    includeIf: { attribute: 'no_expiration', value: false },
    defaultValue: DEFAULT_EXPIRATION,
    needsUnixTransform: true,
    required: true,
    help: <HelpTrigger helpKey="expires" />,
  },
  // decided to go with the much more flexible custom multi field instead of this purpose-built one, may change back
  // but going to leave this comment as a reminder -DMC 2023-04-28
  // {
  //   type: 'recipients',
  //   label: 'Email Recipients',
  //   attribute: 'email_recipients',
  //   defaultValue: [],
  //   help: <HelpTrigger helpKey="email_recipients" />,
  // },
  {
    type: 'select2',
    label: 'Email Recipients',
    attribute: 'email_recipients',
    selectOptions: { multiple: true },
    defaultValue: [],
    allowBlank: true,
    helpItem: <HelpTrigger helpKey="email_recipients" />,
  },
  {
    type: 'checkbox',
    label: 'Does not expire',
    attribute: 'no_expiration',
    defaultValue: true,
  },
];

export const reportTypes = {
  hosts: 'host',
  patches: 'patch',
  vulnerabilities: 'vulnerability',
  instances: 'instance',
  users: 'user',
  // eslint-disable-next-line camelcase
  reporting_dashboard: 'dashboard',
  // eslint-disable-next-line camelcase
  configuration_alerts: 'configuration_alerts',
};

// different adjustsments for different record types
export const adjustedExportedReportValues = ( record, fieldStates ) => {

  const _adjustedStates = { ...fieldStates };

  Object.entries( _adjustedStates ).map( ( [ attr, state ] ) => {
    if ( attr === 'expiration' ) {

      const noExpirationState = _adjustedStates['no_expiration'];

      if ( isNotEmpty( record ) ) {
        if ( record.expiration === DOES_NOT_EXPIRE ) {
          noExpirationState.originalValue = true;
          noExpirationState.updatedValue = true;
          state.originalValue = DEFAULT_EXPIRATION;
          state.updatedValue = DEFAULT_EXPIRATION;
        } else {
          noExpirationState.originalValue = false;
          noExpirationState.updatedValue = false;
          state.originalValue = record.expiration || '';
          state.updatedValue = record.expiration || '';
        }
      }
    }
    // if (attr === 'format') {
    //   state.disabled = true;
    // };
  } );
  return _adjustedStates;
};