/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, getDimensionsAndOffset, isNotEmpty, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';

import './EdgeContent.scss';
import RiskReduction from '../../../../shared/RiskReduction';
import EmptyLoading from '../../../../shared/EmptyLoading';
import { getEscalations } from '../shared';
import RecordList from '../../../../shared/RecordList';
import CombinedVulnerabilitySignatureContent from './CombinedVulnerabilitySignatureContent';

const EdgeContent = ( {
  edge,
  options,
  containerRef,
  context,
  setStyle,
  additionalContent,
  setAdditionalContent,
  selectedEdgeVulnerability,
  setSelectedEdgeVulnerability,
  show,
} ) => {
  const [ loadingVulnerabilities, setLoadingVulnerabilities ] = React.useState( false );
  const [ vulnerabilities, setVulnerabilities ] = React.useState( null );

  const [ showEdgeVulnerabilities, setShowEdgeVulnerabilities ] = React.useState( true );

  const [ fromNodeLabel, setFromNodeLabel ] = React.useState( null );
  const [ toNodeLabel, setToNodeLabel ] = React.useState( null );

  const [ showOnPaths, setShowOnPaths ] = React.useState( true );

  const [ onPaths, setOnPaths ] = React.useState( null );

  const [ edgeCount, setEdgeCount ] = React.useState( 1 );

  React.useEffect( () => {
    if ( isNotEmpty( edge ) ) {

      if (
        isNotEmpty( edge.pathIDs )
        && isNotEmpty( options )
        && isNotEmpty( options.relatedPaths )
        && isNotEmpty( options.relatedPaths.results )
        && isNotEmpty( options.relatedPaths.results.paths )
      ) {
        const _onPaths = edge.pathIDs.map( id => options.relatedPaths.results.paths.find( p => p.id === id ) );

        if ( isNotEmpty( _onPaths ) ) {
          setOnPaths( _onPaths );
        }
      }

      if ( isNotEmpty( edge.includedEdges ) ) {
        const [ firstEdge ] = edge.includedEdges;

        const lastEdge = edge.includedEdges[ edge.includedEdges.length - 1 ];

        setFromNodeLabel( firstEdge?.fromNodeFull?.label );
        setToNodeLabel( lastEdge?.toNodeFull?.label );

        setEdgeCount( edge.includedEdges.length );

      } else if ( isNotEmpty( edge.fromNode ) && isNotEmpty( edge.toNode ) ) {
        setFromNodeLabel( edge.fromNode?.label );
        setToNodeLabel( edge.toNode?.label );
      } else {
        setFromNodeLabel( 'N/A' );
        setToNodeLabel( 'N/A' );
      }
    } else {
      setFromNodeLabel( null );
      setToNodeLabel( null );
      setVulnerabilities( null );
    }
  }, [ edge, options ] );

  React.useEffect( () => {
    if ( show === false ) {
      setFromNodeLabel( null );
      setToNodeLabel( null );
      setVulnerabilities( null );
      setSelectedEdgeVulnerability( null );
    }
  }, [ show ] );

  const toggleSelectedEdgeVulnerability = vuln => {
    if ( isNotEmpty( vuln ) ) {
      if ( isNotEmpty( selectedEdgeVulnerability ) && selectedEdgeVulnerability.id === vuln.id ) {
        setSelectedEdgeVulnerability( null );
        setAdditionalContent( null );
      } else {
        setSelectedEdgeVulnerability( vuln );
        setAdditionalContent(
          <CombinedVulnerabilitySignatureContent
            vulnerability={ vuln }
            edge={ edge }
            context={ context }
          />,
        );
      }
    } else {
      setSelectedEdgeVulnerability( null );
      setAdditionalContent( null );
    }
  };

  const setupContent = async () => {

    setVulnerabilities( null );

    let _edge = edge;

    setLoadingVulnerabilities( true );

    if ( isNotEmpty( edge.includedEdges ) ) {
      if ( edge.includedEdges.length > 1 ) {

        const promises = edge.includedEdges.map( getEscalations );

        const resolvedPromises = await Promise.all( promises );

        let allVulns = {};

        resolvedPromises.map( resolved => {
          const { vulnerabilities } = resolved;

          allVulns = { ...allVulns, ...vulnerabilities };
        } );

        const _vulnerabilities = Object.values( allVulns ).sort( ( a, b ) => b.risk - a.risk );

        setLoadingVulnerabilities( false );
        setVulnerabilities( _vulnerabilities );
      } else {
        [ _edge ] = edge.includedEdges;

        const escalations = await getEscalations( _edge );
        if ( isNotEmpty( escalations ) && isNotEmpty( escalations.vulnerabilities ) ) {
          const _vulnerabilities = Object.values( escalations.vulnerabilities ).sort( ( a, b ) => b.risk - a.risk );
          setLoadingVulnerabilities( false );
          setVulnerabilities( _vulnerabilities );
        } else {
          setLoadingVulnerabilities( false );
          setVulnerabilities( null );
        }
      }
    } else {
      const escalations = await getEscalations( _edge );
      if ( isNotEmpty( escalations ) && isNotEmpty( escalations.vulnerabilities ) ) {
        const _vulnerabilities = Object.values( escalations.vulnerabilities ).sort( ( a, b ) => b.risk - a.risk );
        setLoadingVulnerabilities( false );
        setVulnerabilities( _vulnerabilities );
      } else {
        setLoadingVulnerabilities( false );
        setVulnerabilities( null );
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( edge ) ) {
      setupContent();
    } else {
      setVulnerabilities( null );
      setSelectedEdgeVulnerability( null );
    }
  }, [ edge ] );

  // re-position after the content loads
  React.useEffect( () => {
    if (
      isNotEmpty( containerRef )
      && isNotEmpty( containerRef.current )
      && isNotEmpty( edge )
      && context !== 'explore'
    ) {
      let _clickEvent;
      if ( isNotEmpty( edge.clickEvent ) ) {
        _clickEvent = edge.clickEvent;
      } else if ( isNotEmpty( options?.clickEvent ) ) {
        _clickEvent = options.clickEvent;
      }

      if ( isNotEmpty( _clickEvent ) ) {

        const multiplier = isNotEmpty( additionalContent ) ? 64 : 32;
        const width = multiplier * 16;

        const reactContainer = document.getElementById( 'react_container' );
        const scrollOffset = isNotEmpty( reactContainer ) ? reactContainer.scrollTop : 0;

        let left = 'unset';
        let right = 16;
        let top = 16;
        let bottom = 'unset';

        setTimeout( () => {
          const dimensions = getDimensionsAndOffset( containerRef.current );

          if ( isNotEmpty( dimensions ) ) {
            const { height } = dimensions;

            const { pageX, pageY } = _clickEvent;

            left = pageX + 16;
            right = 'unset';
            top = pageY;
            bottom = 'unset';

            // it would flow over the right side of the screen, need to put on the left side instead
            if ( ( left + width ) > window.innerWidth ) {
              left = pageX - 16 - width;

              if ( left < 16 ) {
                left = 16;
              }
            }

            // it would be too low on the screen
            if ( ( top + height + scrollOffset ) > window.innerHeight ) {
              top = 'unset';
              bottom = 16;
            }

            setStyle( { top, bottom, left, right, width } );
          }
        }, 100 );
      }
    }
  }, [ vulnerabilities, edge, containerRef, options, context, additionalContent ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( edge ) &&
        <React.Fragment>
          {
            isNotEmpty( onPaths ) &&
            <div
              // eslint-disable-next-line max-len
              className={ `collapsibleSectionWrapper ${showOnPaths ? '' : 'collapsed' } edgeContentOnPathsContainer` }
            >
              <div
                className="collapsibleSectionHeader"
                onClick={ () => setShowOnPaths( !showOnPaths ) }
              >
                <div className="headerLeft">
                  <InlineSVG
                    elementClass="itemTypeIcon"
                    type="pathsAlt"
                  />
                  <h3>
                    Paths
                  </h3>
                </div>
                <div className="headerRight">
                  <strong className="sectionCount">
                    ({ onPaths.length })
                  </strong>
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp"/>
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                <ul>
                  {
                    onPaths.map( ( path, index ) => {
                      return <li
                        key={index}
                      >
                        <a
                          // eslint-disable-next-line max-len
                          href={ `#.=explore&page=explore_paths&item_count=100&order_by=risk&order_direction=DESC&item=${path.id}&current_page=1&included_table_columns=%5B"risk"%5D` }
                          target="_blank"
                          rel="noreferrer noopener"
                          className={ `onPathsLink risk-${riskToRating( path.risk ) }` }
                        >
                          {/* eslint-disable-next-line max-len */}
                          <span className="name" >Path to { path.node_labels[ path.node_labels.length - 1][1] } <strong>( { formatNumber( path.edges.length ) } Steps )</strong></span>
                          <div className="actions">
                            <RiskReduction item={path} riskType="risk" />
                            <InlineSVG type="newTabLink" />
                          </div>
                        </a>
                      </li>;
                    } )
                  }
                </ul>
              </div>
            </div>

          }
          {
            edgeCount === 1
              ? <p className="edgeViewerDescription">
                The following vulnerabilities or architectural elements describe how a digital intruder
                with <strong>"{ fromNodeLabel }"</strong> access would be able to escalate
                privileges to <strong>"{ toNodeLabel }."</strong>
              </p>
              : <p className="edgeViewerDescription">
                This "segment" is a combination of <strong>{ edgeCount } segements.</strong> Because the
                vulnerabilities or architectual elements in these segments are similar enough, they were
                combined for simplicity. The following vulnerabilities represent how a digital intruder
                with <strong>{ fromNodeLabel }"</strong> access would be able to escalate
                privileges all the way to <strong>"{ toNodeLabel }"</strong> at the end of the combined
                segments.
              </p>
          }
          <div className="edgeContentvulnerabilitiesSectionWrapper">
            <EmptyLoading
              loading={ loadingVulnerabilities }
              payload={ vulnerabilities }
              emptyMessage="This segment does not have any vulnerabilities"
            />
            {
              isNotEmpty( vulnerabilities ) &&
              <div
                // eslint-disable-next-line max-len
                className={ `collapsibleSectionWrapper ${showEdgeVulnerabilities ? '' : 'collapsed' }` }
              >
                <div
                  className="collapsibleSectionHeader"
                  onClick={ () => setShowEdgeVulnerabilities( !showEdgeVulnerabilities ) }
                >
                  <div className="headerLeft">
                    <InlineSVG
                      elementClass="itemTypeIcon"
                      type="vulnerabilitiesAlt"
                    />
                    <h3>
                      Vulnerabilities
                    </h3>
                  </div>
                  <div className="headerRight">
                    <strong className="sectionCount">
                      ({ Object.values( vulnerabilities ).length })
                    </strong>
                    <span className="carretWrapper">
                      <InlineSVG type="carretUp"/>
                    </span>
                  </div>
                </div>
                <div className="collapsibleSectionBody">
                  <RecordList
                    records={ vulnerabilities }
                    reportType={ 'vulnerability' }
                    sectionKey="num_vulnerabilities"
                    orderBy="risk"
                    vulnerabilityOptions={ {
                      selectedEdgeVulnerability,
                      toggleSelectedEdgeVulnerability,
                    } }
                  />
                </div>
              </div>
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default EdgeContent;