/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import Legend from '../../../../../shared/Charts/Legend';

import './VulnerabilityAgeBreakdown.scss';
import Bar from '../../../../../shared/Charts/Bar';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import EmptyState from '../../../../../shared/EmptyState';
import { defaultVulnerabilityURL } from '../../../../App/Routing';

const VulnerabilityAgeBreakdown = ( { data, settings } ) => {

  const [ vulnerabilityAgeTotal, setVulnerabilityAgeTotal ] = React.useState( 1 );
  const [ vulnerabilityAgeData, setVulnerabilityAgeData ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );
  const [ selectedSeriesIdentifier, setSelectedSeriesIdentifier ] = React.useState( null );
  const [ yMax, setYMax ] = React.useState( 0 );

  const svgContainerRef = React.useRef( null );

  const palette = {
    '0-30d': '#334d6e',
    '30d-60d': '#485f7d',
    '60d-90d': '#5c718b',
    '90d-180d': '#71839a',
    '180d-1y': '#8594a8',
    '1y-3y': '#99a6b7',
    '3y-5y': '#adb8c5',
    '5y-10y': '#c2cad4',
    '10y-inf': '#d6dbe2',
  };

  const ageLabelMap = {
    '0-30d': '0 - 30 Days',
    '30d-60d': '30 - 60 Days',
    '60d-90d': '60 - 90 Days',
    '90d-180d': '90 - 180 Days',
    '180d-1y': '180 Days - 1 Year',
    '1y-3y': '1 - 3 Years',
    '3y-5y': '3 - 5 Years',
    '5y-10y': '5 - 10 Years',
    '10y-inf': 'More than 10 Years',
  };

  const ageKeyDaysAgoMap = {
    '0-30d': { start: 30, end: null },
    '30d-60d': { start: 60, end: 30 },
    '60d-90d': { start: 90, end: 60 },
    '90d-180d': { start: 180, end: 90 },
    '180d-1y': { start: 365, end: 180 },
    '1y-3y': { start: 365 * 3, end: 365 },
    '3y-5y': { start: 365 * 5, end: 365 * 3 },
    '5y-10y': { start: 365 * 10, end: 365 * 5 },
    '10y-inf': { start: null, end: 365 * 10 },
  };

  const setupData = data => {

    const _ageData = {};
    const total = data._total || 1;
    let _yMax = 0;
    if ( isNotEmpty( data.vulnerability_age ) ) {
      Object.entries( data.vulnerability_age ).map( ( [ ageKey, count ] ) => {
        _ageData[ageKey] = {
          original: { id: ageKey },
          label: ageLabelMap[ageKey],
          stroke: palette[ageKey],
          fill: palette[ageKey],
          value: ( count / total ) * 100,
          originalCount: count,
          total: count,
          key: ageKey,
        };

        if ( count > _yMax ) {
          _yMax = count;
        }
      } );
      setYMax( _yMax );
      setVulnerabilityAgeTotal( total );
      setVulnerabilityAgeData( _ageData );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      setupData( data.results );
    }
  }, [ data, settings ] );

  const handleAgeCategoryClickRedirect = ageKey => {
    // eslint-disable-next-line max-len
    let baseURLString = defaultVulnerabilityURL;

    const today = new Date();

    const day = 1_000 * 60 * 60 * 24;

    const daysAgo = ageKeyDaysAgoMap[ageKey];

    if ( isNotEmpty( daysAgo.start ) ) {
      const start = today - day * daysAgo.start;
      const startTS = new Date( start ).getTime() / 1_000;
      baseURLString += `&lt_map=%7B"vulnerability_created"%3A${startTS}%7D`;
    }
    if ( isNotEmpty( daysAgo.end ) ) {
      const end = today - day * daysAgo.end;
      const endTS = new Date( end ).getTime() / 1_000;
      baseURLString += `&gt_map=%7B"vulnerability_created"%3A${endTS}%7D`;
    }

    window.open( baseURLString );
  };

  const onBarClick = element => {
    if ( isNotEmpty( element ) ) {
      // eslint-disable-next-line
      const ageKey = element.id.split( '_' )[1];
      if ( isNotEmpty( ageKey ) ) {
        handleAgeCategoryClickRedirect( ageKey );
      }
    }
  };

  const onLegendClick = legendKey => {
    if ( isNotEmpty( legendKey ) ) {
      handleAgeCategoryClickRedirect( legendKey );
    }
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( vulnerabilityAgeTotal ) && isNotEmpty( vulnerabilityAgeData ) )
          ? <React.Fragment>
            <div className="cvssBarChartWrapper" ref={svgContainerRef} >
              <YAxisLabels yMax={ yMax } />
              <Bar
                data={vulnerabilityAgeData}
                hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                onClick={ onBarClick }
              />
              <div className="xLabel">
                <span>Newer</span>
                <span>Older</span>
              </div>
            </div>

            <Legend
              label="Vulnerability Age"
              legendData={ vulnerabilityAgeData }
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
              onClickCallback={ onLegendClick }
              selectedSeriesIdentifier={ selectedSeriesIdentifier }
              setSelectedSeriesIdentifier={ setSelectedSeriesIdentifier }
            />
          </React.Fragment>
          : <EmptyState message="No data available" />
      }
    </React.Fragment>
  );
};

export default VulnerabilityAgeBreakdown;