/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { getGradeClass, isNotEmpty, percentileToGrade } from '../../../../../shared/Utilities';
import EmptyLoading from '../../../../../shared/EmptyLoading';

import './RiskGrade.scss';
import { RiskContext } from '../../../../../Contexts/Risk';

const RiskGrade = ( { settings } ) => {

  const [ loading, setLoading ] = React.useState( false );

  const [ , , peerPercentile ] = React.useContext( RiskContext );

  const setupData = async () => {
    setLoading( true );
    setLoading( false );
  };

  React.useEffect( () => {
    setupData();
  }, [ settings, peerPercentile ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ peerPercentile }
        loading={ loading }
        loadingMessage="Loading data"
        emptyMessage="No risk data"
      />
      {
        ( isNotEmpty( peerPercentile ) && peerPercentile > 0 ) &&
        <div className={ `riskGradeWidgetWrapper risk--${getGradeClass( peerPercentile )}` }>
          <div className="riskGradeContentWrapper">
            <div className="scoreCircle">
              <strong className="gradeWrapper">{ percentileToGrade( peerPercentile ) }</strong>
            </div>
            <span className="chartLabel">Risk Grade</span>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default RiskGrade;