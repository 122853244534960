/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import WizardModal from './WizardModal.js';
// import WizardFooter from './WizardFooter.js';
import { OnboardingWizardContext } from '../../Contexts/OnboardingWizard';

import './style.scss';
import { getGlobalSettings, isNotEmpty } from '../../shared/Utilities/index.js';
import { makeRequest } from '../../../legacy/io.js';
import { hasFeatureAccess } from '../App/AccessControl.js';
import { CurrentUserContext } from '../../Contexts/CurrentUser.js';

const OnboardingWizard = ( ) => {
  const [ , , , , setShowOnboarding, , , ] = React.useContext( OnboardingWizardContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ global, setGlobal ] = React.useState( null );

  const onboardingRoot = document.getElementById( 'reactOnboardingPortal' );

  React.useEffect( async ( ) => {
    const _global = await getGlobalSettings( 'global' );
    if ( isNotEmpty( _global ) ) {
      setGlobal( _global );
    }
  }, [] );

  const disableOnboarding = () => {
    setShowOnboarding( false );
    // eslint-disable-next-line camelcase
    makeRequest( 'PUT', 'fe/profile/UPDATE', { setup_wizard_disabled: true } );
  };

  if ( !global?.hide_onboarding_wizard && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' ) ) {
    return ReactDOM.createPortal(
      <React.Fragment>
        <WizardModal disableOnboarding={disableOnboarding} />
      </React.Fragment>,
      onboardingRoot,
    );
  }
  return null;
};

export default OnboardingWizard;