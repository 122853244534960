/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { attackScenarioLabelMap, isNotEmpty } from '../../shared/Utilities';
import RecordStatistic from './RecordStatistic';
import ReductionAndRating from '../../shared/RiskExplanation/ReductionAndRating';
import { defaultInstanceVulnerabilityURL } from '../App/Routing';

const Statistics = ( {
  record,
  recordType,
  riskType='filtered_risk',
  recordInstanceData,
  includeRisk=false,
  fullPanelVersion=false,
  riskClass,
} ) => {

  const [ adjustedRiskType, setAdjustedRiskType ] = React.useState( null );
  const [ attackScenarioStats, setAttackScenarioStats ] = React.useState( null );

  const fullPanelStatisticsForRecordType = {
    host: [
      // 'users_at_risk',
      'vulnerabilities',
      'exploitable_vulns_at_risk_count',
      // 'sensitive_nodes',
      // 'unsuperseded_patches',
    ],
    patch: [
      'hosts',
      'vulnerabilities',
    ],
    vulnerability: [
      'hosts',
      'patches',
      'unsuperseded_patches',
      'exploit_status',
    ],
    user: [
      'active_hosts',
      'domain_groups',
      // 'sensitive_assets',
    ],
    path: [
      'steps',
      'num_vulnerabilities',
    ],
  };

  const statisticsForRecordType = {
    host: [
      'vulnerabilities',
      'patches',
      'sensitive_nodes',
      'unsuperseded_patches',
    ],
    patch: [
      'hosts',
      'vulnerabilities',
    ],
    vulnerability: [
      'hosts',
      'patches',
      'unsuperseded_patches',
      'exploit_status',
    ],
    user: [
      'active_hosts',
      'domain_groups',
      // 'sensitive_assets',
    ],
    path: [
      'steps',
      'num_vulnerabilities',
    ],
  };

  React.useEffect( () => {
    if (
      isNotEmpty( record )
      && isNotEmpty( recordType )
      && recordType === 'host'
      && isNotEmpty( record.vulnerability_type_counts )
    ) {
      const _attackScenarioStats = [];

      Object.keys( attackScenarioLabelMap ).map( key => {
        if ( key in record.vulnerability_type_counts ) {
          _attackScenarioStats.push( key );
        }
      } );
      setAttackScenarioStats( _attackScenarioStats );
    }

    if ( isNotEmpty( record ) && isNotEmpty( riskType ) ) {
      if ( 'filtered_risk' in record ) {
        setAdjustedRiskType( 'filtered_risk' );
      } else {
        setAdjustedRiskType( riskType );
      }
    } else {
      setAdjustedRiskType( 'filtered_risk' );
    }
  }, [ record, riskType ] );

  const getLinkForKey = ( key ) => {
    if ( isNotEmpty( key ) && fullPanelVersion ) {
      if ( key === 'vulnerabilities' ) {
        // eslint-disable-next-line max-len
        return `${defaultInstanceVulnerabilityURL}&${recordType}_ids=%5B"${record.id}"%5D`;
      }
      if ( key === 'exploitable_vulns_at_risk_count' ) {
        // eslint-disable-next-line max-len
        return `${defaultInstanceVulnerabilityURL}&${recordType}_ids=%5B"${record.id}"%5D&exploit_statuses=%5B"weaponized"%5D`;
      }
      if ( Object.keys( attackScenarioLabelMap ).includes( key ) ) {
        // eslint-disable-next-line max-len
        return `${defaultInstanceVulnerabilityURL}&attack_scenario=${key}&${recordType}_ids=%5B"${record.id}"%5D&filtered_risk=%5B"gt_map"%2C0%5D`;
      }
      return null;
    }
    return null;
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( statisticsForRecordType[recordType] ) ) &&
        <div className={ `recordDetails__InformationPanel__Body_Section statistics ${recordType}` }>
          {
            fullPanelVersion
              ? <React.Fragment>
                {
                  fullPanelStatisticsForRecordType[recordType].map( ( statisticsKey, index ) => {
                    return <RecordStatistic
                      key={index}
                      record={record}
                      statisticsKey={statisticsKey}
                      recordInstanceData={recordInstanceData}
                      riskType={adjustedRiskType}
                      recordType={recordType}
                      fullPanelVersion
                      linkHref={ isNotEmpty( getLinkForKey( statisticsKey ) ) ? getLinkForKey( statisticsKey ) : null }
                    />;
                  } )
                }
                {
                  isNotEmpty( attackScenarioStats ) &&
                  attackScenarioStats.map( ( scenarioKey, index ) => {
                    return <RecordStatistic
                      key={index}
                      record={record}
                      statisticsKey={scenarioKey}
                      recordInstanceData={recordInstanceData}
                      riskType={adjustedRiskType}
                      recordType={recordType}
                      fullPanelVersion
                      linkHref={ isNotEmpty( getLinkForKey( scenarioKey ) ) ? getLinkForKey( scenarioKey ) : null }
                    />;
                  } )
                }
              </React.Fragment>
              : statisticsForRecordType[recordType].map( ( statisticsKey, index ) => {
                return <RecordStatistic
                  key={index}
                  record={record}
                  statisticsKey={statisticsKey}
                  recordInstanceData={recordInstanceData}
                  riskType={adjustedRiskType}
                  recordType={recordType}
                />;
              } )
          }
          {
            includeRisk &&
            <ReductionAndRating
              record={record}
              recordType={recordType}
              riskClass={riskClass}
              riskType={adjustedRiskType}
              includeExplanationLink
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default Statistics;