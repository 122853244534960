/* **************************************************************
* Copyright (C) 2016-2025 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../legacy/io';
import Modal from '../../shared/Modal';

const ModalBody = ( { eula } ) => {
  return (
    <pre>{ eula.eula }</pre>
  );
};

const ModalAction = ( {
  successfulLoginRedirect,
  eula,
  // sid,
  // expiration,
  // setSid,
  // setExpiration,
} ) => {
  const acceptEULA = () => {

    makeRequest( 'PUT', '/fe/profile/UPDATE', {
      // eslint-disable-next-line camelcase
      eula_hash: eula.eula_hash,
    } ).then( () => {
      successfulLoginRedirect();
    } );
  };

  return (
    <button
      onClick={acceptEULA}
    >
      Accept
    </button>
  );
};

const EulaModal = ( {
  eula,
  sid,
  setSid,
  setExpiration,
  expiration,
  successfulLoginRedirect,
  showModal,
  setShowModal,
} ) => {
  return (
    <Modal
      visible={showModal}
      setVisible={setShowModal}
      header={ `${ window.COMPANY_NAME } Security User License Agreement` }
      body={<ModalBody eula={eula} />}
      action={<ModalAction
        successfulLoginRedirect={successfulLoginRedirect}
        eula={eula}
        sid={sid}
        setSid={setSid}
        setExpiration={setExpiration}
        expiration={expiration}
      />}
    />
  );
};

export default EulaModal;