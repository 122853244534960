/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import SetupPage from '../../../shared/SetupComponents/SetupPage';

import {
  isAWSID,
  isAWSSecret,
} from '../../../shared/Form/Validators';

import {
  getGlobalSettings, isNotEmpty, isTruthy,
} from '../../../shared/Utilities';

import {
  recordData,
} from './data';

import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';
import { normalizedAttribute } from '../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import InlineSVG from '../../../shared/InlineSVG';
import { makeRequest } from '../../../../legacy/io';

const CloudScanning = () => {

  const [ scanners, setScanners ] = React.useState( [] );
  const [ fields, setFields ] = React.useState( null );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const initialize = async() => {

    const latestSettings = await getGlobalSettings( 'global' );

    const proxyEnabled = isTruthy( latestSettings.outbound_proxy_enabled );

    const contentBlocks = {};

    Object.entries( scannerHelpLinkMap ).map( ( [ scannerKey, value ], index ) => {

      contentBlocks[scannerKey] =  <React.Fragment key={ index }>
        {/* eslint-disable-next-line max-len */}
        <p>{ window.COMPANY_NAME } can interpret the access rights assigned in your AWS environment as they apply to EC2 instances and other resources. To collect this information, { window.COMPANY_NAME } requires the access key ID and secret access key of an API user with a minimum of read and list access to the relevant resources. Please see <a href={ value.helpLink } className="inlineLink inline" target="_blank" rel="noreferrer noopener">Cloud Scanning Credential Configuration <InlineSVG type="newTabLink" version="primary" /></a> for more details and links to AWS documentation.</p>
      </React.Fragment>;
    } );

    const _fields = { ...EMPTY_FIELDS };

    // set the correct help paragraph for this scanner type with enabled/disabled
    // outbound_proxy based on global_setting
    _fields.selector.fields.map( field => {

      if ( field.attribute === 'outbound_proxy_enabled' ) {
        field.defaultValue = proxyEnabled
          ? '1'
          : '0';
      }

      if( field.type === 'contentBlock' ) {
        // eslint-disable-next-line
        const fieldScanner = field.attribute.split( '_' )[0];

        if ( isNotEmpty( contentBlocks[fieldScanner] ) ) {
          field.defaultValue = contentBlocks[fieldScanner];
        }
      }
    } );
    // if the proxy is disabled globally, need to adjust the defaults to unchecked for
    // the various scanners
    if ( !proxyEnabled ) {
      Object.values( _fields ).map( group => {
        group.fields.map( field => {
          if ( normalizedAttribute( field, 'cloud_scanner' ) === 'use_proxy' ) {
            field.defaultValue = false;
          }
        } );
      } );
    }

    setFields( _fields );
  };

  const EMPTY_FIELDS = {
    selector: {
      fields: [
        {
          type: 'select',
          label: 'Cloud Service Provider',
          attribute: 'protocol',
          defaultValue: 'aws',
          options: {
            aws: 'AWS',
          },
          disableOnEdit: true,
        },
        {
          type: 'hidden',
          attribute: 'outbound_proxy_enabled',
          defaultValue: '0',
        },
        {
          includeIf: { attribute: 'protocol', value: 'aws' },
          type: 'contentBlock',
          attribute: 'aws_help',
        },
      ],
    },
    aws: {
      fields: [
        {
          includeIf: { attribute: 'protocol', value: 'aws' },
          type: 'text',
          label: 'Descriptive name (for your reference)',
          attribute: 'aws_label',
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'protocol', value: 'aws' },
          type: 'text',
          label: 'Access key ID',
          attribute: 'aws_username',
          required: true,
          defaultValue: '',
          validators: [ isAWSID ],
        },
        {
          includeIf: { attribute: 'protocol', value: 'aws' },
          type: 'text',
          label: 'Secret access key',
          attribute: 'aws_secret',
          required: true,
          defaultValue: '',
          validators: [ isAWSSecret ],
        },
        {
          includeIf: { attribute: 'protocol', value: 'aws' },
          disableIf: { attribute: 'outbound_proxy_enabled', value: '0' },
          help: <HelpTrigger helpKey="use_outbound_proxy" />,
          type: 'checkbox',
          label: 'Use Outbound Proxy',
          attribute: 'aws_use_proxy',
          defaultValue: true,
        },
      ],
    },
  };

  const scannerHelpLinkMap = {
    aws:  {
      description: '',
      helpLink: '#.=help_documentation&main=user_guide&section=scanning&help_page=cloud_scanning',
    },
  };

  React.useEffect( () => {
    initialize();
  }, [ ] );

  const onRefresh = () => {
    makeRequest( 'SEARCH', '/project/default/cloud_setting', {
      // eslint-disable-next-line camelcase,
      extra_columns: [ 'label', 'vendor', 'credential_id', 'settings' ],
    } ).then( cloudResponse => {
      if ( cloudResponse ) {
        if ( cloudResponse['results'] && cloudResponse['results'].length ) {
          makeRequest( 'SEARCH', '/project/default/credential',
            {
              // eslint-disable-next-line camelcase
              extra_columns: [ 'protocol', 'domain', 'username', 'eval_order', 'options', 'label' ],
              // eslint-disable-next-line camelcase
              order_by:[
                [ 'eval_order', 'ASC' ],
              ],
            } ).then( credentialResponse => {

            if (
              credentialResponse
              && credentialResponse['results']
              && credentialResponse['results'].length
            ) {
              const creds = {};
              const scannersWithCredentials = [];

              credentialResponse.results.map( c => {
                creds[c.id] = c;
              } );
              cloudResponse.results.map( cr => {
                const cred = creds[cr.credential_id];
                scannersWithCredentials.push( {
                  ...cr,
                  protocol: cred.protocol,
                  username: cred.username,
                  secret: cred.secret,
                } );
              } );
              setScanners( scannersWithCredentials );
            }
          } );
        } else {
          setScanners( [] );
        }
      }
    } );
  };

  const onSave = (
    selectedScannerWithCred,
    isValid,
    fieldStates,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      const selectedProtocol = fieldStates.protocol.updatedValue;

      const credential = {
        protocol: selectedProtocol,
        // eslint-disable-next-line camelcase
        eval_order: 0,
        domain: '',
        username: fieldStates[`${selectedProtocol}_username`].updatedValue,
        secret: fieldStates[`${selectedProtocol}_secret`].updatedValue,
      };

      // we are editing an existing record
      if ( isNotEmpty( selectedScannerWithCred ) ) {
        // need to remove the blank password, if it has not been altered
        if ( fieldStates[`${selectedProtocol}_secret`].updatedValue === '' ) {
          delete credential['secret'];
        }
        // add the id of the existing cred, so that it updates instead of creating a new record
        credential.id = selectedScannerWithCred.credential_id;
      }
      makeRequest( 'UPSERT', '/project/default/credential', { 'records': [ credential ] } ).then( response => {
        // eslint-disable-next-line
        const newCred = response.results[0];

        const cloudRecord = {
          vendor: credential['protocol'],
          // eslint-disable-next-line camelcase
          credential_id: newCred.id,
          label: fieldStates[`${selectedProtocol}_label`].updatedValue,
          settings: {},
        };

        if ( fieldStates[`${selectedProtocol}_use_proxy`] ) {
          // eslint-disable-next-line camelcase, max-len
          cloudRecord.settings.use_proxy = isNotEmpty( fieldStates[`${selectedProtocol}_use_proxy`].updatedValue )
            ? fieldStates[`${selectedProtocol}_use_proxy`].updatedValue
            : false;
        }

        // editing existing record, add the id
        if ( selectedScannerWithCred && selectedScannerWithCred.id !== '' ) {
          cloudRecord.id = selectedScannerWithCred.id;
        }

        // update cloud_setting entry
        // eslint-disable-next-line camelcase, max-len
        makeRequest( 'UPSERT', '/project/default/cloud_setting', {'records':[ cloudRecord ]} ).then( () => {
          addFlashMessage( {
            body: 'Successfully saved cloud settings',
            type: 'success',
          } );
          onRefresh();
        } );
      } );
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( fields ) &&
        <SetupPage
          onRefresh={onRefresh}
          onSave={onSave}
          records={scanners}
          setRecords={setScanners}
          recordType="cloud_scanner"
          recordData={recordData}
          useForm={true}
          fields={fields}
          alternateItemLayout
        />
      }
    </React.Fragment>

  );
};

export default CloudScanning;
