/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import InlineSVG from '../../../InlineSVG';
import { isNotEmpty } from '../../../Utilities';

import './Number.scss';

const Number = ( {
  field,
  formState,
  originalValue,
  onChange,
  setIsFocused,
  existingRecord,
  fieldRef,
} ) => {

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  // when the form changes, need to sync the value
  //  (this is important on forms where the underlying tool changes the fields)
  React.useEffect( ( ) => {
    if (
      isNotEmpty( formState )
      && isNotEmpty( formState.fieldStates )
      && isNotEmpty( formState.fieldStates[field.attribute] )
      && isNotEmpty( field )
    ) {
      fieldRef.current.value = formState?.fieldStates[field.attribute].updatedValue;
    }
  }, [ formState, field ] );

  return (
    <div className={ 'numberFieldWrapper' }>
      <input
        ref={fieldRef}
        onFocus={ () => setIsFocused( true ) }
        onBlur={ () => setIsFocused( false ) }
        // id={`${field.attribute}_${field.type}`}
        placeholder={ field.placeholder ? field.placeholder : ''}
        disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
        type={field.type}
        onChange={ e => onChange( field, e.target.valueAsNumber || e.target.value ) }
        { ...field.htmlProps }
      />
      <InlineSVG type="edit" elementClass="editIcon" />
    </div>
  );
};

export default Number;
