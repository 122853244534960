import React from 'react';
import { Remarkable } from 'remarkable';
import { makeRequest, search_model_records } from '../../../../legacy/io';
import { getRecords } from '../../../shared/RecordCache';
import {
  isNotEmpty,
  itemIsArray,
} from '../../../shared/Utilities';
/* eslint-disable camelcase, max-len */

const md = new Remarkable();

export const getMarkdown = content => {
  return { __html: md.render( content ) };
};

export const getEscalations = async( edge ) => {
  // currently leaving escalations out of the cache becuase it would fill up so quickly,
  // so this function use is fine. -DMC 2022-02-16
  const _escalations = await search_model_records( 'escalation', {
    // eslint-disable-next-line camelcase
    field_map: { edge_id: edge.id},
    rownums: [],
    // eslint-disable-next-line camelcase
    extra_columns: [ 'vulnerability_id', 'escalation_analysis.risk' ],
  } );

  if ( isNotEmpty( _escalations ) ) {

    const _vulnerabilitiesToEscalationsMap = {};

    const _escalationsByVulnID = {};
    const _escalationsByID = {};
    const _escalationSignatureInstance = {};

    _escalations.map( e => _escalationsByVulnID[e.vulnerability_id] = e );
    _escalations.map( e => _escalationsByID[e.id] = e );
    _escalations.map( e => _escalationSignatureInstance[e.id] = {} );

    const vulnerabilityIDS = _escalations.map( e => e.vulnerability_id );

    const params = {
      // eslint-disable-next-line camelcase
      id_list: vulnerabilityIDS,
      // eslint-disable-next-line camelcase
      extra_columns: [
        'identifier',
        'description',
        'urls',
        'cvss_base_score',
        'public_notes',
        'cvssv2',
        'cvssv3',

        'vulnerability_analysis.model_id',
        'vulnerability_analysis.vulnerability_id',
        'vulnerability_analysis.patches',
        'vulnerability_analysis.hosts',
        'vulnerability_analysis.scan_results',
        'vulnerability_analysis.risk',
        'vulnerability_analysis.risk_percentile',
        'vulnerability_analysis.exploit_status',
        'vulnerability_analysis.nofix',
      ],
      rownums: [ 0, vulnerabilityIDS.length + 1 ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'vulnerability_analysis.risk', 'DESC' ] ],
    };

    // eslint-disable-next-line camelcase
    const vulnerabilityRecords = await getRecords( 'vulnerability', params );

    const _vulnerabilities = {};

    if ( isNotEmpty( vulnerabilityRecords ) ) {
      vulnerabilityRecords.sort( ( a, b ) => b.risk - a.risk ).map( v => {
        _vulnerabilitiesToEscalationsMap[v.id] = { vulnerability: v };
        _vulnerabilities[v.id] = v;
      } );
    }

    const _escalationsDetails = await makeRequest( 'INDEX', '/model/escalation_details', {
      project: 'default',
      model: 'base',
      ids: _escalations.map( e => e.id ),
      // eslint-disable-next-line camelcase
      key_field: 'escalation_id',
    } );

    if ( isNotEmpty( _escalationsDetails.results ) ) {
      let _instances = { results: {} };
      const signature_ids = Object.values( _escalationsDetails.results ).map( ed => ed.scan_result_id );

      const signatureParams = {
        // eslint-disable-next-line camelcase
        columns: [
          'scanner',
          'signature',
          'title',
          'filtered_risk',
          'scanner_rating',
          'description',
          'urls',
          'recommendation',
          // 'created',
        ],
        rows: [ 0, signature_ids.length || 1 ],
        filters: {
          // eslint-disable-next-line camelcase
          signature_ids,
        },
        // eslint-disable-next-line camelcase
        order_by: [
          // [ 'created', 'DESC' ],
          [ 'filtered_risk', 'DESC' ],
          [ 'id', 'ASC' ],
        ],
      };

      const _signatureResponse = await makeRequest( 'POST', '/fe/analysis/signature/SELECT', signatureParams );
      const instanceIds = [];
      Object.values( _escalationsDetails.results ).map( e => {
        if ( isNotEmpty( e.third_party_instance_id ) ) {
          instanceIds.push( e.third_party_instance_id );
        }
      } );

      if ( isNotEmpty( instanceIds ) ) {
        // eslint-disable-next-line camelcase
        _instances = await makeRequest(
          'INDEX',
          '/project/default/third_party_instance',
          { ids: instanceIds },
        );
      }

      Object.values( _escalationsDetails.results ).map( details => {
        let signature = {};
        if ( isNotEmpty( _signatureResponse ) && itemIsArray( _signatureResponse ) ) {
          signature = _signatureResponse.find( s => s.id  === details.scan_result_id );
        }
        const escalation = _escalationsByID[details.escalation_id];
        const escalationDetails = details;
        const instance = _instances?.results[details.third_party_instance_id];

        const vulnID = escalation.vulnerability_id;
        const fullInformation = { escalation, signature, instance, escalationDetails };

        _escalationSignatureInstance[details.escalation_id] = fullInformation;
        _vulnerabilitiesToEscalationsMap[vulnID] = {
          ..._vulnerabilitiesToEscalationsMap[vulnID],
          ...fullInformation,
        };
      } );
    }
    return ( { map: _vulnerabilitiesToEscalationsMap, vulnerabilities: _vulnerabilities } );
  }
  return {};
};

export const getThirdPartyData = ( item, attribute ) => {
  if ( isNotEmpty( item ) && isNotEmpty( attribute ) && isNotEmpty( item[attribute] ) ) {
    if ( item.scanner === 'rapid7' ) {
      return <div
        className="markdownWrapper"
        dangerouslySetInnerHTML={ getMarkdown( item[attribute] ) }
      />;
    }
    if ( attribute === 'evidence' || attribute === 'raw_info' ) {
      return <pre>
        { item[attribute].trim() }
      </pre>;
    }
    return <p>
      { item[attribute].trim() }
    </p>;
  }
  return null;
};

export const getEscalationsForEdge = async ( edge ) => {

  if ( isNotEmpty( edge ) ) {

    let _includedEdges = [ edge ];

    if ( isNotEmpty( edge.includedEdges ) ) {
      _includedEdges = edge.includedEdges;
    }

    const promises = _includedEdges.map( getEscalations );

    const resolvedPromises = await Promise.all( promises );

    let allMaps ={};
    let allVulns = {};

    resolvedPromises.map( resolved => {
      const { map, vulnerabilities } = resolved;

      allMaps = { ...allMaps, ...map };
      allVulns = { ...allVulns, ...vulnerabilities };
    } );

    return ( { map: allMaps, vulnerabilities: allVulns } );
  }
  return {};
};

export const getEscalationsForSignature = async ( signature ) => {

  let _instanceAndEscalationData = {};

  if ( isNotEmpty( signature ) ) {
    _instanceAndEscalationData = { ..._instanceAndEscalationData, signature };
  }

  const _params = {
    'project': 'default',
    'model': 'base',
    'record_type': signature.type,
    'record_id': signature.id,
    'scan_result_id': signature.record?.id,
  };

  const escalationsResponse = await makeRequest( 'COMPUTE', '/analysis/escalation_details_by_record', _params );

  if ( isNotEmpty( escalationsResponse ) && isNotEmpty( escalationsResponse.results ) ) {

    // eslint-disable-next-line camelcase
    const instanceRequest = await makeRequest(
      'INDEX',
      '/project/default/third_party_instance',
      { ids: escalationsResponse.results.map( e => e.third_party_instance_id ) },
    );

    const [ escalation ] = escalationsResponse.results;

    const instance = instanceRequest.results[escalation.third_party_instance_id];

    _instanceAndEscalationData = { ..._instanceAndEscalationData, escalation };

    if ( isNotEmpty( instance ) ) {
      _instanceAndEscalationData = { ..._instanceAndEscalationData, instance };
    }

    if ( isNotEmpty( instance.vulnerability_ids ) ) {

      const params = {
        // eslint-disable-next-line camelcase
        id_list: instance.vulnerability_ids,
        // eslint-disable-next-line camelcase
        extra_columns: [
          'identifier',
          'description',
          'urls',
          'cvss_base_score',
          'public_notes',
          'cvssv2',
          'cvssv3',

          'vulnerability_analysis.model_id',
          'vulnerability_analysis.vulnerability_id',
          'vulnerability_analysis.patches',
          'vulnerability_analysis.hosts',
          'vulnerability_analysis.scan_results',
          'vulnerability_analysis.risk',
          'vulnerability_analysis.risk_percentile',
          'vulnerability_analysis.exploit_status',
        ],
        rownums: [ 0, instance.vulnerability_ids.length + 1 ],
      };

      // eslint-disable-next-line camelcase
      let vulnerabilityRecords = await getRecords( 'vulnerability', params );

      const _vulnerabilities = {};

      if ( isNotEmpty( vulnerabilityRecords ) ) {
        vulnerabilityRecords = vulnerabilityRecords.sort( ( a, b ) => b.risk - a.risk );
        vulnerabilityRecords.map( v => _vulnerabilities[v.id] = v );
      }
      return ( { vulnerabilities: _vulnerabilities, instanceAndEscalationData: _instanceAndEscalationData } );
    }
  }
  return ( { vulnerabilities: [], instanceAndEscalationData: _instanceAndEscalationData } );
};

export const getNodeIconDescription = node => {

  let { flags, type, combined_impact } = node;
  const { isSensitive } = node;

  if ( isNotEmpty( node.original ) ) {
    ( { flags, type, combined_impact } = node.original );
  }

  const description = [];

  if ( isNotEmpty( node ) && isNotEmpty( type ) ) {
    description.push( <span>This node is of the type <strong>{ nodeTypeMap[type] }</strong></span> );
  } else {
    description.push( <span>This node is an <strong>{ nodeTypeMap.abstract }</strong> node</span> );
  }

  if ( isNotEmpty( flags ) ) {
    if ( flags.is_superuser ) {
      description.push( <span>, with <strong>Super User</strong> access</span> );
    }
    if ( flags.rdp_user || flags.has_rdp_access ) {
      description.push( <span>, and <strong>has RDP Access</strong></span> );
    } else if ( flags.browser_user ) {
      description.push( <span>, and <strong>uses a browser</strong></span> );
    } else  if ( flags.outlook_user || flags.windowsmail_user ) {
      description.push( <span>, and <strong>uses email</strong></span> );
    }

    if ( flags.recently_active ) {
      description.push( <span> and has been <strong>recently active</strong></span> );
    }
  }

  if ( combined_impact > 0 || isSensitive ) {
    description.push( <span> and has been flagged as a <strong>sensitive asset</strong></span> );
  }

  return description;
};

export const nodeTypeMap = {
  listening_port: 'tcp listening port',
  user: 'user',
  service_user: 'service user',
  domain_user: 'domain user',
  adversary: 'adversary',
  attacker: 'adversary',
  group: 'group',
  domain_group: 'domain group',
  shared_folder_permission: 'shared folder',
  database: 'database',
  abstract: 'abstract',
};

export const nodeFlagMap = {
  recently_active: 'Recently Active',
  outlook_user: 'Mail User',
  windowsmail_user: 'Mail User',
  browser_user: 'Browser User',
  rdp_user: 'RDP User',
  has_rdp_access: 'RDP User',
  is_superuser: 'Super User',
};