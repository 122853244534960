/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../legacy/io';
import { ModelContext } from '../../Contexts/Model';
import { RiskContext } from '../../Contexts/Risk';
import InlineSVG from '../../shared/InlineSVG';
import Modal from '../../shared/Modal';
import {
  formatNumber,
  // getCurrentPercentile,
  getGlobalSettings,
  getGradeClass,
  getPercentileClass,
  getRiskClass,
  getYForX,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  itemIsString,
  percentileToGrade,
  updateGlobalSettings,
} from '../../shared/Utilities';

import './RiskScoreMenu.scss';
// import Metric from '../Reporting/Dashboards/Widgets/Content/Metric';
import { numberToOrdinal } from '../../shared/Utilities';
import RiskScore from '../Reporting/Dashboards/Widgets/v2/RiskScore';
import PeerPercentile from '../Reporting/Dashboards/Widgets/v2/PeerPercentile';
import RiskGrade from '../Reporting/Dashboards/Widgets/v2/RiskGrade';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import { canConfigure } from './AccessControl';

const ModalAction = ( { newTargetRisk, includedDisplayModes } ) => {

  const [ , RefreshModelData ] = React.useContext( ModelContext );

  const onSave = async () => {
    // eslint-disable-next-line camelcase
    await updateGlobalSettings( 'project', { settings: { risk_target: newTargetRisk } } );
    // eslint-disable-next-line camelcase
    await updateGlobalSettings( 'global', { risk_score_display_mode: includedDisplayModes } );

    RefreshModelData();
    window.location.reload( true );
  };

  return (
    <button
      onClick={ onSave }
    >
      Save Changes
    </button>
  );
};

const ModalBody = ( {
  newTargetRisk,
  setNewTargetRisk,
  riskEditMode,
  setRiskEditMode,
  includedDisplayModes,
  setIncludedDisplayModes,
  setInitialDisplayModes,
  showModal,
  globalSettings,
} ) => {

  const [ globalRisk, targetRisk, peerPercentile ] = React.useContext( RiskContext );

  const [ reductionValue, setReductionValue ] = React.useState( 1 );
  const [ peerIndex, setPeerIndex ] = React.useState( '' );
  const [ peerValues, setPeerValues ] = React.useState( null );
  const [ peerPercentileData, setPeerPercentileData ] = React.useState( null );

  // reset the form on close
  React.useEffect( () => {
    if ( showModal === false ) {
      setReductionValue( 1 );
      setPeerIndex( '' );
      setRiskEditMode( 'percent' );
      setIncludedDisplayModes( [] );
      if ( isNotEmpty( targetRisk ) ) {
        setNewTargetRisk( targetRisk );
      }
    } else if ( isNotEmpty( globalSettings ) ) {

      if ( isNotEmpty( globalSettings.risk_score_display_mode ) ) {

        const legacyModeFormats = [
          'risk',
          'percentile',
          'both',
        ];

        // check for an older format first
        if ( legacyModeFormats.includes( globalSettings.risk_score_display_mode ) ) {
          if ( globalSettings.risk_score_display_mode === 'risk' ) {
            setIncludedDisplayModes( [ 'risk' ] );
            setInitialDisplayModes( [ 'risk' ] );
          }
          if ( globalSettings.risk_score_display_mode === 'percentile' ) {
            setIncludedDisplayModes( [ 'percentile' ] );
            setInitialDisplayModes( [ 'percentile' ] );
          }
          if ( globalSettings.risk_score_display_mode === 'both' ) {
            setIncludedDisplayModes( [ 'risk', 'percentile' ] );
            setInitialDisplayModes( [ 'risk', 'percentile' ] );
          }
        } else if ( itemIsArray( globalSettings.risk_score_display_mode ) ) {
          setIncludedDisplayModes( globalSettings.risk_score_display_mode );
          setInitialDisplayModes( globalSettings.risk_score_display_mode );
        } else if ( itemIsString( globalSettings.risk_score_display_mode ) ) {
          setIncludedDisplayModes( globalSettings.risk_score_display_mode.split( '_' ) );
          setInitialDisplayModes( globalSettings.risk_score_display_mode.split( '_' ) );
        } else {
          setIncludedDisplayModes( [ globalSettings.risk_score_display_mode ] );
          setInitialDisplayModes( [ globalSettings.risk_score_display_mode ] );
        }
      } else {
        setIncludedDisplayModes( [ ] );
        setInitialDisplayModes( [ ] );
      }

    }
  }, [ showModal, globalSettings ] );

  const handleReductionChange = e => {
    if ( riskEditMode === 'percent' ) {
      const { value } = e.target;
      setReductionValue( value );
      if ( isNotEmpty( globalRisk ) ) {
        setNewTargetRisk( Math.ceil( globalRisk * value ) );
      } else {
        setNewTargetRisk( 1 );
      }
    } else if ( isNotEmpty( peerValues ) ) {
      const { value } = e.target;
      setPeerIndex( value );
      setNewTargetRisk( peerValues[value] );
    }
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( targetRisk ) ) {
      setNewTargetRisk( targetRisk );
    }
    if ( isEmpty( peerValues ) ) {
      makeRequest( 'FETCH', '/analysis/telemetry' ).then( response => {
        if ( isNotEmpty( response.results ) && isNotEmpty( response.results.industry_model_percentiles ) ) {
          // eslint-disable-next-line
          const mostRecentPointKey = Object.keys( response.results.industry_model_percentiles )[0];
          const mostRecentPoint = response.results.industry_model_percentiles[mostRecentPointKey];
          if ( mostRecentPoint ) {
            /* eslint-disable max-len */
            const peerDataPoints = [
              // 10%
              Math.round( getYForX( response.results.industry_comparison, 2, mostRecentPoint.observed_hosts, 3.333 ) ),
              // 20%
              Math.round( getYForX( response.results.industry_comparison, 2, mostRecentPoint.observed_hosts, 2.857 ) ),
              // 30%
              Math.round( getYForX( response.results.industry_comparison, 2, mostRecentPoint.observed_hosts, 2.5 ) ),
              // 40%
              Math.round( getYForX( response.results.industry_comparison, 2, mostRecentPoint.observed_hosts, 2.2222 ) ),
              // 50%
              Math.round( getYForX( response.results.industry_comparison, 1, mostRecentPoint.observed_hosts, 4 ) ),
              /* eslint-enable max-len */
            ];
            setPeerValues( peerDataPoints );
          }
        }
      } );
    }
  }, [ targetRisk ] );

  React.useEffect( () => {
    if ( isNotEmpty( peerPercentile ) ) {
      const _data = {
        results: [ { percentile: peerPercentile, risk: globalRisk } ],
      };
      setPeerPercentileData( _data );
    }
  }, [ peerPercentile, globalRisk ] );

  const handleCheck = value => {
    if ( isEmpty( includedDisplayModes ) ) {
      setIncludedDisplayModes( [ value ] );
    } else {
      let _includedDisplayModes = [ ...includedDisplayModes ];

      if ( _includedDisplayModes.includes( value ) ) {
        _includedDisplayModes = _includedDisplayModes.filter( v => v !== value );
      } else {
        _includedDisplayModes.push( value );
      }
      setIncludedDisplayModes( _includedDisplayModes );
    }
  };

  return (
    <React.Fragment>
      <div className="checkboxGroupWrapper fieldWrapper included visible">
        <span className="labelWrapper">Risk Display Mode</span>
        <div className="optionsWrapper">
          <label>
            <div className={ `checkboxFieldWrapper ${includedDisplayModes?.includes( 'risk' ) ? 'checked' : ''}` }>
              <input
                type="checkbox"
                onChange={ () => handleCheck( 'risk' ) }
                checked={ includedDisplayModes?.includes( 'risk' ) }
              />
            </div>
            <span className="labelWrapper">
              Risk Score
            </span>
          </label>
          <label>
            <div
              className={ `checkboxFieldWrapper ${includedDisplayModes?.includes( 'percentile' ) ? 'checked' : ''}` }
            >
              <input
                type="checkbox"
                onChange={ () => handleCheck( 'percentile' ) }
                checked={ includedDisplayModes?.includes( 'percentile' ) }
              />
            </div>
            <span className="labelWrapper">
              Peer Percentile
            </span>
          </label>
          <label>
            <div className={ `checkboxFieldWrapper ${includedDisplayModes?.includes( 'grade' ) ? 'checked' : ''}` }>
              <input
                type="checkbox"
                onChange={ () => handleCheck( 'grade' ) }
                checked={ includedDisplayModes?.includes( 'grade' ) }
              />
            </div>
            <span className="labelWrapper">
              Risk Grade
            </span>
          </label>
        </div>

      </div>
      <div className={ `riskDisplayModesWrapper displayModesLength--${includedDisplayModes?.length || 0}`} >
        {
          includedDisplayModes.includes( 'risk' ) &&
          <RiskScore
            item={ { key: 'risk_Score '} }
          />
        }
        {
          includedDisplayModes.includes( 'percentile' ) &&
          <PeerPercentile data={ peerPercentileData } />
        }
        {
          includedDisplayModes.includes( 'grade' ) &&
          <RiskGrade />
        }
      </div>
      <h3>Target Risk</h3>
      <p>Your target risk (among other things) helps DeepSurface apply ratings to all items in your environment</p>
      <p>Use the form below to change your target</p>
      <div className="formWrapper">
        <div className="selectFieldWrapper fieldWrapper visible included">
          <span className="labelWrapper" >Calculate target risk based on</span>
          <select
            value={riskEditMode}
            onChange={ e => setRiskEditMode( e.target.value ) }
          >
            <option value="percent">Percentage of current risk</option>
            <option value="peer">Peer percentile</option>
          </select>
        </div>
        {
          riskEditMode === 'peer' &&
          <div className="selectFieldWrapper fieldWrapper visible included">
            <span className="labelWrapper" >Desired Peer Percentile</span>
            <select
              value={peerIndex}
              onChange={ handleReductionChange }
            >
              <option value="">Select Percentile</option>
              <option value={ 0 }>Top 10%</option>
              <option value={ 1 }>Top 20%</option>
              <option value={ 2 }>Top 30%</option>
              <option value={ 3 }>Top 40%</option>
              <option value={ 4 }>Top 50% (typical peer average)</option>
            </select>
          </div>
        }
        {
          riskEditMode === 'percent' &&
          <div className="selectFieldWrapper fieldWrapper visible included percentCurrentRisk">
            <span className="labelWrapper" >Set Target Risk to be</span>
            <select
              value={reductionValue}
              onChange={ handleReductionChange }
            >
              <option value={ 1 }>0%</option>
              <option value={ 0.9 }>10%</option>
              <option value={ 0.8 }>20%</option>
              <option value={ 0.7 }>30%</option>
              <option value={ 0.6 }>40%</option>
              <option value={ 0.5 }>50%</option>
            </select>
            <span className="labelWrapper" >lower than current risk</span>
          </div>
        }

        <div className="numberFieldWrapper fieldWrapper visible included">
          <span className="labelWrapper" >Target Risk</span>
          <input
            type="number"
            value={newTargetRisk}
            onChange={ e => setNewTargetRisk( Number( e.target.value ) ) }
          />
        </div>
      </div>

    </React.Fragment>
  );
};

const RiskScoreMenu = ( ) => {
  const [ globalRisk, targetRisk, peerPercentile ] = React.useContext( RiskContext );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const [ showModal, setShowModal ] = React.useState( false );
  const [ newTargetRisk, setNewTargetRisk ] = React.useState( '' );
  const [ riskClass, setRiskClass ] = React.useState( 'high' );
  const [ percentileClass, setPercentileClass ] = React.useState( 'high' );
  const [ gradeClass, setGradeClass ] = React.useState( 'high' );
  const [ globalSettings, setGlobalSettings ] = React.useState( null );

  const [ initialDisplayModes, setInitialDisplayModes ] = React.useState( [ 'risk', 'percentile' ] );

  const [ includedDisplayModes, setIncludedDisplayModes ] = React.useState( [ 'risk', 'percentile' ] );

  const [ riskEditMode, setRiskEditMode ] = React.useState( 'percent' );

  const getCurrentRiskClass = () => {
    if ( isEmpty( initialDisplayModes ) ) {
      return 'empty';
    }
    if ( initialDisplayModes.includes( 'risk' ) ) {
      return riskClass;
    }
    if ( initialDisplayModes.includes( 'percentile' ) ) {
      return percentileClass;
    }
    if ( initialDisplayModes.includes( 'grade' ) ) {
      return gradeClass;
    }
    return riskClass;
  };

  React.useEffect( ( ) => {
    // need the global settings for the trigger display ( options, percentile, global risk, both )
    getGlobalSettings( 'global' ).then( response => {
      setGlobalSettings( response );

      if ( isNotEmpty( response.risk_score_display_mode ) ) {

        const legacyModeFormats = [
          'risk',
          'percentile',
          'both',
        ];

        // check for an older format first
        if ( legacyModeFormats.includes( response.risk_score_display_mode ) ) {
          if ( response.risk_score_display_mode === 'risk' ) {
            setIncludedDisplayModes( [ 'risk' ] );
            setInitialDisplayModes( [ 'risk' ] );
          }
          if ( response.risk_score_display_mode === 'percentile' ) {
            setIncludedDisplayModes( [ 'percentile' ] );
            setInitialDisplayModes( [ 'percentile' ] );
          }
          if ( response.risk_score_display_mode === 'both' ) {
            setIncludedDisplayModes( [ 'risk', 'percentile' ] );
            setInitialDisplayModes( [ 'risk', 'percentile' ] );
          }
        } else if ( itemIsArray( response.risk_score_display_mode ) ) {
          setIncludedDisplayModes( response.risk_score_display_mode );
          setInitialDisplayModes( response.risk_score_display_mode );
        } else if ( itemIsString( response.risk_score_display_mode ) ) {
          setIncludedDisplayModes( response.risk_score_display_mode.split( '_' ) );
          setInitialDisplayModes( response.risk_score_display_mode.split( '_' ) );
        } else {
          setIncludedDisplayModes( [ response.risk_score_display_mode ] );
          setInitialDisplayModes( [ response.risk_score_display_mode ] );
        }
      } else {
        setIncludedDisplayModes( [ ] );
        setInitialDisplayModes( [ ] );
      }
    } );
  }, [ globalRisk, peerPercentile ] );

  React.useEffect( ( ) => {
    if ( isNotEmpty( globalRisk ) && isNotEmpty( targetRisk ) ) {
      const _riskClass = getRiskClass( globalRisk, targetRisk );
      setRiskClass( _riskClass );
    }
  }, [ globalRisk, targetRisk, peerPercentile ] );

  React.useEffect( ( ) => {
    if ( peerPercentile ) {
      const _percentileClass = getPercentileClass( peerPercentile );
      const _gradeClass = getGradeClass( peerPercentile );
      setPercentileClass( _percentileClass );
      setGradeClass( _gradeClass );
    }
  }, [ peerPercentile ] );

  const handleButtonClick = () => {
    if ( canConfigure( licenseInfo ) ) {
      setShowModal( true );
    }
  };

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( globalRisk )
        ) &&
        <React.Fragment>
          <Modal
            visible={ showModal }
            setVisible={ setShowModal }
            body={ <ModalBody
              newTargetRisk={newTargetRisk}
              setNewTargetRisk={setNewTargetRisk}
              riskEditMode={riskEditMode}
              setRiskEditMode={setRiskEditMode}
              globalSettings={globalSettings}
              includedDisplayModes={includedDisplayModes}
              setIncludedDisplayModes={setIncludedDisplayModes}
              setInitialDisplayModes={setInitialDisplayModes}
              showModal={showModal}
            /> }
            action={ <ModalAction newTargetRisk={newTargetRisk} includedDisplayModes={includedDisplayModes} />}
            elementClass="topBarRiskScoreModal"
            size="small"
          />
          <div
            // eslint-disable-next-line max-len
            className={ `${ !canConfigure( licenseInfo ) ? 'disabled' : ''} globalRiskTriggerWrapper currentRiskButton risk--${getCurrentRiskClass()} displayModesLength--${initialDisplayModes?.length || 0}` }
            onClick={ handleButtonClick }
          >
            {
              initialDisplayModes?.includes( 'risk' )  &&
              <div className="riskButtonSection risk" >
                <span className="iconWrapper">
                  <InlineSVG type="dsLogoMinimal" />
                </span>
                <span>{ formatNumber( Math.round( globalRisk || 0 ) ) }</span>
              </div>
            }
            {
              initialDisplayModes?.includes( 'percentile' )  &&
              <div className="riskButtonSection percentile" >
                <span className="iconWrapper">
                  <InlineSVG type="widget_rank" />
                </span>
                <span>
                  {/* eslint-disable-next-line max-len*/}
                  { `${ isNotEmpty( peerPercentile ) ? numberToOrdinal( peerPercentile ) : 'N/A' }` }
                </span>
              </div>
            }
            {
              initialDisplayModes?.includes( 'grade' )  &&
              <div className="riskButtonSection grade" >
                <span className="iconWrapper">
                  <InlineSVG type="grade" />
                </span>
                <span>
                  {/* eslint-disable-next-line max-len*/}
                  { `${ isNotEmpty( peerPercentile ) ? percentileToGrade( peerPercentile ) : 'N/A' }` }
                </span>
              </div>
            }
            {
              isEmpty( initialDisplayModes ) &&
              <div className="riskButtonSection empty" >
                <span>
                  View risk
                </span>
              </div>
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RiskScoreMenu;