/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';

import './OSFamilyBreakdown.scss';
import EmptyState from '../../../../../shared/EmptyState';
import { getThemeColor } from '../../../../../shared/Themes';

const OSFamilyBreakdown = ( { data, settings } ) => {

  const [ osFamilyTotal, setOSFamilyTotal ] = React.useState( 1 );
  const [ osFamilyData, setOSFamilyData ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const palette = {
    macos: '#add4f8',
    windows: '#087dea',
    unix: '#5aa8f1',
    unknown: getThemeColor( '--divider-color' ),
  };

  const familyNameMap = {
    windows: 'Windows',
    unix: 'Unix',
    macos: 'macOS',
    unknown: 'Unknown',
  };

  const setupData = data => {
    const _osFamilyData = {};
    const total = data._total || 1;

    Object.entries( data ).map( ( [ familyKey, count ] ) => {
      _osFamilyData[familyKey] = {
        label: familyNameMap[familyKey],
        stroke: palette[familyKey],
        value: ( count / total ) * 100,
        originalCount: count,
        total: count,
        key: familyKey,
      };
    } );

    setOSFamilyTotal( total );
    setOSFamilyData( _osFamilyData );
  };


  React.useEffect( () => {
    if (
      isNotEmpty( data )
      && isNotEmpty( data.results )
      && isNotEmpty( data.results.os_family )
    ) {
      setupData( data.results.os_family );
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( osFamilyTotal ) && isNotEmpty( osFamilyData ) )
          ? <React.Fragment>
            <Donut
              data={osFamilyData}
              total={osFamilyTotal}
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
            <Legend
              legendData={ osFamilyData }
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
          </React.Fragment>
          : <EmptyState message="No OS family data available" />
      }
    </React.Fragment>
  );
};

export default OSFamilyBreakdown;