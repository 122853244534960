/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  ACLRoleLabelMap,
  decodeURLHash,
  encodeURLHash,
  getExternalUsers,
  isNotEmpty,
  itemIsArray,
  userDisplayName,
} from '../../../../../shared/Utilities';
import InlineSVG from '../../../../../shared/InlineSVG';
import { makeRequest } from '../../../../../../legacy/io';
import { CurrentUserContext } from '../../../../../Contexts/CurrentUser';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../../Contexts/FlashMessageQueue';
import UserDetails from '../../../../../shared/UserDetails';
import FormModal from '../../../../../shared/SetupComponents/FormModal';
import { getUserParams, refresh } from '../data';
import { getFieldValues } from '../../../../../shared/Form/Shared';
import PageCreateButton from '../../../../../shared/PageCreateButton';
import { hasFeatureAccess, isAdmin } from '../../../../App/AccessControl';
import AccessAndCredentials from './AccessAndCredentials';
import PageHeader from '../../../../../shared/PageHeader';
import RatingBadge from '../../../../../shared/RatingBadge';
import DetailsAndPreferences from './DetailsAndPreferences';

const UserDetail = ( { setShowUserDetails, refreshAllUsers } ) => {

  const [ user, setUser ] = React.useState( null );
  const [ users, setUsers ] = React.useState( null );
  const [ externalUsers, setExternalUsers ] = React.useState( null );
  const [ currentUser, setCurrentUser, licenseInfo ] = React.useContext( CurrentUserContext );

  const [ showEditModal, setShowEditModal ] = React.useState( false );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ fields, setFields ] = React.useState( null );

  const [ currentTab, setCurrentTab ] = React.useState( 'access_and_credentials' );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const tabs = {
    // eslint-disable-next-line camelcase
    access_and_credentials: 'Access and Credentials',
    // eslint-disable-next-line camelcase
    details_and_preferences: 'Details and Preferences',
  };

  const iconForTab = tabKey => {
    if ( tabKey === 'access_and_credentials' ) {
      return <InlineSVG type="locked" />;
    }
    if ( tabKey === 'details_and_preferences' ) {
      return <InlineSVG type="user_record" />;
    }
  };

  const handleTabClick = tabKey => {
    if ( isNotEmpty( tabKey ) ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { panel_tab: tabKey } );
      setCurrentTab( tabKey );
    }
  };

  const handleUsersCallback = ( _externalUsers, _users ) => {

    if ( isNotEmpty( _users ) && isNotEmpty( _externalUsers ) ) {
      _users.map( user => {
        user.externalUser = null;
        // eslint-disable-next-line camelcase
        user.mapped_user_id = '';
      } );

      Object.values( _externalUsers ).map( eu  => {
        if ( isNotEmpty( eu.web_user_id ) ) {
          const user = _users.find( u => u.id === eu.web_user_id );
          if ( isNotEmpty( user ) ) {
            user.externalUser = eu;
            // eslint-disable-next-line camelcase
            user.mapped_user_id = eu.id;
          }
        }
      } );
    }
    setUsers( _users );
    setExternalUsers( _externalUsers );
  };

  const onRefresh = async () => {

    const hash = decodeURLHash();

    if ( isNotEmpty( hash.item ) ) {
      // if you are logged in and trying to view your own user, and you are not an admin,
      // hit the profile endpoint to get the data
      const profileVersion = isNotEmpty( currentUser )
        && currentUser.id === hash.item;

      const userData = await refresh( hash.item, null, profileVersion, isAdmin( currentUser ) );

      if (
        isNotEmpty( currentUser )
        && isNotEmpty( userData )
        && isNotEmpty( userData.users )
        && itemIsArray( userData.users )
      ) {

        if ( isNotEmpty( hash.panel_tab ) ) {
          setCurrentTab( hash.panel_tab );
        } else {
          // eslint-disable-next-line camelcase
          encodeURLHash( { panel_tab: 'access_and_credentials' } );
          setCurrentTab( 'access_and_credentials' );
        }

        const [ _user ] = userData.users;

        setFields( userData.fields );
        if (
          isNotEmpty( currentUser )
          && isNotEmpty( licenseInfo )
          && hasFeatureAccess( currentUser, licenseInfo, 'f_external_users' )
        ) {
          getExternalUsers( null, handleUsersCallback, [ userData.users ] );
        }


        if ( isNotEmpty( _user ) ) {
          setUser( _user );
        }
      }
    } else {
      backToUsers();
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( currentUser )
    ) {
      onRefresh();
      window.addEventListener( 'hashchange', onRefresh );
    }
    return () => window.removeEventListener( 'hashchange', onRefresh );
  }, [ currentUser ] );

  const onSaveUserDetails = async (
    user,
    isValid,
    fieldStates,
    onSaveCallback,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      const userParams = getUserParams( user, fieldStates );
      let userRequest;

      const includedValues = getFieldValues( fieldStates, 'user' );

      if ( isNotEmpty( userParams?.id ) ) {

        delete userParams.authentication_provider_id;

        const profileVersion = isNotEmpty( currentUser )
          && currentUser.id === userParams.id;

        if ( profileVersion ) {
          delete userParams.id;
          userRequest = await makeRequest( 'PUT', 'fe/profile/UPDATE', userParams );
        } else {
          userRequest = await makeRequest( 'PUT', '/fe/user/UPDATE', [ userParams ] );
        }

      // this is a create
      } else {
        userRequest = await makeRequest( 'PUT', '/fe/user/INSERT', [ userParams ] );
      }

      // success
      if ( isNotEmpty( userRequest ) ) {
        addFlashMessage( {
          body: 'Successfully saved user',
          type: 'success',
        } );
        if ( user && user.id === currentUser.id ) {
          setCurrentUser( userParams );
        }

        // if the user also mapped to an external user, need to send that request now as well before finishing
        // need to check for it this way because the default value is the null literal and it breaks other checks
        if (
          Object.keys( includedValues ).includes( 'mapped_user_id' )
        ) {

          let externalID;
          let webID;

          const records = [];

          // if we are removing the external mapping, then we need to do some tricky logic to clear it out.
          if ( includedValues.mapped_user_id === null && isNotEmpty( externalUsers ) ) {
            // need to find what the original mapping was and then clear out this user id,
            // (rather than the other way around)
            // eslint-disable-next-line max-len
            const externalUser = Object.values( externalUsers ).find( eu => eu.web_user_id === userRequest.results[0].id );

            if ( isNotEmpty( externalUser ) ) {
              externalID = externalUser.id;
            }
            webID = null;

            if ( isNotEmpty( externalID ) ) {
              // eslint-disable-next-line camelcase
              records.push( { id: externalID, web_user_id: webID } );
            }
          // if we are changing the mapped user, we need to clear out the old mapping and additionally add this mapping
          // again, tricky lookup logic becuase it is backwards
          } else if ( isNotEmpty( user ) && user.mapped_user_id !== includedValues.mapped_user_id ) {
            // need to find what the original mapping was and then clear out this user id,
            // (rather than the other way around)
            // eslint-disable-next-line max-len
            const originalMapping = Object.values( externalUsers ).find( eu => eu.id === user.mapped_user_id );
            // clear out the old mapping
            if ( isNotEmpty( originalMapping ) ) {
              // eslint-disable-next-line camelcase
              records.push( { id: originalMapping.id, web_user_id: null } );
            }
            // create the new mapping
            externalID = includedValues.mapped_user_id;
            webID = userRequest.results[0].id;
            // eslint-disable-next-line camelcase
            records.push( { id: externalID, web_user_id: webID } );
          }

          if ( isNotEmpty( records ) ) {
            const mappedUsersResponse = await makeRequest( 'UPDATE', '/model/base/external_users', { records } );

            if ( isNotEmpty( mappedUsersResponse ) && isNotEmpty( mappedUsersResponse.results ) ) {
              addFlashMessage( {
                type: 'success',
                body: 'Successfully mapped users',
              } );
              onRefresh();
              onSaveCallback();
            } else {
              addFlashMessage( {
                type: 'alert',
                // eslint-disable-next-line max-len
                body: 'There was an error mapping users, please make sure you have entered all of the details correctly',
              } );
            }
          } else {
            onRefresh();
            onSaveCallback();
          }
        } else {
          onRefresh();
          onSaveCallback();
        }
      // there are errors
      } else if ( userRequest.errors ) {
        userRequest.errors.map( e => {
          addFlashMessage( {
            type: 'alert',
            body: e,
          } );
        } );
      // not a correctly formatted response, likely a 500
      } else {
        addFlashMessage( {
          body: 'There was an error saving, please check the form values and try again.',
          type: 'alert',
        } );
      }
    }
  };

  const backToUsers = () => {
    setShowUserDetails( false );
    window.location.href = '#.=setup&page=users';
    refreshAllUsers();
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( fields ) &&
        <FormModal
          recordType="user"
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          isValid={isValid}
          setIsValid={setIsValid}
          selectedRecord={user}
          setSelectedRecord={setUser}
          allowEnterSubmit
          onSave={onSaveUserDetails}
          fields={fields}
          modalClass="twoColumn"
          onRefresh={onRefresh}
          users={users}
          externalUsers={externalUsers}
        />
      }
      {
        isNotEmpty( user ) &&
        <React.Fragment>
          {
            isAdmin( currentUser ) &&
            <PageCreateButton placement="left" >
              <button className="showAllUsersButton" onClick={ backToUsers }>
                <InlineSVG type="carretLeft" />
                <span>All Users</span>
              </button>
            </PageCreateButton>
          }
          <PageHeader elementClass="userDetails_pageHeader">
            <div className="left">
              <UserDetails user={user} />
              <h2>
                { userDisplayName( user ) }
                <RatingBadge rating={ ACLRoleLabelMap[user?.acl_role] }/>
              </h2>
            </div>
            <div className="right">
              <button className="showAllUsersButton" onClick={ () => setCurrentTab( 'details_and_preferences' ) }>
                <InlineSVG type="edit" />
                <span>Edit Details</span>
              </button>
            </div>
          </PageHeader>
          <div className="userDetails__MainPanel">
            <div
              // eslint-disable-next-line max-len
              className={ `mainPanelTabsContainer tabCount--${Object.keys( tabs ).length} ${ isNotEmpty( currentTab ) ? `selectedTabIndex--${ Object.keys( tabs ).indexOf( currentTab )}`: 'noSelectedTab' }`}
            >
              {
                Object.entries( tabs ).map( ( [ tabKey, tabLabel ] ) => {
                  return <div
                    // eslint-disable-next-line max-len
                    className={ `${ currentTab === tabKey ? 'isCurrent' : ''} mainPanelTab ${tabKey}`} key={tabKey}
                    onClick={ () => handleTabClick( tabKey ) }
                  >
                    <div className="iconLabelAndStatusWrapper">
                      <span>
                        { iconForTab( tabKey ) }
                        { tabLabel }
                      </span>
                    </div>
                  </div>;
                } )
              }
            </div>
            <div className={ `userDetails__MainPanel__Body ${currentTab}` }>
              {
                currentTab === 'access_and_credentials' &&
                <AccessAndCredentials user={user} onRefresh={onRefresh} />
              }
              {
                currentTab === 'details_and_preferences' &&
                <DetailsAndPreferences user={user} onRefresh={onRefresh} />
              }
            </div>
          </div>
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default UserDetail;