/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// this page exists so that external application can attempt o query DeepSurface for a record.
// 1. First the page will read all params and look for identifiers that DeepSurface can use to query for a record.
// 2. The available params are:
//    -- host_name
//    -- host_ip_address
//    -- host_mac_address
// 3. The back end can actually accept mutiple values for each, but we will only expose a max of each for one.
// 4. The page will then attempt to query DeepSurface for the record.
// 5. If the record is found, the page will redirect to the record details page.
// 6. If the record is not found, the page will redirect to hosts index page with a message saying no
// record could be found.
// 7. If the params return multiple possible matches, it will redirect to the hosts index page with a message
// saying multiple records were found.
// 8. If the params are not found, it will redirect to the hosts index page with a message saying that some
// form of identifier is required.

import React from 'react';
import { decodeURLHash, isNotEmpty, serializeURLHash } from '../../../../shared/Utilities';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { getRecords, getRecordsCount } from '../../../../shared/RecordCache';

const ExternalLandingPage = ( ) => {

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const attemptToFetchRecords = async() => {

    const hash = decodeURLHash();

    const params = {
      // eslint-disable-next-line camelcase
      order_by: [ [ 'filtered_risk', 'DESC' ] ],
      columns: [
        'has_host',
        'filtered_risk',
        'risk_rating',
        'local_name',
        'num_sensitive_nodes',
        'num_patches',
        'num_unsuperseded_patches',
        'num_vulnerabilities',
        'last_scanned',
      ],
      rows: [ 0, 100 ],
      // eslint-disable-next-line camelcase
      risk_type: 'direct_risk',
      filters: {},
      // eslint-disable-next-line camelcase
      group_filters: {},
    };

    const _toSerialize = {};

    if ( isNotEmpty( hash.host_name ) ) {
      // eslint-disable-next-line camelcase
      params.filters.host_names = [ hash.host_name ];
      // eslint-disable-next-line camelcase
      _toSerialize.host_names = [ hash.host_name ];
    }
    if ( isNotEmpty( hash.host_ip_address ) ) {
      // eslint-disable-next-line camelcase
      params.filters.host_ip_addresses = [ hash.host_ip_address ];
      // eslint-disable-next-line camelcase
      _toSerialize.host_ip_addresses = [ hash.host_ip_address ];
    }
    if ( isNotEmpty( hash.host_mac_address ) ) {
      // eslint-disable-next-line camelcase
      params.filters.host_mac_addresses = [ hash.host_mac_address ];
      // eslint-disable-next-line camelcase
      _toSerialize.host_mac_addresses = [ hash.host_mac_address ];
    }

    const _count = await getRecordsCount( 'host', params );
    const _records = await getRecords( 'host', params, true, true );

    // found exactly one record, redirect to the record details page
    if ( isNotEmpty( _count ) && _count === 1 && isNotEmpty( _records ) ) {
      window.location.href = `#.=risk_insight&report=hosts&item=${_records[0].id}`;
    // found multiple records, redirect to the hosts index page with message
    } else if ( isNotEmpty( _count ) && _count > 1 ) {
      const serializedHash = serializeURLHash( _toSerialize );
      // redirect to the hosts index page with message
      window.location.href = `#.=risk_insight&report=hosts&${serializedHash}`;
      addFlashMessage( {
        // eslint-disable-next-line max-len
        body: 'Multiple records were found based on the identifiers that were provided. Please select the correct host from the list below.',
        type: 'info',
      } );
    // found no records, redirect to hosts index page with message
    } else {
      const serializedHash = serializeURLHash( _toSerialize );
      // redirect to the hosts index page with message
      window.location.href = `#.=risk_insight&report=hosts&${serializedHash}`;
      addFlashMessage( {
        // eslint-disable-next-line max-len
        body: 'No record was found based on the identifiers that were provided. Please provide a valid host name, IP address, or MAC address.',
        type: 'alert',
      } );
    }
  };

  React.useEffect( () => {
    const hash = decodeURLHash();

    const onCorrectPage = isNotEmpty( hash ) && hash.report === 'external_host_landing_page';

    // hash must have at least one of these keys
    const requiredParamKeys = [
      'host_name',
      'host_ip_address',
      'host_mac_address',
    ];

    if ( onCorrectPage ) {
      // at least one of the required params has been provided, attempt to query for a record
      if ( requiredParamKeys.some( key => isNotEmpty( hash[key] ) ) ) {
        attemptToFetchRecords();
      // missing required param, redirect to host index page with message
      } else {
        window.location.href = '#.=risk_insight&report=hosts';
        addFlashMessage( {
          // eslint-disable-next-line max-len
          body: 'No identifier was provided to search for a host record. Please provide a host name, IP address, or MAC address.',
          type: 'alert',
        } );
      }
    }
  }, [] );
  return (
    <React.Fragment>
      ExternalLandingPage
    </React.Fragment>
  );
};

export default ExternalLandingPage;