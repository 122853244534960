/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import EmptyState from '../../../../shared/EmptyState';

/* eslint-disable max-len, camelcase */
const categoryDescriptions = {
  deprioritized:      <React.Fragment>
    <p>Items in this category are likely not a significant concern because they can only cause denial-of-service (DoS) conditions, do not appear to be exploitable in this environment, are duplicates of other issues identified through { window.COMPANY_NAME }'s more advanced analysis, or are false positives.
    </p>
    <p>While these items likely do not need to be addressed, they are broken down into several sub-categories for review if desired.</p>
  </React.Fragment>,
  for_review:               <React.Fragment>
    <p>These vulnerabilities may be significant, but could not be included in { window.COMPANY_NAME }'s advanced graph-based threat model due to a lack of complete information or other reasons.  The reasons they have not been included are broken down further into subcategories below.
    </p>
    <p>Please review the items in this category to determine if additional information can be gathered to better prioritize them, or use more traditional methods of prioritization to estimate the potential risk. </p>
  </React.Fragment>,
  unrecognized:             <React.Fragment>
    <p>These vulnerabilities have not yet been assessed by { window.COMPANY_NAME } staff.  Don’t hesitate to contact our support team if there is an item in this set you’re concerned about.</p>
  </React.Fragment>,
  unsupported:              <React.Fragment>
    <p>{ window.COMPANY_NAME } staff have reviewed this issue, but have determined that it cannot be added to customer threat models at this time. See the subcategories for more information on the specific reason for this.</p>
  </React.Fragment>,
  missing_host:             <React.Fragment>
    <p>Your vulnerability scanner reported these instances, but { window.COMPANY_NAME } was not able to discover and/or connect to the hosts in question. Please see any relevant Configuration alerts or Scan logs for specific information.</p>
  </React.Fragment>,
  cannot_model:             <React.Fragment>
    <p>The diversity and complexity of attack scenarios of these vulnerabilities make it very difficult to include these issues in the threat model in a practical and realistic way.</p>
  </React.Fragment>,
  insufficient_information: <React.Fragment>
    <p>There is not enough information provided by the vendor, security researchers, or other sources to fully understand the attack scenarios that the vulnerability permits. If you have more information on these issues that might help us add them to your threat model, please contact our support team.</p>
  </React.Fragment>,
  missing_capability:       <React.Fragment>
    <p>{ window.COMPANY_NAME } does not currently gather enough contextual information to support this vulnerability, but may in the future.  Don’t hesitate to contact our support team if there is an item in this set you’re concerned about and would like us to prioritize our development to add this capability.</p>
  </React.Fragment>,
  dos_only: <React.Fragment>
    <p>These vulnerabilities are strictly denial of service (DoS) flaws that cannot allow an attack to gain additional privileges.  As such, it does not make sense to add these to a computational threat model that relates to chaining attacks and privileges.  Consider cross-referencing the hosts and services affected by issues in this category with any publicly-exposed services to identify the DoS flaws that are most relevant to you.</p>
  </React.Fragment>,
  not_exploitable:          <React.Fragment>
    <p>These issues can allow an attacker to gain additional privileges in certain circumstances, but in your environment, the specific instances of vulnerabilities in this category do not appear to be exploitable based on your current configuration and software usage patterns.  This could change as the environment and user behavior changes over time, but at this moment these issues are not presenting any significant risk.</p>
  </React.Fragment>,
  overridden:               <React.Fragment>
    <p>These items were reported by your vulnerability scanner, but are also detected directly by { window.COMPANY_NAME } with more detail and analysis.  For this reason, these issues are excluded from the model. Please refer to the vulnerability identifier indicated in the "Public Notes" field to determine how they were mapped to the threat model using the improved analysis offered by { window.COMPANY_NAME }.</p>
  </React.Fragment>,
  prioritized:          <React.Fragment>
    <p>Items in this top-level category were included in the { window.COMPANY_NAME } graph-based computational threat model, allowing for advanced prioritization of business risk.  These are the only items included in the Hosts, Patches, and Vuln. Analysis Risk Insights reports.</p>
  </React.Fragment>,
  unreachable:              <React.Fragment>
    <p>While these instances were included in the threat model, path analysis revealed no specific breach scenarios that are likely to leverage the flaws in a realistic attack.  This could happen for a variety of reasons, including:</p>
    <ul>
      <li>An attacker has no known way of gaining the level of privilege necessary to exploit these vulnerability instances.</li>
      <li>While an attacker may be able to gain the level of privilege necessary to exploit the flaw, the resulting level of access gained would not help the attacker gain access to a sensitive asset.</li>
      <li>An attacker may be able to gain the level of privilege necessary to exploit the flaw, but in all cases the level of privilege gained by doing so would be less than the attacker already has (e.g. it doesn’t make sense for a domain administrator to exploit a local privilege escalation flaw when they already have full control over a system).</li>
    </ul>
  </React.Fragment>,
  carries_risk:             <React.Fragment>
    <p>These instances of vulnerabilities were included in the threat model and were also identified as being useful to an attacker in a realistic attack scenario.  Therefore, these issues contribute to the overall risk of a breach.</p>
  </React.Fragment>,
};
/* eslint-enable max-len, camelcase */

import {
  capitalize,
  categoryLabelMap,
  decodeURLHash,
  encodeURLHash,
  isNotEmpty,
  triggerHashRefresh,
  formatNumber,
} from '../../../../shared/Utilities';
import { getThemeColor } from '../../../../shared/Themes';

const Icicle = ( {
  visualData,
  onRefresh,
} ) => {
  const [ allEmpty, setAllEmpty ] = React.useState( true );
  const [ categoryData, setCategoryData ] = React.useState( null );

  const palette = {
    /* eslint-disable camelcase */
    // greens
    deprioritized: getThemeColor( '--status-success' ),
    dos_only: getThemeColor( '--status-success--75' ),
    not_exploitable: getThemeColor( '--status-success--50' ),
    overridden: getThemeColor( '--status-success--25' ),

    // light reds
    for_review: getThemeColor( '--status-alert--60' ),
    unsupported: getThemeColor( '--status-alert--20' ),
    unrecognized: getThemeColor( '--status-alert--40' ),
    missing_host: getThemeColor( '--status-alert--60' ),
    cannot_model: getThemeColor( '--status-alert--10' ),
    insufficient_information: getThemeColor( '--status-alert--10' ),
    missing_capability: getThemeColor( '--status-alert--20' ),


    // Reds
    prioritized: getThemeColor( '--status-alert' ),
    unreachable: getThemeColor( '--status-alert--60' ),
    carries_risk: getThemeColor( '--status-alert--80' ),
    /* eslint-enable camelcase */
  };

  const needsDarkLabel = [
    'dos_only',
    'not_exploitable',
    'overridden',
    'cannot_model',
    'insufficient_information',
    'unsupported',
    'missing_capability',
  ];

  React.useEffect( () => {
    if ( isNotEmpty( categoryData ) && isNotEmpty( categoryData.children ) ) {
      const allValues = [];

      const getValue = item => {
        allValues.push( item.value || 0 );
        if ( item.children ) {
          item.children.map( child => {
            getValue( child );
          } );
        }
      };

      categoryData.children.map( child  => {
        getValue( child );
      } );

      if ( allValues.every( v => v === 0 ) ) {
        setAllEmpty( true );
      } else {
        setAllEmpty( false );
      }
    }
  }, [ categoryData ] );

  let _x, _y;

  const offset = 16;

  const setCategory = ( e, category ) => {
    const existingCategory = decodeURLHash()['category'] || '';

    if ( category === existingCategory ) {
      return true;
    }

    e.preventDefault();
    e.stopPropagation();
    encodeURLHash( { category: category } );
    triggerHashRefresh();
    onRefresh();
  };

  const IcicleLabel = ( { item, floatingLabelID, showFloatingLabel, x, y } ) => {

    const labelRoot = document.getElementById( 'icicleChartCategoryLabelPortal' );

    return ReactDOM.createPortal(
      <div
        id={floatingLabelID}
        className={`categoryLabel ${showFloatingLabel ? 'visible' : ''}`}
        style={{
          top: y,
          left: x,
        }}
      >
        <h2>
          <span className="labelColor" style={ { background: palette[item.name] } } />
          { capitalize( categoryLabelMap[item.name] ) }
          <strong>( { formatNumber( item.value ) })</strong>
        </h2>
        <div className="categoryDescription">
          { categoryDescriptions[item.name] }
        </div>
      </div>,
      labelRoot,
    );
  };

  const IcicleItem = ( { item, index, total, topLevel=false } ) => {

    const [ showInlineLabel, setShowInlineLabel ] = React.useState( false );
    const [ showFloatingLabel, setShowFloatingLabel ] = React.useState( false );

    const [ x, setX ] = React.useState( 100000000 );
    const [ y, setY ] = React.useState( 100000000 );

    let columnHeight;

    const labelID = `${item.name}_inlineLabel`;
    const floatingLabelID = `${item.name}_floatingLabel`;
    const itemID = `${item.name}_icicleItem`;

    if ( item.tier === 1 ) {
      columnHeight = '100%';
    }
    if ( item.tier === 2 ) {
      columnHeight = '66.6666667%';
    }
    if ( item.tier === 3 ) {
      columnHeight = '50%';
    }
    // eslint-disable-next-line max-len
    const columnWidth =  ( item.parentValue === 0 || item.value === 0 ) ? '0%' : `${( item.value / item.parentValue ) * 100}%`;

    const handleMouseLeave = () => {
      setShowFloatingLabel( false );
      setX( 100000000 );
      setY( 100000000 );
    };

    const handleMouseMove = e => {
      const wrapperEl = document.getElementById( floatingLabelID );
      const container = document.getElementById( 'pageContent' );
      if (
        !wrapperEl
        || !container
        || ( Number.isNaN( wrapperEl.innerWidth ) && Number.isNaN( wrapperEl.innerHeight ) )
      ) {
        console.log( 'doing nothing' );
      } else {
        if ( e.pageX + wrapperEl.clientWidth + offset > window.innerWidth ) {
          _x = e.pageX - offset - wrapperEl.clientWidth;
        } else {
          _x = e.pageX + offset;
        }
        if ( _x <= 0 ) {
          _x = offset;
        }
        // floating label will be 16px above the cursor
        if ( e.pageY + wrapperEl.clientHeight + offset > ( container.clientHeight ) ) {
          _y = e.pageY - wrapperEl.clientHeight - offset;
        // floating label will be 16px below the cursor
        } else {
          _y = e.pageY + offset;
        }
        // floating label will be 16px below the top of the screen
        if ( _y <= 0 ) {
          _y = offset;
        }
      }

      setX( _x );
      setY( _y );
      setShowFloatingLabel( true );
    };

    React.useEffect( () => {
      if ( document.getElementById( labelID ).clientWidth < document.getElementById( itemID ).clientWidth ) {
        setShowInlineLabel( true );
      } else {
        setShowInlineLabel( false );
      }
    }, [] );
    return (
      <div
        // eslint-disable-next-line max-len
        className={ `${topLevel && index === 0 ? 'firstColumn' : '' } ${topLevel && index === total - 1 ? 'lastColumn' : '' } icicleColumnWrapper` }
        style={{
          width: columnWidth,
          height: columnWidth === '0%' ? '0%' : columnHeight,
        }}
      >
        <div
          id={itemID}
          className={ `${item.name} icicleItem ${ topLevel ? 'firstItem' : '' }` }
          onMouseMove={ handleMouseMove }
          onMouseLeave={ handleMouseLeave }
          onClick={ e => setCategory( e, item.name )}
          style={{
            // eslint-disable-next-line max-len
            height: `${ item.parentValue === 0 ? '0' : ( 1 / ( ( categoryData.totalTiers + 1 ) - item.tier ) ) * 100}%`,
            background: palette[item.name],
          }}
        >
          <IcicleLabel
            item={item}
            floatingLabelID={floatingLabelID}
            showFloatingLabel={showFloatingLabel}
            x={x}
            y={y}
          />
          <div
            id={labelID}
            // eslint-disable-next-line max-len
            className={ `${needsDarkLabel.includes( item.name ) ? 'dark' : '' } ${showInlineLabel ? 'visible' : '' } inlineLabel` }
          >
            <strong>{ capitalize( categoryLabelMap[item.name] ) }</strong>
            <span>{ item?.value?.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' ) }</span>
          </div>
        </div>
        {
          isNotEmpty( item.children ) &&
          <React.Fragment>
            {
              item.children.map( ( child, index ) => {
                return  <IcicleItem item={child} key={index} index={index} total={ item.children.length } />;
              } )
            }
          </React.Fragment>
        }
      </div>
    );
  };

  React.useEffect( () => {
    if ( isNotEmpty( visualData ) && isNotEmpty( visualData.tally ) && isNotEmpty( visualData.tally.results ) ) {
      const _categoryData = {
        name: 'all',
        totalTiers: 3,
        parentValue: visualData.tally.results.category['deprioritized']
          + visualData.tally.results.category['prioritized']
          + visualData.tally.results.category['for_review'],
        children: [
          {
            name: 'deprioritized',
            tier: 1,
            value: visualData.tally.results.category['deprioritized'],
            parentValue: visualData.tally.results.category['deprioritized']
              + visualData.tally.results.category['prioritized']
              + visualData.tally.results.category['for_review'],
            children: [
              {
                name: 'dos_only',
                tier: 2,
                value: visualData.tally.results.category['dos_only'],
                parentValue: visualData.tally.results.category['deprioritized'],
              },
              {
                name: 'not_exploitable',
                tier: 2,
                value: visualData.tally.results.category['not_exploitable'],
                parentValue: visualData.tally.results.category['deprioritized'],
              },
              {
                name: 'overridden',
                tier: 2,
                value: visualData.tally.results.category['overridden'],
                parentValue: visualData.tally.results.category['deprioritized'],
              },
            ],
          },
          {
            name: 'for_review',
            tier: 1,
            value: visualData.tally.results.category['for_review'],
            parentValue: visualData.tally.results.category['deprioritized']
              + visualData.tally.results.category['prioritized']
              + visualData.tally.results.category['for_review'],
            children: [
              {
                name: 'unsupported',
                tier: 2,
                value: visualData.tally.results.category['unsupported'],
                parentValue: visualData.tally.results.category['for_review'],
                children: [
                  {
                    name: 'cannot_model',
                    tier: 3,
                    value: visualData.tally.results.category['cannot_model'],
                    parentValue: visualData.tally.results.category['unsupported'],
                  },
                  {
                    name: 'insufficient_information',
                    tier: 3,
                    value: visualData.tally.results.category['insufficient_information'],
                    parentValue: visualData.tally.results.category['unsupported'],
                  },
                  {
                    name: 'missing_capability',
                    tier: 3,
                    value: visualData.tally.results.category['missing_capability'],
                    parentValue: visualData.tally.results.category['unsupported'],
                  },
                ],
              },
              {
                name: 'unrecognized',
                tier: 2,
                value: visualData.tally.results.category['unrecognized'],
                parentValue: visualData.tally.results.category['for_review'],
              },
              {
                name: 'missing_host',
                tier: 2,
                value: visualData.tally.results.category['missing_host'],
                parentValue: visualData.tally.results.category['for_review'],
              },
            ],
          },
          {
            name: 'prioritized',
            tier: 1,
            value: visualData.tally.results.category['prioritized'],
            parentValue: visualData.tally.results.category['deprioritized']
              + visualData.tally.results.category['prioritized']
              + visualData.tally.results.category['for_review'],
            children: [
              {
                name: 'unreachable',
                tier: 2,
                value: visualData.tally.results.category['unreachable'],
                parentValue: visualData.tally.results.category['prioritized'],
              },
              {
                name: 'carries_risk',
                tier: 2,
                value: visualData.tally.results.category['carries_risk'],
                parentValue: visualData.tally.results.category['prioritized'],
              },
            ],
          },
        ],
      };
      setCategoryData( _categoryData );
    }
  }, [ visualData ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( categoryData ) ) &&
        <div
          id="chartWrapper"
          onClick={ e => setCategory( e, '' ) }
        >
          {
            allEmpty
              ? <EmptyState message="Your filters did not return any results" />
              : <React.Fragment>
                {
                  categoryData.children.map( ( item, index ) => {
                    return  <IcicleItem
                      item={item}
                      key={index}
                      index={index}
                      total={categoryData.children.length}
                      topLevel
                    />;
                  } )
                }
              </React.Fragment>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default Icicle;