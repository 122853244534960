/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import { isNotEmpty, riskToRating } from '../../../../../shared/Utilities';
import { nameForPlan, percentComplete } from '../../../../Remediation/RemediationPlans/Shared';

import './RemediationPlans.scss';
import Legend from '../../../../../shared/Charts/Legend';
import RatingBadge from '../../../../../shared/RatingBadge';
import EmptyState from '../../../../../shared/EmptyState';
import { getThemeColor } from '../../../../../shared/Themes';

const RemediationPlans = ( { item, data, settings } ) => {

  const [ chartData, setChartData ] = React.useState( null );

  const legendData = {
    risk: {
      label: 'Risk Reduction',
      key: 'risk',
      fill: getThemeColor( 'filter--teal' ),
    },
    instances: {
      label: 'Instances Reduction',
      key: 'instances',
      fill: getThemeColor( 'filter--blue' ),
    },
  };

  const setupData = async ( data ) => {
    const { plans } = data;

    if ( isNotEmpty( plans ) && isNotEmpty( plans.results ) ) {
      const _plans = {};
      const _chartData = {};
      const _yLabels = [];

      plans.results.map( plan => {
        if ( plan.status === 'active' || plan.status === 'draft' ) {

          _plans[plan.id] = plan;

          const planRisk = plan.risk;
          const planInstances = plan.num_instances;

          _yLabels.push( nameForPlan( plan ) );

          _chartData[plan.id] = {
            label: nameForPlan( plan ),
            original: plan,
            series: [
              {
                name: 'Risk',
                fill: getThemeColor( 'filter--teal' ),
                value: percentComplete( plan.original_risk, planRisk ),
                original: plan,
              },
              {
                name: 'Instances',
                fill: getThemeColor( 'filter--blue' ),
                value: percentComplete( plan.original_num_instances, planInstances ),
                original: plan,
              },
            ],
          };
        }
      } );
      setChartData( _chartData );
    }

  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) ) {
      setupData( data );
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( chartData )
          ? <React.Fragment>
            <div id={ `remediationChartWrapper-${item.i}` } className="remediationChartWrapper" >
              {
                Object.values( chartData ).map( ( planDetails, index ) => {
                  return <div className="remediationPlanWrapper" key={index} >
                    <label>
                      <span>{ planDetails.label }</span>
                      <RatingBadge
                        item={planDetails?.original}
                        rating={ riskToRating( planDetails?.original?.risk ) }
                      />
                    </label>
                    <div className="labelAndBarWrapper">
                      <span>{ `${planDetails.series[0].value}%` }</span>
                      <div className="planCompletionWrapper risk">
                        <div
                          className="planCompletionBar"
                          style={{ background: planDetails.series[0].fill, width: `${planDetails.series[0].value}%`} }
                        />
                      </div>
                      <span className="categoryLabelWrapper">Risk</span>
                    </div>
                    <div className="labelAndBarWrapper">
                      <span>{ `${planDetails.series[1].value}%` }</span>
                      <div className="planCompletionWrapper instances">
                        <div
                          className="planCompletionBar"
                          style={{ background: planDetails.series[1].fill, width: `${planDetails.series[1].value}%`} }
                        />
                      </div>
                      <span className="categoryLabelWrapper">Instances</span>
                    </div>
                  </div>;
                } )
              }
            </div>
            <Legend horizontal legendData={ legendData } />
          </React.Fragment>
          : <EmptyState message="No remediation plans available" />
      }
    </React.Fragment>
  );
};

export default RemediationPlans;