/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import InlineSVG from '../../../InlineSVG';

import {
  isNotEmpty,
  itemIsArray,
} from '../../../Utilities';

import './Text.scss';

const Text = ( {
  field,
  formState,
  originalValue,
  onChange,
  setIsFocused,
  existingRecord,
  fieldRef,
} ) => {

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      // see if this was split into an array, if so, join it
      if ( isNotEmpty( field.textOptions ) && field.textOptions.needsSplit && itemIsArray( originalValue ) ) {
        fieldRef.current.value = originalValue.join( ', ' );
      } else {
        fieldRef.current.value = originalValue || '';
      }
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  // when the form changes, need to sync the value
  //  (this is important on forms where the underlying tool changes the fields)
  React.useEffect( ( ) => {
    if (
      isNotEmpty( formState )
      && isNotEmpty( formState.fieldStates )
      && isNotEmpty( formState.fieldStates[field.attribute] )
      && isNotEmpty( field )
    ) {
      // see if this was split into an array, if so, join it
      if ( isNotEmpty( field.textOptions ) && field.textOptions.needsSplit && itemIsArray( originalValue ) ) {
        fieldRef.current.value = formState?.fieldStates[field.attribute].updatedValue.join( ', ' );
      } else {
        fieldRef.current.value = formState?.fieldStates[field.attribute].updatedValue;
      }
    }
  }, [ formState, field ] );

  const generate = () => {
    const newValue =  field.generateOptions.generate();
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
      && isNotEmpty( newValue )
    ) {
      fieldRef.current.value = newValue;
      onChange( field, newValue );
    }
  };

  const handleChange = value => {
    let _value = value;

    // if this is a split field, split the value on commas, spaces, or newlines
    if ( isNotEmpty( field.textOptions ) && field.textOptions.needsSplit ) {
      _value = value.split( /[\s,]+/ );
    }
    onChange( field, _value );
  };

  return (
    <div
      className={ `textFieldWrapper ${isNotEmpty( field.generateOptions ) ? 'withGenerate' : ''}`}
    >
      <input
        ref={fieldRef}
        onFocus={ () => setIsFocused( true ) }
        onBlur={ () => setIsFocused( false ) }
        placeholder={ field.placeholder ? field.placeholder : ''}
        disabled={field.disabled || formState?.fieldStates[field.attribute]?.disabled}
        type={field.type}
        onChange={ e => handleChange( e.target.value ) }
        { ...field.htmlProps }
      />
      {
        isNotEmpty( field.generateOptions )
          ? <button
            className="generateButton"
            onClick={ generate }
          >
            { field.generateOptions.buttonText }
          </button>
          : <InlineSVG type="edit" elementClass="editIcon" />
      }
    </div>
  );
};

export default Text;
