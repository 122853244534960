/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../../legacy/io';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';
import DataTable from '../../../../shared/DataTable';
import Form from '../../../../shared/Form';
import { getFieldValues } from '../../../../shared/Form/Shared';
import InlineSVG from '../../../../shared/InlineSVG';
import Loading from '../../../../shared/Loading';
import IndeterminantPagination, {
  getRowNums,
  pageIterator,
} from '../../../../shared/Pagination/IndeterminantPagination';
import {
  decodeURLHash,
  // decodeURLHash,
  encodeURLHash,
  formatNumber,
  isNotEmpty,
  removeFromURLHash,
} from '../../../../shared/Utilities';

import './PolicyModal.scss';
import {
  scopeTypeOptions,
  buildPolicyRecord,
  conditionalTagLabels,
  userFriendlyNameFor,
  conditionalScopeNameLabels,
  conditionalNodeTypeLabels,
  nodeTypeOptions,
} from './shared';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser.js';
import { canConfigure } from '../../../App/AccessControl.js';

const PolicyModal = ( {
  selectedRecord,
  setSelectedRecord,
  showModal,
  setShowModal,
  onSave,
  assetTagOptions,
  externalLoading,
} ) => {

  const [ fields, setFields ] = React.useState( null );
  const [ updatedRecord, setUpdatedRecord ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );
  // pagination scroll behavior
  const [ scrollToTableTop ] = React.useState( false );
  const [ loading, setLoading ] = React.useState( false );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const EMPTY_FIELDS = {
    label: {
      fields: [
        {
          type: 'text',
          attribute: 'label',
          label: 'Policy Name (Optional)',
          defaultValue: '',
          allowInDemoMode: true,
        },
      ],
    },
    impact: {
      asSentence: true,
      header: 'Impact',
      fields: [
        {
          type: 'number',
          attribute: 'impact',
          label: <React.Fragment>
            Give an impact score of
            <HelpTrigger helpKey="policy_node_impact" />
          </React.Fragment>,
          defaultValue: 100,
          required: true,
          allowInDemoMode: true,
        },
      ],
    },
    scope: {
      asSentence: true,
      header: 'Scope',
      fields: [
        {
          type: 'select',
          attribute: 'scope_name',
          conditionalLabel: { attribute: 'scope_name', labels: conditionalScopeNameLabels },
          defaultLabel: <React.Fragment>
            to
            <HelpTrigger helpKey="policy_scope_type" />
          </React.Fragment>,
          postLabel: 'scopes',
          label: <React.Fragment>
            to
            <HelpTrigger helpKey="policy_scope_type" />
          </React.Fragment>,
          options: scopeTypeOptions,
          defaultValue: 'null',
          allowInDemoMode: true,
        },
        {
          type: 'text',
          attribute: 'scope_label',
          label: <React.Fragment>
            with the wildcard matcher
            <HelpTrigger helpKey="policy_scope_wildcard" />
          </React.Fragment>,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'scope_name', value: [ 'sharedfolder_*' ] },
          type: 'checkbox',
          attribute: 'scope_builtin',
          label: '(Include Default Folders)',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
    tag: {
      asSentence: true,
      header: 'Asset Tag',
      fields: [
        {
          type: 'select',
          attribute: 'asset_tag_id',
          postLabel: 'tag',
          conditionalLabel: { attribute: 'asset_tag_id', labels: conditionalTagLabels },
          label: <React.Fragment>
            that belong to the
            <HelpTrigger helpKey="policy_asset_tag" />
          </React.Fragment>,
          defaultLabel: <React.Fragment>
            that belong to the
            <HelpTrigger helpKey="policy_asset_tag" />
          </React.Fragment>,
          options: {},
          defaultValue: 'null',
          allowBlank: true,
          blankDisplayValue: 'null',
          blankDisplayText: 'Any',
          allowInDemoMode: true,
        },
      ],
    },
    node: {
      asSentence: true,
      header: 'Sensitive Asset',
      fields: [
        {
          includeIf: { attribute: 'scope_name', value: 'sharedfolder_*' },
          type: 'select',
          attribute: 'node_name',
          label: <React.Fragment>
            and have the name
            <HelpTrigger helpKey="policy_node_name" />
          </React.Fragment>,
          options: {
            'read': 'Read',
            'write': 'Write',
          },
          defaultValue: 'read',
          allowInDemoMode: true,
        },
        {
          type: 'select',
          attribute: 'node_type',
          conditionalLabel: { attribute: 'node_type', labels: conditionalNodeTypeLabels },
          label: <React.Fragment>
            and are
            <HelpTrigger helpKey="policy_node_type" />
          </React.Fragment>,
          postLabel: 'asset type',
          options: nodeTypeOptions,
          defaultValue: 'null',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'scope_name', value: [ 'mssql_*', 'mysqld', 'null' ] },
          type: 'text',
          attribute: 'node_label',
          label: <React.Fragment>
            and include the wildcard matcher
            <HelpTrigger helpKey="policy_node_wildcard" />
          </React.Fragment>,
          defaultValue: '',
          allowInDemoMode: true,
        },
        {
          includeIf: { attribute: 'scope_name', value: [ 'mssql_*', 'mysqld' ] },
          type: 'checkbox',
          attribute: 'node_builtin',
          label: '(Include Default Databases)',
          defaultValue: false,
          allowInDemoMode: true,
        },
      ],
    },
  };

  // on load need to fetch the existing asset_tags (if any) and then setup the fields for the form.
  React.useEffect( ( ) => {

    if ( showModal ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { current_page: 1, item_count: 100 } );
      const tagField = EMPTY_FIELDS.tag.fields.find( f => f.attribute === 'asset_tag_id' );
      if ( isNotEmpty( tagField ) && isNotEmpty( assetTagOptions ) ) {
        tagField.options = assetTagOptions;
      }

      setFields( EMPTY_FIELDS );
    } else {
      removeFromURLHash( 'current_page' );
      removeFromURLHash( 'item_count' );
      setSelectedRecord( null );
      setUpdatedRecord( null );
    }
  }, [ assetTagOptions, showModal ] );

  // when the modal closes, clear out the results of the policy
  React.useEffect( () => {
    if ( showModal === false ) {
      setCurrentPageNumber( 1 );
      setCurrentPageResults( [] );
      setNextPageResults( [] );
    }
  }, [ showModal ] );


  // passed in as the callback to the Form component, takes the values from the fields and outputs a more user-friendly
  // sentence explaining what the applied filters will do.
  const onFormChange = form => {
    if ( isNotEmpty( form ) && isNotEmpty( form.fieldStates ) ) {
      const values = getFieldValues( form.fieldStates, 'sensitive_asset_policy' );
      setUpdatedRecord( values );
    }
  };

  // when clicking the test policy button, shows results of what would currently be returned by the given policy
  const testPolicy = async () => {

    const hash = decodeURLHash();

    console.log( hash.current_page );

    setCurrentPageNumber( hash.current_page );

    setLoading( true );
    setCurrentPageResults( [] );
    setNextPageResults( [] );
    // eslint-disable-next-line camelcase
    // encodeURLHash( { current_page: page } );

    // eslint-disable-next-line camelcase
    const filterValues = { current_page: hash.current_page, item_count: 100 };
    const recordParams = buildPolicyRecord( selectedRecord, isValid, updatedRecord );

    const params =  {
      // eslint-disable-next-line camelcase
      asset_tag_id: recordParams.asset_tag_id,
      criteria: recordParams.criteria,
      // eslint-disable-next-line camelcase
      order_by: [ [ 'label', 'ASC' ] ],
      rownums: getRowNums( filterValues ),
    };

    const testPolicyResponse = await makeRequest( 'COMPUTE', '/model/base/sensitive_asset_policy', params );

    setLoading( false );

    if ( isNotEmpty( testPolicyResponse ) && isNotEmpty( testPolicyResponse.results ) ) {
      const pageResults = pageIterator( testPolicyResponse.results, filterValues );

      setCurrentPageResults( adjustPageResults( pageResults.firstPage ) );
      setNextPageResults( adjustPageResults( pageResults.secondPage ) );
    }
  };

  // whenever the results change, adjust the data for display in the table
  const adjustPageResults = results => {
    if ( results ) {
      return results.map( transformRowData );
    }
  };

  const transformRowData = row => {
    return {
      id: row.id,
      scope: row.ancestor_labels ? row.ancestor_labels.join( ' > ' ) : 'N/A',
      // eslint-disable-next-line max-len, camelcase
      asset_name: <a className={ `inlineLink newTabLink ${ window.IS_DARK_MODE ? 'darkMode' : '' }` } href={`#.=explore&page=explore_model&explore_scope=${ encodeURIComponent( JSON.stringify( [ row.scope_id ] ) ) }&selected=${row.id}`} target="_blank" rel="noreferrer noopener" >
        { row.label }
        <InlineSVG type="newTabLink" version="primary" />
      </a>,
      // eslint-disable-next-line camelcase
      asset_type: row.type ? userFriendlyNameFor( row.type ) : 'N/A',
    };
  };

  const onSaveCallback = () => {
    setShowModal( false );
    removeFromURLHash( 'selected_record' );
    removeFromURLHash( 'item_count' );
    removeFromURLHash( 'current_page' );
  };

  return (
    <React.Fragment>
      <p className="tableDescription">
        A sensitive asset policy lets you define a set of criteria (via filters) that will apply to a group of
        assets. The policy will automatically apply the impact score you define to any assets that match the criteria
        as they are discovered. You can create multiple policies, and they will be applied in order of priority. To
        change the priority order, you can drag and drop the policies in the list.
      </p>
      { externalLoading && <Loading text="Saving Policy..." /> }
      {
        isNotEmpty( fields ) &&
        <div className="filterBuilder">
          <Form
            fields={fields}
            trackUpdates={false}
            recordType="sensitive_asset_policy"
            onChangeCallback={ onFormChange }
            existingRecord={selectedRecord}
            setIsValid={setIsValid}
          />
          <button
            className="testPolicyButton"
            onClick={ () => testPolicy( 1 ) }
          >
            Test Policy
          </button>
        </div>
      }
      <IndeterminantPagination
        currentPageNumber={currentPageNumber}
        nextPageResults={nextPageResults}
        elementClass="policyTestPagination"
        onRefresh={ testPolicy}
      />
      <div className="policyTestTableWrapper">
        {
          ( isNotEmpty( updatedRecord ) && isNotEmpty( currentPageResults ) ) &&
          <div className="tableDescription">
            Once created, this policy would apply to the following
            assets (unless overwritten manually or by another policy with higher precedence) and give
            them an impact score of {formatNumber( updatedRecord?.impact || 0 ) }
          </div>
        }
        <DataTable
          data={currentPageResults}
          elementClass="policyTestDataTable"
          scrollToTableTop={scrollToTableTop}
        />
        { loading && <Loading /> }
      </div>
      <div className="modalActions">
        <button
          disabled={ !isValid || !canConfigure( licenseInfo ) }
          className={`${ ( !isValid || !canConfigure( licenseInfo ) ) ? 'disabled' : ''} submitButton`}
          onClick={ () => onSave(
            selectedRecord,
            isValid,
            updatedRecord,
            onSaveCallback,
          ) }
        >
          Save Changes
        </button>
        <button
          className="cancelButton"
          onClick={ () => setShowModal( false ) }
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

export default PolicyModal;