/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import DataTable from '../../../../shared/DataTable';
import Loading from '../../../../shared/Loading';
import FilterForm from '../../../../shared/FilterForm';
import EmptyState from '../../../../shared/EmptyState';

import './style.scss';

import {
  isNotEmpty,
  isEmpty,
  paramsToFilters,
  encodeURLHash,
  triggerHashRefresh,
  focusForOnboarding,
} from '../../../../shared/Utilities';

import {
  data,
} from './data';

import InlineSVG from '../../../../shared/InlineSVG';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import IndeterminantPagination, {
  getRowNums,
  pageIterator,
} from '../../../../shared/Pagination/IndeterminantPagination';
import EditModal from './EditModal';
import { userFriendlyNameFor } from './shared';
import { getRecord, getRecords, updateRecords } from '../../../../shared/RecordCache';
import PageHeader from '../../../../shared/PageHeader';
import { getNodeIcon } from '../../../RecordDetails/shared';

const Manual = () => {
  const [ sortBy, setSortBy ] = React.useState( 'node_analysis.combined_impact' );
  const [ sortDirection, setSortDirection ] = React.useState( 'DESC' );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  // selection and impact score vars
  const [ selectedRow, setSelectedRow ] = React.useState( {} );
  const [ multipleRowIDs, setMultipleRowIDs ] = React.useState( [] );
  const [ multipleImpactScore, setMultipleImpactScore ] = React.useState( 0 );
  const [ editingMultiple, setEditingMultiple ] = React.useState( false );

  // loading and hide/show vars
  const [ showEditModal, setShowEditModal ] = React.useState( false );
  const [ loadingNodes, setLoadingNodes ] = React.useState( false );

  // ContextualHelp getters and setters
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ editedImpacts, setEditedImpacts ] = React.useState( {} );

  // pagination scroll behavior
  const [ scrollToTableTop ] = React.useState( false );

  const sortableColumns = {
    name: 'label',
    // context: 'ancestor_labels',
    // eslint-disable-next-line camelcase
    impact: 'node_analysis.combined_impact',
    // eslint-disable-next-line camelcase
    asset_type: 'type',
  };

  let isMounted = true;

  React.useEffect( () => {
    isMounted = true;

    const input = data.filterInputs.find( i => i.attribute === 'id_list' );
    input.selectCallback = onResultSelected;

    const elementToFocus = document.getElementById( 'sensitiveAssetFormWrapper' );
    focusForOnboarding( elementToFocus );
    return () => {
      isMounted = false;
    };
  }, [] );

  // whenever the results change, adjust the data for display in the table
  const adjustPageResults = results => {
    if ( results ) {
      return results.map( transformRowData );
    }
  };

  // fires after a host is chosen to limit by
  const onResultSelected = async ( record ) => {
    const ids = [];
    if ( isNotEmpty( record ) ) {
      // eslint-disable-next-line camelcase, max-len
      const scopeRecord = await getRecord( 'scope', record.id, { extra_columns: [ 'scope_analysis.descendants' ] } );
      if ( isNotEmpty( scopeRecord ) ) {
        ids.push( record.id );
        scopeRecord.descendants?.map( d => ids.push( d ) );
      }
    }
    return ids;
  };
  const onRefresh = async() => {
    setMultipleRowIDs( [] );
    setLoadingNodes( true );
    setEditedImpacts( {} );

    const params =  {
      // eslint-disable-next-line camelcase
      field_map: {},
      // eslint-disable-next-line camelcase
      extra_columns: [ 'node_analysis.ancestor_labels', 'node_analysis.combined_impact', 'label', 'impact', 'type' ],
    };

    const filterValues = paramsToFilters();

    // need to do something slightly different for sorting
    // eslint-disable-next-line camelcase
    params.order_by = [
      [
        filterValues.sort_by ? filterValues.sort_by : 'node_analysis.combined_impact',
        filterValues.sort_direction ? filterValues.sort_direction : 'DESC',
      ],
      [ 'id', 'ASC' ],
    ];

    const _rowNums = getRowNums( filterValues );

    Object.entries( filterValues ).map( ( [ key, val ] ) => {

      if ( key === 'id_list' ) {
        if ( isNotEmpty( val ) ) {
          // eslint-disable-next-line camelcase
          params.id_list = [ ...val ];
          // eslint-disable-next-line camelcase
          params.id_field = 'scope_id';
        }
      } else if ( key === 'type' && val !== 'any' ) {
        // eslint-disable-next-line camelcase
        params.field_map = { type: val };
      } else if ( key === 'combined_impact' ) {
        if ( val === 'has_impact' ) {
          // eslint-disable-next-line camelcase
          params.gt_map = { 'node_analysis.combined_impact': 0 };
        }
        if ( val === 'no_impact' ) {
          // eslint-disable-next-line camelcase
          params.field_map = { ...params.field_map, 'node_analysis.combined_impact': 0 };
        }
        if ( val === 'either_impact' ) {
          // eslint-disable-next-line camelcase
          params.field_map = { ...params.field_map };
        }
      } else if ( key === 'keywords' ) {
        params.keywords = val;
      } else if ( key === 'item_count' ) {
        params.rownums = _rowNums;
      }
    } );

    // override id_list if callback_ids are present
    if ( isNotEmpty( filterValues['id_list_callback_ids'] ) ) {
      // eslint-disable-next-line camelcase
      params.id_list = filterValues['id_list_callback_ids'];
    }

    getRecords( 'node', params ).then( response => {
      if ( isMounted ) {
        setLoadingNodes( false );
        const pageResults = pageIterator( response, filterValues );

        setCurrentPageNumber(
          pageResults.currentPageNumber ? parseInt( pageResults.currentPageNumber ) : 1,
        );
        setCurrentPageResults( adjustPageResults( pageResults.firstPage ) );
        setNextPageResults( adjustPageResults( pageResults.secondPage ) );
      }
    } );
  };

  // callback for when the checkbox for a row is checked
  const onSelect = id => {
    if ( multipleRowIDs.includes( id ) ) {
      setMultipleRowIDs( multipleRowIDs.filter( i => i !== id ) );
    } else {
      setMultipleRowIDs( [ ...multipleRowIDs, id ] );
    }
  };

  const editMultipleScores = () => {
    setShowEditModal( true );
    setEditingMultiple( true );
  };

  const addNewScore = ( row, score ) => {
    const _edited = { ...editedImpacts };
    if ( score === row.impact ) {
      delete _edited[row.id];
      setEditedImpacts( _edited );
    } else {
      setEditedImpacts( { ..._edited, [row.id]: score } );
    }
  };

  const getUpdatedImpact = row => {
    if ( isNotEmpty( editedImpacts ) && isNotEmpty( editedImpacts[row.id] ) ) {
      return editedImpacts[row.id];
    }
    return null;

  };

  const getError = row => {
    if ( isNotEmpty( editedImpacts ) && isNotEmpty( editedImpacts[row.id] ) ) {
      if ( editedImpacts[row.id] < 0 ) {
        return 'Impact score must be a positive number';
      }
      return null;

    }
    return null;

  };

  const transformRowData = row => {
    return {
      id: row.id,
      name: <a
        // eslint-disable-next-line max-len
        href={`#.=explore&page=explore_model&explore_scope=${ encodeURIComponent( JSON.stringify( [ row.scope_id ] ) ) }&selected=${row.id}`}
        target="_blank"
        rel="noreferrer noopener"
        className="nodeLink"
      >
        <div className="nodeIconWrapper">
          <svg
            width={ 24 }
            height={ 24 }
            viewBox="0 0 32 32"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            className="recordTypeHeaderIcon"
          >
            { getNodeIcon( row ) }
          </svg>
        </div>
        { row.label }
      </a>,
      context: row.ancestor_labels ? row.ancestor_labels.join( ' > ' ) : 'N/A',
      // eslint-disable-next-line camelcase
      asset_type: row.type ? userFriendlyNameFor( row.type ) : 'N/A',
      impact: row.combined_impact || '',
      actions: <a
        // eslint-disable-next-line max-len
        href={`#.=explore&page=explore_model&explore_scope=${ encodeURIComponent( JSON.stringify( [ row.scope_id ] ) ) }&selected=${row.id}`}
        target="_blank"
        rel="noreferrer noopener"
        className="roundGlyphButton light"
      >
        <InlineSVG type="carretRight" />
      </a>,
    };
  };

  const editImpacts = async ( shouldRefresh=true ) => {
    if ( isNotEmpty( editedImpacts ) ) {
      setLoadingNodes( true );
      const updates = [];
      Object.entries( editedImpacts ).map( ( [ id, impact ] ) => {
        updates.push( { id, impact } );
      } );

      if ( updates.every( edit => edit.impact >= 0 ) ) {
        if (
          isNotEmpty( updates )
          && confirm( `You are about to make updates to ${updates.length} node impact score(s) are you sure?` )
        ) {
          const updatedNodes = await updateRecords( 'node', updates );

          if ( isNotEmpty( updatedNodes ) ) {
            if ( shouldRefresh && isMounted ) {
              addFlashMessage( {
                type: 'success',
                body: 'Successfully updated all impact scores',
              } );
              window.location.reload();
            }
          } else if ( shouldRefresh && isMounted ) {
            setLoadingNodes( false );
            addFlashMessage( {
              type: 'alert',
              body: 'There was an error saving the impact scores',
            } );
          }
        } else {
          setLoadingNodes( false );
        }
      } else {
        setLoadingNodes( false );
        addFlashMessage( {
          type: 'alert',
          body: 'Impact scores must be a positive number',
        } );
      }
    } else if ( shouldRefresh && isMounted ) {
      addFlashMessage( {
        type: 'info',
        body: 'No Impact scores have been updated',
      } );
    }
  };

  // whenever the sort or filters change, kick off a fetch for results
  React.useEffect( () => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { sort_by: sortBy, sort_direction: sortDirection } );
    triggerHashRefresh();
  }, [ sortBy, sortDirection ] );

  return (
    <React.Fragment>
      <EditModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        editingMultiple={editingMultiple}
        multipleImpactScore={multipleImpactScore}
        setMultipleImpactScore={setMultipleImpactScore}
        multipleRowIDs={multipleRowIDs}
        loadingNodes={loadingNodes}
        setLoadingNodes={setLoadingNodes}
        onRefresh={onRefresh}
      />
      <PageHeader elementClass="sensitiveAssetsManualHeaderWrapper" >
        <FilterForm
          inputs={data.filterInputs}
          onRefresh={onRefresh}
          reportType="sensitiveAssets"
          disabled={isNotEmpty( editedImpacts )}
        />
      </PageHeader>
      {
        isNotEmpty( currentPageResults ) ?
          <React.Fragment>
            <div className="riskInsightTableWrapper">
              <div className="tableActions">
                <div className="multipleSelectedActions">
                  <button
                    className={`${multipleRowIDs.length ? '' : 'disabled'}`}
                    disabled={multipleRowIDs.length <= 0}
                    onClick={ editMultipleScores }
                  >
                    {
                      multipleRowIDs.length
                        ? <span>
                        Edit { multipleRowIDs.length } record(s)
                        </span>
                        : <span>
                        Select records to edit
                        </span>
                    }
                  </button>
                </div>
                <IndeterminantPagination
                  currentPageNumber={currentPageNumber}
                  nextPageResults={nextPageResults}
                  elementClass="sensitiveAssetsPagination"
                />
              </div>
              <DataTable
                sortableColumns={sortableColumns}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                data={currentPageResults}
                withSelect
                onSelect={onSelect}
                selectedIDs={multipleRowIDs}
                setSelectedIDs={setMultipleRowIDs}
                elementClass="sensitiveAssetsDataTable"
                scrollToTableTop={scrollToTableTop}
                allowHover
                editable={{
                  impact: {
                    type: 'number',
                    onEdit: addNewScore,
                    updatedValue: getUpdatedImpact,
                    error: getError,
                    htmlProps: { min: 0, step: 10_000 },
                  },
                }}
              />
            </div>
            { loadingNodes && <Loading /> }
          </React.Fragment>
          : <React.Fragment>
            {
              loadingNodes
                ? <div className="loadingWrapperPlaceholder">
                  <Loading />
                </div>
                : <EmptyState message="Your filters did not return any assets" />
            }
          </React.Fragment>
      }
      <div className="formActionsContainer">
        <div className="formActions">
          <button
            disabled={ isEmpty( editedImpacts ) }
            className={ `${isEmpty( editedImpacts ) ? 'disabled' : ''} revertButton` }
            onClick={ () => window.location.reload() }
          >
            revert
          </button>
          <button
            disabled={
              isEmpty( editedImpacts )
              || ( isNotEmpty( editedImpacts ) && Object.values( editedImpacts ).some( edit => edit < 0 ) )
            }
            className={ `${isEmpty( editedImpacts ) ? 'disabled' : ''}` }
            onClick={editImpacts}
          >
            Save
          </button>
        </div>
      </div>
    </React.Fragment>

  );
};

export default Manual;
