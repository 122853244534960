/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import {
  isEmpty,
  isNotEmpty,
} from '../../../Utilities';

import './Checkbox.scss';

const Checkbox = ( {
  field,
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {

  const [ subInputValue, setSubInputValue ] = React.useState( '' );
  const [ checkboxValue, setCheckboxValue ] = React.useState( false );

  // logic to handle checking and and setting subinput value for checkbox with input types
  React.useEffect( () => {
    if ( isNotEmpty( field ) ) {
      if ( isNotEmpty( field.withInput ) ) {
        if ( originalValue === 0 || isNotEmpty( originalValue ) ) {
          setSubInputValue( originalValue );
          setCheckboxValue( true );
        } else {
          setCheckboxValue( false );
          setSubInputValue( field.withInput.defaultValue );
        }
      } else {
        setCheckboxValue( originalValue || false );
        setSubInputValue( '' );
      }
    }
  }, [ field, originalValue, existingRecord, fieldRef ] );

  const handleCheckboxChange = e => {
    setCheckboxValue( e.target.checked );
    if ( isEmpty( field.withInput ) ) {
      onChange( field, e.target.checked );
    } else if ( e.target.checked ) {
      onChange( field, subInputValue );
    } else {
      onChange( field, null );
    }
  };

  const handleSubInputchange = e => {
    setSubInputValue( e.target.valueAsNumber || e.target.value );
    onChange( field, e.target.valueAsNumber || e.target.value );
  };

  return (
    <React.Fragment>
      <label className={ `${field.asToggle ? 'asToggleField' : ''}` }>
        {/* eslint-disable-next-line max-len */}
        <div className={ `checkboxFieldWrapper ${checkboxValue ? 'checked' : ''} ${( field?.disabled || formState?.fieldStates[field.attribute]?.disabled ) ? 'disabled' : ''}` }>
          {
            field.asToggle &&
            <div
              className={ ` ${checkboxValue ? 'checked' : ''} toggleWrapper` }
            >
              <div className="toggleSwitch" />
            </div>
          }
          <input
            disabled={field?.disabled || formState?.fieldStates[field.attribute]?.disabled}
            type="checkbox"
            ref={fieldRef}
            onChange={ handleCheckboxChange }
            checked={ checkboxValue }
            { ...field.htmlProps }
          />
        </div>
        <span className="labelWrapper">
          { field.label }
          {
            field.required &&
            <span className="required">*</span>
          }
          { field.help && field.help }
        </span>
        {
          ( checkboxValue && field.withInput ) &&
          <input
            type={field.withInput.type}
            value={ subInputValue }
            onChange={ handleSubInputchange }
          />
        }
      </label>
    </React.Fragment>
  );
};

export default Checkbox;
