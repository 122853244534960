/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { decodeURLHash, formatNumber, isNotEmpty } from '../Utilities';
import InlineSVG from '../InlineSVG';

import './AttackScenarioCountLink.scss';
import { defaultInstanceVulnerabilityURL } from '../../components/App/Routing';

const AttackScenarioCountLink = ( { recordType='host', attackScenario } ) => {

  const [ href, setHref ] = React.useState( '' );

  React.useEffect( () => {
    if ( isNotEmpty( attackScenario ) && isNotEmpty( recordType ) ) {
      const hash = decodeURLHash();

      const { item } = hash;

      if ( isNotEmpty( item ) && isNotEmpty( attackScenario?.key ) ) {
        if ( attackScenario.key === 'exploitable_vulns_at_risk_count' ) {
          // eslint-disable-next-line max-len
          setHref( `${defaultInstanceVulnerabilityURL}&category=carries_risk&${recordType}_ids=%5B"${item}"%5D&exploit_statuses=%5B"weaponized"%5D&group_gt_map=%7B"risk_reduction"%3A"0"%7D` );
        } else {
          // eslint-disable-next-line max-len
          setHref( `${defaultInstanceVulnerabilityURL}&category=carries_risk&${recordType}_ids=%5B"${item}"%5D&attack_scenario=${attackScenario?.key}&group_gt_map=%7B"risk_reduction"%3A"0"%7D` );
        }
      }
    }
  }, [ recordType, attackScenario ] );
  return (
    <React.Fragment>
      {
        isNotEmpty( href ) &&
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={ `attackScenarioCountLink ${attackScenario?.key} ${window.IS_DARK_MODE ? 'darkMode' : ''}` }
        >
          <div className="iconAndCountWrapper">
            <InlineSVG type={attackScenario?.key} />
            { formatNumber( attackScenario?.value ) }
          </div>
          <div className="labelAndLinkWrapper">
            { attackScenario?.label }
            <InlineSVG type="newTabLink" />
          </div>
        </a>
      }
    </React.Fragment>
  );
};

export default AttackScenarioCountLink;