/** *************************************************************
* Copyright (C) 2016-2025 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Notification from '../../shared/Notification';

import './ResetPassword.scss';
import { isValidPassword } from '../../shared/Form/Validators';
import Form from '../../shared/Form';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import { isNotEmpty } from '../../shared/Utilities';
import { getFieldValues } from '../../shared/Form/Shared';
import { makeRequest } from '../../../legacy/io';
import { FlashMessageQueueContext } from '../../Contexts/FlashMessageQueue';

const ResetPassword = () => {

  const [ fields, setFields ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ updatedUser, setUpdatedUser ] = React.useState( null );
  const [ currentUser ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage ] = React.useContext( FlashMessageQueueContext );

  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) ) {
      setFields( [
        {
          type: 'hidden',
          attribute: 'id',
          defaultValue: currentUser.id,
          value: currentUser.id,
        },
        {
          type: 'password',
          label: 'Please choose a new password',
          attribute: 'password',
          required: true,
          validators: [ isValidPassword ],
          defaultValue: '',
        },
      ] );
    }
  }, [ currentUser ] );

  const handleResetPassword = async () => {
    if ( isNotEmpty( updatedUser ) ) {
      const values = getFieldValues( updatedUser.fieldStates, 'user' );
      if ( isValid && isNotEmpty( values?.id ) && isNotEmpty( values?.password ) ) {
        const resetRequest = await makeRequest( 'PUT', 'fe/profile/UPDATE', {
          password: values.password,
          // eslint-disable-next-line camelcase
          reset_password: false,
        } );
        // success need to re-authenticate and reload the page
        if ( isNotEmpty( resetRequest ) && isNotEmpty( resetRequest.id ) && resetRequest.id === currentUser.id ) {
          const authenticationResponse =  await makeRequest( 'AUTHENTICATE', '/session', {
            username: currentUser.username,
            password: values.password,
          } );

          if ( isNotEmpty( authenticationResponse ) ) {

            if ( isNotEmpty( authenticationResponse.sid ) ) {

              window.localStorage.setItem( 'sid', authenticationResponse['sid'] );
              window.localStorage.setItem( 'sid_expiration', new String( authenticationResponse['expiration'] ) );

              addFlashMessage( {
                body: 'Successfully reset password',
                type: 'success',
              } );
              window.location.reload();
            } else if ( isNotEmpty( authenticationResponse.errors ) ) {
              addFlashMessage( {
                body: 'There was an error resetting your password, please try again',
                type: 'alert',
              } );
            } else {
              addFlashMessage( {
                body: 'There was an error resetting your password, please try again',
                type: 'alert',
              } );
            }
          }
        } else {
          addFlashMessage( {
            body: 'There was an error resetting your password, please try again',
            type: 'alert',
          } );
        }
      }
    }
  };

  return (
    <div id="resetPasswordContainer">
      <div className="resetFormWrapper">
        <Notification
          options={ {
            type: 'info',
            header: 'Password Reset',
            // eslint-disable-next-line max-len
            message: `Your password has been reset by your administrator, please create a new password to continue using ${ window.COMPANY_NAME }.`,
          } }
        />
        {
          isNotEmpty( fields ) &&
          <Form
            fields={ fields }
            recordType="user"
            onChangeCallback={ setUpdatedUser }
            setIsValid={ setIsValid }
          />
        }
        <div className="actions">
          <button
            className={ `resetPasswordButton ${ isValid ? '' : 'disabled' }` }
            onClick={ handleResetPassword }
            disabled={ !isValid }
          >
            Reset Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;