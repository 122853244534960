/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// AccessControl for the Front End that mirrors the Back End ACLs
// This is a simple ACL system that checks the user's role against the route, page, or feature key
// to determine if the user has access to the requested resource
// The ACLs are defined in the aclRouteAccessMap, aclPageAccessMap, and aclFeatureAccessMap objects

/* eslint-disable camelcase */
import {
  isEmpty,
  isNotEmpty,
} from '../../shared/Utilities';

// the main routes of the application and what acl roles have access to them
const aclRouteAccessMap = {
  reporting: [ 'admin', 'report_manager', 'report_consumer' ],
  risk_insight: [ 'admin', 'report_manager', 'report_consumer' ],
  remediation: [ 'admin', 'report_manager', 'report_consumer' ],
  explore: [ 'admin', 'report_manager' ],
  activity: [ 'admin' ],
  scanning: [ 'admin' ],
  setup: [ 'admin', 'report_manager' ],
  about: [ 'admin' ],
  help_documentation: [ 'admin', 'report_manager', 'report_consumer' ],
};

// the pages of the application and what acl roles have access to them
const aclPageAccessMap = {
  reporting: {
    reporting_dashboard: [ 'admin', 'report_manager', 'report_consumer' ],
    exports: [ 'admin', 'report_manager', 'report_consumer' ],
  },

  risk_insight: {
    hosts: [ 'admin', 'report_manager', 'report_consumer' ],
    patches: [ 'admin', 'report_manager', 'report_consumer' ],
    vulnerabilities: [ 'admin', 'report_manager', 'report_consumer' ],
    users: [ 'admin', 'report_manager', 'report_consumer' ],
    instances: [ 'admin', 'report_manager', 'report_consumer' ],
  },

  remediation: {
    remediation_plans: [ 'admin', 'report_manager', 'report_consumer' ],
    accepted_risk_plans: [ 'admin' ],
    remediation_settings: [ 'admin' ],
    remediation_ticketing: [ 'admin' ],
  },

  explore: {
    explore_model: [ 'admin' ],
    explore_paths: [ 'admin', 'report_manager' ],
  },

  activity: {
    tasks: [ 'admin' ],
    configuration_alerts: [ 'admin' ],
    scan_logs: [ 'admin' ],
    notification_settings: [ 'admin' ],
  },

  scanning: {
    scanning_dashboard: [ 'admin' ],
    user_managed: [ 'admin' ],
    agents: [ 'admin' ],
    agentless_settings: [ 'admin' ],
    credentials: [ 'admin' ],
    scan_groups: [ 'admin' ],
    scanning_general_settings: [ 'admin' ],
    vulnerability_scanners: [ 'admin' ],
    cloud_scanning: [ 'admin' ],
    subordinates: [ 'admin' ],
  },

  setup: {
    sensitive_assets_policies: [ 'admin' ],
    sensitive_assets_manual: [ 'admin' ],
    tags: [ 'admin', 'report_manager' ],
    providers: [ 'admin' ],
    users: [ 'admin' ],
    admin: [ 'admin' ],
    smtp: [ 'admin' ],
    certificates: [ 'admin' ],
    outbound_proxy: [ 'admin' ],
  },
};

// the features of the application and what acl roles have access to them
const aclFeatureAccessMap = {
  f_remediation: [ 'admin', 'report_manager' ],
  f_onboarding: [ 'admin' ],
  f_manage_dashboards: [ 'admin', 'report_manager' ],
  f_configuration_alerts: [ 'admin' ],
  f_general_configuration: [ 'admin' ],
  f_tasks: [ 'admin' ],
  f_third_party_settings: [ 'admin', 'report_manager', 'report_consumer' ],
  f_authentication_providers: [ 'admin' ],
  f_deepsurface_scanning: [ 'admin' ],
  f_credentialed_scan: [ 'admin' ],
  f_smtp_configuration: [ 'admin' ],
};

// the features of the application that are controlled by the license
export const licenseFeatureKeys = [
  'f_remediation',
  'f_risk_acceptance',
  'f_general_configuration',
  'f_explore_model',
  'f_credentialed_scan',
  'f_subordinates',
  'f_outbound_proxy',
  'f_custom_dashboards',
  'f_analytics',
  'f_multitenant_cluster',
  'f_insight_instances',
  'f_sensitive_asset_policies',
  'f_user_managed_scan',
  'f_agent_scan',
  'f_activity',
  'f_reporting',
  'f_cloud_integrations',
  'f_third_party_settings',
  'f_third_party',
  'f_third_party_manual',
  'f_customer_api',
  'f_auth_providers',
  'f_smtp_configuration',
  'f_certificate_configuration',
];

// the pages of the application that are controlled by the license feature keys
export const pageToFeatureKeyMap = {
  remediation_plans: 'f_remediation',
  remediation_settings: 'f_remediation',
  remediation_ticketing: 'f_remediation',

  instances: 'f_insight_instances',

  accepted_risk_plans: 'f_risk_acceptance',

  explore_model: 'f_explore_model',

  agentless_settings: 'f_credentialed_scan',
  credentials: 'f_credentialed_scan',
  scan_groups: 'f_credentialed_scan',

  subordinates: 'f_subordinates',

  providers: 'f_auth_providers',

  cloud_scanning: 'f_cloud_integrations',

  smtp: 'f_smtp_configuration',

  certificates: 'f_certificate_configuration',

  outbound_proxy: 'f_outbound_proxy',
  admin: 'f_general_configuration',
  scanning_general_settings: 'f_general_configuration',
  user_managed: 'f_user_managed_scan',
  agents: 'f_general_configuration',
  scanning_dashboard: 'f_credentialed_scan',
  sensitive_assets_manual: 'f_general_configuration',
  sensitive_assets_policies: 'f_sensitive_asset_policies',
  vulnerability_scanners: 'f_third_party',
  users: 'f_general_configuration',
};

// the pages of the application that are controlled by the license feature keys
export const routeToFeatureKeyMap = {
  remediation: 'f_remediation',
  activity: 'f_general_configuration',
};

// check the license for the access level of a feature
export const featureAccessLevel = ( featureKey='', licenseInfo ) => {
  if (
    isNotEmpty( featureKey )
    && licenseFeatureKeys.includes( featureKey )
    && isNotEmpty( licenseInfo )
    && isNotEmpty( licenseInfo.license_effective_edition )
  ) {
    return licenseInfo.license_effective_edition[featureKey];
  }
  return 'enabled';
};

// nice shortcut method for the general configuation featurekey
export const canConfigure = ( licenseInfo ) => {
  return featureAccessLevel( 'f_general_configuration', licenseInfo ) === 'enabled';
};

// nice shortcut method to determine if a user is an admin, a user can either be an admin or be in admin mode which
// is a setting that allows a user to act as an admin
export const isAdmin = ( user ) => {
  if ( isNotEmpty( user ) ) {
    if ( isNotEmpty( window.globalSettings ) ) {
      return user.acl_role === 'admin' || window.globalSettings.assume_admin_role === true;
    }
    return user.acl_role === 'admin';
  }
  return false;
};

// nice shortcut method to determine if a user is a report manager
export const isReportManager = ( user ) => {
  if ( isNotEmpty( user ) ) {
    if ( isNotEmpty( window.globalSettings ) ) {
      return user.acl_role === 'report_manager' && window.globalSettings.assume_admin_role !== true;
    }

    return user.acl_role === 'report_manager';
  }
  return false;
};

// nice shortcut method to determine if a user is a report consumer
export const isReportConsumer = ( user ) => {
  if ( isNotEmpty( user ) ) {
    if ( isNotEmpty( window.globalSettings ) ) {
      return user.acl_role === 'report_consumer' && window.globalSettings.assume_admin_role !== true;
    }

    return user.acl_role === 'report_consumer';
  }
  return false;
};

// determine the user's access level based on their role
export const aclAccessLevel = ( user ) => {
  if ( isAdmin( user ) ) {
    return 'admin';
  }
  if ( isReportManager( user ) ) {
    return 'report_manager';
  }
  return 'report_consumer';
};

// check if the user has access to a route, page, or feature
export const hasACLAccess = ( user, accessRouteKey, accessPageKey, accessFeatureKey, license ) => {
  const role = aclAccessLevel( user );
  let featureFlagAccess = true;

  let hasAccess = false;

  let accessRequestType = 'route';

  if ( isNotEmpty( accessRouteKey ) ) {
    if ( isNotEmpty( accessPageKey ) ) {
      accessRequestType = 'page';
    } else {
      accessRequestType = 'route';
    }
  } else if ( isNotEmpty( accessFeatureKey ) ) {
    accessRequestType = 'feature';
  }

  switch ( accessRequestType ) {
  case 'route':
    if ( isNotEmpty( aclRouteAccessMap[accessRouteKey] ) ) {
      if ( isEmpty( routeToFeatureKeyMap[accessRouteKey] ) ) {
        featureFlagAccess = true;
      } else {
        featureFlagAccess = featureAccessLevel( routeToFeatureKeyMap[accessRouteKey], license ) === 'enabled';
      }

      hasAccess = aclRouteAccessMap[accessRouteKey].includes( role );

    } else {
      hasAccess = false;
    }
    break;
  case 'page':
    if (
      isNotEmpty( aclPageAccessMap[accessRouteKey] )
      && isNotEmpty( aclPageAccessMap[accessRouteKey][accessPageKey] )
    ) {
      hasAccess = aclPageAccessMap[accessRouteKey][accessPageKey].includes( role );
    } else {
      hasAccess = false;
    }
    break;
  case 'feature':
    if ( isNotEmpty( aclFeatureAccessMap[accessFeatureKey] ) ) {
      hasAccess = aclFeatureAccessMap[accessFeatureKey].includes( role );
    } else {
      hasAccess = false;
    }
    break;
  default:
    return false;
  }

  return hasAccess && featureFlagAccess;
};

// check if the user has access to a route
export const hasRouteAccess = ( user, licenseInfo, routeKey ) => {
  return hasACLAccess( user, routeKey, null, null, licenseInfo );
};

// check if the user has access to a page
export const hasPageAccess = ( user, licenseInfo, routeKey, pageKey ) => {
  // first check against the license
  const hasLicenseAccess = featureAccessLevel( pageToFeatureKeyMap[pageKey], licenseInfo ) === 'enabled';
  // early return if the license does not allow
  if ( !hasLicenseAccess ) {
    return false;
  }

  // then check against the user's role
  return hasACLAccess( user, routeKey, pageKey, null );
};

// check if the user has access to a feature
export const hasFeatureAccess = ( user, licenseInfo, featureKey ) => {
  // first check against the license
  const hasLicenseAccess = featureAccessLevel( featureKey, licenseInfo ) === 'enabled';
  // early return if the license does not allow
  if ( !hasLicenseAccess ) {
    return false;
  }

  // then check against the user's role
  return hasACLAccess( user, null, null, featureKey );
};
