/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import { isEmpty, isNotEmpty } from '../../../Utilities';

export const typeMap = type => {
  if ( type === 'scanner_signature' ) {
    return 'signature';
  }
  // if ( type === 'host' ) {
  //   return 'scope';
  // }
  return type;
};

export const searchParamsForResultType = ( type, query, selected, existingRecord ) => {
  let params = {
    keywords: query,
    // eslint-disable-next-line camelcase
    extra_columns: [ 'label', 'identifier', 'vendor', 'vulnerability_analysis.nofix' ],
    rownums: [ 0, 50 ],
  };

  if ( type === 'external_user' ) {
    params = {
      filters: {
        keywords: query,
      },
      // eslint-disable-next-line camelcase
      third_party_setting_id: existingRecord ? existingRecord.third_party_setting_id : null,
    };
  }

  if ( type === 'host' ) {
    params = {
      rows: [ 0, 200 ],
      filters: {
        // eslint-disable-next-line camelcase
        num_sensitive_nodes: null,
        // eslint-disable-next-line camelcase
        accepted_risk: false,
        // eslint-disable-next-line camelcase
        host_keywords: query,
      },
      // eslint-disable-next-line camelcase
      order_by: [ [ 'filtered_risk', 'DESC' ], [ 'local_name', 'ASC' ] ],
    };
  }

  if ( type === 'scope' ) {
    params = {
      ...params,
      // eslint-disable-next-line camelcase
      order_by: [ [ 'scope_analysis.risk', 'DESC' ], [ 'label', 'DESC' ] ],
      // eslint-disable-next-line camelcase
      extra_columns: [ ...params.extra_columns, 'scope_analysis.risk' ],
      // eslint-disable-next-line camelcase
      not_id_list: isNotEmpty( selected ) ? [ ...Object.keys( selected ) ] : [],
    };
  }

  if ( type === 'scanner_signature' ) {
    params = {
      columns: [ 'scanner', 'signature', 'title', 'filtered_risk' ],
      rows: [ 0, 50 ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'filtered_risk', 'DESC' ] ],
      filters: {
        // eslint-disable-next-line camelcase
        signature_keywords: query,
      },
    };
  }

  if ( type === 'patch' ) {
    params = {
      rows: [ 0, 200 ],
      filters: {
        // eslint-disable-next-line camelcase
        patch_keywords: query,
      },
      // eslint-disable-next-line camelcase
      columns: [ ...params.extra_columns, 'filtered_risk' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'filtered_risk', 'DESC' ], [ 'id', 'ASC' ] ],
    };
  }

  if ( type === 'vulnerability' ) {
    params = {
      rows: [ 0, 200 ],
      filters: {
        // eslint-disable-next-line camelcase
        vulnerability_keywords: query,
      },
      // eslint-disable-next-line camelcase
      columns: [ ...params.extra_columns, 'filtered_risk' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'filtered_risk', 'DESC' ], [ 'id', 'ASC' ] ],
    };
  }

  return params;
};

export const displayValueForResultType = ( type, result ) => {
  if ( isEmpty( result ) ) {
    return 'MISSING';
  }
  if ( type === 'host' ) {
    if ( isNotEmpty( result.label ) ) {
      const labelParts = result.label.split( ' ' );
      return labelParts[ labelParts.length - 1 ];
    }

    if ( isNotEmpty( result.local_name ) ) {
      return result.local_name;
    }
    return '-- No Name Available --';
  }

  if ( type === 'scope' ) {
    return result.label;
  }


  if ( type === 'scanner_signature' ) {
    return `${result.scanner} ${result.signature}`;
  }

  if ( type === 'patch' ) {
    return `${result.vendor} ${result.identifier}`;
  }

  if ( type ==='vulnerability' ) {
    return result.identifier;
  }
  return '';
};