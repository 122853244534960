/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import {
  getDimensionsAndOffset,
  isNotEmpty,
  itemIsObject,
  itemIsString,
  recordTypeDisplayName,
} from '../../../../Utilities';
import TagItem from '../../../../../components/RiskInsight/Tags/Item';

const Options = ( {
  options,
  field,
  wrapperRef,
  showOptions,
  setShowOptions,
  selections,
  addOption,
  isSelected,
  isHovered,
  isMultiSelect,
} ) => {

  const [ style, setStyle ] = React.useState( {} );

  const optionsRoot = document.getElementById( 'selectV2OptionsPortal' );

  // need to position the options below the wrapperRef
  React.useEffect( () => {
    if ( isNotEmpty( wrapperRef?.current ) && showOptions ) {
      const wrapperDimensions = getDimensionsAndOffset( wrapperRef.current );

      if ( isNotEmpty( wrapperDimensions ) ) {
        setStyle( {
          position: 'absolute',
          top: wrapperDimensions.top + wrapperDimensions.height + 8,
          left: wrapperDimensions.left,
          width: wrapperDimensions.width,
        } );
      }
    }
  }, [ wrapperRef, showOptions, selections ] );

  if ( isNotEmpty( optionsRoot ) && isNotEmpty( options ) && showOptions ) {
    return ReactDOM.createPortal(
      <React.Fragment>
        <div className="selectV2OptionsShade" onClick={ () => setShowOptions( false ) }/>
        <div
          className="selectV2Options"
          style={ style }
        >
          {/* options should always be a flattened object of options with label "options" as dividers if needed */}
          {
            itemIsObject( options ) &&
            Object.entries( options ).map( ( [ key, value ], index ) => {
              if ( value.isLabelOption ) {
                return <div key={index} className="selectV2OptGroupLabel">{ value.label }</div>;
              }
              return <div
                key={index}
                // eslint-disable-next-line max-len
                className={ `selectV2Option ${isMultiSelect ? 'isMultiSelect' : '' } ${ isSelected( { value: key, label: value } ) ? 'isSelected' : '' } ${ isHovered( { value: key, label: value } ) ? 'isHovered' : ''}` }
                onClick={ () => addOption( { value: key, label: value } ) }
              >
                {
                  isMultiSelect &&
                  <label>
                    <div
                      // eslint-disable-next-line max-len
                      className={ `checkboxFieldWrapper ${isSelected( { value: key, label: value } ) ? 'checked' : ''}`}
                    >
                    </div>
                  </label>
                }
                { itemIsString( value ) && value }
                {/* a recordFetch that needs to format the name of the returned record */}
                {
                  (
                    itemIsObject( value )
                    && isNotEmpty( field )
                    && isNotEmpty( field.selectOptions )
                    && isNotEmpty( field.selectOptions.recordFetchNeeded )
                    && isNotEmpty( field.selectOptions.recordType )
                  ) &&
                  recordTypeDisplayName( value, field.selectOptions.recordType )
                }
                {
                  (
                    itemIsObject( value )
                    && isNotEmpty( field )
                    && field.attribute === 'asset_tag_ids'
                  ) &&
                  <TagItem tag={value} minimalVersion />
                }
              </div>;
            } )
          }
        </div>
      </React.Fragment>,
      optionsRoot,
    );
  }
  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default Options;