/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './NoThirdPartyEmptyState.scss';
import InlineSVG from '../../../shared/InlineSVG';

const NoThirdPartyEmptyState = ( { hasThirdPartyHosts } ) => {

  return (
    <div className={ `noThirdPartyEmptyState ${hasThirdPartyHosts ? 'hasHosts' : ''}` }>
      {
        hasThirdPartyHosts
          ? <InlineSVG type="noVulnerabilities" />
          : <InlineSVG type="notScanned" />
      }
      {
        hasThirdPartyHosts
          // eslint-disable-next-line max-len
          ? <p>This host was scanned/identified by a third party vulnerability source, but no vulnerabilities were identified.</p>
          // eslint-disable-next-line max-len
          : <p>{ window.COMPANY_NAME } does not have any third party vulnerability scanner information for this item. Either a vulnerability scanner was never set up, or is no longer configured correctly and the last data that { window.COMPANY_NAME } received was too old and has been expired. Please visit the <a href="#.=scanning&page=vulnerability_scanners">vulnerability scanners section</a> of the application to configure or fix a vulnerability scanner configuration.</p>
      }
    </div>
  );
};

export default NoThirdPartyEmptyState;