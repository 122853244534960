/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../Utilities';
import InlineSVG from '../../../../InlineSVG';

const SelectedOption = ( { selection, selections, setSelections, field } ) => {

  const [ showRemove, setShowRemove ] = React.useState( false );

  // when the field comes in, check if it is a multi-select or if it needs to fetch records
  React.useEffect( () => {
    if ( isNotEmpty( field ) ) {
      if ( field.selectOptions?.multiple ) {
        setShowRemove( true );
      } else if ( field.selectOptions?.recordFetchNeeded ) {
        setShowRemove( true );
      } else {
        setShowRemove( false );
      }
    } else {
      setShowRemove( false );
    }
  }, [ field ] );

  const removeSelection = ( e ) => {
    e.preventDefault();
    e.stopPropagation();
    if ( isNotEmpty( selection ) && isNotEmpty( selections ) ) {
      const _selections = { ...selections };

      delete _selections[selection.value];
      setSelections( _selections );
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( selection ) &&
        <div className={ `selectionWrapper ${showRemove ? 'withRemoveButton' : ''}`}>
          { selection.label }
          {/* don't show the remove button if it is a standard, single select */}
          {
            showRemove &&
            <button onClick={ removeSelection }>
              <InlineSVG type="remove" />
            </button>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default SelectedOption;