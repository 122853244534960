/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
// import Split from 'react-split'
import FilterForm from '../../../shared/FilterForm';

// import Chart from './Chart';
import Table from './Table';
import Widgets from './Widgets';

import './style.scss';

import {
  isNotEmpty,
  paramsToFilters,
  encodeURLHash,
  decodeURLHash,
  triggerHashRefresh,
  removeFromURLHash,
  // isEmpty,
} from '../../../shared/Utilities';

import {
  filters,
  filtersAgentless,
  scanningModeOptionsMap,
} from './data';
import Loading from '../../../shared/Loading';
import { makeRequest } from '../../../../legacy/io';
import PageHeader from '../../../shared/PageHeader';
import InlineSVG from '../../../shared/InlineSVG';
import { TagsContext } from '../../../Contexts/Tags';
import IPConnectivityTest from '../IPConnectivityTest';
import { getRowNums, pageIterator } from '../../../shared/Pagination/IndeterminantPagination';

const ScanningDashboard = () => {
  const [ credentials, setCredentials ] = React.useState( [] );
  const [ scanGroups, setScanGroups ] = React.useState( [] );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  const [ sortBy, setSortBy ] = React.useState( 'local_name' );
  const [ sortDirection, setSortDirection ] = React.useState( 'DESC' );

  const [ tallyLoading, setTallyLoading ] = React.useState( false );
  const [ tableLoading, setTableLoading ] = React.useState( false );

  const [ tallyResults, setTallyResults ] = React.useState( {} );
  const [ scanningMode, setScanningMode ] = React.useState();

  const [ selectedScanGroups, setSelectedScanGroups ] = React.useState( null );
  const [ selectedIPAddresses, setSelectedIPAddresses ] = React.useState( null );
  const [ showConnectivityModal, setShowConnectivityModal ] = React.useState( false );

  const [ visualCollapsed, setVisualCollapsed ] = React.useState( false );

  // vars for clicking on the widgets
  const [ selectedScanningAgeIdentifier, setSelectedScanningAgeIdentifier ] = React.useState( null );
  const [ selectedScanGroupIdentifier, setSelectedScanGroupIdentifier ] = React.useState( null );
  const [ selectedAgentVersionIdentifier, setSelectedAgentVersionIdentifier ] = React.useState( null );

  // vars for hovering on the widgets
  const [ hoveredScanningAgeIdentifier, setHoveredScanningAgeIdentifier ] = React.useState( null );
  const [ hoveredScanGroupIdentifier, setHoveredScanGroupIdentifier ] = React.useState( null );
  const [ hoveredAgentVersionIdentifier, setHoveredAgentVersionIdentifier ] = React.useState( null );

  const [ tags ] = React.useContext( TagsContext );

  let isMounted = true;

  // 1) on page load, grab the asset groups needed for searching and filtering,
  //    scanGroups and credentials to get the names
  React.useEffect( () => {
    isMounted = true;

    // grab all creds
    makeRequest( 'SEARCH', '/project/default/credential', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'protocol', 'domain', 'username', 'label' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'eval_order', 'ASC' ] ],
    } ).then( response => {
      if ( response && isNotEmpty( response.results ) ) {
        setCredentials( response.results );
      } else {
        setCredentials( [] );
      }
    } );

    // grab all scan groups
    makeRequest( 'FIND', '/project/default/scan_group', {} ).then( response => {
      if ( response && isNotEmpty( response.results ) ) {
        setScanGroups( response.results );
      } else {
        setScanGroups( [] );
      }
    } );

    const agentFilter = filters.find( i => i.attribute === 'version' );

    if ( isNotEmpty( agentFilter ) ) {
      const _versions = {};

      makeRequest( 'VERSIONS', '/scan_status/agent/default', { filters: {} } ).then( response => {
        if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
          response.results.map( v => {
            _versions[v] = v;
          } );

          agentFilter.options = _versions;
        }
      } );
    }

    if ( isNotEmpty( tags ) ) {
      const tagFilter = filters.find( i => i.attribute === 'asset_tag_ids' );
      if ( isNotEmpty( tagFilter ) ) {
        tagFilter.options = tags;
      }
    }

    setScanningMode( decodeURLHash()['scanning_mode'] || 'agent' );
    onRefresh();
    return () => {
      isMounted = false;
    };
  }, [ tags ] );

  // 2) any time the filters change, refresh the results
  const onRefresh = async ( ) => {

    const onCorrectPage = decodeURLHash()['page'] === 'scanning_dashboard';

    if ( onCorrectPage ) {
      const filterValues = paramsToFilters();

      setTallyLoading( true );
      setTableLoading( true );

      const _scanningMode = filterValues.scanning_mode ? filterValues.scanning_mode : 'agent';

      setScanningMode( _scanningMode );

      const newParams = {};

      const _rowNums = getRowNums( filterValues );

      /* eslint-disable camelcase */
      // grab the values of the filters and map them to appropriate keys that the db expects
      if ( _scanningMode === 'agentless' ) {
        if ( isNotEmpty( filterValues ) ) {
          Object.entries( filterValues ).map( ( [ key, val ] ) => {
            if ( key === 'scanning_age' ) {
              if ( val === 'last_30_days' ) {
                setSelectedScanningAgeIdentifier( [ 'last_24_hours', 'last_7_days', val ] );
              } else if ( val === 'last_7_days' ) {
                setSelectedScanningAgeIdentifier( [ 'last_24_hours', val ] );
              } else  {
                setSelectedScanningAgeIdentifier( [ val ] );
              }
              if ( val === 'never_scanned' ) {
                if ( isNotEmpty( newParams.lt_map ) ) {
                  newParams.lt_map.last_successful_scan = scanningAgeKeyToTimestamp( val );
                } else {
                  newParams.lt_map = { last_successful_scan: scanningAgeKeyToTimestamp( val ) };
                }
              } else if ( val === 'greater_than_30_days' ) {
                if ( isNotEmpty( newParams.lt_map ) ) {
                  newParams.lt_map.last_successful_scan = scanningAgeKeyToTimestamp( val );
                } else {
                  newParams.lt_map = { last_successful_scan: scanningAgeKeyToTimestamp( val ) };
                }
              } else if ( isNotEmpty( newParams.gt_map ) ) {
                newParams.gt_map.last_successful_scan = scanningAgeKeyToTimestamp( val );
              } else {
                newParams.gt_map = { last_successful_scan: scanningAgeKeyToTimestamp( val ) };
              }
            }
            if ( key === 'scan_groups' ) {
              setSelectedScanGroupIdentifier( val );
              newParams.scan_groups = val;
            }
            if ( key === 'item_count' ) {
              newParams.rownums = _rowNums;
            }
            if ( key === 'host_globs' ) {
              // eslint-disable-next-line camelcase
              newParams.host_globs = isNotEmpty( val ) ? val : null;
            }
            if ( key === 'asset_tag_ids' ) {
              // eslint-disable-next-line camelcase
              newParams.asset_tag_ids = val || [];
            }
          } );
        }
      }

      if ( _scanningMode === 'agent' ) {
        if ( isNotEmpty( filterValues ) ) {
          Object.entries( filterValues ).map( ( [ key, val ] ) => {
            if ( key === 'scanning_age' ) {
              if ( val === 'last_30_days' ) {
                setSelectedScanningAgeIdentifier( [ 'last_24_hours', 'last_7_days', val ] );
              } else if ( val === 'last_7_days' ) {
                setSelectedScanningAgeIdentifier( [ 'last_24_hours', val ] );
              } else  {
                setSelectedScanningAgeIdentifier( val );
              }
              if ( val === 'never_scanned' ) {
                if ( isNotEmpty( newParams.lt_map ) ) {
                  newParams.lt_map.last_successful_scan = scanningAgeKeyToTimestamp( val );
                } else {
                  newParams.lt_map = { last_successful_scan: scanningAgeKeyToTimestamp( val ) };
                }
              } else if ( val === 'greater_than_30_days' ) {
                if ( isNotEmpty( newParams.lt_map ) ) {
                  newParams.lt_map.last_successful_scan = scanningAgeKeyToTimestamp( val );
                } else {
                  newParams.lt_map = { last_successful_scan: scanningAgeKeyToTimestamp( val ) };
                }
              } else if ( isNotEmpty( newParams.gt_map ) ) {
                newParams.gt_map.last_successful_scan = scanningAgeKeyToTimestamp( val );
              } else {
                newParams.gt_map = { last_successful_scan: scanningAgeKeyToTimestamp( val ) };
              }
            }
            if ( key === 'version' ) {
              setSelectedAgentVersionIdentifier( val );
              newParams.version = val;
            }
            if ( key === 'item_count' ) {
              newParams.rownums = _rowNums;
            }
            if ( key === 'host_globs' ) {
              // eslint-disable-next-line camelcase
              newParams.host_globs = isNotEmpty( val ) ? val : null;
            }
            if ( key === 'version' ) {
              newParams.version = val;
            }
            if ( key === 'asset_tag_ids' ) {
              // eslint-disable-next-line camelcase
              newParams.asset_tag_ids = val || [];
            }
          } );
        }
      }

      // combine the new params with the base params for this type
      const params = { ...scanningModeOptionsMap[_scanningMode].baseParams, ...newParams };

      // need to do something slightly different for sorting
      let orderBy = [];

      if ( isNotEmpty( filterValues.sort_by ) ) {
        if ( Array.isArray( filterValues.sort_by ) ) {
          filterValues.sort_by.map( s => {
            return orderBy.push( [ s, filterValues.sort_direction ? filterValues.sort_direction : 'DESC' ] );
          } );
        } else {
          orderBy = [ [ filterValues.sort_by, filterValues.sort_direction ? filterValues.sort_direction : 'DESC' ] ];
        }
      }
      // eslint-disable-next-line camelcase
      params.order_by = orderBy;

      const tallyParams = { ...params };

      // remove params that do not pertain to the tally results
      delete tallyParams.rownums;
      delete tallyParams.extra_columns;
      delete tallyParams.order_by;
      // don't apply any filters that are controlled by the visuals to the tallies (visuals) themselves
      delete tallyParams.scan_groups;
      delete tallyParams.version;
      delete tallyParams.gt_map;
      delete tallyParams.lt_map;


      // grab the records
      // eslint-disable-next-line max-len
      const recordResponse = await makeRequest( 'SEARCH', `/scan_status/${_scanningMode}/default`, { filters: params } );

      if ( isNotEmpty( recordResponse ) && isMounted ) {
        const pagedResults = pageIterator( recordResponse.results, filterValues );

        setCurrentPageNumber( pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1 );
        setCurrentPageResults( pagedResults.firstPage );
        setNextPageResults( pagedResults.secondPage );
      }

      // grab the tallies for the charts
      const tallies = await makeRequest(
        'TALLY',
        `/scan_status/${_scanningMode}/default`,
        { filters: tallyParams },
      );

      if ( isMounted ) {
        // for now, just using stubbedData
        if ( isNotEmpty( tallies ) ) {
          setTallyResults( tallies );
        }

        setTallyLoading( false );
        setTableLoading( false );
      }
    }
  };

  // 3) any time any of the headers are clicked on the table the sort direction and
  // attribute changes, or pagination button is clicked whenever the sort or filters change,
  // kick off a fetch for results
  React.useEffect( () => {
    isMounted = true;
    const params = decodeURLHash();
    // eslint-disable-next-line camelcase
    if ( sortBy !== params.sort_by || sortDirection !== params.sort_direction ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { sort_by: sortBy, sort_direction: sortDirection } );
      triggerHashRefresh();
    }
    return () => {
      isMounted = false;
    };
  }, [ sortBy, sortDirection ] );

  const onPageChange = page => {
    const params = decodeURLHash();

    if ( page !== params.page_number ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { page_number: page } );
      triggerHashRefresh();
    }
  };

  const scanningAgeKeyToTimestamp = key => {
    const today = new Date();
    const day = 1000 * 60 * 60 * 24;

    switch ( key ) {
    case 'last_24_hours':
      // 1 day ago, gt_map
      return ( today - day ) / 1000;
    case 'last_7_days':
      // 7 days ago, gt_map
      return ( today - ( day * 7 ) ) / 1000;
    case 'last_30_days':
      // 30 days ago, gt_map
      return ( today - ( day * 30 ) ) / 1000;
    case 'greater_than_30_days':
      // 30 days ago, lt_map
      return ( today - ( day * 30 ) ) / 1000;
    case 'never_scanned':
      // 1, lt_map
      return 1;
    default:
      return null;
    }
  };

  // when the user clicks either a section of the scanning age donut, or the legend entry
  const handleScanningAgeClick = clickedSectionKey => {
    if ( isNotEmpty( clickedSectionKey ) ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { scanning_age: clickedSectionKey } );
    } else {
      removeFromURLHash( 'scanning_age' );
    }
    onRefresh( false );
  };

  // when the user clicks either a section of the scanning age donut, or the legend entry
  const handleScanGroupClick = clickedSectionKey => {
    if ( isNotEmpty( clickedSectionKey ) ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { scan_groups: clickedSectionKey } );
    } else {
      removeFromURLHash( 'scan_groups' );
    }
    onRefresh( false );
  };

  // when the user clicks either a section of the scanning age donut, or the legend entry
  const handleAgentVersionClick = clickedSectionKey => {
    if ( isNotEmpty( clickedSectionKey ) ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { version: clickedSectionKey } );
    } else {
      removeFromURLHash( 'version' );
    }
    onRefresh( false );
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( scanningMode ) &&
        <React.Fragment>
          <IPConnectivityTest
            scanGroups={selectedScanGroups}
            ipAddresses={selectedIPAddresses}
            show={showConnectivityModal}
            setShow={setShowConnectivityModal}
          />
          <PageHeader elementClass="scanningDashboardHeaderWrapper" >
            <button
              className={ `showVisualToggle ${ visualCollapsed ? 'visualDisabled' : ''}` }
              onClick={ () => setVisualCollapsed( !visualCollapsed )}
              title="View/Hide Chart"
            >
              {
                visualCollapsed
                  ? <React.Fragment>
                    <InlineSVG type="visualDisabled" />
                  </React.Fragment>
                  : <React.Fragment>
                    <InlineSVG type="visualEnabled" />
                  </React.Fragment>
              }
            </button>
            <FilterForm
              inputs={scanningMode === 'agent' ? filters : filtersAgentless}
              onRefresh={onRefresh}
              reportType="scanning"
              horizontal={true}
              noRefresh
            />
            <Widgets
              tallyLoading={tallyLoading}
              scanningMode={scanningMode}
              tallyResults={tallyResults}
              collapsed={visualCollapsed}
              scanGroups={scanGroups}
              handleScanningAgeClick={ handleScanningAgeClick }
              handleScanGroupClick={ handleScanGroupClick }
              handleAgentVersionClick={ handleAgentVersionClick }
              selectedScanningAgeIdentifier={ selectedScanningAgeIdentifier }
              setSelectedScanningAgeIdentifier={ setSelectedScanningAgeIdentifier }
              selectedScanGroupIdentifier={ selectedScanGroupIdentifier }
              setSelectedScanGroupIdentifier={ setSelectedScanGroupIdentifier }
              selectedAgentVersionIdentifier={ selectedAgentVersionIdentifier }
              setSelectedAgentVersionIdentifier={ setSelectedAgentVersionIdentifier }
              hoveredScanningAgeIdentifier={ hoveredScanningAgeIdentifier }
              setHoveredScanningAgeIdentifier={ setHoveredScanningAgeIdentifier }
              hoveredScanGroupIdentifier={ hoveredScanGroupIdentifier }
              setHoveredScanGroupIdentifier={ setHoveredScanGroupIdentifier }
              hoveredAgentVersionIdentifier={ hoveredAgentVersionIdentifier }
              setHoveredAgentVersionIdentifier={ setHoveredAgentVersionIdentifier }
            />
          </PageHeader>
          <Table
            scanningMode={scanningMode}
            results={currentPageResults}
            nextResults={nextPageResults}
            currentPageNumber={currentPageNumber}
            isLoading={tableLoading}
            credentials={credentials}
            scanGroups={scanGroups}
            onPageChange={onPageChange}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            onRefresh={onRefresh}
            setSelectedIPAddresses={setSelectedIPAddresses}
            setSelectedScanGroups={setSelectedScanGroups}
            setShowConnectivityModal={setShowConnectivityModal}
          />
          {
            tableLoading &&
            <Loading />
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ScanningDashboard;
