/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';

import './ExploitStatusBreakdown.scss';
import EmptyState from '../../../../../shared/EmptyState';
import { defaultInstanceHostURL } from '../../../../App/Routing';
import { getThemeColor } from '../../../../../shared/Themes';

const ExploitStatusBreakdown = ( { data, onClickCallback, settings } ) => {
  const [ chartData, setChartData ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );

  const [ selectedSeriesIdentifier, setSelectedSeriesIdentifier ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = async ( data ) => {
    let _talliesResults;

    if ( isNotEmpty( data?.results )  ) {
      _talliesResults = data.results;
      const { _total } = _talliesResults;

      setTotal( _total );

      const zeroed = _total === 0;

      const _chartData = {
        private: {
          label: 'Private',
          stroke: getThemeColor( '--divider-color' ),
          value: zeroed ? zeroed : ( ( _talliesResults.exploit_status.private / _total ) * 100 ),
          total: _talliesResults.exploit_status.private,
          key: 'private',
          original: { id: 'private' },
        },
        // eslint-disable-next-line camelcase
        published_details: {
          label: 'Details Published',
          stroke: getThemeColor( '--low' ),
          value: zeroed ? zeroed : ( ( _talliesResults.exploit_status.published_details / _total ) * 100 ),
          total: _talliesResults.exploit_status.published_details,
          key: 'published_details',
          original: { id: 'published_details' },
        },
        poc: {
          label: 'PoC Published',
          stroke: getThemeColor( '--moderate' ),
          value: zeroed ? zeroed : ( ( _talliesResults.exploit_status.poc / _total ) * 100 ),
          total: _talliesResults.exploit_status.poc,
          key: 'poc',
          original: { id: 'poc' },
        },
        weaponized: {
          label: 'Weaponized',
          stroke: getThemeColor( '--high' ),
          value: zeroed ? zeroed : ( ( _talliesResults.exploit_status.weaponized / _total ) * 100 ),
          total: _talliesResults.exploit_status.weaponized,
          key: 'weaponized',
          original: { id: 'weaponized' },
        },
      };
      setChartData( _chartData );
    }
  };

  React.useEffect( () => {
    setupData( data );
  }, [ data, settings ] );

  const handleExploitStatusClickRedirect = statusKey => {
    // eslint-disable-next-line max-len
    let baseURLString = defaultInstanceHostURL;

    if ( isNotEmpty( statusKey ) ) {
      baseURLString += `&exploit_statuses=%5B"${statusKey}"%5D`;
    }
    window.open( baseURLString );
  };

  const onChartClick = statusKey => {
    if ( isNotEmpty( onClickCallback ) ) {
      onClickCallback( statusKey );
    } else if ( isNotEmpty( statusKey ) ) {
      handleExploitStatusClickRedirect( statusKey );
    }
  };

  const onLegendClick = legendKey => {
    if ( isNotEmpty( onClickCallback ) ) {
      onClickCallback( legendKey );
    } else if ( isNotEmpty( legendKey ) ) {
      handleExploitStatusClickRedirect( legendKey );
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( chartData )
          ? <React.Fragment>
            <Donut
              strokeWidth="thick"
              data={chartData}
              total={total}
              // eslint-disable-next-line max-len
              onClickCallback={ onChartClick }
              selectedSeriesIdentifier={ selectedSeriesIdentifier }
              setSelectedSeriesIdentifier={ setSelectedSeriesIdentifier }
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
            <Legend
              legendData={chartData}
              onClickCallback={ onLegendClick }
              selectedSeriesIdentifier={ selectedSeriesIdentifier }
              setSelectedSeriesIdentifier={ setSelectedSeriesIdentifier }
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
          </React.Fragment>
          : <EmptyState message="No exploit status information available" />
      }
    </React.Fragment>
  );
};

export default ExploitStatusBreakdown;