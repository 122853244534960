/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../legacy/io';
import { FlashMessageQueueContext } from '../../Contexts/FlashMessageQueue';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
// import ConfigurationAlert from '../ConfigurationAlert';
import Form from '../Form';
import { getFieldValues } from '../Form/Shared';
import { isEmpty, isNotEmpty, itemIsArray, userDisplayName } from '../Utilities';
import './style.scss';
import RatingBadge from '../RatingBadge';

const displayLalbelForNotificationKey = {
  'third-party-scan-unknown-host': 'Example Notifcation One',
  'second_notification_type': 'Example Notifcation Two',
  'configuration-notifications': 'Configuration Alerts',
};

const DOW_OPTIONS = {
  mon: 'Mondays',
  tue: 'Tuesdays',
  wed: 'Wednesdays',
  thu: 'Thursdays',
  fri: 'Fridays',
  sat: 'Saturdays',
  sun: 'Sundays',
};


const NotificationSubscriptions = ( {
  user,
  availableNotifications,
  onChangeCallback=() => {},
  elementClass='',
  onSaveCallback=() => {},
  onCancelCallback=() => {},
  omitActions=false,
} ) => {

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ currentUser, , , ] = React.useContext( CurrentUserContext );

  // options for the user selector, if using
  const [ userOptions, setUserOptions ] = React.useState( {} );
  // different from the userOptions (which are formatted for an advanced select field), just the raw users as obj.
  const [ allUsers, setAllUsers ] = React.useState( null );

  // all possible notifcations that a user can subscribe to
  const [ notificationOptions, setNotificationOptions ] = React.useState( {} );
  // either the passed in user, or the currently selected user, if selector is used
  const [ selectedUser, setSelectedUser ] = React.useState( null );

  const [ userSelectForm, setUserSelectForm ] = React.useState( null );

  // the current subscriptions that the selectedUser is receiving
  const [ existingSubscriptionsRecord, setExistingSubscriptionsRecord ] = React.useState( {} );

  // fields sent to the form, each notification option is a group
  const [ fields, setFields ] = React.useState( null );
  // a separate fields obj. for just the one user selector
  const [ userField, setUserField ] = React.useState( null );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  const onFormChangeCallback = ( form ) => {
    setUpdatedForm( form );
    onChangeCallback( form );
  };

  // fetches any subscriptions a given user has
  const getUserSubscriptions = async( user ) => {
    // stubbed for now, will eventually return results from the real endpoint

    const params = {
      // eslint-disable-next-line camelcase
      field_map: {
        // eslint-disable-next-line camelcase
        web_user: user.id,
      },

      // eslint-disable-next-line camelcase
      extra_columns : [ 'web_user', 'settings', 'notification_key' ],
    };

    const userSubscriptions = await makeRequest( 'SEARCH', '/notification_settings', params );

    if ( isNotEmpty( userSubscriptions ) && isNotEmpty( userSubscriptions.results ) ) {
      return userSubscriptions.results;
    }

    return [];
  };

  // if a user is passed in, then this is just for a single user and the selector is not needed
  // fetch all available users for the selector for use in the notification: settings page.
  React.useEffect( ( ) => {

    if ( isEmpty( user ) ) {
      // fetch all the users, set the selected user to the first, set all the options for the user
      // selector
      const _userOptions = {};
      const _allUsers = {};

      const params = {
        // eslint-disable-next-line camelcase
        columns: [
          'authentication_provider_id',
          'username',
          'given_name',
          'family_name',
          'api_key',
          'setup_wizard_disabled',
          'email_address',
          'remediation_export_setting',
          'acl_all_asset_tags',
          'acl_all_dashboards',
          'acl_all_remediation_plans',
          'acl_role',
        ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'username', 'ASC' ] ],
      };

      makeRequest( 'POST', '/fe/user/SELECT', params ).then( response => {
        if ( isNotEmpty( response ) && itemIsArray( response ) ) {
          response.map( user => {

            _allUsers[user.id] = user;

            _userOptions[user.id] = <React.Fragment>
              <span className="selectedUserDisplayName">{ userDisplayName( user ) }</span>
              {
                isEmpty( user.email_address ) &&
                <RatingBadge rating="Email Address Missing" elementClass="high" />
              }
            </React.Fragment>;
          } );
          if ( isEmpty( currentUser ) ) {
            setSelectedUser( response.results[0] );
          } else {
            setSelectedUser( currentUser );
          }
        }
      } );
      setAllUsers( _allUsers );
      setUserOptions( _userOptions );

      const fields = [
        {
          type: 'select',
          options: _userOptions,
          attribute: 'user_selector',
          asDropdown: true,
          label: ' Select User',
          defaultValue: isEmpty( currentUser ) ? Object.keys( _userOptions )[0] : currentUser.id,
        },
      ];

      setUserField( fields );
    } else {
      setSelectedUser( user );
      setUserOptions( { [user.id]: userDisplayName( user ) } );
    }
  }, [ user, currentUser ] );

  // if for some reason the availableNotifications have not been passed in, grab them now
  React.useEffect( ( ) => {
    if ( isEmpty( availableNotifications ) ) {
      makeRequest( 'TYPES', '/notification_settings', {} ).then( response => {
        if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
          setNotificationOptions( response.results );
        }
      } );
    } else {
      setNotificationOptions( availableNotifications );
    }
  }, [ availableNotifications ] );

  // when the selected user changes, either because a user is passed in, or the selector is changed
  // fetch what notifications that user has subscribed to so that they can be displayed
  // main setter for the form and rest of display
  React.useEffect( ( ) => {
    if ( isNotEmpty( selectedUser ) && isNotEmpty( notificationOptions ) ) {
      // map the user's subscriptions to the available notifications and set the subscriptions for
      // display

      getUserSubscriptions( selectedUser ).then( subscriptions => {

        const _existingSubscriptionsRecord = { ...existingSubscriptionsRecord };
        const _fields = { ...fields };

        // map through each of the notificationOptions and see if the user is subscribing
        Object.entries( notificationOptions ).map( ( [ notificationKey, options ] ) => {

          const sub = subscriptions.find( s => s.notification_key === notificationKey );

          const digestOptions = {
            daily: 'Daily',
            weekly: 'Weekly',
            immediate: 'Immediately',
          };

          const optionsForDigests = digests => {
            const options = {};

            digests.map( o => {
              options[o] = digestOptions[o];
            } );
            return options;
          };

          const frequencyOptions = optionsForDigests( options.digests ) || {};

          const fequencyDefault = Object.keys( frequencyOptions ).includes( 'weekly' ) ? 'weekly' : 'daily';

          const fields = [
            {
              type: 'checkbox',
              asToggle: true,
              attribute: `${notificationKey}_subscribing`,
              defaultValue: false,
              label: displayLalbelForNotificationKey[notificationKey],
            },
            {
              type: 'select',
              includeIf: { attribute: `${notificationKey}_subscribing`, value: true },
              options: frequencyOptions,
              attribute: `${notificationKey}_frequency`,
              defaultValue: fequencyDefault,
              label: '',
            },
            {
              type: 'select',
              includeIf: [
                { attribute: `${notificationKey}_subscribing`, value: true },
                { attribute: `${notificationKey}_frequency`, value: 'weekly' },
              ],
              options: DOW_OPTIONS,
              attribute: `${notificationKey}_dow`,
              defaultValue: 'mon',
              label: 'on',
            },
            {
              type:'contentBlock',
              includeIf: { attribute: `${notificationKey}_subscribing`, value: false },
              defaultValue: 'Not notifying',
              attribute: `${notificationKey}_not-notifying`,
            },
            {
              type:'checkbox',
              includeIf: { attribute: `${notificationKey}_subscribing`, value: true },
              defaultValue: false,
              attribute: `${notificationKey}_diff_digest`,
              elementClass: 'diff_digest_checkbox',
            },
          ];

          _fields[notificationKey] = { fields };

          // the user has subscribed to this option, need to save as part of the 'existing record'
          // for the form, otherwise setting associated attrs to null for the form to work properly
          if ( isNotEmpty( sub ) ) {
            _existingSubscriptionsRecord[`${notificationKey}_subscribing`] = true;
            // adding this for onSave later on
            _existingSubscriptionsRecord[notificationKey] = sub.id;
            if ( isNotEmpty( sub.settings ) && isNotEmpty( sub.settings.frequency ) ) {
              _existingSubscriptionsRecord[`${notificationKey}_frequency`] = sub.settings.frequency;
            }
            if ( isNotEmpty( sub.settings ) && isNotEmpty( sub.settings.dow ) ) {
              _existingSubscriptionsRecord[`${notificationKey}_dow`] = sub.settings.dow;
            }
            if ( isNotEmpty( sub.settings ) && isNotEmpty( sub.settings.diff_digest ) ) {
              _existingSubscriptionsRecord[`${notificationKey}_diff_digest`] = sub.settings.diff_digest;
            }
          } else {
            _existingSubscriptionsRecord[`${notificationKey}_subscribing`] = false;
          }
        } );

        setExistingSubscriptionsRecord( _existingSubscriptionsRecord );
        setFields( _fields );
      } );
    }
  }, [ selectedUser, notificationOptions ] );

  // syncs the user selector with the the selectedUser value
  React.useEffect( ( ) => {
    if ( isNotEmpty( userSelectForm ) && isNotEmpty( allUsers ) ) {
      const values = getFieldValues( userSelectForm?.fieldStates, 'user_select' );

      if ( isNotEmpty( values ) && isNotEmpty( values.user_selector ) ) {
        const user = allUsers[values.user_selector];
        if ( isNotEmpty( user ) ) {
          setSelectedUser( user );
        }
      }
    }
  }, [ userSelectForm, allUsers ] );

  const onSave = async () => {
    if (
      isNotEmpty( updatedForm )
      && isNotEmpty( updatedForm.fieldStates )
      && isNotEmpty( notificationOptions )
    ) {
      const values = getFieldValues( updatedForm.fieldStates, 'notification_setting' );
      const notificationOptionsKeys = Object.keys( notificationOptions );

      // intermediate object to group the form values with correct keys
      const formattedValuesObject = {};

      // array to be sent to the backend will get filled in after going through all the values of the form
      const records = [];
      // for unsubscribing, need to delete previous subscriptions.
      const toDelete = [];

      notificationOptionsKeys.map( key => {
        formattedValuesObject[key] = {};
      } );

      if ( isNotEmpty( values ) ) {
        Object.entries( values ).map( ( [ key, value ] ) => {

          const keyParts = key.split( '_' );

          const [ strippedKey ] = keyParts;

          // the actual key for this notification in order to correctly format the records for the request
          const attr = key.replace( `${strippedKey}_`, '' );

          // are we subscribing or not
          if ( attr === 'subscribing' ) {
            formattedValuesObject[strippedKey].subscribing = value;
          }

          // what frequency
          if ( attr === 'frequency' ) {
            formattedValuesObject[strippedKey].frequency = value;
          }

          // day of week (only for weekly)
          if ( attr === 'dow' ) {
            formattedValuesObject[strippedKey].dow = value;
          }

          // alert only if there is a significant change
          if ( attr === 'diff_digest' ) {
            // eslint-disable-next-line
            formattedValuesObject[strippedKey].diff_digest = value;
          }

          // ignoring everything else
        } );

        // now that all the keys and values are in the correct spot, we need to build out the records for the backend
        if ( isNotEmpty( formattedValuesObject ) ) {
          Object.entries( formattedValuesObject ).map( ( [ notificationKey, settings ] ) => {

            // check to see if we are subscribing or not, if so build the record
            if ( settings.subscribing === true ) {
              const record = {
                // eslint-disable-next-line camelcase
                web_user: selectedUser.id,
                // eslint-disable-next-line camelcase
                notification_key: notificationKey,
                settings: {
                  frequency: settings.frequency,
                },
              };

              if ( isNotEmpty( settings.dow ) ) {
                record.settings.dow = settings.dow;
              }

              if ( isNotEmpty( settings.diff_digest ) ) {
                // eslint-disable-next-line camelcase
                record.settings.diff_digest = settings.diff_digest;
              }

              // add it to the array of records to upsert
              records.push( record );
            // we are unsubscribing, from a previous subscription, need to queue for delete
            } else if ( settings.subscribing === false && existingSubscriptionsRecord[notificationKey] ) {
              toDelete.push( existingSubscriptionsRecord[notificationKey] );
            }
          } );
        }
      }

      // if there are any records to delete them one at a time
      if ( isNotEmpty( toDelete ) ) {

        const deletion = await makeRequest( 'DELETE', '/notification_settings', { ids: toDelete } );

        // success
        if ( isNotEmpty( deletion ) && isNotEmpty( deletion.results ) ) {
          addFlashMessage( {
            type: 'success',
            body: <p>Successfully updated notification settings.</p>,
          } );
        // error
        } else if ( deletion.errors ) {
          deletion.errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: <p>{ e }</p>,
            } );
          } );
        // likely 500
        } else {
          addFlashMessage( {
            type: 'alert',
            body: <p>There was an error updating your notification settings, please try again.</p>,
          } );
        }
      }

      // if there are any records to submit
      if ( isNotEmpty( records ) ) {
        // normally for an upsert, we would need to add the ID if it is an existing record, because of the nature
        // of this interaction, and the fact there is already another unique identifier present, we do not need
        // to pass in the id for an existing record, the backend will already know.

        const update = await makeRequest( 'UPSERT', '/notification_settings', { records } );

        // success
        if ( isNotEmpty( update ) && isNotEmpty( update.results ) ) {
          addFlashMessage( {
            type: 'success',
            body: <p>Successfully updated notification settings.</p>,
          } );
        // error
        } else if ( update.errors ) {
          update.errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: <p>{ e }</p>,
            } );
          } );
        // likely 500
        } else {
          addFlashMessage( {
            type: 'alert',
            body: <p>There was an error updating your notification settings, please try again.</p>,
          } );
        }
      }
    }
    onSaveCallback();
  };

  return (
    <div className={ `notificationSubscriptionsWrapper ${elementClass} ${isEmpty( user ) ? 'fullPage' : '' }` }>
      {
        ( isNotEmpty( userOptions ) && isNotEmpty( selectedUser ) && isEmpty( user ) && isNotEmpty( userField ) ) &&
        <Form
          onChangeCallback={ setUserSelectForm }
          elementClass="userSelector"
          recordType={ 'user_subscription_selector' }
          fields={userField}
        />
      }
      {
        ( isNotEmpty( fields ) && isNotEmpty( existingSubscriptionsRecord ) ) &&
        <React.Fragment>
          <div className="subscriptionHeader">
            <span>Notification</span>
            <span>
              <span>Frequency</span>
              <span>Changes Only?</span>
            </span>
          </div>
          <Form
            fields={fields}
            existingRecord={existingSubscriptionsRecord}
            trackUpdates={false}
            recordType={'notification_setting'}
            setIsValid={setIsValid}
            onChangeCallback={onFormChangeCallback}
            elementClass="subscriptionRow"
          />
        </React.Fragment>

      }
      {/* different actions and structure depending on if this is within the user modal or on the settings page */}
      {
        !omitActions &&
        <React.Fragment>
          {
            isEmpty( user )
              ? <div className="formActionsContainer">
                <div className="formActions singleButton">
                  <button
                    onClick={ onSave }
                    disabled={ !isValid }
                    className="submitButton"
                  >
                  Save
                  </button>
                </div>
              </div>
              : <div className="modalActions">
                <button
                  onClick={ onSave }
                  disabled={ !isValid }
                >
                  Save
                </button>
                <button className="cancelButton" onClick={ onCancelCallback }>
                  <span>Cancel</span>
                </button>
              </div>
          }
        </React.Fragment>
      }


    </div>
  );
};

export default NotificationSubscriptions;