/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';

import './AccessItem.scss';
import { CurrentUserContext } from '../../../../../Contexts/CurrentUser';
import CheckButton from '../../../../../shared/Form/CheckButton';

const AccessItem = ( {
  accessItem,
  accessItemType,
  // existingAccess,
  callback=() => {},
} ) => {

  const [ currentUser ] = React.useContext( CurrentUserContext );

  const [ isOwner, setIsOwner ] = React.useState( false );
  const [ isViewer, setIsViewer ] = React.useState( false );
  const [ ownerDisabled, setOwnerDisabled ] = React.useState( false );
  const [ viewerDisabled, setViewerDisabled ] = React.useState( false );

  const handleOwnerCheck = newValue => {
    setIsOwner( newValue );
    if ( newValue === true ) {
      setViewerDisabled( true );
      setIsViewer( true );
      callback( accessItem, accessItemType, 'owner' );
    } else {
      callback( accessItem, accessItemType, null );
    }
  };

  const handleViewerCheck = newValue => {
    setIsViewer( newValue );
    if ( newValue === true ) {
      callback( accessItem, accessItemType, 'viewer' );
    } else {
      callback( accessItem, accessItemType, null );
    }
  };

  React.useEffect( () => {
    let _ownerDisabled, _viewerDisabled;

    if ( isNotEmpty( currentUser ) && currentUser.acl_role === 'admin' ) {
      _ownerDisabled = false;
      _viewerDisabled = false;
    } else {
      _ownerDisabled = true;
      _viewerDisabled = true;
    }

    if ( isNotEmpty( accessItem ) ) {
      if ( accessItem.override ) {
        if ( accessItem.existing === 'owner' ) {
          setIsOwner( true );
          setIsViewer( true );
          setOwnerDisabled( _ownerDisabled );
          setViewerDisabled( true );
        } else if ( accessItem.existing === 'viewer' ) {
          setIsOwner( false );
          setIsViewer( true );
          setOwnerDisabled( _ownerDisabled );
          setViewerDisabled( false );
        } else {
          setIsOwner( false );
          setIsViewer( true );
          setOwnerDisabled( _ownerDisabled );
          setViewerDisabled( true );
        }
      } else if ( accessItem.existing === 'owner' ) {
        setIsOwner( true );
        setIsViewer( true );
        setOwnerDisabled( _ownerDisabled );
        setViewerDisabled( true );
      } else if ( accessItem.existing === 'viewer' ) {
        setIsOwner( false );
        setIsViewer( true );
        setOwnerDisabled( _ownerDisabled );
        setViewerDisabled( false );
      } else {
        setIsOwner( false );
        setIsViewer( false );
        setOwnerDisabled( _ownerDisabled );
        setViewerDisabled( _viewerDisabled );
      }
    } else {
      setIsOwner( false );
      setIsViewer( false );
      setOwnerDisabled( _ownerDisabled );
      setViewerDisabled( _viewerDisabled );
    }
  }, [ accessItem, currentUser ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( accessItem ) &&
        <li className="objectAccessItemRow">
          <div className="checkboxesWrapper">
            {
              accessItemType !== 'asset_tag' &&
              <CheckButton
                checked={ isOwner }
                handleCheck={ handleOwnerCheck }
                elementClass="owner"
                disabled={ ownerDisabled }
                label="Owner"
              />
            }

            <CheckButton
              checked={ isViewer }
              handleCheck={ handleViewerCheck }
              elementClass="viewer"
              disabled={ viewerDisabled }
              label="Viewer"
            />
          </div>
          <div className="labelWrapper">
            <span>{ accessItem.label }</span>
          </div>
        </li>
      }
    </React.Fragment>
  );
};

export default AccessItem;