/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import { makeRequest } from '../../../legacy/io';

import {
  isNotEmpty,
  encodeURLHash,
  capitalize,
  isEmpty,
  itemIsArray,
} from '../Utilities';

export const pluralizedRecord = recordType => {
  const specialCases = {
    // eslint-disable-next-line camelcase
    sensitive_asset_policy: 'Sensitive Asset Policies',
  };
  return specialCases[recordType] || `${capitalize( recordType )}s`;
};

export const getSubnets = ( record, recordData ) => {
  const nets = [];
  record.members.map( member => {
    if ( isNotEmpty( recordData.subnets[member] ) ) {
      nets.push( recordData.subnets[member] );
    }
  } );
  if ( isNotEmpty( nets ) ) {
    return nets.join( ', ' );
  }
  return 'No longer present';

};

export const getDeletePathForType = type => {
  const map = {
    /* eslint-disable camelcase */
    user: 'user',
    vulnerability_source: 'third_party_setting',
    cloud_scanner: 'cloud_setting',
    scan_credential: 'credential',
    integration: 'third_party_setting',
    authentication_provider: 'authentication_provider',
    certificate: 'certificate',
    subordinate_scanner: 'subordinate_scanner',
    connectivity_clique: 'connectivity_clique',
    tag: 'asset_tag',
    scan_group: 'scan_group',
    remediation_plan: 'model/base/remediation_plan',
    sensitive_asset_policy: 'model/base/sensitive_asset_policy',
    accepted_risk_plan: 'model/base/accepted_risk',
    /* eslint-enable camelcase */
  };

  if (
    type === 'user'
    || type === 'certificate'
    || type === 'subordinate_scanner'
    || type === 'sensitive_asset_policy'
    || type === 'accepted_risk_plan'
  ) {
    return `/${map[type]}`;
  }
  return `/project/default/${map[type]}`;
};

export const getDisplayValueFor = ( type, displayAttribute, record, providers ) => {
  if ( type === 'user' ) {
    if ( displayAttribute === 'authentication_provider_id' ) {
      if ( isNotEmpty( providers ) ) {
        return providers[record.authentication_provider_id];
      }
      return record.authentication_provider_id;

    }
  }
  return record[displayAttribute];
};

export const deleteClique = ( clique, cliques, callback ) => {
  const records = cliques.filter( c => c.id !== clique.id );
  if ( window.confirm( 'Are you sure you want to delete this clique?' ) ) {
    makeRequest( 'REPLACE', '/project/default/connectivity_clique', { records: records } ).then( () => {
      callback();
    } );
  }
};

export const deleteRecord = ( record, type, successCallBack, failureCallBack ) => {
  if ( window.confirm( 'Are you sure you want to delete this?' ) ) {
    if ( type === 'user' ) {
      const params = [ record.id ];
      makeRequest( 'PUT', '/fe/user/DELETE', params ).then( response => {
        if ( isNotEmpty( response ) && itemIsArray( response ) ) {
          successCallBack();
        } else {
          failureCallBack();
        }
      } );
    } else {
      let params = {
        id: record.id,
      };

      if ( type === 'sensitive_asset_policy' ) {
        params = {
          ids: [ record.id ],
        };
      }

      makeRequest( 'DELETE', getDeletePathForType( type ), params ).then( response => {

        if ( response && response.results ) {
          let credID = '';
          if ( type === 'vulnerability_source' || type === 'integration' ) {
            credID = record.settings.credential_id;
          }
          if ( type === 'cloud_scanner' ) {
            credID = record.credential_id;
          }

          if ( isNotEmpty( credID ) ) {
            makeRequest( 'DELETE', '/project/default/credential',  {'id': credID} ).then( () => {
              successCallBack();
            } );
          } else {
            successCallBack();
          }
        } else {
          failureCallBack();
        }
      } );
    }
  }
};

export const editRecord = ( record, setter, modalSetter, recordType ) => {
  const _record = { ...record };
  if (
    isNotEmpty( recordType )
    && recordType === 'vulnerability_source'
    && _record.tool === 'msatp'
    && isNotEmpty( _record.settings )
    && isEmpty( _record.settings.server )
  ) {
    _record.settings.server = 'api.securitycenter.microsoft.com';
  }

  // eslint-disable-next-line camelcase
  encodeURLHash( { selected_record: record.id } );
  setter( _record );
  modalSetter( true );
};
