/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import {
  categoryLabelsAndDescription,
  deprioritizedKeys,
  forReviewKeys,
  parentCategoryKeys,
  prioritizedKeys,
} from './VulnerabilityInstancesCategories';
import Donut from '../../../../../shared/Charts/Donut';

import './SpecificCategory.scss';
import EmptyState from '../../../../../shared/EmptyState';
import { getThemeColor } from '../../../../../shared/Themes';

const SpecificCategory = ( { data, settings } ) => {

  const [ chartData, setChartData ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );

  const getCategoryFill = key => {
    if ( isNotEmpty( key ) ) {

      if ( parentCategoryKeys.includes( key ) ) {
        if ( key === 'prioritized' ) {
          return getThemeColor( '--status-alert' );
        }
        if ( key === 'for_review' ) {
          return getThemeColor( '--text-color-secondary' );
        }
        if ( key === 'deprioritized' ) {
          return getThemeColor( '--status-success' );
        }
        return getThemeColor( '--text-color-primary' );
      }

      if ( prioritizedKeys.includes( key ) ) {
        return getThemeColor( '--status-alert--75' );
      }
      if ( deprioritizedKeys.includes( key ) ) {
        return getThemeColor( '--status-success--75' );
      }
      if ( forReviewKeys.includes( key ) ) {
        return getThemeColor( '--text-color-secondary' );
      }
    }
    return getThemeColor( '--text-color-primary' );
  };

  const setupData = ( data ) => {
    if ( isNotEmpty( data ) && isNotEmpty( settings ) && isNotEmpty( settings.category ) ) {
      if (
        isNotEmpty( data.results )
        && isNotEmpty( data.results.category )
        && isNotEmpty( data.results._total )
      ) {
        const { _total } = data.results;
        setTotal( _total );

        let categoryInformation;

        if ( settings.category === 'prioritized' ) {
          categoryInformation = {
            label: 'Deprioritized',
            fill: getCategoryFill( settings.category ),
          };
        } else {
          categoryInformation = categoryLabelsAndDescription[settings.category];
        }

        if ( isNotEmpty( categoryInformation ) ) {
          const _chartData = {
            [settings.category]: {
              label: categoryInformation.label,
              order: 1,
              stroke: getCategoryFill( settings.category ),
              rawValue: data.results.category[settings.category],
              value: ( data.results.category[settings.category] / _total ) * 100,
            },
          };
          setChartData( _chartData );
        }
      }
    }
  };

  React.useEffect( () => {
    setupData( data );
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( chartData )
          && isNotEmpty( total )
          && isNotEmpty( settings )
          && isNotEmpty( settings.category )
          && isNotEmpty( chartData[settings.category] )
        )
          ? <React.Fragment>
            <Donut
              strokeWidth="thin"
              total={total}
              data={ chartData }
              displayLabel
              // subLabel={ chartData[settings?.category].label }
            />
            <p className="categoryDescription">
              {/* eslint-disable-next-line max-len */}
              <strong>{chartData[settings?.category].label}</strong> instances make up <strong>{ Math.round( chartData[settings?.category].value ) }%</strong> of all vulnerability instances in your environment
            </p>
          </React.Fragment>
          : <EmptyState message="No data available" />
      }

    </React.Fragment>
  );
};

export default SpecificCategory;